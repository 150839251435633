import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import {Img} from 'react-image';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/Album.css';

// Assets Imports
import { default_album_image } from '@Assets';

// Components and Views Imports
import AdUnit from '@components/AdUnit.js';
import Card from '@components/Card.js';
import Haptic from '@components/haptic.js';
import Ripples from 'react-ripples';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Constant Imports
import * as constants from '@constants';

// Import the event bus
import event_bus from '@Eventbus';

// Import the controllers
import queue from '@Queue';
import storage from '@Storage';

const cover_size = window.screen.width;

class Album extends Component {
	constructor(props){
		super(props);
		this.state = {
			album_hash_id: null,
			recommended_artist: [],
			recommended_albums: [],
		};

		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);

		var path = window.location.href;
		var album_hash_id = null;

		if (path.includes('album')) {
			album_hash_id = this.props.url.params.album_hash_id;
			this.state.album_hash_id = album_hash_id;
		}

		this.get_album_info = this.get_album_info.bind(this);
		this.get_album_recommendations = this.get_album_recommendations.bind(this);
		this.see_more = this.see_more.bind(this);
		this.find_similar_button = this.find_similar_button.bind(this);

		this.get_album_info(album_hash_id);
	}

	componentDidMount() {
		const scrollable_element = document.querySelector('.main-content-inner');
		scrollable_element.onscroll = this.handle_scroll; // For Chrome
		scrollable_element.onwheel = this.handle_scroll; // For Firefox
		scrollable_element.ontouchmove = this.handle_scroll; // For mobile
		scrollable_element.onmousewheel = this.handle_scroll; // For IE
	}

	handle_scroll() {
		if (!window.location.pathname.includes('album')) {
			return;
		}

		// If it's the Album page, get the scrollable element and the header
		const scrollable_element = document.querySelector('.main-content-inner > div');
		const more_header = document.querySelector('.album-header-container');

		// If the user has scrolled down, change the header background color to black, else change it back to transparent
		if (scrollable_element.scrollTop !== 0){
			more_header.classList.add('album-header-scrolled');
		} else {
			more_header.classList.remove('album-header-scrolled');
		}
	}

	get_album_info(hash_id){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/album/',
			data: {hash_id: hash_id},
			success: function(data){
				if (data.status === 'success') {
					store_set('album_info', data);
					store_set('loading_overlay_display', false);
					this.get_album_recommendations(hash_id);
				}
			}.bind(this),
		});
	}

	get_album_recommendations(hash_id){
		// We check if there's any recommendations saved on localStorage
		let local_data = storage.get('recommendations_album_' + hash_id);
		if (local_data) {
			this.setState({
				recommended_artist: local_data.recommended_artist,
				recommended_albums: local_data.recommended_albums
			});
			store_set('loading_overlay_display', false);
			return;
		}

		// If the results aren't locally stored, we fetch new ones
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_album_recommendations,
			data: {album_hash: hash_id},
			success: function(data){
				if (data.status === 'success') {
					const recommendations_data = {
						'recommended_artist': data.data.recommended_artist,
						'recommended_albums': data.data.recommended_albums
					};
					this.setState(recommendations_data);

					// Now, we save the recommendations results in local storage with a 1 week ttl
					storage.set('recommendations_album_' + hash_id, recommendations_data, 604800);
				}
			}.bind(this),
			complete: function(){
				store_set('loading_overlay_display', false);
			},
		});
	}

	see_more(song){
		// album_info dict has incomplete info on its song array, so we quickly fetch the song_info
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/song/',
			data: {hash_id: song.spotify_id},
			success: function(data){
				if (data.status === 'success') {
					store_set('song_info', data);
					store_set('active_overlay', 'track_options');
					store_set('active_overlay_option', 'show_all_track');
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			},
		});
	}

	find_similar_button(seed){
		store_set('seed', seed);
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_top_text', 'Now searching similar to:');
		store_set('loading_overlay_text', seed.title);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		queue.get_recommendations(seed.spotify_id, seed.type, '', true);
		store_set('active_overlay', false);
	}

	render() {
		const album = this.props.album_info;
		const { recommended_artist, recommended_albums } = this.state;

		if (!album.spotify_id || album.spotify_id !== this.state.album_hash_id) {
			return(
				<div></div>
			);
		} else {
			return(
				<React.Fragment>
					<div className='album-container'>
						<div className='album-main'>
							<Img
								src={[album['images']['large'], default_album_image]}
								loader={
									<ContentLoader 
										width={cover_size}
										height={cover_size}
										backgroundColor={'#D8D8D8'}
										foregroundColor={'#b1afaf'}
									>
										<rect x='0' y='0' rx='0' ry='0' width={cover_size} height={cover_size} /> 
									</ContentLoader>
								}
								className='album-main-image'
							/>
							<div className='album-content' id='album-content'>
								<div className='album-info'>
									<div className='album-artist'>{album.artist.name}</div>
									<div className='album-title'>{album.title}</div>
								</div>
								<div className='album-options'>
									<div className='album-options-double-button'>
										<Ripples>
											<Haptic intesity='light'>
												<div className='album-options-item-left' onClick={() => {event_bus.emit('play_full_album');}}>Play Full Album</div>
											</Haptic>
										</Ripples>
										<span className='album-options-middle-line' />
										<Ripples>
											<Haptic intesity='light'>
												<div className='album-options-item-right' onClick={() => this.find_similar_button(album)}>Find Similar</div>
											</Haptic>
										</Ripples>
									</div>
								</div>
							</div>
						</div>
						{album.songs && album.songs.length > 0 &&
							<div className='album-songs-container'>
								{album.songs.map((song, index) => (
									<React.Fragment key={index}>
										{index === 2 ? <AdUnit height={60} /> : null}
										<Link to={'/track/' + song.spotify_id} className='album-song-card'>
											<div className='album-song-card-info'>
												<div className='album-song-card-title'>{song.title}</div>
												<div className='album-song-card-artist'>{album.artist.name}</div>
											</div>
											<span className='material-icons' onClick={(e) => {this.see_more(song); e.stopPropagation(); e.preventDefault();}}>more_vert</span>
										</Link>
									</React.Fragment>
								))}
							</div>
						}
						{recommended_artist && recommended_artist.length > 0 &&
							<div className='album-recommended-section'>
								<div className='album-recommended-title'>More from {album.artist.name}:</div>
								<div className='album-recommended-container'>
									{recommended_artist.map((item, index) => {
										if (item.type === 'song') {
											return(
												<Card bodyLink={'/track/' + item.spotify_id} data={item} type='big_card_song' displayLabel={true} key={index} onMoreClick={() => {store_set('active_overlay', 'track_options'); store_set('song_info', item); }}/>
											);
										}
										return(
											<Link key={index} to={'/album/' + item.spotify_id}>
												<Card data={item} type='big_card_album' displayLabel={true} key={index} />
											</Link>
										);
									})}
								</div>
							</div>
						}
						{recommended_albums && recommended_albums.length > 0 &&
							<div className='album-recommended-section'>
								<div className='album-recommended-title'>More albums like this:</div>
								<div className='album-recommended-container'>
									{recommended_albums.map((album, index) => (
										<Link key={index} to={'/album/' + album.spotify_id}>
											<Card data={album} type='big_card_album' displayLabel={true} key={index} />
										</Link>
									))}
								</div>
							</div>
						}
					</div>
				</React.Fragment>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		album_info: state.GlobalReducer.album_info,		
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(Album));
