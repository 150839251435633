const stripe_publishable_key_dev = 'pk_test_51JK80nDhS7ybAq9lyq8j8Izq4XcVIbTDGukxe6GlCwhJkffKmdSbqrrvxuWtMRLvITKKdyGUHa0bAHCTebAwbelM00aXjZTQ2O';
const stripe_publishable_key_live = 'pk_live_51JK80nDhS7ybAq9lOA1mWBcncnsAutgRESwUhdz04PzmzNJMXpI3tP9NgjhzzU92W9wfXbTJzeIfr1b9jK77e4D100JPRagoLE';

const default_state = {
	version: {},
	version_overlay: false,
	downloading_new_version: false,
	download_progress: 0,
	ready_to_reload: false,
	invite_to_update: false,

	mute_switch_on: false,
	dismiss_mute_switch_message: false,
	status_online: true,
	logging_in: false,
	user_token: false,
	user: {},
	profile_info: {},
	user_info_date: false,
	fine_tunning: {},
	just_login: true,
	home_type: false,
	link_account_loading: false,
	linked_accounts: [],
	spotify_playlists: [],
	deezer_playlists: [],
	synced_playlists: [],
	location: 'home',
	navigation_location: 'home',
	current_page: '/',
	previous_page: '/',
	display_player: true,
	is_playing: false,
	playing_song: false,
	free_like_dislike: false,
	duration: 0,
	song_info: {
		'type': 'song',
		'album': {
			'images': {}
		},
		'artist':{}
	},
	album_info: {
		'artist': {},
		'images': {},
		'type': 'album',
		'songs': []
	},
	obssesion_info: {
		'type': 'song',
		'album': {
			'images': {}
		},
		'artist':{}
	},
	playlist_info: {
		'type': 'playlist',
		'hash_id': 0,
		'name': '',
		'image_link': '',
		'description': '',
		'is_active': false,
		'dsps': [],
		'spotify_id': '',
		'deezer_id': '',
		'songs': [],
		'created_at': ''
	},
	artist_info: {
		'type': 'artist',
		'images': {},
		'name': '',
		'spotify_id': ''
	},
	track_info: {
		'type': 'song',
		'album': {
			'images': {}
		},
		'artist':{}
	},
	recommendation_info: {
		'hash_id': '',
		'user': {},
		'message': '',
		'recommendation': {
			'is_recommendation': true,
		},
		'interaction': false,
		'created_at': ''
	},
	active_overlay: false,
	secondary_overlay: false,
	premium_overlay: false,
	active_overlay_option: 'show_all_track',
	playlist_overlay_option: 'show_all',
	loading_overlay_display: false,
	loading_overlay_component: 'circular-progress',
	loading_overlay_top_text: false,
	loading_overlay_text: 'Now Searching...',
	loading_overlay_action: ['CONFIRM', 'CANCEL'],
	seed: {'name': 'Custom', 'type': ''},
	user_synced: 'none',
	suggestions: [],
	suggestions_loading: true,
	recent_seeds: [],
	queue: [],
	active_playlist: false,
	playlists: [],
	new_likes: false,
	new_likes_list: [],
	likes: [],
	tracks_selected: [],
	loading_older_likes: false,
	error_loading_older_likes: false,
	my_list_display: 'list_view',
	hide_footer: false,
	streaming_overlay: '',
	click_and_hold_event: true,
	display_licenses_step: false,
	display_beta_message: false,
	safe_area_top: 0,
	see_all_artists: false,
	see_all_songs: false,
	see_all_albuns: false,
	search_query: '',
	expand_search: false,
	search_progress: false,
	total_search_progress: 0,
	display_results: false,
	reset_value: false,
	input_changed: false,
	previous_seed: {},
	current_streak: 0,
	current_streak_total_songs: 0,
	current_streak_total_time: 0,
	redirected_bubble: false,
	similar_songs: [],
	spotify_stats: false, // Changes after fetching data on Profile Page
	magroove_stats: false,
	magroove_stats_version: false,
	user_leaderboard: [],
	likeminded_users: [], 
	friends_recommendations: [], // track recommendations from friends / visitors on profile
	my_recommendations: [], // tracks recommendations by user to friends / visited profiles
	selected_search_items: [],
	already_selected_search_items: [],
	trending_artists: [],
	trending_songs: [],
	device_info: {},

	poll_info: {},
	polls: [],

	// Hub
	hub_date: false,
	loading_social_activity: false,
	loading_user_favorites: false,
	loading_new_releases: false,
	social_activity: [],
	user_favorites: [],
	new_releases: [],
	following_users: [],
	people_you_may_know: [],
	following_artists: [],
	followers: [],

	// Premium Subscription keys
	last_day_recommendations_total: 0,
	last_day_first_interaction: '',
	member_subscription: false,
	stripe_publishable_key: process.env.NODE_ENV === 'development' ? stripe_publishable_key_dev : stripe_publishable_key_live,

	// Footer
	is_footer_progress: false, // Hides regular footer and shows progress footer
	footer_progress_length: 2, // Number of dots ('steps') on a progress footer
	footer_progress_index: 0, // Current active dot on progress footer
	footer_progress_allow_next: false, // Disallows click on Next button

	recommendation_return_page: '/',

	is_member_premium: undefined,
	premium_subsription_expires_at: false,
	premium_monthly_subscription_price: 1,
	premium_yearly_subscription_price: 10,
	premium_subscription_ID: '82be2f76', // Android
	premium_subscription_ID_ios: 'a4dbc5db', // iOS
	premium_sponsored_recommendation_interval: 5,
	premium_waiting_tracks_to_premium_invite:3,
	premium_max_swipes_per_day: 20,
	subscription_codes: {
		'android': [],
		'ios': []
	},

	// A/B tests
	ab_pricing_test: false,
	ab_premium_overlay_test: false,
	premium_overlay_type: 'default',

	// Blocklist
	blocklist: [],
	loading_blocklist: false,

	// Notifications
	notifications: [],
	loading_notifications: false,
	clearing_notifications: false,

	// Quiz
	quiz: undefined,
	edit_quiz: false,
	user_quiz: [],
	show_case_scroll: 0,
};

function GlobalReducer(state = default_state, action) {
	let new_state = Object.assign({}, state);
	switch (action.type) {
	case 'STORE_SET':
		new_state[action.key] = action.value;
		return new_state;

	case 'UPDATE_STORE':
		for (var [key, value] of Object.entries(action.dict)) {
			new_state[key] = value;
		}
		return new_state;

	case 'RESET_STORE':
		// Prevent some states from reseting
		default_state.version_overlay = new_state.version_overlay;
		default_state.display_beta_message = new_state.display_beta_message;
		default_state.safe_area_top = new_state.safe_area_top;
		return default_state;

	case 'UPDATE_PROPS':
		// eslint-disable-next-line
		for (let prop of Object.keys(default_state)) {
			if (new_state[prop] === undefined) {
				new_state[prop] = default_state[prop];
			}
		}
		return new_state;

	default:
		return state;
	}

}

export default GlobalReducer;
