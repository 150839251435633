import React from 'react';

// Swipe gesture
import { useSpring, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';

// CSS Imports
import './styles/Slider.css';

function Slider(props){
	/*
		backgroundColor: string 
		icon: string (material icon)
		text: string
		height: number
		marginBottom: number
		action: function (When swiped out)
		disabled: boolean
	*/

	// Create a spring with initial position (0,0)
	var [{ x }, update_card_position] = useSpring(() => ({ x: 0 }));

	// We also create another spring to the second animation (card behind)
	var [{ height, marginBottom, display }, update_styles] = useSpring(() => (
		{ height: props.height, marginBottom: props.marginBottom, display: 'flex' }
	));

	var swiped_out = false;

	var half_screen = 0.5*window.innerWidth;

	// Create a gesture, we're interested in down-state and delta (current-pos - click-pos)
	var onClick = useGesture(({ down, delta: [x_position], initial: [click_x] }) => {
		/*
			args: arguments we passed to the onClick function (eg. args: [index])
			down: true when a mouse button or touch is down
			delta: movement delta (movement - previous movement)
			intial: xy value when the gesture started
		*/
		if (x_position < 0) return; // We only animate for the right side
		if (swiped_out) return; // If the user keeps the finger down after swiped out, prevent new animation
		if (click_x > half_screen) return; // Does not trigger if click was on right side of card

		// If the user slides the card more than half the screen it should trigger the card to fly out
		var trigger = x_position > half_screen;
		swiped_out = !down && trigger;
		if (swiped_out){
			// Do the action passed to the component
			props.action();

			// After the card slides off the screen, we will do one more animation: decrease the height
			// of the card at the back. To do this, we'll also hide the content and the margin.
			setTimeout(function(){
				update_styles({ display: 'none', height: 0, marginBottom: 0 });
			}, 200);
		}

		update_card_position({ x: swiped_out ? window.innerWidth : down ? x_position : 0, duration: swiped_out ? 200 : ''});
	});

	if (props.disabled){
		return props.children;
	}
	return(
		<animated.div {...onClick()} className="slider-container" style={{ height, marginBottom }}>
			<animated.div
				className="slider-container-behind"
				style={{ backgroundColor: props.backgroundColor }}
			>
				<animated.div className="slider-container-behind-content" style={{ display }}>
					<span className="material-icons">{props.icon}</span><span>{props.text}</span>
				</animated.div>
			</animated.div>
			<animated.div className="slider-container-front" id={props.id} style={{ x }}>
				{props.children}
			</animated.div>
		</animated.div>
	);
}

function areEqual(prevProps, nextProps) {
	return prevProps.song_id === nextProps.song_id;
}

const SliderMemo = React.memo(Slider, areEqual);
export default SliderMemo;
