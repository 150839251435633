import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import CircularProgress from '@material-ui/core/CircularProgress';

// Import style
import './styles/Poll.css';

// Import components
import { Checkbox, TextInput } from '@magroove/magroove-ui';
import UploadImage from '@components/UploadImage.js';
import Card from '@components/Card.js';

// Import actions
import { store_set, store_get } from '@actions/GlobalActions.js';
import { request_buffer } from '@RequestBuffer';
import { redirect } from '@routes/Routes.js';

import * as constants from '@constants';

function display_loading_overlay(text = 'Loading...', top_text = false){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

class Poll extends Component {
	constructor(props){
		super(props);
		this.state = {
			step: 0,
			value: '',
			entities: [],
			loading: false,
			image: ''
		};

		this.finish = this.finish.bind(this);
		this.handle_checkbox = this.handle_checkbox.bind(this);
		this.on_new_image_success = this.on_new_image_success.bind(this);

		const hash_id = this.props.url ? this.props.url.params.hash_id : false;
		if (this.props.location === 'poll' && hash_id && hash_id !== this.props.poll_info.hash_id){
			this.state.loading = true;
			const current_poll = this.props.polls.find(p => p.hash_id === hash_id);
			store_set('poll_info', current_poll);
			setTimeout(() => this.setState({loading: false}), 5000);
		}
	}

	shouldComponentUpdate(nextProps){
		if (
			this.props.location === 'poll' &&
			this.props.polls.length !== nextProps.polls.length &&
			this.state.loading
		){
			const hash_id = this.props.url ? this.props.url.params.hash_id : false;
			if (hash_id){
				const current_poll = nextProps.polls.find(p => p.hash_id === hash_id);
				store_set('poll_info', current_poll);
			}
		}
		return true;
	}

	attach_file(){
		const element = document.querySelector('#upload-image-ref');
		if (element) element.click();
	}

	handle_checkbox(entity){
		const entities = [...this.state.entities];
		if (entities.includes(entity)){
			this.setState({ entities: entities.filter(item => item !== entity )});
		}
		else {
			this.setState({ entities: [...entities, entity ]});
		}
	}

	finish(){
		const { value, entities, image } = this.state;
		const item = {message: value, entities: entities.join(','), image: image};

		display_loading_overlay('Creating poll...');

		request_buffer.auth_execute({
			type: 'POST',
			url: constants.api_endpoint_create_poll,
			data: { poll: JSON.stringify(item)},
			success: function(res){
				if (res.status === 'success'){
					const polls = store_get('polls');
					const user = store_get('user');
					const now = moment(new Date());
					item.replies = [];
					item.hash_id = res.data.hash_id;
					item.created_at = now.format('h:mm A • MMMM DD, YYYY');
					item.author = true;
					item.user = {
						hash_id: user.hash_id,
						picture: user.picture,
						name: user.first_name + ' ' + user.last_name,
						slug: user.slug,
					};
					store_set('polls', [item, ...polls]);

					display_snackbar('Success!');
					redirect('/profile/');
				}
				else {
					display_snackbar('Sorry, we got an error...');
				}
			},
			error: function(){
				display_snackbar('Something went wrong. Please, try again...');
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}

	on_card_more_click(item){
		let key, overlay, overlay_option;
		if (item.recommendation.type === 'artist'){
			key = 'artist_info';
			overlay = 'artist_options';
			overlay_option = 'show_all_artist';
		} else if (item.recommendation.type === 'song'){
			key = 'song_info';
			overlay = 'track_options';
			overlay_option = 'show_all_track';
		} else {
			key = 'album_info';
			overlay = 'album_options';
			overlay_option = 'show_all_album';
		}

		store_set(key, item.recommendation);
		store_set('active_overlay', overlay);
		store_set('active_overlay_option', overlay_option);			
	}

	on_new_image_success(data){
		const { image_link } = data;
		setTimeout(() => {
			store_set('loading_overlay_display', false);
			this.setState({
				image: image_link
			});
		}, 1000);
	}

	render() {
		const { poll_info, user } = this.props;
		const { entities, image, step, value, loading } = this.state;

		if (this.props.location === 'poll' && !poll_info.replies && loading){
			return (
				<div className='poll-loading'>
					<CircularProgress />
				</div>
			);
		}
		if (this.props.location === 'poll'){
			return (
				<div className='poll-content'>
					<Card type='poll_card' data={poll_info} user={user} secondary={true} />
					<div className='poll-replies'>Replies</div>
					{poll_info.replies.map((item) => (
						<Card 
							key={item.hash_id}
							type='quiz_card' 
							dataItem={item}
							userLabel={true}
							displayButtons={true}
							onMoreClick={() => this.on_card_more_click(item)}
						/>
					))}
				</div>
			);
		}

		const next_available = (step === 0 && value.length > 0) || (step === 1 && entities.length > 0);
		return (
			<div className='poll'>
				{step === 0 &&
					<React.Fragment>
						<TextInput
							theme='dark'
							label='Ask for a recommendation for any type of music you want to discover more about:'
							placeholder='Ex.: Send me some songs to lay in bed crying'
							onChange={(value) => this.setState({value: value})}
							value={value}
						/>
						{image.length > 0 &&
							<div className='poll-image'>
								<span className='material-icons' onClick={() => this.setState({image: ''})}>close</span>
								<img src={image} alt='' />
							</div>
						}
						<UploadImage type='poll' prefix='poll/' onSuccess={this.on_new_image_success} />
					</React.Fragment>
				}
				{step === 1 &&
					<div className='poll-section'>
						<div className='poll-title'>What kind of recommendation you want to get?</div>
						<div className='poll-type-options'>
							<Checkbox label='Artist' onChange={() => this.handle_checkbox('artist') } checked={entities.includes('artist')}/>
							<Checkbox label='Album' onChange={() => this.handle_checkbox('album') } checked={entities.includes('album')}/>
							<Checkbox label='Song' onChange={() => this.handle_checkbox('song') } checked={entities.includes('song')}/>
						</div>
					</div>
				}
				{step === 2 &&
					<div className='poll-section'>
						<div className='poll-title'>Check how is gonna look for your friends:</div>
						<div className='poll-subtitle'>Your friend is asking</div>
						<Card type='poll_card' preview={true} data={{user: this.props.user, message: value, image: image, replies: []}} user={this.props.user} />
					</div>
				}
				<div className='poll-footer'>
					{step === 0 ?
						<div className='poll-button' onClick={this.attach_file}>Attach file</div>
						:
						<div className='poll-button' onClick={() => this.setState({step: step - 1})}>Back</div>
					}
					<div className='poll-steps'>
						{Array.from({ length: 3 }).map((_, i) =>(
							<div
								key={i}
								className='poll-steps-dot'
								style={step === i ? {width: 24, opacity: 1} : {}}
							></div>
						))}
					</div>
					{step === 2 ?
						<div className='poll-button' onClick={this.finish}>Finish</div>
						:
						<div className='poll-button' onClick={next_available ? () => this.setState({ step: step + 1 }) : () => {}} style={{opacity: next_available ? 1 : 0.3}}>Next</div>
					}
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		location: state.GlobalReducer.location,
		polls: state.GlobalReducer.polls,
		poll_info: state.GlobalReducer.poll_info,
		previous_page: state.GlobalReducer.previous_page
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Poll));