import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Ripples from 'react-ripples';
import { Img } from 'react-image';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { Link } from 'react-router-dom';

// Import components
import Haptic from '@components/haptic.js';
import Hub from '@components/Hub.js';
import NotificationsIcon from '@components/NotificationsIcon.js';
import ContentLoader from '@components/ContentLoader.js';
import MarqueeText from 'react-marquee-text-component';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { CircularSliderWithChildren } from 'react-circular-slider-svg';

// Swipe gesture
import { useSprings, animated, to } from 'react-spring';
import { useGesture } from 'react-use-gesture';

// import the controllers
import player from '@Player';
import user from '@User';
import interaction from '@Interaction';
import subscription from '@Subscription';
import queue from '@Queue';

// CSS Imports
import 'react-circular-progressbar/dist/styles.css';
import './styles/Home.css';

// Assets Imports
import * as assets from '@Assets';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Constants import
import * as constants from '@constants';

// Functions Imports
import { share } from '@views/Header.js';

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

class HomePlaylists extends Component {
	render() {
		const { active_playlist } = this.props;
		const title = active_playlist ? active_playlist.name : 'Select Playlist';
		const member_is_premium = subscription.is_valid();
		return (
			<div className='home-playlists' onClick={!member_is_premium ? () => subscription.invite('Discover much more and get extra control over your songs!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'playlists_home') : () => { store_set('active_overlay', 'playlists_options'); store_set('playlist_overlay_option', 'show_internal_playlists'); }}>
				<span className='material-icons'>playlist_add</span>
				<div className='home-playlists-text'>{title}</div>
			</div>
		);
	}
}

class Header extends Component {
	render() {
		return (
			<React.Fragment>
				{this.props.home_type === 'B' &&
					<div className='home-header-b-background'></div>
				}
				<header className={this.props.home_type === 'default' ? 'home-header' : 'home-header-alternative'} style={!this.props.playing_song ? { position: 'absolute', justifyContent: 'flex-end', backgroundImage: 'none' } : {}}>
					{this.props.playing_song &&
						<div className='home-header-left-side'>
							<Link to={this.props.seed.type === 'song' ? '/track/' + this.props.seed.spotify_id : '/'}>
								<img src={assets.seed_icon} alt='Seed icon' />
								<div className='home-header-seed'>
									{this.props.seed && !(this.props.seed.type === 'song' || this.props.seed.type === 'album') &&
										<React.Fragment>
											<div className='home-header-title'>Similar to</div>
											<div className='home-header-info'>{this.props.seed.name}</div>
										</React.Fragment>
									}
									{this.props.seed && (this.props.seed.type === 'song' || this.props.seed.type === 'album') &&
										<React.Fragment>
											<div className='home-header-title'>Similar to</div>
											<div className='home-header-info'>{capitalize(this.props.seed.title)} - {this.props.seed.artist.name}</div>
										</React.Fragment>
									}
								</div>
							</Link>
						</div>
					}
					<div className='home-header-right-side'>
						{this.props.playing_song &&
							<span className='material-icons' style={{ marginRight: 15 }} onClick={() => { store_set('active_overlay', 'track_options'); store_set('song_info', this.props.playing_song); }}>more_vert</span>
						}
						<NotificationsIcon />
					</div>
				</header>
			</React.Fragment>
		);
	}
}

class HomeButtons extends Component {
	render() {
		const interaction_available = this.props.free_like_dislike || this.props.current_time > constants.minimum_waiting_time;
		const ab_home = this.props.home_type === 'A' || this.props.home_type === 'B';
		return (
			<div className='home-buttons'>
				<Ripples onClick={() => interaction_available ? interaction.dislike() : {}}>
					<Haptic intesity='light' disable={!interaction_available}>
						<div className='home-dislike-button' style={{ opacity: interaction_available ? 1 : 0.2, width: ab_home ? 60 : 80, marginRight: ab_home ? 0 : 16 }}>
							<span className='material-icons' style={ab_home ? { color: '#fff', fontSize: 30, fontWeight: 'normal' } : {}}>clear</span>
						</div>
					</Haptic>
				</Ripples>
				{!ab_home &&
					<Ripples>
						<Haptic intesity='light'>
							<div className='home-play-pause-button' onClick={interaction.undo}>
								<span className='material-icons'>undo</span>
							</div>
						</Haptic>
					</Ripples>
				}
				{this.props.home_type !== 'B' &&
					<Ripples>
						<Haptic intesity='light'>
							<div className='home-play-pause-button' onClick={player.toggle}
								style={ab_home ? { backgroundColor: '#232323', width: 40, margin: 0 } : {}}
							>
								<span className='material-icons' style={ab_home ? { fontSize: 25, color: '#fff' } : {}}>{this.props.is_playing ? 'pause' : 'play_arrow'}</span>
							</div>
						</Haptic>
					</Ripples>
				}
				<Ripples onClick={() => interaction_available ? interaction.like() : {}}>
					<Haptic intesity='light' disable={!interaction_available}>
						<div className='home-like-button' style={{ opacity: interaction_available ? 1 : 0.2, width: ab_home ? 60 : 80, marginLeft: ab_home ? 0 : 16 }}>
							<span className='material-icons' style={ab_home ? { color: '#fff', fontSize: 30 } : {}}>favorite</span>
						</div>
					</Haptic>
				</Ripples>
			</div>
		);
	}
}

class HomeFooter extends Component {
	constructor(props) {
		super(props);

		this.find_similar = this.find_similar.bind(this);
	}

	blocklist() {
		if (this.props.is_member_premium) {
			store_set('active_overlay', 'track_options');
			store_set('active_overlay_option', 'blocklist');
			store_set('song_info', this.props.playing_song);
		} else {
			subscription.require('block', 'Subscribing now grants you artists and albums in the blocklist!', 'blocklist');
		}
	}

	find_similar(){
		const seed = this.props.playing_song;
		store_set('seed', seed);
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_top_text', 'Now searching similar to:');
		store_set('loading_overlay_text', seed.title);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		queue.get_recommendations(seed.spotify_id, seed.type, '', true);
		store_set('active_overlay', false);
	}

	render() {
		return (
			<div className='home-footer-options'>
				<div className='home-footer-options-item' onClick={this.find_similar}>
					<img src={assets.find_similar_icon} alt='find-similar-icon' />
					<div className='home-footer-options-item-text'>Find Similar</div>
				</div> |
				<div className='home-footer-options-item' onClick={() => share(this.props.playing_song)}>
					<span className='material-icons'>share</span>
					<div className='home-footer-options-item-text'>Share</div>
				</div> |
				<div className='home-footer-options-item' onClick={interaction.undo}>
					<span className='material-icons'>undo</span>
					<div className='home-footer-options-item-text'>Undo</div>
				</div>
			</div>
		);
	}
}

function HomeHooks(props) {
	// The list flags all the cards that are flicked out
	var swiped_covers = [];

	// Create a bunch of springs with initial position (0,0)
	// eslint-disable-next-line no-unused-vars
	var [cover_springs, update_cover_position] = useSprings(props.queue.length + 1, index => ({ x: 0, y: 0, rot: 0 }));
	const [direction, setDirection] = useState('down');
	
	useEffect(() => {
		const homeScrollContainer = document.getElementById('home-scroll-container');
		let prevScrollPosition = homeScrollContainer.pageYOffset || homeScrollContainer.scrollTop;
	
		const handleScroll = () => {
			const currentScrollPosition = homeScrollContainer.pageYOffset || homeScrollContainer.scrollTop;
	
			if (currentScrollPosition > prevScrollPosition) {
				setDirection('down');
			} else if(currentScrollPosition < prevScrollPosition) {
				setDirection('up');
			}
	
			prevScrollPosition = currentScrollPosition;
		};
	
		window.addEventListener('scroll', handleScroll);
		window.addEventListener('touchmove', handleScroll);
	
		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.addEventListener('touchmove', handleScroll);
		};
	}, []);

	useEffect(() => {
		const homeScrollContainer = document.getElementById('home-scroll-container');

		if (homeScrollContainer) {
			homeScrollContainer.classList.remove('home-scroll-container-scroll-up');
			homeScrollContainer.classList.remove('home-scroll-container-scroll-down');
			homeScrollContainer.classList.add(`home-scroll-container-scroll-${direction}`);
		}
	}, [direction]);

	// Update interaction visual feedback
	var [feedback, setFeedback] = useState(null);
	var update_feedback = (rot, down, swiped_out) => {
		if (rot < -40) {
			setFeedback('dislike');
		} else if (rot > 40) {
			setFeedback('like');
		} else if (down || !swiped_out) {
			setFeedback(null);
		} else {
			setTimeout(() => setFeedback(null), constants.swipe_animation_duration + 600);
		}
	};

	// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
	// eslint-disable-next-line no-unused-vars
	var onClick = useGesture(({ args: [queue_length], down, initial: [x_inital, y_initial], delta: [x_position], velocity }) => {
		/*
		args: arguments we passed to the onClick function
		down: true when a mouse button or touch is down
		delta: movement delta (movement - previous movement)
		velocity: absolute velocity of the gesture
		*/
		if (y_initial > window.innerHeight / 2) return;

		// Since we only want the last cover, subtract the swiped covers length from the queue size to get the index 
		var index = queue_length - swiped_covers.length;

		var x_distance_from_center = Math.abs(x_position);

		// If the user swipe hard enough or far enough it should trigger the card to fly out
		var trigger = x_distance_from_center > 0.2 * window.innerWidth;

		// Direction should either point left or right
		var x_direction = x_position < 0 ? -1 : 1;

		// If button/finger's up and the trigger is reached, we flag the card ready to fly out.
		// NOTE: Swipe animation should still happen when controls are locked, it should just never trigger the
		// interaction - it should always go back to the center.
		if (!down && trigger && (props.free_like_dislike || props.current_time > constants.minimum_waiting_time)) swiped_covers.push(index);

		// OBS: This function runs for each spring
		update_cover_position(idx => {
			// We're only interested in changing spring-data for the current spring
			if (index !== idx) return;

			// Check the cover state
			var swiped_out = swiped_covers.includes(index);

			var x, y;
			// Now let's define the new position
			if (swiped_out) {
				// 1 - When the cover was swiped out
				// In this case, we set the final position (where the cover must go with the animation). Also do the
				// interaction action. Add a delay so the animation can finish before the component re-render because
				// of the new queue state.
				x = x_direction * (window.innerWidth + 200);
				y = 150;
				if (x_direction < 0) interaction.dislike(constants.swipe_animation_duration + 600);
				else interaction.like(constants.swipe_animation_duration + 600);
				interaction.stop_countdown();
			} else if (down) {
				// 2 - When the user is still with the finger down
				// On the x-axis, the cover will follow the position of the gesture. For the y-axis, we will create
				// an animation that appears the cover is falling, but not so tilted.
				// Here, we set how much the cover must go horizontally before going down.
				var horizontal_distance = 0.4 * window.innerWidth;
				x = x_position;
				y = x_distance_from_center > horizontal_distance ? (x_distance_from_center - horizontal_distance) / 2 : 0;
			} else {
				// 3 - Otherwise goes back to zero
				x = 0;
				y = 0;
			}

			// Here we set the animation duration. If the cover was swiped out, use the time in constants.py
			// Otherwise, set to 300 so it can animate the cover back to the initial position. (If the finger 
			// is still pressed, don't set the duration)
			var duration = swiped_out ? constants.swipe_animation_duration : down ? '' : 300;

			// Finally, set how much the cover rotates. If the cover is gone, will be a complete rotation according
			// to the direction.  Otherwise, just use the x position to set the rotation.
			var rot = swiped_out ? x_direction * 360 : down ? x_position : 0;

			// Updates the visual feedback on cover image
			var rot_pos = swiped_out ? null : rot;
			update_feedback(rot_pos, down, swiped_out);

			// Return the new spring-data
			return { x, y, rot, config: { duration } };
		});
	});

	var covers = [...props.queue.map(track => track.album.images.large), props.playing_song.album.images.large];
	var first_cover = props.queue.length - swiped_covers;

	const feedback_styles = {
		like: {
			backgroundColor: '#05a65026',
		},
		dislike: {
			backgroundColor: '#ec6b4326',
		},
		like_txt: {
			color: '#06a752',
			borderColor: '#06a752',
		},
		dislike_txt: {
			color: '#ec6b43',
			borderColor: '#ec6b43',
		}
	};

	const circular_size = window.innerWidth - (window.innerWidth > 375 ? 60 : 30);

	if (props.home_type === 'A') {
		return (
			<React.Fragment>
				<div className='home-content'>
					<div {...onClick(props.queue.length)} className='home-main-a'>
						<HomePlaylists {...props} />
						<div className='home-cover-main'>
							<div className='home-cover-main-feedback'>
								<div className='home-cover-main-feedback-chip' style={!feedback ? { opacity: 0 } : (feedback === 'like' ? feedback_styles.like_txt : feedback_styles.dislike_txt)}>
									{!feedback ? '' : (feedback === 'like' ? 'LIKE' : 'NOPE')}
								</div>
							</div>
							<div className='home-cover-container'>
								<Link
									to={'/track/' + props.playing_song.spotify_id}
								>
									<CircularSliderWithChildren
										size={circular_size}
										handle1={{ value: props.current_time * 100 / props.duration, onChange: () => { } }}
										angleType={{ direction: 'cw', axis: '-y' }}
										startAngle={30}
										endAngle={330}
										arcColor='#ec6b43'
										arcBackgroundColor='#ffffff70'
									>
										{cover_springs.map(({ x, y, rot }, index) => {
											return (
												<animated.div className='home-cover-animated-container' key={index} style={{ x, y }}>
													<animated.div
														className='home-cover-animated'
														style={{
															opacity: first_cover === index ? 1 : 0,
															transform: to([rot], (rot) => `rotate(${rot}deg)`),
															border: props.playing_song.sponsored ? '2px solid #ec6b43' : '',
															width: 270,
															height: 270
														}}
													>
														<animated.div
															className='home-cover-animated-feedback'
															style={first_cover === index && feedback ? (feedback === 'like' ? feedback_styles.like : feedback_styles.dislike) : { opacity: 0 }}
														>
														</animated.div>
														<Img
															className='home-cover-image'
															src={[covers[index], assets.default_album_image]}
															loader={
																<ContentLoader style={{ height: 'calc(100% - 20px)', width: 'calc(100% - 20px)', borderRadius: '100%' }} />
															}
														/>
													</animated.div>
												</animated.div>
											);
										})}
										<div className='home-main-a-time'>
											<div className='home-main-a-current-time'>{Math.floor(props.current_time % 3600 / 60).toString().padStart(2, '0') + ':' + Math.floor(props.current_time % 60).toString().padStart(2, '0')}</div>
											<span className='home-main-a-duration-time'>/</span>
											<div className='home-main-a-duration-time'>{Math.floor(props.duration % 3600 / 60).toString().padStart(2, '0') + ':' + Math.floor(props.duration % 60).toString().padStart(2, '0')}</div>
										</div>
									</CircularSliderWithChildren>
								</Link>
							</div>
						</div>
						<div className='home-a-track-info'>
							{props.playing_song.sponsored &&
								<div className='home-sponsored-tag'>
									<span>SPONSORED</span>
									<span className='material-icons-sharp'>campaign</span>
								</div>
							}
							<Link to={'/track/' + props.playing_song.spotify_id} className='home-track-title-link'>
								{props.playing_song.title.length > 18 ?
									<MarqueeText
										className='home-a-track-title'
										text={props.playing_song.title}
										duration={10}
										repeat={10}
									/>
									:
									<div className='home-a-track-title'>{props.playing_song.title}</div>
								}
							</Link>
							<Link to={'/artist/' + props.playing_song.artist.spotify_id} className='home-track-title-link'>
								<div className='home-a-track-artist'>{props.playing_song.artist.name}</div>
							</Link>
						</div>
						<HomeButtons {...props} />
						<HomeFooter {...props} />
					</div>
				</div>
				<div className='home-main-a-background' style={{ backgroundImage: 'url(' + covers[first_cover] + ')' }}>
					<div className='home-main-a-background-feedback' style={!feedback ? {} : (feedback === 'like' ? { backgroundColor: 'rgba(6, 167, 82, 0.5)' } : { backgroundColor: 'rgba(236, 107, 67, 0.5)' })}></div>
				</div>
			</React.Fragment>
		);
	}

	if (props.home_type === 'B') {
		return (
			<React.Fragment>
				<div className='home-content'>
					<div {...onClick(props.queue.length)} className='home-main-b'>
						<HomePlaylists {...props} />
						<div className='home-cover-main'>
							<div className='home-cover-main-feedback'>
								<div className='home-cover-main-feedback-chip' style={!feedback ? { opacity: 0 } : (feedback === 'like' ? feedback_styles.like_txt : feedback_styles.dislike_txt)}>
									{!feedback ? '' : (feedback === 'like' ? 'LIKE' : 'NOPE')}
								</div>
							</div>
							<div className='home-player-container'>
								<CircularSliderWithChildren
									size={window.innerWidth - 90}
									handle1={{ value: props.current_time * 100 / props.duration, onChange: () => { } }}
									angleType={{ direction: 'cw', axis: '+y' }}
									arcColor='#ec6b43'
									arcBackgroundColor='#ffffff20'
								>
									{feedback ? null :
										<Ripples>
											<Haptic intesity='light'>
												<div className='home-play-pause-button' onClick={(e) => { e.stopPropagation(); player.toggle(); }}>
													<span className='material-icons'>{props.is_playing ? 'pause' : 'play_arrow'}</span>
												</div>
											</Haptic>
										</Ripples>
									}
								</CircularSliderWithChildren>
							</div>
						</div>
						<div className='home-a-track-info'>
							{props.playing_song.sponsored &&
								<div className='home-sponsored-tag'>
									<span>SPONSORED</span>
									<span className='material-icons-sharp'>campaign</span>
								</div>
							}
							<Link to={'/track/' + props.playing_song.spotify_id} className='home-track-title-link'>
								{props.playing_song.title.length > 18 ?
									<MarqueeText
										className='home-a-track-title'
										text={props.playing_song.title}
										duration={10}
										repeat={10}
									/>
									:
									<div className='home-a-track-title'>{props.playing_song.title}</div>
								}
							</Link>
							<Link to={'/artist/' + props.playing_song.artist.spotify_id} className='home-track-title-link'>
								<div className='home-a-track-artist'>{props.playing_song.artist.name}</div>
							</Link>
						</div>
						<HomeButtons {...props} />
						<HomeFooter {...props} />
					</div>
				</div>
				<div className='home-main-b-covers-container'>
					<div className='home-main-covers-feedback'>
						<div className='home-main-covers-feedback-background' style={!feedback ? {} : (feedback === 'like' ? feedback_styles.like : feedback_styles.dislike)}></div>
					</div>
					{cover_springs.map(({ x }, index) => {
						const active_cover = first_cover === index;
						return (
							<animated.div className='home-cover-animated-container' key={index} style={{ x }}>
								<animated.div
									className='home-cover-animated'
									style={{ display: active_cover ? 'flex' : 'none', backgroundColor: active_cover ? 'transparent' : '#101010' }}
								>
									<animated.div
										className='home-cover-animated-feedback'
										style={!feedback ? { backgroundColor: 'transparent' } : (feedback === 'like' ? feedback_styles.like : feedback_styles.dislike)}
									>
										<Img
											className='home-main-b-background'
											src={[covers[index], assets.default_album_image]}
											loader={
												<ContentLoader style={{ height: '100%', width: '100%' }} />
											}
										/>
									</animated.div>
								</animated.div>
							</animated.div>
						);
					})}
				</div>
			</React.Fragment>
		);
	}

	// Default Home
	return (
		<div className='home-default'>
			<div className='home-main-covers-feedback'>
				<div className='home-main-covers-feedback-background' style={!feedback ? {} : (feedback === 'like' ? feedback_styles.like : feedback_styles.dislike)}></div>
			</div>
			<div {...onClick(props.queue.length)} className='home-main'>
				<HomePlaylists {...props} />
				<div className='home-cover-main' >
					<div className='home-cover-main-feedback'>
						<div className='home-cover-main-feedback-chip' style={!feedback ? { opacity: 0 } : (feedback === 'like' ? feedback_styles.like_txt : feedback_styles.dislike_txt)}>
							{!feedback ? '' : (feedback === 'like' ? 'LIKE' : 'NOPE')}
						</div>
					</div>
					<div className='home-cover-container'>
						<Link
							to={'/track/' + props.playing_song.spotify_id}
						>
							<CircularProgressbarWithChildren value={(props.duration - props.current_time) * 100 / props.duration}>
								{cover_springs.map(({ x, y, rot }, index) => (
									<animated.div className='home-cover-animated-container' key={index} style={{ x, y }}>
										<animated.div
											className='home-cover-animated'
											style={{
												opacity: first_cover === index ? 1 : 0,
												transform: to([rot], (rot) => `rotate(${rot}deg)`),
												border: props.playing_song.sponsored ? '2px solid #ec6b43' : ''
											}}
										>
											<animated.div
												className='home-cover-animated-feedback'
												style={!feedback ? { opacity: 0 } : (feedback === 'like' ? feedback_styles.like : feedback_styles.dislike)}
											>
											</animated.div>
											<Img
												className='home-cover-image'
												src={[covers[index], assets.default_album_image]}
												loader={
													<ContentLoader style={{ height: 'calc(100% - 15vw)', width: 'calc(100% - 15vw)', borderRadius: '100%' }} />
												}
											/>
										</animated.div>
									</animated.div>
								))}
							</CircularProgressbarWithChildren>
						</Link>
					</div>
				</div>
				<div className='home-track-info'>
					{props.playing_song.sponsored &&
						<div className='home-sponsored-tag'>
							<span>SPONSORED</span>
							<span className='material-icons-sharp'>campaign</span>
						</div>
					}
					<Link to={'/track/' + props.playing_song.spotify_id} className='home-track-title-link'>
						{props.playing_song.title.length > 18 ?
							<MarqueeText
								className='home-track-title'
								text={props.playing_song.title}
								duration={10}
								repeat={10}
							/>
							:
							<div className='home-track-title'>{props.playing_song.title}</div>
						}
					</Link>
					<Link to={'/artist/' + props.playing_song.artist.spotify_id} className='home-track-title-link'>
						<div className='home-track-artist'>{props.playing_song.artist.name}</div>
					</Link>
				</div>
				<HomeButtons {...props} />
			</div>
		</div>
	);
}

class Home extends Component {
	// eslint-disable-next-line
	componentWillMount() {
		if (!this.props.home_type) {
			user.set_home_type();
		}
	}

	render() {
		if (this.props.playing_song) {
			return (
				<div className="home-scroll-container-scroll-down" id="home-scroll-container">
					<div className="home-container" id='home'>
						<Header {...this.props} />
						<HomeHooks {...this.props} />
						<Hub />
					</div>
				</div>
			);
		}
		return (
			<div className="home-scroll-container-scroll-down" id="home-scroll-container">
				<Header {...this.props} />
				<Hub />
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_playing: state.GlobalReducer.is_playing,
		duration: state.GlobalReducer.duration,
		current_time: state.GlobalReducer.current_time,
		playing_song: state.GlobalReducer.playing_song,
		queue: state.GlobalReducer.queue,
		safe_area_top: state.GlobalReducer.safe_area_top,
		free_like_dislike: state.GlobalReducer.free_like_dislike,
		active_playlist: state.GlobalReducer.active_playlist,
		playlists: state.GlobalReducer.playlists,
		home_type: state.GlobalReducer.home_type,
		seed: state.GlobalReducer.seed,
		recent_seeds: state.GlobalReducer.recent_seeds,
		likes: state.GlobalReducer.likes,
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(Home));
