import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/Playlist.css';

// Assets Imports
import * as assets from '@Assets';

// Constant Imports
import * as constants from '@constants';

// Components and Views Imports
import Ripples from 'react-ripples';
import Haptic from '@components/haptic.js';
import Card from '@components/Card.js';
import ContentLoader from '@components/ContentLoader.js';

// Actions import
import { store_set } from '@actions/GlobalActions.js';
import { request_buffer } from '@RequestBuffer';
import { redirect } from '@routes/Routes.js';

import social from '@Social';
import queue from '@Queue';

function display_loading_overlay(top_text, text){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

function find_similar_button(seed){
	store_set('seed', seed);
	display_loading_overlay('Now searching similar to:', seed.name);
	queue.get_recommendations(seed.hash_id, seed.type, '', true);
	store_set('active_overlay', false);
}

class Playlist extends Component {
	constructor(props){
		super(props);
		this.state = {};

		var path = window.location.href;
		var playlist_hash_id = null;

		if (path.includes('playlist')) {
			playlist_hash_id = this.props.url.params.playlist_hash_id;
			this.state.playlist_hash_id = playlist_hash_id;
		}

		this.follow = this.follow.bind(this);
		this.get_playlist_info = this.get_playlist_info.bind(this);
		this.on_owner_click = this.on_owner_click.bind(this);

		if (this.props.playlist_info.hash_id !== playlist_hash_id){
			display_loading_overlay(false, 'Loading...');
			this.get_playlist_info(playlist_hash_id);
		}
	}

	follow(){
		const playlist = Object.assign({}, this.props.playlist_info);
		const action = playlist.is_following ? 'unfollow' : 'follow';

		playlist.is_following = !playlist.is_following;
		if (action === 'follow'){
			playlist.followers = playlist.followers + 1;
		}
		else {
			playlist.followers = playlist.followers - 1;
		}
		store_set('playlist_info', playlist);

		social.follow_playlist(this.state.playlist_hash_id, action);
	}

	get_playlist_info(hash_id){
		store_set('loading_overlay_display', true);

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/playlist/',
			data: { hash_id: hash_id },
			success: function(res){
				if (res.status === 'success') {
					store_set('playlist_info', res.data);
				} else {
					redirect('/default-error/');
				}
			},
			error: function(){
				redirect('/default-error/');
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			},
		});
	}

	on_owner_click(){
		const data = this.props.playlist_info;
		if (!data || !data.owner) return null;

		store_set('profile_info', data);
		if (data.owner.slug){
			redirect('/profile/' + data.owner.slug);
		}
		else {
			redirect('/profile/' + data.owner.hash_id.slice(0,10));
		}
	}

	render(){
		const playlist = this.props.playlist_info;
		if (playlist.hash_id !== this.state.playlist_hash_id) return null;

		return(
			<React.Fragment>
				<div className='playlist-container'>
					<div className='playlist-main'>
						<div className='playlist-main-top'>
							<div className='playlist-main-top-image card-image-loader'>
								<ContentLoader style={{height: '306px', width: '100%'}} />
								<img src={playlist.image_link} className='playlist-main-image' alt='' onError={(ev) => ev.target.src = assets.default_playlist_image} />
							</div>
							<div className='playlist-main-top-shadow'>
								<div className='playlist-main-title'>{playlist.name}</div>
								<div className='playlist-main-subtitle'>{playlist.songs.length} songs ⸱ {playlist.followers} followers</div>
								<div className='playlist-main-description'>{playlist.description}</div>
							</div>
						</div>
						<div className='playlist-main-body'>
							{playlist.owner &&
								<div className='playlist-main-owner' onClick={this.on_owner_click}>
									<img src={playlist.owner.picture} alt='Owner' />
									<div className='playlist-main-owner-name'>{playlist.owner.name}</div>
								</div>
							}
							<div className='playlist-options-double-button' style={playlist.is_author && playlist.is_active ? {backgroundColor: '#ec6a4326'} : {}}>
								{playlist.is_author && playlist.is_active &&
									<div className='playlist-options-item-left' style={{color: '#ec6b43'}}>
										<span className='material-icons' style={{color: '#ec6b43'}}>check</span>
										<span>Active</span>
									</div>
								}
								{playlist.is_author && !playlist.is_active &&
									<div className='playlist-options-item-left' onClick={this.follow}>Not Active</div>
								}
								<Ripples>
									<Haptic intesity='light'>
										{!playlist.is_author && playlist.is_following &&
											<div className='playlist-options-item-left' onClick={this.follow}>
												<span className='material-icons'>done</span>
												<span>Following</span>
											</div>
										}
										{!playlist.is_author && !playlist.is_following &&
											<div className='playlist-options-item-left' onClick={this.follow}>
												<span className='material-icons'>person_add</span>
												<span>Follow</span>
											</div>
										}
									</Haptic>
								</Ripples>
								{!playlist.is_author &&
									<React.Fragment>
										<span className='playlist-options-middle-line' />
										<Ripples>
											<Haptic intesity='light'>
												<div className='playlist-options-item-right' onClick={() => find_similar_button(this.props.playlist_info)}>Find Similar</div>
											</Haptic>
										</Ripples>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
					{playlist.songs.length > 0 ?
						<div className='playlist-songs-container'>
							{playlist.songs.map((track, index) => (
								<Card
									key={index}
									data={track}
									type='small_card_song'
									onClick={() => {}}
									bodyLink={'/track/' + track.spotify_id}
									onMoreClick={() => {
										store_set('active_overlay', 'track_options');
										store_set('song_info', track);
									}}
								/>
							))}
						</div>
						:
						<div className='playlist-songs-container-empty'>
							<div className='playlist-main-subtitle'>There are no songs in this playlist.</div>
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		playlist_info: state.GlobalReducer.playlist_info,
		playlists: state.GlobalReducer.playlists,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Playlist));