import React, { Component } from 'react';

// CSS Imports
import './styles/ContentLoader.css';

class ContentLoader extends Component {
	render(){
		const className = this.props.className || '';
		const style = this.props.style || {};
		return(
			<div className={'content-loader-image ' + className} style={style} ></div>
		);
	}
}

export default ContentLoader;