import $ from 'jquery';
import { request_buffer } from '@RequestBuffer';
import * as constants from '@constants';
import imageCompression from 'browser-image-compression';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

function display_loading(message = 'Loading...'){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_text', message);
	store_set('loading_overlay_top_text', false);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

function callback_error(resolve, reason){
	store_set('loading_overlay_display', false);
	display_snackbar('Sorry, something went wrong...');
	resolve({'status': 'error', 'reason': reason});
}

class S3Controller {
	upload_image(file, image_type, prefix) {
		return new Promise(resolve => {
			display_loading();

			if (['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'].includes(file.type)) {
				let max_size = image_type === 'banner' ? 420 : 250;
				imageCompression(file, { maxWidthOrHeight: max_size })
					.then(compressed => {
						compressed['path'] = compressed.name;

						const file_data = JSON.stringify([compressed], ['name', 'path', 'size', 'type']);
						this.get_signed_url(file_data, prefix, image_type, file).then(res => resolve(res));
					});
			}
			else {
				callback_error(resolve, 'error_on_get_image');
			}
		});
	}

	get_signed_url(files_data, prefix, image_type, file){
		return new Promise(resolve => {
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_get_signed_url,
				data: {'files': files_data, 's3_prefix': prefix},
				success: function(data){
					if (data.status === 'success'){
						this.send_file(data.data, image_type, file).then(res => resolve(res));
					}
				}.bind(this),
				error: function(){
					callback_error(resolve, 'error_on_get_signed_url');
				},
			}, true);
		});
	}

	send_file(data, image_type, file) {
		return new Promise(resolve => {
			const file_hash_id = data[0].hash_id;
			const url = data[0].signed_url;
			const content_type = data[0].type;
			const image_link = data[0].link;

			$.ajax({
				type: 'put',
				url: url,
				contentType: content_type,
				// this flag is important, if not set, it will try to send data as a form
				processData: false,
				data: file,
				success: function() {
					resolve({'status': 'success', 'data': { file_hash_id, image_type, image_link }});
				},
				error: function(){
					callback_error(resolve, 'error_on_send_file');
				},
			});
		});
	}
}

const s3 = new S3Controller();

export default s3;

