import React, { Component } from 'react';
import { Button, RadioButton, Textfield } from '@magroove/magroove-ui';
import { LinearProgress } from '@magroove/magroove-ui';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import * as constants from '@constants';
import { request_buffer } from '@/RequestBuffer.js';
import ClickNHold from 'react-click-n-hold'; 

// CSS Imports
import './styles/DeleteAccount.css';
import '@/assets/fonts/Montserrat.css';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Import controllers
import user from '@User';

class DeleteAccount extends Component {
	constructor(props){
		super(props);
		this.state = {
			delete_progress: 0,
			step_one_completed: false,
			step_two_completed: false,
			step_three_completed: false,
			step_one_option_choosed: '',
			step_two_explanation: '',
		};

		this.delete_account = this.delete_account.bind(this);
		this.display_loading_overlay = this.display_loading_overlay.bind(this);
		this.delete_start = this.delete_start.bind(this);
		this.delete_end = this.delete_end.bind(this);
		this.delete_clickNHold = this.delete_clickNHold.bind(this);
	}

	delete_account(){
		this.display_loading_overlay();

		const data = {
			step_one_option_choosed: this.state.step_one_option_choosed,
			step_two_explanation: this.state.step_two_explanation
		};
		request_buffer.auth_execute({
			type: 'post',
			data: data,
			url: constants.api_endpoint_delete_account,
			success: function(){
				user.logout();
			}, 
			complete: function(){
				store_set('loading_overlay_component', false);
			}
		});
	}

	delete_start(){
		this.delete_interval = setInterval(function(){
			let progress = this.state.delete_progress;
			let new_progress = progress + 5/3;
			this.setState({delete_progress: new_progress});
		}.bind(this), 50);
	}

	delete_end(){
		clearInterval(this.delete_interval);
		this.setState({delete_progress: 0});
	} 

	delete_clickNHold(){
		clearInterval(this.delete_interval);
		this.setState({delete_progress: 0});
		this.delete_account();
	}

	display_loading_overlay(){
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
	}
	
	render() {
		return (
			<React.Fragment>
				<div className='delete-account-main-container'>
					{this.state.step_one_completed === false &&
						<div className='delete-account-step-one-container'>
							<div className='delete-account-text'>
								Can you please share with us the reason for your decision?
							</div>
							<div className='delete-account-step-one-options'>
								<div className='delete-account-step-one-radio-option' onClick={() => {this.setState({step_one_option_choosed: 'Something was broken'});}}>
									<RadioButton color='primary' checked={this.state.step_one_option_choosed === 'Something was broken'} />
									<div className='delete-account-step-one-radio-option-description'>Something was broken</div>
								</div>
								<div className='delete-account-step-one-radio-option' onClick={() => {this.setState({step_one_option_choosed: 'I have a privacy concern'});}}>
									<RadioButton color='primary' checked={this.state.step_one_option_choosed === 'I have a privacy concern'}/>
									<div className='delete-account-step-one-radio-option-description'>I have a privacy concern</div>
								</div>
								<div className='delete-account-step-one-radio-option' onClick={() => {this.setState({step_one_option_choosed: 'Other'});}}>
									<RadioButton color='primary' checked={this.state.step_one_option_choosed === 'Other'}/>
									<div className='delete-account-step-one-radio-option-description'>Other</div>
								</div>
							</div>
							<Button
								value='CONTINUE'
								size='small'
								fullWidth={true}
								onClick={this.state.step_one_option_choosed ? () => this.setState({step_one_completed: true}) : () => {}}
							/>
						</div>
					}
					{(this.state.step_one_completed && this.state.step_two_completed === false) &&
						<div className='delete-account-step-two-container'>
							<div className='delete-account-text'>
								Can you please share with us what was not working? 
								We are fixing bugs as soon as we spot them. 
								If something slipped through our fingers, we&apos;d be super grateful to be aware of it and fix it.
							</div>
							<div className='delete-account-step-two-textfield' onClick={() => store_set('keyboard_open', true)}>
								<Textfield
									fullWidth={true}
									theme='dark'
									size='small'
									multiline={true}
									rows={10}
									placeholder='Your explanation is entirely optional...'
									onChange={(value) => this.setState({step_two_explanation: value})}
								/>
							</div>
							<div>
								<Button
									value='CONTINUE'
									size='small'
									fullWidth={true}
									onClick={() => this.setState({step_two_completed: true})}
								/>
							</div>
						</div>
					}
					{(this.state.step_one_completed && this.state.step_two_completed && this.state.step_three_completed === false) &&
						<div className='delete-account-step-three-container'>
							<div className='delete-account-text'>
								Are you sure you want to do this? Deleting your account is an irreversible action. 
								Once deleted, your personal data as well as all your likes and playlists will be permanently erased from our database.
							</div>
							<div className='delete-account-button'>
								<ClickNHold 
									time={3} // Time to keep pressing
									onStart={this.delete_start} // Start callback
									onClickNHold={this.delete_clickNHold} //Timeout callback
									onEnd={this.delete_end}
								>
									<Button value='CONFIRM DELETION' size='small' fullWidth/> 
								</ClickNHold>
								{this.state.delete_progress > 0 &&
									<LinearProgress value={this.state.delete_progress}/>
								}
							</div>
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}


export default error_boundary_hoc(DeleteAccount);