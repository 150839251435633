import React from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import uniqid from 'uniqid';
import ads from '@Ads';

// CSS Imports
import './styles/AdUnit.css';

class AdUnit extends React.Component {
	constructor(props){
		super(props);
		this.id = uniqid();
		this.clicked = false;
		this.location = window.location.pathname;

		this.check_ad_status = this.check_ad_status.bind(this);
	}

	componentDidMount(){
		if (this.props.is_member_premium === false /* must be different from undefined */){
			(window.adsbygoogle = window.adsbygoogle || []).push({});

			this.check_ad_status();

			window.addEventListener('blur', () => {
				if (document.activeElement === document.querySelector('iframe')) {
					if (!this.clicked){
						this.clicked = true;
						ads.save_impression('adsense', 'clicked');
					}
				}
			});
		}
	}

	componentWillUnmount(){
		ads.save_impression('adsense', 'viewed', this.location);
	}

	check_ad_status(){
		// select all ins.adsbygoogle elements
		const ad = document.getElementById(this.id);
		if (!ad){
			setTimeout(this.check_ad_status, 2000);
			return;
		}

		// check the value of data-ad-status attribute
		const adStatus = ad.getAttribute('data-ad-status');

		// do something with the element based on its ad status
		if (adStatus === 'unfilled') {
			// Do something with unfilled ads
		} else if (adStatus === 'filled') {
			// Do something with filled ads
			ads.onload_adsense();
		} else {
			setTimeout(this.check_ad_status, 2000);
		}
	}

	render(){
		const width = window.innerWidth;
		const height = this.props.height || '';
		const paddingX = 15;
		const paddingTop = 7;
		const paddingBottom = 10;

		if (this.props.is_member_premium !== false){
			return null;
		}
		return (
			<div
				className='ad-unit'
				style={{ minWidth: width, padding: `${paddingTop}px ${paddingX}px ${paddingBottom}px` }}
			>
				<div className='ad-unit-label'>
					<span className='material-icons-sharp'>campaign</span>
					<span className='ad-unit-text'>SPONSORED</span>
				</div>
				<div
					className='ad-unit-content'
					style={{ width: width - 2*paddingX }}
				>
					<ins
						id={this.id}
						className='adsbygoogle'
						style={{display: 'block', height: height, width: width - 2*paddingX}}
						data-ad-format='fluid'
						data-ad-layout-key='-hn+23+5l-4c-8z'
						data-ad-client='ca-pub-9939197865290920'
						data-adtest='on'
						data-ad-slot='3690464778'
					></ins>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_member_premium: state.GlobalReducer.is_member_premium,
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(AdUnit));