import { Capacitor } from '@capacitor/core';

// import Firebase analytics
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

// Constants import
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_get } from '@actions/GlobalActions.js';

class AnalyticsController{
	constructor() {
		this.initialize = this.initialize.bind(this);
		this.log_event = this.log_event.bind(this);
		this.user_event = this.user_event.bind(this);
	}

	initialize() {
		/**
		 * Firebase Initialize
		 * Platform: Web
		 * Configure and initialize the firebase app.
		 * @param options - firebase web app configuration options
		 */
		if (constants.enable_analytics && Capacitor.getPlatform() === 'web'){
			// Firebase Analytics
			FirebaseAnalytics.initializeFirebase({
				apiKey: 'AIzaSyCApjX5vUuL-7JN6nLcgYlrQF7tEo0vSQc',
				authDomain: 'magroove-app.firebaseapp.com',
				projectId: 'magroove-app',
				storageBucket: 'magroove-app.appspot.com',
				messagingSenderId: '1038185873962',
				appId: '1:1038185873962:web:d10b36e321f786ec4cb953',
				measurementId: 'G-81H9E22HYL'
			});
		}
	}

	log_event(name, params = {}) {
		/**
		 * Platform: Android/iOS (both), Web (only Firebase)
		 * Logs an app event.
		 * @param name - name of the event to log
		 *        params - key/value pairs of properties
		 */
		if (constants.enable_analytics){
			FirebaseAnalytics.logEvent({
				name: name, 
				params: params
			});

			let device_info = store_get('device_info');
			let user = store_get('user');
			let event_details = {
				event_name: name,
				event_source_url: window.location.pathname,
				custom_params: params,
				device_info: device_info,
				member_hash: user.hash_id
			}
	
			request_buffer.execute({
				type: 'post',
				url: constants.api_endpoint_save_generic_event,
				data: {
					event_details: JSON.stringify(event_details)
				},
				success: function(data){}.bind(this),// eslint-disable-line
				error: function(){}.bind(this),// eslint-disable-line
				complete: function(){}.bind(this)// eslint-disable-line
			})
		}
	}

	user_event(name){
		/* Saves a tag in our DB that later triggers a push notification */
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_user_event,
			data: { event: name }
		});
	}
}

const app_analytics = new AnalyticsController();

export default app_analytics;
