import React, { Component } from 'react';
import { Browser } from '@capacitor/browser';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'format-unicorn';
import randomstring from 'randomstring';
import { Capacitor } from '@capacitor/core';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import Cookies from 'js-cookie';

// Login Plugins
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';

// CSS Imports
import './styles/Login.css';

// Assets Imports
import * as assets from '@Assets';

// URLs Imports

// Components and Views Imports
import Ripples from 'react-ripples';
import Haptic from '@components/haptic.js';

// Constants import
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Import the controllers
import user from '@User';

const openCapacitorSite = async (url) => {
	await Browser.open({ url: url });
};

const SignInWithGoogle = async () => {
	const response = await GoogleAuth.signIn();
	return response;
};

const login_error = (message = 'Sorry, we got an error...') => {
	store_set('loading_overlay_display', false);
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
};

const FACEBOOK_PERMISSIONS = [
	'email',
	'public_profile'
];

const SignInWithFB = async () => {
	const response = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
	return response;
};

class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			session_hash: '',
			loading: false,
		};
		store_set('loading_overlay_display', false);

		// ===================================================================================
		const url_string = window.location.href;
		const url = new URL(url_string);

		// Referral param
		const aid = url.searchParams.get('aid'); // aid = member_hash_id.slice(0, 10)
		if (aid && aid.length >= 10){
			Cookies.set('aid', aid);
		}

		// Login param
		const login_failed = url.searchParams.get('login-failed');
		if (this.props.logging_in && login_failed){
			store_set('snackbar_message', 'Sorry, we got an error...');
			store_set('snackbar_status', true);
			store_set('logging_in', false);
		}
		// ===================================================================================

		this.apple_login = this.apple_login.bind(this);
		this.google_login = this.google_login.bind(this);
		this.facebook_login = this.facebook_login.bind(this);

		this.display_loading_overlay = this.display_loading_overlay.bind(this);
		this.open_login_window = this.open_login_window.bind(this);
		this.close_login_window = this.close_login_window.bind(this);
		this.get_token = this.get_token.bind(this);
		this.get_token_timeout = this.get_token_timeout.bind(this);
		this.clear_token_interval = this.clear_token_interval.bind(this);
	}

	display_loading_overlay(){
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', [{
			label: 'CANCEL',
			function: () => {
				this.clear_token_interval();
			}
		}]);
		store_set('loading_overlay_display', true);
	}

	native_login_callback(url, res){
		store_set('loading_overlay_text', 'Logging in...');
		request_buffer.execute({
			url: url,
			type: 'post',
			data: {'result': JSON.stringify(res)},
			success: function(data){
				if (data.status === 'success'){
					user.login(data);
				}
				else{
					login_error('Sorry, something went wrong...');
				}
			},
			error: function(){
				login_error('Sorry, something went wrong. Try again...');
			}
		});
	}

	facebook_login(){
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();
		let url = constants.api_endpoint_login_fb_native.formatUnicorn({session_hash_id: this.state.session_hash});

		this.display_loading_overlay();

		SignInWithFB()
			.then((data) => {
				this.native_login_callback(url, data);
			})
			.catch(() => {
				login_error('Please, try again...');
			});
	}

	google_login(){
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();
		let url = constants.api_endpoint_login_google_native.formatUnicorn({session_hash_id: this.state.session_hash});

		this.display_loading_overlay();

		SignInWithGoogle()
			.then((data) => {
				this.native_login_callback(url, data);
			})
			.catch(() => {
				login_error('Please, try again...');
			});
	}

	apple_login(){
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();
		var url = constants.api_endpoint_login_apple.formatUnicorn({session_hash_id: this.state.session_hash});

		var options = {
			clientId: 'com.magroove.auth',
			redirectURI: 'https://auth.magroove.com/apple-login/',
			scopes: 'email name',
			nonce: this.state.session_hash
		};

		this.display_loading_overlay();

		SignInWithApple.authorize(options)
			.then((result) => {
				this.native_login_callback(url, result.response);
			})
			.catch(() => {
				login_error('Please, try again...');
			});
	}

	open_login_window(type){
		var url;
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();
		switch(type) {
		case 'facebook':
			url = constants.api_endpoint_login_facebook.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'google':
			url = constants.api_endpoint_login_google.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'spotify':
			url = constants.api_endpoint_login_spotify.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'debug':
			url = constants.api_endpoint_login_debug.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		default:
			return;
		}

		this.display_loading_overlay();
		const aid = Cookies.get('aid');

		if (Capacitor.getPlatform() === 'web') {
			store_set('logging_in', true);
			if (aid){
				window.location.href = url + '?redirect=true&aid=' + aid;
			} else {
				window.location.href = url + '?redirect=true';
			}
		} else {
			if (aid) {
				url = url + '?aid=' + aid;
			}

			// eslint-disable-next-line
			this.state.login_window = openCapacitorSite(url);

			Browser.addListener('browserFinished', () => {
				this.clear_token_interval();
				Browser.removeAllListeners();
			});

			// eslint-disable-next-line
			this.state.get_token_interval = setInterval(this.get_token, constants.login_token_check_interval)
			setTimeout(this.get_token_timeout, constants.login_token_timeout);
		}
	}

	close_login_window(){
		if (this.state.login_window) {
			if (Capacitor.getPlatform() === 'ios'){
				Browser.close(); // only iOS supports this method
			}
			// eslint-disable-next-line
			this.state.login_window = false;
		}
	}

	clear_token_interval(){
		if (this.state.get_token_interval) {
			clearInterval(this.state.get_token_interval);
			// eslint-disable-next-line
			this.state.get_token_interval = false;
		}
	}
 
	get_token(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_login_get_token.formatUnicorn({session_hash_id: this.state.session_hash}),
			success: function(data) {
				if (data.status !== 'unknown') {
					this.clear_token_interval();
					this.close_login_window();
					if (data.status === 'error') {
						store_set('loading_overlay_display', false);
						this.setState({snackbar_open: true});
						this.setState({snackbar_message: data.message});
					}
					if (data.status === 'success') {
						user.login(data);					
					}
				}
			}.bind(this),
			error: function() {
				store_set('loading_overlay_display', false);
				this.setState({snackbar_open: true});
				this.setState({snackbar_message: 'Sorry, we got an error...'});
			}.bind(this),
			timeout: 30000
		});
	}

	get_token_timeout(){
		if (this.state.get_token_interval) {
			this.clear_token_interval();
			this.close_login_window();
			store_set('loading_overlay_display', false);
			this.setState({snackbar_open: true});
			this.setState({snackbar_message: 'Sorry, we got an error...'});
		}
	}

	render() {
		return (
			<div className='login-main'>
				<div className='login-background' style={{top: this.props.safe_area_top}}/>
				<div className='login-content'>
					<div className='login-text'>How would you like to login?</div>
					<Ripples>
						<Haptic>
							<div
								className='login-button'
								style={{backgroundColor: '#3b5998'}}
								onClick={this.facebook_login}
							>
								<img src={assets.login_button_facebook} alt='Facebook'/>
								<span className='login-button-text'>SIGN IN WITH FACEBOOK</span>
							</div>
						</Haptic>
					</Ripples>
					<Ripples>
						<Haptic>
							<div
								className='login-button'
								style={{backgroundColor: '#ffffff'}}
								onClick={this.google_login}
							>
								<img src={assets.login_button_google} alt='Google'/>
								<span className='login-button-text' style={{color: '#757575'}}>SIGN IN WITH GOOGLE</span>
							</div>
						</Haptic>
					</Ripples>
					<Ripples>
						<Haptic>
							<div
								className='login-button'
								style={{backgroundColor: '#1db954'}}
								onClick={() => this.open_login_window('spotify')}
							>
								<img src={assets.login_button_spotify} alt='Spotify'/>
								<span className='login-button-text'>SIGN IN WITH SPOTIFY</span>
							</div>
						</Haptic>
					</Ripples>
					{Capacitor.getPlatform() === 'ios' &&
						<Ripples>
							<Haptic>
								<div
									className='login-button'
									style={{backgroundColor: '#292929'}}
									onClick={this.apple_login}
								>
									<img src={assets.login_button_apple} alt='Apple Music'/>
									<span className='login-button-text'>SIGN IN WITH APPLE</span>
								</div>
							</Haptic>
						</Ripples>
					}
					{constants.debug &&
						<Ripples>
							<Haptic>
								<div
									className='login-button'
									style={{backgroundColor: 'grey'}}
									onClick={() => this.open_login_window('debug')}
								>
									<img src={assets.login_button_debug} alt='Debug'/>
									<span className='login-button-text'>SIGN IN DEBUG TOOL</span>
								</div>
							</Haptic>
						</Ripples>
					}
					<div className='login-small-text'>
						<Haptic>
							<Link to='/contact-us/'>
								Need help? Contact our support
							</Link>
						</Haptic>
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		safe_area_top: state.GlobalReducer.safe_area_top,
		logging_in: state.GlobalReducer.logging_in
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Login));