import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContentLoader from '@components/ContentLoader.js';

// CSS Imports
import './styles/NotificationsPage.css';

// Assets and Constants Imports
import * as assets from '@Assets';
import * as constants from '@constants';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Components and Views Imports
import { redirect } from '@routes/Routes';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Import controllers
import notifications from '@Notifications';

function checkDate(dateStr) {
	const date = new Date(dateStr);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	const oneWeekAgo = new Date(today);
	oneWeekAgo.setDate(today.getDate() - 7);
	const lastMonth = new Date(today);
	lastMonth.setMonth(today.getMonth() - 1);

	if (date.toDateString() === today.toDateString()) {
		return 'Today';
	} else if (date.toDateString() === yesterday.toDateString()) {
		return 'Yesterday';
	} else if (date >= oneWeekAgo) {
		return 'This week';
	} else if (date >= lastMonth) {
		return 'Last month';
	} else {
		return 'More than a month';
	}
}

class NotificationsPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			items:  this.get_items() || {},
		};
	}

	componentWillUnmount() {
		// Checks all as viewed
		this.on_view();
	}

	get_items() {
		// For now, we add the new releases as well just so the notifications tab won't be empty
		let new_releases = [...this.props.new_releases];
		new_releases = new_releases.slice(5, 10).sort((a,b) => new Date(b.release_date) - new Date(a.release_date));
		let notifications = this.props.notifications.concat(new_releases);
		
		// Order the notifications and releases by date
		let items = {};
		// eslint-disable-next-line
		for (let item of notifications) {
			let section = checkDate(item.sent_at);
			if (section in items) {
				items[section].push(item);
			} else {
				items[section] = [item];
			}
		}

		return items;
	}

	on_click(item, component, notif_hash = false){
		notifications.save_click(item.hash_id);

		const action_type = item.action.type;
		const action_content = item.action.content;

		if (component === 'body' && item.action.body_link){
			redirect(item.action.body_link);

		} else if (action_type.includes('follow')){
			let follow_type = action_type.slice(7);
			this.handle_follow(follow_type, item.extra_data.entity_id, notif_hash);
	
		} else if (action_type === 'open_link' && action_content.includes('discover.magroove.com')){
			const url = new URL(action_content);
			const page = url.href.replace(url.origin, '');
			redirect(page);

		} else if (action_type === 'redirect_page'){
			redirect(action_content);
		}

		const items = [...this.props.notifications];
		if (items.length) {
			items.forEach((current) => {
				if (current.hash_id === item.hash_id){ 
					item.new = false;
					item.clicked = true;
				}
			});

			store_set('notifications', items);
		}
	}

	on_view() {
		let new_notifs = this.props.notifications.filter(notif => notif.new);
		let new_hashes = Array.from(new_notifs, notif => notif.hash_id);
			
		if (new_notifs.length && new_hashes.length) {
			notifications.save_view(new_hashes);
			new_notifs.forEach((current) => {
				current.new = false;
			});

			store_set('notifications', new_notifs);
		}
	}

	remove_notification(item_hash_id, section) {
		let items = this.get_items();
		let new_notifs = items[section].filter(item => item.hash_id !== item_hash_id);
		items[section] = new_notifs;
		notifications.clear(item_hash_id);
	}

	handle_follow(entity_type, entity_id, notif_hash) {
		let items = this.get_items();
		let entity = [...this.props.notifications].find(el => el.hash_id === notif_hash);
		if (!entity) { return; }

		entity['action']['is_loading'] = true;
		this.setState({items});

		let url = entity_type === 'member' ? 'api_endpoint_follow_user_management' : 'api_endpoint_follow_artist_management';
		let data = {
			[entity_type === 'member' ? 'user_id' : 'artist_id'] : entity_id
		};

		if (entity_type === 'artist') {
			data['action'] = entity['extra_data']['is_following'] ? 'unfollow' : 'follow';
		}

		request_buffer.auth_execute({
			type: 'post',
			url: constants[url],
			data: data,
			success: function(data){
				if (data.status === 'success'){
					entity['extra_data']['is_following'] = !entity['extra_data']['is_following'];
					entity['action']['is_loading'] = false;
					this.setState({items});
				}
			}.bind(this)
		}, true);
	}

	render(){
		const items = this.get_items();

		if ((this.props.loading_notifications && !Object.keys(items).length) || this.props.clearing_notifications) {
			return(
				<div className='notitications-empty'>
					<CircularProgress size={65} />
				</div>
			);
		}

		if (!Object.keys(items).length) {
			return(
				<div className='notitications-empty'>
					<div className='notifications-empty-message'>You have no notifications yet.</div>
				</div>
			);
		}

		return(
			<div className='notitications'>
				{Object.keys(items).map((time_range) => {
					return (
						<React.Fragment key={time_range}>
							{items[time_range].length > 0 &&
								<div className='notifications-section'>
									<div className='notifications-section-title'>{time_range}</div>
									{items[time_range].find(item => item.new) &&
										<div className='notifications-header-title-circle'></div>
									}
								</div>
							}
							{items[time_range].map((item) => {
								if (item.type) {
									return(
										<div className='notifications-item' key={item.hash_id}>
											<Link to={'/album/' + item.spotify_id}>
												<img className='notifications-item-image-release' src={item.images.medium} alt='' />
												<div className='notifications-item-text'><b>{item.artist.name}</b> just released a new album. Check it out!</div>
												<div className='notifications-item-action'>
													<div className='notification-item-action-redirect material-icons'>chevron_right</div>
												</div>
											</Link>
										</div>
									);
								}
								return(
									<div className='notifications-item' onClick={() => this.on_click(item, 'body')} key={item.hash_id}>
										{item.new &&
											<div className='notifications-item-new-label'>New!</div>
										}
										<Link to={item.action.body_link}>
											<div className='notifications-image-loader'>
												<ContentLoader />
												<img src={item.image} className='notifications-item-image' alt='' onError={(ev) => ev.target.src = assets.default_profile_image} />
											</div>
											<div className='notifications-item-text' dangerouslySetInnerHTML={{__html: item.body}}></div>
											{item.action.is_loading ?
												<div className='notifications-item-action'>
													<CircularProgress className='notifications-item-action-loading' size={15} />
												</div>
												:
												<div className='notifications-item-action' onClick={(e) => {e.stopPropagation(); e.preventDefault();}}>
													{['follow_member', 'follow_artist'].includes(item.action.type) &&
														(item.extra_data.is_following ?
															<div className='notification-item-action-content'>
																<span className='material-icons'>check</span>
																<div className='notification-item-action-unfollow' onClick={() => this.on_click(item, 'button', item.hash_id)}>Following</div>
															</div>
															:
															<div className='notification-item-action-follow' onClick={() => this.on_click(item, 'button', item.hash_id)}>Follow</div>
														)
													}
													{['open_link', 'redirect_page'].includes(item.action.type) &&
														<React.Fragment>
															{item.is_closeable && item.clicked ? 
																<div className='notification-item-action-redirect-close material-icons' onClick={() => this.remove_notification(item.hash_id, time_range)}>close</div>
																:
																<div className='notification-item-action-redirect material-icons' onClick={() => this.on_click(item, 'button')}>chevron_right</div>
															}
														</React.Fragment>
													}
												</div>
											}

										</Link>
									</div>
								);
							})}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		notifications: state.GlobalReducer.notifications,
		loading_notifications: state.GlobalReducer.loading_notifications,
		clearing_notifications: state.GlobalReducer.clearing_notifications,
		new_releases: state.GlobalReducer.new_releases,
		recommendation_info: state.GlobalReducer.recommendation_info,
		friends_recommendations: state.GlobalReducer.friends_recommendations,
		current_page: state.GlobalReducer.current_page,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(NotificationsPage));