
// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Constant Imports
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Import controllers
import subscription from '@Subscription';
import player from '@Player';

function display_error_message(message){
	store_set('loading_overlay_display', false);
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

function display_loading_overlay(message = 'Loading...'){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', false);
	store_set('loading_overlay_text', message);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

class PlaylistController{
	constructor() {
		this.state = {};

		this.remove_playlist = this.remove_playlist.bind(this);
		this.update_active_playlist_tracks = this.update_active_playlist_tracks.bind(this);
		this.remove_active_playlist_tracks = this.remove_active_playlist_tracks.bind(this);
		this.update_active_playlist = this.update_active_playlist.bind(this);
		this.sync_internal_playlist = this.sync_internal_playlist.bind(this);	
		this.unsync_internal_playlist = this.unsync_internal_playlist.bind(this);	
		this.export_internal_playlist = this.export_internal_playlist.bind(this);
	}

	remove_playlist() {
		const playlist = store_get('playlist_info');
		display_loading_overlay();
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_remove_internal_playlist,
			data: {playlist_hash_id: playlist.hash_id},
			success: function(data){
				if (data.status === 'success'){
					// Update list of user playlists
					let playlists = store_get('playlists');
					let new_list = playlists.filter(p => p.hash_id !== playlist.hash_id);
					// If active playlist was deleted, we must set active_playlist to false
					let active_playlist = store_get('active_playlist');
					if (playlist.hash_id === active_playlist.hash_id) {
						store_set('active_playlist', false);
					}
					store_set('playlists', new_list);
				}
			},
			complete: function(){
				store_set('active_overlay', false);
				store_set('loading_overlay_display', false);
			}
		});
	}

	update_active_playlist_tracks(song, playlist_info){
		if (!playlist_info) { return; }
		store_set('active_overlay', false);
		store_set('active_overlay_option', 'show_all_track');

		// We add the song to the playlist first and then we try to add it on server
		let playlists = store_get('playlists');
		let ix = playlists.findIndex(p => p.hash_id === playlist_info.hash_id);
		if (ix !== -1) {
			let playlist_songs = playlists[ix].songs;
			let song_in_playlist = playlist_songs.find(s => s.spotify_id === song.spotify_id);
			if (!song_in_playlist) {
				playlists[ix].songs.push(song);
				store_set('playlists', playlists);
			}

			request_buffer.execute({
				type: 'post',
				url: constants.api_endpoint_add_track_internal_playlist,
				data: {
					playlist_hash_id: playlist_info.hash_id,
					song: JSON.stringify(song)
				},
				success: function(){}
			});
		}
	}

	remove_active_playlist_tracks(song, playlist_info){
		if (!playlist_info) { return; }
		store_set('active_overlay', false);

		// We remove the song to the playlist first and then we try to add it on server
		let playlists = [...store_get('playlists')];
		let current_playlist = playlists.find(p => p.hash_id === playlist_info.hash_id);
		let new_songs = current_playlist.songs.filter(s => s.spotify_id !== song.spotify_id);
		current_playlist.songs = new_songs;
		store_set('playlists', playlists);
		store_set('playlist_info', current_playlist);

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_remove_track_internal_playlist,
			data: {
				playlist_hash_id: playlist_info.hash_id,
				song: JSON.stringify(song)
			},
			success: function(){}
		});
	}
	
	update_active_playlist(playlist_hash) {
		display_loading_overlay();
		const was_playing = store_get('is_playing');
		player.pause();
		store_set('active_overlay', false);

		// If playlist_hash is 'false', it means to deselect the active playlist
		if (!playlist_hash) {
			store_set('active_playlist', false);
		}

		// If user selects already active playlist, we unselect
		const active_playlist = store_get('active_playlist');
		if (active_playlist && playlist_hash === active_playlist.hash_id) {
			playlist_hash = false;
			store_set('active_playlist', false);
		}

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_update_active_playlist,
			data: { playlist_hash: playlist_hash },
			success: function(data){
				if (data.status === 'success'){
					let playlists = store_get('playlists');
					let old_playlist = playlists.find(item => item.is_active);
					let new_playlist = playlists.find(item => item.hash_id === playlist_hash);
					if (old_playlist) {
						old_playlist.is_active = false;
					}
					if (new_playlist) {
						new_playlist.is_active = true;
						store_set('active_playlist', new_playlist);
					}
					store_set('playlists', playlists);
				} else {
					display_error_message(data.reason);
				}
			},
			complete: function(){
				store_set('active_overlay', false);
				store_set('loading_overlay_display', false);
				if (was_playing) {
					player.play();
				}
			}
		});
	}

	sync_internal_playlist(playlist, platform) {
		store_set('loading_playing_sync', platform);
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_sync_internal_playlist,
			data: {
				playlist: JSON.stringify(playlist),
				platform: platform
			},
			success: function(data){
				if (data.status === 'success'){
					// Deezer playlists are numbers, so we turn the id into string
					let synced_playlists = store_get('synced_playlists');
					store_set('synced_playlists', [...synced_playlists, data.playlist_id.toString()]);
					
					// Then we update the information on the internal playlists list
					let playlists = store_get('playlists');
					let updated_playlist = playlists.find(p => p.hash_id === playlist.hash_id);
					updated_playlist[platform + '_id'] = data.playlist_id;
					updated_playlist[platform + '_synced'] = true;
					if (!updated_playlist['dsps'].includes(platform)) {
						updated_playlist['dsps'].push(platform);
					}
					store_set('playlists', playlists);
				} else {
					display_error_message(data.reason);
				}
			},
			error: function(){
				display_error_message('Sorry, we got an error...');
			},
			complete: function() {
				store_set('loading_playing_sync', false);
				store_set('active_overlay', false);
			},
		});
	}

	unsync_internal_playlist(playlist, platform) {
		store_set('loading_playing_sync', platform);
		let playlist_id = playlist[platform + '_id'];
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_remove_synced_playlist,
			data: {
				playlist_id: playlist_id,
				platform: platform
			},
			success: function(data){
				if (data.status === 'success'){
					// Deezer playlists are numbers, so we turn the id into string
					const new_synced_list = store_get('synced_playlists').filter(playlist => playlist !== playlist_id.toString());
					store_set('synced_playlists', new_synced_list);
					
					// Then we update the information on the internal playlists list
					let playlists = store_get('playlists');
					let updated_playlist = playlists.find(p => p.hash_id === playlist.hash_id);
					updated_playlist[platform + '_synced'] = false;
					let ix = updated_playlist['dsps'].findIndex(dsp => dsp === platform);
					if (ix !== -1) {
						updated_playlist['dsps'].splice(ix, 1);
					}
					store_set('playlists', playlists);
				}
			},
			error: function(){
				display_error_message('Sorry, we got an error...');
			},
			complete: function() {
				store_set('loading_playing_sync', false);
				store_set('active_overlay', false);
			},
		});
	}

	// Export one of our internal playlist to synced DSPs
	// This will create a new playlist in the DSPs and then add the tracks
	export_internal_playlist() {
		const playlist = store_get('playlist_info');
		const linked_accounts = store_get('linked_accounts');
		const platforms = linked_accounts.filter(a => a.state === 'active').map((a) => a.platform);

		if (platforms.length === 0 ) {
			store_set('snackbar_message', 'No linked accounts.');
			store_set('snackbar_status', true);
			return;
		}

		display_loading_overlay('Exporting playlist...');
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_export_internal_playlist,
			data: {
				platforms: JSON.stringify(platforms),
				playlist_data: JSON.stringify(playlist),
			},
			success: function(data){
				if (data.status === 'success'){
					store_set('playlist_info', data.data);
					store_set('snackbar_message', 'Success!');
					store_set('snackbar_status', true);
				} else {
					display_error_message('Sorry, something went wrong...');
				}
			},
			error: function(){
				display_error_message('Sorry, we got an error...');
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}

	duplicate(){
		if (!subscription.is_valid()) {
			subscription.require('queue_music', 'Subscribing now allows you to manage playlists!', 'internal_playlists');
			return;
		}

		display_loading_overlay('Duplicating playlist...');

		const playlist = store_get('playlist_info');
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_duplicate_playlist,
			data: { playlist_hash_id: playlist.hash_id },
			success: function(res){
				if (res.status === 'success'){
					const playlists = store_get('playlists');
					store_set('playlists', [res.data, ...playlists]);
					store_set('snackbar_message', 'Duplicated!');
					store_set('snackbar_status', true);
				}
			},
			error: function(){
				display_error_message('Sorry, something went wrong...');
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}
}

const playlist_manager = new PlaylistController();

export default playlist_manager;