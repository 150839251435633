import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { connect } from 'react-redux';
import { execute_middlewares } from '../Middlewares.js';

import Onboarding from '@views/Onboarding.js';
import OutOfIdeas from '@views/OutOfIdeas.js';
import Login from '@views/Login.js';
import Home from '@views/Home.js';
import MyList from '@views/MyList.js';
import Search from '@views/Search.js';
import Profile from '@views/Profile.js';
import Settings from '@views/Settings.js';
import EditProfile from '@views/EditProfile.js';
// import MyStats from '@views/MyStats';
import Track from '@views/Track.js';
import Album from '@views/Album.js';
import Artist from '@views/Artist.js';
import LinkedAccounts from '@views/LinkedAccounts.js';
import StaticText from '@views/StaticText.js';
import DeleteAccount from '@views/DeleteAccount.js';
import DefaultError from '@views/DefaultError.js';
import ContactUs from '@views/ContactUs.js';
import Version from '@views/Version.js';
import RedirectStores from '@views/RedirectStores.js';
import SyncPlaylists from '@views/SyncPlaylists.js';
import MyPlaylists from '@views/MyPlaylists.js';
import SubscriptionPage from '@views/SubscriptionPage.js';
import FineTunning from '@views/FineTunning.js';
import Blocklist from '@views/Blocklist.js';
import Playlist from '@views/Playlist.js';
import QueueList from '@views/QueueList.js';
import Recommendation from '@views/Recommendation.js';
import TrackRecommendation from '@views/TrackRecommendation.js';
import NotificationsPage from '@views/NotificationsPage.js';
import Poll from '@views/Poll.js';

// Actions import
import { 
	store_set,
	store_get,
	store_update,
	clear_all_overlays,
	execute_post_link_account_action
} from '@actions/GlobalActions.js';
import { Redirect } from 'react-router';

// Import controllers and event bus
import event_bus from '@Eventbus';

export function redirect(page){
	store_update({
		redirect_location: page,
		should_redirect: true
	});
}

const routes_excluded_login = [
	'track', 'artist', 'album', 'lp', 'login', 'onboarding', 'out-of-ideas', 'contact-us', 'more', 'privacy-policy', 'terms-of-service', 'version'
];

class Routes extends Component {
	constructor(props){
		super(props);
		this.state = {
			redirect: false
		};
	}

	componentDidMount() {
		// ===================================================================================
		// In the Link-account flow, api redirects back to the App
		// Check for url params to execute the post link account action
		const url = new URL(window.location.href);
		const status = url.searchParams.get('status');
		const link_account_loading = store_get('link_account_loading');
		if (link_account_loading && status) {
			execute_post_link_account_action(status);
		}
		// ===================================================================================
	}

	shouldComponentUpdate(nextProps){
		if (
			this.props.location === nextProps.location &&
			this.props.user_token === nextProps.user_token &&
			this.props.redirect_location === nextProps.redirect_location &&
			this.props.should_redirect === nextProps.should_redirect
		){
			return false;
		}

		return true;
	}

	componentDidUpdate(){
		const url = new URL(window.location.href);
		const pathname = window.location.pathname;
		const previous_page = store_get('current_page');
		const new_state = {};
		if (pathname !== previous_page){
			if ((pathname.includes('contact-us') && (previous_page.includes('login') || previous_page.includes('onboarding') || previous_page.includes('out-of-ideas'))) ||
				(pathname.includes('out-of-ideas') && previous_page.includes('onboarding')) ||
				(pathname.includes('search') && previous_page.includes('onboarding'))) {
				new_state.hide_footer = true;
			} else {
				new_state.hide_footer = false;
			}

			if (store_get('select_mode') && !pathname.includes('my-list')){
				new_state.tracks_selected = [];
				new_state.select_mode = false;
			}

			if (previous_page.includes('my-list')) {
				new_state.new_likes_list = [];
				new_state.new_likes = false;
			}

			new_state.previous_page = previous_page;
			new_state.current_page = window.location.pathname;
		}

		store_update(new_state);

		const invite = url.searchParams.get('invprem');
		if (this.props.user_token && invite) {
			event_bus.emit('invite-premium');
		}

		const notification_action = url.searchParams.get('notification_action');
		if (notification_action === 'experience_evaluation'){
			store_set('secondary_overlay', 'experience_evaluation');
		}
	}

	render() {
		const url_string = window.location.href;
		const url = new URL(url_string);
		const user_token = url.searchParams.get('token');
		if (!this.props.user_token && !(store_get('logging_in') && user_token)) {
			store_set('hide_footer', true);

			// eslint-disable-next-line
			const matches = routes_excluded_login.find(route => window.location.pathname.match('\/' + route + '($|\/)'));

			// In case the page requires login, we should redirect to login page 
			if (!matches) {
				redirect('/login/');
			}
		}

		if (this.props.should_redirect) {
			// Update state so the redirect happens only once
			store_set('should_redirect', false);
			return <Redirect push to={this.props.redirect_location} />;
		}
		return (
			<AnimatedSwitch
				atEnter={{ opacity: 0 }}
				atLeave={{ opacity: 0 }}
				atActive={{ opacity: 1 }}
				className='main-content-inner'
			>
				<Route path='/onboarding/' exact
					render={() => {
						if (this.props.location !== 'onboarding'){
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'onboarding');
						}
						return(<Onboarding/>);
					}}
				/>
				<Route path='/out-of-ideas/' exact
					render={() => {
						if (this.props.location !== 'out-of-ideas'){
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'out-of-ideas');
							store_set('navigation_location', false);
						}
						return(<OutOfIdeas/>);
					}}
				/>
				<Route path='/login/' exact
					render={() => {
						if (this.props.location !== 'login') {
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'login');
						}
						return (<Login/>);
					}}
				/>
				<Route path='/' exact
					render={() => {
						if (this.props.location !== 'home') {
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'home');
							store_set('navigation_location', 'home');
						}
						return (<Home />);
					}}
				/>
				<Route path='/my-list/' exact
					render={() => {
						if (this.props.location !== 'my-list') {
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'my-list');
							store_set('navigation_location', 'my-list');
						}
						return (<MyList />);
					}}
				/>
				<Route path='/search/' exact
					render={() => {
						if (this.props.location !== 'search') {
							execute_middlewares();
							store_set('location', 'search');
							store_set('navigation_location', 'search');
						}
						return(<Search />);
					}}
				/>
				<Route path='/edit-profile-search/:entities/' exact 
					render={({ match }) => {
						if (this.props.location !== 'edit-profile-search'){
							execute_middlewares();
							store_set('location', 'edit-profile-search');
							store_set('navigation_location', false);
						}
						return(<Search url={match}/>);
					}}
				/>
				<Route path='/quiz-search/:entities/' exact
					render={({ match }) => {
						if (this.props.location !== 'quiz-search'){
							execute_middlewares();
							store_set('location', 'quiz-search');
							store_set('navigation_location', false);
						}
						return(<Search url={match}/>);
					}}
				/>
				<Route path='/obsession-search/:entities/' exact 
					render={({ match }) => {
						if (this.props.location !== 'obsession-search'){
							execute_middlewares();
							store_set('location', 'obsession-search');
							store_set('navigation_location', false);
						}
						return(<Search url={match}/>);
					}}
				/>
				<Route path='/poll-recommendation-search/:entities/' exact 
					render={({ match }) => {
						if (this.props.location !== 'poll-recommendation-search'){
							execute_middlewares();
							store_set('location', 'poll-recommendation-search');
							store_set('navigation_location', false);
						}
						return(<Search url={match}/>);
					}}
				/>
				<Route path='/recommendation-search/' exact 
					render={() => {
						if (this.props.location !== 'recommendation-search'){
							execute_middlewares();
							store_set('location', 'recommendation-search');
							store_set('navigation_location', false);
						}
						return(<Search url={{params: {entities: 'song'}}}/>);
					}}
				/>
				<Route path='/new-poll/' exact 
					render={() => {
						if (this.props.location !== 'new-poll'){
							execute_middlewares();
							store_set('location', 'new-poll');
							store_set('navigation_location', false);
						}
						return(<Poll />);
					}}
				/>
				<Route path='/poll/' exact 
					render={() => {
						if (this.props.location !== 'poll'){
							execute_middlewares();
							store_set('location', 'poll');
							store_set('navigation_location', false);
						}
						return(<Poll />);
					}}
				/>
				<Route path='/poll/:hash_id/' exact 
					render={({ match }) => {
						if (this.props.location !== 'poll'){
							execute_middlewares();
							store_set('location', 'poll');
							store_set('navigation_location', false);
						}
						return(<Poll url={match} />);
					}}
				/>
				<Route path='/recommendation/' exact 
					render={() => {
						if (this.props.location !== 'recommendation'){
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'recommendation');
							store_set('navigation_location', false);
						}
						return(<Recommendation />);
					}}
				/>
				<Route path='/recommendation/:hash_id/' exact 
					render={({ match }) => {
						if (this.props.location !== 'recommendation'){
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'recommendation');
							store_set('navigation_location', false);
						}
						return(<Recommendation url={match} />);
					}}
				/>
				<Route path='/profile/:slug/' exact
					render={({ match }) => {
						if (this.props.location !== 'profile') {
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'profile');
						}
						store_set('navigation_location', false);
						return (<Profile url={match} key={match.params.slug} />);
					}}
				/>
				<Route path='/profile/' exact
					render={() => {
						if (this.props.location !== 'profile') {
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'profile');
						}
						store_set('navigation_location', 'profile');
						return(<Profile url={{params: {slug: ''}}} />);
					}}
				/>
				<Route path='/settings/' exact
					render={() => {
						if (this.props.location !== 'settings'){
							execute_middlewares();
							clear_all_overlays();
							store_set('location', 'settings');
							store_set('navigation_location', 'settings');
						}
						return(<Settings/>);
					}}
				/>
				<Route path='/edit-profile/' exact
					render={() => {
						if (this.props.location !=='edit-profile'){
							execute_middlewares();
							store_set('location', 'edit-profile');
							store_set('navigation_location', false);
						}
						return(<EditProfile/>);
					}}
				/>
				<Route path='/track/:track_hash_id/' exact
					render={({ match }) => {
						execute_middlewares();
						store_set('location', 'track');
						store_set('navigation_location', false);
						return(<Track url={match}/>);
					}}
				/>
				<Route path='/album/:album_hash_id/' exact
					render={({ match }) => {
						execute_middlewares();
						store_set('location', 'album');
						return(<Album url={match}/>);
					}}
				/>
				<Route path="/artist/:artist_hash_id/" exact
					render={({ match }) => {
						execute_middlewares();
						store_set('location', 'artist');
						return(<Artist url={match}/>);
					}}
				/>
				<Route path='/linked-accounts/' exact
					render={() => {
						if (this.props.location !== 'linked-accounts'){
							execute_middlewares();
							store_set('location', 'linked-accounts');
							store_set('navigation_location', 'settings');
						}
						return(<LinkedAccounts/>);
					}}
				/>
				<Route path='/terms-of-service/' exact
					render={() => {
						if (this.props.location !== 'terms-of-service'){
							execute_middlewares();
							store_set('location', 'terms-of-service');
						}
						return(<StaticText/>);
					}}
				/>
				<Route path='/privacy-policy/' exact
					render={() => {
						if (this.props.location !== 'privacy-policy'){
							execute_middlewares();
							store_set('location', 'privacy-policy');
						}
						return(<StaticText/>);
					}}
				/>
				<Route path='/delete-account/' exact
					render={() => {
						if (this.props.location !== 'delete-account'){
							execute_middlewares();
							store_set('location', 'delete-account');
						}
						return(<DeleteAccount/>);
					}}
				/>
				<Route path='/contact-us/' exact
					render={() => {
						if (this.props.location !== 'contact-us'){
							execute_middlewares();
							store_set('navigation_location', 'settings');
							store_set('location', 'contact-us');
						}
						return(<ContactUs/>);
					}}
				/>
				<Route path='/version/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'version');
						return(<Version/>);
					}}
				/>
				<Route path='/lp/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'lp');
						return(<RedirectStores/>);
					}}
				/>
				<Route path='/sync-playlists/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'sync-playlists');
						store_set('navigation_location', 'settings');
						return(<SyncPlaylists/>);
					}}
				/>
				<Route path='/my-playlists/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'my-playlists');
						return(<MyPlaylists/>);
					}}
				/>
				<Route path='/playlist/:playlist_hash_id/' exact
					render={({ match }) => {
						execute_middlewares();
						store_set('location', 'playlist');
						return(<Playlist url={match}/>);
					}}
				/>
				<Route path='/subscriptions/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'subscriptions');
						return(<SubscriptionPage/>);
					}}
				/>
				<Route path='/fine-tunning/' exact
					render={() => {
						execute_middlewares();
						store_set('location', 'fine-tunning');
						store_set('navigation_location', 'fine-tunning');
						return(<FineTunning/>);
					}}
				/>
				<Route path='/queue/' exact
					render={() => {
						if (this.props.location !== 'queue-list'){
							execute_middlewares();
							store_set('location', 'queue-list');
							store_set('navigation_location', false);
						}
						return(<QueueList/>);
					}}
				/>
				<Route path='/blocklist/' exact
					render={() => {
						if (this.props.location !== 'blocklist'){
							execute_middlewares();
							store_set('location', 'blocklist');
							store_set('navigation_location', 'home');
						}
						return(<Blocklist />);
					}}
				/>
				<Route path='/track-recommendation/' exact
					render={() => {
						if (this.props.location !== 'track-recommendation') {
							execute_middlewares();
							store_set('location', 'track-recommendation');
							store_set('navigation_location', false);
						}
						return <TrackRecommendation />;
					}}
				/>
				<Route path='/notifications/' exact
					render={() => {
						if (this.props.location !== 'notifications'){
							execute_middlewares();
							store_set('location', 'notifications');
							store_set('navigation_location', false);
						}
						return <NotificationsPage />;
					}}
				/>
				<Route
					render={() => {
						if (this.props.location !== 'default-error'){
							execute_middlewares();
							store_set('location', 'default-error');
						}
						return(<DefaultError/>);
					}}
				/>
			</AnimatedSwitch>
		);
	}
}

function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		user_token: state.GlobalReducer.user_token,
		redirect_location: state.GlobalReducer.redirect_location,
		should_redirect: state.GlobalReducer.should_redirect
	};
}

export default connect(mapStateToProps)(Routes);
