import React, { Component } from 'react';
import { connect } from 'react-redux';
import { request_buffer } from '@RequestBuffer';
import * as constants from '@constants';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { redirect } from '@routes/Routes.js';

// CSS Imports
import './styles/Hub.css';

// Components import
import AdUnit from '@components/AdUnit.js';
import Card from '@components/Card.js';
import Milestone from '@components/Milestone.js';
import ContentLoader from '@components/ContentLoader.js';
import { ClickAwayListener } from '@material-ui/core';

// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Assets import
import {
	magroove_premium_ad,
	milestone_discover_new,
	// milestone_find_friends,
	milestone_take_our_quiz,
	milestone_premium_subs,
	milestone_profile_pic,
	milestone_sync_streaming,
	milestone_create_playlist
} from '@Assets';

// Import controllers
import queue from '@Queue';
import subscription from '@Subscription';
import storage from '@Storage';
import social from '@Social';
import player from '@Player';
import user from '@User';

function load_recommendations(seed){
	const top_text = (seed.type === 'artist' || seed.type === 'genre' || seed.type === 'mood') ? seed.name : seed.title;
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', 'Now searching similar to:');
	store_set('loading_overlay_text', top_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
	queue.get_recommendations(seed.spotify_id, seed.type, false, true);
	store_set('active_overlay', false);
	store_set('seed', seed);
}

class BecauseYouLike extends Component {
	constructor(props){
		super(props);
		this.state = {
			artist: this.props.artist,
			recommendations: [],
			loading: false
		};

		this.get_recommendations = this.get_recommendations.bind(this);

		const value = storage.get('hub_recommendations_' + this.state.artist.spotify_id);
		if (value){
			this.state.recommendations = value;
		} else {
			this.state.loading = true;
			this.get_recommendations();
		}
	}

	get_recommendations(){
		const data = {};
		data.hash_id = this.state.artist.spotify_id;
		data.seed_type = 'artist';

		request_buffer.execute({
			type: 'post',
			data: data,
			url: constants.api_endpoint_recommendations,
			success: function(data){
				if (data.status === 'success'){
					this.setState({ recommendations: data.data });
					storage.set('hub_recommendations_' + this.state.artist.spotify_id, data.data.slice(0,10), 86400);
				}
			}.bind(this),
			complete: function(){
				this.setState({ loading: false });
			}.bind(this)
		});
	}

	render(){
		const { artist, loading, recommendations } = this.state;
		if (!loading && !recommendations){
			return null;
		}
		return (
			<div className='hub-section'>
				<div className='hub-section-similars'>
					<img src={artist.images.medium} alt='Artist' />
					<div className='hub-section-similars-info'>
						<div className='hub-section-similars-text'>Because you like</div>
						<div className='hub-section-similars-title'>{artist.name}</div>
					</div>
				</div>
				{loading ?
					<div className='hub-section-content'>
						<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
						<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
						<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
					</div>
					:
					<div className='hub-section-content'>
						{recommendations.slice(0,10).map((item, index) => (
							<Card
								key={index}
								data={item}
								type='big_card_song'
								bodyLink={'/track/' + item.spotify_id + '/'}
								onMoreClick={() => {store_set('active_overlay', 'track_options'); store_set('song_info', item); }}
							/>
						))}
					</div>
				}
			</div>
		);
	}
}

BecauseYouLike.propTypes = {
	artist: PropTypes.object
};

class Hub extends Component {
	constructor(props){
		super(props);
		this.state = {
			because_you_like_1: false,
			because_you_like_2: false,
			recently_heard_button: 'all',
			recently_heard_search: ''
		};

		this.recommendations_based_in_favorites = this.recommendations_based_in_favorites.bind(this);
		this.handle_follow_action = this.handle_follow_action.bind(this);

		// Keep friends recommendations list updated
		user.get_friends_recommendations();
		social.get_people_you_may_know();
	}

	componentDidMount(){
		const date = store_get('hub_date');
		const reload = !date || moment(date).isBefore(moment().subtract(1, 'hours'));

		const { new_releases, social_activity, user_favorites} = this.props;

		if (reload || !new_releases || new_releases.length === 0){
			store_set('new_releases', []);
			social.get_new_releases();
		}

		if (reload || !social_activity || social_activity.length < 3){
			social.get_activity(reload);
		}

		if (reload || !user_favorites || user_favorites.length === 0){
			store_set('user_favorites', []);
			social.get_user_favorites();
		} else {
			this.recommendations_based_in_favorites();
		}

		if (reload){
			store_set('hub_date', new Date());
		}
	}

	shouldComponentUpdate(nextProps){
		if (this.props.loading_user_favorites && !nextProps.loading_user_favorites){
			this.recommendations_based_in_favorites(nextProps.user_favorites);
		}

		return true;
	}

	recommendations_based_in_favorites(artists = this.props.user_favorites){
		const shuffle_artists = _.shuffle(artists);
		if (artists.length > 0){
			this.setState({ because_you_like_1: shuffle_artists[0] });
		} 
		if (artists.length > 1){
			this.setState({ because_you_like_2: shuffle_artists[1] });
		}
	}

	handle_follow_action(user_id) {
		if (!user_id) { return; }
		let people_you_may_know = [...this.props.people_you_may_know];
		let user = people_you_may_know.find(u => u.member_id === user_id);
		if (!user) { return; }
		
		user['followed'] = !user.followed;
		store_set('people_you_may_know', people_you_may_know);
	
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_follow_user_management,
			data: {user_id: user_id}
		}, true);
	}

	render(){
		const { because_you_like_1, because_you_like_2 } = this.state;
		const {
			is_member_premium,
			likes,
			loading_new_releases,
			loading_user_favorites,
			loading_social_activity,
			new_releases,
			recent_seeds,
			social_activity,
			user,
			user_favorites,
			friends_recommendations,
			people_you_may_know
		} = this.props;
		const animation_widths = [59.2, 19.4, 36.7, 49, 9.2, 59.2, 19.4, 36.7, 29.6, 49, 9.2, 59.2, 19.4, 36.7, 29.6, 49, 9.2, 59.2, 19.4, 36.7, 29.6, 49];
		const recently = recent_seeds ? (recent_seeds.length > 0 ? recent_seeds[0] : false) : false;
		const recently_playing = this.props.playing_song.spotify_id === recently.spotify_id;

		const footer = window.innerHeight < 750 ? 60 : 40;
		const beta_message = this.props.display_beta_message && window.innerWidth > 300 ? 50 : 0;

		const polls = this.props.polls.filter(item => !item.author);

		return (
			<div className='hub-container' style={{ minHeight:  window.innerHeight - footer - beta_message - this.props.safe_area_top }}>
				{recently && Object.keys(recently).length > 0 &&
					<div className='hub-header'>
						<div className='hub-header-background' style={{ backgroundImage: `url(${recently.album ? recently.album.images.medium : recently.images ? recently.images.medium : recently.image_link})`, height: `${window.innerHeight - 80}px` }}></div>
						<div className='hub-header-image'>
							<figure className='hub-header-image-container'>
								<img alt='' src={recently.album ? recently.album.images.medium : recently.images ? recently.images.medium : recently.image_link} onClick={() => redirect(`/${recently.type === 'song' ? 'track' : recently.type}/${recently.spotify_id || recently.hash_id}`)}></img>
								{recently.type === 'song' &&
									<span className='material-icons' onClick={recently_playing ? (e) => {e.preventDefault(); player.toggle();} : (e) => {e.preventDefault(); player.load(0, recently);}}>{(recently_playing && this.props.is_playing) ? 'pause' : 'play_arrow'}</span>
								}
							</figure>
							<div className='hub-header-song-animation'></div>
							<div className='hub-header-image-animation'>
								<ul className='hub-header-image-animation-list'>
									{[...Array(23).keys()].map((number) => {	
										// Setting position
										let radius = (337/2);
										let margin_top = 22.4;
										let height_item = 3;
										let padding = 10;
										let margin_circle_item = 20;
										let y = (12 - number) * (padding + height_item) - margin_top;
										let x = Math.sqrt(Math.pow(radius + margin_circle_item, 2) - Math.pow(y, 2));
										let position = x - radius;

										// Setting animation
										let width_item = animation_widths[number];
										let type_animation;
										if(width_item === 9.2) type_animation = 0;
										else if(width_item === 19.4) type_animation = 1;
										else if(width_item === 29.6) type_animation = 2;
										else if(width_item === 36.7) type_animation = 3;
										else if(width_item === 49) type_animation = 4;
										else if(width_item === 59.2) type_animation = 5;

										return(
											<li key={number} className={`hub-header-image-animation-item animation-item-type-${type_animation}`} style={{left: `${position}px`, width: `${width_item}px`}}></li>
										);})}		
								</ul>
							</div>
						</div>
						<div className='hub-header-content' onClick={() => redirect(`/${recently.type === 'song' ? 'track' : recently.type}/${recently.spotify_id}`)}>
							<div className='hub-header-recently-heard'>
								<span className='material-icons'>restore</span>
								<div>Recent seed</div>
							</div>
							<div className='hub-header-title'>{recently.title || recently.name}</div>
							<div className='hub-header-info'>
								{recently.type === 'song' &&
									<React.Fragment>
										<div className='hub-header-subtitle'>{recently.album.title || recently.album.name}</div>
										<div className='hub-header-subtitle'>•</div>
									</React.Fragment>
								}
								{recently.artist ?
									<div className='hub-header-subtitle'>{recently.artist.name}</div>
									: null
								}
							</div>
							{this.state.recently_heard_button === 'all' ?
								<div className='hub-header-button-container'>
									<div className='hub-header-button' onClick={(e) => {load_recommendations(recently); e.stopPropagation();}}>
										<span className='material-icons'>play_arrow</span>
										<div className='hub-header-button-text'>Resume Discovery</div>
									</div>
									<div className='hub-header-button' onClick={(e) => {this.setState({recently_heard_button: 'search'}); e.stopPropagation();}}>
										<span className='material-icons'>search</span>
										<div className='hub-header-button-text'>Search</div>
									</div>
								</div>
								:
								<ClickAwayListener onClickAway={(e) => {this.setState({recently_heard_button: 'all'}); e.stopPropagation();}}> 
									<div className='hub-header-search-container'>
										<input
											type='text'
											value={this.state.recently_heard_search}
											onClick={(e) => e.stopPropagation()}
											onChange={(text) => this.setState({recently_heard_search: text.target.value})}
											onKeyPress={(event) => {
												if (event.key === 'Enter' && this.state.recently_heard_search.length > 0) {
													let search_query = this.state.recently_heard_search;
													let new_search = '';

													search_query = search_query.split(' ');
													search_query.forEach((item, index) => {new_search += index === search_query.length - 1 ? item : `${item}+`;});												
													redirect(`/search?q=${new_search}`);
												}
											}}
											placeholder="Search for a song or artist..."
										/>
										{this.state.recently_heard_search.length === 0 ?
											<span className='material-icons' onClick={(e) => {
												if (this.state.recently_heard_search.length > 0) {
													let search_query = this.state.recently_heard_search;
													let new_search = '';

													search_query = search_query.split(' ');
													search_query.forEach((item, index) => {new_search += index === search_query.length - 1 ? item : `${item}+`;});											
													redirect(`/search?q=${new_search}`);
												}
												e.stopPropagation();
											}}>search</span>
											:
											<span className='material-icons' onClick={(e) => {this.setState({recently_heard_search: ''}); e.stopPropagation();}}>close</span>
										}
									</div>
								</ClickAwayListener>
							}
						</div>
					</div> 
				}
				<div className='hub-sections'>
					{friends_recommendations.length > 0 && 
						<div className='hub-section' id='friends-rec-section'>
							<div className='hub-section-title'>Your friends recommended to you</div>
							<div className='hub-section-content'>
								{friends_recommendations.map((data, index) => (
									<Card type='recommendation' data={data} key={index} onClick={() => {store_set('active_overlay', 'track_options'); store_set('song_info', data.recommendation);}} />
								))}
							</div>
						</div>
					}
					{polls.length > 0 &&
						<div className='hub-section'>
							<div className='hub-section-title'>Your friends are asking</div>
							<div className='hub-section-content'>
								{polls.map((item, index) => (
									<Card key={index} type='poll_card' data={item} user={user} />
								))}
							</div>
						</div>
					}
					{((Object.keys(user) && (!user.picture || this.props.user_quiz.length === 0 || user.picture.includes('default'))) || is_member_premium === false || likes.length === 0 || !this.props.linked_accounts.some(item => item.state === 'active')) &&
						<div className='hub-section'>
							<div className='hub-section-title'>Get started</div>
							<div className='hub-section-content'>
								{Object.keys(user) && (!user.picture || user.picture.includes('default')) &&
									<Milestone label='First Steps' image={milestone_profile_pic} text='Set your profile pic' onClick={() => redirect('/profile/')} />
								}
								{this.props.user_quiz.length === 0 &&
									<Milestone
										label='First Steps'
										image={milestone_take_our_quiz}
										text='Take our musical quiz'
										onClick={() => {const first = constants.quiz_list[0];
											store_set('quiz', {quiz: first, index: 0});
											redirect(`/quiz-search/${first.search}/`);
										}}
									/>
								}
								{/* <Milestone label='First Steps' image={milestone_find_friends} text='Find your friends' /> */}
								{is_member_premium === false && /* must be different from undefined! */
									<Milestone label='First Steps' image={milestone_premium_subs} text='Get a premium subscription' onClick={() => subscription.invite()} />
								}
								{likes.length === 0 &&
									<Milestone label='First Steps' image={milestone_discover_new} text='Discover new music' onClick={() => redirect('/search/')} />
								}
								{!this.props.linked_accounts.some(item => item.state === 'active') &&
									<Milestone label='First Steps' image={milestone_sync_streaming} text='Sync with your streaming' onClick={() => redirect('/sync-playlists/')} />
								}
								{this.props.playlists.length === 0 &&
									<Milestone label='First Steps' image={milestone_create_playlist} text='Create your first playlist' onClick={is_member_premium ? () => {store_set('create_playlist', true); store_set('playlist_overlay_option', 'internal');} : () => subscription.invite('Discover much more and get extra control over your songs!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'playlists_my_list')} />
								}
							</div>
						</div>
					}
					<AdUnit />
					{(Object.keys(user).length === 0 || recent_seeds.length > 0) &&
						<div className='hub-section'>
							<div className='hub-section-title'>Your recent seeds</div>
							<div className='hub-section-content'>
								{recent_seeds.length > 0 && recent_seeds.slice(0, 6).map((item, index) => (
									<Card key={index} type='seed_card' data={item} onClick={() => load_recommendations(item)} />
								))}
								{Object.keys(user).length === 0 ?
									<React.Fragment>
										<ContentLoader style={{minWidth: 243, height: 100, borderRadius: 6 }} />
										<ContentLoader style={{minWidth: 243, height: 100, borderRadius: 6 }} />
									</React.Fragment>
									: null
								}
							</div>
						</div>
					}
					<div className='hub-section'>
						<div className='hub-section-title'>What&apos;s new?</div>
						<div className='hub-section-content'>
							{new_releases && new_releases.map((item, index) => (
								<Card key={index} bodyLink={'/album/' + item.spotify_id + '/'} data={item} type='big_card_album'/>
							))}
							{loading_new_releases ?
								<React.Fragment>
									<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
									<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
									<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
								</React.Fragment>
								: null
							}
						</div>
					</div>
					{is_member_premium === false && /* must be different from undefined */
						<div className='hub-section'>
							<div className='hub-section-title'>Upgrade to try exclusive tools!</div>
							<div className='hub-section-premium' onClick={() => subscription.invite()}>
								<img src={magroove_premium_ad} alt='Invite to Premium Subscription'/>
							</div>
						</div>
					}
					{because_you_like_1 ?
						<BecauseYouLike artist={because_you_like_1} />
						: null
					}
					{!loading_social_activity && social_activity && social_activity.length > 0 &&
						<div className='hub-section'>
							<div className='hub-section-title'>Social activity</div>
							<div className='hub-section-content-row'>
								{social_activity.map((item, index) => (
									<Card key={index} type='user_seed_card' data={item} />
								))}
							</div>
						</div>
					}
					{(loading_user_favorites || (user_favorites && user_favorites.length > 0)) &&
						<div className='hub-section'>
							<div className='hub-section-title'>Your favorites</div>
							<div className='hub-section-content'>
								{user_favorites.map((item, index) => (
									<Card key={index} bodyLink={'/artist/' + item.spotify_id + '/'} data={item} type='big_card_artist'/>
								))}
								{loading_user_favorites ?
									<React.Fragment>
										<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
										<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
										<ContentLoader style={{minWidth: 155, height: 155, borderRadius: 4 }} />
									</React.Fragment>
									: null
								}
							</div>
						</div>
					}
					{because_you_like_2 ?
						<BecauseYouLike artist={because_you_like_2} />
						: null
					}
					{people_you_may_know.length > 0 &&
						<div className='hub-section'>
							<div className='hub-section-title'>People You May Know</div>
							<div className='hub-section-content-scroll'>
								{people_you_may_know.map((user, index) => (
									<Card key={index}
										type='user_alternative_card' data={user}
										onFollow={() => this.handle_follow_action(user.member_id)}
									/>
								))}
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_member_premium: state.GlobalReducer.is_member_premium,
		display_beta_message: state.GlobalReducer.display_beta_message,
		safe_area_top: state.GlobalReducer.safe_area_top,
		linked_accounts: state.GlobalReducer.linked_accounts,
		loading_new_releases: state.GlobalReducer.loading_new_releases,
		loading_social_activity: state.GlobalReducer.loading_social_activity,
		loading_user_favorites: state.GlobalReducer.loading_user_favorites,
		user_favorites: state.GlobalReducer.user_favorites,
		social_activity: state.GlobalReducer.social_activity,
		new_releases: state.GlobalReducer.new_releases,
		recent_seeds: state.GlobalReducer.recent_seeds,
		likes: state.GlobalReducer.likes,
		user: state.GlobalReducer.user,
		is_playing: state.GlobalReducer.is_playing,
		playing_song: state.GlobalReducer.playing_song,
		playlists: state.GlobalReducer.playlists,
		friends_recommendations: state.GlobalReducer.friends_recommendations,
		people_you_may_know: state.GlobalReducer.people_you_may_know,
		user_quiz: state.GlobalReducer.user_quiz,
		polls: state.GlobalReducer.polls,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(Hub);