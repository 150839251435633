import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// CSS Imports
import './styles/BetaMessage.css';

class BetaMessage extends React.Component {
	render() {
		if (this.props.display_beta_message){
			return (
				<Link to='/contact-us/' className='beta-link'>
					<div className='beta-content'>
						This is a beta version. If you encounter any problems or would just like to send us a message, tap <span>here</span>.
					</div>
				</Link>
			);
		}
		return(<div></div>);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		display_beta_message: state.GlobalReducer.display_beta_message
	};
}

// Connect component to the store
export default connect(mapStateToProps)(BetaMessage);