import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Textfield, Selectfield, Selectitem } from '@magroove/magroove-ui';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { createMask } from 'string-mask-jedi';
import Cookies from 'js-cookie';

// Components and Views Imports
import Haptic from '@components/haptic.js';

// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Constants import
import * as constants from '@constants';

// CSS Imports
import './styles/PremiumOverlay.css';

// Assets Imports
import {
	orange_common_badge,
	orange_sparkles_badge,
	premium_badge,
	premium_credit_card
} from '@Assets';

// Import the controllers
import subscription from '@Subscription';
import subscribe_ab_test from '../SubscribeAB.js';

// Badge Animation
import Lottie from 'react-lottie-player';
import badge_display_animation from '../assets/badge_display_animation.json';

// Import the event bus
import event_bus from '@Eventbus';

// We use Stripe if user is using the app on web
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Import controllers
import app_analytics from '@Analytics';

// Import components
import {
	PremiumOverlayDefault,
	PremiumOverlayA,
	PremiumOverlayB,
	PremiumOverlayC,
	PremiumOverlayD,
	PremiumOverlayE,
	PremiumOverlayF
} from '@components/PremiumVariations.js';

const creditCardType = require('credit-card-type');

function StripeHook(props) {
	const stripe = useStripe();
	const data_dict = {
		plan: props.plan,
		price: props.price,
		card_number: props.card_number,
		expire_date: props.card_expire_date,
		cvc: props.card_cvc,
		card_holder_name: props.card_holder_name,
		card_country: props.card_country,
		card_type: props.card_type,
	};

	const confirm_credit_card_purchase = () => {
		if (
			!props.card_number ||
			!props.card_expire_date ||
			!props.card_cvc ||
			!props.card_holder_name ||
			!props.card_country
		) {
			store_set('snackbar_message', 'Please, fill in all the fields...');
			store_set('snackbar_status', true); return;
		}

		subscription.order(props.plan, data_dict).then((response) => {
			if (response.status === 'requires_action') {
				stripe.confirmCardPayment(response.client_secret).then(result => {
					if (result.error) {
						this.props.response('error', 'Sorry, an error occurred while trying to confirm your payment. Please, try again...');
						return;
					}
					var pi_id = result.paymentIntent.id;
					subscription.handle_card_action(pi_id, data_dict);
				});
			}
		});
	};

	return (
		<Haptic>
			<Button fullWidth={true} size='small' value='SUBSCRIBE' onClick={confirm_credit_card_purchase} />
		</Haptic>
	);
}

class PremiumOverlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display_badge: false,
			display_content: false,
			display_form: false,
			step: 'choose_plan',
			plan: 'yearly',
			direction: 1,
			feature: false,
			overlay_title: '',
			overlay_subtitle: '',
			response_dict: false,
			button_disabled: false,
			countdown_to_allow_recommendation: false,

			// Card Info
			card_expire_date: '',
			card_number: '',
			card_holder_name: '',
			card_country: '',
			card_cvc: '',
			card_type: ''
		};
		this.initial_state = Object.assign({}, this.state);
		this.stripePromise = loadStripe(this.props.stripe_publishable_key);

		this.is_renewal = false;
		this.display_plans = this.display_plans.bind(this);
		this.previous = this.previous.bind(this);
		this.order = this.order.bind(this);
		this.edit_card = this.edit_card.bind(this);
		this.invite = this.invite.bind(this);
		this.on_complete = this.on_complete.bind(this);
		this.require = this.require.bind(this);
		this.response = this.response.bind(this);
		this.reset_state = this.reset_state.bind(this);

		event_bus.on('order-premium', this.order);
		event_bus.on('invite-premium', this.invite);
		event_bus.on('require-premium', this.require);
		event_bus.on('response-premium', this.response);
		event_bus.on('back-button-premium', this.reset_state);
		event_bus.on('display-plans', this.display_plans);
	}

	display_plans() {
		this.setState({ display_form: true });
		app_analytics.log_event('choose_plan');
		app_analytics.user_event('unfinished_premium_subs');
	}

	previous() {
		// If one of the following conditions, just close the feedback overlay
		if (
			Capacitor.getPlatform() === 'ios' ||
			Capacitor.getPlatform() === 'android' ||
			this.state.step === 'choose_plan' ||
			(this.state.step === 'response' &&
				this.state.response_dict.status === 'success')
		) {
			this.reset_state();
			return;
		}

		let new_state = {};
		if (this.state.step === 'credit_card') {
			new_state = { step: 'choose_plan' };
		}
		else if (this.state.step === 'response') {
			new_state = { step: 'credit_card', response_dict: false };
		}
		this.setState(new_state);
	}

	order(plan = this.state.plan) {
		if (
			Capacitor.getPlatform() === 'ios' ||
			Capacitor.getPlatform() === 'android'
		) {
			subscription.order(plan);
		}
		else {
			this.setState({ display_form: true, step: 'credit_card', plan: plan });
		}

		const amount = store_get('premium_' + plan + '_subscription_price');
		app_analytics.log_event('offer_payment');
		app_analytics.log_event('InitiateCheckout', { amount: amount });
	}

	edit_card(new_value, type) {
		if (type === 'card_number') {
			const card_number_mask = createMask('dddd dddd dddd dddd', { d: /\d/ });
			this.setState({ card_number: card_number_mask.run(new_value).value });

			var credit_card = creditCardType(new_value);
			if (credit_card.length > 0) {
				this.setState({ card_type: credit_card[0].type });
			}
		}
		else if (type === 'expire_date') {
			const expire_date_mask = createMask('dd/dd', { d: /\d/ });
			this.setState({ card_expire_date: expire_date_mask.run(new_value).value });
		}
		else if (type === 'cvc') {
			const cvc_mask = createMask('d', { d: /\d+/ });
			this.setState({ card_cvc: cvc_mask.run(new_value).value });
		}
		else if (type === 'card_holder_name') {
			this.setState({ card_holder_name: new_value.toUpperCase() });
		}
		else if (type === 'card_country') {
			this.setState({ card_country: new_value });
		}
	}

	invite(title, subtitle, action) {
		store_set('premium_overlay', true);
		if (store_get('premium_overlay_type') === 'default') {
			this.setState({ display_badge: true, feature: false, overlay_title: title, overlay_subtitle: subtitle });
		} else {
			this.setState({ display_content: 'invite', overlay_title: title, overlay_subtitle: subtitle });
		}
		app_analytics.log_event('premium_overlay', { action: action });

		this.subscribe_ab_test();
	}

	on_complete() {
		const content = this.state.feature ? 'require' : 'invite';
		this.setState({ display_content: content, display_badge: false });
	}

	reset_state() {
		store_set('premium_overlay', false);
		const new_state = Object.assign({}, this.initial_state);
		this.setState(new_state);
	}

	require(icon, description, title, feature) {
		// If user reached the limit of recommendations, 
		// we'll show him how much time left to swipe again
		let countdown = Cookies.get('countdown_to_allow_recommendation');
		if (countdown && feature === 'unlimited_recommendations') {
			var time_start = new Date().getTime();
			var time_end = new Date(countdown).getTime();
			var miliseconds_difference = time_end - time_start; // in miliseconds
			var minutes_difference = miliseconds_difference / 60 / 1000; // in minutes
			var hours_difference = miliseconds_difference / 3600 / 1000; // in hours
			var total_time = {};
			total_time.hours = Math.floor(hours_difference);
			total_time.minutes = parseInt(minutes_difference - 60 * total_time.hours);
			this.setState({ countdown_to_allow_recommendation: total_time });
		}

		store_set('premium_overlay', true);
		if (store_get('premium_overlay_type') === 'default') {
			this.setState({ display_badge: true, feature: { title: title, icon: icon, description: description } });
		} else {
			this.setState({ display_content: 'require', feature: { title: title, icon: icon, description: description } });
		}

		app_analytics.log_event('premium_overlay', { feature: feature });

		this.subscribe_ab_test();
	}

	response(status, text = false) {
		let response_dict = {};
		response_dict['text'] = text;
		response_dict['status'] = status;
		this.setState({ display_form: true, step: 'response', response_dict: response_dict });
	}

	subscribe_ab_test() {
		const ab_pricing_test = store_get('ab_pricing_test');
		if (ab_pricing_test) {
			subscribe_ab_test(ab_pricing_test.hash_id, ab_pricing_test.group);
		}

		const ab_overlay_test = store_get('ab_premium_overlay_test');
		if (ab_overlay_test) {
			subscribe_ab_test(ab_overlay_test.hash_id, ab_overlay_test.group);
		}
	}

	render() {
		if (!this.state.display_badge && !this.state.display_form && !this.state.display_content) {
			return (<div></div>);
		}
		if (this.state.display_form) {
			const step_width = this.state.step === 'choose_plan' ? '33%' : this.state.step === 'credit_card' ? '67%' : '100%';
			return (
				<div className='premium-form' style={{ top: this.props.safe_area_top }}>
					<div className='premium-form-stepper' style={{ width: step_width }}></div>
					{this.state.response_dict.status !== 'error' &&
						<div className='premium-form-header'>
							<div className='premium-form-header-icon' onClick={this.previous}>
								<span className="material-icons">keyboard_backspace</span>
							</div>
							<div className='premium-form-header-text'>Premium Subscription</div>
						</div>
					}
					{this.state.step === 'choose_plan' &&
						<React.Fragment>
							<div className='premium-form-container'>
								<div className='premium-form-section-title'>Choose a plan:</div>
								<div className='premium-form-plan-item'>
									<div className='premium-form-plan-header'>
										<img className='premium-form-plan-icon'
											src={orange_common_badge}
											style={{ width: 105, marginRight: 9, marginLeft: '-5px' }}
											alt="Orange badge with no sparkles"
										/>
										<div className='premium-form-plan-content'>
											<div className='premium-form-plan-price'>
												<span>${this.props.monthly_price} USD </span><span>/ month</span>
											</div>
											<div className='premium-form-plan-info'>
												Billed monthly, get access to all benefits and discover much more with Magroove Premium!
											</div>
										</div>
									</div>
									<div className='premium-form-plan-body'>
										<div className='premium-form-plan-list'>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Fine Tuning</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Perfect Match</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>No sponsored content</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Playlist Generator</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>And much more!</div>
											</div>
										</div>
										<Button size="small" variant="text" value="SUBSCRIBE" onClick={() => this.order('monthly')} />
									</div>
								</div>
								<div className='premium-form-plan-item-selected'>
									<div className='premium-form-plan-star'>
										<span className='material-icons'>star</span>
									</div>
									<div className='premium-form-plan-header'>
										<img className='premium-form-plan-icon'
											src={orange_sparkles_badge}
											alt='Orange badge with sparkles'
										/>
										<div className='premium-form-plan-content'>
											<div className='premium-form-plan-price-yearly'>${this.props.monthly_price * 12} USD</div>
											<div className='premium-form-plan-price'>
												<span>${this.props.yearly_price} USD </span><span>/ year</span>
											</div>
											<div className='premium-form-plan-info'>
												Billed yearly, get access to all benefits and discover much more with Magroove Premium!
											</div>
										</div>
									</div>
									<div className='premium-form-plan-body'>
										<div className='premium-form-plan-list'>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Fine Tuning</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Perfect Match</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>No sponsored content</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>Playlist Generator</div>
											</div>
											<div className='premium-form-plan-list-item'>
												<span className='material-icons'>check</span>
												<div className='premium-form-plan-info'>And much more!</div>
											</div>
										</div>
										<Button size="small" value="SUBSCRIBE" onClick={() => this.order('yearly')} />
									</div>
								</div>
								{Capacitor.getPlatform() === 'ios' &&
									<Button fullWidth={true} size='small' value='RESTORE PURCHASES' onClick={subscription.restore} variant='text' main_color='rgba(236,107,67,.2)' />
								}
							</div>
						</React.Fragment>
					}
					{this.state.step === 'credit_card' &&
						<React.Fragment>
							<div className='premium-form-container'>
								<div className='premium-form-section-title'>Payment Info</div>
								<Textfield
									value={this.state.card_number}
									fullWidth={true} theme='dark'
									size='small' label='Card number'
									onChange={(value) => this.edit_card(value, 'card_number')}
								/>
								<div className='premium-form-textfields'>
									<Textfield
										value={this.state.card_expire_date}
										fullWidth={true} theme='dark'
										size='small' label='Expire date'
										onChange={(value) => this.edit_card(value, 'expire_date')}
									/>
									<Textfield
										value={this.state.card_cvc}
										fullWidth={true} theme='dark'
										size='small' label='CVC/CVV'
										onChange={(value) => this.edit_card(value, 'cvc')}
									/>
								</div>
								<Textfield
									value={this.state.card_holder_name}
									fullWidth={true} theme='dark'
									size='small' label='Cardholder name'
									onChange={(value) => this.edit_card(value, 'card_holder_name')}
								/>
								<Selectfield
									theme='dark'
									value={this.state.card_country}
									fullWidth={true}
									size='small' label='Country'
									onChange={(value) => this.edit_card(value, 'card_country')}
									options={
										constants.countries.map((item, index) => (
											<Selectitem value={item.value} key={index} title={item.country} size='small' />
										))
									}
								/>
							</div>
							<div className='premium-form-bottom'>
								<div className='premium-form-section-title'>Your plan</div>
								<div className={'premium-form-selected-plan' + (this.state.plan === 'yearly' ? ' premium-form-plan-item-selected' : ' premium-form-plan-item')}>
									<img className='premium-form-plan-icon' src={this.state.plan === 'yearly' ? orange_sparkles_badge : orange_common_badge} alt={this.state.plan === 'yearly' ? 'Orange badge with sparkles' : 'Orange badge with no sparkles'} />
									{this.state.plan === 'monthly' &&
										<div className='premium-form-plan-content'>
											<div className='premium-form-plan-price'>
												<span>${this.props.monthly_price} USD</span><span>/month</span>
											</div>
											<div className='premium-form-plan-info'>
												Billed monthly, get access to all benefits and discover much more with Magroove Premium!
											</div>
										</div>
									}
									{this.state.plan === 'yearly' &&
										<div className='premium-form-plan-content'>
											<div className='premium-form-plan-price'>
												<span>${this.props.yearly_price} USD</span><span>/year</span>
											</div>
											<div className='premium-form-plan-info'>
												Billed yearly, get access to all benefits and discover much more with Magroove Premium!
											</div>
										</div>
									}
								</div>
								<div className='premium-response-buttons'>
									<Elements stripe={this.stripePromise}>
										<StripeHook {...this.state} response={this.response} price={this.props[this.state.plan + '_price']} />
									</Elements>
								</div>
							</div>
						</React.Fragment>
					}
					{this.state.step === 'response' &&
						<React.Fragment>
							<div className='premium-response-container'>
								<div className='premium-response-container-image' >
									<img src={this.state.response_dict.status === 'success' ? premium_badge : premium_credit_card} alt='' />
								</div>
								<div className='premium-response-container-title'>
									{this.state.response_dict.status === 'success' &&
										<React.Fragment>
											<span className='premium-response-container-title-details'>PAYMENT SUCCESSFUL!</span>
											<span className="material-icons">done</span>
										</React.Fragment>
									}
									{this.state.response_dict.status === 'error' &&
										<span className='premium-response-container-title-details'>PAYMENT REFUSED :(</span>
									}
								</div>
								<div className='premium-response-container-subtitle'>
									{this.state.response_dict.status === 'success' && 'Welcome to Magroove Premium!'}
									{this.state.response_dict.status === 'error' && 'Something has gone wrong!'}
								</div>
								{this.state.response_dict.text &&
									<div className='premium-response-container-text'>{this.state.response_dict.text}</div>
								}
								{!this.state.response_dict.text &&
									<div className='premium-response-container-text'>
										{this.state.response_dict.status === 'success' && 'Discover much more and get extra control over your songs: unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!'}
										{this.state.response_dict.status === 'error' && 'Make sure the card numbers, expiring date and CVV are valid.'}
									</div>
								}
							</div>
							<div className='premium-response-buttons'>
								{this.state.response_dict.status === 'success' &&
									<div className='premium-response-response-button'>
										<Link to='/' onClick={this.reset_state}>
											START EXPLORING
										</Link>
									</div>
								}
								{this.state.response_dict.status === 'error' &&
									<Button fullWidth={true} size='small' value='BACK' onClick={this.previous} />
								}
							</div>
						</React.Fragment>
					}
				</div>
			);
		}

		const { premium_overlay_type } = this.props;
		if (premium_overlay_type === 'A') {
			return <PremiumOverlayA {...this} />;
		}
		if (premium_overlay_type === 'B') {
			return <PremiumOverlayB {...this} />;
		}
		if (premium_overlay_type === 'C') {
			return <PremiumOverlayC {...this} />;
		}
		if (premium_overlay_type === 'D') {
			return <PremiumOverlayD {...this} />;
		}
		if (premium_overlay_type === 'E') {
			return <PremiumOverlayE {...this} />;
		}
		if (premium_overlay_type === 'F') {
			return <PremiumOverlayF {...this} />;
		}

		// Overlay default - badge animation
		if (this.state.display_badge) {
			return (
				<div className='premium-overlay' style={{ justifyContent: 'center' }}>
					<div className='premium-overlay-badge'>
						<Lottie
							speed={1.3}
							loop={false}
							play={true}
							animationData={badge_display_animation}
							onComplete={this.on_complete}
							direction={this.state.direction}
						/>
					</div>
				</div>
			);
		}
		return <PremiumOverlayDefault {...this} />;
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		premium_overlay_type: state.GlobalReducer.premium_overlay_type,
		monthly_price: state.GlobalReducer.premium_monthly_subscription_price,
		yearly_price: state.GlobalReducer.premium_yearly_subscription_price,
		stripe_publishable_key: state.GlobalReducer.stripe_publishable_key,
		safe_area_top: state.GlobalReducer.safe_area_top
	};
}

// Connect component to the store
export default connect(mapStateToProps)(PremiumOverlay);