import { throttle } from 'throttle-debounce';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import { Preferences } from '@capacitor/preferences';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Constant Imports
import * as constants from '@constants';

// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Import the controllers
import queue from '@Queue';
import player from '@Player';
import list from './List.js';
import app_analytics from './Analytics.js';
import subscription from '@Subscription';
import user from '@User';
import playlist_manager from './PlaylistsController.js';
import ads from './Ads.js';
import subscribe_ab_test from './SubscribeAB.js';

// Import the event bus
import event_bus from './Eventbus.js';

class InteractionController{
	constructor() {
		this.history = [];
		this.likes = 0;
		this.premium_count = 0;
		this.update_count = 0;

		this.ads_count = 0;
		this.ads_displayed = 0;
		this.ads_interval = Math.floor(Math.random() * 5) + 5; // Random number between 5 and 9

		this.history_mounted = false;
		this.countdown = false;
		this.requested_review = false;
		this.invited_friends = false;

		this.undo = this.undo.bind(this);
		this.like = this.like.bind(this);
		this.dislike = this.dislike.bind(this);
		this.timeout = this.timeout.bind(this);
		this.disable_interactions = this.disable_interactions.bind(this);
		this.enable_interactions = this.enable_interactions.bind(this);
		this.start_countdown = this.start_countdown.bind(this);
		this.save_in_history = this.save_in_history.bind(this);

		this.ask_for_auto_recommendation = this.ask_for_auto_recommendation.bind(this);
		this.set_max_swipes =  this.set_max_swipes.bind(this);

		this.current_streak_analysis = this.current_streak_analysis.bind(this);
		this.check_daily_invitation = this.check_daily_invitation.bind(this);
		this.check_update_invite = this.check_update_invite.bind(this);
		this.display_ads = this.display_ads.bind(this);
		this.get_history_list = this.get_history_list.bind(this);
		event_bus.on('play', this.start_countdown);
		event_bus.on('end', this.timeout);
	}

	start_countdown(){
		if (!this.countdown){
			this.countdown = setTimeout(function() {
				var is_playing = store_get('is_playing');
				if (is_playing){
					player.pause();
					store_set('active_overlay', 'max_timeouts');
				}
				this.countdown = false;
			}.bind(this), constants.max_seconds_with_no_interactions*1000);
		}
	}

	stop_countdown(){
		if (this.countdown){
			clearTimeout(this.countdown);
			this.countdown = false;
		}
		// If its playing, (re)start the countdown
		var is_playing = store_get('is_playing');
		if (is_playing){
			this.start_countdown();
		}
	}

	update_remote_status(song, status, timestamp = 0, interval = 30000, retry = false){
		// Only save interaction if timestamp > 0 (Except status = removed)
		if (status !== 'removed' && timestamp === 0) return;

		if (!retry){
			this.check_daily_invitation();
			this.check_update_invite();
		}

		// IMPORTANT - Keep track of the successfully synced songs,
		// for the non successful, schelude a new ajax for t+X seconds
		// where X is double the previous interval time

		// IMPORTANT 2 - If timestamp = 0, the API should leave the current value stored
		// in the database. It should NOT update it to 0. (the the List.js file for an example case)
		var data = {};

		// The song to be added to the liked list
		if (song.spotify_id) {
			data['hash_id'] = song.spotify_id;
			data['source'] = 'spotify';
		} else if (song.hash_id) {
			data['hash_id'] = song.hash_id;
			data['source'] = 'magroove';
		} else {
			// For some reason, some songs are entering here as it doesn't contain a hash_id or spotify_id.
			// This way, when calling the ajax to save the interaction, the API is throwing an error because
			// the hash_id key in data is needed.
			new Error('Interaction error');
			return;
		}

		// The status of the interaction to be saved (liked, disliked, ...)
		data['status'] = status;

		// The timestamp at which the song was liked within the song duration
		data['timestamp'] = timestamp;

		if (song.from_queue){
			const seed = store_get('seed');
			data['seed'] = JSON.stringify(seed);
		}

		// Set config to save fb event
		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: status,
			event_source_url: window.location.pathname,
			custom_params: {timestamp: timestamp},
			device_info: device_info,
			member_hash: user.hash_id
		}
		data['event_details'] = JSON.stringify(event_details);

		var request = {
			type: 'post',
			url: constants.api_endpoint_interaction,
			data: data,
			post_login: {
				action: 'execute',
				type: 'interaction',
				params: {
					page: '/track/' + data['hash_id'],
					status: status,
					song: song,
					timestamp: timestamp
				}
			},
			success: function(data){
				if (data.last_day){
					store_set('last_day_recommendations_total', data.last_day);
					store_set('last_day_first_interaction', data.last_day_first_interaction);
				}
			},
			error: function(){
				// Schedule a new ajax
				setTimeout(function() {this.update_remote_status(song, status, timestamp, interval*2);}.bind(this), interval, true);
			}.bind(this)
		};

		request_buffer.auth_execute(request, true);
	}

	like(player_delay = 0, song = false){
		this.current_streak_analysis();

		if (!this.allow_recommendation()) return; 

		if (this.throttle_like === undefined){
			var total_time = constants.minimum_waiting_time*1000 + constants.swipe_animation_duration;
			this.throttle_like = throttle(total_time, (player_delay) => {
				// Get time and date when user clicked "like" button
				let created_at = new Date().toISOString();

				const current_song = song ? song : store_get('playing_song');
				current_song.created_at = created_at;
				const timestamp = store_get('current_time');

				// Update the interaction status in the database
				this.update_remote_status(current_song, 'like', timestamp);

				// Add song to active playlist
				let active_playlist = store_get('active_playlist');
				playlist_manager.update_active_playlist_tracks(current_song, active_playlist);
		
				// Retrieve the current list
				const likes_list = store_get('likes');

				// If the current song is not in the list, add it to the list
				const current_song_already_in_list = likes_list.find(item => item.spotify_id === current_song.spotify_id);
				if (!current_song_already_in_list){
					list.add(current_song);
				}
				
				// Update the interaction status in the client side
				this.update_statistics(current_song, 'like', timestamp);

				this.ask_for_auto_recommendation();
				this.review_app();

				const dont_auto_play = this.display_ads();

				// Get a new song for the player
				player.load(player_delay, false, dont_auto_play);

				this.save_in_history(current_song);	
			});
		}
		this.throttle_like(player_delay);
	}

	dislike(player_delay = 0, song = false){
		this.current_streak_analysis();

		if (!this.allow_recommendation()) return; 

		if (this.throttle_dislike === undefined){
			var total_time = constants.minimum_waiting_time*1000 + constants.swipe_animation_duration;
			this.throttle_dislike = throttle(total_time, (player_delay) => {
				const current_song = song ? song : store_get('playing_song');
				const timestamp = store_get('current_time');

				// Update the interaction status in the database
				this.update_remote_status(current_song, 'dislike', timestamp);

				// Update the interaction status in the client side
				this.update_statistics(current_song, 'dislike', timestamp);

				const dont_auto_play = this.display_ads();

				// Get a new song for the player
				player.load(player_delay, false, dont_auto_play);

				this.save_in_history(current_song);
			});
		}

		this.throttle_dislike(player_delay);
	}

	timeout(){
		this.current_streak_analysis();

		if (!this.allow_recommendation()) return; 

		if(isMobile && user.is_logged()){
			const current_song = store_get('playing_song');
			const timestamp = store_get('current_time');
	
			// Update the interaction status in the database
			this.update_remote_status(current_song, 'timeout', timestamp);

			// Update statistics
			this.update_statistics(current_song, 'timeout', timestamp);

			const dont_auto_play = this.display_ads();

			// Get a new song for the player
			player.load(0, false, dont_auto_play);

			this.save_in_history(current_song);
		}
	}

	undo(){
		// If the user doesn’t have a premium subscription, display an overlay
		// telling him he can only unlock this feature by subscribing.
		if (!subscription.is_valid()){
			subscription.require('undo', 'Subscribing now grants you unlimited rollbacks!', 'undo_interaction');
			player.pause(); return;
		}

		// Place the current song back in the queue
		const playing_song = store_get('playing_song');
		queue.push(playing_song);

		// If there's no songs in history, use the last liked songs
		// it will only enter here the first time to avoid the loop
		if (this.history.length === 0 && !this.history_mounted){
			const likes = store_get('likes');
			this.history = likes.slice(0,10).reverse();
			this.history_mounted = true;
		}

		// Get the last song and reload it
		const last_song = this.history.pop();
		if (last_song !== undefined){
			player.load(0, last_song);
		}
	}

	update_statistics(song, action, timestamp){
		// Retrieve the current list
		const likes_list = store_get('likes');
		const new_likes_list = store_get('new_likes_list');

		// Get statistics
		const user_info = store_get('user');

		const current_song_already_in_list = likes_list.find(item => item.spotify_id === song.spotify_id);
		if (current_song_already_in_list){
			if (action === 'dislike'){
				list.remove(song);
				user_info.likes -= 1;
			}
		} else {
			// Only increment tracks and likes if the current song wasn't in the likes list
			user_info.tracks += 1;

			if (action === 'like'){
				list.add(song);
				user_info.likes += 1;
			}
		}

		if (action === 'like'){
			store_set('new_likes', true);
			store_set('new_likes_list', [...new_likes_list, song.spotify_id]);
		}

		user_info.listened += timestamp;
		store_set('user', user_info);
	}

	allow_recommendation(){
		const total = store_get('last_day_recommendations_total');
		const maximum = store_get('premium_max_swipes_per_day');
		const reached_limit = total >= maximum;

		// The amount of swipes (like or dislike) a user can do per day is limited.
		// If he reaches that limit and he is not a premium user, then simply
		// display the invitation to premium overlay with a message.
		if (!subscription.is_valid() && reached_limit){
			if(!Cookies.get('countdown_to_allow_recommendation')){
				// Create new date to be 1 day in the future
				const last_day_first_interaction = store_get('last_day_first_interaction');
				let date_start_count = last_day_first_interaction ? new Date(last_day_first_interaction).getTime() : new Date().getTime();
				let in_one_day = new Date(new Date(date_start_count) + 24 * 60 * 60 * 1000);
				// Create a cookie that expires 1 day from now
				Cookies.set('countdown_to_allow_recommendation', in_one_day, { expires: in_one_day });

				const ab_test_value = this.set_max_swipes();
				if (ab_test_value) {
					subscribe_ab_test('ok1Chae9no', ab_test_value);
				}
			}

			app_analytics.user_event('run_out_of_swipes');
			const title = 'You reached the limit of recommendations for today!';
			subscription.require('thumbs_up_down', 'Subscribing now grants you unlimited swipes!', 'unlimited_recommendations', title);
			player.pause();
			return false;
		}

		// Increase the counter - this allow us to not depend of the API response
		// but in case in the API returns a value, we update the store.
		store_set('last_day_recommendations_total', total + 1);
		return true;
	}

	save_in_history(song){
		// Keep track of the history of songs so the user can go back to the previous track
		// to “like” it or “dislike” it again.
		this.history = [...this.history, song];
	}

	disable_interactions(){
		// To be developed
	}

	enable_interactions(){
		// To be developed
	}

	// Everytime user finish all recommendations from a seed, we'll display a overlay to inform him about it
	// In this overlay we'll just display some numbers of interactions he made, and also encourage him to continue using the app
	// This function is only to store the statistics info
	current_streak_analysis(){
		const timestamp = store_get('current_time');
		let seed = store_get('seed');
		let previous_seed = store_get('previous_seed');
		let current_streak = store_get('current_streak');
		let current_streak_total_songs = store_get('current_streak_total_songs');
		let current_streak_total_time = store_get('current_streak_total_time');

		// Check if user changes seed, we'll reset values and start a new streak
		if (JSON.stringify(seed) !== JSON.stringify(previous_seed)) {
			current_streak += 1;
			current_streak_total_songs = 0;
			current_streak_total_time = 0;
			store_set('previous_seed', seed);
			store_set('current_streak', current_streak);
		}
		current_streak_total_songs += 1;
		current_streak_total_time += timestamp;
		
		store_set('current_streak_total_songs', current_streak_total_songs);
		store_set('current_streak_total_time', current_streak_total_time);
	}

	check_daily_invitation(){
		// We'll invite user once per day after the Nth interaction on that day
		const daily_invitation = Cookies.get('daily_invitation');

		// After N tracks, auto pause and display invitation to premium, display invitation for premium
		// if the user is not premium yet.
		if (!daily_invitation){
			const tracks = store_get('premium_waiting_tracks_to_premium_invite');
			if (this.premium_count > tracks){
				this.premium_count = 1;
			} else if (this.premium_count < tracks){
				this.premium_count = this.premium_count + 1;
			} else if (!subscription.is_valid()){
				setTimeout(() => player.pause(), 2000);
				subscription.invite('Discover new music with no restrictions!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'invite_after_nth_interaction');
				this.premium_count = 0;

				// Create a cookie that expires 1 day from now
				Cookies.set('daily_invitation', 'true', { expires: 1 });
			}
		}
	}

	set_max_swipes() {
		const user_token = user.check_for_token();
		if (user_token) { 
			let user_hash = store_get('user').hash_id;
			if (!user_hash) { return; }

			// We'll find where in these groups is the last character of user's hash_id and apply
			// the max number of swipes according to the group
			const groups = ['abcdefghi', 'jklmnopqr', 'stuvxwyz0', '123456789'];
			let max_swipes = 20;
			let value = groups.findIndex(group => group.includes(user_hash.slice(-1)));
			switch (value) {
			case 3:
				max_swipes = 25;
				break;
			case 2:
				max_swipes = 20;
				break;
			case 1:
				max_swipes = 15;
				break;
			case 0:
				max_swipes = 10;
				break;
			default:
				break;
			}
			store_set('premium_max_swipes_per_day', max_swipes);

			// Create a cookie that expires 1 day from now
			Cookies.set('premium_max_swipes_per_day_' + user_hash, max_swipes, { expires: 1 });

			return value;
			
		}
	}

	get_history_list(){
		return [...this.history];
	}

	async check_update_invite(){
		// If it's a suggestion to update, we will wait for a couple of 
		// interactions before displaying the overlay.
		const waiting_tracks_to_update_invite = 2;
		if (['suggest', 'suggest_base'].includes(store_get('version_overlay'))){
			if (this.update_count > waiting_tracks_to_update_invite){
				this.update_count = 1;
			} else if (this.premium_count < waiting_tracks_to_update_invite){
				this.update_count = this.update_count + 1;
			} else {
				setTimeout(() => player.pause(), 2000);

				// Display overlay
				store_set('invite_to_update', true);
				this.update_count = 0;

				// Update the time the overlay is being displayed
				const info = JSON.parse((await Preferences.get({ key: 'version_info' })).value);
				info.last_invite_overlay = new Date();
				await Preferences.set({ key: 'version_info', value: JSON.stringify(info) });
			}
		}
	}

	ask_for_auto_recommendation(){
		const user = store_get('user');

		// After 3 likes, ask the user if they allow auto recommendation
		this.likes = this.likes + 1;
		if (this.likes === 3 && user.auto_recommendation === 'Undefined'){
			store_set('secondary_overlay', 'auto_recommendation');
			return true;
		}
		return false;
	}

	review_app(){
		const likes = store_get('likes');
		if (likes.length === 10){
			event_bus.emit('review-app', null, 'first_likes');
		}
		else if (subscription.is_valid()){
			event_bus.emit('review-app', null, 'likes_after_subscription');
		}
	}

	display_ads(){
		if (subscription.is_valid()) return false;
		if (this.ads_count && this.ads_count % this.ads_interval === 0){
			this.ads_count = 0;
			ads.show_interstitial();
			return true;
		} else if (this.ads_count > this.ads_interval){
			this.ads_count = 1;
		} else {
			this.ads_count += 1;
		}
		return false;
	}
}

const interaction = new InteractionController();

export default interaction;
