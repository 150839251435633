import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/LinkedAccounts.css';

// Assets Imports
import * as assets from '@Assets';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Components and Views Imports

// Export Controller Import
import export_track from '@Export';

class LinkedAccounts extends Component {
	render() {
		var spotify_is_linked = this.props.linked_accounts.filter(account => account.platform === 'spotify' && account.state === 'active').length > 0;
		var deezer_is_linked = this.props.linked_accounts.filter(account => account.platform === 'deezer'  && account.state === 'active').length > 0;
		return (
			<div className="linked-accounts-main-container">
				<div className="linked-accounts-header">Sync your streaming app account to get awesome features and enjoy your Magroove experience</div>
				<div className="linked-accounts-content">
					<div className="linked-accounts-content-title">Streaming services</div>
					<div className="linked-accounts-box" onClick={() => {spotify_is_linked ? store_set('active_overlay', 'unlink_spotify_account') : export_track.open_login_window('spotify');}}>
						<div className="linked-accounts-box-info">
							<div className="linked-accounts-box-info-image">
								<img src={assets.spotify_round_icon} alt="account-pic"/>
							</div>
							<div className="linked-accounts-box-info-description">Sync your Spotify account</div>
						</div>
						<div className="linked-accounts-box-button">
							{spotify_is_linked &&
								<span className="material-icons" style={{color: '#ec6b43'}}>check</span>
							}
							{!spotify_is_linked &&
								<span className="material-icons">keyboard_arrow_right</span>
							}
						</div>
					</div>
					<div className="linked-accounts-box" onClick={() => deezer_is_linked ? store_set('active_overlay', 'unlink_deezer_account') : export_track.open_login_window('deezer')}>
						<div className="linked-accounts-box-info">
							<div className="linked-accounts-box-info-image">
								<img src={assets.deezer_icon} alt="account-pic"/>
							</div>
							<div className="linked-accounts-box-info-description">Sync your Deezer account</div>
						</div>
						<div className="linked-accounts-box-button">
							{deezer_is_linked &&
								<span className="material-icons" style={{color: '#ec6b43'}}>check</span>
							}
							{!deezer_is_linked &&
								<span className="material-icons">keyboard_arrow_right</span>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		linked_accounts: state.GlobalReducer.linked_accounts
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(LinkedAccounts));