import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	TelegramShareButton,
	VKShareButton,
} from 'react-share';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

// Components and Views Imports
import posed from 'react-pose';
import Haptic from '@components/haptic.js';
import Ripples from 'react-ripples';
import { Swipeable } from 'react-swipeable';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Assets Imports
import * as assets from '../Assets.js';

// CSS Imports
import './styles/SecondaryOverlay.css';

// Import controllers
import export_track from '@Export';

// Constant Imports
import * as constants from '@constants';

const shareCapacitor = async (url, text, dialogTitle = 'Share with your friends') => {
	await Share.share({
		title: 'Let\'s find new songs!',
		text: text,
		url: url,
		dialogTitle: dialogTitle
	});
};

const PosedReferralOverlay = posed.div({
	referral: {
		height: () => { return window.innerWidth > 350 ? 446 : 360; },
		borderTop: 'solid 4px #707070'
	},
	referral_share: {
		height: () => { return window.innerWidth > 300 ? 290 : 230; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

const PosedAutoRecommendationOverlay = posed.div({
	full: {
		height: () => { return window.innerWidth > 375 ? 350 : 325; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

const PosedExperienceEvaluation = posed.div({
	full: {
		height: () => { return window.innerWidth > 375 ? 410 : 365; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

const PosedDownloadAppOverlay = posed.div({
	full: {
		height: () => { return window.innerWidth > 300 ? 181 : 188; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

const PosedPlaylistOverlay = posed.div({
	full: {
		height: () => { return window.innerWidth > 300 ? 'initial' : 150; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

class SecondaryOverlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			share_url: ''
		};

		this.invite_friends = this.invite_friends.bind(this);
	}

	invite_friends() {
		var user_info = this.props.user;
		var text = 'Are you looking for that next song you will be hearing in loop for days in a row? Magroove will help you!';
		var referral_link = constants.app_domain + '/login/';
		if (user_info.hash_id) {
			referral_link += '?aid=' + user_info.hash_id.slice(0, 10);
		}
		if (Capacitor.getPlatform() === 'web') {
			this.setState({ share_url: referral_link });
			store_set('secondary_overlay', 'referral_share');
		}
		else {
			const dialogTitle = 'Invite your friends';
			shareCapacitor(referral_link, text, dialogTitle);
		}
	}

	render() {
		const secondary_overlay = this.props.secondary_overlay;
		return (
			<div className={secondary_overlay ? 'magroove-secondary-overlay-open' : ''} onClick={() => store_set('secondary_overlay', false)}>
				<Swipeable onSwipedDown={() => store_set('secondary_overlay', false)} delta={1}>
					<PosedAutoRecommendationOverlay
						pose={secondary_overlay === 'auto_recommendation' ? 'full' : 'hidden'}
						className='overlay-main'
					>
						<div className='secondary-overlay-auto-recommendation' style={{ overflow: secondary_overlay === 'auto_recommendation' ? 'visible' : 'hidden' }}>
							<div className='secondary-overlay-auto-recommendation-content'>
								<img className='secondary-overlay-auto-recommendation-image' src={assets.auto_recommendation} alt='' />
								<div className='secondary-overlay-auto-recommendation-title'>Auto-enrichment</div>
								<div className='secondary-overlay-auto-recommendation-text'>
									Hey! We were taking a look at your playlists and I think we have some perfect matches for you!
									<br></br> <br></br>
									<strong>Would you like us to automatically add songs to your playlists?</strong>
								</div>
								<div className='secondary-overlay-auto-recommendation-buttons'>
									<Ripples>
										<Haptic intesity='light'>
											<div className='secondary-overlay-auto-recommendation-no-button' onClick={() => export_track.auto_recommendation('Rejected')}>
												NOT REALLY
											</div>
										</Haptic>
									</Ripples>
									<Ripples>
										<Haptic intesity='light'>
											<div className='secondary-overlay-auto-recommendation-yes-button' onClick={() => export_track.auto_recommendation('Accepted')}>
												YES, OF COURSE
											</div>
										</Haptic>
									</Ripples>
								</div>
							</div>
						</div>
					</PosedAutoRecommendationOverlay>
					<PosedDownloadAppOverlay
						pose={secondary_overlay === 'download_app' ? 'full' : 'hidden'}
						className='secondary-overlay-main'
					>
						<div className='secondary-overlay-download-app'>
							<div className='secondary-overlay-download-app-close material-icons'>close</div>
							<div className='secondary-overlay-download-app-title'>Try our app</div>
							<div className='secondary-overlay-download-app-description'>Get access to the full app and discover much more with Magroove Music Discovery!</div>
							<div className='secondary-overlay-download-app-buttons'>
								<Haptic intesity='light'>
									<a href={this.props.version.android}><img src={assets.google_play_badge} alt='Google Play' /></a>
								</Haptic>
								<Haptic intesity='light'>
									<a href={this.props.version.ios}><img src={assets.apple_store_badge} alt='Apple Store' /></a>
								</Haptic>
							</div>
						</div>
					</PosedDownloadAppOverlay>
					<PosedReferralOverlay
						pose={['referral', 'referral_share'].includes(secondary_overlay) ? secondary_overlay : 'hidden'}
						className='secondary-overlay-main'
					>
						{secondary_overlay === 'referral' &&
							<div className='secondary-overlay-referral-content'>
								<div className='secondary-overlay-referral-close material-icons'>close</div>
								<div className='secondary-overlay-referral-title'>Invite your friends</div>
								<img src={assets.invite_friends} alt='' className='secondary-overlay-referral-image' />
								<div className='secondary-overlay-referral-description'>Jamming by yourself is fun, but with friends, everything is so much better!</div>
								<Ripples className='secondary-overlay-referral-button'>
									<Haptic intesity='light'>
										<div onClick={this.invite_friends}>SEND INVITES</div>
									</Haptic>
								</Ripples>
							</div>
						}
						{secondary_overlay === 'referral_share' &&
							<div className='secondary-overlay-share-options'>
								<Ripples>
									<FacebookShareButton url={this.state.share_url}>
										<Haptic intesity='light'>
											<div className='secondary-overlay-share-item'>
												<div className='secondary-overlay-share-icon'>
													<img src={assets.facebook_icon} alt='facebook-share-icon' />
												</div>
												<div className='secondary-overlay-share-item-text'>Facebook</div>
											</div>
										</Haptic>
									</FacebookShareButton>
								</Ripples>
								<Ripples>
									<TwitterShareButton url={this.state.share_url}>
										<Haptic intesity='light'>
											<div className='secondary-overlay-share-item'>
												<div className='secondary-overlay-share-icon'>
													<img src={assets.twitter_bird_icon} alt='twitter-share-icon' />
												</div>
												<div className='secondary-overlay-share-item-text'>Twitter</div>
											</div>
										</Haptic>
									</TwitterShareButton>
								</Ripples>
								<Ripples>
									<WhatsappShareButton url={this.state.share_url}>
										<Haptic intesity='light'>
											<div className='secondary-overlay-share-item'>
												<div className='secondary-overlay-share-icon'>
													<img src={assets.whatsapp_round_icon} alt='whatsapp-share-icon' />
												</div>
												<div className='secondary-overlay-share-item-text'>Whatsapp</div>
											</div>
										</Haptic>
									</WhatsappShareButton>
								</Ripples>
								<Ripples>
									<TelegramShareButton url={this.state.share_url}>
										<Haptic intesity='light'>
											<div className='secondary-overlay-share-item'>
												<div className='secondary-overlay-share-icon'>
													<img src={assets.telegram_round_icon} alt='telegram-share-icon' />
												</div>
												<div className='secondary-overlay-share-item-text'>Telegram</div>
											</div>
										</Haptic>
									</TelegramShareButton>
								</Ripples>
								<Ripples>
									<VKShareButton url={this.state.share_url}>
										<Haptic intesity='light'>
											<div className='secondary-overlay-share-item'>
												<div className='secondary-overlay-share-icon'>
													<img src={assets.vk_round_icon} alt='vk-share-icon' />
												</div>
												<div className='secondary-overlay-share-item-text'>VK</div>
											</div>
										</Haptic>
									</VKShareButton>
								</Ripples>
							</div>
						}
					</PosedReferralOverlay>
					<PosedPlaylistOverlay
						pose={secondary_overlay === 'create_playlist' ? 'full' : 'hidden'}
						className="overlay-main"
					>
						<div className="overlay-confirm-create-playlist">
							<div className="overlay-confirm-playlist-title">Playlist Syncing</div>
							<div className="overlay-confirm-playlist-text">Looks like your Spotify account is already connected with Magroove! Would you like us to create this very same playlist on Spotify?</div>
							<div className="overlay-confirm-playlist-text">When synced, any changes to your Magroove Playlist will be automatically applied to other platforms.</div>
							<div className="overlay-confirm-buttons">
								<Ripples>
									<Haptic intesity='light'>
										<div className="overlay-confirm-yes-button" onClick={() => store_set('secondary_overlay', false)}>CANCEL</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className="overlay-confirm-no-button" onClick={() => store_set('secondary_overlay', false)}>YES, CREATE</div>
									</Haptic>
								</Ripples>
							</div>
						</div>
					</PosedPlaylistOverlay>
					<PosedExperienceEvaluation
						pose={secondary_overlay === 'experience_evaluation' ? 'full' : 'hidden'}
						className='overlay-main'
					>
						<div className='secondary-overlay-experience-evaluation' style={{ overflow: secondary_overlay === 'experience_evaluation' ? 'visible' : 'hidden' }}>
							<div className='secondary-overlay-experience-evaluation-content'>
								<img className='secondary-overlay-experience-evaluation-image' src={assets.auto_recommendation} alt='' />
								<div className='secondary-overlay-experience-evaluation-title'>Gives us some feedback!</div>
								<div className='secondary-overlay-experience-evaluation-text'>
									Take a moment to answer our experience evaluation form and help us enhance your experience. Your insights are crucial in shaping the future of our app!
									<br></br>
									<br></br>
									<strong>After taking the survey, if you video chat with us to talk about your experience, you get 1 month free premium!</strong>
								</div>
								<div className='secondary-overlay-experience-evaluation-buttons'>
									<Ripples>
										<Haptic intesity='light'>
											<div className='secondary-overlay-experience-evaluation-no-button' onClick={() => store_set('secondary_overlay', false)}>
												NOT NOW
											</div>
										</Haptic>
									</Ripples>
									<Ripples>
										<Haptic intesity='light'>
											<div className='secondary-overlay-experience-evaluation-yes-button' onClick={() => {window.open('https://docs.google.com/forms/d/1glUTt0nXejFCtQyIh5If70DtolJz8eWEGxkQi76ITT0/viewform?edit_requested=true'); store_set('secondary_overlay', false);}}>
												<span className='secondary-overlay-experience-evaluation-yes-button-text'>OPEN FORM</span>
												<span className='material-icons'>open_in_new</span>
											</div>
										</Haptic>
									</Ripples>
								</div>
							</div>
						</div>
					</PosedExperienceEvaluation>
				</Swipeable>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		version: state.GlobalReducer.version,
		secondary_overlay: state.GlobalReducer.secondary_overlay,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(SecondaryOverlay);