import React, { Component } from 'react';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/Version.css';

// Version imports
import version from '@Version';
import remote_exporter from '@/RemoteExporter.js';

// Constant Imports
import * as constants from '@constants';

// Actions import
import { store_get, store_set, get_api_version, get_base_version } from '@actions/GlobalActions.js';

// Import controllers
import storage from '@Storage';

class Version extends Component {
	constructor(props){
		super(props);
		this.state = {
			api_version: false,
			base_version: false,
			developer_count: 0
		};

		this.handle_click = this.handle_click.bind(this);
		this.countdown = false;
		this.remote_js = false;

		get_api_version().then(res => this.setState({api_version: res}));
		get_base_version().then(res => this.setState({base_version: res}));
	}

	handle_click(){
		// Tapping five times in a row in the profile picture should toggle the developer mode (enable / disable)
		if (this.countdown){
			clearTimeout(this.countdown);
		}

		var count = this.state.developer_count + 1;
		var developer_mode = store_get('developer_mode');
		if (count === 5){
			store_set('developer_mode', !developer_mode);
			this.setState({developer_count: 0});
			this.countdown = false;

			if (developer_mode){
				// Refresh the page so the script tag is removed
				store_set('snackbar_message', 'Exiting developer mode');
				store_set('snackbar_status', true);
				window.getObject = false;
				window.location.reload(false);
			}
			else{
				// Inject a remote js tag (https://remotejs.com/)
				let remotejs = document.createElement('script');
				remotejs.src = 'https://remotejs.com/agent/agent.js';
				remotejs.setAttribute('data-consolejs-channel', 'magroove-app-debugger');
				document.head.appendChild(remotejs);

				// Function to access objects on RemoteJs
				window.getObject = (value) => remote_exporter(value);

				// Log the developer mode on
				const extra_log = {};
				const log = {
					app_name: 'magroove-app',
					message: 'developer mode on',
					level: 'info',
					timestamp: new Date().toISOString(),
					location: window.location,
					device: navigator.userAgent,
					stack: '',
				};
				extra_log['api_version'] = storage.get('api_version');
				extra_log['app_version'] = storage.get('app_version');
				extra_log['user_hash_id'] = storage.get('hash_id');

				const message = {log, extra_log};
				fetch(constants.api_endpoint_app_log, {
					method: 'POST',
					body: JSON.stringify({message}),
					headers: { 'Content-Type': 'application/json' }
				})
					.then(response => response.json())
					.then(data => console.log(data))
					.catch(
						() => {
							const local_logs = storage.get('pending_logs');
							let logs_to_store = [message];

							if (local_logs) {
								const pending_logs = local_logs.sort(
									(a, b) => 
										new Date(a['log'].timestamp).getTime() > new Date(b['log'].timestamp).getTime()
								);
								
								//remove the older log if are more than 10 logs
								logs_to_store = pending_logs.length > 10 ? 
									[
										...(pending_logs.filter((item, inx) => inx > 0)),
										message
									] : [
										...pending_logs, message
									];
							} 
							return storage.set('pending_logs', logs_to_store);
						}
					);

				// Display a Snackbar to let the user know that happened 
				store_set('snackbar_message', 'You are now in developer mode');
				store_set('snackbar_status', true);
			}
		}
		else{
			this.setState({developer_count: count});
			this.countdown = setTimeout(function() {
				this.setState({developer_count: 0});
				this.countdown = false;
			}.bind(this), 1000);
		}
	}

	render() {
		return (
			<div className='version-main-container'>
				{this.state.api_version ?
					<div className='version-info'>
						<div className='version-info-title'>Api Version</div>
						<div className='version-info-description'>{this.state.api_version}</div>
					</div>
					: null
				}
				<div className='version-info'>
					<div className='version-info-title'>App Version</div>
					<div className='version-info-description' onClick={this.handle_click}>{version.app_version}</div>
				</div>
				{this.state.base_version ?
					<div className='version-info'>
						<div className='version-info-title'>Base Version</div>
						<div className='version-info-description'>{this.state.base_version}</div>
					</div>
					: null
				}
			</div>
		);
	}
}

// Connect component to the store
export default error_boundary_hoc(Version);
