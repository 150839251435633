// import the request_buffer
import { request_buffer } from '@/RequestBuffer.js';

// Constant Imports
import * as constants from '@constants';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

// Import controllers
import ads from '@Ads';
import user from '@User';
import storage from '@Storage';
import player from '@Player';
import file from '@FileManager';

class QueueController{
	constructor() {
		this.active_ajaxs = false;
		this.no_more_recommendations = false;

		// Open methods ------------------------------------------------------------------------------------------------------------------
		// This methods can be called from outside the QueueController itself.
		this.pop = this.pop.bind(this);
		this.shift = this.shift.bind(this);
		this.push = this.push.bind(this);
		this.size = this.size.bind(this);
		this.clear_queue = this.clear_queue.bind(this);
		this.get_recommendations = this.get_recommendations.bind(this);

		// Closed Methods ----------------------------------------------------------------------------------------------------------------
		// This methods should be called only by the QueueController itself, never from outside the class
		this.check_for_duplicates = this.check_for_duplicates.bind(this);
		this.get_more_recommendations = this.get_more_recommendations.bind(this);
		this.get_user_recommendations = this.get_user_recommendations.bind(this);

		event_bus.on('store-loaded', this.initialize_queue);
	}

	pop(){
		// Take a song from the end of the queue - LIFO
		const current_queue = store_get('queue');
		const popped_song = current_queue.pop();
		store_set('queue', current_queue);

		// Get new recommendations when there're 15 or less tracks in queue.
		if (current_queue.length <= 15 && user.is_logged()) {
			this.get_more_recommendations();
		}

		// Display No more tracks warning in every page if the queue was not empty before
		if (this.no_more_recommendations && popped_song === undefined){
			ads.show_interstitial();
			store_set('active_overlay', 'no_more_recommendations');
		}

		try {
			// To avoid duplicate tracks in the recommendation roll, remove duplicates by
			// checking the track+artist name combination. Store the track+artist name
			// combinations in the localstorage with a TTL of a 24 hours and then ignore
			// them when popping a new song from the recommendation array.
			const song_plus_artist = popped_song.title + popped_song.artist.name;
			if (storage.get('queue_' + song_plus_artist)){
				return this.pop();
			}
			storage.set('queue_' + song_plus_artist, true, 86400);
		} catch (err){ /** */ }

		if (typeof popped_song === 'object'){
			popped_song.from_queue = true;
		}
		return popped_song;
	}

	shift(){
		// Take a song from the beginning of the queue - LIFO
		const current_queue = store_get('queue');
		const popped_song = current_queue.shift();
		store_set('queue', current_queue);
		return popped_song;
	}

	push(song){
		// Push a song to the end of the queue
		const current_queue = store_get('queue');
		current_queue.push(song);
		store_set('queue', current_queue);
	}

	size(){
		const current_queue = store_get('queue');
		return current_queue.length;
	}

	clear_queue(){
		store_set('queue', []);
		return true;
	}

	clean(entity_type, entity){
		// Receives a blocked item and cleans the recommendation queue
		const current_queue = store_get('queue');
		const cleaned_queue = current_queue.filter(song => {
			const item = entity_type === 'artist' ? song.artist : song.album;
			if (
				(item.hash_id && item.hash_id !== entity.hash_id) ||
				(item.spotify_id && item.spotify_id !== entity.spotify_id)
			){
				return true;
			}
			return false;
		});

		store_set('queue', cleaned_queue);
	}

	check_for_duplicates(){
		// Check for and remove duplicates in queue
		const current_queue = store_get('queue');
		const unique_queue = [...new Map(current_queue.map(song => [song.spotify_id, song])).values()];
		store_set('queue', unique_queue);
	}

	initialize_queue(keep_paused = false){
		// To be called once and only once.
		// It will be automatically called once the persistor is done loading the saved store state
		// this.get_user_recommendations(1);
		let redirect = store_get('redirect_to_home');
		if (redirect){
			player.load(0, false, keep_paused);
		}
		else{
			player.initialize();
		}
	}

	get_more_recommendations(){
		// Make sure to get fine tune parameters 
		// (For example level of popularity, or happyness, etc)
		// We use for premium users that want to customize their recommendations
		const fine_tunning = store_get('fine_tunning') || {};

		// Keep track of active ajaxs' running and avoid making new ajax
		// calls if there are any other running
		if (this.active_ajaxs || this.no_more_recommendations){
			return;
		}
		this.active_ajaxs = true;

		var seed = store_get('seed');
		if (!seed) return;

		var data = {};
		data['hash_id'] = seed.spotify_id;
		data['seed_type'] = (seed.type === 'genre' || seed.type === 'mood') ? 'song' : seed.type;
		data['fine_tunning'] = JSON.stringify(fine_tunning);

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_recommendations,
			data: data,
			success: function(data){
				if (data.status === 'success'){
					// Push the new songs to the queue
					for (var song of data.data){
						if (song.preview_url) {
							this.push(song);
							// Also preload the song silently
							file.load(song);
						}
					}
					// Check for and remove duplicates
					this.check_for_duplicates();

				} else{
					// If "no more recommendations" message received, simply stop
					// trying to get new recommendations and let the queue end
					if (data.reason === 'no_more_recommendations'){
						this.no_more_recommendations = true;
					}
				}
			}.bind(this),
			complete: function(){
				this.active_ajaxs = false;
			}.bind(this)
		});
	}

	get_recommendations(hash_id, seed_type, search_query = '', execute_immediately = false, keep_paused = false){
		// Make sure to get fine tune parameters 
		// (For example level of popularity, or happyness, etc)
		// We use for premium users that want to customize their recommendations
		const fine_tunning = store_get('fine_tunning') || {};

		this.active_ajaxs = true;
		this.no_more_recommendations = false;

		// Get this beautiful hash and seed type an AJAX the API for fresh recommendations
		var data = {};

		data['hash_id'] = hash_id;
		data['seed_type'] = (seed_type === 'genre' || seed_type === 'mood') ? 'song' : seed_type;
		data['fine_tunning'] =  JSON.stringify(fine_tunning);
		if (search_query){
			data['search_query'] = search_query;
		}

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_recommendations,
			data: data,
			post_login: {
				action: 'execute',
				type: 'get_recommendations',
				params: {
					hash_id: hash_id,
					seed_type: seed_type,
					search_query: search_query,
					execute_immediately: execute_immediately
				}
			},
			success: function(data){
				// Clear current queue
				this.clear_queue();

				// Push the new songs to the queue
				for (var song of data.data){
					if (song.preview_url) {
						this.push(song);
					}
				}

				// Check for and remove duplicates
				this.check_for_duplicates();

				// If execute_immediately is set, load a track in the player
				if (execute_immediately) {
					store_set('redirect_to_home', true);
					this.initialize_queue(keep_paused);
				}
				// Otherwise, only preload the queue silently
				else{
					file.preload_queue();
				}
				store_set('loading_overlay_display', false);
			}.bind(this),
			error: function(){
				store_set('loading_overlay_display', false);
			},
			complete: function(){
				this.active_ajaxs = false;
			}.bind(this)
		}, true);
	}

	get_user_recommendations(){
		// Generate a queue based on user likes or recent seed
		// Keep track of active ajaxs' running and avoid making new ajax
		// calls if there are any other running
		if (this.active_ajaxs) return;
		this.active_ajaxs = true;

		store_set('seed', {'name': 'Custom', 'type': ''});
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_user_recommendations,
			success: function(data){
				// Only add the songs to queue if its empty
				const songs_in_queue = store_get('queue');
				if (data.status === 'success' && songs_in_queue.length === 0){
					// Push the new songs to the queue
					for (var song of data.data){
						if (song.preview_url) {
							this.push(song);
							file.load(song); // Also preload the song silently
						}
					}

					// Check for and remove duplicates
					this.check_for_duplicates();

					// Set the first song in queue
					const next_song = this.pop();
					player.initialize(next_song);
				}
			}.bind(this),
			complete: function(){
				this.active_ajaxs = false;
			}.bind(this)
		});
	}
}

const queue = new QueueController();

export default queue;