// Actions import
import { store_set } from '@actions/GlobalActions.js';

function reset_keyboard_open_in_store(){
	store_set('keyboard_open', false);
}

export function execute_middlewares(){
	// List here all the middlewares you want
	const global_middlewares = [
		reset_keyboard_open_in_store,
		// Another cool moddleware....
	];

	// All middlewares will be executed here
	for (var i = global_middlewares.length - 1; i >= 0; i--) {
		global_middlewares[i]();
	}
}
