import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

class KeyboardController{
	constructor(){
		this.opened = this.opened.bind(this);
		this.hidden = this.hidden.bind(this);
        
		if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
			Keyboard.addListener('keyboardWillShow', () => {
				store_set('keyboard_open', true);
			});

			Keyboard.addListener('keyboardWillHide', () => {
				store_set('keyboard_open', false);
			});
		}
	}

	opened(){
		if (Capacitor.getPlatform() === 'web') {
			store_set('keyboard_open', true);
		}
	}
    
	hidden(){
		if (Capacitor.getPlatform() === 'web') {
			store_set('keyboard_open', false);
		}
	}

}

const keyboard = new KeyboardController();

export default keyboard;