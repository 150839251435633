import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// CSS Imports
import './styles/DesktopHeader.css';

// Assets Imports
import { magroove_logo } from '@Assets';

class DesktopHome extends Component {
	constructor(props){
		super(props);
		this.state = {
			scroll: false
		};
	}

	componentDidMount(){
		window.onscroll = function(){ 
			var scroll_position = window.scrollY;
			this.setState({scroll: scroll_position !== 0});
		}.bind(this);
	}

	render() {
		const href = window.location.href;
		if (href.includes('track') || href.includes('album') || href.includes('artist')) {
			return(
				<div className='header-desktop-links'>
					<div className='header-desktop-links-content'>
						<div className='header-desktop-links-text-container'>
							<div className='header-desktop-links-text'>Wanna discover more songs? Try</div>
							<img src={magroove_logo} alt='Magroove Logo'/>
						</div>
						<div className='header-desktop-links-buttons-container'>
							<a href={this.props.version.android} className='header-desktop-links-button'>
								<img src='https://magroove-dev.s3.amazonaws.com/magroove-for-artists/google-play-button.jpg' alt='google-play-button'/>
							</a>
							<a href={this.props.version.ios} className='header-desktop-links-button'>
								<img src='https://magroove-dev.s3.amazonaws.com/magroove-for-artists/app-store-button.jpg' alt='app-store-button'/>
							</a>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className='header-desktop' 
					style={{
						backgroundColor: (this.state.scroll || href.includes('login')) ? 'white' : 'transparent', 
						boxShadow: href.includes('login') ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : ''
					}}>
					<div className='header-content-desktop'>
						<Link className='header-logo-desktop' to='/'>
							<img src='https://magroove-dev.s3.amazonaws.com/magroove-blog/logos/logo-black.svg' alt='magroove-logo'/>
						</Link>
						<div className='header-menu-desktop'>
							<a className='header-menu-item-desktop' href='https://artists.magroove.com/'>I'M AN ARTIST</a>
							<a className='header-menu-item-desktop' href='https://magroove.com/blog/'>BLOG</a>
							<Link className='header-menu-item-desktop' to='/terms-and-conditions/'>TERMS AND CONDITIONS</Link>
							<Link className='header-menu-item-desktop' to='/privacy-policy/'>PRIVACY POLICY</Link>
							<Link className='header-menu-item-desktop' to='/contact-us/'>CONTACT US</Link>
						</div>
					</div>
				</div>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		version: state.GlobalReducer.version
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopHome);