import React, { Component } from 'react';
import { connect } from 'react-redux';

import interaction from '@Interaction';
import Card from '@components/Card.js';
import PremiumOverlayHide from './PremiumOverlayHide.js';

// Actions import
import { store_set } from '@actions/GlobalActions.js';
import { redirect } from '@routes/Routes.js';

// CSS Imports
import './styles/QueueList.css';

class QueueList extends Component {
	constructor(props){
		super(props);
		if (!this.props.playing_song){
			redirect('/');
		}
	}

	componentDidMount(){
		// Focus on the playing song
		var element = document.getElementById('queue-focus');
		if (element){
			element.parentNode.scrollTop = element.offsetTop; 
		}
	}

	render(){
		const { is_member_premium, likes, playing_song, queue } = this.props;

		const interaction_history = interaction.get_history_list();
		interaction_history.map((song) => likes.includes(song) ? song.interaction = 'like' : song.interaction = 'dislike');
		const recommendation_queue = playing_song ? interaction_history.concat([playing_song]).concat([...queue].reverse()) : [];

		return(
			<div className='recommendation-queue'>
				{is_member_premium ? null : 
					<PremiumOverlayHide icon='history' title='access to your songs queue!'></PremiumOverlayHide>
				}
				<React.Fragment>
					<div className='recommendation-queue-container'>
						{recommendation_queue.map((item, index) => { 
							return(
								<div key={index} id={index === interaction_history.length ? 'queue-focus' : ''} className='home-queue-item'>
									<Card
										data={item}
										type='small_card_song'
										is_new_like={false}
										show_interaction={true}
										blockLink={is_member_premium ? false : true}
										onMoreClick={is_member_premium ? () => {
											store_set('active_overlay', 'track_options');
											store_set('song_info', item);
										} : () => {}}
									/>
								</div>
							);
						})}
					</div>
				</React.Fragment>	
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_member_premium: state.GlobalReducer.is_member_premium,
		queue: state.GlobalReducer.queue,
		likes: state.GlobalReducer.likes,
		playing_song: state.GlobalReducer.playing_song
	};
}

// Connect component to the store
export default connect(mapStateToProps)(QueueList);