import { Haptics, HapticsImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';

import React from 'react';

export default class Haptic extends React.Component {
	render() {
		let callback = () => Haptics.impact({ style: HapticsImpactStyle.Light });
		
		if (this.props.intesity === 'light') {
			callback = () => Haptics.impact({ style: HapticsImpactStyle.Light });
		}

		if (this.props.intesity === 'medium') {
			callback = () => Haptics.impact({ style: HapticsImpactStyle.Medium });
		}

		if (this.props.intesity === 'heavy') {
			callback = () => Haptics.impact({ style: HapticsImpactStyle.Heavy });
		}

		if (this.props.disable){
			callback = () => {};
		}

		if (Capacitor.getPlatform() === 'web'){
			callback = () => {};
		}

		return (
			<div onClick={callback}>
				{this.props.children}
			</div>
		);
	}
}
