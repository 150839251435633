import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Textfield, Selectfield, Selectitem } from '@magroove/magroove-ui';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import keyboard from '@/Keyboard';
import * as EmailValidator from 'email-validator';
import Sortable from 'sortablejs';
import storage from '@Storage';

// CSS Imports
import './styles/EditProfile.css';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import ContentLoader from '@components/ContentLoader.js';
import { Img } from 'react-image';
import UploadImage from '@components/UploadImage.js';
import {
	DiscoveryLeaderboard,
	DiscoveryTimeline,
	GenreChart,
	FollowingArtists,
	FollowingUsers,
	MusicAspects,
	MusicTaste,
	Obsession,
	Overview,
	Polls,
	TopAlbums,
	TopArtists,
	TopTracks,
	EditQuizes
} from '@components/ProfileSections.js';

// Actions import
import { store_set, store_get, finish_search_selection } from '@actions/GlobalActions.js';

// Constants import
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

const magrooveBanners = [
	{ id: 1, asset: assets.profile_banner_1, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-1.png', hash_id: 'mqi10dvqs7bglh5b3u8n43drkfwhqy6x'},
	{ id: 2, asset: assets.profile_banner_2, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-2.png', hash_id: 'avgsfenqqyv0ameaqtcmvnmp1yl8n3pr'},
	{ id: 3, asset: assets.profile_banner_3, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-3.png', hash_id: 'psu2bmfug6adoku5o6t5yzzm17n2rrul'},
	{ id: 4, asset: assets.profile_banner_4, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-4.png', hash_id: 'xwy8fqvutg1lxsp8e4z2njr2jg9fh4dn'},
	{ id: 5, asset: assets.profile_banner_5, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-5.png', hash_id: 'ylf7720459jerelh6z1qoyky7yqzfwzx'},
	{ id: 6, asset: assets.profile_banner_6, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-6.png', hash_id: 'ehsichyhhi25i20q9ulkpt1m8cut11ko'},
	{ id: 7, asset: assets.profile_banner_7, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-7.png', hash_id: 'ud5m35knzjwnpbw6mec9j82akfigh8a3'},
	{ id: 8, asset: assets.profile_banner_8, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-8.png', hash_id: 't9amtpys3xgr28ex6a8ubowwyu02icqu'},
	{ id: 9, asset: assets.profile_banner_9, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-9.png', hash_id: 'nt8a431y03798ivckv6sk75dm4ujw7nq'},
	{ id: 10, asset: assets.profile_banner_10, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-10.png', hash_id: '6g93zw2wqy9s9xn48u0sidonicywefj5'},
	{ id: 11, asset: assets.profile_banner_11, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-11.png', hash_id: 'p2sb9zgwq8m22k7fp8ucj8c5e4vxr3xx'},
	{ id: 12, asset: assets.profile_banner_12, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-12.png', hash_id: 'z6ur3c40gt9ax5e76zpgfjf06zqmh3dp'},
	{ id: 13, asset: assets.profile_banner_13, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-13.png', hash_id: 'zxclz9c1a8wzf4ip0qdnhgr3azoqxanr'},
	{ id: 14, asset: assets.profile_banner_14, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-14.png', hash_id: '8hpoyrb27h1rmfjjh62n9snt6bn31iyp'},
	{ id: 15, asset: assets.profile_banner_15, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-15.png', hash_id: 'cyz4vrtwuo7ppzhs7no4qnlb4clspata'},
	{ id: 16, asset: assets.profile_banner_16, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-16.png', hash_id: 'jln4b1hcqmkeeppivhxtd3ga69nf2ck6'},
	{ id: 17, asset: assets.profile_banner_17, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-17.png', hash_id: 'f2nc1rhbyspxhq0buv11b8np9zs182g6'},
	{ id: 18, asset: assets.profile_banner_18, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-18.png', hash_id: 'cjicmlefvcf5v851ocen1stk4thev7va'},
	{ id: 19, asset: assets.profile_banner_19, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-19.png', hash_id: '7mkjr4cxoytn4ssfwzpsvtqgcyx0enog'},
	{ id: 20, asset: assets.profile_banner_20, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-20.png', hash_id: 'f5hrp9yybdhr8mbk31kjygsywdtz9etu'},
	{ id: 21, asset: assets.profile_banner_21, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-21.png', hash_id: 'sizx1n1fqs2v8bod78747qhuhrcclu7k'},
	{ id: 22, asset: assets.profile_banner_22, url: 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/profile-banner-22.png', hash_id: 'yu3bee7ii8sxr0oh18i5vf8krg6kp9lg'}
];


function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

class ImageLoader extends Component {
	render(){
		const { image_url, style, className, onError } = this.props;

		return(
			<div className='card-image-loader'>
				<ContentLoader style={style} />
				<img src={image_url} className={className} style={style} alt='' onError={(ev) => ev.target.src = onError} />
			</div>
		);
	}
}

class General extends Component {
	render(){
		const { edit_profile_page, state, handle_change } = this.props;

		return (
			<div className='edit-profile-fields'>
				<div 
					className='edit-profile-field-input' 
					onClick={() => keyboard.opened()}
					onBlur={() => keyboard.hidden()}
				>
					<Textfield
						theme='dark' size='small'
						fullWidth={true}
						label='First Name'
						value={state.first_name}
						onChange={(value) => handle_change('first_name', value)}
						error={!state.is_first_name_valid}
					/>
				</div>
				<div 
					className='edit-profile-field-input' 
					onClick={() => keyboard.opened()}
					onBlur={() => keyboard.hidden()}
				>
					<Textfield
						theme='dark' size='small'
						fullWidth={true}
						label='Last Name'
						value={state.last_name}
						onChange={(value) => handle_change('last_name', value)}
						error={!state.is_last_name_valid}
					/>
				</div>
				<div 
					className='edit-profile-field-input' 
					onClick={() => keyboard.opened()}
					onBlur={() => keyboard.hidden()}
				>
					<Textfield
						theme='dark' size='small'
						fullWidth={true}
						label='Username'
						value={state.slug}
						onChange={(value) => handle_change('slug', value)}
						error={!state.is_slug_valid}
					/>
				</div>
				<div 
					className='edit-profile-field-input' 
					onClick={() => keyboard.opened()}
					onBlur={() => keyboard.hidden()}
				>
					<Textfield
						theme='dark' size='small'
						fullWidth={true}
						label='Email'
						value={state.email}
						onChange={(value) => handle_change('email', value)}
						error={!state.is_email_valid}
					/>
				</div>
				<div 
					className='edit-profile-field-input' 
					onClick={() => keyboard.opened()}
					onBlur={() => keyboard.hidden()}
				>
					<Selectfield
						theme='dark' size='small'
						label='Pronouns' fullWidth={true}
						onChange={(value) => handle_change('pronouns', value)}
						value={state.pronouns}
						options={[
							<Selectitem value='he/him' title='he/him' size='small' key='he/him' />,
							<Selectitem value='she/her' title='she/her' size='small' key='she/her' />,
							<Selectitem value='they/them' title='they/them' size='small' key='they/them' />,
							<Selectitem value='he/they' title='he/they' size='small' key='he/they' />,
							<Selectitem value='she/they' title='she/they' size='small' key='she/they' />,
							<Selectitem value='not say' title='Rather not say' size='small' key='not say' />,
						]}
					/> 
				</div>
				{state.display_save_button &&
					<Button
						value='SAVE' size='small'
						className='edit-profile-field-button'
						onClick={edit_profile_page}
					/>
				}
			</div>
		);
	}
}

class Avatar extends Component {
	constructor(props){
		super(props);
		this.state = {
			uploading: false,
			picture: this.props.props.user.picture,
		};
		this.delete_profile_picture = this.delete_profile_picture.bind(this);
	}

	delete_profile_picture(picture_type='profile'){
		this.setState({picture: assets.default_profile_image});

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_delete_profile_picture,
			data: {picture_type: picture_type},
			success: function(){
				const user_object = store_get('user');
				if (picture_type === 'profile') {
					user_object.picture = undefined;
				} else {
					user_object.header_picture = undefined;
				}
				store_set('user', user_object);
			}
		}, true);
	}

	render() {
		const { props, on_new_image_success } = this.props;
		const { uploading, picture } = this.state;

		return (
			<div className='edit-profile-photo-container'>
				{!uploading &&
					<div className='edit-profile-photo'>
						<Img
							src={[picture, assets.default_profile_image]} alt={props.user.first_name}
							loader={
								<ContentLoader style={{width: 345, height: 345, margin: '0', borderRadius: '100%' }} />
							}
						/>
					</div>
				}
				<UploadImage
					type='profile'
					text='UPLOAD NEW'
					prefix='profile_pics/'
					show_button={true}
					onSuccess={image => {
						this.setState({picture: image.image_link});
						on_new_image_success(image);
					}}
					onClose={() => this.setState({uploading: false})}
					onEdit={() => this.setState({uploading: true})}
				/>
				{picture && picture !== assets.default_profile_image &&
					<div className='edit-profile-photo-restore' onClick={() => this.delete_profile_picture()}> RESTORE DEFAULT </div>
				}
			</div>
		);
	}
}

class Banner extends Component {
	constructor(props){
		super(props);
		this.state = {
			uploading: false,
			header_picture: this.props.props.user.header_picture,
		};
	}

	render(){
		const { props, on_new_image_success } = this.props;
		const { uploading, header_picture } = this.state;

		return (
			<div className='edit-profile-banner'>
				{!uploading &&
					<div className='profile-top-container'>
						<div className='profile-banner'>
							<ImageLoader style={{'height': 97, 'width': '100%'}} image_url={header_picture} onError={assets.default_profile_header} />
							<div className='profile-banner-shadow'></div>
						</div>
						<div className='profile-info'>
							<div className='profile-info-image'>
								<Img
									src={[props.user.picture, assets.default_profile_image]} alt={props.user.first_name}
									loader={
										<ContentLoader style={{ width: 106, height: 106, borderRadius: '100%' }} />
									}
								/>
							</div>
							<div className='profile-info-text'>
								<div className='profile-info-title'>
									<div style={{ backgroundColor: '#232323', width: 100, height: 20 }} />
								</div>
								<div className='profile-info-user'>
									<div style={{ backgroundColor: '#232323', width: 180, height: 20 }} />
								</div>
								<div className='profile-info-pronoun'>
									<div style={{ backgroundColor: '#232323', width: 40, height: 12 }} />
								</div>
								<div className='profile-info-numbers'>
									<div style={{ backgroundColor: '#232323', width: 156, height: 12 }} />
								</div>
							</div>
						</div>
					</div>
				}
				<div className='edit-profile-banner-bottom'>
					<UploadImage
						type='header'
						text='UPLOAD NEW'
						prefix='profile_pics/'
						show_button={true}
						onSuccess={image => {
							this.setState({header_picture: image.image_link});
							on_new_image_success(image);
						}}
						onClose={() => this.setState({uploading: false})}
						onEdit={() => this.setState({uploading: true})}
					/>
				</div>
				<div className='edit-profile-banner-options-container'>
					<p className='edit-profile-banner-options-title'>Or select a preset:</p>
					<div className='edit-profile-banner-options'>
						{[...magrooveBanners].map(banner => {
							let selected = header_picture === banner.url;	
							let style = selected ? { backgroundColor: 'rgba(236, 107, 67, 0.2)', border: 'solid 1px #ec6b43'} : {};
							return (
								<div
									className='edit-profile-banner-option-container'
									style={style}
									onClick={() => {
										on_new_image_success({file_hash_id: banner.hash_id, image_type: 'banner', url: banner.url});
										this.setState({header_picture: banner.url});
									}}
									key={banner.id}
								>
									{selected &&
										<div className='edit-profile-banner-option-selected'>
											<p className='edit-profile-banner-option-selected-text'>In use</p>
											<figure className='edit-profile-banner-option-selected-background'></figure>
										</div>
									}
									<figure className='edit-profile-banner-option' style={{backgroundImage: `url(${banner.asset})`}}></figure>
								</div>);
						})}
					</div>
				</div>
			</div>
		);
	}
}

function VisibilityButton(props){
	const [value, set_value] = useState(props.item.visibility);
	if (value === 'on'){
		return <span className='material-icons' onClick={() => {set_value('off'); props.change(props.item.section, 'off');}}>visibility</span>;
	}
	return <span className='material-icons' onClick={() => {set_value('on'); props.change(props.item.section, 'on');}}>visibility_off</span>;
}

class Showcase extends Component {
	constructor(props){
		super(props);
		this.state = {
			render_options: this.props.state.showcase.sort((a, b) => a.ordinal - b.ordinal)
		};

		this.order_changed = this.order_changed.bind(this);
		this.change_visibility = this.change_visibility.bind(this);
	}

	componentDidMount(){
		const url_string = window.location.href;
		const url = new URL(url_string);
		const section = url.searchParams.get('section');
		const element = document.getElementById(section);
		if (element){
			const header_plus_padding = 110;
			const position = element.offsetTop;
			const scrollable = document.querySelector('.main-content-inner > div');
			scrollable.scrollTo({
				top: position - header_plus_padding,
				behavior: 'smooth'
			});
		}

		const sortable_sections = document.querySelector('.edit-profile-showcase');
		if (sortable_sections){
			new Sortable(sortable_sections, {
				handle: '.edit-profile-showcase-section-drag',
				onEnd: this.order_changed,
			});
		}

		const showcase = document.querySelector('.main-content-inner > div');
		if (this.props.props.show_case_scroll > 0){
			showcase.scrollTo(0, this.props.props.show_case_scroll);
			store_set('show_case_scroll', 0);
		}
	}

	order_changed(){
		// Card sort callback - We should now update the cards order in the store
		const new_order = [];
		const sections = document.getElementsByClassName('edit-profile-showcase-section');
		for (let section of sections) { // eslint-disable-line
			new_order.push(section.id);
		}

		const options = [...this.props.state.showcase];
		for (let item of options){ // eslint-disable-line
			const item_index = new_order.findIndex(i => i === item.section);
			item.ordinal = item_index;
		}

		this.props.edit_profile_page('showcase', options);
	}

	change_visibility(section, value){
		const options = [...this.props.state.showcase];
		const item = options.find(item => item.section === section);
		item.visibility = value;

		this.props.edit_profile_page('showcase', options);
	}

	render(){
		const { props, on_delete } = this.props;
		const { render_options } = this.state;

		const top_artists_section = render_options.find(item => item.section === 'top_artists');
		const top_albums_section = render_options.find(item => item.section === 'top_albums');
		const top_songs_section = render_options.find(item => item.section === 'top_songs');

		const top_artists = props.user.top_artists && (props.user.top_artists.length > 0 || top_artists_section.spotify_stats === false) ? props.user.top_artists : props.spotify_stats ? props.spotify_stats.long_term.artists.slice(0,3) : [];
		const top_albums = props.user.top_albums && (props.user.top_albums.length > 0 || top_albums_section.spotify_stats === false) ? props.user.top_albums : props.spotify_stats ? props.spotify_stats.long_term.albums.slice(0,3) : [];
		const top_songs = props.user.top_songs && (props.user.top_songs.length > 0 || top_songs_section.spotify_stats === false) ? props.user.top_songs : props.spotify_stats ? props.spotify_stats.long_term.songs.slice(0,3) : [];
		const quizes = props.user_quiz ? props.user_quiz : [];
		const filtered_quizes = quizes.filter(item => constants.quiz_list.some(question => question.quiz === item.quiz));

		const sections = {
			discovery_leaderboard: {title: 'Discovery Leaderboard', component: <DiscoveryLeaderboard editable={true} data={props.user_leaderboard} user={props.user} />},
			discovery_timeline: {title: 'Discovery Timeline', component: <DiscoveryTimeline editable={true} data={props.magroove_stats.timeline_data} display_user='Me'/>},
			following_artists: {title: 'Following Artists', component: <FollowingArtists editable={true} data={props.following_artists} />},
			following_users: {title: 'Following Users', component: <FollowingUsers editable={true} data={props.following_users} />},
			genre_chart: {title: 'Genre Chart', component: <GenreChart editable={true} data={props.magroove_stats} my_data={props.magroove_stats} display_user='Me' own_profile={true}/>},
			music_aspects: {title: 'Music Aspects', component: <MusicAspects editable={true} data={props.magroove_stats} my_data={props.magroove_stats} display_user='Me' own_profile={true}/>},
			music_taste: {title: 'Music Taste', component: <MusicTaste editable={true} data={props.magroove_stats} my_data={props.magroove_stats} own_profile={true} display_user='Me' />},
			overview: {title: 'Overview', component: <Overview editable={true} user={props.user} />},
			top_artists: {title: `Top Artists (${top_artists.length}/3)`, component: <TopArtists editable={true} onDelete={on_delete} data={top_artists} />},
			top_albums: {title: `Top Albums (${top_albums.length}/3)`, component: <TopAlbums editable={true} onDelete={on_delete} data={top_albums} />},
			top_songs: {title: `Top Songs (${top_songs.length}/3)`, component: <TopTracks editable={true} onDelete={on_delete} data={top_songs} />},
			quizes: {title: `Quizes (${[...filtered_quizes].length}/${constants.quiz_list.length})`, component: <EditQuizes editable={true} data={quizes} onDelete={this.props.delete_user_quiz}/>},
			obsession: {title: 'Obsession', component: <Obsession editable={true} logged_user={props.user} user={props.user} />},
			polls: {title: 'Polls', component: <Polls editable={true} data={props.polls} user={props.user} onDelete={this.props.delete_poll}/>},
		};

		return (
			<div className='edit-profile-showcase'>
				{render_options.map((item, index) => {
					const section = sections[item.section];
					if (!section) return null;
					return (
						<div key={index} className='edit-profile-showcase-section' id={item.section}>
							<div className='edit-profile-showcase-section-header'>
								<div className='edit-profile-showcase-section-title'>{section.title}</div>
								<div className='edit-profile-showcase-section-buttons'>
									<VisibilityButton item={item} change={this.change_visibility} />
									<span className='material-icons-sharp edit-profile-showcase-section-drag'>drag_indicator</span>
								</div>
							</div>
							<div className='edit-profile-showcase-section-content'>
								{section.component}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

class EditProfile extends Component {
	constructor(props){
		super(props);
		this.state = {
			active_tab: 'General',
			tabs: ['General', 'Avatar', 'Banner', 'Showcase'],
			display_save_button: false,
			first_name: this.props.user.first_name,
			last_name: this.props.user.last_name,
			slug: this.props.user.slug,
			email: this.props.user.email,
			pronouns: this.props.user.pronouns,
			showcase: this.props.user.showcase,
			is_first_name_valid: true,
			is_last_name_valid: true,
			is_slug_valid: true,
			is_email_valid: true,
			file: [],
		};

		const url_string = window.location.href;
		const url = new URL(url_string);
		const tab = url.searchParams.get('tab');
		if (this.state.tabs.includes(tab)){
			this.state.active_tab = tab;
		}

		this.on_delete = this.on_delete.bind(this);
		this.delete_poll = this.delete_poll.bind(this);
		this.delete_user_quiz = this.delete_user_quiz.bind(this);
		this.on_new_image_success = this.on_new_image_success.bind(this);
		this.edit_profile_page = this.edit_profile_page.bind(this);
		this.handle_change = this.handle_change.bind(this);
	}

	on_delete(entity){
		const top_items = this.props.user['top_' + entity.type + 's'];
		const spotify_stats = this.props.spotify_stats ? this.props.spotify_stats.long_term[entity.type + 's'].slice(0,3) : [];
		const items = top_items.length > 0 ? top_items : spotify_stats;

		const new_items = items.filter(i => i.spotify_id !== entity.spotify_id);

		finish_search_selection(entity.type, new_items);
	}

	delete_poll(poll_hash_id){
		const polls = [...this.props.polls].filter(item => item.hash_id !== poll_hash_id);
		store_set('polls', polls);
		request_buffer.auth_execute({
			type: 'POST',
			url: constants.api_endpoint_delete_poll,
			data: { hash_id: poll_hash_id }
		});
	}

	delete_user_quiz(quiz){
		const user_quiz = [...this.props.user_quiz].filter(item => item.quiz !== quiz);
		store_set('user_quiz', user_quiz);
		storage.remove('user_quiz');

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_delete_user_quiz,
			data: {quiz: quiz}
		}, true);
	}

	on_new_image_success(data){
		const { file_hash_id, image_type, url } = data;

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_update_profile_picture,
			data: {hash_id: file_hash_id, picture_type: image_type, url: url},
			success: function(data){
				const user_object = store_get('user');
				if (image_type === 'profile') {
					user_object.picture = data.data.url;
				} else {
					user_object.header_picture = data.data.url;
				}
				store_set('user', user_object);
				store_set('loading_overlay_display', false);
			}
		}, true);
	}

	edit_profile_page(input_name = false, input_value = false){
		// Only try to edit if the fields are valid
		if (this.state.slug.length < 5) {
			display_snackbar('Username must have more than 5 characters...');
			return;
		}

		if (
			this.state.is_first_name_valid &&
			this.state.is_last_name_valid &&
			this.state.is_slug_valid &&
			this.state.is_email_valid
		){
			const data = {
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				slug: this.state.slug,
				email: this.state.email,
				pronouns: this.state.pronouns
			};

			if (input_name){
				data[input_name] = typeof input_value === 'string' ? input_value : JSON.stringify(input_value) ;
			}

			const previous_user_object = store_get('user');
			const user_object = Object.assign({}, previous_user_object);
			user_object.first_name = data.first_name;
			user_object.last_name = data.last_name;
			user_object.email = data.email;
			user_object.slug = data.slug;
			user_object.pronouns = data.pronouns;
			user_object[input_name] = input_value;
			store_set('user', user_object);

			this.setState({display_save_button: false});

			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_edit_profile_page,
				data: data,
				success: function(data){
					if (data.status !== 'success'){
						display_snackbar(data.reason);
						store_set('user', previous_user_object);
					}
				}
			}, true);

		} else {
			// Snackbar for invalid fields
			display_snackbar('Some input fields are invalid');
		}
	}

	handle_change(input_name, input_value){
		// Update the state with the new input value
		this.setState({[input_name]: input_value, display_save_button: true});

		// Regex for name validation
		const name_regex = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}$/;
		const slug_regex = /[^A-Za-z0-9]/g;

		const is_first_name_valid = name_regex.test(input_value);
		const is_last_name_valid = name_regex.test(input_value);
		const is_email_valid = EmailValidator.validate(input_value);

		switch (input_name) {
		case 'first_name':
			this.setState({is_first_name_valid});
			if (!is_first_name_valid){
				display_snackbar('Invalid First Name');
			}
			break;

		case 'last_name':
			this.setState({is_last_name_valid});
			if (!is_last_name_valid){
				display_snackbar('Invalid Last Name');
			}
			break;

		case 'email':
			this.setState({is_email_valid});
			if (!is_email_valid){
				display_snackbar('Invalid Email');
			}
			break;

		case 'slug':
			let check_regex = input_value; // eslint-disable-line
			check_regex = check_regex.replaceAll('-', '');
			check_regex = check_regex.split(' ').join('');
			check_regex = check_regex.toLowerCase();
			
			let is_non_alphanumeric = check_regex.match(slug_regex, ''); //eslint-disable-line

			if (input_value.match(/^[0-9a-zA-Z -]+$/) && is_non_alphanumeric === null) {
				input_value = input_value.replaceAll(' ', '-');
				input_value = input_value.toLowerCase();
				this.setState({slug: input_value});
				this.setState({is_slug_valid: true});
			} else {
				display_snackbar('Invalid Username');
				this.setState({is_slug_valid: false}) ;
			}

			break;
			
		default:
			break;
		}
	}

	render() {
		const { active_tab, tabs } = this.state;

		return (
			<div className='edit-profile-main-container'>
				<div className='edit-profile-tabs' id='edit-profile-tabs'>
					{tabs.map((tab, index) => (
						<div key={index} onClick={() => this.setState({active_tab: tab})}className={'edit-profile-tab ' + (active_tab === tab ? 'edit-profile-active-tab' : '')}>{tab}</div>
					))}
				</div>
				{active_tab === 'General' &&
					<General {...this} />
				}
				{active_tab === 'Avatar' &&
					<Avatar {...this} />
				}
				{active_tab === 'Banner' &&
					<Banner {...this} />
				}
				{active_tab === 'Showcase' &&
					<Showcase {...this} />
				}
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		spotify_stats: state.GlobalReducer.spotify_stats,
		magroove_stats: state.GlobalReducer.magroove_stats,
		profile_info: state.GlobalReducer.profile_info,
		user_leaderboard: state.GlobalReducer.user_leaderboard,
		user_quiz: state.GlobalReducer.user_quiz,
		likeminded_users: state.GlobalReducer.likeminded_users,
		user_token: state.GlobalReducer.user_token,
		linked_accounts: state.GlobalReducer.linked_accounts,
		following_artists: state.GlobalReducer.following_artists,
		following_users: state.GlobalReducer.following_users,
		playlists: state.GlobalReducer.playlists,
		selected_search_items: state.GlobalReducer.selected_search_items,
		show_case_scroll: state.GlobalReducer.show_case_scroll,
		polls: state.GlobalReducer.polls,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(EditProfile));
