import $ from 'jquery';
import 'format-unicorn';
import { Capacitor } from '@capacitor/core';

// Actions import
import {
	store_get,
	store_set,
	reset_store,
	load_version_info,
	get_api_version,
	get_base_version
} from '@actions/GlobalActions.js';

// Version import
import version from '@Version';

// Import the controllers
import subscription from '@Subscription';
import player from '@Player';
import user from '@User';

function deal_with_old_version(data){
	const is_browser = Capacitor.getPlatform() === 'web';
	if (is_browser && (!data.version || data.version.is_update_necessary)){
		reset_store(); player.reset();
		window.location.href = '/onboarding/';
	} else if (data.version){
		load_version_info(data.version);
	} else {
		store_set('version_overlay', 'force_base');
	}
}

class RequestBuffer {
	async auth_execute(request){
		const api_version = await get_api_version();
		request.url = request.url.formatUnicorn({api_version: api_version});

		// If user token is not available, redirect to the login page
		// but only if we are not currently in it (otherwise we get an infinite redirect)
		var user_token = user.check_for_token();
		if (!user_token) {
			if (store_get('location') !== 'login') {
				let url_parameters = '';
				if ('post_login' in request) {
					store_set('post_login_action', request['post_login']);
					url_parameters = '?post-login-action=true';
				}
				user.logout(url_parameters);
			}
			return;
		}

		request.beforeSend = function (xhr) {xhr.setRequestHeader('Authorization', 'JWT ' + user_token);};

		// Include as a parameter the current version numbers
		var data = request.data ? request.data : {};
		version.platform = Capacitor.getPlatform();
		version.base_version = await get_base_version();
		data.version = JSON.stringify(version);
		request.data = data;

		// Store the success callback to call it later
		const success_callback = request.success;

		// Check if there's an authentication error message, if yes, then redirect to the login page
		request.success = function(data) {
			// Check if there was an error
			if (data.status === 'error') {
				// If auth error found, then push to the auth queue
				if (data.reason === 'token_error' ||
					data.reason === 'token_expired_error' ||
					data.reason === 'user_token_expired_error'
				) {
					store_set('user_token', false);
					let url_parameters = '';
					if ('post_login' in request) {
						store_set('post_login_action', request['post_login']);
						url_parameters = '?post-login-action=true';
					}
					user.logout(url_parameters);
				}
				if (data.reason === 'version_missing'){
					deal_with_old_version(data);
				}
			}
			// probe_version callback will already take care of it
			if (!request.url.includes('probe-version') && data.version){
				deal_with_old_version(data);
			}
			if (typeof(data.app_constants) === 'object'){
				for (var [key, value] of Object.entries(data.app_constants)) {
					store_set(key, value);
				}
			}
			if (data.premium_subscription_expires_at !== undefined){
				const expires_at = data.premium_subscription_expires_at;
				const is_valid = subscription.is_valid(expires_at);
				store_set('premium_subscription_expires_at', expires_at);
				if (store_get('is_member_premium') !== is_valid){
					store_set('is_member_premium', is_valid);
				}
			}

			// If a success callback is defined, call it as well
			if (success_callback) {
				success_callback(data);
			}
		};

		// FInally, perform the request
		$.ajax(request);
	}

	async execute(request){
		const api_version = await get_api_version();
		request.url = request.url.formatUnicorn({api_version: api_version});

		var user_token = user.check_for_token();
		if (user_token){
			request.beforeSend = function (xhr) {xhr.setRequestHeader('Authorization', 'JWT ' + user_token);};
		}

		// Include as a parameter the current version numbers
		var data = request.data ? request.data : {};
		version.platform = Capacitor.getPlatform();
		version.base_version = await get_base_version();
		data.version = JSON.stringify(version);
		request.data = data;

		// Store the success callback to call it later
		const success_callback = request.success;

		// Check if there's an authentication error message, if yes, then reschedule
		request.success = function(data) {
			// Check if there was an error
			if (data.status === 'error') {
				// If auth error found, then push to the auth queue
				if (data.reason === 'token_error' ||
					data.reason === 'token_expired_error' ||
					data.reason === 'user_token_expired_error'
				) {
					request_buffer.auth_execute(request);
				}
				if (data.reason === 'version_missing'){
					deal_with_old_version(data);
				}
			}
			// probe_version callback will already take care of it
			if (!request.url.includes('probe-version') && data.version){
				deal_with_old_version(data);
			}
			if (typeof(data.app_constants) === 'object'){
				for (var [key, value] of Object.entries(data.app_constants)) {
					store_set(key, value);
				}
			}

			// If a success callback is defined, call it as well
			if (success_callback) {
				success_callback(data);
			}
		};

		// Perform the request
		$.ajax(request);
	}
}

const request_buffer = new RequestBuffer();

export { request_buffer };