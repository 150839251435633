import React, { Component } from 'react';
import { connect } from 'react-redux';
import SnackbarComponent from '@material-ui/core/Snackbar';

// CSS Imports
import './styles/Snackbar.css';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

class Snackbar extends Component {
	render(){
		return(
			<div className="snackbar-container">
				<SnackbarComponent
					open={this.props.snackbar_status}
					message={this.props.snackbar_message}
					autoHideDuration={3000}
					onClose={() => store_set('snackbar_status', false)}
				/>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		snackbar_status: state.GlobalReducer.snackbar_status,
		snackbar_message: state.GlobalReducer.snackbar_message
	};
}

// Connect component to the store
export default connect(mapStateToProps)(Snackbar);