import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Swipeable } from 'react-swipeable';

// CSS Imports
import './styles/Footer.css';

// Assets Imports
import { export_all } from '@Assets';

// Actions import
import { store_set, finish_search_selection, store_get } from '@/actions/GlobalActions.js';

// Import the queue
import queue from '@Queue'; 

// Import the User
import user from '@User';

// Badge Animation
import Lottie from 'react-lottie-player';
import badge_footer_animation from '../assets/badge_footer_animation.json';

// Import the controllers
import subscription from '@Subscription';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Constant Imports
import * as constants from '@constants';

// Actions import
import { redirect } from '@routes/Routes.js';

class Footer extends Component {
	constructor(props){
		super(props);
		this.state = {};
		this.find_similar_button = this.find_similar_button.bind(this);
		this.handle_progress_button = this.handle_progress_button.bind(this);
	}

	find_similar_button(track_list){		
		let hash_list = track_list.map((track) => track.spotify_id);
		if (hash_list.length > 5){
			let random_list = [];
			for(let i = 0; i < 5; i++){
				let random_hash = hash_list[Math.floor(Math.random()*hash_list.length)];
				random_list.push(random_hash);
				hash_list = hash_list.filter(hash => hash !== random_hash);
			}
			hash_list = random_list;
		}
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		queue.get_recommendations(hash_list.join(), 'song', '', true);
		store_set('seed', {name: 'Custom', type: 'songs', spotify_id: hash_list});
		store_set('active_overlay', false);
	}

	handle_progress_button() {
		if (!this.props.footer_progress_allow_next) { return; }
		if (this.props.selected_search_items.length === 0) {
			return store_set('footer_progress_allow_next', false);
		}

		if (this.props.footer_progress_index === this.props.footer_progress_length - 1) {
			if (['recommendation-search', 'poll-recommendation-search'].includes(this.props.location)){
				this.props.send_user_message();
			}
			if (this.props.location === 'obsession-search'){
				store_set('footer_progress_index', 0);
				user.get_data();
				redirect('/profile/');
			}
		} else {
			if (this.props.location === 'obsession-search')
				if (this.props.selected_search_items.length > 0){
					store_set('loading_overlay_display', true);
					store_set('loading_overlay_component', 'circular-progress');
					store_set('loading_overlay_text', 'Loading...');
					let user_info = store_get('user');
					if(user_info.obsession && user_info.obsession.song && user_info.obsession.song.spotify_id !== this.props.selected_search_items[0].spotify_id){
						store_set('user', { ...user_info, obsession: {}}); 
						user_info = ({ ...user_info, obsession: {}}); 
					}
					if (user_info && (!user_info.obsession || !user_info.obsession.song || user_info.obsession.song.spotify_id !== this.props.selected_search_items[0].spotify_id)){
						let showcase = user_info.showcase || [];
						let obsession_section = showcase.find(item => item.section === 'obsession');
						let visibility = obsession_section ? obsession_section.visibility === 'on' : true;

						request_buffer.auth_execute({
							type: 'post',
							url: constants.api_endpoint_add_obsession,
							data: {song_info: JSON.stringify(this.props.selected_search_items[0]), visibility: visibility},
							success: function(){
								store_set('user', { ...user_info, obsession: { song: this.props.selected_search_items[0] }}); 
								user.get_data();
							}.bind(this),
							complete: function() {
								store_set('loading_overlay_display', false);
							},
							timeout: 30000
						});
					}
				}
			store_set('footer_progress_index', this.props.footer_progress_index + 1);
		}
	}

	render() {
		if (
			this.props.location === 'login' ||
			this.props.location === 'onboarding' ||
			this.props.location === 'quiz-search' ||
			this.props.location === 'new-poll' ||
			this.props.location === 'poll' ||
			this.props.keyboard_open ||
			this.props.hide_footer
		) {
			return(
				<footer></footer>
			);
		} else if (this.props.location === 'edit-profile-search' && this.props.is_footer_progress) {
			const entity_type = window.location.pathname.replace('/edit-profile-search/', '').replace('/', '');
			const total_new = this.props.selected_search_items.length;
			const total_selected = total_new + this.props.already_selected_search_items.length;
			return(
				<footer className='recommendation-search-footer'>
					{false && //this.props.footer_progress_index > 0 &&
						<div className='recommendation-search-footer-left-button'>
							<div className='recommendation-search-footer-back' onClick={() => store_set('footer_progress_index', this.props.footer_progress_index - 1)}>Back</div>
						</div>
					}
					<div className='recommendation-search-footer-left-area'>
						<div
							className='recommendation-search-footer-progress-white-dot'
							style={total_selected === 3 ? {backgroundColor: '#ec6b43'} : total_selected === 0 ? {width: 8, height: 8, opacity: 0.25} : {}}
						/>
						<div
							className='recommendation-search-footer-progress-white-dot'
							style={total_selected === 3 ? {backgroundColor: '#ec6b43'} : total_selected < 2 ? {width: 8, height: 8, opacity: 0.25} : {}}
						/>
						<div
							className='recommendation-search-footer-progress-white-dot'
							style={total_selected === 3 ? {backgroundColor: '#ec6b43'} : total_selected < 3 ? {width: 8, height: 8, opacity: 0.25} : {}}
						>
							{total_selected === 3 ? <span className='material-icons'>check</span> : null}
						</div>
					</div>
					<div
						className='recommendation-search-footer-right-button'
						style={{opacity: total_new > 0 ? 1 : 0.2}}
						onClick={() => total_new > 0 ? finish_search_selection(entity_type, this.props.selected_search_items.concat(this.props.already_selected_search_items)) : {}}
					>
						<div className='recommendation-search-footer-next'>Add</div>
						<span className='material-icons'>arrow_forward</span>
					</div>
				</footer>
			);
		// Step by step footer with "Skip/Back/Next" buttons
		} else if (['poll-recommendation-search', 'recommendation-search', 'obsession-search'].includes(this.props.location) && this.props.is_footer_progress) {
			return(
				<footer className='recommendation-search-footer'>
					{false && //this.props.footer_progress_index > 0 &&
						<div className='recommendation-search-footer-left-button'>
							<div className='recommendation-search-footer-back' onClick={() => store_set('footer_progress_index', this.props.footer_progress_index - 1)}>Back</div>
						</div>
					}
					<div className='recommendation-search-footer-center-area'>
						{Array.from({ length: this.props.footer_progress_length }).map((_, i) =>(
							<div key={i}
								className='recommendation-search-footer-progress-dot'
								style={this.props.footer_progress_index === i ? {width: 24, opacity: 1} : {}}
							></div>
						))}
					</div>
					<div className='recommendation-search-footer-right-button'
						style={{opacity: this.props.footer_progress_allow_next ? 1 : 0.2}}
						onClick={this.handle_progress_button}
					>
						{this.props.footer_progress_index === this.props.footer_progress_length - 1 ?
							<div className='recommendation-search-footer-next'>{this.props.location === 'recommendation-search' ? 'Send' : 'Finish'}</div>
							:
							<React.Fragment>
								<div className='recommendation-search-footer-next'>Next</div>
								<span className='material-icons'>arrow_forward</span>
							</React.Fragment>
						}
					</div>
				</footer>
			);
		} else if (this.props.select_mode) {
			var tracks_selected = this.props.tracks_selected.length > 0;
			return(
				<footer className='select-mode-footer'>
					<div
						className='select-mode-footer-item'
						onClick={tracks_selected ? () => store_set('active_overlay', 'remove_songs') : () => {}}
						style={{opacity: tracks_selected ? 1 : 0.3}}
					>
						<span className='material-icons'>delete</span>
						<div className='select-mode-footer-item-text'>Delete</div>
					</div>
					<div
						className='select-mode-footer-item'
						onClick={tracks_selected ? () => this.find_similar_button(this.props.tracks_selected) : () => {}}
						style={{opacity: tracks_selected ? 1 : 0.3}}
					>
						<span className='material-icons-outlined'>batch_prediction</span>
						<div className='select-mode-footer-item-text'>Find Similar</div>
					</div>
					<div
						className='select-mode-footer-item'
						onClick={(tracks_selected && this.props.tracks_selected.length <= 50) ? () => {store_set('active_overlay', 'track_options'); store_set('active_overlay_option', 'export_select_mode');} : () => {}}
						style={{opacity: (tracks_selected && this.props.tracks_selected.length <= 50) ? 1 : 0.3}}
					>
						<span className='material-icons-outlined'>file_upload</span>
						<div className='select-mode-footer-item-text'>{this.props.tracks_selected.length > 50 ? '50 Max' : 'Export'}</div>
					</div>
					<div
						className='select-mode-footer-item'
						onClick={(e) => {store_set('active_overlay', 'track_options'); store_set('active_overlay_option', 'export_all'); e.stopPropagation();}}
					>
						<img src={export_all} alt='' style={{height: 22}}/>
						<div className='select-mode-footer-item-text' style={{marginTop: 5}}>Export All</div>
					</div>
					<div
						className='select-mode-footer-item'
						onClick={() => {store_set('tracks_selected', []); store_set('select_mode', false);}}
					>
						<span className='material-icons'>close</span>
						<div className='select-mode-footer-item-text'>Close</div>
					</div>
				</footer>
			);
		} else {
			return (
				<Swipeable
					delta={1}
					className='main-footer'
					onSwipedDown={() => store_set('display_player', false)}
					onSwipedUp={() => store_set('display_player', true)}
				>
					<footer className='main-footer'>
						<Link
							to='/'
							className={this.props.navigation_location === 'home' ? 'navigation-item navigation-active' : 'navigation-item'}
						>
							<i className='material-icons navigation-item-icon'>home</i>
							<span className='navigation-item-subtitle'>Home</span>
						</Link>
						<Link
							to='/my-list/'
							className={this.props.navigation_location === 'my-list' ? 'navigation-item navigation-active' : 'navigation-item'}
						>
							<span className='navigation-item-bubble-container'>
								{this.props.new_likes &&
									<span className='navigation-item-bubble'></span>
								}
								<i className='material-icons navigation-item-icon'>playlist_play</i>
							</span>
							<span className='navigation-item-subtitle'>My List</span>
						</Link>
						{this.props.is_member_premium === false /* must be different from undefined */ ?
							<div
								className='navigation-item navigation-premium'
								onClick={subscription.is_valid() ? () => redirect('/subscriptions/') : () => subscription.invite()}
							>
								<div className='navigation-premium-badge'>
									<Lottie
										speed={0.8}
										loop={true}
										play={true}
										animationData={badge_footer_animation}
									/>
								</div>
								<span className='navigation-item-subtitle'>Premium</span>
							</div>
							:
							<Link
								to='/fine-tunning/'
								className={this.props.navigation_location === 'fine-tunning' ? 'navigation-item navigation-active' : 'navigation-item'}
							>
								<i className='material-icons navigation-item-icon'>tune</i>
								<span className='navigation-item-subtitle'>Tuning</span>
							</Link>	
						}
						<Link
							to='/search/'
							className={this.props.navigation_location === 'search' ? 'navigation-item navigation-active' : 'navigation-item'}
						>
							<i className='material-icons navigation-item-icon'>search</i>
							<span className='navigation-item-subtitle'>Search</span>
						</Link>
						<Link
							to='/profile/'
							className={this.props.navigation_location === 'profile' ? 'navigation-item navigation-active' : 'navigation-item'}
						>
							<i className='material-icons navigation-item-icon'>person</i>
							<span className='navigation-item-subtitle'>Profile</span>
						</Link>
					</footer>
				</Swipeable>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		navigation_location: state.GlobalReducer.navigation_location,
		hide_footer: state.GlobalReducer.hide_footer,
		keyboard_open: state.GlobalReducer.keyboard_open,
		select_mode: state.GlobalReducer.select_mode,
		tracks_selected: state.GlobalReducer.tracks_selected,
		new_likes: state.GlobalReducer.new_likes,
		is_member_premium: state.GlobalReducer.is_member_premium,
		is_footer_progress: state.GlobalReducer.is_footer_progress,
		footer_progress_length: state.GlobalReducer.footer_progress_length,
		footer_progress_index: state.GlobalReducer.footer_progress_index,
		footer_progress_allow_next: state.GlobalReducer.footer_progress_allow_next,
		selected_search_items: state.GlobalReducer.selected_search_items,
		already_selected_search_items: state.GlobalReducer.already_selected_search_items,
		send_user_message: state.GlobalReducer.send_user_message,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(Footer);
