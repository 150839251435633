import React, { Component } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import storage from '@Storage';

// Styles import
import './styles/Quiz.css';

// Components and Views imports
import Card from '@components/Card.js';
import { CircularProgress } from '@material-ui/core';

// Assets import
import * as assets from '@Assets';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Constants import
import * as constants from '@constants';

// Import functions
import { redirect } from '@routes/Routes';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

class Quiz extends Component {
	constructor(props){
		super(props);
		this.state = {
			step: 0,
			quiz: undefined,
			edit_quiz: false,
			quiz_list: [...constants.quiz_list],
			item: undefined,
			index: 0,
			max_index: 0,
			user_quiz: [],
			description: '',
			show_skip_tip: false,
			revert_animation: false,
			search_query: this.props.props.search_query,
			saving: false,
		};
		this.back_quiz = this.back_quiz.bind(this);
		this.next_quiz = this.next_quiz.bind(this);
		this.close_quiz = this.close_quiz.bind(this);
		this.animation_id = this.animation_id.bind(this);
		this.save_quiz = this.save_quiz.bind(this);
		this.input_changed = this.input_changed.bind(this);
		
		let quiz = this.props.props.quiz;
		if (quiz) {
			this.state.index = quiz.index;
			this.state.quiz = quiz.quiz;
		}

		let edit_quiz = this.props.props.edit_quiz;
		if (edit_quiz) this.state.edit_quiz = quiz;

		let user_quiz = [...this.props.props.user_quiz];
		if (user_quiz) {
			// Show tip if user never used the quiz before
			if (user_quiz.length === 0) this.state.show_skip_tip = true;
			else this.state.user_quiz = user_quiz;
		}

		this.state.max_index = this.state.quiz_list.length - 1;
	}

	back_quiz(){
		if (this.state.index > 0) {
			let new_index = this.state.index - 1;
			let new_quiz = this.state.quiz_list[new_index];

			if (new_quiz.search && new_quiz.search !== this.props.state.display_results_type){
				this.props.change_result_type(new_quiz.search);
			} 
			let data = {quiz: new_quiz, index: new_index};
			this.setState(data); store_set('quiz', data);
			this.input_changed('');
		}
		else this.close_quiz();
	}

	next_quiz(){
		if (this.state.edit_quiz) this.close_quiz();
		else if (this.state.index === this.state.max_index) {
			this.setState({step: 2});
		}
		else {
			let new_index = this.state.index + 1;
			let new_quiz = this.state.quiz_list[new_index];

			if (new_quiz.search && new_quiz.search !== this.props.state.display_results_type) {
				this.props.change_result_type(new_quiz.search);
			}

			this.setState( {quiz: new_quiz, index: new_index, description: '', step: 0, item: undefined, search_query: ''}); 
			store_set('quiz', {quiz: new_quiz, index: new_index});
			this.input_changed('');        
		}
	}

	animation_id(name){
		if (this.state.revert_animation) return(`revert-${name}-animation`);
		if (this.state.search_query.length > 0 || this.state.quiz.options) return(`resize-${name}-animation`);
		return '';
	}

	input_changed(event){
		const input_changed = store_get('input_changed');
		input_changed(event);
		if (event.target) this.setState({search_query: event.target.value});
		else this.setState({search_query: event});
	}

	save_quiz(){
		if (this.state.saving) return;
		// eslint-disable-next-line
		this.state.saving = true;

		const quiz = this.state.quiz;
		const extra_info = this.state.extra_info;
		const description = this.state.description;
		const new_user_quiz = [...this.state.user_quiz].filter(item => item.quiz !== quiz.quiz);    
		const item = this.state.item;
		let new_item; let source = '';
		let item_type = 'multiple choice';

		this.next_quiz();

		let already_added = this.state.user_quiz.filter(item => item.hash_id === quiz.hash_id);
		if (already_added.length > 0) return;

		if (quiz.search) {
			item_type = quiz.search;
			if (item.hash_id) {
				new_item = item.hash_id;
				source = 'hash_id';
			}
			else {
				new_item = item.spotify_id;
				source = 'spotify_id';
			}
			if (extra_info) {
				if (quiz.quiz === 'generation_song') {
					new_item.generation = extra_info;
				}
			}
		} 
		else new_item = item;

		// Saving the quiz
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_save_user_quiz,
			data: {
				quiz: quiz.quiz,
				item_type: item_type,
				item: new_item,
				description: description,
				source: source,
			},
			success: function(data){
				if (data.status === 'success') {
					const new_item = {
						quiz: quiz.quiz,
						item_type: item_type,
						item: item,
						description: description,
						hash_id: data.quiz_hash_id
					};

					let user_quiz = [...new_user_quiz]; 
					user_quiz.push(new_item);
					this.setState({user_quiz: user_quiz}); 
					store_set('user_quiz', user_quiz);
					storage.set('user_quiz', user_quiz, 86400);
				}
			}.bind(this),
		},  true);
		
		// eslint-disable-next-line
		this.state.saving = false;
	}

	close_quiz(){
		this.setState({
			quiz: undefined,
			quiz_list: [...constants.quiz_list],
			index: 0,
			max_index: 0,
			step: 0,
			item: undefined,
			description: '',
			user_quiz: [],
			search_query: '',
			show_skip_tip: false,
			revert_animation: false,
		});

		store_set('quiz', undefined);
		store_set('edit_quiz', false);
		this.input_changed('');
		redirect('/edit-profile?tab=Showcase');
	}

	render(){
		const props = this.props.props;
		const state = this.props.state;
		const numberIcons = Math.ceil(window.innerWidth/55) - 2;

		if (!this.state.quiz) return null;
		return(
			<div className='quiz'>
				<div className='quiz-background' style={{backgroundImage: `url('${assets.quiz_overlay_header}')`}}></div> 
				<div className='quiz-border' style={{width: `${80 + this.state.step * 10}%`}}></div>
				{/* Header */}
				{this.state.step === 2 ?
					<div className='quiz-header' style={{padding: 0}}>
						<div className='quiz-end-background' style={{backgroundImage: `url('${assets.quiz_end_check}')`}}></div>
					</div>
					:
					<div className='quiz-header'>
						<span className='material-icons' onClick={() => this.close_quiz()}> arrow_back </span>
						<p>{this.state.index + 1}/{this.state.quiz_list.length}</p>
					</div>
				}
				{/* Body */}
				<div className='quiz-body'>
					{this.state.step !== 2 &&
						<div className='quiz-title'>
							<div className='quiz-title-icons' id={this.animation_id('icon')}>
								<figure className='quiz-title-icon'>
									<img src={this.state.quiz.icons[1]} alt=''></img>
								</figure>
								<figure className='quiz-title-icon'>
									<img src={this.state.quiz.icons[0]} alt=''></img>
								</figure>
								{[...Array(numberIcons)].map((e, i) => {
									let opacity = 1.0;
									let rest = numberIcons - i;

									if (rest <= 4) opacity = 0.2 * rest;
									return(
										<figure className='quiz-title-icon' style={{opacity: opacity}} key={i}>
											<img src={this.state.quiz.icons[1]} alt=''></img>
										</figure>);
								})}
							</div>
							<p className='quiz-title-text' id={this.animation_id('text')}>{this.state.quiz.title}</p>
						</div>
					}
					{this.state.step === 0 &&
						<div className='quiz-content'>
							{this.state.quiz.search ?
								<div className='quiz-search'>
									<input
										placeholder={this.state.quiz.search === 'song' ? 'Search for a song' : 'Search for an artist'}
										value={this.state.search_query} 
										type='text' 
										onChange={(event) => this.input_changed(event)} 
									/>
									<span className='material-icons' onClick={() => {if (this.state.search_query.length > 0) this.input_changed(this.state.search_query);}}>
										search
									</span>
								</div>
								:
								<ul className='quiz-options' style={{height: window.innerHeight - 343}}>
									{this.state.quiz.options.map((option, i) => {
										return(<li key={i} onClick={() => {
											// eslint-disable-next-line
											this.state.item = option;
											this.save_quiz();
										}}>{option}</li>);
									})}
								</ul>
							}
							{state.loading_result && this.state.search_query.length > 0 &&
								<div className='quiz-loading-container'>
									<CircularProgress size='50px'/>
								</div>
							}
							{(this.state.search_query.length > 0 && !state.display_feedback && props.display_results && !state.loading_result) &&
								<React.Fragment>
									<div className='quiz-search-results-container' id='quiz-search-results-container' style={{height: window.innerHeight - 250}}>
										{state.ordered_data.slice(state.results_slice, state.ordered_data.length).map((item, index) => {
											if (item.type !== this.state.quiz.search) return null;
											if (index > 0 && item.type !== 'profile') { // Prevent duplicate results even if different IDs (display the most popular)
												const previous = state.ordered_data[index - 1];
												const same_type = item.type === previous.type;
												const same_text = item.type === 'artist' ? item.name === previous.name : item.title === previous.title;
												const same_album = same_type && item.type === 'song' ? item.album.name === previous.album.name : true;
												const less_popular = previous.popularity >= item.popularity;
												if (same_type && same_text && same_album && less_popular) return null;
											}

											if (item.type === 'song'){
												return(
													<Card key={index}
														data={item} type='small_card_song' displayLabel={true}
														onClick={() => this.setState({item: item, step: 1})}
														selectedIcon='normal'
													/>
												);
											} else if (item.type === 'artist'){
												return(
													<Card key={index}
														data={item} type='small_card_artist'
														onClick={() => this.setState({item: item, step: 1})}
														selectedIcon='normal'
													/>
												);
											}
											else return null;
										})}
									</div>
								</React.Fragment>
							}
							{state.display_feedback &&
								<React.Fragment>
									{this.props.feedback_reason === 'nothing found' &&
										<div className='search-feedback-container'>
											<div className='search-feedback-icon'>
												<span className='material-icons'>
													warning
												</span>
											</div>
											<div className='search-feedback-title'>Nothing found...</div>
											<div className='search-feedback-description'>Try searching again using a different spelling of keyword</div>
										</div>
									}
									{this.props.feedback_reason === 'error' &&
										<div className='search-feedback-container'>
											<div className='search-feedback-icon'>
												<span className='material-icons'>
													error
												</span>
											</div>
											<div className='search-feedback-title'>Ooooops...</div>
											<div className='search-feedback-description'>Seems like we had an error, please try again later.</div>
										</div>
									}
								</React.Fragment>
							}
						</div>
					}
					{this.state.step === 1 &&
						<div className='quiz-content' id='quiz-content-second-step'>
							<Card 
								type='quiz_card' 
								dataItem={{item: this.state.item}}
								displayButtons={true}
								id={this.state.input_animation}
							/>

							<ClickAwayListener onClickAway={() => {
								const quiz = document.querySelector('#quiz-content-second-step');
								quiz.scrollTo({left: 0, top: 0, behavior: 'smooth'});
							}}>
								<textarea
									placeholder='You can write something... or not!'
									className='quiz-description'
									value={this.state.description}
									type='text'
									onChange={(e) => this.setState({description: e.target.value})}
									onClick={() => {
										const quiz = document.querySelector('#quiz-content-second-step');
										quiz.scrollTo({left: 0, top: 200, behavior: 'smooth'});
									}}
								/>
							</ClickAwayListener>
						</div>
					}
					{this.state.step === 2 &&
						<div className='quiz-content'>
							<p className='quiz-end-title'>That was all!</p>
							<p className='quiz-end-text'>Your answers will be shown on your profile and you will be able to edit them anytime you want.</p>
						</div>
					}
				</div>
				{/* Footer */}
				<div className='quiz-footer'>
			{this.state.show_skip_tip &&
				<ClickAwayListener onClickAway={() => this.setState({show_skip_tip: false})}>
					<div className='quiz-footer-tip'>
						<span className='material-icons' onClick={() => this.setState({show_skip_tip: false})}> close </span>
						<figure className='quiz-footer-tip-icon'>
							<img src={assets.quiz_tip_icon} alt=''></img>
						</figure>
						<p>Don&apos;t worry! You can modify your answers anytime you want.</p>
					</div>
				</ClickAwayListener>
			}
			{this.state.step === 2 ?
				<div className='quiz-footer-buttons'>
					<div className='quiz-footer-button' onClick={() => this.setState({step: 1})}>
						<span className='material-icons'> arrow_back </span>
						<p>Back</p>
					</div>
					<div className='quiz-footer-button' onClick={() => this.close_quiz()}> 
						<p>Finish</p>
					</div>
				</div>
		
				:   <div className='quiz-footer-buttons'>
					<div className='quiz-footer-button' onClick={() => {
						if (this.state.step === 1) this.setState({step: 0});
						else if (this.state.index > 0) this.back_quiz();
						else this.close_quiz();
					}}>
						<span className='material-icons'> arrow_back </span>
						<p>Back</p>
					</div>
					
					{!this.state.edit_quiz &&
						<p style={{opacity: this.state.index === this.state.max_index ? 0.5 : 1.0}} onClick={this.next_quiz}>Skip</p>
					}

					<div className='quiz-footer-button' 
						style={{opacity: this.state.step === 0 ? 0.5 : 1.0}}
						onClick={() => {if (this.state.step === 1) this.save_quiz();}}>
						<p>Next</p>
						<span className='material-icons'> arrow_forward </span>
					</div>
				</div>
			}
				</div>
			</div>);
	}
}

export default Quiz;