import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// CSS Imports
import './styles/Blocklist.css';

// Components and Views Imports
import Card from '@components/Card.js';
import { CircularProgress } from '@material-ui/core';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Assets Import
import { blocklist_icon } from '@Assets';

class Blocklist extends Component {
	constructor(props){
		super(props);
		this.state = {
			filter: 'all'
		};
	}

	on_more_click(entity_type, entity){
		store_set('active_overlay', entity_type + '_options');
		store_set(entity_type + '_info', entity);
		store_set('active_overlay_option', 'show_all_' + entity_type);
	}

	render() {
		const { filter } = this.state;
		const { blocklist, loading_blocklist } = this.props;

		if (loading_blocklist){
			return (
				<div className='blocklist-loading'>
					<CircularProgress />
				</div>
			);
		}
		if (!blocklist || blocklist.length === 0){
			return (
				<div className='blocklist-empty'>
					<img src={blocklist_icon} alt='Blocklist'/>
					<div className='blocklist-empty-title'>Your blocklist is empty</div>
					<div className='blocklist-empty-text'>That&apos;s good! It means you don&apos;t dislike anything enought to put it here</div>
					<Link to='/'>
						<div className='blocklist-empty-button'>GO BACK TO DISCOVERY</div>
					</Link>
				</div>
			);
		}
		return (
			<div className='blocklist'>
				<div className='blocklist-results-section-options'>
					<div className={'blocklist-results-section-option-item' + (filter === 'all' ? '-selected' : '')} onClick={() => this.setState({filter: 'all'})}>All results</div>
					<div className={'blocklist-results-section-option-item' + (filter === 'album' ? '-selected' : '')} onClick={() => this.setState({filter: 'album'})}>Albums</div>
					<div className={'blocklist-results-section-option-item' + (filter === 'artist' ? '-selected' : '')} onClick={() => this.setState({filter: 'artist'})}>Artists</div>
				</div>
				<div className='blocklist-results-container'>
					{blocklist.map((item, index) => {
						if (!['all', item.type].includes(filter)) return null;
						if (item.type === 'album'){
							return (
								<Card data={item} type='small_card_album' displayLabel={true} key={index} onMoreClick={() => this.on_more_click('album', item)} />
							);
						}
						return(
							<Card data={item} button_type='more' type='small_card_artist' key={index} onMoreClick={() => this.on_more_click('artist', item)} />
						);
					})}
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		blocklist: state.GlobalReducer.blocklist,
		loading_blocklist: state.GlobalReducer.loading_blocklist
	};
}

// Connect component to the store
export default connect(mapStateToProps)(Blocklist);