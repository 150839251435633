import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Img } from 'react-image';
import ContentLoader from 'react-content-loader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Ripples from 'react-ripples';
import Dialog from '@material-ui/core/Dialog';
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	TelegramShareButton,
	VKShareButton,
} from 'react-share';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '../RequestBuffer.js';

// Imports
import * as constants from '../constants.js';
import * as assets from '@Assets';

// Import Components
import Card from '@components/Card.js';

// CSS Imports
import './styles/DesktopArtistPage.css';

class DesktopArtistPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			artist_hash_id: null,
			backdrop: true,
			open_share_overlay: false,
			most_popular: [],
			artist_albums: [],
			artist_albums_backup: [],
			display_artist_albums: [],
			artist_stats: {},
			similar_artists: [],
		};

		var path = window.location.href;
		var artist_hash_id = null;

		if (path.includes('artist')) {
			artist_hash_id = this.props.url.params.artist_hash_id;
			this.state.artist_hash_id = artist_hash_id;
		}

		this.get_artist_info = this.get_artist_info.bind(this);
		this.get_artist_stats = this.get_artist_stats.bind(this);
		this.get_top_tracks = this.get_top_tracks.bind(this);
		this.get_artist_albums = this.get_artist_albums.bind(this);
		this.get_similar_artists = this.get_similar_artists.bind(this);
		this.load_artist_albums = this.load_artist_albums.bind(this);
		this.hide_artist_albums = this.hide_artist_albums.bind(this);

		this.get_artist_info(artist_hash_id);
		// this.get_artist_stats();
		this.get_top_tracks();
		this.get_artist_albums();
		this.get_similar_artists();
		this.load_artist_albums();
	}

	get_artist_info(hash_id){
		/* eslint-disable-next-line */
		this.state.backdrop = true;
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/artist/',
			data: {hash_id: hash_id},
			success: function(data){
				if (data.status === 'success') {
					store_set('artist_info', data);
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
				this.setState({'backdrop': false});
			}.bind(this),
		});
	}

	get_artist_stats(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_stats,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({'artist_stats': data.data});
				}
			}.bind(this),
		});
	}

	get_top_tracks(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_top_tracks,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({'most_popular': data.data});
				}
			}.bind(this),
		});
	}

	get_artist_albums(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_albums,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({'artist_albums': data.data, 'artist_albums_backup': [...data.data]});
					this.load_artist_albums();
				}
			}.bind(this),
		});
	}

	get_similar_artists() {
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_similar_artists,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({'similar_artists': data.similar_artists});
				}
			}.bind(this),
		});
	}

	load_artist_albums() {
		var artist_albums = this.state.artist_albums.length > 0 ? this.state.artist_albums : [...this.state.artist_albums_backup];
		var display_artist_albums = this.state.display_artist_albums;
		display_artist_albums.push(
			<div className={'app-desktop-artist-body-section-dynamic-content' + (display_artist_albums.length % 2 === 0 ? '-right' : '-left')}>
				{artist_albums.splice(0,7).map((album, i) => {
					let enlarge = (i === 0 || i % 7 === 0) ? true : false;
					return(
						<Card data={album} type='desktop_card_album' bodyLink={'/album/' + album.spotify_id} key={i} enlarge={enlarge} />
					);
				})}
			</div>);
		this.setState({display_artist_albums});
	}

	hide_artist_albums() {
		var artist_albums = this.state.artist_albums;
		var display_artist_albums = [...this.state.display_artist_albums];
		display_artist_albums.pop();
		if (display_artist_albums.length === 2 && artist_albums.length === 0) {
			// eslint-disable-next-line
			this.state.artist_albums = [...this.state.artist_albums_backup.slice(7)];
		}
		this.setState({display_artist_albums});
	}

	render() {
		if (this.state.backdrop) {
			return(
				<React.Fragment>
					<Backdrop open={this.state.backdrop}>
						<CircularProgress color='inherit' />
					</Backdrop>
				</React.Fragment>
			);
		} else {
			var artist_info = this.props.artist_info;
			var { most_popular, display_artist_albums, artist_stats, similar_artists } = this.state;
			return (
				<div className='app-desktop-artist-page'>
					<div className='app-desktop-artist-header-background' style={{backgroundImage: 'url(' + assets.desktop_artist_banner + ')'}}>
						<div className='app-desktop-artist-header-gradient'></div>
					</div>
					<div className='app-desktop-artist-container'>
						<div className='app-desktop-artist'>
							<div className='app-desktop-artist-header'>
								<div className='app-desktop-artist-header-image'>
									<Img
										src={[artist_info.images.large, assets.default_artist_image]}
										loader={
											<ContentLoader 
												width={'212px'}
												height={'212px'}
												backgroundColor={'#D8D8D8'}
												foregroundColor={'#b1afaf'}
											>
												<circle x='0' y='0' rx='0' ry='0' width={100 + '%'} height={100 + '%'} /> 
											</ContentLoader>
										}
									/>
									<div className='app-desktop-artist-header-image-tag'>Artist</div>
								</div>
								<div className='app-desktop-artist-header-description'>
									<div className='app-desktop-artist-header-description-title'>
										<div className='app-desktop-artist-header-description-text-title'>{artist_info.name}</div>
										<Ripples>
											<div className='app-desktop-artist-header-button' onClick={() => this.setState({'open_share_overlay': true})}>
												<span className='material-icons'>share</span>
											</div>
										</Ripples>
									</div>
									{/*
										<div className='app-desktop-artist-header-options'>
											<Ripples>
												<div className='app-desktop-artist-header-button' onClick={() => {}}>
													<span className='material-icons'>add</span>
													<div className='app-desktop-artist-header-button-text'>FOLLOW</div>
												</div>
											</Ripples>
											<Ripples>
												<div className='app-desktop-artist-header-button' onClick={() => window.open('http://open.spotify.com/artist/' + artist_info.spotify_id)}>
													<span className='material-icons'>share</span>
												</div>
											</Ripples>
										</div>
										<div className='app-desktop-artist-header-listeners'>2.599 Monthly Listeners</div>
									*/}
								</div>
							</div>
							<div className='app-desktop-artist-body'>
								{most_popular.length > 0 &&
									<div className='app-desktop-artist-body-section'>
										<div className='app-desktop-artist-body-section-title'>Most Popular</div>
										<div className='app-desktop-artist-body-section-content'>
											{most_popular.slice(0,5).map((track, i) => (
												<Card data={track} type='desktop_card_album' bodyLink={'/track/' + track.spotify_id} key={i} />
											))}
										</div>
									</div>
								}
								{false && //artist_stats &&
									<div className='app-desktop-artist-body-section'>
										<div className='app-desktop-artist-body-section-title'>This artist has</div>
										<div className='app-desktop-artist-body-section-content'>
											<div className='app-desktop-artist-stats-card'>
												<img src={assets.desktop_artist_followers} alt='' />
												<div className='app-desktop-artist-stats-icon'>
													<span className='material-icons'>supervisor_account</span>
												</div>
												<div className='app-desktop-artist-stats-info'>
													<div className='app-desktop-artist-stats-card-subtitle'>Magroove Followers</div>
													<div className='app-desktop-artist-stats-card-title'>{artist_stats.total_followers}</div>
												</div>
											</div>
											<div className='app-desktop-artist-stats-card'>
												<img src={assets.desktop_artist_listeners} alt='' />
												<div className='app-desktop-artist-stats-icon'>
													<span className='material-icons'>graphic_eq</span>
												</div>
												<div className='app-desktop-artist-stats-info'>
													<div className='app-desktop-artist-stats-card-subtitle'>Monthly Listeners</div>
													<div className='app-desktop-artist-stats-card-title'>{artist_stats.monthly_listeners}</div>
												</div>
											</div>
											<div className='app-desktop-artist-stats-card'>
												<img src={assets.desktop_artist_likes} alt='' />
												<div className='app-desktop-artist-stats-icon'>
													<span className='material-icons'>favorite</span>
												</div>
												<div className='app-desktop-artist-stats-info'>
													<div className='app-desktop-artist-stats-card-subtitle'>Likes this month</div>
													<div className='app-desktop-artist-stats-card-title'>{artist_stats.likes_this_month}</div>
												</div>
											</div>
											{false && //
												<div className='app-desktop-artist-stats-card'>
													<img src={assets.desktop_artist_played} alt='' />
													<div className='app-desktop-artist-stats-icon' style={{'width': 24, 'height': 24}}>
														<img src={assets.musical_note_icon} alt='' />
													</div>
													<div className='app-desktop-artist-stats-info'>
														<div className='app-desktop-artist-stats-card-subtitle'>Minutes Played</div>
														<div className='app-desktop-artist-stats-card-title'>10.000</div>
													</div>
												</div>
											}
										</div>
									</div>
								}
								{display_artist_albums.length > 0 &&
									<div className='app-desktop-artist-body-section'>
										<div className='app-desktop-artist-body-section-options'>
											<div className='app-desktop-artist-body-section-title'>Latest albums</div>
											{this.state.artist_albums.length === 0 && display_artist_albums.length === 2 ?
												<div></div>
												:
												(this.state.artist_albums.length === 0 && display_artist_albums.length > 2 ?
													<div className='app-desktop-artist-body-section-button' onClick={this.hide_artist_albums}>View Less</div>
													:
													<div className='app-desktop-artist-body-section-button' onClick={this.load_artist_albums}>View more</div>
												)
											}
										</div>
										<div className='app-desktop-artist-body-section-column'>
											{display_artist_albums.map(item => item)}
										</div>
									</div>
								}
								{/*<div className='app-desktop-artist-body-section'>
									<div className='app-desktop-artist-body-section-title'>About</div>
									<div className='app-desktop-artist-body-section-content'>
										<div className='app-desktop-artist-about-description'>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
											<br /><br />
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
											<br /><br />
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
										</div>
										<div className='app-desktop-artist-about-image'></div>
									</div>
								</div>*/}
								<div className='app-desktop-artist-body-section'>
									<div className='app-desktop-artist-body-section-title'>You May Also Like</div>
									<div className='app-desktop-artist-body-section-content'>
										{similar_artists.slice(0,4).map((artist, i) => (
											<div className='app-desktop-artist-similar-card' key={i}>
												<a href={'/artist/' + artist.spotify_id}>
													<img className='app-desktop-artist-similar-card-image' src={artist.images.medium} alt={artist.name} />
													<div className='app-desktop-artist-similar-card-info'>
														<div className='app-desktop-artist-similar-card-title'>{artist.name}</div>
														{artist.plays &&
															<div className='app-desktop-artist-similar-card-subtitle'>{artist.plays + ' plays'}</div>
														}
													</div>
												</a>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<Dialog
						open={this.state.open_share_overlay}
						onClose={() => this.setState({open_share_overlay: false})}
						className='app-desktop-dialog'
					>
						<div className='app-desktop-dialog-container'>
							<div className='app-desktop-dialog-image'>
								<img src={artist_info.images.large} alt='album'/>
							</div>
							<div className='app-desktop-dialog-info'>
								<div className='app-desktop-dialog-info-title'>{artist_info.name}</div>
							</div>
							<div className='app-desktop-dialog-buttons-container'>
								<Ripples>
									<FacebookShareButton url={constants.api_endpoint_artist_meta.replace('{hash_id}', artist_info.spotify_id)}>
										<div style={{backgroundColor: '#3b5998'}} className='app-desktop-dialog-button'>
											<div className='app-desktop-dialog-button-icon'>
												<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-frontpage/assets/facebook_icon.png' alt='share-icon'/>
											</div>
											<div className='app-desktop-dialog-button-text'>SHARE ON FACEBOOK</div>
										</div>
									</FacebookShareButton>
								</Ripples>
								<Ripples>
									<TwitterShareButton url={constants.api_endpoint_artist_meta.replace('{hash_id}', artist_info.spotify_id)}>
										<div style={{backgroundColor: '#50aeea'}} className='app-desktop-dialog-button'>
											<div className='app-desktop-dialog-button-icon'>
												<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/twitter_share_icon.png' alt='share-icon'/>
											</div>
											<div className='app-desktop-dialog-button-text'>SHARE ON TWITTER</div>
										</div>
									</TwitterShareButton>
								</Ripples>
								<Ripples>
									<WhatsappShareButton url={constants.api_endpoint_artist_meta.replace('{hash_id}', artist_info.spotify_id)}>
										<div style={{backgroundColor: '#3fb64d'}} className='app-desktop-dialog-button'>
											<div className='app-desktop-dialog-button-icon'>
												<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/whatsapp_share_icon.png' alt='share-icon'/>
											</div>
											<div className='app-desktop-dialog-button-text'>SHARE ON WHATSAPP</div>
										</div>
									</WhatsappShareButton>
								</Ripples>
								<Ripples>
									<TelegramShareButton url={constants.api_endpoint_artist_meta.replace('{hash_id}', artist_info.spotify_id)}>
										<div style={{backgroundColor: '#20a0e1'}} className='app-desktop-dialog-button'>
											<div className='app-desktop-dialog-button-icon'>
												<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/telegram_share_icon.svg' alt='share-icon'/>
											</div>
											<div className='app-desktop-dialog-button-text'>SHARE ON TELEGRAM</div>
										</div>
									</TelegramShareButton>
								</Ripples>
								<Ripples>
									<VKShareButton url={constants.api_endpoint_artist_meta.replace('{hash_id}', artist_info.spotify_id)}>
										<div style={{backgroundColor: '#54769b'}} className='app-desktop-dialog-button'>
											<div className='app-desktop-dialog-button-icon'>
												<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/vk_share_icon.svg' alt='share-icon'/>
											</div>
											<div className='app-desktop-dialog-button-text'>SHARE ON VK</div>
										</div>
									</VKShareButton>
								</Ripples>
							</div>
						</div>
					</Dialog>
				</div>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		artist_info: state.GlobalReducer.artist_info,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopArtistPage);