class StorageController {
	clear(){
		localStorage.clear();
	}

	clear_expired(){
		Object.keys(localStorage).forEach(function(key){
			this.get(key); // This function will delete the key if outdated
		}.bind(this));
	}

	get(key){
		const res = localStorage.getItem(key);

		// if the item doesn't exist, return null
		if (!res) {
			return null;
		}

		try {
			const item = JSON.parse(res);
			if (!item.expiry){
				return item;
			}

			const now = new Date();
			// compare the expiry time of the item with the current time
			if (now.getTime() > item.expiry) {
				// If the item is expired, delete the item from storage
				// and return null
				localStorage.removeItem(key);
				return null;
			}

			return item.value;

		} catch(err){
			return res;
		}
	}

	set(key, value, ttl = false, retry = false) {
		try {
			if (!ttl){
				localStorage.setItem(key, JSON.stringify(value));
				return value;
			}

			const now = new Date();

			// `item` is an object which contains the original value
			// as well as the time when it's supposed to expire
			const item = {
				value: value,
				expiry: now.getTime() + ttl,
			};
			localStorage.setItem(key, JSON.stringify(item));
			return value;
		}
		catch (err){
			if (!retry){
				this.clear_expired();
				this.set(key, value, ttl, true);
			}
		}
	}

	remove(key){
		localStorage.removeItem(key);
	}
}

const storage = new StorageController();

export default storage;

