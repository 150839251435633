import React from 'react';
import PropTypes from 'prop-types';

// CSS Imports
import './styles/Milestone.css';

export default class Milestone extends React.Component {
	render() {
		return (
			<div className='milestone' style={{ backgroundImage: 'url(' + this.props.image + ')'}} onClick={this.props.onClick}>
				<div className='milestone-chip'>{this.props.label}</div>
				<div className='milestone-title'>{this.props.text}</div>
			</div>
		);
	}
}

Milestone.propTypes = {
	image: PropTypes.string,
	label: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func
};