import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import keyboard from '@/Keyboard';

// CSS Imports
import './styles/Onboarding.css';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Components and Views Imports
import Haptic from '@components/haptic.js';
import { redirect } from '@routes/Routes';
import Bubbles from '@components/Bubbles.js';

class Onboarding extends Component {
	constructor(props){
		super(props);
		this.state = {
			value: '',
			about_licenses: this.props.display_licenses_message,
			display_checkbox: false,
			checked: false,
		};

		this.search = this.search.bind(this);

		// Make sure the player is disabled during onboarding 
		store_set('playing_song', false);

		// Display 'I understand' checkbox only after 5 seconds
		setTimeout(function(){
			this.setState({display_checkbox: true});
		}.bind(this), 5000);

	}

	search(){
		if (this.state.value){
			redirect('/search?q=' + this.state.value);
		} else {
			store_set('snackbar_message', 'Please, enter a search term...');
			store_set('snackbar_status', true);
		}
	}

	render(){
		return(
			<div className="onboarding-main">
				{this.state.about_licenses &&
					<React.Fragment>
						<div className="onboarding-about-licenses-overlay" />
						<div className="onboarding-about-licenses">
							<div className="onboarding-about-licenses-title">
								<div style={{color: '#ec6b43'}}>!!! ATTENTION !!!</div>
								<div>About Licenses and copyrighted content</div>
							</div>
							<div className="onboarding-about-licenses-text">
								Magroove relies on the <span className="onboarding-about-licenses-bold-green">Spotify API</span> to serve audio files and metadata.
							</div>
							<div className="onboarding-about-licenses-text">
								All the content served within our app is made <span className="onboarding-about-licenses-bold-orange">publicly available by Spotify</span> for 
								“Limited commercial use” - and that’s the scope where Magroove lands.
							</div>
							<div className="onboarding-about-licenses-text">
								We operate under the Spotify Developer Terms and Developer Policy. Both 
								documents are available <a className="onboarding-about-licenses-bold-orange" href="https://developer.spotify.com/terms/" target="_blank" rel="noopener noreferrer">here</a> and
								<a className="onboarding-about-licenses-bold-orange" href="https://developer.spotify.com/policy/" target="_blank" rel="noopener noreferrer"> here</a>.
							</div>
							{this.state.display_checkbox &&
								<div className="onboarding-about-licenses-checkbox-container" onClick={()=>this.setState({checked: !this.state.checked})}>
									<div className="onboarding-about-licenses-checkbox" style={{backgroundColor: this.state.checked ? '#ec6b43' : 'transparent'}}>
										{this.state.checked &&
											<span className="material-icons">check</span>
										}
									</div>
									<div className="onboarding-about-licenses-checkbox-text">I understand</div>
								</div>
							}
						</div>
						<div className="onboarding-about-licenses-footer">
							{this.state.checked &&
								<Haptic>
									<div className="onboarding-footer-next-button" onClick={() => this.setState({about_licenses: false})}>
										<div className="onboarding-footer-next-text">Next</div>
										<span className="material-icons">keyboard_backspace</span>
									</div>
								</Haptic>
							}
						</div>
					</React.Fragment>
				}
				{!this.state.about_licenses &&
					<React.Fragment>
						<Bubbles animation={true} lines={3} />
						<div className="onboarding-step">
							<div className="onboarding-text">Discover and share music</div>
							<div className="onboarding-description">With Magroove you can search for a <b className="onboarding-description-focus">track, album, or artist</b> and we will show you music that <b className="onboarding-description-focus">sounds like it</b>!</div>
							<Haptic>
								<div className="onboarding-textfield">
									<input 
										value={this.state.value} 
										type="text" 
										onChange={(e) => this.setState({value: e.target.value})} 
										onKeyUp={(event) => event.keyCode === 13 ? this.search() : null}
										onClick={() => keyboard.opened()}
										onBlur={() => keyboard.hidden()}
										placeholder="Give us a song, album or artist..."
									/>
									<span className="material-icons">search</span>
								</div>
							</Haptic>
						</div>
						<Bubbles animation={true} lines={3} />
					</React.Fragment>
				}
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		display_licenses_message:  state.GlobalReducer.display_licenses_message,
		safe_area_top: state.GlobalReducer.safe_area_top
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Onboarding));