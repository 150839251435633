import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';

// Components and Views Imports
import Haptic from '@components/haptic.js';
import Bubbles from '@components/Bubbles.js';
import RotateSentences from '@components/RotateSentences.js';
import Ripples from 'react-ripples';
import { Button, LinearProgress } from '@magroove/magroove-ui';
import { Drawer } from '@material-ui/core';

// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Assets Imports
import * as assets from '@Assets';

// CSS Imports
import './styles/VersionOverlay.css';

// Capgo
import { CapacitorUpdater } from '@capgo/capacitor-updater';

import remote_exporter from '@/RemoteExporter.js';

class VersionOverlay extends Component {
	constructor(props) {
		super(props);
		// To be removed afterwards ================
		this.state = {
			developer_count: 0
		};
		this.countdown = false;
		this.handle_click = this.handle_click.bind(this);
		// ========================================

		this.text_index = 0;
		this.timeout = false;

		this.accept_update = this.accept_update.bind(this);
		this.add_listeners = this.add_listeners.bind(this);
		this.close_reload_invite = this.close_reload_invite.bind(this);
		this.close_update_invite = this.close_update_invite.bind(this);
		this.update_app = this.update_app.bind(this);
		this.open_app_store = this.open_app_store.bind(this);
		this.on_new_version_error = this.on_new_version_error.bind(this);
		this.on_new_version_success = this.on_new_version_success.bind(this);

		this.add_listeners();

		if (this.props.version_overlay === 'background') {
			this.update_app('background');
		} else if (this.props.version_overlay === 'force') {
			this.update_app('normal');
		}
	}

	handle_click() {
		// Tapping five times in a row in the profile picture should toggle the developer mode (enable / disable)
		if (this.countdown) {
			clearTimeout(this.countdown);
		}

		var count = this.state.developer_count + 1;
		var developer_mode = store_get('developer_mode');
		if (count === 5) {
			store_set('developer_mode', !developer_mode);
			this.setState({ developer_count: 0 });
			this.countdown = false;

			if (developer_mode) {
				window.getObject = false;
				window.location.reload(false);
			}
			else {
				// Inject a remote js tag (https://remotejs.com/)
				let remotejs = document.createElement('script');
				remotejs.src = 'https://remotejs.com/agent/agent.js';
				remotejs.setAttribute('data-consolejs-channel', 'magroove-app-debugger');
				document.head.appendChild(remotejs);

				// Function to access objects on RemoteJs
				window.getObject = (value) => remote_exporter(value);
			}
		}
		else {
			this.setState({ developer_count: count });
			this.countdown = setTimeout(function () {
				this.setState({ developer_count: 0 });
				this.countdown = false;
			}.bind(this), 1000);
		}
	}

	animate_text() {
		const text_interval = 600;
		const component = document.getElementsByClassName('version-overlay-update-required-progress-text');
		if (!component) {
			this.timeout = setTimeout(this.animate_text, 1000);
		}

		const texts = component[0].children;
		for (var item of texts) {
			item.classList.remove('text-in', 'text-out');
		}
		texts[this.text_index].classList.add('text-in');

		this.timeout = setTimeout(function () {
			texts[this.text_index].classList.add('text-out');
			if (this.text_index === texts.length - 1) {
				this.text_index = 0;
			} else {
				this.text_index += 1;
			}
			this.animate_text();
		}.bind(this), text_interval);
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.version_overlay === nextProps.version_overlay) return true;
		if (nextProps.version_overlay === 'background') {
			this.update_app('background');
		}
		else if (nextProps.version_overlay === 'force') {
			this.update_app('normal');
		}
		return true;
	}

	accept_update() {
		if (['force_base', 'suggest_base'].includes(this.props.version_overlay)) {
			this.open_app_store();
		} else if (this.props.version_overlay === 'suggest') {
			this.update_app('background');
		} else {
			this.update_app('normal');
		}
	}

	add_listeners() {
		CapacitorUpdater.addListener('download', async (state) => {
			// Prevent the percentage bar displaying negative number
			if (state.percent > store_get('download_progress')) {
				store_set('download_progress', state.percent);
			}
		});
	}

	close_reload_invite() {
		store_set('ready_to_reload', false);
	}

	close_update_invite() {
		store_set('invite_to_update', false);
		store_set('version_overlay', false);
	}

	async update_app(mode = 'normal') {
		const version = await this.get_next_available_version();

		// In a worst case scenario the shipped version with the
		// app's own binary should be rendered.
		if (version === 'auto_rollback' || version === null) {
			store_set('downloading_new_version', false);
			store_set('version_overlay', false);
			return;
		}

		store_set('downloading_new_version', true);
		store_set('download_progress', 0);
		try {
			const new_version = await CapacitorUpdater.download(version);
			if (this.timeout) clearTimeout(this.timeout);
			try {
				if (mode === 'background') {
					await CapacitorUpdater.next(new_version);
				}
				else {
					await CapacitorUpdater.set(new_version);
				}
				await this.on_new_version_success(version, mode);
			}
			catch (err) {
				await this.on_new_version_error(version, mode);
			}
		}
		catch (err) {
			store_set('downloading_new_version', false);
			await this.on_new_version_error(version, mode);
		}
	}

	async get_version_info() {
		const response = await Preferences.get({ key: 'version_info' });
		return JSON.parse(response.value);
	}

	async get_next_available_version() {
		const { success, error, pending } = await this.get_version_info();
		const version = store_get('version');

		const options = version.app_version;
		const item = options.shift();

		if (!item) {
			return null;
		}
		if (pending.includes(item.version)) {
			return item;
		}
		if (success.includes(item.version)) {
			return 'auto_rollback';
		}
		if (error.includes(item.version)) {
			store_set('version', version);
			return await this.get_next_available_version();
		}
		return null;
	}

	async update_version_info(status, code) {
		const data = await this.get_version_info();
		if (status === 'success') {
			data.success.unshift(code);
		}
		else if (status === 'error') {
			data.error.unshift(code);
		}
		data.pending.splice(data.pending.findIndex(item => item === code), 1);
		await Preferences.set({ key: 'version_info', value: JSON.stringify(data) });
	}

	open_app_store() {
		if (Capacitor.getPlatform() === 'ios') {
			window.open(this.props.version.ios);
		} else {
			window.open(this.props.version.android);
		}
	}

	async on_new_version_error(item, mode) {
		await this.update_version_info('error', item.version);

		// Update failures should be handled gracefully: an upgrade
		// attempt should automatically trigger a new upgrade to the
		// latest, non-problematic version in the app_version array.
		this.update_app(mode);
	}

	async on_new_version_success(item, mode) {
		await this.update_version_info('success', item.version);
		store_set('downloading_new_version', false);
		store_set('version_overlay', false);

		if (mode === 'background' && store_get('user_token')) {
			store_set('ready_to_reload', true);
		}
	}

	render() {
		const { ready_to_reload, invite_to_update, downloading, download_progress } = this.props;
		const force_overlay = ['force', 'force_base'].includes(this.props.version_overlay);

		if (force_overlay) {
			return (
				<div className='version-overlay-update-required'>
					<div className='version-overlay-update-required-header' onClick={this.handle_click}>
						<img src={assets.magroove_logo} alt='magroove-logo' />
					</div>
					<div className='version-overlay-update-required-main'>
						<Bubbles lines={3} />
						{downloading ?
							<div className='version-overlay-update-required-content'>
								<div className='version-overlay-update-required-title'>Update in Progress</div>
								<div className='version-overlay-update-required-progress'>
									<div className='version-overlay-update-required-progress-info'>
										<div className='version-overlay-update-required-progress-subtitle'>
											<RotateSentences
												interval={3000}
												options={[
													'Making your grooves even groovier...',
													'Implementing cool features...',
													'Adding some spice to your playlists...',
													'Updating your jam sesh, hold on...',
													'Getting a makeover...',
													'Updates, updates, we\'re almost there...',
													'Cooking up something fresh, just for you...'
												]}
											/>
										</div>
										<div className='version-overlay-update-required-progress-number'>{download_progress}%</div>
									</div>
									<LinearProgress value={download_progress} />
								</div>
							</div>
							:
							<div className='version-overlay-update-required-content'>
								<div className='version-overlay-update-required-title'>New Version Available</div>
								<div className='version-overlay-update-required-text'>
									<span>A new version of Magroove is available!</span><br></br>
									In order to continue using our app and take advantage of the latest features, an update is required.
								</div>
								<Ripples>
									<Haptic intesity='light'>
										<Button value='UPDATE NOW' size='small' fullWidth={true} onClick={() => this.accept_update()} />
									</Haptic>
								</Ripples>
							</div>
						}
						<Bubbles lines={3} />
						<div className='version-overlay-update-required-shadow'></div>
					</div>
				</div>
			);
		}
		return (
			<Drawer anchor='bottom' open={invite_to_update || ready_to_reload}>
				{invite_to_update &&
					<div className='version-overlay-update-invite-confirm-overlay'>
						<div className='version-overlay-update-invite-close material-icons' onClick={this.close_update_invite}>close</div>
						<div className='version-overlay-update-invite-confirm-overlay-title'>New Version Available!</div>
						<div className='version-overlay-update-invite-confirm-overlay-text'>
							These updates will enhance your experience and bring new features.
							{this.props.version_overlay === 'suggest' &&
								<React.Fragment>
									<br></br><br></br>
									<strong>Plus, if you choose to update now, the process will run in the background and won’t interrupt your music experience.</strong>
								</React.Fragment>
							}
						</div>
						<div className='version-overlay-update-invite-confirm-overlay-buttons'>
							<Button fullWidth={true} size='small' label='NOT NOW' variant='text' onClick={this.close_update_invite} />
							<Button fullWidth={true} size='small' label='UPDATE' onClick={() => { this.accept_update(); this.close_update_invite(); }} />
						</div>
					</div>
				}
				{ready_to_reload &&
					<div className='version-overlay-update-invite-confirm-overlay'>
						<div className='version-overlay-update-invite-close material-icons' onClick={this.close_reload_invite}>close</div>
						<div className='version-overlay-update-invite-confirm-overlay-title'>Update Completed!</div>
						<div className='version-overlay-update-invite-confirm-overlay-text'>
							In order to fully experience the new features, please restart the app.
						</div>
						<div className='version-overlay-update-invite-confirm-overlay-buttons'>
							<Button fullWidth={true} size='small' label='NOT NOW' variant='text' onClick={this.close_reload_invite} />
							{Capacitor.getPlatform() === 'android' ?
								<Button fullWidth={true} size='small' label='RESTART' onClick={() => { this.close_reload_invite(); App.exitApp(); }} />
								: /*  Apple do not approve closing apps programmatically, so just close the overlay */
								<Button fullWidth={true} size='small' label='OK' onClick={this.close_reload_invite} />
							}
						</div>
					</div>
				}
			</Drawer>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		version: state.GlobalReducer.version,
		version_overlay: state.GlobalReducer.version_overlay,
		downloading: state.GlobalReducer.downloading_new_version,
		download_progress: state.GlobalReducer.download_progress,
		ready_to_reload: state.GlobalReducer.ready_to_reload,
		invite_to_update: state.GlobalReducer.invite_to_update
	};
}

// Connect component to the store
export default connect(mapStateToProps)(VersionOverlay);