import React from 'react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';

class Meta extends React.Component {
	render() {
		if (this.props.location === 'track'){
			var song_name = this.props.song_info.title;
			var song_url = this.props.song_info.preview_url;
			var artist_name = this.props.song_info.artist ? this.props.song_info.artist.name : '';
			var album_name = '';
			var album_upc = '';
			var album_cover = '';
			if (this.props.song_info.album){
				album_name = this.props.song_info.album.name;
				album_upc = this.props.song_info.album.upc;
				album_cover = this.props.song_info.album.images && this.props.song_info.album.images.small;
			}
			return (
				<MetaTags>
					<title>Magroove - Music Discovery App - Find your next hit!</title>
					<meta name="description"             content="Find your next favorite tune with a unique recommendation! Get to know Jenny, our AI who recommends music with the personal user's taste in the first place!" />
					<meta property="og:title"            content={song_name + ' - ' + artist_name} />
					<meta property="og:description"      content="Let's find new songs!" />
					<meta property="og:image"            content={album_cover} />

					<meta property="og:audio"            content={song_url} />
					<meta property="og:audio:title"      content={song_name} />
					<meta property="og:audio:artist"     content={artist_name} />
					<meta property="og:audio:album"      content={album_name} />
					<meta property="og:audio:type"       content="application/mp3" />
					<meta property="og:upc"              content={album_upc} />

					<meta name="twitter:title"           content={song_name + ' - ' + artist_name} />
					<meta name="twitter:description"     content="Let's find new songs!" />
					<meta name="twitter:image"           content={album_cover} />
				</MetaTags>
			);
		} else {
			return (
				<MetaTags>
					<title>Magroove - Music Discovery App - Find your next hit!</title>
					<meta name="description"             content="Find your next favorite tune with a unique recommendation! Get to know Jenny, our AI who recommends music with the personal user's taste in the first place!" />
					<meta property="og:title"            content="Magroove" />
					<meta property="og:description"      content="Let's find new songs!" />
					<meta property="og:image"            content="https://magroove-dev.s3.amazonaws.com/magroove-app/assets/og-image.jpg" />
					<meta name="twitter:title"           content="Magroove" />
					<meta name="twitter:description"     content="Let's find new songs!" />
					<meta name="twitter:image"           content="https://magroove-dev.s3.amazonaws.com/magroove-app/assets/og-image.jpg" />
				</MetaTags>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		song_info: state.GlobalReducer.song_info
	};
}

export default connect(mapStateToProps)(Meta);
