import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import {Img} from 'react-image';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import CircularProgress from '@material-ui/core/CircularProgress';

// CSS Imports
import './styles/Artist.css';

// Assets Imports
import { default_artist_image } from '@Assets';

// Components and Views Imports
import AdUnit from '@components/AdUnit.js';
import Card from '@components/Card.js';
import Haptic from '@components/haptic.js';
import Ripples from 'react-ripples';

// Actions import
import { store_set } from '@actions/GlobalActions.js';
import { redirect } from '@routes/Routes.js';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Constant Imports
import * as constants from '@constants';

// Import the event bus
import event_bus from '@Eventbus';

// Import the controllers
import queue from '@Queue';

const artist_cover_size = window.screen.width;

class Artist extends Component {
	constructor(props){
		super(props);
		this.state = {
			artist_hash_id: null,
			most_popular: [],
			most_popular_full_list: [],
			most_popular_index: 5,
			loading_top_tracks: false,
			artist_stats: false,
			artist_albums: [],
			artist_playlists: [],
			is_following_artist: 'loading',
			allow_text_animation: false,
		};

		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);

		var path = window.location.href;
		var artist_hash_id = null;

		if (path.includes('artist')) {
			artist_hash_id = this.props.url.params.artist_hash_id;
			this.state.artist_hash_id = artist_hash_id;
		}

		this.get_artist_info = this.get_artist_info.bind(this);
		this.get_top_tracks = this.get_top_tracks.bind(this);
		this.get_artist_stats = this.get_artist_stats.bind(this);
		this.get_artist_albums = this.get_artist_albums.bind(this);
		this.get_artist_playlists = this.get_artist_playlists.bind(this);
		this.follow_artist_management = this.follow_artist_management.bind(this);

		// Make sure we get the correct artist, 
		// If spotify_id saved in redux store is different from the current path user accessed in page, we go after the artist info again
		if (this.props.artist_info === undefined || this.props.artist_info.spotify_id !== artist_hash_id){
			this.get_artist_info(artist_hash_id);
		} else {
			store_set('loading_overlay_display', false);
		}

		this.get_top_tracks();
		// this.get_artist_stats();
		this.get_artist_albums();
		this.get_artist_playlists();
		this.follow_artist_management('check_status');

		// This event is called when user click to follow/unfollow an artist inside overlay
		event_bus.on('call-follow-function', (data) => {this.follow_artist_management(data); });
	}

	get_artist_info(hash_id){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/artist/',
			data: {hash_id: hash_id},
			success: function(data){
				if (data.status === 'success') {
					store_set('artist_info', data);
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			},
		});
	}

	get_top_tracks(){
		/* eslint-disable-next-line */
		this.state.loading_top_tracks = true;
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_top_tracks,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					// Show only the first 5 tracks
					// If user wants to see more, he can click at 'View more' button
					this.setState({most_popular: data.data.slice(0, this.state.most_popular_index)});
					this.setState({most_popular_full_list: data.data});
				}
			}.bind(this),
			complete: function(){
				this.setState({loading_top_tracks: false});
			}.bind(this)
		});
	}

	get_artist_stats(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_stats,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({artist_stats: data.data});
				}
			}.bind(this)
		});
	}

	get_artist_albums(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_albums,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({artist_albums: data.data});
				}
			}.bind(this)
		});
	}

	get_artist_playlists(){
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_playlists,
			data: {artist_hash_id: this.state.artist_hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({artist_playlists: data.data});
				}
			}.bind(this)
		});
	}

	follow_artist_management(action){
		this.setState({is_following_artist: `loading_to_${action}`, allow_text_animation: true});

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_follow_artist_management,
			data: {
				artist_hash_id: this.state.artist_hash_id,
				action: action,
			},
			success: function(data){
				let is_following = false;
				if (data.status === 'success') {
					is_following = data.data;
				}
				this.setState({is_following_artist: is_following});

				// This event bus exists to update the follow button status inside the overlay
				event_bus.emit('is-following-artist', null, this.state.artist_hash_id, is_following);
			}.bind(this),
			error: function(){
				this.setState({is_following_artist: false});
			}.bind(this)
		});
	}

	componentDidMount() {
		const scrollable_element = document.querySelector('.main-content-inner');
		scrollable_element.onscroll = this.handle_scroll; // For Chrome
		scrollable_element.onwheel = this.handle_scroll; // For Firefox
		scrollable_element.ontouchmove = this.handle_scroll; // For mobile
		scrollable_element.onmousewheel = this.handle_scroll; // For IE
	}

	handle_scroll(){
		// Make sure we set a background color in header (so he can easily see the content inside)
		// but just when user scrolls the page down
		if (!window.location.pathname.includes('artist')) {
			return;
		}

		const scrollable_element = document.querySelector('.main-content-inner > div');
		const artist_header_container = document.getElementById('artist-header-container');
		if (scrollable_element.scrollTop !== 0){
			artist_header_container.classList.add('is-artist-header-fixed');
		}
		else {
			artist_header_container.classList.remove('is-artist-header-fixed');
		}
	}

	find_similar_button(seed){
		store_set('seed', seed);
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_top_text', 'Now searching similar to:');
		store_set('loading_overlay_text', seed.name);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		queue.get_recommendations(seed.spotify_id, seed.type, '', true);
		store_set('active_overlay', false);
	}

	render() {
		// Make sure we have a default artist info in case it is undefined
		const default_artist = {'type': 'artist', 'images': {}, 'name': '', 'spotify_id': ''};
		const artist_info = this.props.artist_info ? this.props.artist_info : default_artist;
		const is_following_artist = this.state.is_following_artist;
		const allow_text_animation = this.state.allow_text_animation;
		const texts_button_classNames = allow_text_animation ? {
			button: 'artist-options-button-details-white', icon: 'material-icons white'
		} : {
			button: 'artist-options-button-details', icon: 'material-icons'
		};

		return(
			<div className='artist-container' id='artist-container'>
				<div className='artist-main'>
					<Img
						src={[artist_info.images.large, default_artist_image]}
						loader={
							<ContentLoader 
								width={artist_cover_size}
								height={250}
								backgroundColor='#D8D8D8'
								foregroundColor='#b1afaf'
							>
								<rect x='0' y='0' rx='0' ry='0' width={artist_cover_size} height={250} /> 
							</ContentLoader>
						}
						className='artist-main-image'
					/>
					<div className='artist-content' id='artist-content'>
						<div className='artist-info'>
							<div className='artist-title'>{artist_info.name}</div>
						</div>
						<div className='artist-options'>
							<div className='artist-options-double-button'>
								{is_following_artist === 'loading' ? 
									<CircularProgress className='artist-loading-options-button' size={20} />
									:
									<React.Fragment>
										{`${is_following_artist}`.includes('loading') &&
											<div className='artist-options-button'>
												{is_following_artist === 'loading_to_follow' && 
													<React.Fragment>
														<div className='material-icons orange'>done</div>
														<div className='artist-options-button-details-orange'>Following</div>
													</React.Fragment>
												}
												{is_following_artist !== 'loading_to_follow' && 
													<CircularProgress />
												}
											</div>
										}
										{!`${is_following_artist}`.includes('loading') &&
											<Ripples>
												<Haptic intesity='light'>
													{is_following_artist &&
														<div className='artist-options-button' onClick={() => this.follow_artist_management('unfollow')}>
															<div className={texts_button_classNames.icon}>done</div>
															<div className={texts_button_classNames.button}>Following</div>
														</div>
													}
													{!is_following_artist &&
														<div className='artist-options-button' onClick={() => this.follow_artist_management('follow')}>
															<div className='material-icons'>person_add</div>
															<div className='artist-options-button-details'>Follow</div>
														</div>
													}
												</Haptic>
											</Ripples>
										}
										<span className='artist-options-middle-line' />
										<Ripples>
											<Haptic intesity='light'>
												<div className='artist-options-button' onClick={() => this.find_similar_button(this.props.artist_info)}>
													<div className='artist-options-button-details-right'>
														Find Similar
													</div>
												</div>
											</Haptic>
										</Ripples>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
				<div className='artist-songs-container'>
					<div className='artist-songs-title'>
						<div className='artist-songs-title-details'>Most Popular</div>
						{((!this.state.loading_top_tracks) && (this.state.most_popular.length < this.state.most_popular_full_list.length)) &&
							<div 
								className='artist-songs-title-button'
								onClick={() => {
									let most_popular_index = this.state.most_popular_index + 5;
									this.setState({most_popular_index: most_popular_index});
									this.setState({most_popular: this.state.most_popular_full_list.slice(0, most_popular_index)});
								}}
							>
								View More
							</div>
						}
					</div>
					{this.state.loading_top_tracks &&
						[...Array(3).keys()].map((_, index) => {
							return(
								<div key={index} className='artist-songs-loading-card'>
									<ContentLoader 
										width='100%'
										height={54}
										backgroundColor='#D8D8D8'
										foregroundColor='#b1afaf'
									>
										<rect x='0' y='0' rx='4' ry='4' width={'100%'} height={54} /> 
									</ContentLoader>
								</div>
							);
						})
					}
					{!this.state.loading_top_tracks &&
						<React.Fragment>
							{this.state.most_popular.map((song, index) => {
								return (
									<React.Fragment key={index}>
										{index === 2 ? <AdUnit height={60} /> : null}
										<Card
											data={song}
											type={'small_card_song'}
											is_new_like={false}
											onMoreClick={() => {
												store_set('active_overlay', 'track_options');
												store_set('song_info', song);
											}}
										/>
									</React.Fragment>
								);
							})}
						</React.Fragment>
					}
				</div>
				{/* 
					THE SECTION 'STATS' BELOW WILL BE ENABLED LATER
					It won't be open yet because in the beginning all stats will probably be small or empty, 
					so we'll leave commented for now, but feature is already implemented, we can just uncomment 
				*/}
				{/* {this.state.artist_stats &&
					<div className='artist-stats'>
						<div className='artist-songs-title'>
							<div className='artist-songs-title-details'>This Artist has</div>
						</div>
						<div className='artist-stats-cards'>
							<div className='artist-stats-card'>
								<div className='material-icons'>supervisor_account</div>
								<div className='artist-stats-card-info'>
									<div className='artist-stats-card-info-number'>{this.state.artist_stats.total_followers}</div>
									<div className='artist-stats-card-info-title'>Magroove Followers</div>
								</div>
							</div>
							<div className='artist-stats-card'>
								<div className='material-icons'>graphic_eq</div>
								<div className='artist-stats-card-info'>
									<div className='artist-stats-card-info-number'>{this.state.artist_stats.monthly_listeners}</div>
									<div className='artist-stats-card-info-title'>Monthly Listeners</div>
								</div>
							</div>
							<div className='artist-stats-card'>
								<div className='material-icons'>favorite</div>
								<div className='artist-stats-card-info'>
									<div className='artist-stats-card-info-number'>{this.state.artist_stats.likes_this_month}</div>
									<div className='artist-stats-card-info-title'>Likes this month</div>
								</div>
							</div>
						</div>
					</div>
				} */}
				{this.state.artist_albums.length > 0 &&
					<div className='artist-recommended-section'>
						<div className='artist-songs-title'>
							<div className='artist-songs-title-details'>Latest Albums</div>
						</div>
						<div className='artist-recommended-container'>
							{this.state.artist_albums.map((album, index) => {
								return (
									<Link key={index} to={'/album/' + album.spotify_id}>
										<Card data={album} type='big_card_album' displayLabel={true} key={index} />
									</Link>
								);
							})}
						</div>
					</div>
				}
				{this.state.artist_playlists.length > 0 &&
					<div className='artist-recommended-section'>
						<div className='artist-songs-title'>
							<div className='artist-songs-title-details'>Appears in</div>
						</div>
						<div className='artist-recommended-container'>
							{this.state.artist_playlists.map((playlist, index) => {
								return (
									<Card
										key={index}
										data={playlist}
										type='big_playlist_card'
										onClick={() => {store_set('playlist_info', playlist); redirect('/playlist/' + playlist.hash_id);}}
										onMoreClick={() => {store_set('active_overlay', 'playlists_options'); store_set('playlist_overlay_option', 'show_all'); store_set('playlist_info', playlist);}}
									/>
								);
							})}
						</div>
					</div>
				}
				{/* THE SECTION 'ABOUT' BELOW WILL BE DEVELOPED LATER */}
				{/* <div className='artist-about'>
					<div className='artist-songs-title'>
						<div className='artist-songs-title-details'>About</div>
					</div>
					<div className='artist-about-cards'>
						<div className='artist-about-card' style={{backgroundImage: 'url('' + 'https://fastly.picsum.photos/id/403/650/650.jpg?hmac=Oe7kb5uDoKE-a5ce-1Dmpa2z7_AmHZJUO-UBorxjVxE' + '')'}}></div>
						<div className='artist-about-card' style={{backgroundImage: 'url('' + 'https://fastly.picsum.photos/id/403/650/650.jpg?hmac=Oe7kb5uDoKE-a5ce-1Dmpa2z7_AmHZJUO-UBorxjVxE' + '')'}}></div>
						<div className='artist-about-card' style={{backgroundImage: 'url('' + 'https://fastly.picsum.photos/id/403/650/650.jpg?hmac=Oe7kb5uDoKE-a5ce-1Dmpa2z7_AmHZJUO-UBorxjVxE' + '')'}}></div>
					</div>
					<div className='artist-about-description'>
						Necry Talkie (Japanese: ネクライトーキー, Hepburn: Nekurai Tōkī) (stylized in all caps when romanised) is a Japanese rock band currently signed to Sony Music Entertainment Japan. It was formed in 2017, when Ren Asahi invited three other members...
					</div>
					<div className='artist-about-button'>
						<div className='artist-about-button-title'>Read more</div>
						<div className='material-icons'>expand_more</div>
					</div>
				</div> */}
			</div> 
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		artist_info: state.GlobalReducer.artist_info,
		user: state.GlobalReducer.user,
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(Artist));
