import React from 'react';
import { Textfield } from '@magroove/magroove-ui';
import * as constants from '../constants.js';
import { request_buffer } from '@RequestBuffer';
import { ClickAwayListener } from '@material-ui/core';
import { RateApp } from 'capacitor-rate-app';
import { store_get } from '@/actions/GlobalActions.js';
import player from '@Player';

// Import Capacitor
import { Preferences } from '@capacitor/preferences';

// CSS Imports
import './styles/Review.css';

// Import the event bus
import event_bus from '@Eventbus';

export class Review extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			show: false,
			rate: 0, 
			confirm_review: false,
			review_text: '',
			review_type: '',
		};
		this.save_review = this.save_review.bind(this);
		this.next_step = this.next_step.bind(this);
		this.get_preferences_by_key  = this.get_preferences_by_key.bind(this);
		this.get_preferences_by_value = this.get_preferences_by_value.bind(this);
		this.remove_preferences_key = this.remove_preferences_key.bind(this);
		this.redirect_review = this.redirect_review.bind(this);
		this.should_review_display = this.should_review_display.bind(this);
		this.show_review = this.show_review.bind(this);
		this.close_review = this.close_review.bind(this);
		event_bus.on('review-app', this.redirect_review); 
	}	

	async get_preferences_by_key(key){
		let result = await Preferences.get({ key: key });
		return result.value;
	}

	async get_preferences_by_value(key, trigger, review_type){
		this.get_preferences_by_key(key).then((value) => {
			// If result is null, set it to 0
			if (value === null){
				value = 0;
			} 
			value = parseInt(value) + 1;
			Preferences.set({ key: key, value: value.toString()}); 
			
			// If value is equal to trigger, remove key and show review
			if (value === trigger){
				this.show_review(review_type);
				this.remove_preferences_key(key);
			} else{
				this.close_review();
			}
		});
		return;
	}

	async remove_preferences_key(key){
		await Preferences.remove({ key: key });
		return;
	}

	redirect_review(review_type){
		// Return if review should appear
		this.get_preferences_by_key(review_type).then(last_review => {
			let display = this.should_review_display(last_review, review_type);
			
			if (display){
				// If type is sucessful searches, just display review on fifth search
				if (review_type === 'sucessful_searches'){
					this.get_preferences_by_value('number_sucessful_searches', 5, review_type, last_review);
				} else if (review_type ==='likes_after_subscription'){
					// If type is likes after subscription, display on third like
					this.get_preferences_by_value('number_likes_after_subscription', 3, review_type, last_review);
				} else {
					this.show_review(review_type);
				}
			} else {
				this.close_review();
			}
		});  
		return;
	}

	should_review_display(last_review, review_type){
		const show_once = ['first_likes', 'search_sucessful'];
		let six_months_ago = new Date(new Date().setMonth(new Date().getMonth() - 6));

		// Display review if last review is null or if it is not a show once review and it has been more than six months since last review 
		let response = last_review === null || (!show_once.includes(review_type) && six_months_ago >  new Date(last_review));
		return response;
	}

	show_review(review_type){
		var is_playing = store_get('is_playing');
		
		if (review_type === 'search_sucessful'){
			setTimeout(() => this.setState({show: true, review_type: review_type}), 10000);
		} else {
			this.setState({show: true, review_type: review_type});
		} 

		if (is_playing){
			player.pause();
		}
		Preferences.set({ key: review_type, value: new Date().toDateString()});
		return;
	}

	next_step(){
		if (this.state.rate === 0){
			this.setState({show_error: true});
		} else if (this.state.rate < 4){
			this.setState({confirm_review: true});
		} else {
			this.save_review();
			this.close_review();
			RateApp.requestReview();
		} 
		return;
	}

	save_review(){
		this.setState({show: false});

		// Save review in Tags
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_save_review,
			data: {
				rate: this.state.rate,
				trigger: this.state.review_type,
				review_text: this.state.review_text,
			},
			success: function(){},
			error: function(){},
			complete: function(){}
		});
		return;
	}

	close_review(){
		this.setState({show: false, review_type: '', review_text: '', rate: 0, confirm_review: false});
		return;
	}

	render(){
		if (this.state.show){
			return(
				<div className='review'>
					<ClickAwayListener onClickAway={() => this.setState({show: false})}>
						<div className='review-container'>
							<div className='review-container-border-top'></div>
							<div className='review-container-content'>
								{!this.state.confirm_review ?
									<div>
										<div className='review-text'>
											<p>How would you rate your Magroove experience?</p>
											<div className='review-text-description'>
												<p>Your feedback matters.</p>
												<p>Help us make Magroove better!</p>
											</div>
										</div>
										<div className='review-stars-container'>
											{[...Array(this.state.rate).keys()].map((i, index) => {
												return(
													<span className='material-icons-outlined review-star' key={index} onClick={() => this.setState({rate: i+1, show_error: false})}>
														star
													</span>
												);})
											}
											{[...Array(5 - this.state.rate).keys()].map((i, index) => {
												return(
													<span className='material-icons-outlined review-star' key={index} onClick={() => this.setState({rate: this.state.rate + i + 1, show_error: false})}>
														star_outline
													</span>
												);})
											}
										</div>
										<div className='review-error'>
											<p> {this.state.show_error ? 'Please select a rating' : ''} </p>
										</div>
										<div className='review-buttons'>
											<div className='review-button-not-now' onClick={() => this.close_review()}>NOT NOW</div>
											<div className='review-button-confirm' onClick={()=> this.next_step()}>CONFIRM</div>
										</div>
									</div>
									:
									<div>
										<div>
											<div className='review-rate-confirm'>
												<div className='review-stars-container-small'>  
													{[...Array(this.state.rate).keys()].map((i, index) => {
														return(
															<span className='material-icons-outlined review-star-small' key={index} onClick={() => this.setState({rate: i+1})}>
																star
															</span>
														);})
													}
													{[...Array(5 - this.state.rate).keys()].map((i, index) => {
														return(
															<span className='material-icons-outlined review-star-small' key={index} onClick={() => this.setState({rate: this.state.rate + i + 1})}>
																star_outline
															</span>
														);})
													}
												</div>
												<p>Thank you for your feedback!</p>
												<div className='review-rate-confirm-input-text-container'>
													<p>Anything we could do better?</p>
													<Textfield
														theme='dark'
														size='small'
														fullWidth={true}
														multiline={true}
														rows={5}
														className='review-rate-confirm-input-text'
														value={this.state.review_text}
														placeholder='Every bit of Feedback helps!'
														onChange={(value) => this.setState({review_text: value})}
													/>
												</div>
											</div>
											<div className='review-buttons'>
												<div className='review-button-not-now' onClick={() => this.close_review()}>NOT NOW</div>
												<div className='review-button-confirm' onClick={()=> this.save_review()}>SEND</div>
											</div>
										</div>
									</div>
									
								}
							</div>
						</div>
					</ClickAwayListener>
				</div>
			);
		} else {
			return null;
		}
	}
}