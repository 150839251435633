// Objects import
import version from '@Version';
import * as constants from '@constants';
import { request_buffer } from '@RequestBuffer';

// Actions import
import { get_api_version, get_base_version } from '@actions/GlobalActions.js';

// Subscribes user to A/B test
export default async function subscribe_ab_test(hash_id, value, success_function = false){
	if (!hash_id || !value) return;

	const api_version = await get_api_version();
	const base_version = await get_base_version();
	const data_dict = JSON.stringify({
		base_version: base_version,
		app_version: version.app_version,
		api_version: api_version,
	});

	request_buffer.execute({
		type: 'post',
		data: {
			test_hash_id: hash_id,
			value: value,
			data: data_dict,
		},
		url: constants.api_endpoint_subscribe_ab_test,
		success: function() {
			if (success_function) {
				success_function();
			}
		}
	}, true);
}



