import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ripples from 'react-ripples';
import Haptic from '@components/haptic.js';
import { Link } from 'react-router-dom';
import ContentLoader from '@components/ContentLoader.js';
import { Collapse, CircularProgress } from '@material-ui/core';
import { Img } from 'react-image';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';
import { redirect } from '@routes/Routes.js';

// Constants import
import * as constants from '@constants';

// Import Request Buffer
import { request_buffer } from '@RequestBuffer';

// CSS Imports
import './styles/Card.css';

// Imports
import * as assets from '@Assets';
import player from '@Player';
import interaction from '@Interaction';
import queue from '@Queue';
import event_bus from '@Eventbus';

function find_similar_button(seed){
	store_set('seed', seed);
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', 'Now searching similar to:');
	store_set('loading_overlay_text', (seed.title || seed.name));
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
	queue.get_recommendations(seed.spotify_id || seed.hash_id, seed.type, '', true);
	store_set('active_overlay', false);
}

function redirect_profile(e, logged_user, user) {
	e.preventDefault();
	e.stopPropagation();
	if (user.hash_id === logged_user.hash_id) return;

	const user_link = user.slug ? user.slug : user.hash_id.substring(0,10);
	redirect(`/profile/${user_link}`);
}

// Simple wrapper to add a link around a component if that link is not undefined
const WithLinkIfAvailable = ({ link, children, className }) => (
	link ?
		<Link to={link} className={className}>
			{children}
		</Link>
		:
		children
);

class ImageLoader extends Component {
	render(){
		const { image_url, style, className, onError } = this.props;

		return(
			<div className='card-image-loader'>
				<ContentLoader style={style} />
				<img src={image_url} className={className} style={style} alt='' onError={(ev) => ev.target.src = onError} />
			</div>
		);
	}
}

class QuizCard extends Component {
	constructor(props){
		super(props);
		this.state = {
			data: {},
			expanded: false
		};

		if (this.props.dataItem){
			this.state.data = Object.assign({}, this.props.dataItem);
		} else if (this.props.dataArray && this.props.dataArray.length){
			this.state.data = this.props.dataArray[0];
		}
	}

	render(){
		const { data, expanded } = this.state;
		const { asset, displayButtons, icon, label, userLabel, onMoreClick, shouldExpand } = this.props;

		const item = data.item || data.recommendation; 
		const item_type = data.item_type || item.type;
		const title = item.name || item.title;
		const image = item.images ? item.images.large : item.album && item.album.images ? item.album.images.large : '';
		const subtitle = item.artist ? item.artist.name : item.album ? item.album.title : 'Artist';
		const description = data.description || data.message;
		const expanded_value = expanded || (!shouldExpand && (displayButtons || description));

		return (
			<div className='quiz-card'>
				{userLabel && data.user &&
					<div className='quiz-card-user-header'>
						<div className='quiz-card-user-header-user' onClick={(e) => redirect_profile(e, {}, data.user)}>
							<Img src={[data.user.picture, assets.default_profile_image]} alt='' />
							<div className='quiz-card-user-header-info'>
								<div className='quiz-card-user-header-title'>{data.user.name || data.user.first_name}</div>
								{data.user.slug &&
									<div className='quiz-card-user-header-subtitle'>@{data.user.slug}</div>
								}
							</div>
						</div>
					</div>
				}
				{label &&
					<div className='quiz-card-header'>
						{this.props.icon &&
							<div className='material-icons'>{icon}</div>
						}
						{this.props.asset &&
							<img className='quiz-card-icon-asset' src={asset} alt='' />
						}
						<div className='quiz-card-header-label'>{label}</div>
					</div>
				}
				<div
					className='quiz-card-main'
					style={{ backgroundImage: `url('${image}')` }}
				>
					<div className='quiz-card-main-background'></div>
					<div className='quiz-card-content'>
						<div className='quiz-card-content-text'>
							<div className='quiz-card-content-title'>{title}</div>
							<div className='quiz-card-content-subtitle'>{subtitle}</div>
						</div>
						{shouldExpand ?
							<div className='quiz-card-content-button' onClick={() => this.setState({expanded: !expanded})}>
								<span className='material-icons'>{expanded ? 'expand_less' : 'expand_more'}</span>
							</div>
							: onMoreClick && !expanded_value ?
								<div className='quiz-card-content-button' onClick={onMoreClick}>
									<span className='material-icons'>more_vert</span>
								</div>
								: null
						}
					</div>
					<Collapse in={expanded_value} timeout='auto' unmountOnExit={true}>
						{description ?
							<div className='quiz-card-content-comment'>&ldquo;{description}&rdquo;</div>
							:
							null
						}
						{displayButtons &&
							<div className='quiz-card-content-buttons'>
								<div className='quiz-card-content-double-button'>
									{item_type === 'song' &&
										<React.Fragment>
											<Ripples>
												<Haptic intesity='light'>
													<div className='quiz-card-content-double-button-left' onClick={() => {event_bus.emit('play_full_song', null, item);}}>Play Full Song</div>
												</Haptic>
											</Ripples>
											<span className='quiz-card-content-double-button-middle-line' />
										</React.Fragment>
									}
									{item_type === 'album' && item.spotify_id &&
										<React.Fragment>
											<Ripples>
												<Haptic intesity='light'>
													<div className='quiz-card-content-double-button-left' onClick={() => {redirect('/album/' + item.spotify_id);}}>Album Page</div>
												</Haptic>
											</Ripples>
											<span className='quiz-card-content-double-button-middle-line' />
										</React.Fragment>
									}
									{item_type === 'artist' && item.spotify_id &&
										<React.Fragment>
											<Ripples>
												<Haptic intesity='light'>
													<div className='quiz-card-content-double-button-left' onClick={() => {redirect('/artist/' + item.spotify_id);}}>Artist Page</div>
												</Haptic>
											</Ripples>
											<span className='quiz-card-content-double-button-middle-line' />
										</React.Fragment>
									}
									<Ripples>
										<Haptic intesity='light'>
											<div className='quiz-card-content-double-button-right' onClick={() => find_similar_button(item)}>Find Similar</div>
										</Haptic>
									</Ripples>
								</div>
								<div className='quiz-card-content-more-button material-icons' onClick={onMoreClick}>more_vert</div>
							</div>
						}
					</Collapse>
				</div>
			</div>
		);
	}
}
/*
Usage:
item = {title, subtitle, comment, image}

<Card 
	type='quiz_card' 
	label=''
	icon=''
	dataItem={item}
	dataArray={[item, item]}
	displayButtons={true}
	onMoreClick={() => console.log('open overlay')}
/>
*/

class DesktopCardAlbum extends Component {
	render(){
		const { data, bodyLink, onClick, enlarge } = this.props;
		const image_url = data.album ? data.album.images.large : data.images.large;
		const size = enlarge ? 423 : 204;

		return (
			<div className='desktop-big-card-container' id={data.spotify_id} style={{width: size, minWidth: size, height: size}}>
				<WithLinkIfAvailable link={bodyLink}>
					<Ripples className='big-card-box card-box-item' onClick={onClick}>
						<ImageLoader style={{'height': size, 'width': size}} image_url={image_url} onError={assets.default_album_image} className='big-card-box-image' />
						<div className='desktop-big-card-content'>
							<div className='desktop-big-card-bottom'>
								<div className='desktop-big-card-title'>{data.title}</div>
								{data.album &&
									<div className='desktop-big-card-subtitle'>{(data.album.name || data.album.title)}</div>
								}
								<div className='desktop-big-card-subtitle'>{data.artist.name}</div>
								<div className='desktop-big-card-subtitle'>{data.plays ? data.plays + ' plays' : ''}</div>
							</div>
							<div className='desktop-big-card-gradient'></div>
						</div>
					</Ripples>
				</WithLinkIfAvailable>
			</div>
		);
	}
}

class BigCardArtist extends Component {
	render(){
		const { data, bodyLink, onClick, onDelete, select_mode } = this.props;
		const image_url = data.images ? data.images.medium : assets.default_artist_image;

		return (
			<div className='big-card-container' id={data.spotify_id} onClick={onClick}>
				<WithLinkIfAvailable link={select_mode ? null : bodyLink}>
					<div className='big-card-blur-image'>
						<ImageLoader style={{height: 150, width: 150}} image_url={image_url} onError={assets.default_artist_image} className='big-card-box-image' />
						<div className='big-card-blur-image-overlay'>
							<ImageLoader style={{height: 96, width: 96, borderRadius: '100%', marginTop: 10}} image_url={image_url} onError={assets.default_artist_image} />
						</div>
					</div>
					<div className='big-card-content'>
						<div className='big-card-top'>
							<div></div>
							{onDelete &&
								<div className='big-card-options' onClick={(e) => {onDelete(e.target.value); e.stopPropagation(); e.preventDefault();}}>
									<span className='material-icons'>delete</span>
								</div>
							}
						</div>
						<div className='big-card-bottom'>
							<div className='big-card-title'>{data.name}</div>
							<div className='big-card-subtitle'>{data.plays ? data.plays + ' plays' : 'Artist'}</div>
						</div>
					</div>
				</WithLinkIfAvailable>
			</div>
		);
	}
}

class BigCardAlbum extends Component {
	render(){
		const { bodyLink, data, displayLabel, select_mode, onClick, onDelete } = this.props;
		const image_url = data.album ? data.album.images.medium : data.images.medium;

		return (
			<div className='big-card-container' id={data.spotify_id}>
				<WithLinkIfAvailable link={select_mode ? null : bodyLink}>
					<Ripples className='big-card-box card-box-item' onClick={onClick}>
						<ImageLoader style={{height: 150, width: 150}} image_url={image_url} onError={assets.default_album_image} className='big-card-box-image' />
						<div className='big-card-content'>
							<div className='big-card-top'>
								{displayLabel ?
									<div className='big-card-label'>Album</div>
									:
									<div></div>
								}
								{onDelete &&
									<div className='big-card-options' onClick={(e) => {onDelete(e.target.value); e.stopPropagation(); e.preventDefault();}}>
										<span className='material-icons'>delete</span>
									</div>
								}
							</div>
							<div className='big-card-bottom'>
								<div className='big-card-title'>{data.title}</div>
								<div className='big-card-subtitle'>{data.artist.name}</div>
								<div className='big-card-subtitle'>{data.plays ? data.plays + ' plays' : ''}</div>
							</div>
						</div>
					</Ripples>
				</WithLinkIfAvailable>
			</div>
		);
	}
}

class BigCardSong extends Component {
	render(){
		const { bodyLink, data, displayLabel, select_mode, onClick, onMoreClick } = this.props;
		const image_url = data.album ? data.album.images.medium : data.images.medium;

		return(
			<div className='big-card-container' id={data.spotify_id}>
				<WithLinkIfAvailable link={select_mode ? null : bodyLink}>
					<Ripples className='big-card-box card-box-item' onClick={onClick}>
						<div className='big-card-blur-image'>
							<ImageLoader style={{height: 150, width: 150}} image_url={image_url} onError={assets.default_artist_image} className='big-card-box-image' />
							<div className='big-card-blur-image-overlay'>
								<ImageLoader style={{height: 75, width: 75, marginTop: 15}} image_url={image_url} onError={assets.default_artist_image} />
							</div>
						</div>
						<div className='big-card-content'>
							<div className='big-card-top'>
								{displayLabel ?
									<div className='big-card-label'>Song</div>
									:
									<div></div>
								}
								<div className='big-card-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
									<span className='material-icons'>more_vert</span>
								</div>
							</div>
							<div className='big-card-bottom'>
								<div className='big-card-title'>{data.title}</div>
								<div className='big-card-subtitle'>{data.album.name}</div>
								<div className='big-card-subtitle'>{data.artist.name}</div>
							</div>
						</div>
					</Ripples>
				</WithLinkIfAvailable>
			</div>
		);
	}
}

class BigCardSongOriginal extends Component {
	render(){
		const { data, bodyLink, select_mode, tracks_selected, onClick, onMoreClick } = this.props;
		const export_track = tracks_selected.filter(track => track.spotify_id === data.spotify_id).length > 0;
		const image_url = data.album ? data.album.images.medium : data.images.medium;

		return (
			<div className='big-card-box-container' id={data.spotify_id}>
				<Ripples className='big-card-box card-box-item' onClick={onClick}>
					<WithLinkIfAvailable
						link={select_mode ? null : bodyLink}
					>
						<ImageLoader style={{height: 'calc(50vw - 22.5px)', width: 'calc(50vw - 22.5px)'}} image_url={image_url} onError={assets.default_album_image} className='big-card-box-image' />
					</WithLinkIfAvailable>
					{export_track &&
						<div className='big-card-box-selected' />
					}
					{select_mode &&
						<div className='big-card-box-checkbox'>
							<Ripples className='card-select-mode-checkbox-container' onClick={onClick}>
								<Haptic>
									<div className={'card-select-mode-checkbox' + (export_track ? '-selected' : '')}>
										{export_track &&
											<span className='material-icons'>check</span>
										}
									</div>
								</Haptic>
							</Ripples>
						</div>
					}
					<div className='big-card-info'>
						<div className='big-card-info-container'>
							<WithLinkIfAvailable
								link={select_mode ? null : bodyLink}
								className='card-info-link'
							>
								<div className='big-card-info-description'>
									<div className='big-card-info-description-title'>{data.title}</div>
									<div className='big-card-info-description-subtitle'>{data.artist.name}</div>
								</div>
							</WithLinkIfAvailable>
							{!select_mode &&
								<div className='big-card-info-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
									<span className='material-icons'>
										more_vert
									</span>
								</div>
							}
						</div>
					</div>
				</Ripples>
			</div>
		);
	}
}

class SmallCardSong extends Component {
	constructor(props){
		super(props);
		this.state = {
			expanded: false
		};
	}

	render(){
		const { expanded } = this.state;
		const {
			data,
			blockLink,
			displayLabel,
			is_new_like,
			select_mode,
			tracks_selected,
			show_interaction, 
			onClick,
			onDelete,
			onEdit,
			onMoreClick, 
			selected,
			selectable
		} = this.props;

		const export_track = tracks_selected.filter(track => track.spotify_id === data.spotify_id).length > 0;
		const image_url = data.album ? data.album.images ? data.album.images.large : '' : data.images ? data.images.large : '';
		const album_size = window.innerWidth <= 300 ? 37 : 54;
		const artists = data.artist.name ? data.artist.name : Array.from(data.artist, artist => artist.name).join(', ');
		const selectedIcon = this.props.selectedIcon ? this.props.selectedIcon : 'normal';
		const options_animation = {opacity: expanded ? 1 : 0, left: expanded ? 0 : '-5px'};
		
		if (!data) return <div></div>;
		return (
			<div className={'small-card-song-box ' + (selected ? 'small-card-box-orange-selected' : '')} id={data.spotify_id}>
				{is_new_like &&
					<div className='small-card-new-label'>New!</div>
				}
				<Ripples className='card-box-item' onClick={(select_mode || selectable) ? () => onClick() : () => this.setState({ expanded: !expanded })}>
					<div className='small-card-song-box-info'>
						<div className='small-card-song-box-image'>
							<ImageLoader style={{height: album_size, width: album_size, borderRadius: '4px 0px 0px 4px'}} image_url={image_url} onError={assets.default_album_image} />
							{show_interaction ? 
								data.interaction === 'like' ?
									<div className='small-card-box-like'><span className='material-icons'>favorite</span></div>
									: data.interaction === 'dislike'?
										<div className='small-card-box-dislike'><span className='material-icons'>clear</span></div>
										: null
								: null
							}
						</div>
						<div className='small-card-song-box-description'>
							<div className='small-card-song-box-description-title'>{data.title}</div>
							<div className='small-card-song-box-description-subtitle'>
								{displayLabel ? 'Song' : (data.album.name || data.album.title)}
								<span className='small-card-song-box-description-subtitle-circle'></span>
								{artists}
							</div>
						</div>
					</div>
					{!select_mode && !selectable && !blockLink &&
						<div className='small-card-song-overlay' style={{visibility: expanded ? 'visible' : 'hidden'}}>
							<div className='small-card-song-box-options'>
								<div className='small-card-song-overlay-option' style={options_animation}  onClick={(e) => {find_similar_button(data); e.preventDefault(); e.stopPropagation();}}>
									<img src={assets.find_similar_icon} alt=''/>
									<div className='small-card-song-overlay-option-text'>Find Similar</div>
								</div>
								<div className='small-card-song-overlay-option-divider' style={options_animation}>|</div>
								<div className='small-card-song-overlay-option' style={options_animation}  onClick={(e) => {redirect('/track/' + data.spotify_id); e.preventDefault(); e.stopPropagation(); store_set('active_overlay', false);}}>
									<span className='material-icons'>album</span>
									<div className='small-card-song-overlay-option-text'>Song Page</div>
								</div>
							</div>
						</div>
					}
					{!select_mode && onEdit &&
						<div className='small-card-song-box-options' onClick={(e) => {onEdit(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>edit</span>
						</div>
					}
					{!select_mode && onDelete &&
						<div className='small-card-song-box-options' onClick={(e) => {onDelete(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>delete</span>
						</div>
					}
					{!select_mode && onMoreClick && !expanded &&
						<div className='small-card-song-box-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							{selectedIcon === 'normal' ?
								<span className='material-icons' style={selected ? {fontSize: 20, color: '#ec6b43'} : {}}>
									{selected ? 'check' : 'more_vert'}
								</span>
								: selected ?
									<span className='material-icons' style={{fontSize: 24, color: '#ec6b43'}}>check_box</span>
									:  
									<span className='material-icons' style={{fontSize: 24, color: '#707070'}}>check_box_outline_blank</span>
							}
						</div>
					}
					{select_mode &&
						<React.Fragment>
							{export_track &&
								<div className='small-card-song-box-selected' />
							}
							<Ripples className='card-select-mode-checkbox-container' onClick={onClick}>
								<Haptic>
									<div className={'card-select-mode-checkbox' + (export_track ? '-selected' : '')}>
										{export_track &&
											<span className='material-icons'>check</span>
										}
									</div>
								</Haptic>
							</Ripples>
						</React.Fragment>
					}
				</Ripples>
			</div>
		);
	}
}

class SmallCardAlbum extends Component {
	constructor(props){
		super(props);
		this.state = {
			expanded: false
		};
	}

	render(){
		const { expanded } = this.state;
		const { data, displayLabel, onClick, onMoreClick, selected, selectable } = this.props;
		const image_url = data.images ? data.images.medium : '';
		const album_size = window.innerWidth <= 300 ? 27 : 44;
		const selectedIcon = this.props.selectedIcon ? this.props.selectedIcon : 'normal';
		const options_animation = {opacity: expanded ? 1 : 0, left: expanded ? 0 : '-5px'};
		
		return(
			<div className={'small-card-box ' + (selected ? ' small-card-box-orange-selected' : '')} id={data.spotify_id}>
				<Ripples 
					className='small-card-box-ripples'
					onClick={selectable ? () => onClick() : () => this.setState({ expanded: !expanded })}
				>
					<div className='small-card-box-image'>
						<ImageLoader style={{height: album_size, width: album_size, borderRadius: '100%', margin: 5}} image_url={image_url} onError={assets.default_album_image} />
					</div>
					<div className='small-card-box-info'>
						<div className='small-card-box-title'>{data.title}</div>
						<div className='small-card-song-box-description-subtitle'>
							{displayLabel ?
								<React.Fragment>
									Album
									<span className='small-card-song-box-description-subtitle-circle'></span>
								</React.Fragment>
								:
								null
							}
							{data.artist.name}
						</div>
					</div>
					<div className='small-card-song-overlay' style={{visibility: expanded ? 'visible' : 'hidden'}}>
						<div className='small-card-song-box-options'>
							<div className='small-card-song-overlay-option' style={options_animation} onClick={(e) => {find_similar_button(data); e.preventDefault(); e.stopPropagation();}}>
								<img src={assets.find_similar_icon} alt=''/>
								<div className='small-card-song-overlay-option-text'>Find Similar</div>
							</div>
							<div className='small-card-song-overlay-option-divider' style={options_animation}>|</div>
							<div className='small-card-song-overlay-option' style={options_animation} onClick={(e) => {redirect('/album/' + data.spotify_id); e.preventDefault(); e.stopPropagation();}}>
								<span className='material-icons'>album</span>
								<div className='small-card-song-overlay-option-text'>Album Page</div>
							</div>
						</div>
					</div>
					{onMoreClick && !selectable ? 
						<div className='small-card-song-box-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>
								more_vert
							</span>
						</div>
						: null
					}
					{selected && selectedIcon === 'normal' &&
						<div className='small-card-song-box-options'>
							<span className='material-icons' style={{fontSize: 20, color: '#ec6b43'}}>
								check
							</span>
						</div>
					}
					{ selectedIcon === 'checkbox' && 
						<div className='small-card-song-box-options'>
							{selected ?
								<span className='material-icons' style={{fontSize: 24, color: '#ec6b43'}}> check_box </span> :
								<span className='material-icons' style={{fontSize: 24, color: '#707070'}}> check_box_outline_blank </span>
							}
						</div>
					}
				</Ripples>
			</div>
		);
	}
}

class SmallCardArtist extends Component {
	constructor(props){
		super(props);
		this.state = {
			expanded: false
		};
	}

	render(){
		const { expanded } = this.state;
		const { data, onClick, onMoreClick, blockClick, onEdit, onDelete, button_type, subtitle_text, selected, selectable } = this.props;
		const image_url = data.images ? data.images.medium : '';
		const width = window.innerWidth <= 300 ? 95 : 115;
		const height = window.innerWidth <= 300 ? 37 : 54;
		const selectedIcon = this.props.selectedIcon ? this.props.selectedIcon : 'normal';
		const options_animation = {opacity: expanded ? 1 : 0, zIndex: expanded ? 1 : 0};

		return (
			<div className={'small-card-box ' + (selected ? ' small-card-box-orange-selected' : '')} id={data.spotify_id}>
				<Ripples 
					className='small-card-box-ripples' 
					onClick={blockClick ? false : (selectable ? () => onClick() : () => this.setState({ expanded: !expanded }))}
				>
					<div className='small-card-box-artist-image'>
						<ImageLoader style={{height: height, width: width}} image_url={image_url} onError={assets.default_artist_image_2} />
					</div>
					<div className='small-card-box-info'>
						<div className='small-card-box-title'>{data.name}</div>
						<div className='small-card-box-subtitle'>
							{subtitle_text ? subtitle_text : 'Artist'}
						</div>
					</div>
					<div className='small-card-artist-overlay-container' style={{visibility: expanded ? 'visible' : 'hidden'}}>
						<div className='small-card-artist-gradient-overlay' style={{...options_animation, width: expanded ? 60 : 30}} onClick={() => {}}></div>
						<div className='small-card-artist-overlay' style={{...options_animation, width: expanded ? '100%' : '80%'}}>
							<div className='small-card-song-box-options' style={options_animation}>
								<div className='small-card-song-overlay-option' onClick={expanded ? (e) => {find_similar_button(data); e.preventDefault(); e.stopPropagation();} : () => {}}>
									<img src={assets.find_similar_icon} alt=''/>
									<div className='small-card-song-overlay-option-text'>Find Similar</div>
								</div>
								<div className='small-card-song-overlay-option-divider' style={options_animation}>|</div>
								<div className='small-card-song-overlay-option' style={options_animation} onClick={expanded ? (e) => {redirect('/artist/' + data.spotify_id); e.preventDefault(); e.stopPropagation();} : () => {}}>
									<span className='material-icons'>album</span>
									<div className='small-card-song-overlay-option-text'>Artist Page</div>
								</div>
							</div>
						</div>
					</div>
					{onMoreClick && !expanded && !selectable &&
						button_type === 'more' ?
						<div className='small-card-song-box-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>more_vert</span>
						</div>
						: 
						button_type === 'follow' ?
							<div className='small-card-song-box-options-follow' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
								{data.is_following &&
									<span className='material-icons'>done</span>
								}
								<div 
									className='small-card-song-box-options-follow-text'
									style={{opacity: data.is_following ? 0.5 : 1}}
								>
									{data.is_following ? 'Following' : 'Follow'}
								</div>
							</div>
							: null
					}
					{onEdit &&
						<div className='small-card-song-box-options' onClick={(e) => {onEdit(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>edit</span>
						</div>
					}
					{onDelete &&
						<div className='small-card-song-box-options' onClick={(e) => {onDelete(e.target.value); e.stopPropagation(); e.preventDefault();}}>
							<span className='material-icons'>delete</span>
						</div>
					}
					{selectedIcon === 'normal' && selected &&
						<div className='small-card-song-box-options'>
							<span className='material-icons' style={{fontSize: 20, color: '#ec6b43'}}>
								check
							</span>
						</div>
					}
					{selectedIcon === 'checkbox' &&
						<div className='small-card-song-box-options'>
							{selected ?
								<span className='material-icons' style={{fontSize: 24, color: '#ec6b43'}}>check_box</span> :
								<span className='material-icons' style={{fontSize: 24, color: '#707070'}}>check_box_outline_blank</span>
							}
						</div>
					}
				</Ripples>
			</div>
		);
	}
}

class SeedCard extends Component {
	render(){
		const { data, onClick } = this.props;
		const image_url = data.album ? data.album.images.medium : data.images ? data.images.medium : data.image_link;

		return (
			<div
				onClick={onClick}
				className='seed-card'
				style={{
					backgroundImage: 'url(' + image_url + ')'
				}}
			>
				<div className='seed-card-background'></div>
				<div className='seed-card-content'>
					<div className='seed-card-icon material-icons'>schedule</div>
					<div className='seed-card-info'>
						<div className='seed-card-title'>{data.title || data.name}</div>
						{data.type === 'album' &&
							<div className='seed-card-subtitle'>
								<div>{data.artist.name}</div>
							</div>
						}
						{data.type === 'song' &&
							<div className='seed-card-subtitle'>
								<div>{(data.album.title || data.album.name)}</div>
								<div>•</div>
								<div>{data.artist.name}</div>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

class UserSeedCard extends Component {
	render(){
		const { data } = this.props;

		const on_click = () => {
			store_set('profile_info', { 'hash_id': data.member_info.hash_id, 'slug': data.member_info.slug });
			const slug = data.member_info.slug || data.member_info.hash_id.slice(0, 10);
			redirect('/profile/' + slug);
		};

		const open_overlay = (e) => {
			store_set('overlay_seed', data);
			store_set('active_overlay', 'track_options');
			store_set('active_overlay_option', 'show_seed_likes');
			e.stopPropagation(); e.preventDefault();
		};

		return (
			<div className='user-seed-card' onClick={on_click}>
				<img className='user-seed-card-image' src={data.member_info.image} alt='User' onError={(ev) => ev.target.src = assets.default_profile_image} />
				<div className='user-seed-card-content'>
					<div className='user-seed-card-info'>
						<div className='user-seed-card-info-title'>{data.member_info.name}</div>
						{data.member_info.slug ?
							<React.Fragment>
								<div className='user-seed-card-info-bullet'>●</div>
								<div className='user-seed-card-info-text user-seed-card-info-slug'>@{data.member_info.slug}</div>
							</React.Fragment>
							: null
						}
					</div>
					<div className='user-seed-card-info'>
						<svg xmlns='http://www.w3.org/2000/svg' width='19.875' height='17.391' viewBox='0 0 19.875 17.391'>
							<path id='Icon_awesome-seedling' data-name='Icon awesome-seedling' d='M2.484,4.734H0a8.7,8.7,0,0,0,8.7,8.7v5.59a.623.623,0,0,0,.621.621h1.242a.623.623,0,0,0,.621-.621V13.43A8.7,8.7,0,0,0,2.484,4.734ZM17.391,2.25a8.688,8.688,0,0,0-7.6,4.472,9.952,9.952,0,0,1,2.29,4.177A8.7,8.7,0,0,0,19.875,2.25Z' transform='translate(0 -2.25)' fill='#707070'/>
						</svg>
						<div className='user-seed-card-info-text'>{data.last_seed}</div>
					</div>
					<div className='user-seed-card-info-text' onClick={open_overlay}>Recently Liked</div>
					<div className='user-seed-card-items' onClick={open_overlay} >
						{data.recent_likes.slice(0,4).map((item, index) => (
							<img key={index} src={item.album.images.medium} alt='' />
						))}
						{data.recent_likes.length === 5 ?
							<img src={data.recent_likes[4].album.images.medium} alt='' />
							: data.recent_likes.length > 5 ?
								<div className='user-seed-card-items-more'>+{data.recent_likes.length - 4}</div>
								: null
						}
					</div>
				</div>
			</div>
		);
	}
}

class PlaylistCard extends Component {
	render(){
		const { data } = this.props;
		return (
			<div className='big-playlist-card' onClick={this.props.onClick}>
				<ImageLoader className='big-playlist-card-cover' image_url={data.image_link} onError={assets.default_playlist_image} style={{height: 160, width: 160}}/>
				<div className='big-playlist-card-content'>
					<div className='big-playlist-card-info'>
						<div className='big-playlist-card-title'>{data.name}</div>
						<div className='big-playlist-card-songs'>Playlist ⸱ {data.songs.length} songs</div>
						{data.is_author ?
							<div className='big-playlist-card-status' style={{ color: data.is_active ? '#ec6b43' : '#707070'}}>{data.is_active ? 'Active' : 'Not Active'}</div>
							:
							<div className='big-playlist-card-status'>{data.owner.slug ? `@${data.owner.slug}` : data.owner.name}</div>
						}
					</div>
					<span className='material-icons' onClick={(e) => {this.props.onMoreClick(e.target.value); e.stopPropagation();}}>more_vert</span>
				</div>
			</div>
		);
	}
}

class SmallPlaylistCard extends Component {
	constructor(props){
		super(props);
		this.state = {
			expanded: false
		};
	}

	render(){
		const { expanded } = this.state;
		const { data, onFindSimilar, onMoreClick } = this.props;
		const options_animation = {opacity: expanded ? 1 : 0};

		const handle_find_similar = (e) => {
			if (onFindSimilar){
				onFindSimilar(e);
			} else {
				find_similar_button(data);
				e.preventDefault();
				e.stopPropagation();
			}
		};

		return (
			<div className='small-card-playlist' onClick={() => this.setState({expanded: !expanded})}>
				<div className='small-card-song-box-info'>
					<div className='small-card-song-box-image'>
						<ImageLoader style={{height: 53, width: 102, borderRadius: '4px 0px 0px 4px'}} image_url={data.image_link} onError={assets.default_playlist_image} />
					</div>
					<div className='small-card-song-box-description'>
						<div className='small-card-song-box-description-title'>{data.name}</div>
						<div className='small-card-song-box-description-subtitle'>
							Playlist
							{data.owner.slug &&
								<React.Fragment>
									<span className='small-card-song-box-description-subtitle-circle'></span>
									@{data.owner.slug}
								</React.Fragment>
							}
						</div>
					</div>
				</div>
				<div className='small-card-artist-overlay-container' style={{visibility: expanded ? 'visible' : 'hidden'}}>
					<div className='small-card-artist-gradient-overlay' style={{...options_animation, width: expanded ? 50 : 30}}></div>
					<div className='small-card-artist-overlay' style={{...options_animation, width: expanded ? '100%' : '80%'}}>
						<div className='small-card-song-box-options'>
							<div className='small-card-song-overlay-option' style={options_animation} onClick={handle_find_similar}>
								<img src={assets.find_similar_icon} alt=''/>
								<div className='small-card-song-overlay-option-text'>Find Similar</div>
							</div>
							<div className='small-card-song-overlay-option-divider' style={options_animation}>|</div>
							<div className='small-card-song-overlay-option' style={options_animation} onClick={(e) => {redirect('/playlist/' + data.hash_id); e.preventDefault(); e.stopPropagation();}}>
								<span className='material-icons'>queue_music</span>
								<div className='small-card-song-overlay-option-text'>Playlist Page</div>
							</div>
						</div>
					</div>
				</div>
				{onMoreClick &&
					<div className='small-card-song-box-options' onClick={(e) => {onMoreClick(e.target.value); e.stopPropagation(); e.preventDefault();}}>
						<span className='material-icons'>more_vert</span>
					</div>
				}
			</div>	
		);
	}
}

class UserCard extends Component {
	render(){
		const { data, onClick, onFollow } = this.props;
		let picture = data.picture ? data.picture : assets.default_profile_image;
		let profile_slug = window.location.pathname.replace('/profile/', '');
		if (!data.hash_id) return null;

		return(
			<div className='user-card' onClick={() => onClick ? onClick() : {}}>
				<div className='user-card-link' onClick={() => onClick ? redirect('/profile/' + (data.slug ? data.slug : data.hash_id.substring(0,10))) : {}}>
					<ImageLoader className='user-card-image' image_url={picture} onError={assets.default_profile_image} style={{'width': 68, 'height': 68, 'clipPath': 'circle(100.0% at 0 50%)'}}/>
					<div className='user-card-info'>
						<div className='user-card-info-title'>
							<div className='user-card-info-name'>{data.first_name}</div>
							{data.slug &&
								<React.Fragment>
									<span>●</span>
									<div className='user-card-info-subtitle'>@{data.slug}</div>
								</React.Fragment>
							}
						</div>
						{profile_slug.length > 0 ?
							<div className='user-card-info-subtitle'>Both like <span>{data.liked_artists[0]}</span>
								{data.liked_artists.length - 1 > 0 && ' + ' + (data.liked_artists.length - 1) + ' more'}
							</div>
							:
							<div className='user-card-info-subtitle'>Both of you liked <span>{data.liked_artists[0]}</span>
								{data.liked_artists.length - 1 > 0 &&
									' + ' + (data.liked_artists.length - 1) + ' more'
								}
							</div>
						}
					</div>
					{data.is_loading ?
						<div className='user-card-loading'>
							<CircularProgress size={15} />
						</div>
						: onFollow ?
							<div
								className='user-card-button' style={data.followed ? {color: '#7a7a7a'} : {}}
								onClick={(e) => {e.preventDefault(); e.stopPropagation(); onFollow();}}>
								{data.followed ? 'Unfollow' : 'Follow'}
							</div>
							: null
					}
				</div>
			</div>
		);
	}
}

class ProfileCard extends Component {
	constructor(props){
		super(props);
		this.state = {};

		this.set_new_profile_info = this.set_new_profile_info.bind(this);
	}

	set_new_profile_info(data){
		store_set('profile_info', {
			'followers': data.followers,
			'hash_id': data.hash_id,
			'picture': data.picture,
			'slug': data.slug,
			'member_id': data.member_id,
		});
	}

	render(){
		const { bodyLink, data, show_follow, onClick } = this.props;
		const picture = data.picture ? data.picture : assets.default_profile_image;
		const followers = data.followers ? data.followers : 0;
		const common_followers = data.common_followers ? data.common_followers : [];
		let followers_text;

		if (common_followers.length > 0){
			followers_text = 'Followed by ';
			common_followers.forEach((user, index) => followers_text += user + (index < common_followers.length - 1 ? ', '  : ''));
			if (followers > common_followers.length){
				followers_text += ' + ' + (followers - common_followers.length) + ' more';
			}
		}
		else followers_text = followers + ' followers';

		if (!data.hash_id) return null;
		return(
			<div className='profile-card' onClick={() => this.set_new_profile_info(data)}>
				<WithLinkIfAvailable link={bodyLink}>
					<ImageLoader style={{ width: 68, height: 54, clipPath: 'circle(100.0% at 0 50%)', borderRadius: 4 }} image_url={picture} onError={assets.default_profile_image} className='profile-card-image' />
					<div className='profile-card-info'>
						<p className='profile-card-info-name'>{data.name}</p>
						<p className='profile-card-info-followers'>{followers_text}</p>
					</div>
				</WithLinkIfAvailable>
				{onClick && show_follow &&
					<div className='profile-card-button' onClick={onClick}>
						{data.followed ?
							<div className='profile-card-button-text' style={{opacity: 0.5}}>
								<span className='material-icons'>check</span>
								Following
							</div>
							: 
							<div className='profile-card-button-text'>Follow</div>
						}
					</div>
				}
			</div>
		);
	}
}

class BigProfileCard extends Component {
	constructor(props){
		super(props);
		this.state = {};
		this.set_new_profile_info = this.set_new_profile_info.bind(this);
	}

	set_new_profile_info(data){
		store_set('profile_info', {
			'followers': data.followers,
			'hash_id': data.hash_id,
			'picture': data.picture,
			'slug': data.slug,
			'member_id': data.member_id,
		});
	}

	render(){
		const { data, onClick } = this.props;
		const picture = data.picture ? data.picture : assets.default_profile_image;
		const slug = data.slug ? data.slug : data.hash_id.substring(0,10);

		return(
			<div className='big-profile-card' onClick={() => this.set_new_profile_info(data)} >
				<div className='big-profile-card-background' style={{backgroundImage: `url(${picture}), url(${assets.default_profile_image})`}}></div>
				<Link to={'/profile/' + slug}>				
					<div className='big-profile-card-info'>
						<div className='big-profile-card-image'>
							<ImageLoader style={{ width: 86, height: 86, borderRadius: 43}} image_url={picture} onError={assets.default_profile_image}/>
						</div>
						<p className='big-profile-card-info-name'>{data.name}</p>
						<p className='big-profile-card-info-slug'>@{slug}</p>
					</div>
				</Link>
				{data.is_loading ?
					<div className='big-profile-card-loading'>
						<CircularProgress size={15} />
					</div>
					:
					<div className='big-profile-card-button' onClick={onClick}>
						{data.followed ?
							<div className='big-profile-card-button-text' style={{opacity: 0.5}}>
								<span className='material-icons'>check</span>
								Following
							</div>
							: 
							<div className='big-profile-card-button-text'>+ Follow</div>
						}
					</div>
				}
			</div>
		);
	}
}

class GridCardAlbum extends Component {
	render(){
		const { bodyLink, data, onClick } = this.props;
		const image_url = data.album ? data.album.images.medium : data.images.medium;

		return (
			<div className='grid-card-container' id={data.spotify_id}>
				<WithLinkIfAvailable link={bodyLink}>
					<Ripples className='grid-card-box' onClick={onClick}>
						<ImageLoader
							image_url={image_url}
							onError={assets.default_album_image}
							className='grid-card-box-image'
						/>
					</Ripples>
				</WithLinkIfAvailable>
			</div>
		);
	}
}

class PollCard extends Component {
	render(){
		const { data, secondary, preview, user, onDelete } = this.props;
		const total_replies = data.replies.length;

		const card_width = secondary ? window.innerWidth - 30 : window.innerWidth*0.75;
		const image_width = 345; // Width of the rectangle in pixels
		const image_height = 245; // Height of the rectangle in pixels
		const height = (card_width * image_height) / image_width;
		const image = data.image || 'https://magroove-dev.s3.amazonaws.com/magroove-app/assets/polls-empty.jpg';

		const reply_disabled = preview || (user.hash_id === data.user.hash_id);
		const on_reply = (e) => {
			if (reply_disabled) return;
			e.stopPropagation();
			store_set('poll_info', data);
			store_set('recommendation_return_page', window.location.pathname);
			redirect('/poll-recommendation-search/' + data.entities);
		};

		const on_click = () => {
			if (total_replies && !preview){
				store_set('poll_info', data);
				redirect('/poll/');
			}
		};

		return (
			<div className='poll-card'>
				<div className='poll-card-header'>
					<div className='poll-card-user' onClick={preview ? () => {} : (e) => redirect_profile(e, this.props.user, this.props.data.user)}>
						<Img src={[data.user.picture, assets.default_profile_image]} alt='' />
						<div className='poll-card-header-info'>
							<div className='poll-card-header-title'>{data.user.name || data.user.first_name}</div>
							{data.user.slug &&
								<div className='poll-card-header-subtitle'>@{data.user.slug}</div>
							}
						</div>
					</div>
				</div>
				{secondary ?
					<div className='poll-card-content-secondary' onClick={on_click}>
						<div className='poll-card-description-secondary'>{data.message}</div>
						<div className='poll-card-recommended-secondary' style={{height}}>
							<img src={image} alt='' />
						</div>
						<div className='poll-card-footer-secondary'>
							<div className='poll-card-info-secondary'>{data.created_at} • {total_replies} Replies</div>
							<div className='poll-card-button-secondary' onClick={on_reply} style={{opacity: reply_disabled ? 0.5 : 1}}>
								<span className='material-icons'>reply</span>
								<span>Reply</span>
							</div>
						</div>
					</div>
					:
					<div
						className='poll-card-content'
						style={{backgroundImage: `url(${image})`, height: height}}
						onClick={on_click}
					>
						<div className='poll-card-overlay'></div>
						<div className='poll-card-description'>{data.message}</div>
						<div className='poll-card-button'>
							<Ripples>
								<Haptic intesity='light'>
									<div className='poll-card-button-left' onClick={on_reply} style={{opacity: reply_disabled ? 0.5 : 1}}>Reply</div>
								</Haptic>
							</Ripples>
							<span className='poll-card-button-middle-line' />
							<Ripples>
								<Haptic intesity='light'>
									<div className='poll-card-button-item-right' onClick={on_click} style={{opacity: total_replies === 0 ? 0.5 : 1}}>{total_replies} Replies</div>
								</Haptic>
							</Ripples>
						</div>
					</div>
				}
				{onDelete ?
					<div className='poll-card-delete' onClick={onDelete}>
						<div className='material-icons'>delete</div>
					</div>
					: null
				}
			</div>
		);
	}
}

class RecommendationCard extends Component {
	constructor(props){
		super(props);
		this.state = {
			interaction: false,
		};

		const current_recommendation = store_get('friends_recommendations').find(rec => rec.hash_id === this.props.data.hash_id);
		if (current_recommendation) {
			this.state.interaction = current_recommendation.interaction;
		}

		this.handle_player = this.handle_player.bind(this);
		this.friend_recommendation_interaction = this.friend_recommendation_interaction.bind(this);
	}

	handle_player(e) {
		// If track has no preview to play, we simply redirect to track page
		if (!this.props.data.recommendation.preview_url) { return; }
		
		// We stop link redirecting and either load or toggle the player with track
		e.preventDefault();
		if (this.props.playing_song.spotify_id === this.props.data.recommendation.spotify_id) {
			player.toggle();
		} else {
			// If loading track on player, we scroll to top so user can see home tinder
			const scroll_top = document.getElementsByClassName('home-scroll-container')[0];
			if (scroll_top) {
				scroll_top.scrollTo({ top: 0, behavior: 'smooth' });
			}
			player.load(0, this.props.data.recommendation);
		}
	}

	friend_recommendation_interaction(interaction) {
		let new_friends_recommendations = [...store_get('friends_recommendations')];
		let recommendation = new_friends_recommendations.find(rec => rec.hash_id === this.props.data.hash_id);

		if (recommendation) {
			recommendation.interaction = interaction;
			store_set('friends_recommendations', new_friends_recommendations);
		}

		this.setState({interaction});
		request_buffer.auth_execute({
			type: 'post',
			data: {
				tag_hash: this.props.data.hash_id,
				interaction: interaction,
			},
			url: constants.api_endpoint_friend_recommendation_interaction,
		});
	}

	render(){
		let { data, hide_interaction, onClick } = this.props;
		let recommendation = data.recommendation;
		let display_image = recommendation.images ? recommendation.images.medium : recommendation.album.images.medium;

		if ((!data || !recommendation)) return null;
		return(
			<div className='recommendation-card'>
				<div onClick={() => {redirect('/recommendation/'); store_set('recommendation_info', data);}}>
					<div className='recommendation-card-header'>
						<div className='recommendation-card-user'>
							<Img src={[data.user.picture, assets.default_profile_image]} alt='' onClick={(e) => redirect_profile(e, this.props.user, this.props.data.user)} />
							<div className='recommendation-card-header-info'>
								<div className='recommendation-card-header-title' onClick={(e) => redirect_profile(e, this.props.user, this.props.data.user)}>{data.user.name}</div>
								{data.user.slug &&
									<div className='recommendation-card-header-subtitle' onClick={(e) => redirect_profile(e, this.props.user, this.props.data.user)}>@{data.user.slug}</div>
								}
							</div>
						</div>
						{onClick &&
							<span className='material-icons' onClick={(e) => {e.preventDefault(); e.stopPropagation(); onClick();}}>more_vert</span>
						}
					</div>
					<div className='recommendation-card-content'>
						<div className='recommendation-card-description' onClick={(e) => {e.stopPropagation(); redirect('/recommendation/'); store_set('recommendation_info', data);}}>{data.message}</div>
						<div className='recommendation-card-recommended'>
							<Img src={[display_image, assets.default_album_image]} />
							<div className='recommendation-card-recommended-shadow' onClick={(e) => {e.preventDefault(); e.stopPropagation(); redirect(`/${(recommendation.type === 'song' ? 'track' : recommendation.type)}/${recommendation.spotify_id}`);}}></div>
							<div className='recommendation-card-recommended-inner'>
								<div className='recommendation-card-recommended-info'>
									<div className='recommendation-card-recommended-title'>{(recommendation.name || recommendation.title)}</div>
									<div className='recommendation-card-recommended-subtitle'>{recommendation.type} {recommendation.type !== 'artist' ? ' • ' + (recommendation.artist.name) : ''} </div>
								</div>
								{recommendation.type === 'song' && recommendation.preview_url &&
									<div className='recommendation-card-recommended-play' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.handle_player(e);}}>
										<span className='material-icons'>{this.props.is_playing && this.props.playing_song.spotify_id === recommendation.spotify_id ? 'pause' : 'play_arrow'}</span>
									</div>
								}
							</div>
						</div>
					</div>
					{recommendation.type === 'song' && !hide_interaction &&
						<div className='recommendation-card-options'>
							<div
								className='recommendation-card-option'
								style={this.state.interaction === 'dislike' ? {opacity: 0.5} : {}}
								onClick={(e) => {e.preventDefault(); e.stopPropagation(); interaction.like(0, recommendation); this.friend_recommendation_interaction('like');}}
							>
								<span className='material-icons'>favorite</span>
								<div className='recommendation-card-option-text'>Like</div>
							</div>
							<div
								className='recommendation-card-option'
								style={this.state.interaction === 'like' ? {opacity: 0.5} : {}}
								onClick={(e) => {e.preventDefault(); e.stopPropagation(); interaction.dislike(0, recommendation); this.friend_recommendation_interaction('dislike');}}
							>
								<span className='material-icons'>close</span>
								<div className='recommendation-card-option-text'>Dislike</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

class UserAlternativeCard extends Component {
	render(){
		const { data, onClick, onFollow } = this.props;
		const slug = data.slug ? data.slug : data.hash_id.substring(0,10);
		if (!data.hash_id) return null;
		return(
			<div className='user-alternative-card-container' onClick={onClick}>
				<Link to={'/profile/' + slug}>
					<Img className='user-alternative-image' src={[data.picture, assets.default_profile_image]} alt='' />
					<div className='user-alternative-card'>
						<div className='user-alternative-card-title'>{data.name}</div>
						<div className='user-alternative-card-subtitle'>@{slug}</div>
						{data.is_loading ?
							<div className='user-alternative-card-loading'>
								<CircularProgress size={15} />
							</div>
							:
							<div className='user-alternative-button' onClick={(e) => {e.preventDefault(); e.stopPropagation(); onFollow();}}>
								<span className='material-icons'>{data.followed ? 'check' : 'add'}</span>
								<div className='user-alternative-button-text'>{data.followed ? 'Following' : 'Follow'}</div>
							</div>
						}
					</div>
				</Link>
			</div>
		);
	}
}

class ShareObsessionCard extends Component {
	render(){
		const { data, onClick, user, style, id } = this.props;
		const image_url = data.album ? data.album.images && data.album.images.medium : data.images.medium;
		const width = window.innerWidth; 
		const half_width = window.innerWidth * 0.6;
		const days = { 
			Mon: 'Monday', 
			Tue: 'Tuesday',
			Wed: 'Wednesday', 
			Thu: 'Thursday',
			Fri: 'Friday', 
			Sat: 'Saturday',
			Sun: 'Sunday' 
		};
		const months = { Jan: 'January', Feb: 'February', Mar: 'March', Apr: 'April', May: 'May', Jun: 'June', Jul: 'July', Aug: 'August', Sep: 'September', Oct: 'October', Nov: 'November', Dec: 'December' };

		const d = new Date().toString().split(' '); //Tue May 23 2023 21:00:00 GMT-0300 (Brasilia Standard Time)
		const day = `${days[d[0]]} ${d[2]} ${months[d[1]]} ${d[3]}`;
		if (!user || (!user.slug && !user.first_name))
			return (
				<>
					<CircularProgress style={{ margin: 'auto' }} size={20} />
				</>
			);

		return(
			<div id={id || 'share-obsession-card'} style={style} className='share-obsession-container'>
				<Ripples className='share-obsession-box card-box-item' onClick={onClick}>
					<div id='share-obsession-card-title' className='share-obsession-card-title'>
						{user.slug ? `@${user.slug} new obsession:` : `${user.first_name}'s new obsession`}
					</div>
					<div className='share-obsession-image'>
						<div
							onClick={onClick}
							style={{
								justifyContent: 'center',
								display: 'flex',
								width,
								height: width * 0.95
							}}
						>
							<svg className='share-obsession-image-background' xmlns='http://www.w3.org/2000/svg' width={width} height={width} viewBox='0 0 494.982 461.489'>
								<g id='Grupo_26891' data-name='Grupo 26891' transform='translate(13604.219 12827.326)'>
									<path id='Polígono_149' data-name='Polígono 149' d='M330.008,0l-11,133.357,121,57.153-121,57.153,11,133.357-110-76.2-110,76.2,11-133.357L0,190.51l121-57.153L110,0l110,76.2Z' transform='translate(-13548.079 -12815.855) rotate(8)' fill='#faf6eb'/>
									<path id='Polígono_148' data-name='Polígono 148' d='M302.441,0,292.36,122.23l110.895,52.384L292.36,227l10.081,122.23L201.627,279.383,100.814,349.229,110.9,227,0,174.614,110.895,122.23,100.814,0,201.627,69.846Z' transform='translate(-13490.521 -12827.326) rotate(19)' fill='#eb921b'/>
									<path id='Polígono_147' data-name='Polígono 147' d='M302.473,0,292.39,122.23,403.3,174.614,292.39,227l10.082,122.23L201.649,279.383,100.824,349.229,110.907,227,0,174.614,110.907,122.23,100.824,0,201.648,69.846Z' transform='translate(-13583.21 -12737.501) rotate(-9)' fill='#ec6b43'/>
								</g>
							</svg>
							<div
								onClick={onClick}
								className='share-obsession-image-overlay'
								style={{
									backgroundImage: 'url(' + image_url + ')',
									width: half_width,
									height: half_width
								}}
							>
								<div className='share-obsession-gradient-background'></div>
								<div className='share-obsession-content'>
									<div>
										<div className='share-obsession-title'> {data.title || ''}</div>
										<div className='share-obsession-subtitle'>{data.artist.name || ''}</div>
										{data.album.release_date &&
											<div className='share-obsession-year'>{data.album.release_date.split('-')[0]}</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='share-obsession-footer-container'>
						<div className='share-obsession-footer-date'>{day}</div>
						<div className='share-obsession-footer-image'>
							<svg xmlns='http://www.w3.org/2000/svg' id='Group_6052' width='144.5' height='30' data-name='Group 6052' viewBox='0 0 465.023 57.274'>
								<defs>
									<clipPath id='clip-path'>
										<path fill='none' d='M0 0H465.023V57.274H0z'/>
									</clipPath>
								</defs>
								<g id='Component_2' clipPath='url(#clip-path)' data-name='Component 2'>
									<path id='Path_1' d='M12.2.636L29.5 35.8 46.695.636H58.8V55.5h-9.608l-.1-39.2L32.86 49.829h-6.822L9.8 16.3v39.2H0V.636z' className='cls-3' data-name='Path 1' transform='translate(0 .521)'/>
									<path id='Path_2' d='M94.623 43.872H67.817L62.916 55.5H52.06L76.176.636h10.856L110.763 55.5H99.426zm-3.747-9.032l-9.608-23.059-9.7 23.059z' className='cls-3' data-name='Path 2' transform='translate(13.37 .521)'/>
									<path id='Path_3' d='M141.135 28.289h9.127v20.657a38.554 38.554 0 0 1-21.714 7.11c-16.429 0-29.016-12.106-29.016-27.959C99.532 12.34 112.407.33 129.124.33a33.991 33.991 0 0 1 21.522 7.879l-5.958 7.691a23.516 23.516 0 0 0-15.564-6.15c-10.569 0-18.928 8.07-18.928 18.448 0 10.473 8.359 18.543 18.928 18.543a25.264 25.264 0 0 0 11.818-3.459V28.289z' className='cls-3' data-name='Path 3' transform='translate(25.562 .443)'/>
									<path id='Path_4' d='M183.906 55.5l-9.512-16.237a12.952 12.952 0 0 1-1.922.1h-12.394V55.5h-10.473V.636h22.868c14.22 0 22.291 6.822 22.291 18.928 0 8.647-3.843 14.8-10.856 17.775L196.013 55.5h-12.107zm-23.828-25.368h12.395c7.879 0 12.2-3.363 12.2-10.281 0-6.725-4.42-9.992-12.2-9.992h-12.395z' className='cls-3' data-name='Path 4' transform='translate(38.421 .521)'/>
									<path id='Path_5' d='M249.761 28.1c0 15.757-12.683 27.959-29.208 27.959S191.344 43.951 191.344 28.1 204.027.331 220.552.331c16.526.096 29.209 12.009 29.209 27.769zm-47.75 0a18.5 18.5 0 1 0 36.989 0 18.288 18.288 0 0 0-18.35-18.353A18.534 18.534 0 0 0 202.011 28.1z' className='cls-4' data-name='Path 5' transform='translate(49.141 .443)'/>
									<path id='Path_6' d='M298.687 28.1c0 15.757-12.683 27.959-29.208 27.959S240.27 43.951 240.27 28.1 252.952.331 269.479.331 298.687 12.34 298.687 28.1zm-47.752 0a18.5 18.5 0 1 0 36.991 0 18.288 18.288 0 0 0-18.351-18.353c-10.088.096-18.64 8.07-18.64 18.353z' className='cls-4' data-name='Path 6' transform='translate(61.706 .443)'/>
									<path id='Path_7' d='M299.6.636l16.141 43.236L332.072.636h10.856l-22 54.861H310.07L288.355.636z' className='cls-3' data-name='Path 7' transform='translate(74.055 .521)'/>
									<path id='Path_8' d='M377.443.636v9.127h-29.689V23.31h26.614v9.127h-26.614v13.932H378.4V55.5h-41.119V.636z' className='cls-3' data-name='Path 8' transform='translate(86.62 .521)'/>
								</g>
							</svg>
						</div>
					</div>
				</Ripples>
			</div>
		);
	}
}

class Card extends Component {
	render(){
		const { type } = this.props;
		const options = {
			big_card_artist: <BigCardArtist {...this.props} />,
			big_card_album: <BigCardAlbum {...this.props} />,
			desktop_card_album: <DesktopCardAlbum {...this.props} />,
			big_card_song: <BigCardSong {...this.props} />,
			big_card_song_original: <BigCardSongOriginal {...this.props} />,
			small_card_song: <SmallCardSong {...this.props} />,
			small_card_album: <SmallCardAlbum {...this.props} />,
			small_card_artist: <SmallCardArtist {...this.props} />,
			seed_card: <SeedCard {...this.props} />,
			user_seed_card: <UserSeedCard {...this.props} />,
			big_playlist_card: <PlaylistCard {...this.props} />,
			small_playlist_card: <SmallPlaylistCard {...this.props} />,
			user_card: <UserCard {...this.props} />,
			profile_card: <ProfileCard {...this.props} />,
			big_profile_card: <BigProfileCard {...this.props} />,
			grid_card_album: <GridCardAlbum {...this.props} />,
			recommendation: <RecommendationCard {...this.props} />,
			quiz_card: <QuizCard {...this.props} />,
			user_alternative_card: <UserAlternativeCard {...this.props} />,
			share_obsession: <ShareObsessionCard {...this.props} />,
			poll_card: <PollCard {...this.props} />,
		};
		const component = options[type];

		if (component) return component;
		return null;
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		tracks_selected: state.GlobalReducer.tracks_selected,
		select_mode: state.GlobalReducer.select_mode,
		user: state.GlobalReducer.user,
		is_playing: state.GlobalReducer.is_playing,
		playing_song: state.GlobalReducer.playing_song,
	};
}

function areEqual(prevProps, nextProps) {
	if (prevProps.data){
		// Poll Card
		if (prevProps.data.replies !== nextProps.data.replies){
			return false;
		}
		// In case of card with a loading/following option,
		// we need to allow re-render so it can updated properly
		if (prevProps.data.is_loading !== undefined ||
			prevProps.data.is_following !== undefined ||
			prevProps.data.followed !== undefined ||
			prevProps.data.follow_status !== undefined ||
			prevProps.data.followers !== undefined) {
			return false;
		}
		// Allow to immediately update information displayed on editable cards
		if ((prevProps.data.type === 'playlist') ||
			(prevProps.data.name && prevProps.data.name !== nextProps.data.name) ||
			(prevProps.data.songs && prevProps.data.songs.length !== nextProps.data.songs.length)) {
			return false;
		}
		const prevId = prevProps.data.spotify_id || prevProps.data.hash_id;
		const nextId = nextProps.data.spotify_id || nextProps.data.hash_id;
		return prevId === nextId && prevProps.selected === nextProps.selected;
	}
	return false;
}

const CardMemo = connect(mapStateToProps)(React.memo(Card, areEqual));
export default CardMemo;
