import React from 'react';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

import { redirect } from '@routes/Routes.js';

class BackButton extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			history: [],
			history_locations: [],
			props_to_check: [
				'location',
				'active_overlay',
				'premium_overlay',
				'just_login',
				'current_page',
				'track_overlay_option',
			],
			last_history: {},
			last_action: ''
		};
		this.exit_app = this.exit_app.bind(this);
		this.add_props_to_history = this.add_props_to_history.bind(this);
		this.pop_last_history = this.pop_last_history.bind(this);
		this.go_back = this.go_back.bind(this);
		event_bus.on('back-button-pressed', this.go_back);
		event_bus.on('add-user-history', this.add_props_to_history);
	}

	shouldComponentUpdate(nextProps){
		if (this.state.last_action === 'pop'){
			this.setState({ last_action: '' });
			return true;
		}
		this.state.props_to_check.every((prop) => {
			if (this.props[prop] !== nextProps[prop] && this.props[prop] !== this.state.last_history[prop]) {
				if (!this.state.history_locations.includes(this.props.location)) {
					this.add_props_to_history();
					return false; 
				}
			}
			return true;
		});
		return true;
	}

	add_props_to_history(){
		if (this.props.premium_overlay || this.props.active_overlay)
			return;
		let new_props_state = {};
		let new_history = [...this.state.history];
		let new_history_location = [...this.state.history_locations]

		this.state.props_to_check.forEach((prop) => {
			new_props_state[prop] = this.props[prop];
		});
		new_history.push(new_props_state);
		new_history_location.push(new_props_state.location);
		this.setState({history: new_history, history_locations: new_history_location, last_history: new_props_state, last_action: 'add'});
	}

	go_back(){
		if(this.props.location === 'home' && !this.props.premium_overlay && (['no_more_recommendations', 'no_more_tracks'].includes(this.props.active_overlay) || !this.props.active_overlay)){
			this.exit_app();
			return;
		}
		if (this.props.location === 'home' && this.props.just_login){
			// On first time after a new login, we redirect user to search page after first searching a seed
			// we set the just_login prop to false, so this happens only on the first seed search flow  
			redirect('/search/');
			store_set('just_login', false);
		} else if (this.props.location === 'onboarding'){
			// The event bus, for some reason, is not getting the updated state on the Onboarding
			// page, so we saved the function on the store and we manually call it.
			var onboarding_action = store_get('onboarding_back_button');
			onboarding_action();
		} else if (this.props.active_overlay) {
			store_set('active_overlay', false);
			store_set('active_overlay_option', 'show_all_track');
		} else if (this.props.premium_overlay) {
			event_bus.emit('back-button-premium');
		} else {
			this.pop_last_history();
		}
	}

	pop_last_history(){
		if(this.state.history.length > 0){
			let new_history = [...this.state.history];
			let last_history = new_history.pop();
			let new_history_location = [...this.state.history_locations];
			new_history_location.pop();
			
			this.setState({history: new_history, history_locations: new_history_location, last_history: last_history, last_action: 'pop'});

			// Set the state of the props to the last state of the history
			this.state.props_to_check.forEach((prop) => {
				if(prop === 'current_page' && this.props.current_page !== last_history[prop]) {
					redirect(last_history[prop]);
				}
				else if (prop !== 'location') store_set(prop, last_history[prop]);
			});
		}
		else this.exit_app();
	}

	exit_app(){
		if (Capacitor.getPlatform() !== 'web'){
			App.exitApp();
		}
	}

	render(){
		return null;
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		active_overlay: state.GlobalReducer.active_overlay,
		premium_overlay: state.GlobalReducer.premium_overlay,
		just_login: state.GlobalReducer.just_login,
		current_page: state.GlobalReducer.current_page,
		track_overlay_option: state.GlobalReducer.track_overlay_option,
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(BackButton));
