import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Textfield } from '@magroove/magroove-ui';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import * as constants from '@constants';
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// CSS Imports
import './styles/ContactUs.css';

// Components and Views Imports
import Ripples from 'react-ripples';
import Haptic from '@components/haptic.js';

class ContactUs extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: '',
			email: this.props.user ? this.props.user.email : '',
			text: '',
			subject: ''
		};

		if (this.props.user && this.props.user.first_name){
			var last_name = this.props.user.last_name ? this.props.user.last_name : '';
			this.state.name = this.props.user.first_name + ' ' + last_name;
		}

		this.send = this.send.bind(this);
	}

	send(){
		var message = false;
		const regex = /\S+@\S+\.\S+/;
		if (!this.state.text ||
			!this.state.email ||
			!this.state.name ||
			!this.state.subject
		){
			message = 'Please, fill in all the fields';
		}
		else if(!regex.test(this.state.email)){
			message = 'Invalid email format';
		}
		else if (this.state.text.length < 10){
			message = 'The message is too short, please give us more details';
		}

		if (message){
			store_set('snackbar_message', message);
			store_set('snackbar_status', true);
			return;
		}

		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Now sending...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_contact_our_support,
			data: {
				name: this.state.name,
				email: this.state.email,
				subject: this.state.subject,
				text: this.state.text
			},
			success: function(data){
				if (data.status === 'success'){
					this.setState({text: ''});
					this.setState({subject: ''});
					store_set('snackbar_message', 'Success!');
					store_set('snackbar_status', true);
				}
				else{
					store_set('snackbar_message', 'Error sending your message, please try again.');
					store_set('snackbar_status', true);
				}
			}.bind(this),
			error: function(){
				store_set('snackbar_message', 'Error sending your message, please try again.');
				store_set('snackbar_status', true);
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}

	render(){
		return(
			<div className='contact-us-main'>
				<div className='contact-us-text'>
					Do you need help with your app or any of our services? Let us know how we can help you.
				</div>
				<div className='contact-us-textfields' onClick={() => store_set('keyboard_open', true)}>
					{!this.props.user.first_name &&
						<Textfield
							theme='dark'
							size='small'
							fullWidth={true}
							className='contact-us-oneline'
							value={this.state.name}
							label='Name'
							onChange={(value) => this.setState({name: value})}
						/>
					}
					{!this.props.user.email &&
						<Textfield
							theme='dark'
							size='small'
							fullWidth={true}
							className='contact-us-oneline'
							value={this.state.email}
							label='E-mail'
							onChange={(value) => this.setState({email: value})}
						/>
					}
					<Textfield
						theme='dark'
						size='small'
						fullWidth={true}
						className='contact-us-oneline'
						value={this.state.subject}
						label='Subject'
						onChange={(value) => this.setState({subject: value})}
					/>
					<Textfield
						theme='dark'
						size='small'
						fullWidth={true}
						value={this.state.text}
						multiline={true}
						rows={10}
						placeholder='Write your message here...'
						onChange={(value) => this.setState({text: value})}
					/>
				</div>
				<Ripples>
					<Haptic disable={!this.state.text}>
						<Button
							value='SEND'
							size='small'
							fullWidth={true}
							onClick={this.send}
						/>
					</Haptic>
				</Ripples>
			</div>
		);
	}
}


// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(ContactUs));