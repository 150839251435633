import React, { Component } from 'react';

// CSS Imports
import './styles/DefaultError.css';

export default class DefaultError extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="default-error-container">
					<div className="default-error-icon">
						<span className="material-icons">
							warning
						</span>
					</div>
					<div className="default-error-title">Ooops…</div>
					<div className="default-error-text">Something went wrong.</div>
					<div className="default-error-text">Could you please try again later?</div>
				</div>
			</React.Fragment>
		);
	}
}