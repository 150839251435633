// Assets Imports
import * as assets from './Assets.js';

// API environment configuration file
import { API_ENV } from './ApiEnv.js';

// Debug options ----------------------------------------------------------------------------------------------------------------
export const debug = process.env.NODE_ENV === 'development';
export const purge_store_on_reload = process.env.NODE_ENV === 'development' ? false : false;
export const enable_analytics = process.env.NODE_ENV === 'development' ? false : true;
export const app_domain = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8100' : 'https://discover.magroove.com';

// API endpoints ----------------------------------------------------------------------------------------------------------------
// eslint-disable-next-line 
export const api_endpoint = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'http://127.0.0.1:8000/dev' : 'https://api.magroove.com/{api_version}'
export const api_endpoint_auth = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'http://127.0.0.1:8000' : 'https://auth.magroove.com';
export const api_endpoint_share = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'http://127.0.0.1:8000' : 'https://share.magroove.com';
// export const api_endpoint = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'https://magroove.ngrok.io/{api_version}' : 'https://api.magroove.com/{api_version}'
// export const api_endpoint_auth = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'https://magroove.ngrok.io' : 'https://auth.magroove.com'
// export const api_endpoint_share = (process.env.NODE_ENV === 'development' && API_ENV === 'development') ? 'https://magroove.ngrok.io' : 'https://share.magroove.com'

export const api_endpoint_login_get_token = api_endpoint + '/login-get-token/{session_hash_id}/';
export const api_endpoint_login_facebook = api_endpoint_auth + '/facebook-login/{session_hash_id}/';
export const api_endpoint_login_fb_native = api_endpoint_auth + '/fb-native-login/{session_hash_id}/';
export const api_endpoint_login_google = api_endpoint_auth + '/google-login/{session_hash_id}/';
export const api_endpoint_login_google_native = api_endpoint_auth + '/google-native-login/{session_hash_id}/';
export const api_endpoint_login_spotify = api_endpoint_auth + '/spotify-login/{session_hash_id}/';
export const api_endpoint_login_apple = api_endpoint_auth + '/apple-login/{session_hash_id}/';
export const api_endpoint_login_debug = api_endpoint_auth + '/debug-login/{session_hash_id}/';
export const api_endpoint_login_link_spotify = api_endpoint_auth + '/spotify-token/{session_hash_id}?token={token}';
export const api_endpoint_login_link_deezer = api_endpoint_auth + '/deezer-token/{session_hash_id}?token={token}';
export const api_endpoint_probe_version = api_endpoint_auth + '/probe-version/';

export const api_endpoint_linked_account = api_endpoint + '/link-accounts/{session_hash_id}/';
export const api_endpoint_session_interaction = api_endpoint + '/interaction/';
export const api_endpoint_recommendations = api_endpoint + '/recommendations/';
export const api_endpoint_user_recommendations = api_endpoint + '/user-recommendations/';
export const api_endpoint_search = api_endpoint + '/search/';
export const api_endpoint_internal_search = api_endpoint + '/internal-search/';
export const api_endpoint_profile_search = api_endpoint + '/profile-search/';
export const api_endpoint_save_search = api_endpoint + '/save-search/';
export const api_endpoint_get_song_links = api_endpoint + '/get-song-links/';
export const api_endpoint_get_album_links = api_endpoint + '/get-album-links/';
export const api_endpoint_get_seed_list = api_endpoint + '/get-seed-list/';
export const api_endpoint_interaction = api_endpoint + '/interaction/';
export const api_endpoint_get_likes_list = api_endpoint + '/get-likes-list/' ;
export const api_endpoint_user = api_endpoint + '/user/';
export const api_endpoint_profile = api_endpoint + '/profile/';
export const api_endpoint_probe_token = api_endpoint + '/probe-token/';
export const api_endpoint_edit_profile_page = api_endpoint + '/edit-profile-page/';
export const api_endpoint_unlink_account = api_endpoint + '/unlink-account/';
export const api_endpoint_get_signed_url = api_endpoint + '/get-signed-url/';
export const api_endpoint_update_profile_picture = api_endpoint + '/update-profile-picture/';
export const api_endpoint_delete_profile_picture = api_endpoint + '/delete-profile-picture/';
export const api_endpoint_delete_account = api_endpoint + '/delete-account/';
export const api_endpoint_get_playlists = api_endpoint + '/get-playlists/';
export const api_endpoint_duplicate_playlist = api_endpoint + '/duplicate-playlist/';
export const api_endpoint_export_to_spotify = api_endpoint + '/export-to-spotify/';
export const api_endpoint_export_sync_to_spotify = api_endpoint + '/export-sync-to-spotify/';
export const api_endpoint_export_to_deezer = api_endpoint + '/export-to-deezer/';
export const api_endpoint_export_sync_to_deezer = api_endpoint + '/export-sync-to-deezer/';
export const api_endpoint_export_to_youtube = api_endpoint + '/export-to-youtube/';
export const api_endpoint_contact_our_support = api_endpoint + '/contact-support/';
export const api_endpoint_create_playlist = api_endpoint + '/create-playlist/';
export const api_endpoint_fetch_notifications = api_endpoint + '/notifications/';
export const api_endpoint_notification_touch = api_endpoint + '/notification/touch/';
export const api_endpoint_notification_click = api_endpoint + '/notification/onclick/';
export const api_endpoint_notification_view = api_endpoint + '/notification/onview/';
export const api_endpoint_notification_clear = api_endpoint + '/notification/clear/';
export const api_endpoint_song_meta = api_endpoint_share + '/song/meta/{hash_id}';
export const api_endpoint_artist_meta = api_endpoint_share + '/artist/meta/{hash_id}';
export const api_endpoint_album_meta = api_endpoint_share + '/album/meta/{hash_id}';
export const api_endpoint_get_synced_playlists = api_endpoint + '/get-synced-playlists/';
export const api_endpoint_add_synced_playlist = api_endpoint + '/add-synced-playlist/';
export const api_endpoint_remove_synced_playlist = api_endpoint + '/remove-synced-playlist/';
export const api_endpoint_auto_recommendation = api_endpoint + '/auto-enrichment-choice/';
export const api_endpoint_add_obsession = api_endpoint + '/save-obsession/';

export const api_endpoint_subscription_pricing = api_endpoint + '/subscription/pricing/';
export const api_endpoint_subscription_verify = api_endpoint + '/subscription/verify/';
export const api_endpoint_subscription_card_payment = api_endpoint + '/subscription/payment/';
export const api_endpoint_subscription_confirm_payment = api_endpoint + '/subscription/confirm/';
export const api_endpoint_subscription_cancel = api_endpoint + '/subscription/cancel/';
export const api_endpoint_subscription_renew = api_endpoint + '/subscription/renew/';
export const api_endpoint_subscription_restore = api_endpoint + '/subscription/restore/';
export const api_endpoint_subscription_get_info = api_endpoint + '/subscription/';
export const api_endpoint_save_premium_action = api_endpoint + '/save-premium-action/';

export const api_endpoint_polls = api_endpoint + '/polls/';
export const api_endpoint_create_poll = api_endpoint + '/poll/create/';
export const api_endpoint_delete_poll = api_endpoint + '/poll/delete/';

export const api_endpoint_user_event = api_endpoint + '/user-event/';
export const api_endpoint_save_referrer = api_endpoint + '/save-referrer/';
export const api_endpoint_get_suggestions = api_endpoint + '/get-suggestions/';
export const api_endpoint_save_fine_tunning = api_endpoint + '/save-fine-tunning/';
export const api_endpoint_save_review = api_endpoint + '/save-review/';
export const api_endpoint_get_album_recommendations = api_endpoint + '/get-album-recommendations/';
export const api_endpoint_create_internal_playlist = api_endpoint + '/create-internal-playlist/';
export const api_endpoint_edit_internal_playlist = api_endpoint + '/edit-internal-playlist/';
export const api_endpoint_remove_internal_playlist = api_endpoint + '/remove-internal-playlist/';
export const api_endpoint_get_internal_playlists = api_endpoint + '/get-internal-playlists/';
export const api_endpoint_add_track_internal_playlist = api_endpoint + '/add-track-internal-playlist/';
export const api_endpoint_remove_track_internal_playlist = api_endpoint + '/remove-track-internal-playlist/';
export const api_endpoint_update_active_playlist = api_endpoint + '/update-active-playlist/';
export const api_endpoint_export_internal_playlist = api_endpoint + '/export-internal-playlist/';
export const api_endpoint_sync_internal_playlist = api_endpoint + '/sync-internal-playlist/';
export const api_endpoint_get_spotify_stats = api_endpoint + '/get-spotify-stats/';
export const api_endpoint_get_new_releases = api_endpoint + '/new-releases/';
export const api_endpoint_get_user_favorites = api_endpoint + '/user-favorites/';
export const api_endpoint_follow_user_management = api_endpoint + '/follow-user-management/';
export const api_endpoint_follow_artist_management = api_endpoint + '/follow-artist-management/';
export const api_endpoint_follow_playlist_management = api_endpoint + '/follow-playlist-management/';
export const api_endpoint_get_top_tracks = api_endpoint + '/get-top-tracks/';
export const api_endpoint_get_artist_stats = api_endpoint + '/get-artist-stats/';
export const api_endpoint_get_artist_albums = api_endpoint + '/get-artist-albums/';
export const api_endpoint_get_artist_playlists = api_endpoint + '/get-artist-playlists/';

export const api_endpoint_subscribe_ab_test = api_endpoint + '/subscribe-ab-test/';
export const api_endpoint_get_blocklist = api_endpoint + '/blocklist/';
export const api_endpoint_update_blocklist = api_endpoint + '/blocklist/{action}/';
export const api_endpoint_get_magroove_stats = api_endpoint + '/get-magroove-stats/';
export const api_endpoint_get_user_leaderboard = api_endpoint + '/get-user-leaderboard/';
export const api_endpoint_get_user_quiz = api_endpoint + '/get-user-quiz/';
export const api_endpoint_save_user_quiz = api_endpoint + '/save-user-quiz/';
export const api_endpoint_delete_user_quiz = api_endpoint + '/delete-user-quiz/';
export const api_endpoint_get_likeminded_users = api_endpoint + '/get-likeminded-users/';
export const api_endpoint_get_people_you_may_know = api_endpoint + '/get-people-you-may-know/';
export const api_endpoint_get_artist_works = api_endpoint + '/get-artist-works/';
export const api_endpoint_get_similar_artists = api_endpoint + '/get-similar-artists/';
export const api_endpoint_get_social_activity = api_endpoint + '/social-activity/';
export const api_endpoint_save_play_history = api_endpoint + '/save-play-history/';
export const api_endpoint_get_trending = api_endpoint + '/trending/';
export const api_endpoint_get_following_entities = api_endpoint + '/get-following-entities/';
export const api_endpoint_save_generic_event = api_endpoint + '/save-generic-event/';
export const api_endpoint_get_friends_recommendations = api_endpoint + '/get-friends-recommendations/';
export const api_endpoint_save_friend_recommendation = api_endpoint + '/save-friend-recommendation/';
export const api_endpoint_remove_friend_recommendation = api_endpoint + '/remove-friend-recommendation/';
export const api_endpoint_friend_recommendation_interaction = api_endpoint + '/friend-recommendation-interaction/';
export const api_endpoint_get_my_recommendations = api_endpoint + '/get-my-recommendations/';
export const api_endpoint_ad_tracking = api_endpoint + '/ad-tracking/';
export const api_endpoint_top_entities_edit = api_endpoint + '/top-entities/edit/';
export const api_endpoint_app_log = 'https://log.magroove.com/log';
export const api_endpoint_get_followers = api_endpoint + '/get-followers/';

// AdMob options ----------------------------------------------------------------------------------------------------------------
export const admob_debug = API_ENV === 'development' ? true : false;
export const admob_android_banner_id = API_ENV === 'development'? 'ca-app-pub-3940256099942544/6300978111' : 'ca-app-pub-9939197865290920/9958838771';
export const admob_android_interstitial_id = API_ENV === 'development'? 'ca-app-pub-3940256099942544/1033173712' : 'ca-app-pub-9939197865290920/1178330628';
export const admob_ios_banner_id = API_ENV === 'development'? 'ca-app-pub-3940256099942544/6300978111' : 'ca-app-pub-9939197865290920/1865544570';
export const admob_ios_interstitial_id = API_ENV === 'development'? 'ca-app-pub-3940256099942544/1033173712' : 'ca-app-pub-9939197865290920/8454185415';

// Auth options -----------------------------------------------------------------------------------------------------------------
export const login_token_check_interval = process.env.NODE_ENV === 'development' ? 2000 : 500;
export const login_token_timeout = process.env.NODE_ENV === 'development' ? 300000 : 300000;

// RequestBuffer options --------------------------------------------------------------------------------------------------------
export const probe_queue_interval = process.env.NODE_ENV === 'development' ? 500 : 500;
export const retry_initial_interval = process.env.NODE_ENV === 'development' ? 2000 : 3000;
export const retry_escalation_factor = process.env.NODE_ENV === 'development' ? 2 : 1.5;
export const max_retries = process.env.NODE_ENV === 'development' ? 10 : 24;

// Player constants
export const minimum_waiting_time = process.env.NODE_ENV === 'development' ? 0.5 : 1;
export const max_seconds_with_no_interactions = 382;
export const audio_time_update_interval = 100;
export const swipe_animation_duration = 700; // milliseconds

// Quiz constants
export const quiz_list = [
	{quiz: 'teenage', title: 'Song that represent your teenage years', icons: [assets.quiz_teenage_icon, assets.quiz_teenage_icon_outlined], search: 'song'}, 
	{quiz: 'karaoke_song', title: 'Go to karaoke song', icons: [assets.quiz_karaoke_song_icon, assets.quiz_karaoke_song_icon_outlined], search: 'song'},
	{quiz: 'cry', title: 'Best song to cry to', icons: [assets.quiz_cry_icon, assets.quiz_cry_icon_outlined], search: 'song'},
	{quiz: 'exercise', title: 'Best song to exercise to', icons: [assets.quiz_exercise_icon, assets.quiz_exercise_icon_outlined], search: 'song'},
	{quiz: 'heartbroken', title: 'Best song to listen when you\'re heartbroken', icons: [assets.quiz_heartbroken_icon, assets.quiz_heartbroken_icon_outlined], search: 'song'},
	{quiz: 'zombie', title: 'Best song to listen when fighting zombies', icons: [assets.quiz_zombie_icon, assets.quiz_zombie_icon_outlined], search: 'song'},
	{quiz: 'sex', title: 'Best song to listen when having sex', icons: [assets.quiz_sex_icon, assets.quiz_sex_icon_outlined], search: 'song'},
	{quiz: 'roadtrip', title: 'Must listen song during a roadtrip', icons: [assets.quiz_roadtrip_icon, assets.quiz_roadtrip_icon_outlined], search: 'song'},
	{quiz: 'dishes', title: 'Best song to listen when doing the dishes', icons: [assets.quiz_dishes_icon, assets.quiz_dishes_icon_outlined], search: 'song'},
	{quiz: 'concert', title: 'Favorite track from your first concert', icons: [assets.quiz_concert_icon, assets.quiz_concert_icon_outlined], search: 'song'},
	{quiz: 'artist_underrated', title: 'An artist that you consider underrated', icons: [assets.quiz_attention_icon, assets.quiz_attention_icon_outlined], search: 'artist'},
	{quiz: 'soul', title: 'Artist you would sell your soul to watch live', icons: [assets.quiz_soul_icon, assets.quiz_soul_icon_outlined], search: 'artist'},
	{quiz: 'karaoke_artist', title: 'Artist you would like to go karaoke with', icons: [assets.quiz_karaoke_artist_icon, assets.quiz_karaoke_artist_icon_outlined], search: 'artist'},
	{quiz: 'wardrobe', title: 'Artist you would like to steal the wardrobe from', icons: [assets.quiz_wardrobe_icon, assets.quiz_wardrobe_icon_outlined], search: 'artist'},
];

// Moods
export const all_moods = [
	{
		type: 'mood',
		name: 'Drop It Like It\'s Hot',
		image: assets.mood_drop_it_like_its_hot,
		icon: assets.icon_drop_it_like_its_hot,
		spotify_id: '70ludZZEUJy1aFVkPI0Kpi,0Qp37xG9JmIpYURi8xJl3E,2EMauqGtcQBe2WpALvKh3j,0Qp37xG9JmIpYURi8xJl3E,3B6WDceNe6lUQnczntRIuf'
	},
	{
		type: 'mood',
		name: 'I Wanna Be, Anarchy',
		image: assets.mood_I_wanna_be_anarchy,
		icon: assets.icon_I_wanna_be_anarchy,
		spotify_id: '5moTxUGPZXgGmosl4rIELm,5jzma6gCzYtKB1DbEwFZKH,2kYn0VPQY1iTY3XpCvUaPt,5IN2qOuRqPJoMNtsWwoztX,7weMJXfu8leOPWd4COj2Qp'
	},
	{
		type: 'mood',
		name: 'Party Hard',
		image: assets.mood_party_hard,
		icon: assets.icon_party_hard,
		spotify_id: '4rCDfKjSS4KZk6hMB9epXh,5FgtdSf7I5lClThz2ptWvl,4gOFjiG8qm5eZzeqQl9MU5,3uLSUZEmTY50H6Kw17lpfW,0pMUR7Uvp6vxlbG0qBFvgM'
	},
	{
		type: 'mood',
		name: 'Focus & Productivity',
		image: assets.mood_focus_and_productivity,
		icon: assets.icon_focus_and_productivity,
		spotify_id: '1c7J3x5nUIl7krcxbZqpW0,4cNtJQaOgmjlBSChAlrsBT,4jgazYikKO5Xhctuhc0snS,111YqYUFNPFN75J6qDhT1b,2P3LnLs7poXbWyKrf6KNav'
	},
	{
		type: 'mood',
		name: 'Pumped up',
		image: assets.mood_pumped_up,
		icon: assets.icon_pumped_up,
		spotify_id: '1RZc3mEB7kp0VYeKHorILL,4t9aVhB3mYRqk2H3BgHRS4,4L302vPKtjDaRyiXumrCxn,3NVYXqjBYy5caVac5RRNxj,1Oryb6TjgqwR97WZc8nuAH'
	},
	{
		type: 'mood',
		name: 'Country Roads',
		image: assets.mood_country_roads,
		icon: assets.icon_country_roads,
		spotify_id: '2xYQTU2bbg6WVAmpY1eae4,6R6ib5FgDYlG0rPA9c5DVv,39q7xibBdRboeMKUbZEB6g,2EoIt9vdgFRNW03u5IvFsQ,02sENi9ULQ36x9mKo5B7XW'
	},
	{
		type: 'mood',
		name: 'Heart of Jazz',
		image: assets.mood_heart_of_jazz,
		icon: assets.icon_heart_of_jazz,
		spotify_id: '1qCQTy0fTXerET4x8VHyr9,78MI7mu1LV1k4IA2HzKmHe,3AgqQbQWYLGsemeBHk1U1d,4p4XgwnZJCYOWSvz8ruiOY,1YQWosTIljIvxAgHWTp7KP'
	},
	{
		type: 'mood',
		name: 'Rock this City',
		image: assets.mood_rock_this_city,
		icon: assets.icon_rock_this_city,
		spotify_id: '6OnfBiiSc9RGKiBKKtZXgQ,0M7oqERflkrJVHD3IAZqxo,29SyMC0plk6qw8NMF7lfRL,3XcjIvaZVUFAIdIYZqY9bd,2lRhq1ZfHNiaVNHkTWeXUv'
	},
	{
		type: 'mood',
		name: 'Chilly Beats',
		image: assets.mood_chilly_beats,
		icon: assets.icon_chilly_beats,
		spotify_id: '2MJ2Ch0REzpgLvnLIIU8zA,1o6Xgn3GJXh0MpLeP684Qo,1mJ9oAPuo3hHspOYamtoYc,0G3uQxxQxp08QHIMxKfEgO,2Gt3Svg0Ji2fL4qf1RHEUU'
	},
	{
		type: 'mood',
		name: 'K-popping',
		image: assets.mood_k_popping,
		icon: assets.icon_k_popping,
		spotify_id: '28Jwll1H52Hr4JzQ6vMoul,2Oi0IO8K4BEbhPUdWcjNmv,7tMN6iGJMulMFpSCeU7pmU,3aG0gbuvZ1VeVm68d4J6Yq,22sIPXiQzcPydCC6skPPPq'
	},
	{
		type: 'mood',
		name: 'Pop at Work',
		image: assets.mood_pop_at_work,
		icon: assets.icon_pop_at_work,
		spotify_id: '1qAt45VjrZYwyVJSX6Mpuy,5QO79kh1waicV47BqGRL3g,76nqCfJOcFFWBJN32PAksn,54bFM56PmE4YLRnqpW6Tha,3PfIrDoz19wz7qK7tYeu62'
	},
	{
		type: 'mood',
		name: 'Sad Vibes',
		image: assets.mood_sad_vibes,
		icon: assets.icon_sad_vibes,
		spotify_id: '7m9OqQk4RVRkw9JJdeAw96,7r2p4VKIqYrDdWN6z4qhSo,4O3RwH1TFG1EjPezLIYehI,61KpQadow081I2AsbeLcsb,0rKtyWc8bvkriBthvHKY8d'
	},
	{
		type: 'mood',
		name: 'Swinging like it\'s 1930',
		image: assets.mood_swinging_like_2930,
		icon: assets.icon_swinging_like_2930,
		spotify_id: '3sMleqdCDalZ6xsAQe8xuY,1fbZDbnXkNXN4wbzme7Kk0,5KdJVf0tqurkIDdBEbIJVG,7ylvjuIk3hO7K8JTgtoLEd,0ztNLT11ln4bOudVKfvcCh'
	}
];

// Genres
export const all_genres = [
	{
		type: 'genre',
		name: 'Rock',
		image: assets.genre_rock,
		color: 'linear-gradient(8deg, #f30b60 21%, #ffbc05 52%)',
		spotify_id: '1Q5kgpp4pmyGqPwNBzkSrw,3qT4bUD1MaWpGrTwcvguhb,5y4761pjcksXs1hNsCmc4n,2b9lp5A6CqSzwOrBfAFhof,5SAUIWdZ04OxYfJFDchC7S'
	},
	{
		type: 'genre',
		name: 'Pop',
		image: assets.genre_pop,
		color: 'linear-gradient(8deg, #b9052f 21%, #ff058a 52%)',
		spotify_id: '7qiZfU4dY1lWllzX7mPBI3,52yOf7Ls1u5AO3cFIzQ58m,78rIJddV4X0HkNAInEcYde,2XlHu0HcujBCkWMdIAvrqt,6gpcs5eMhJwax4mIfKDYQk'
	},
	{
		type: 'genre',
		name: 'Hip / Hop',
		image: assets.genre_hiphop_rap,
		color: 'linear-gradient(8deg, #ad0bf3 21%, #05deff 52%)',
		spotify_id: '7KXjTSCq5nL1LoYtL7XAwS,2bUojZta7GJTjGAqo064jo,0v9Wz8o0BT8DU38R4ddjeH,3KUpGMnZL76I9E7FoWV1re,7sLpSWxQazJzDVG6YGzlVs'
	},
	{
		type: 'genre',
		name: 'R&B Soul',
		image: assets.genre_r_and_b_soul,
		color: 'linear-gradient(8deg, #0bf37f 21%, #00f5cd 52%)',
		spotify_id: '0y8h1AJzI4y7UxzX5xdVpo,4Edtgs4Io5HYYN6MDZ4i5G,2dQXFXq40PRZyurt30uNxM,3uJ8zHRASayItvcSRh8TIm,59RJ9hBNhwRVLmgqyd9OFJ'
	},
	{
		type: 'genre',
		name: 'Dance',
		image: assets.genre_dance,
		color: 'linear-gradient(8deg, #0bf3d3 21%, #05ff99 52%)',
		spotify_id: '0pMUR7Uvp6vxlbG0qBFvgM,4K0SX0U52u9GQ1m6H2rRt4,74PyndGWYtfs86n60lAsYW,0DiWol3AO6WpXZgp0goxAV,1jpD1K2zRhaCIAEkIBb51U'
	},
	{
		type: 'genre',
		name: 'Alternative',
		image: assets.genre_alternative,
		color: 'linear-gradient(8deg, #ff65d2 21%, #fdd450 52%)',
		spotify_id: '7nnWIPM5hwE3DaUBkvOIpy,6emdJW80SbDBMuHci8PJJn,7clUVcSOtkNWa58Gw5RfD4,3DQVgcqaP3iSMbaKsd57l5,4fPBB44eDH71YohayI4eKV'
	},
	{
		type: 'genre',
		name: 'Electronic',
		image: assets.genre_electronic,
		color: 'linear-gradient(8deg, #f30b0b 21%, #fc1590 52%)',
		spotify_id: '5WYB4aZkflwjAApBoDCYkz,10fYerkpuLv3Fvh8EP7gf1,294vBlXfZYspeI29SXZaON,4Np4NOxEBsYvPNBZ8W0BDu,0oiYllbOKQMN9SHF5viwQg'
	},
	{
		type: 'genre',
		name: 'New Age',
		image: assets.genre_new_age,
		color: 'linear-gradient(8deg, #c766ff 21%, #5effae 52%)',
		spotify_id: '6FLwmdmW77N1Pxb1aWsZmO,6bk11TH82XrT5QUC1gDw9A,2UKkLqST2xWpK8n1b0Xr5Z,7mfvOqsvzZaUl7sfbcNk4E,7LAJWSKK8JMIZAcblgUMS6'
	},
	{
		type: 'genre',
		name: 'Blues',
		image: assets.genre_blues,
		color: 'linear-gradient(8deg, #30cfd0 21%, #330867 52%)',
		spotify_id: '58PSYdY0GFg0LFb2PxYk4T,4CayREZylZ5ij94y1v0zlc,5PHnHhiVMiMjzYOqz7AJQQ,3YGsgJqtIWKjMjZtKLk11Z,6gJnxMZphJt3sk2JJXTkoJ'
	},
	{
		type: 'genre',
		name: 'Jazz',
		image: assets.genre_jazz,
		color: 'linear-gradient(8deg, #ffe53c 21%, #fc6caf 52%)',
		spotify_id: '7q3kkfAVpmcZ8g6JUThi3o,4Ce66JznW8QbeyTdSzdGwR,1uvIV6UKOFRw7HqfD932tn,7wGx08b8HOKxnppqcoq4ea,0CLbmkYmQIWiEwnsbOkLpd'
	},
	{
		type: 'genre',
		name: 'Folk',
		image: assets.genre_folk,
		color: 'linear-gradient(8deg, #f9f586 21%, #96fbc4 52%)',
		spotify_id: '4fbvXwMTXPWaFyaMWUm9CR,5GbVzc6Ex5LYlLJqzRQhuy,2Zom8KgY4hnsZUlgg0WNQP,3XRALl9CNCnjjbCZid2L9w,7iIpvpJDEGgqU1wg4f9Tnm'
	},
	{
		type: 'genre',
		name: 'Country',
		image: assets.genre_country,
		color: 'linear-gradient(8deg, #fa71cd 21%, #c471f5 52%)',
		spotify_id: '2xYQTU2bbg6WVAmpY1eae4,6R6ib5FgDYlG0rPA9c5DVv,39q7xibBdRboeMKUbZEB6g,2EoIt9vdgFRNW03u5IvFsQ,02sENi9ULQ36x9mKo5B7XW'
	},
	{
		type: 'genre',
		name: 'Latin',
		image: assets.genre_latin,
		color: 'linear-gradient(8deg, #0250c5 21%, #d43f8d 52%)',
		spotify_id: '3VvA1wSxukMLsvXoXtlwWx,7BIy3EGQhg98CsRdKYHnJC,5iF33sXejXwfTuidVHMll0,66S2z95FN3nUAr9S1gUIsp,1LXb70u8SLsvBAPWHMCTMe'
	},
	{
		type: 'genre',
		name: 'Reggae',
		image: assets.genre_reggae,
		color: 'linear-gradient(8deg, #0bf3d3 21%, #ff7272 52%)',
		spotify_id: '6JRLFiX9NJSoRRKxowlBYr,4snbdgmZZH2Nqt3aIp5DqF,7bnRX1ObyGzzSuOWrNFLWw,6A9mKXlFRPMPem6ygQSt7z,2hnMS47jN0etwvFPzYk11f'
	},
	{
		type: 'genre',
		name: 'Classical',
		image: assets.genre_classical,
		color: 'linear-gradient(8deg, #8b09ac 21%, #63ff77 52%)',
		spotify_id: '58PSYdY0GFg0LFb2PxYk4T,5u5aVJKjSMJr4zesMPz7bL,3dae4N7bUkLcetcWf1QjwN,744WBttJC108sWMrzD8uBw,0CJuLeZpCdqrqoCMXWZw8k'
	},
	{
		type: 'genre',
		name: 'Opera',
		image: assets.genre_opera,
		color: 'linear-gradient(8deg, #00dbde 21%, #e602b8 52%)',
		spotify_id: '1ntATsEazvDV1jw3iiZlID,5Adxsld8lL9MLcaULeoSKu,0ySYHJm1Bf5P6vEsdeSYb0,51Fi8flrG3IHrFeU4G198k,41ujv4mhxlqR8nlnieDpDp'
	},
	/*
	{
		type: 'genre',
		name: 'World',
		image: assets.genre_world,
		color: 'linear-gradient(8deg, #ff9365 21%, #48f1bf 52%)',
		spotify_id: ''
	}
	*/
];

export const countries = [
	{value: 'AR', country: 'Argentina'},
	{value: 'AU', country: 'Australia'},
	{value: 'BR', country: 'Brazil'},
	{value: 'CL', country: 'Chile'},
	{value: 'CN', country: 'China'},
	{value: 'DK', country: 'Denmark'},
	{value: 'FR', country: 'France'},
	{value: 'DE', country: 'Germany'},
	{value: 'IT', country: 'Italy'},
	{value: 'IE', country: 'Ireland'},
	{value: 'ES', country: 'Spain'},
	{value: 'US', country: 'United States of America'},
	{value: 'AF', country: 'Afghanistan'},
	{value: 'AX', country: 'Åland Islands'},
	{value: 'AL', country: 'Albania'},
	{value: 'DZ', country: 'Algeria'},
	{value: 'AS', country: 'American Samoa'},
	{value: 'AD', country: 'Andorra'},
	{value: 'AO', country: 'Angola'},
	{value: 'AI', country: 'Anguilla'},
	{value: 'AQ', country: 'Antarctica'},
	{value: 'AG', country: 'Antigua and Barbuda'},
	{value: 'AM', country: 'Armenia'},
	{value: 'AW', country: 'Aruba'},
	{value: 'AT', country: 'Austria'},
	{value: 'AZ', country: 'Azerbaijan'},
	{value: 'BS', country: 'Bahamas'},
	{value: 'BH', country: 'Bahrain'},
	{value: 'BD', country: 'Bangladesh'},
	{value: 'BB', country: 'Barbados'},
	{value: 'BY', country: 'Belarus'},
	{value: 'BE', country: 'Belgium'},
	{value: 'BZ', country: 'Belize'},
	{value: 'BJ', country: 'Benin'},
	{value: 'BM', country: 'Bermuda'},
	{value: 'BT', country: 'Bhutan'},
	{value: 'BO', country: 'Bolivia'},
	{value: 'BA', country: 'Bosnia and Herzegovina'},
	{value: 'BW', country: 'Botswana'},
	{value: 'BV', country: 'Bouvet Island'},
	{value: 'BN', country: 'Brunei Darussalam'},
	{value: 'BG', country: 'Bulgaria'},
	{value: 'BF', country: 'Burkina Faso'},
	{value: 'BI', country: 'Burundi'},
	{value: 'CV', country: 'Cabo Verde'},
	{value: 'KH', country: 'Cambodia'},
	{value: 'CM', country: 'Cameroon'},
	{value: 'CA', country: 'Canada'},
	{value: 'KY', country: 'Cayman Islands'},
	{value: 'CF', country: 'Central African Republic'},
	{value: 'TD', country: 'Chad'},
	{value: 'CX', country: 'Christimas Island'},
	{value: 'CC', country: 'Cocos'},
	{value: 'CO', country: 'Colombia'},
	{value: 'KM', country: 'Comoros'},
	{value: 'CD', country: 'DR Congo'},
	{value: 'CG', country: 'Congo Republic'},
	{value: 'CK', country: 'Cook Islands'},
	{value: 'CR', country: 'Costa Rica'},
	{value: 'CI', country: 'Côte d\'Ivoire'},
	{value: 'HR', country: 'Croatia'},
	{value: 'CU', country: 'Cuba'},
	{value: 'CW', country: 'Curaçao'},
	{value: 'CY', country: 'Cyprus'},
	{value: 'CZ', country: 'Czechia'},
	{value: 'DJ', country: 'Djibouti'},
	{value: 'DM', country: 'Dominica'},
	{value: 'DO', country: 'Dominican Republic'},
	{value: 'EC', country: 'Ecuador'},
	{value: 'EG', country: 'Egypt'},
	{value: 'SV', country: 'El Salvador'},
	{value: 'GQ', country: 'Equatorial Guinea'},
	{value: 'ER', country: 'Eritrea'},
	{value: 'EE', country: 'Estonia'},
	{value: 'SZ', country: 'Eswatini'},
	{value: 'ET', country: 'Ethiopia'},
	{value: 'FK', country: 'Falkland Islands'},
	{value: 'FO', country: 'Faroe Islands'},
	{value: 'FJ', country: 'Fiji'},
	{value: 'FI', country: 'Finland'},
	{value: 'GF', country: 'French Guiana'},
	{value: 'PF', country: 'French Polynesia'},
	{value: 'TF', country: 'French Southern Territories'},
	{value: 'GA', country: 'Gabon'},
	{value: 'GM', country: 'Gambia'},
	{value: 'GE', country: 'Georgia'},
	{value: 'GH', country: 'Ghana'},
	{value: 'GI', country: 'Gibraltar'},
	{value: 'GR', country: 'Greece'},
	{value: 'GL', country: 'Greenland'},
	{value: 'GD', country: 'Grenada'},
	{value: 'GP', country: 'Guadeloupe'},
	{value: 'GU', country: 'Guam'},
	{value: 'GT', country: 'Guatemala'},
	{value: 'GG', country: 'Guernsey'},
	{value: 'GN', country: 'Guinea'},
	{value: 'GW', country: 'Guinea-Bissau'},
	{value: 'GY', country: 'Guyana'},
	{value: 'HT', country: 'Haiti'},
	{value: 'VA', country: 'Holy See'},
	{value: 'HN', country: 'Honduras'},
	{value: 'HK', country: 'Hong Kong'},
	{value: 'HU', country: 'Hungary'},
	{value: 'IS', country: 'Iceland'},
	{value: 'IN', country: 'India'},
	{value: 'ID', country: 'Indonesia'},
	{value: 'IR', country: 'Iran'},
	{value: 'IQ', country: 'Iraq'},
	{value: 'IM', country: 'Isle of Man'},
	{value: 'IL', country: 'Israel'},
	{value: 'JM', country: 'Jamaica'},
	{value: 'JP', country: 'Japan'},
	{value: 'JE', country: 'Jersey'},
	{value: 'JO', country: 'Jordan'},
	{value: 'KZ', country: 'Kazakhstan'},
	{value: 'KE', country: 'Kenya'},
	{value: 'KI', country: 'Kiribati'},
	{value: 'KP', country: 'North Korea'},
	{value: 'KR', country: 'South Korea'},
	{value: 'KW', country: 'Kuwait'},
	{value: 'KG', country: 'Kyrgyzstan'},
	{value: 'LA', country: 'Laos'},
	{value: 'LV', country: 'Latvia'},
	{value: 'LB', country: 'Lebanon'},
	{value: 'LS', country: 'Lesotho'},
	{value: 'LR', country: 'Liberia'},
	{value: 'LY', country: 'Libya'},
	{value: 'LI', country: 'Liechtenstein'},
	{value: 'LT', country: 'Lithuania'},
	{value: 'LU', country: 'Luxembourg'},
	{value: 'MO', country: 'Macao'},
	{value: 'MK', country: 'North Macedonia'},
	{value: 'MG', country: 'Madagascar'},
	{value: 'MW', country: 'Malawi'},
	{value: 'MY', country: 'Malasya'},
	{value: 'MV', country: 'Maldives'},
	{value: 'ML', country: 'Mali'},
	{value: 'MT', country: 'Malta'},
	{value: 'MH', country: 'Marshall Islands'},
	{value: 'MQ', country: 'Martinique'},
	{value: 'MR', country: 'Mauritania'},
	{value: 'MU', country: 'Mauritius'},
	{value: 'YT', country: 'Mayotte'},
	{value: 'MX', country: 'Mexico'},
	{value: 'FM', country: 'Micronesia'},
	{value: 'MD', country: 'Moldova'},
	{value: 'MC', country: 'Monaco'},
	{value: 'MN', country: 'Mongolia'},
	{value: 'ME', country: 'Montenegro'},
	{value: 'MS', country: 'Montserrat'},
	{value: 'MA', country: 'Morocco'},
	{value: 'MZ', country: 'Mozambique'},
	{value: 'MM', country: 'Myanmar'},
	{value: 'NA', country: 'Namibia'},
	{value: 'NR', country: 'Nauru'},
	{value: 'NP', country: 'Nepal'},
	{value: 'NL', country: 'Netherlands'},
	{value: 'NC', country: 'New Caledonia'},
	{value: 'NZ', country: 'New Zealand'},
	{value: 'NI', country: 'Nicaragua'},
	{value: 'NE', country: 'Niger'},
	{value: 'NG', country: 'Nigeria'},
	{value: 'NU', country: 'Niue'},
	{value: 'NF', country: 'Norfolk Island'},
	{value: 'MP', country: 'Northern Mariana Islands'},
	{value: 'NO', country: 'Norway'},
	{value: 'OM', country: 'Oman'},
	{value: 'PK', country: 'Pakistan'},
	{value: 'PW', country: 'Palau'},
	{value: 'PS', country: 'Palestine'},
	{value: 'PA', country: 'Panama'},
	{value: 'PG', country: 'Papua New Guinea'},
	{value: 'PY', country: 'Paraguay'},
	{value: 'PE', country: 'Peru'},
	{value: 'PH', country: 'Philippines'},
	{value: 'PN', country: 'Pitcairn'},
	{value: 'PL', country: 'Poland'},
	{value: 'PT', country: 'Portugal'},
	{value: 'PR', country: 'Puerto Rico'},
	{value: 'QA', country: 'Qatar'},
	{value: 'RE', country: 'Réunion'},
	{value: 'RO', country: 'Romania'},
	{value: 'RU', country: 'Russia'},
	{value: 'RW', country: 'Rwanda'},
	{value: 'BL', country: 'Saint Barthélemy'},
	{value: 'KN', country: 'Saint Kitts and Nevis'},
	{value: 'LC', country: 'Saint Lucia'},
	{value: 'MF', country: 'Saint Martin'},
	{value: 'PM', country: 'Saint Pierre and Miquelon'},
	{value: 'VC', country: 'Saint Vincent and the Grenadines'},
	{value: 'WS', country: 'Samoa'},
	{value: 'SM', country: 'San Marino'},
	{value: 'ST', country: 'Sao Tome and Principe'},
	{value: 'SA', country: 'Saudi Arabia'},
	{value: 'SN', country: 'Senegal'},
	{value: 'RS', country: 'Serbia'},
	{value: 'SC', country: 'Seychelles'},
	{value: 'SL', country: 'Sierra Leone'},
	{value: 'SG', country: 'Singapore'},
	{value: 'SX', country: 'Sint Maarten'},
	{value: 'SK', country: 'Slovakia'},
	{value: 'SI', country: 'Slovenia'},
	{value: 'SB', country: 'Solomon Islands'},
	{value: 'SO', country: 'Somalia'},
	{value: 'ZA', country: 'South Africa'},
	{value: 'GS', country: 'South Georgia'},
	{value: 'SS', country: 'South Sudan'},
	{value: 'LK', country: 'Sri Lanka'},
	{value: 'SD', country: 'Sudan'},
	{value: 'SR', country: 'Suriname'},
	{value: 'SJ', country: 'Svalbard and Jan Mayen'},
	{value: 'SE', country: 'Sweden'},
	{value: 'CH', country: 'Switzerland'},
	{value: 'SY', country: 'Syrian Arab'},
	{value: 'TW', country: 'Taiwan'},
	{value: 'TJ', country: 'Tajikistan'},
	{value: 'TZ', country: 'Tanzania'},
	{value: 'TH', country: 'Thailand'},
	{value: 'TL', country: 'Timor-Leste'},
	{value: 'TG', country: 'Togo'},
	{value: 'TK', country: 'Tokelau'},
	{value: 'TO', country: 'Tonga'},
	{value: 'TT', country: 'Trinidad and Tobago'},
	{value: 'TN', country: 'Tunisia'},
	{value: 'TR', country: 'Turkey'},
	{value: 'TM', country: 'Turkmenistan'},
	{value: 'TC', country: 'Turks and Caicos Islands'},
	{value: 'TV', country: 'Tuvalu'},
	{value: 'UG', country: 'Uganda'},
	{value: 'UA', country: 'Ukraine'},
	{value: 'AE', country: 'United Arab Emirates'},
	{value: 'GB', country: 'United Kingdom'},
	{value: 'UY', country: 'Uruguay'},
	{value: 'UZ', country: 'Uzbekistan'},
	{value: 'VU', country: 'Vanuatu'},
	{value: 'VE', country: 'Venezuela'},
	{value: 'VN', country: 'Vietmam'},
	{value: 'VG', country: 'Virgin Islands (British)'},
	{value: 'VI', country: 'Virgin Islands (U.S.)'},
	{value: 'WF', country: 'Wallis and Futuna'},
	{value: 'EH', country: 'Western Sahara'},
	{value: 'YE', country: 'Yemen'},
	{value: 'ZM', country: 'Zambia'},
	{value: 'ZW', country: 'Zimbabwe'}
];
