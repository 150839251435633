import React, { Component } from 'react';
import { connect } from 'react-redux';

// CSS Imports
import './styles/DesktopHome.css';

// Assets Imports
import { bg_lp_desk_app, app_store_button, google_play_button } from '@Assets';

class DesktopHome extends Component {
	render() {
		return (
			<div className="app-desktop-background" style={{ position: 'relative' }}>
				<img src={bg_lp_desk_app} alt="" style={{ position: 'absolute', left: '0', right: '0', width: '100%', height: '100%', objectFit: 'cover', zIndex: '-1' }}/>
				<div className="app-desktop-container">
					<div className="app-desktop-content">
						<div className="app-desktop-col">
							<div className="app-desktop-title">Discover music.</div>
							<div className="app-desktop-text">
								<div className="app-desktop-text-details">Are you looking for that next song you will be hearing in loop for days in a row? </div>
								<div className="app-desktop-text-details">We’re here to help ;)</div>
							</div>
							<div className='app-desktop-buttons'>
								<a href={this.props.version.ios}>
									<img className="app-desktop-store" src={app_store_button} alt="app-store-button"/>
								</a>
								<a href={this.props.version.android}>
									<img className="app-desktop-store" src={google_play_button} alt="google-play-button"/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		version: state.GlobalReducer.version
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopHome);