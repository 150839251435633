import React from 'react';
import Lottie from 'react-lottie-player';
import Slider from 'react-slick';

// Components Imports
import Haptic from '@components/haptic.js';
import Bubbles from '@components/Bubbles.js';

// Import assets and style
import './styles/PremiumVariations.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import badge_sparkle_animation from '../assets/badge_sparkle_animation.json';
import overlay_pattern_a from '../assets/premium/overlay-pattern-a.png';
import overlay_hand_badge from '../assets/premium/overlay-image-e.png';
import overlay_invite_title from '../assets/premium/overlay-invite-title.png';
import overlay_require_title from '../assets/premium/overlay-require-title.png';
import overlay_invite_title_small from '../assets/premium/overlay-invite-title-small.png';
import overlay_require_title_small from '../assets/premium/overlay-require-title-small.png';
import badge_with_sparkles from '../assets/premium/badge-with-sparkles.png';
import blocklist_icon from '../assets/blocklist-icon.svg';

// Components and Views Imports

const features = [
	{icon: 'insights', title: 'Perfect Match', description: 'Get unique recommendations that fit perfectly into your playlists.', class: 'material-icons-sharp'},
	{icon: 'app_blocking', title: 'No sponsored content', description: 'Get rid of ads and sponsored songs in your discovery!', class: 'material-icons-sharp'},
	{icon: 'library_add', title: 'Playlist Generator', description: 'Automatically generated playlists full of songs that fit your taste!', class: 'material-icons'},
	{icon: 'slideshow', title: 'Unlimited Autoplay', description: 'Don\'t stop discovering, listen as much as you want!', class: 'material-icons'},
	{icon: 'sync', title: 'Sync your playlists', description: 'Automatically add songs to your favorite playlists on streaming platforms!', class: 'material-icons'},
	{icon: 'undo', title: 'Unlimited rollbacks', description: 'No more accidents, rollback to that track you just missed as much as you want!', class: 'material-icons'},
	{icon: 'thumbs_up_down', title: 'Unlimited swipes/blocks', description: 'Discover on your own pace!', class: 'material-icons'},
	{icon: 'play_circle_filled', title: 'Background play', description: 'Keep listening while you use other apps!', class: 'material-icons'},
	{icon: 'photo_filter', title: 'Fine Tuning', description: 'Filter songs based on popularity, mood, tempo, etc!', class: 'material-icons'},
	{icon: 'block', image: blocklist_icon, title: 'Blocklist', description: 'No more recommendations from artists and albums you didn\'t like'}
];

function PremiumOverlayDefault(data){
	const overlay_title = data.state.overlay_title || 'Discover new music with no restrictions!';
	const overlay_subtitle = data.state.overlay_subtitle || 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!';
	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE NOW FOR $' + data.props.monthly_price + '/mo';
	const filtered_features = data.state.display_content === 'invite' ? features : features.filter(item => item.icon !== data.state.feature.icon);

	return (
		<div className='premium-overlay' onClick={data.reset_state}>
			<div className='premium-overlay-badge'>
				<Lottie
					loop={true}
					play={true}
					animationData={badge_sparkle_animation}
					direction={1}
				/>
			</div>
			<div
				className='premium-overlay-container'
				onClick={(e) => e.stopPropagation()}
				style={{ borderTop: data.state.display_content ? 'solid 4px #707070' : 'solid 0px #707070' }}
			>
				<div className='premium-overlay-container-button'>
					<span className='material-icons' onClick={data.reset_state}>close</span>
				</div>
				<div className='premium-overlay-content'>
					{data.state.display_content === 'invite' &&
						<React.Fragment>
							<div className='premium-overlay-title'>{overlay_title}</div>
							<div className='premium-overlay-subtitle'>{overlay_subtitle}</div>
						</React.Fragment>
					}
					{data.state.display_content === 'require' &&
						<React.Fragment>
							<div className='premium-overlay-title'>{data.state.feature.title}</div>
							{data.props.countdown_to_allow_recommendation &&
								<div className='premium-overlay-subtitle' style={{color: 'dbdbdb'}}>
									You can wait more  
									<span> {data.props.countdown_to_allow_recommendation.hours}</span>h 
									<span> {data.props.countdown_to_allow_recommendation.minutes}</span>min 
									to use this feature again or:
								</div>
							}
							{!data.props.countdown_to_allow_recommendation &&
								<div className='premium-overlay-subtitle'>Magroove Premium is here to help you discover much more!</div>
							}
							<div className='premium-overlay-benefit' style={{margin: '20px 0 0'}}>
								<div className='premium-overlay-benefit-icon material-icons'>{data.state.feature.icon}</div>
								<div className='premium-overlay-benefit-text'>{data.state.feature.description}</div>
							</div>
						</React.Fragment>
					}
					<Haptic disabled={data.state.button_disabled}>
						<div className='premium-overlay-button' onClick={data.state.button_disabled ? {} : () => data.display_plans()} style={data.state.button_disabled ? {backgroundColor: '#9e9e9e', color: '#fff'} : {}}><span></span>{button_text}</div>
					</Haptic>
					<div className='premium-overlay-section'>Included in your subscription:</div>
					<div className='premium-overlay-items'>
						{filtered_features.map((item, index) => (
							<div key={index} className='premium-overlay-benefit'>
								{item.image ?
									<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
									:
									<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
								}
								<div className='premium-overlay-benefit-text'>
									<div className='premium-overlay-benefit-title'>{item.title}</div>
									<div className='premium-overlay-benefit-description'>{item.description}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayA(data){
	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE NOW FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'or Monthly for $' + data.props.monthly_price + '/month';
	const title = data.state.display_content === 'invite' ? 'Discover much more with a' : 'This action requires a';

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-a' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-a-background'>
					<img src={overlay_pattern_a} alt='Patern' />
				</div>
				<div className='premium-overlay-a-content'>
					<div className='premium-overlay-a-header'>
						<div className='premium-overlay-a-header-subtitle'>{title}</div>
						<div className='premium-overlay-a-header-title'>PREMIUM</div>
						<div className='premium-overlay-a-header-subtitle'>subscription</div>
						<div className='premium-overlay-a-header-close material-icons' onClick={data.reset_state}>close</div>
					</div>
					<div className='premium-overlay-a-features'>
						{features.map((item, index) => (
							<div key={index} className='premium-overlay-a-item'>
								<div className='premium-overlay-a-item-icon material-icons'>check</div>
								<div className='premium-overlay-a-item-text'>{item.title}</div>
							</div>
						))}
					</div>
					<div className='premium-overlay-a-buttons'>
						<div className='premium-overlay-a-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
						{secondary_button_text &&
							<div className='premium-overlay-a-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayB(data){
	const features_ref = React.useRef();

	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE NOW FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'or Monthly for $' + data.props.monthly_price + '/month';
	const title = data.state.display_content === 'invite' ? 'Discover much more with a' : 'This action requires a';

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-b' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-b-main'>
					<div className='premium-overlay-b-header'>
						<div className='premium-overlay-b-header-subtitle-1'>{title}</div>
						<div className='premium-overlay-b-header-title'>PREMIUM</div>
						<div className='premium-overlay-b-header-subtitle-2'>SUBSCRIPTION</div>
						<div className='premium-overlay-b-header-close material-icons' onClick={data.reset_state}>close</div>
					</div>
					<div className='premium-overlay-b-image'>
						<div className='premium-overlay-b-buttons'>
							<div className='premium-overlay-b-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
							{secondary_button_text &&
								<div className='premium-overlay-b-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
							}
							<div className='premium-overlay-b-more-button' onClick={() => features_ref.current.scrollIntoView({ behavior: 'smooth' })}>
								<div className='premium-overlay-b-more-button-text'>Scroll down to check all perks</div>
								<div className='premium-overlay-b-more-button-icon material-icons'>expand_more</div>
							</div>
						</div>
					</div>
				</div>
				<div ref={features_ref} className='premium-overlay-b-features'>
					<div className='premium-overlay-b-features-pattern'></div>
					{features.map((item, index) => (
						<div key={index} className='premium-overlay-b-benefit'>
							{item.image ?
								<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
								:
								<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
							}
							<div className='premium-overlay-benefit-text'>
								<div className='premium-overlay-benefit-title'>{item.title}</div>
								<div className='premium-overlay-benefit-description'>{item.description}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayC(data){
	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE YEARLY FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'SUBSCRIBE MONTLHY FOR $' + data.props.monthly_price + '/mo';
	const bubble_lines = window.innerHeight > 670 ? window.innerHeight > 760 ? 4 : 3 : 2;
	const title = data.state.display_content === 'invite' ? 'Discover much more with a' : 'This action requires a';

	const settings = {
		acessibility: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 2,
	};

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-c' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-c-bubbles'>
					<div className='premium-overlay-c-bubbles-item'>
						<Bubbles lines={bubble_lines} />
					</div>
					<div className='premium-overlay-c-bubbles-shadow'></div>
					<div className='premium-overlay-c-bubbles-close material-icons' onClick={data.reset_state}>close</div>
				</div>
				<div className='premium-overlay-c-main'>
					<div className='premium-overlay-c-header'>
						<div className='premium-overlay-c-header-image'>
							<img src={badge_with_sparkles} alt='Orange premium badge with sparkles' />
						</div>
						<div className='premium-overlay-c-header-text'>
							<div className='premium-overlay-c-header-subtitle-1'>{title}</div>
							<div className='premium-overlay-c-header-title'>PREMIUM</div>
							<div className='premium-overlay-c-header-subtitle-2'>SUBSCRIPTION</div>
						</div>
					</div>
					<div className='premium-overlay-c-features-title'>Our Membership includes:</div>
					<div className='premium-overlay-c-features-container'>
						<div className='premium-overlay-c-features'>
							<Slider {...settings}>
								{features.map((item, index) => (
									<div key={index} className='premium-overlay-c-benefit'>
										{item.image ?
											<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
											:
											<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
										}
										<div className='premium-overlay-benefit-text'>
											<div className='premium-overlay-benefit-title'>{item.title}</div>
											<div className='premium-overlay-benefit-description'>{item.description}</div>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
					<div className='premium-overlay-c-buttons'>
						<div className='premium-overlay-c-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
						{secondary_button_text &&
							<React.Fragment>
								<div className='premium-overlay-c-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
								<div className='premium-overlay-c-no-button-text' onClick={data.reset_state}>No, thanks.</div>
							</React.Fragment>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayD(data){
	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE YEARLY FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'SUBSCRIBE MONTLHY FOR $' + data.props.monthly_price + '/mo';
	const title = data.state.display_content === 'invite' ? 'Discover much more with a' : 'This action requires a';

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-d' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-d-close-container'>
					<div className='premium-overlay-d-close material-icons' onClick={data.reset_state}>close</div>
				</div>
				<div className='premium-overlay-d-header'>
					<div className='premium-overlay-d-header-image'>
						<img src={badge_with_sparkles} alt='Orange premium badge with sparkles' />
					</div>
					<div className='premium-overlay-d-header-text'>
						<div className='premium-overlay-d-header-subtitle-1'>{title}</div>
						<div className='premium-overlay-d-header-title'>PREMIUM</div>
						<div className='premium-overlay-d-header-subtitle-2'>SUBSCRIPTION</div>
					</div>
				</div>
				<div className='premium-overlay-d-content'>
					<div className='premium-overlay-d-features-title'>Our Membership includes:</div>
					<div className='premium-overlay-d-features'>
						{features.map((item, index) => (
							<div key={index} className='premium-overlay-d-benefit'>
								{item.image ?
									<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
									:
									<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
								}
								<div className='premium-overlay-benefit-text'>
									<div className='premium-overlay-benefit-title'>{item.title}</div>
								</div>
							</div>
						))}
					</div>
					<div className='premium-overlay-d-buttons'>
						<div className='premium-overlay-d-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
						{secondary_button_text &&
							<React.Fragment>
								<div className='premium-overlay-d-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
								<div className='premium-overlay-d-no-button-text' onClick={data.reset_state}>No, thanks.</div>
							</React.Fragment>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayE(data){
	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE YEARLY FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'SUBSCRIBE MONTLHY FOR $' + data.props.monthly_price + '/mo';
	const title = data.state.display_content === 'invite' ? 'Discover much more with a' : 'This action requires a';

	const settings = {
		acessibility: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 2,
	};

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-e' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-e-image'>
					<div className='premium-overlay-e-image-item'>
						<img src={overlay_hand_badge} alt='Hand holding a premium badge' />
					</div>
					<div className='premium-overlay-e-image-close material-icons' onClick={data.reset_state}>close</div>
				</div>
				<div className='premium-overlay-e-main'>
					<div className='premium-overlay-e-header'>
						<div className='premium-overlay-e-header-text'>
							<div className='premium-overlay-e-header-subtitle-1'>{title}</div>
							<div className='premium-overlay-e-header-title'>PREMIUM</div>
							<div className='premium-overlay-e-header-subtitle-2'>SUBSCRIPTION</div>
						</div>
					</div>
					<div className='premium-overlay-e-features-title'>Our Membership includes:</div>
					<div className='premium-overlay-e-features-container'>
						<div className='premium-overlay-e-features'>
							<Slider {...settings}>
								{features.map((item, index) => (
									<div key={index} className='premium-overlay-e-benefit'>
										{item.image ?
											<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
											:
											<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
										}
										<div className='premium-overlay-benefit-text'>
											<div className='premium-overlay-benefit-title'>{item.title}</div>
											<div className='premium-overlay-benefit-description'>{item.description}</div>
										</div>
									</div>
								))}
							</Slider>
						</div>
					</div>
					<div className='premium-overlay-e-buttons'>
						<div className='premium-overlay-e-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
						{secondary_button_text &&
							<React.Fragment>
								<div className='premium-overlay-e-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
								<div className='premium-overlay-e-no-button-text' onClick={data.reset_state}>No, thanks.</div>
							</React.Fragment>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

function PremiumOverlayF(data){
	const features_ref = React.useRef();

	const button_text = data.state.button_disabled ? 'ALREADY SUBSCRIBED' : 'SUBSCRIBE NOW FOR $' + data.props.yearly_price + '/year';
	const secondary_button_text = data.state.button_disabled ? false : 'or Monthly for $' + data.props.monthly_price + '/month';

	return (
		<div className='premium-overlay-wrapper'>
			<div className='premium-overlay-f' style={{ top: data.props.safe_area_top }}>
				<div className='premium-overlay-f-main'>
					<div className='premium-overlay-f-title'>
						{window.innerWidth/(window.innerHeight - data.props.safe_area_top) > 0.57 ?
							<img src={data.state.display_content === 'invite' ? overlay_invite_title_small : overlay_require_title_small} alt='Premium word' />
							:
							<img src={data.state.display_content === 'invite' ? overlay_invite_title : overlay_require_title} alt='Premium word' />
						}
						<div className='premium-overlay-f-close material-icons' onClick={data.reset_state}>close</div>
					</div>
					<div className='premium-overlay-f-buttons'>
						<div className='premium-overlay-f-main-button' onClick={data.state.button_disabled ? () => {} : () => data.order('yearly')}>{button_text}</div>
						{secondary_button_text &&
							<div className='premium-overlay-f-secondary-button' onClick={() => data.order('monthly')}>{secondary_button_text}</div>
						}
						<div className='premium-overlay-f-more-button' onClick={() => features_ref.current.scrollIntoView({ behavior: 'smooth' })}>
							<div className='premium-overlay-f-more-button-text'>Scroll down to check all perks</div>
							<div className='premium-overlay-f-more-button-icon material-icons'>expand_more</div>
						</div>
					</div>
				</div>
				<div ref={features_ref} className='premium-overlay-f-features'>
					{features.map((item, index) => (
						<div key={index} className='premium-overlay-f-benefit'>
							{item.image ?
								<img className='premium-overlay-benefit-image' src={item.image} alt='Feature' />
								:
								<div className={'premium-overlay-benefit-icon '+ item.class}>{item.icon}</div>
							}
							<div className='premium-overlay-benefit-text'>
								<div className='premium-overlay-benefit-title'>{item.title}</div>
								<div className='premium-overlay-benefit-description'>{item.description}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export {
	PremiumOverlayDefault,
	PremiumOverlayA,
	PremiumOverlayB,
	PremiumOverlayC,
	PremiumOverlayD,
	PremiumOverlayE,
	PremiumOverlayF
};