import React from 'react';
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';

// CSS Imports
import './styles/ExportTip.css';

export default class ExportTip extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			show: true,
			step: 0
		};
	}

	render(){
		if (this.props.current_page === '/my-list/') {
			/*eslint-disable-next-line*/
			this.state.step = 1;
		} else if(this.props.current_page === '/') {
			/*eslint-disable-next-line*/
			this.state.step = 0;
		} else {
			/*eslint-disable-next-line*/
			this.state.show = false;
		}
		if (this.state.show) {
			return(
				<section className={this.state.step ? 'export-tip-focus' : 'export-tip-regular'}>
					<ClickAwayListener onClickAway={() => 
					{if(this.state.step){
						this.setState({show: false});
					}}}>
						<div className={`export-tip tip-${this.state.step}`}
							style={this.props.is_premium ? {left: 'calc(((100% - 130px) / 8 ) * 2 - 32px)'} : {}}
						>
							<div className='export-tip-content'>
								<span className='material-icons export-tip-close-icon' onClick={() => this.setState({show: false})}>close</span>
							
								<div className='export-tip-text'>
									<p>Song Exporting</p>

									{this.state.step === 0 ?
										<p>Did you know you can export your likes to other streaming apps?</p>
										:
										<p>Press the ⁝ icon to open a menu with lots of useful options!</p>
									}	
								</div>

								<div className='export-tip-button'>
									<div className='export-tip-button-slider'>
										{[...Array(2).keys()].map((index) => {
											var classname = index === this.state.step ? 'active' : 'deactive';
											classname = 'export-tip-' + classname;
											return(<div className={classname} key={index}></div>);
										})}
									</div>
									{!this.state.step ?
										<Link
											to='/my-list/'
											className='export-tip-button-text'
											onClick={() => this.setState({step: 1})}
										>
											<p className='export-tip-button-text'>Next</p>
										</Link>
										: 
										<p className='export-tip-button-text'  onClick={()=> this.setState({show: false})}>Finish</p>
									}		
								</div>
							</div>
		
							<div className={`export-tip-triangle triangle-tip-${this.state.step}`}></div>
						</div>
					</ClickAwayListener>
				</section>
			);
		}
		return(null);
	}
}
