import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { Capacitor } from '@capacitor/core';

// CSS Imports
import './styles/Settings.css';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Import the controllers
import subscription from '@Subscription';

class Settings extends Component {
	render() {
		return (
			<div className="settings-main-container">

				<div className="settings-content">
					<div className="settings-content-box">
						{this.props.user_token &&
							<React.Fragment>
								<div className="settings-content-box-title">Export</div>
								<div className="settings-content-box-subtitle" onClick={() => store_set('select_mode', true)}>
									<Link to='/my-list/'>
										Export my tracks
									</Link>	
								</div>
								<div className="settings-content-box-subtitle">
									<Link to='/sync-playlists/'>
										Sync with playlist
									</Link>
								</div>
							</React.Fragment>
						}
					</div>
					<div className="settings-content-box">
						<div className="settings-content-box-title">About</div>
						<div className="settings-content-box-subtitle">
							<Link to='/privacy-policy/' >
								Privacy Policy
							</Link>
						</div>
						<div className="settings-content-box-subtitle">
							<Link to='/terms-of-service/'>
								Terms of Service
							</Link>
						</div>
						<div className="settings-content-box-subtitle">
							<Link to='/contact-us/'>
								Contact us
							</Link>
						</div>
						<div className="settings-content-box-subtitle">
							<Link to='/version/'>
								Version
							</Link>
						</div>
					</div>
					<div className="settings-content-box">
						<div className="settings-content-box-title">Account</div>
						{this.props.user_token &&
							<React.Fragment>
								{subscription.is_valid() ?
									<div className="settings-content-box-subtitle">
										<Link to='/subscriptions/'>
											Subscription
										</Link>	
									</div>
									: Capacitor.getPlatform() === 'ios' && subscription.can_restore() ?
										<div className="settings-content-box-subtitle" onClick={() => subscription.restore()}>
											Restore premium
										</div>
										:
										<div className="settings-content-box-subtitle" onClick={() => subscription.invite('Discover new music with no restrictions!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'settings_button')}>
											Try our premium!
										</div>
								}
								<div className="settings-content-box-subtitle">
									{subscription.is_valid() ?
										<Link to='/fine-tunning/'>
											Fine Tune Experience
										</Link>	
										:
										<div className="settings-content-box-subtitle" onClick={() => subscription.invite('Discover much more and get extra control over your songs!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'fine_tunning_more')}>
											Fine Tune Experience
										</div>
									}
								</div>
								<div className="settings-content-box-subtitle">
									<Link to='/linked-accounts/'>
										Linked accounts
									</Link>	
								</div>
								<div className="settings-content-box-subtitle" onClick={() => store_set('secondary_overlay', 'referral')}>
									Referral link
								</div>
								<div className="settings-content-box-subtitle" onClick={() => store_set('active_overlay', 'logout')}>Logout</div>
								<div className="settings-content-box-subtitle delete-account">
									<Link to='/delete-account/'>
										Delete account
									</Link>
								</div>
							</React.Fragment>
						}
						{!this.props.user_token &&
							<div className="settings-content-box-subtitle">
								<Link to='/login/'>
									Login
								</Link>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		user_token: state.GlobalReducer.user_token,
		premium: state.GlobalReducer.premium_subsription_expires_at
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Settings));