import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@magroove/magroove-ui';
import Snackbar from '@material-ui/core/Snackbar';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import CircularSlider from '@fseehawer/react-circular-slider';

// CSS Imports
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/FineTunning.css';

// Components and Views Imports
import Slider from 'react-slick';
import { redirect } from '@routes/Routes.js';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Constants import
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Import controllers
import subscription from '@Subscription';

const fine_tunning_parameters = {
	'target_popularity': 'Popularity',
	'target_valence': 'Happiness',
	'target_energy': 'Energy',
	'target_danceability': 'Danceability',
	'target_acousticness': 'Acousticness'
};


class FineTunning extends Component {
	constructor(props){
		super(props);
		this.state = {
			fine_tunning: {},
			is_dragging: false,
			snackbar_status: false,
			snackbar_message: ''
		};

		const values = this.props.fine_tunning || {};
		this.state.fine_tunning.target_acousticness = values.target_acousticness || 50;
		this.state.fine_tunning.target_danceability = values.target_danceability || 50;
		this.state.fine_tunning.target_energy = values.target_energy || 50;
		this.state.fine_tunning.target_valence = values.target_valence || 50;
		this.state.fine_tunning.target_popularity = values.target_popularity || 50;

		this.save_fine_tunning = this.save_fine_tunning.bind(this);
		this.change_parameter = this.change_parameter.bind(this);
		this.fine_tunning = this.state.fine_tunning;
		this.restore_fine_tunning = this.restore_fine_tunning.bind(this);
		this.update_redux = this.update_redux.bind(this);

		// Fine Tune is a premium feature.
		// Redirect to the search page and display invitation for premium if the user is not premium yet.
		if (!subscription.is_valid()){
			redirect('/search?invprem=1');
		}
	}

	save_fine_tunning(){
		this.setState({snackbar_message: 'Success!'});
		this.setState({snackbar_status: true});

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_save_fine_tunning,
			data: {fine_tunning: JSON.stringify(this.state.fine_tunning)}
		});
	}

	change_parameter(key, value){
		this.fine_tunning[key] = value; 
	}

	update_redux(){
		store_set('fine_tunning', this.fine_tunning);
	}

	componentDidMount(){
		// Prevent cards from scrolling when user is changing a Fine Tune value
		const element = document.getElementsByClassName('slick-track');
		if (element){
			element[0].addEventListener('touchstart', function(event){
				const slider = document.querySelectorAll('.fine-tunning-slider-card-control div div circle');
				for (var item of slider){
					if (item.contains(event.target)){
						this.setState({ is_dragging: true }); return;
					}
				}
			}.bind(this));
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// If use stops dragging the circular control (for any card), we just update the parameters in redux
		if (prevState.is_dragging && !this.state.is_dragging){
			this.update_redux();
		}
	}

	restore_fine_tunning(){
		let default_fine_tunning = {...fine_tunning_parameters};
		// eslint-disable-next-line
		for (let key in default_fine_tunning){
			default_fine_tunning[key] = 50;
		}
		this.setState({fine_tunning: default_fine_tunning});
		this.fine_tunning = default_fine_tunning;
		this.update_redux();
	}

	render() {
		const settings = {
			acessibility: false,
			arrows: false,
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: '30px',
			swipe: !this.state.is_dragging
		};

		return (
			<div className='fine-tunning-main-container'>
				<div className='fine-tunning-title'>Fine Tuning</div>
				<div className='fine-tunning-subtitle'>Personalize  your recommendation parameters according to your taste!</div>
				<div className='fine-tunning-slider'>
					<Slider {...settings} >
						{/* eslint-disable-next-line no-unused-vars */}
						{Object.entries(this.state.fine_tunning).map((item, index) => {
							let key = item[0];
							let parameter = item[1];
							return (
								<div key={key} className='fine-tunning-slider-card'>
									<div className='fine-tunning-slider-card-title'>{fine_tunning_parameters[key]}</div>
									<div className='fine-tunning-slider-card-control'>
										<CircularSlider
											knobColor='#ffffff'
											knobSize={50}
											knobPosition='left'
											progressColorFrom='#ec6b43'
											progressColorTo='#ec6b43'
											progressSize={24}
											label=' '
											trackColor='#ffffff'
											labelColor='ffffff'
											trackSize={24}
											dataIndex={parameter}
											min={0}
											max={100}
											width={200}
											onChange={ value => this.change_parameter(key, value) }
											isDragging={ value => this.setState({is_dragging: value}) }
										/>
									</div>
								</div>
							);
						})}
					</Slider>
				</div>
				<div className='fine-tunning-button'>
					<Button
						value='RESTORE DEFAULTS' size='small'
						className=''
						onClick={this.restore_fine_tunning}
						fullWidth={true}	
						main_color='rgba(236, 107, 67, 0.15)'
						secondary_color='#ec6b43'
					/>
					<Button
						value='SAVE' size='small'
						className=''
						onClick={this.save_fine_tunning}
						fullWidth={true}
					/>
				</div>
				<Snackbar
					open={this.state.snackbar_status}
					message={this.state.snackbar_message}
					autoHideDuration={2000}
					onClose={() => this.setState({snackbar_status: false})}
				/>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		fine_tunning: state.GlobalReducer.fine_tunning
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(FineTunning));