import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	TelegramShareButton,
	VKShareButton,
} from 'react-share';

// Capacitor Imports

import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/react';
import { AppLauncher } from '@capacitor/app-launcher';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { get_shareable_my_obsession, share_data_url } from '@components/ShareImage.js';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import posed from 'react-pose';
import Haptic from '@components/haptic.js';
import Card from '@components/Card.js';
import ObsessionPopup from '@components/ObsessionPopup.js';
import Ripples from 'react-ripples';
import { redirect } from '@routes/Routes';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Import the controllers
import player from '@Player';
import list from '@List';
import queue from '@Queue';
import export_track from'@Export';
import user from '@User';
import playlist_manager from '@/PlaylistsController.js';
import app_analytics from '@Analytics';
import subscription from '@Subscription';

import { CircularProgress } from '@material-ui/core';

// Import the event bus
import event_bus from '@Eventbus';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Constant Imports
import * as constants from '@constants';

// ========================================================================================================================================
// Create your functions here =============================================================================================================
// ========================================================================================================================================
export function display_loading_overlay(text = 'Loading...', top_text = false){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

const openCapacitorSite = async (url) => {
	await Browser.open({ url: url });
};

const openYoutubeApp = async (url) => {
	// If the app can be opened, open it. Otherwise, open the web page.
	if (isPlatform('android') && await AppLauncher.canOpenUrl({ url: 'com.google.android.youtube' })){
		await AppLauncher.openUrl({ url: url });
	} else if (isPlatform('ios') && await AppLauncher.canOpenUrl({ url: 'com.apple.youtube' })){
		await AppLauncher.openUrl({ url: url });
	} else {
		await Browser.open({ url: url });
	}
};

const PosedTrackOverlay = posed.div({
	full: {
		height: ({height}) => { return height; },
		borderTop: 'solid 4px #707070'
	},
	feedback:{
		height: ({height}) => { return height; },
		borderTop: 'solid 4px #707070'
	},
	change: {
		height: ({height}) => { return height; },
		borderTop: 'solid 4px #707070'
	},
	playlist: {
		height: ({height}) => { return height; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

// ========================================================================================================================================
// Create your component here =============================================================================================================
// ========================================================================================================================================
class ItemOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			pose_type: 'hidden',
			pose_height: 0,
			loading_links: false,
			is_following_artist: false,
			export_all: false,
			openShareObsession: false,
			obsession_card_data: null,
			user_info: store_get('user')
		};

		this.my_obsession_clone_slot = React.createRef();
		this.share_obsession_ref = React.createRef();

		this.export = this.export.bind(this);
		this.export_to_apple = this.export_to_apple.bind(this);
		this.export_to_youtube = this.export_to_youtube.bind(this);
		this.handle_share_my_obsession = this.handle_share_my_obsession.bind(this);

		this.remove_recommendation = this.remove_recommendation.bind(this);
		this.reset_search_recommendation = this.reset_search_recommendation.bind(this);
		this.blocklist = this.blocklist.bind(this);
		
		this.reset_search_recommendation();

		event_bus.on('is-following-artist', (hash_id, value) => {
			const { artist_info } = this.props;
			if (artist_info.spotify_id === hash_id || artist_info.hash_id === hash_id){
				this.setState({is_following_artist: value}); 
			}
		});
	}

	export(playlist){
		const all_tracks = this.state.export_all;
		if (all_tracks || this.props.likes.length >= 50) {
			if (this.props.streaming_overlay === 'spotify'){
				export_track.add_sync_to_spotify(playlist);
			}
			if (this.props.streaming_overlay === 'deezer'){
				export_track.add_sync_to_deezer(playlist);
			}	
		}
		else{
			const tracks = this.props.select_mode  ? this.props.tracks_selected : all_tracks ? this.props.likes : [this.props.song_info];
			if (this.props.streaming_overlay === 'spotify'){
				export_track.add_to_spotify(tracks, playlist);
			}
			if (this.props.streaming_overlay === 'deezer'){
				export_track.add_to_deezer(tracks, playlist);
			}
		}
	}

	export_to_apple(){
		display_loading_overlay();
		const song = this.props.song_info;

		// Check if itunes_link has been fetched already
		if (song.itunes_link){
			openCapacitorSite(song.itunes_link);
			store_set('loading_overlay_display', false);
			return;
		}

		// If not, fetch it
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_song_links,
			data: {song: JSON.stringify(song)},
			success: function(data){
				if (data.status === 'success'){
					song.spotify_link = data.final_dict.spotify.song;
					song.itunes_link = data.final_dict.itunes.release;
					song.deezer_link = data.final_dict.deezer.song;
					song.youtube_link = data.final_dict.youtube.song;
					store_set('song_info', song);

					// Open the link if it exists, otherwise show a snackbar
					if (data.final_dict.itunes.release){
						openCapacitorSite(data.final_dict.itunes.release);
					} else {
						store_set('snackbar_message', 'Sorry, we couldn\'t find this song on Apple Music.');
						store_set('snackbar_status', true);
					}
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}

	export_to_youtube(tracks){
		display_loading_overlay();
		request_buffer.auth_execute({
			type: 'get',
			url: constants.api_endpoint_export_to_youtube,
			data: {tracks: JSON.stringify(tracks)},
			success: function(data){
				if (data.status === 'success'){
					if (data.final_link){
						player.pause();
						openYoutubeApp(data.final_link);
					} else {
						store_set('snackbar_message', 'Sorry, we couldn\'t find this song on Youtube.');
						store_set('snackbar_status', true);
					}
					if (!subscription.is_valid()){
						app_analytics.user_event('export_to_youtube');
					}
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
				event_bus.emit('review-app', null, 'sucessful_export');
			}
		});
	}

	remove_recommendation() {
		let friends_recommendations = [...this.props.friends_recommendations];
		let recommendation = friends_recommendations.find(rec => rec.recommendation.spotify_id === this.props.song_info.spotify_id);
		let filtered_recommendations = friends_recommendations.filter(rec => rec.recommendation.spotify_id !== this.props.song_info.spotify_id);
		store_set('friends_recommendations', filtered_recommendations);

		if (recommendation) {
			store_set('snackbar_message', 'Recommendation removed');
			store_set('snackbar_status', true);
			store_set('active_overlay', false);

			if (this.props.location === 'recommendation') {
				redirect('/');
				store_set('recommendation_info', {});
			}

			request_buffer.auth_execute({
				type: 'post',
				data: { 'tag_hash': recommendation.hash_id },
				url: constants.api_endpoint_remove_friend_recommendation,
			});
		}
	}
	handle_set_obsession(song_info){
		const willBeSetted = !this.state.user_info.obsession || !this.state.user_info.obsession.song || song_info.isrc !== this.state.user_info.obsession.song.isrc;
		display_loading_overlay();
		if (!willBeSetted) {
			this.setState({ 
				user_info: {
					...this.state.user_info,
					obsession: {}
				}
			});
			store_set('user', { ...this.state.user_info, obsession: {}});
			store_set('active_overlay', false);
			store_set('loading_overlay_display', false);
		}

		let showcase = this.state.user_info.showcase || [];
		let obsession_section = showcase.find(item => item.section === 'obsession');
		let visibility = obsession_section ? obsession_section.visibility === 'on' : true;

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_add_obsession,
			data: {song_info: JSON.stringify(song_info), visibility: visibility},
			success: function(){
				(async () => {
					if (willBeSetted) {
						store_set('user', { ...this.state.user_info, obsession: { song: song_info }});
						const setObsessionCard = (data_url) => {
							this.setState({ obsession_card_data: data_url });
						};

						await get_shareable_my_obsession(setObsessionCard);
						this.setState({ 
							openShareObsession: !this.props.openShareObsession, 
							user_info: {
								...this.state.user_info,
								obsession: { song: song_info }
							}
						});
					}
				})();
				store_set('active_overlay', false);

			}.bind(this),
			complete: function() {
				store_set('loading_overlay_display', false);
			},
			timeout: 30000
		});
	}

	reset_search_recommendation() {
		store_set('selected_search_items', []);
		store_set('footer_progress_index', 0);
		store_set('footer_progress_length', 2);
		store_set('footer_progress_allow_next', false);
		store_set('is_footer_progress', false);
	}

	blocklist() {
		if (this.props.is_member_premium) {
			store_set('active_overlay', 'track_options');
			store_set('active_overlay_option', 'blocklist');
			store_set('song_info', this.props.song_info);
		}
		else {
			store_set('active_overlay', false);
			subscription.require('block', 'Subscribing now grants you artists and albums in the blocklist!', 'blocklist');
		}
	}

	handle_share_my_obsession() {
		// Checks which cards is displayed on the My Stats component
		// And shares the corresponding data url
		const { obsession_card_data } = this.state;

		share_data_url(obsession_card_data, 'my-obsession');
	}
	
	shouldComponentUpdate(nextProps){
		if (this.props.artist_info !== nextProps.artist_info){
			this.setState({is_following_artist: false});
		}

		if (this.state.user_info !== store_get('user')){
			this.setState({ user_info: store_get('user')});
		}

		// For our awesome animation to work properly, we need to set the exact content height depending on what we want
		// to display to the user. So before the component update, we will set the type and height for the next pose state.
		// Here let's check if the height and type should be updated.
		if (
			this.props.active_overlay !== nextProps.active_overlay ||
			this.props.active_overlay_option !== nextProps.active_overlay_option
		){
			var new_type = '';
			var new_height = '';
			var song_info = nextProps.song_info;
			var album_info = nextProps.album_info;
			var small_screen = window.innerWidth <= 300;
			var number_of_dsp = 0;

			// The overlays that we need to put this effort into are the 'track/album/artist options' overlay.
			if (['track_options', 'album_options', 'artist_options'].includes(nextProps.active_overlay)){
				// Important measures:
				//
				// Small screen (max-width: 300px):              Normal screen:
				// Overlay item: 36px                            Overlay item: 54px
				// Overlay header: 57px                          Overlay header: 92px
				// Content margin: 5px                           Content margin: 10px
				// Border top: 4px                               Border top: 4px
				//
				// Let's set the height according to which step of the overlay we will display
				switch (nextProps.active_overlay_option){
				// Shows every option the user can choose to manipulate the track. But we need to pay attention 
				// if the song is already inside user likes list. In this case, we also give the option to remove.
				case 'show_all_track':
					new_type = 'full';
					new_height = small_screen ? 319 : 490;

					// If user is not logged in, we don't need to show some interactions
					if (nextProps.user_token){
						const song_inside_likes_list = nextProps.likes.filter((song) => song.spotify_id === song_info.spotify_id).length > 0;
						const display_remove_button = (song_inside_likes_list && nextProps.current_page.includes('my-list')) || (nextProps.playlist_info.is_author && nextProps.current_page.includes('playlist'));
						const user_has_playlists = nextProps.playlists.length > 0;						
						const is_friends_recommendation = song_info.is_recommendation && this.props.location === 'home';
						const display_queue_button = nextProps.location === 'home' && queue.size() > 0;
						const display_blocklist_button = ['home', 'track'].includes(nextProps.location) && !is_friends_recommendation;

						if (display_remove_button){ // Remove button
							new_height += small_screen ? 36 : 54;
						}
						if (user_has_playlists){ // Add track to playlist button
							new_height += small_screen ? 36 : 54;
						}
						if (display_queue_button){ // Recommendation queue button
							new_height += small_screen ? 36 : 54;
						}
						if (is_friends_recommendation){ // Remove recommendation button
							new_height += small_screen ? 36 : 54;
						}
						if (display_blocklist_button){ // Blocklist button
							new_height += small_screen ? 36 : 54;
						}
					}
					break;

				case 'show_all_album':
					new_type = 'full';
					new_height = small_screen ? 215 : 328;

					var album_inside_blockist = nextProps.blocklist && nextProps.blocklist.find(b => (b.spotify_id && b.spotify_id === nextProps.album_info.spotify_id) || (b.hash_id && b.hash_id === nextProps.album_info.hash_id));
					if (album_inside_blockist){
						new_height += small_screen ? 36 : 54;
					}
					if (nextProps.album_info.artist && nextProps.album_info.artist.spotify_id){
						new_height += small_screen ? 36 : 54;
					}
					break;

				case 'show_all_artist':
					new_type = 'full';
					new_height = small_screen ? 215 : 328;

					var artist_inside_blocklist = nextProps.blocklist && nextProps.blocklist.find(b => (b.spotify_id && b.spotify_id === nextProps.artist_info.spotify_id) || (b.hash_id && b.hash_id === nextProps.artist_info.hash_id));
					if (artist_inside_blocklist){
						new_height += small_screen ? 36 : 54;
					}
					break;

				case 'show_seed_likes':
					new_height = '100%';
					new_type = 'change';
					break;

				// Show a feedback message to the user if something goes wrong or is loading.
				case 'loading':
				case 'loading_playlists':
				case 'error':
					new_height = small_screen ? 150 : 256;
					new_type = 'feedback';
					break;

				case 'my_list_header_overlay':
					new_height = 230;
					new_type = 'full';
					break;

				// Shows user's playlists when he wants to export tracks. Depending on the number of playlists, we
				// set a height or if the list is too long we use the max height.
				case 'show_playlists':
					new_height = '100%';
					new_type = 'playlist';
					break;

				// Shows DSPs where the user can export tracks to.
				case 'export' :
					new_height = small_screen ? 210 : 332;
					new_type = 'change';
					break;

				// Shows social medias where the user can export tracks to.
				case 'share' :
					new_height = small_screen ? 287 : 382;
					new_type = 'change';
					break;

				// Shows social medias where the user can export tracks to.
				case 'blocklist' :
					new_height = small_screen ? 182 : 272;
					new_type = 'change';
					break;

				// Shows platforms where the user can export tracks to.
				case 'export_select_mode' :
					new_height = small_screen ? 122 : 185;
					new_type = 'change';
					break;
				
				// Shows platforms where the user can export tracks to.
				case 'export_all' :
					new_height = small_screen ? 100 : 130;
					new_type = 'change';
					break;
				
				case 'add_track_to_playlist':
					new_height = small_screen ? 71+(nextProps.playlists.length)*36 : 86+(nextProps.playlists.length)*42;
					new_type = 'change';
					break;

				// Shows DSPs where the user can listen the track. Since depends on where it's delivered it can be
				// different to each track.
				case 'play_full_song':
					number_of_dsp = 0;
					if (song_info.spotify_link){
						number_of_dsp++;
					}
					if (song_info.deezer_link){
						number_of_dsp++;
					}
					if (song_info.itunes_link){
						number_of_dsp++;
					}
					if (song_info.youtube_link){
						number_of_dsp++;
					}
					new_height = small_screen ? 71+number_of_dsp*36 : 116+number_of_dsp*54;
					new_type = 'change';
					break;

				// Shows DSPs where the user can listen find the album. Number of DSPs may vary.
				case 'play_full_album':
					number_of_dsp = 0;
					if (album_info.spotify_link){
						number_of_dsp++;
					}
					if (album_info.deezer_link){
						number_of_dsp++;
					}
					if (album_info.itunes_link){
						number_of_dsp++;
					}
					if (album_info.youtube_link){
						number_of_dsp++;
					}
					new_height = small_screen ? 71+number_of_dsp*36 : 116+number_of_dsp*54;
					new_type = 'change';
					break;

				default:
					break;
				}
			} else {
				new_height = 0;
				new_type = 'hidden';
			}
			this.setState({pose_type: new_type});
			this.setState({pose_height: new_height});
		}

		return true;
	}

	render(){
		const {
			active_overlay,
			album_info,
			artist_info,
			blocklist,
			current_page,
			get_song_links,
			load_recommendations,
			my_list_display,
			overlay_seed,
			playlists,
			share_url,
			share,
			song_info,
			streaming_overlay,
			tracks_selected,
			user_token,
		} = this.props;

		const active_overlay_option = this.props.active_overlay_option ? this.props.active_overlay_option : 'show_all_track';
		const artist_spotify_id = Array.isArray(song_info.artist) ? song_info.artist[0].spotify_id : song_info.artist.spotify_id;
		const synced_playlists = streaming_overlay === 'spotify' ? this.props.spotify_playlists : this.props.deezer_playlists;

		const song_inside_likes_list = this.props.likes.filter((song) => song.spotify_id === song_info.spotify_id);

		const item = active_overlay === 'album_options' ? album_info : artist_info;
		const item_inside_blocklist = blocklist && blocklist.find(b => (b.spotify_id && b.spotify_id === item.spotify_id) || (b.hash_id && b.hash_id === item.hash_id));

		const artists = song_info.artist.name ? song_info.artist.name : Array.from(song_info.artist, artist => artist.name).join(', ');

		let header_image, header_default_image, header_title, header_subtitle;
		if (active_overlay === 'album_options'){
			header_image = album_info.images.medium;
			header_default_image = assets.default_album_image;
			header_title = album_info.title;
			header_subtitle = album_info.artist.name;
		}
		else if (active_overlay === 'artist_options'){
			header_image = artist_info.images.medium;
			header_default_image = assets.default_artist_image;
			header_title = artist_info.name;
		}
		else if (active_overlay_option === 'show_seed_likes'){
			header_image = overlay_seed.member_info.image;
			header_default_image = assets.default_profile_image;
			header_title = overlay_seed.member_info.name + (overlay_seed.member_info.slug ? '<span style="font-size: 10px; color: #707070"> ● @' + overlay_seed.member_info.slug + '</span>' : '');
			header_subtitle = overlay_seed.last_seed;
		}
		else if (!['show_playlists', 'loading_playlists', 'export_select_mode', 'my_list_header_overlay', 'export_all', 'add_track_to_playlist', 'show_seed_likes'].includes(active_overlay_option)){
			header_image = song_info.album ? (song_info.album.images ? song_info.album.images.large : '') : '';
			header_default_image = assets.default_album_image;
			header_title = song_info.title;
			header_subtitle = (song_info.album.name || song_info.album.title) + ' - ' + artists;
		}

		return (
			<>
				<PosedTrackOverlay
					pose={this.state.pose_type}
					height={this.state.pose_height}
					className='overlay-main'
				>
					<div className='overlay-track-content'>
						{/** Overlay Header */}
						{header_title ?
							<div className='overlay-track-info' onClick={(e) => e.stopPropagation()}>
								<img
									alt='Release Album'
									src={header_image}
									onError={(ev) => ev.target.src = header_default_image}
									className='overlay-track-cover'
								/>
								<div className='overlay-track-info-text'>
									<div className='overlay-track-title' dangerouslySetInnerHTML={{__html: header_title}}></div>
									<div className='overlay-track-subtitle'>{header_subtitle}</div>
								</div>
								{active_overlay_option === 'show_seed_likes' &&
								<div className='overlay-track-info-right-side'>
									<span className='material-icons' onClick={() => {
										store_set('active_overlay', false);
										store_set('active_overlay_option', 'show_all_track');
										store_set('playlist_overlay_option', 'show_all');
										store_set('tracks_selected', []);
									}}>close</span>
								</div> 
								}
							</div>
							: <div></div>
						}
						{(active_overlay_option === 'show_playlists' || active_overlay_option === 'loading_playlists') &&
							<div className='overlay-track-info' onClick={(e) => e.stopPropagation()}>
								<img
									src={streaming_overlay === 'deezer' ? assets.deezer_icon : assets.spotify_round_icon}
									alt={streaming_overlay === 'deezer' ? 'deezer-icon' : 'spotify-icon'}
								/>					
								<div className='overlay-track-info-text'>
									<div className='overlay-track-title'>Export to {streaming_overlay === 'deezer' ? 'Deezer' : 'Spotify'}</div>
								</div>
								<div className='overlay-track-info-right-side'>
									<span className='material-icons' onClick={() => {
										store_set('active_overlay', false);
										store_set('active_overlay_option', 'show_all_track');
										store_set('playlist_overlay_option', 'show_all');
										store_set('tracks_selected', []);
									}}>close</span>
								</div>
							</div>
						}
						{active_overlay_option === 'blocklist' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => user.update_blocklist('add', 'artist', song_info.artist )}>
											<div className='material-icons'>mic</div>
											<div className='overlay-track-item-text'>Blocklist artist</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => user.update_blocklist('add', 'release', song_info.album )}>
											<div className='material-icons'>album</div>
											<div className='overlay-track-item-text'>Blocklist album</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => {store_set('active_overlay', false); redirect('/blocklist/');}}>
											<img src={assets.blocklist_icon_white} alt='Blocklist' />
											<div className='overlay-track-item-text'>Your blocklist</div>
										</div>
									</Haptic>
								</Ripples>
							</div>
						}
						{active_overlay_option === 'my_list_header_overlay' &&
							<div className='overlay-track-options'>
								{my_list_display === 'grid_view' ?
									<div className='overlay-my-list-item' onClick={() => {store_set('my_list_display', 'list_view'); store_set('active_overlay', false);}}> 
										<span className='material-icons-outlined'>format_list_bulleted</span> 
										<p>List view</p>
									</div>
									:
									my_list_display === 'list_view' ?
										<div className='overlay-my-list-item' onClick={() => {store_set('my_list_display', 'grid_view'); store_set('active_overlay', false);}}>
											<span className='material-icons-outlined'>grid_view</span> 
											<p>Grid view</p>
										</div>
										: null
								}
								<div className='overlay-my-list-item' onClick={() => {store_set('active_overlay', false); store_set('select_mode', true);}}>
									<span className='material-icons'>delete</span>
									<p>Delete multiple</p>
								</div>
								<div className='overlay-my-list-item' onClick={() => {store_set('active_overlay', false); store_set('select_mode', true);}}>
									<span className='material-icons-outlined'>file_upload</span>
									<p>Export tracks</p>
								</div>
								<div className='overlay-my-list-item' onClick={(e) => {store_set('active_overlay_option', 'export_all'); e.stopPropagation();}} >
									<img src={assets.export_all} alt='' style={{height: 22}}/>
									<p>Export all tracks</p>
								</div>
							</div>	
						}
						{active_overlay_option === 'add_track_to_playlist' &&
							<div className='overlay-show-playlists-info'>
								<div className='overlay-playlist-info-text'>
									<div className='overlay-playlist-title'>Choose a playlist</div>
									<div className='overlay-playlist-subtitle'>The song will be added to the chosen playlist</div>
								</div>
							</div>
						}
		
						{/** Overlay Content */}
						{active_overlay_option === 'loading' &&
							<div className='overlay-track-feedback'>
								<CircularProgress />
							</div>
						}
						{active_overlay_option === 'loading_playlists' &&
							<div className='overlay-track-feedback'>
								<CircularProgress />
								<div className='overlay-track-feedback-text'>Fetching playlists...</div>
							</div>
						}
						{active_overlay_option === 'error' &&
							<div className='overlay-track-feedback'>
								<span className='material-icons'>warning</span>
								<div className='overlay-track-feedback-text'>Sorry, something went wrong...</div>
							</div>
						}
						{active_overlay_option === 'show_all_track' &&
							<div className='overlay-track-options'>
								{!['artist_options', 'album_options'].includes(active_overlay) &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={get_song_links}>
												<div className='material-icons'>play_arrow</div>
												<div className='overlay-track-item-text'>Play Full Song</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{['track_options', 'album_options'].includes(active_overlay) &&
									<Ripples>
										<Haptic intesity='light'>
											<Link style={{textDecoration: 'none'}} to={'/album/' + song_info.album.spotify_id}>
												<div className='overlay-track-item' onClick={() => store_set('active_overlay', false)}>
													<div className='material-icons'>album</div>
													<div className='overlay-track-item-text'>Album Page</div>
												</div>
											</Link>
										</Haptic>
									</Ripples>
								}
								<Ripples>
									<Haptic intesity='light'>
										<Link style={{textDecoration: 'none'}} to={'/artist/' + artist_spotify_id}>
											<div className='overlay-track-item' onClick={() => store_set('active_overlay', false)}>
												<div className='material-icons'>person</div>
												<div className='overlay-track-item-text'>Artist Page</div>
											</div>
										</Link>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => load_recommendations(song_info, 'song')}>
											<div className='overlay-track-options-icon'>
												<img src={assets.find_similar_icon} alt='find-similar-icon'/>
											</div>
											<div className='overlay-track-item-text'>Find Similar</div>
										</div>
									</Haptic>
								</Ripples>
								{user_token &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => store_set('active_overlay_option', 'export')}>
												<div className='material-icons'>open_in_new</div>
												<div className='overlay-track-item-text'>Export</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{song_inside_likes_list.length > 0 && current_page.includes('my-list') &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => list.remove(song_info)}>
												<div className='material-icons'>delete</div>
												<div className='overlay-track-item-text'>Remove</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{current_page.includes('playlist') && this.props.playlist_info.is_author &&
										<Ripples>
											<Haptic intesity='light'>
												<div className='overlay-track-item' onClick={() => playlist_manager.remove_active_playlist_tracks(song_info, this.props.playlist_info)}>
													<div className='material-icons'>delete</div>
													<div className='overlay-track-item-text'>Remove from playlist</div>
												</div>
											</Haptic>
										</Ripples>
								}
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => this.handle_set_obsession(song_info, this.state.user_info) }>
											<img className='overlay-track-item-icon' src={assets.icon_obsession} alt='Obsession'/>
											<div className='overlay-track-item-text'>
												{
													this.state.user_info.obsession && this.state.user_info.obsession.song && this.state.user_info.obsession.song.isrc === song_info.isrc ? 'Remove obsession' : 'Set as obsession'
												}
											</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => share('song')}>
											<div className='material-icons'>share</div>
											<div className='overlay-track-item-text'>Share</div>
										</div>
									</Haptic>
								</Ripples>	
								{['home', 'track'].includes(this.props.location) && !song_info.is_recommendation &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={this.blocklist}>
												<img src={assets.blocklist_icon_white} alt='Blocklist' />
												<div className='overlay-track-item-text'>Add to Blocklist</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{playlists.length > 0 &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => {store_set('active_overlay', 'track_options'); store_set('active_overlay_option', 'add_track_to_playlist');}}>
												<div className='material-icons'>playlist_add</div>
												<div className='overlay-track-item-text'>Add to Playlist</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{song_info.is_recommendation && this.props.location === 'home' &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={this.remove_recommendation}>
												<div className='material-icons'>delete</div>
												<div className='overlay-track-item-text'>Remove Recommendation</div>
											</div>
										</Haptic>
									</Ripples>	
								}
								{this.props.location === 'home' && queue.size() > 0 &&
									<Ripples>
										<Haptic intesity='light'>
											<Link style={{textDecoration: 'none'}} to='/queue/'>
												<div className='overlay-track-item' onClick={() => {store_set('active_overlay', false);}}>
													<div className='material-icons'>queue_music</div>
													<div className='overlay-track-item-text'>Recommendation Queue</div>
												</div>
											</Link>
										</Haptic>
									</Ripples>
								}
							</div>
						}
						{active_overlay_option === 'show_all_album' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => event_bus.emit('play_full_album')}>
											<div className='material-icons'>play_arrow</div>
											<div className='overlay-track-item-text'>Play Full Album</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<Link style={{textDecoration: 'none'}} to={'/album/' + album_info.spotify_id}>
											<div className='overlay-track-item' onClick={() => store_set('active_overlay', false)}>
												<div className='material-icons'>album</div>
												<div className='overlay-track-item-text'>Album Page</div>
											</div>
										</Link>
									</Haptic>
								</Ripples>
								{album_info.artist && album_info.artist.spotify_id &&
									<Ripples>
										<Haptic intesity='light'>
											<Link style={{textDecoration: 'none'}} to={'/artist/' + album_info.artist.spotify_id}>
												<div className='overlay-track-item' onClick={() => store_set('active_overlay', false)}>
													<div className='material-icons'>person</div>
													<div className='overlay-track-item-text'>Artist Page</div>
												</div>
											</Link>
										</Haptic>
									</Ripples>
								}
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => load_recommendations(album_info, 'album')}>
											<div className='overlay-track-options-icon'>
												<img src={assets.find_similar_icon} alt='find-similar-icon'/>
											</div>
											<div className='overlay-track-item-text'>Find Similar</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => share('album')}>
											<div className='material-icons'>share</div>
											<div className='overlay-track-item-text'>Share</div>
										</div>
									</Haptic>
								</Ripples>
								{item_inside_blocklist &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => {store_set('active_overlay', false); user.update_blocklist('remove', 'release', album_info);}}>
												<div className='material-icons'>delete</div>
												<div className='overlay-track-item-text'>Remove</div>
											</div>
										</Haptic>
									</Ripples>
								}
							</div>
						}
						{active_overlay_option === 'show_all_artist' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => load_recommendations(artist_info, 'artist')}>
											<div className='overlay-track-options-icon'>
												<img src={assets.find_similar_icon} alt='find-similar-icon'/>
											</div>
											<div className='overlay-track-item-text'>Find Similar</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<Link style={{textDecoration: 'none'}} to={'/artist/' + artist_info.spotify_id}>
											<div className='overlay-track-item' onClick={() => store_set('active_overlay', false)}>
												<div className='material-icons'>person</div>
												<div className='overlay-track-item-text'>Artist Page</div>
											</div>
										</Link>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => share('artist')}>
											<div className='material-icons'>share</div>
											<div className='overlay-track-item-text'>Share</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										{this.state.is_following_artist &&
											<div className='overlay-track-item' onClick={() => {event_bus.emit('call-follow-function', null, 'unfollow'); store_set('active_overlay', false);}}>
												<div className='material-icons'>done</div>
												<div className='overlay-track-item-text'>Unfollow</div>
											</div>
										}
										{!this.state.is_following_artist &&
											<div className='overlay-track-item' onClick={() => {event_bus.emit('call-follow-function', null, 'follow'); store_set('active_overlay', false);}}>
												<div className='material-icons'>person_add</div>
												<div className='overlay-track-item-text'>Follow</div>
											</div>
										}
									</Haptic>
								</Ripples>
								{item_inside_blocklist &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => {store_set('active_overlay', false); user.update_blocklist('remove', 'artist', artist_info);}}>
												<div className='material-icons'>delete</div>
												<div className='overlay-track-item-text'>Remove</div>
											</div>
										</Haptic>
									</Ripples>
								}
							</div>
						}
						{active_overlay_option === 'show_seed_likes' &&
							<div className='overlay-seed-options'>
								{overlay_seed.recent_likes.map((item, index) => (
									<Card
										key={index}
										data={item}
										type='small_card_song'
									/>
								))}
							</div>
						}
						{active_overlay_option === 'play_full_song' &&
							<div className='overlay-track-options'>
								{song_info.spotify_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(song_info.spotify_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
												</div>
												<div className='overlay-track-item-text'>Spotify</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{song_info.itunes_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(song_info.itunes_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.apple_music_icon} alt='apple-music-icon'/>
												</div>
												<div className='overlay-track-item-text'>Apple Music</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{song_info.deezer_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(song_info.deezer_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.deezer_icon} alt='deezer-icon'/>
												</div>
												<div className='overlay-track-item-text'>Deezer</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{song_info.youtube_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); openYoutubeApp(song_info.youtube_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.youtube_icon} alt='youtube-icon'/>
												</div>
												<div className='overlay-track-item-text'>Youtube</div>
											</div>
										</Haptic>
									</Ripples>
								}
							</div>
						}
						{active_overlay_option === 'play_full_album' &&
							<div className='overlay-track-options'>
								{album_info.spotify_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(album_info.spotify_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
												</div>
												<div className='overlay-track-item-text'>Spotify</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{album_info.itunes_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(album_info.itunes_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.apple_music_icon} alt='apple-music-icon'/>
												</div>
												<div className='overlay-track-item-text'>Apple Music</div>
											</div>
										</Haptic>
									</Ripples>
								}
								{album_info.deezer_link !== '' && !this.state.loading_links &&
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={(e) => {player.pause(); window.open(album_info.deezer_link); e.stopPropagation();}}>
												<div className='overlay-track-icon'>
													<img src={assets.deezer_icon} alt='deezer-icon'/>
												</div>
												<div className='overlay-track-item-text'>Deezer</div>
											</div>
										</Haptic>
									</Ripples>
								}
							</div>
						}
						{active_overlay_option === 'export_select_mode' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item'
											onClick={() => { 
												this.setState({export_all: false});
												store_set('streaming_overlay', 'spotify'); 
												export_track.get_playlists('spotify');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
											</div>
											<div className='overlay-track-item-text'>Spotify</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item'
											onClick={() => {
												this.setState({export_all: false});
												store_set('streaming_overlay', 'deezer');
												export_track.get_playlists('deezer');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.deezer_icon} alt='deezer-icon'/>
											</div>
											<div className='overlay-track-item-text'>Deezer</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => this.export_to_youtube(tracks_selected)}>
											<div className='overlay-track-icon'>
												<img src={assets.youtube_icon} alt='youtube-icon'/>
											</div>
											<div className='overlay-track-item-text'>Youtube</div>
										</div>
									</Haptic>
								</Ripples>
							</div>
						}
						{active_overlay_option === 'export_all' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item' 
											onClick={() => { 
												this.setState({export_all: true});
												store_set('streaming_overlay', 'spotify');
												export_track.get_playlists('spotify');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
											</div>
											<div className='overlay-track-item-text'>Spotify</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item'
											onClick={() => {
												this.setState({export_all: true});
												store_set('streaming_overlay', 'deezer');
												export_track.get_playlists('deezer');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.deezer_icon} alt='deezer-icon'/>
											</div>
											<div className='overlay-track-item-text'>Deezer</div>
										</div>
									</Haptic>
								</Ripples>
							</div>
						}
						{active_overlay_option === 'export' &&
							<div className='overlay-track-options'>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item'
											onClick={() => {
												this.setState({export_all: false});
												store_set('streaming_overlay', 'spotify'); 
												export_track.get_playlists('spotify');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
											</div>
											<div className='overlay-track-item-text'>Spotify</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={this.export_to_apple}>
											<div className='overlay-track-icon'>
												<img src={assets.apple_music_icon} alt='apple-music-icon'/>
											</div>
											<div className='overlay-track-item-text'>Apple Music</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div
											className='overlay-track-item'
											onClick={() => {
												this.setState({export_all: true});
												store_set('streaming_overlay', 'deezer');
												export_track.get_playlists('deezer');
											}}
										>
											<div className='overlay-track-icon'>
												<img src={assets.deezer_icon} alt='deezer-icon'/>
											</div>
											<div className='overlay-track-item-text'>Deezer</div>
										</div>
									</Haptic>
								</Ripples>
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => this.export_to_youtube([song_info])}>
											<div className='overlay-track-icon'>
												<img src={assets.youtube_icon} alt='youtube-icon'/>
											</div>
											<div className='overlay-track-item-text'>Youtube</div>
										</div>
									</Haptic>
								</Ripples>
							</div>
						}
						{active_overlay_option === 'show_playlists' &&
							<div className='overlay-scroll-area'>
								<div className='overlay-track-options'>
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-playlist-item overlay-playlist-item-new-playlist' onClick={()=> {store_set('create_playlist', true); store_set('playlist_overlay_option', 'external');}}>Add New</div>
										</Haptic>
									</Ripples>
									{synced_playlists.map((playlist, index) => (
										<Ripples key={index}>
											<Haptic intesity='light'>
												<div className='overlay-playlist-item' onClick={()=> this.export(playlist)}>{playlist.title}</div>
											</Haptic>
										</Ripples>
									))}
								</div>
							</div>
						}
						{active_overlay_option === 'add_track_to_playlist' &&
							<div className='overlay-track-options'>
								{playlists.map((playlist, index) => (
									<Ripples key={index}>
										<Haptic intesity='light'>
											<div className='overlay-show-playlist-item' onClick={() => playlist_manager.update_active_playlist_tracks(song_info, playlist)}>
												<div className='overlay-track-item-text'>{playlist.name}</div>
											</div>
										</Haptic>
									</Ripples>
								))}
							</div>
						}
						{active_overlay_option === 'share' &&
							<div className='overlay-track-options'>
								<Ripples>
									<FacebookShareButton url={share_url}>
										<Haptic intesity='light'>
											<div className='overlay-track-item'>
												<div className='overlay-track-icon'>
													<img src={assets.facebook_icon} alt='facebook-share-icon'/>
												</div>
												<div className='overlay-track-item-text'>Facebook</div>
											</div>
										</Haptic>
									</FacebookShareButton>
								</Ripples>
								<Ripples>
									<TwitterShareButton url={share_url}>
										<Haptic intesity='light'>
											<div className='overlay-track-item'>
												<div className='overlay-track-icon'>
													<img src={assets.twitter_bird_icon} alt='twitter-share-icon'/>
												</div>
												<div className='overlay-track-item-text'>Twitter</div>
											</div>
										</Haptic>
									</TwitterShareButton>
								</Ripples>	
								<Ripples>
									<WhatsappShareButton url={share_url}>
										<Haptic intesity='light'>
											<div className='overlay-track-item'>
												<div className='overlay-track-icon'>
													<img src={assets.whatsapp_round_icon} alt='whatsapp-share-icon'/>
												</div>
												<div className='overlay-track-item-text'>Whatsapp</div>
											</div>
										</Haptic>
									</WhatsappShareButton>
								</Ripples>	
								<Ripples>
									<TelegramShareButton url={share_url}>
										<Haptic intesity='light'>
											<div className='overlay-track-item'>
												<div className='overlay-track-icon'>
													<img src={assets.telegram_round_icon} alt='telegram-share-icon'/>
												</div>
												<div className='overlay-track-item-text'>Telegram</div>
											</div>
										</Haptic>
									</TelegramShareButton>
								</Ripples>	
								<Ripples>
									<VKShareButton url={share_url}>
										<Haptic intesity='light'>
											<div className='overlay-track-item'>
												<div className='overlay-track-icon'>
													<img src={assets.vk_round_icon} alt='vk-share-icon'/>
												</div>
												<div className='overlay-track-item-text'>VK</div>
											</div>
										</Haptic>
									</VKShareButton>
								</Ripples>	
							</div>
						}
					</div>
				</PosedTrackOverlay>
				<ObsessionPopup 
					obsession_card_data={this.state.obsession_card_data}
					user_info={this.state.user_info}
					openObsession={this.state.openShareObsession} 
					obsession_ref={this.share_obsession_ref}
					onShareImage={this.handle_share_my_obsession}
					onCloseObsession={
						() => this.setState({ 
							openShareObsession: false,
							obsession_card_data: undefined
						})
					} 
				/>
				<div className='overlay-obsession-hidden' id='share-obssesion-card-hidden' ref={this.my_obsession_clone_slot}>
					<Card key={song_info.isrc} id='share-obsession-card-clone' type='share_obsession' data={song_info} />
				</div>
			</>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		active_overlay: state.GlobalReducer.active_overlay,
		active_overlay_option: state.GlobalReducer.active_overlay_option,
		album_info: state.GlobalReducer.album_info,
		artist_info: state.GlobalReducer.artist_info,
		blocklist: state.GlobalReducer.blocklist,
		current_page: state.GlobalReducer.current_page,
		likes: state.GlobalReducer.likes,
		my_list_display: state.GlobalReducer.my_list_display,
		playlists: state.GlobalReducer.playlists,
		select_mode: state.GlobalReducer.select_mode,
		song_info: state.GlobalReducer.song_info,
		streaming_overlay: state.GlobalReducer.streaming_overlay,
		tracks_selected: state.GlobalReducer.tracks_selected,
		overlay_seed: state.GlobalReducer.overlay_seed,
		user_token: state.GlobalReducer.user_token,
		obsession: state.GlobalReducer.obsession,
		playlist_info: state.GlobalReducer.playlist_info,
		spotify_playlists: state.GlobalReducer.spotify_playlists,
		deezer_playlists: state.GlobalReducer.deezer_playlists,
		friends_recommendations: state.GlobalReducer.friends_recommendations,
		location: state.GlobalReducer.location,
		is_member_premium: state.GlobalReducer.is_member_premium,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(ItemOverlay));
