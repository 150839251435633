import React, { Component } from 'react';

// CSS Imports
import './styles/SplashScreen.css';

// Assets Imports
import * as assets from '@Assets';

// URLs Imports

// Components and Views Imports

export default class SplashScreen extends Component {
	render() {
		return(
			<div className="splash-screen-main-container">
				<img src={assets.magroove_logo} alt="magroove-logo"/>
			</div>
		);
	}
}