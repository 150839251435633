import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { redirect } from '@routes/Routes.js';

// Components
import { Button } from '@magroove/magroove-ui';
import { CircularProgress, Drawer } from '@material-ui/core';

// CSS Imports
import './styles/SubscriptionPage.css';

// Assets Imports
import {
	gray_badge,
	orange_badge,
	american_express_logo,
	diners_club_logo,
	elo_logo,
	hiper_logo,
	hipercard_logo,
	jcb_logo,
	maestro_logo,
	mastercard_logo,
	mir_logo,
	paypal_logo_2,
	unionpay_logo,
	visa_logo,
} from '@Assets';

// Import the controllers
import subscription from '@Subscription';

function get_card_logo(brand) {
	switch (brand) {
	case 'american_express':
		return american_express_logo;
	case 'diners_club':
		return diners_club_logo;
	case 'elo':
		return elo_logo;
	case 'hiper':
		return hiper_logo;
	case 'hipercard':
		return hipercard_logo;
	case 'jcb':
		return jcb_logo;
	case 'maestro':
		return maestro_logo;
	case 'mastercard':
		return mastercard_logo;
	case 'mir':
		return mir_logo;
	case 'paypal':
		return paypal_logo_2;
	case 'unionpay':
		return unionpay_logo;
	case 'visa':
		return visa_logo;
	default:
		return 'https://magroove-dev.s3.amazonaws.com/magroove-for-artists/' + brand + '-logo.svg';
	}
}

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

class SubscriptionPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirm_action: false
		};

		if (!subscription.is_valid()) {
			redirect('/settings/');
		}
		if (!this.props.member_subscription) {
			subscription.fetch();
		}
	}

	render() {
		if (!this.props.member_subscription) {
			return (
				<div className='subscription-page-loading'>
					<CircularProgress size={50} />
				</div>
			);
		}

		const { frequency, internal_state, payment_method, price, end_of_billing, card } = this.props.member_subscription;
		return (
			<div className='subscription-page'>
				<div className='subscription-page-content'>
					<div className='subscription-page-section'>
						<div className='subscription-page-section-title'>Your plan</div>
						<div className={'subscription-page-plan subscription-page-plan-' + internal_state}>
							<div className='subscription-page-plan-container'>
								<img className='premium-form-plan-icon' src={internal_state === 'active' ? orange_badge : gray_badge} alt='Premium badge' />
							</div>
							<div className='subscription-page-plan-container'>
								<div className='subscription-page-plan-state'>{internal_state === 'active' ? 'Active' : 'Inactive'}</div>
								<div className='subscription-page-plan-price'><span>${price} USD</span>/ {frequency === 'monthly' ? 'month' : 'year'}</div>
								<div className='subscription-page-plan-info'>Billed {frequency}, get access to all benefits and discover much more with Magroove Premium!</div>
							</div>
						</div>
						<div className='subscription-page-section-text'>This subscription {internal_state === 'active' ? 'will be renewed' : 'ends'} on {end_of_billing}.</div>
					</div>
					{payment_method === 'credit_card' &&
						<div className='subscription-page-section'>
							<div className='subscription-page-section-title'>Billing</div>
							<div className='subscription-page-card'>
								<img src={() => get_card_logo(card.brand)} alt='Card Logo' />
								<div className='subscription-page-card-info'>
									<div className='subscription-page-card-text'>{capitalize(card.brand)} Card ending with {card.last4}</div>
									<div className='subscription-page-card-expire'>Expiring date: {card.expire}</div>
								</div>
							</div>
						</div>
					}
				</div>
				{internal_state === 'active' && payment_method === 'in_app_ios' &&
					<div className='subscription-page-cancelation'>
						<div className='subscription-page-cancelation-text'>To cancel your Magroove Premium subscription, head over to your App Store Subscriptions.</div>
						<a className='subscription-page-cancelation-link' href='https://support.apple.com/en-ca/HT202039'>How to cancel your subscription</a>
					</div>
				}
				{internal_state === 'active' && payment_method !== 'in_app_ios' &&
					<div className='subscription-page-cancelation'>
						<Button size='small' label='CANCEL SUBSCRIPTION' onClick={() => this.setState({ confirm_action: true })} fullWidth={true} variant='text' main_color='rgba(236, 107, 67, 0.15)' />
					</div>
				}
				{internal_state === 'canceled' &&
					<div className='subscription-page-cancelation'>
						<Button size='small' label='RENEW SUBSCRIPTION' onClick={() => subscription.renew(frequency, payment_method)} fullWidth={true} variant='text' main_color='rgba(236, 107, 67, 0.15)' />
					</div>
				}
				<Drawer anchor='bottom' open={this.state.confirm_action} onClick={() => this.setState({ confirm_action: false })}>
					<div className='subscription-page-confirm-overlay'>
						<div className='subscription-page-confirm-overlay-title'>Cancel Subscription?</div>
						<div className='subscription-page-confirm-overlay-text'>
							The cancellation will be effective at the end of your current billing period on {end_of_billing}.
							You can restart your subscription anytime.
							<br></br><br></br>
							<strong>Are you sure you want to cancel your Magroove Premium Membership?</strong>
						</div>
						<div className='subscription-page-confirm-overlay-buttons'>
							<Button fullWidth={true} size='small' value='NOT REALLY' variant='text' />
							<Button fullWidth={true} size='small' value='YES, CANCEL' onClick={() => subscription.cancel()} />
						</div>
					</div>
				</Drawer>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		member_subscription: state.GlobalReducer.member_subscription
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(SubscriptionPage));