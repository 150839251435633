import React, { Component } from 'react';
import FuzzySearch from 'fuzzy-search';
import { ClickAwayListener } from '@material-ui/core';

// Components import
import Card from '@components/Card.js';
import { CircularProgress } from '@material-ui/core';

// Actions import
import { redirect } from '@routes/Routes.js';

// Css import
import './styles/FollowOverlay.css';

function transformString(inputString) {
	// Split the string by underscores
	var words = inputString.split('_');
  
	// Capitalize the first letter of each word and convert the rest to lowercase
	var capitalizedWords = words.map(function(word) {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
  
	// Join the words without underscores
	var transformedString = capitalizedWords.join(' ');
  
	return transformedString;
}

class FollowOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			follow_list: this.props.data,
			follow_list_aux: this.props.data,
			follow_query: ''
		};

		this.search_user_follow_list = this.search_user_follow_list.bind(this);
	}

	shouldComponentUpdate(nextProps){
		if (nextProps.data.length !== this.state.follow_list.length){
			this.setState({ follow_list: nextProps.data });
			this.search_user_follow_list(nextProps.data);
		}
		return true;
	}

	search_user_follow_list(current = this.state.follow_list){
		if (this.state.follow_query.length === 0) {
			this.setState({follow_list_aux: current});
			return;
		}
		const follow_list = [...current];
		let searcher = new FuzzySearch(follow_list, ['name'],{caseSensitive: false});
		let new_follow_list = searcher.search(this.state.follow_query);
		this.setState({follow_list_aux: new_follow_list});
	}

	render(){
		const { follow_type } = this.props;

		const is_loading_following = follow_type === 'following_users' && this.props.profile_info.following > 0;
		const is_loading_followers = follow_type === 'followers' && this.props.profile_info.followers > 0;
		const display_loading = this.props.data.length === 0 && (is_loading_following || is_loading_followers);

		return(	
			<ClickAwayListener onClickAway={this.props.onClose}>
				<div
					className='follow-overlay' id='follow-overlay'
					style={{ top: this.props.safe_area_top }}
				>
					<div className='follow-overlay-header'>
						<span className='material-icons' onClick={this.props.onClose}>keyboard_backspace</span>
						<p>{transformString(follow_type)}</p>
					</div>
					<div className='follow-overlay-search-bar'>
						<input 
							value={this.state.follow_query} 
							type='text' 
							onChange={(event) => {
								this.setState({follow_query: event.target.value}); 
								this.search_user_follow_list();
							}} 
							placeholder='Search for someone...'
						/>
						<span onClick={() => this.setState({follow_query: '', follow_list_aux: this.state.follow_list})} className='material-icons'>close</span>
					</div>
					<div
						className='follow-overlay-body'
						id='follow-overlay-scroll'
						ref={this.scroll_ref}
						onScroll={this.handle_scroll}
					>
						<div className='follow-overlay-cards'> 
							{this.state.follow_list_aux.map((item, index) => {
								if (follow_type === 'following_artists'){
									const action_available = item.is_following ? 'unfollow' : 'follow';
									return (
										<Card 
											data={item}
											key={item.spotify_id} 
											type='small_card_artist'
											button_type='follow'
											subtitle_text={item.followers + ' followers'}
											onClick={() => redirect('/artist/' + item.spotify_id)} 
											onMoreClick={() => this.props.follow_artist(action_available, item)}
										/>
									);
								}
								const action_available = item.followed ? 'unfollow' : 'follow';
								return (
									<Card
										key={index}
										data={item}
										show_follow={item.member_id !== this.props.user.member_id}
										type='profile_card'
										onClick={() => this.props.follow_profile(action_available, item)}
										bodyLink={'/profile/' + (item.slug ? item.slug : item.hash_id.substring(0,10))}
									/>
								);
							})}
						</div>
						{display_loading &&
							<div className='follow-overlay-loading'>
								<CircularProgress/>
							</div>
						}
					</div>
				</div>
			</ClickAwayListener>
		);
	}
}

// Connect component to the store
export default FollowOverlay;
