import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Textfield } from '@magroove/magroove-ui';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import * as constants from '@constants';
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// CSS Imports
import './styles/DesktopContact.css';

class DesktopContact extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			name: '',
			email: '',
			subject: '',
			text: ''
		};

		this.send = this.send.bind(this);
	}

	send(){
		var message = false;
		const regex = /\S+@\S+\.\S+/;
		if (!this.state.text ||
			!this.state.email ||
			!this.state.name ||
			!this.state.subject
		){
			message = 'Please, fill in all the fields';
		}
		else if (!regex.test(this.state.email)){
			message = 'Invalid email format';
		}

		if (message){
			store_set('snackbar_message', message);
			store_set('snackbar_status', true);
			return;
		}

		this.setState({loading: true});

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_contact_our_support,
			data: {
				name: this.state.name,
				email: this.state.email,
				subject: this.state.subject,
				text: this.state.text
			},
			success: function(data){
				if (data.status === 'success'){
					this.setState({text: ''});
					this.setState({subject: ''});
					store_set('snackbar_message', 'Success!');
					store_set('snackbar_status', true);
				}
				else{
					store_set('snackbar_message', 'Error sending your message, please try again.');
					store_set('snackbar_status', true);
				}
			}.bind(this),
			error: function(){
				store_set('snackbar_message', 'Error sending your message, please try again.');
				store_set('snackbar_status', true);
			},
			complete: function(){
				this.setState({loading: false});
			}.bind(this)
		});
	}

	render() {
		return (
			<div className="contact-desktop">
				<div className="contact-content-dekstop">
					<div className="contact-title-desktop">
						<div className="contact-title-text-desktop">Contact Us</div>
						<div className="contact-title-subtitle-desktop">Let us know how we can help you =)</div>
						<div className="contact-title-line-desktop" />
					</div>
					<div className="contact-content-desktop">
						<Textfield
							fullWidth={true}
							className="contact-textfield-desktop"
							value={this.state.name}
							label="Name"
							onChange={(value) => this.setState({name: value})}
						/>
						<Textfield
							fullWidth={true}
							className="contact-textfield-desktop"
							value={this.state.email}
							label="E-mail"
							onChange={(value) => this.setState({email: value})}
						/>
						<Textfield
							fullWidth={true}
							className="contact-textfield-desktop"
							value={this.state.subject}
							label="Subject"
							onChange={(value) => this.setState({subject: value})}
						/>
						<Textfield
							fullWidth={true}
							className="contact-textfield-desktop"
							value={this.state.text}
							multiline={true}
							rows={10}
							placeholder="Write your message here..."
							onChange={(value) => this.setState({text: value})}
						/>
						<div className="contact-buttons-desktop">
							<Link to="/" className="contact-back-button-desktop">
								<span className="material-icons">keyboard_return</span>
								<span>BACK</span>
							</Link>
							<Button value="SEND" onClick={this.send}/>
						</div>
					</div>
				</div>
				<Backdrop open={this.state.loading} style={{zIndex: 1}}>
					<CircularProgress />
				</Backdrop>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps() {
	return {};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopContact);