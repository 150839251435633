import '@capacitor/core';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import PlatformController from './PlatformController';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import storage from '@Storage';
import SplashScreen from '@views/SplashScreen.js';
import { isMobile } from 'react-device-detect';

// import the store created in the store.js file
import { store, store_persistor } from './store';

// Constant Imports
import * as constants from '@constants';

// Import the event bus
import event_bus from './Eventbus.js';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Actions import
import {
	store_set,
	reset_store,
	load_version_info,
	get_base_version,
	update_undefined_props
} from '@actions/GlobalActions.js';

// Version imports
import version from '@Version';

// Import the controllers
import player from '@Player';

// Capgo 
import { CapacitorUpdater } from '@capgo/capacitor-updater';

class Index extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			ready: false
		};

		update_undefined_props();

		this.probe_version = this.probe_version.bind(this);
	}

	componentDidMount(){
		CapacitorUpdater.notifyAppReady();

		CapacitorUpdater.current().then(res => {
			if (res.bundle.version === 'builtin'){
				Preferences.set({ key: 'base_version', value: version.base_version });
			}
		});
	}

	async probe_version(){
		const base_version = await get_base_version();

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_probe_version,
			data: {
				platform: Capacitor.getPlatform(),
				api_version: version.api_version,
				app_version: version.app_version,
				base_version: base_version
			},
			success: function(data){
				// For web devices, if the update is required:
				// reset the store, reload the page and redirect to onboarding page
				const force_update = ['force', 'force_base'].includes(data.is_update_necessary);
				if (force_update && Capacitor.getPlatform() === 'web'){
					reset_store(); player.reset();
					window.location.href = '/onboarding/'; return;
				}

				const on_complete = () => {
					// Also check if the licenses & copyright step should be displayed (only App)
					store_set('display_licenses_message', data.display_licenses_message && Capacitor.getPlatform() !== 'web');

					// And the Beta version message
					store_set('display_beta_message', data.display_beta_message);

					storage.set('app_version', version.app_version);
					storage.set('api_version', version.api_version);

					this.setState({ ready: true });

					// Finally, emit store loaded
					if (!force_update && isMobile){
						event_bus.emit('store-loaded');
					}
				};

				load_version_info(data)
					.then(on_complete)
					.catch(on_complete);

			}.bind(this),
			error: function(){
				this.setState({ ready: true });
			}.bind(this)
		});
	}

	render(){
		return (
			<PersistGate
				loading={<SplashScreen/>}
				onBeforeLift={this.probe_version}
				persistor={store_persistor}
			>
				<PlatformController ready={this.state.ready} />
			</PersistGate>
		);
	}
}

ReactDOM.render(
	<Provider store={store}>
		<Index/>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
