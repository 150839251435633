import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import posed from 'react-pose';
import Haptic from '@components/haptic.js';
import Ripples from 'react-ripples';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Import the controllers
import playlist_manager from '@/PlaylistsController.js';

// import the request_buffer
import { CircularProgress } from '@material-ui/core';

const PosedPlaylistOverlay = posed.div({
	full: {
		height: ({height}) => { return height ? height : 'initial'; },
		borderTop: 'solid 4px #707070'
	},
	change: {
		height: ({height}) => { return height; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

class PlaylistOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			pose_type: 'hidden',
			pose_height: 0
		};
	}

	shouldComponentUpdate(nextProps){
		// For our awesome animation to work properly, we need to set the exact content height depending on what we want
		// to display to the user. So before the component update, we will set the type and height for the next pose state.
		// Here let's check if the height and type should be updated.
		if (
			this.props.active_overlay !== nextProps.active_overlay ||
			this.props.playlist_overlay_option !== nextProps.playlist_overlay_option 
		){
			var new_type = '';
			var new_height = '';
			var playlist_info = nextProps.playlist_info;
			var small_screen = window.innerWidth <= 300;
			var item_height = small_screen ? 36 : 54;

			if (nextProps.active_overlay === 'playlists_options') {
				switch (nextProps.playlist_overlay_option){
				case 'show_all':
					var remove_button = playlist_info.is_author ? item_height : 0;
					var find_similar_button = playlist_info.songs.length > 0 ? item_height : 0;
					var sync_button = playlist_info.is_author && (!playlist_info.dsps.includes('spotify') || !playlist_info.dsps.includes('deezer')) ? item_height : 0;
					var dsp_button = playlist_info.is_author && playlist_info.dsps.length > 0 ? item_height : 0;

					new_height = (small_screen ? 143 : 224) + remove_button + find_similar_button + dsp_button + sync_button;
					new_type = 'full';
					break;

				case 'sync_with_dsps':
					new_height = small_screen ? 244 : 264;
					new_type = 'change';
					break;

				case 'show_internal_playlists':
					new_height = small_screen ? 190 + nextProps.playlists.length*43 : 183 +  nextProps.playlists.length*49;
					new_type = 'full';
					break;

				case 'confirm_unsync_playlist':
					new_height = small_screen ? 248 : 320; 
					new_type = 'change';
					break;

				case 'confirm_delete_playlist':
					new_height = small_screen ? 145 : 175;
					new_type = 'change';
					break;

				default:
					break;
				}
			} else {
				new_height = 0;
				new_type = 'hidden';
			}
			this.setState({pose_type: new_type});
			this.setState({pose_height: new_height});
		}

		return true;
	}

	render(){
		const {
			active_playlist,
			chosen_dsp,
			load_recommendations,
			loading_playing_sync,
			playlist_info,
			playlists,
		} = this.props;

		const playlist_overlay_option = this.props.playlist_overlay_option ? this.props.playlist_overlay_option : 'show_all';
		const linked_accounts = this.props.linked_accounts.filter(a => a.state === 'active').map((a) => a.platform);

		return (
			<PosedPlaylistOverlay
				pose={this.state.pose_type}
				height={this.state.pose_height}
				className='overlay-main'
			>
				{playlist_overlay_option === 'confirm_delete_playlist' &&
					<div className='overlay-confirm-create-playlist'>
						<div className='overlay-confirm-playlist-title'>Delete Playlist</div>
						<div className='overlay-confirm-playlist-text'>Are you sure you want to delete &apos;{playlist_info.name}&apos;? This action is irreversible.</div>
						<div className='overlay-confirm-buttons'>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-confirm-yes-button' onClick={() => store_set('active_overlay', false)}>CANCEL</div>
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-confirm-no-button' onClick={() => playlist_manager.remove_playlist()}>YES, DELETE</div>
								</Haptic>
							</Ripples>
						</div>
					</div>
				}
				{playlist_overlay_option === 'sync_with_dsps' &&
					<React.Fragment>
						<div className='overlay-playlist-info'>
							<div className='overlay-confirm-playlist-title'>Playlist Syncing</div>
							<div className='overlay-confirm-playlist-text'>When synced, any changes on your Magroove Playlist will be automatically applied to other platforms.</div>
						</div>
						<div className='overlay-track-options'>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-playlist-dsp-item' onClick={(e) => {e.stopPropagation(); playlist_manager.sync_internal_playlist(playlist_info, 'spotify');}}>
										<div className='overlay-playlist-dsp-item-info'>
											<div className='overlay-track-icon'>
												<img src={assets.spotify_round_icon} alt='spotify-round-icon'/>
											</div>
											<div className='overlay-track-item-text'>Spotify</div>
											<div className='overlay-playlist-dsp-item-status'
												style={{color: linked_accounts.includes('spotify') ? '#ec6b43' : '#707070'}}
											>
												{linked_accounts.includes('spotify') ? 'Active' : 'Not Active'}
											</div>
										</div>
										{loading_playing_sync === 'spotify' ?
											<CircularProgress size={20} style={{marginRight: 5}}/>
											:
											<span className='material-icons overlay-playlist-dsp-item-button'
												style={{color: !playlist_info.dsps.includes('spotify') ? '#fff' : '#ec6b43'}}
											>
												{!playlist_info.dsps.includes('spotify') ? 'chevron_right' : 'close'}
											</span>
										}
									</div>
								</Haptic>
							</Ripples>
							{/* ===== TO BE IMPLEMENTED LATER WITH APPLE MUSIC SYNC =====
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-playlist-dsp-item' onClick={(e) => {e.stopPropagation(); playlist_manager.sync_internal_playlist(playlist_info, 'apple')}}>
											<div className='overlay-playlist-dsp-item-info' >
												<div className='overlay-track-icon'>
													<img src={assets.apple_music_icon} alt='apple-music-icon'/>
												</div>
												<div className='overlay-track-item-text'>Apple Music</div>
												<div className='overlay-playlist-dsp-item-status'
													style={{color: linked_accounts.includes('apple') ? '#ec6b43' : '#707070'}}
												>
													{linked_accounts.includes('apple') ? 'Active' : 'Not Active'}
												</div>
											</div>
											{loading_playing_sync === 'apple' ?
												<CircularProgress size={20} style={{marginRight: 5}}/>
											:
												<span className='material-icons overlay-playlist-dsp-item-button'
													style={{color: !playlist_info.dsps.includes('apple') ? '#fff' : '#ec6b43'}}
												>
													{!playlist_info.dsps.includes('apple') ? 'chevron_right' : 'close'}
												</span>
											}
										</div>
									</Haptic>
								</Ripples>
							*/}
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-playlist-dsp-item' onClick={(e) => {e.stopPropagation(); playlist_manager.sync_internal_playlist(playlist_info, 'deezer');}}>
										<div className='overlay-playlist-dsp-item-info'>
											<div className='overlay-track-icon'>
												<img src={assets.deezer_icon} alt='deezer-icon'/>
											</div>
											<div className='overlay-track-item-text'>Deezer</div>
											<div className='overlay-playlist-dsp-item-status'
												style={{color: linked_accounts.includes('deezer') ? '#ec6b43' : '#707070'}}
											>
												{linked_accounts.includes('deezer') ? 'Active' : 'Not Active'}
											</div>
										</div>
										{loading_playing_sync === 'deezer' ?
											<CircularProgress size={20} style={{marginRight: 5}} />
											:
											<span className='material-icons overlay-playlist-dsp-item-button'
												style={{color: !playlist_info.dsps.includes('deezer') ? '#fff' : '#ec6b43'}}
											>
												{!playlist_info.dsps.includes('deezer') ? 'chevron_right' : 'close'}
											</span>
										}
									</div>
								</Haptic>
							</Ripples>
						</div>
					</React.Fragment>
				}
				{playlist_overlay_option === 'confirm_unsync_playlist' &&
					<React.Fragment>
						<div className='overlay-track-info' onClick={(e) => e.stopPropagation()}>
							<img
								alt='DSP Icon'
								src={chosen_dsp === 'spotify' ? assets.spotify_round_icon : assets.deezer_icon}
								className='overlay-track-cover'
							/>
							<div className='overlay-playlist-info-text'>
								<div className='overlay-playlist-title'>{chosen_dsp}</div>
								<div className='overlay-playlist-subtitle'>Active</div>
							</div>
						</div>
						{!loading_playing_sync &&
							<div className='overlay-confirm-create-playlist'>
								<div className='overlay-confirm-playlist-title'>Unsync Playlist</div>
								<div className='overlay-confirm-playlist-text'>Are you sure you want to unsync &apos;{playlist_info.name}&apos; from {chosen_dsp}?</div>
								<div className='overlay-confirm-playlist-text'>Any changes on your Magroove Playlist will no longer be applied to other platforms.</div>
								<div className='overlay-confirm-buttons'>
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-confirm-yes-button' onClick={() => store_set('active_overlay', false)}>CANCEL</div>
										</Haptic>
									</Ripples>
									<Ripples>
										<Haptic intesity='light'>
											<div className='overlay-confirm-no-button' onClick={() => playlist_manager.unsync_internal_playlist(playlist_info, chosen_dsp)}>YES, UNSYNC</div>
										</Haptic>
									</Ripples>
								</div>
							</div>
						}
					</React.Fragment>
				}
				{playlist_overlay_option === 'show_internal_playlists' &&
					<React.Fragment>
						<div className='overlay-show-playlists-info' onClick={(e) => e.stopPropagation()}>
							<div className='overlay-playlist-info-text'>
								<div className='overlay-playlist-title'>Choose a playlist</div>
								<div className='overlay-playlist-subtitle'>Your likes will be redirected to the chosen playlist</div>
								<span className='material-icons' onClick={() => store_set('active_overlay', false)}>close</span>
							</div>
						</div>
						<div className='overlay-track-options'>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-show-playlist-item' onClick={() => {store_set('create_playlist', true); store_set('active_overlay', false);}}>
										<div className='overlay-track-item-text overlay-show-playlist-item-new-playlist'>Add New</div>
									</div>
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-show-playlist-item' onClick={() => playlist_manager.update_active_playlist(false)}>
										<div className='overlay-show-playlist-item-block'>
											<div className='overlay-track-item-text'>My List</div>
											<div className='overlay-track-item-subtext'>Your likes are always saved on your list by default</div>
										</div>
										<span className='material-icons'>check</span>
									</div>
								</Haptic>
							</Ripples>
							{playlists.map((playlist, index) => (
								<Ripples key={index}>
									<Haptic intesity='light'>
										<div className='overlay-show-playlist-item' onClick={() => playlist_manager.update_active_playlist(playlist.hash_id)}>
											<div className='overlay-track-item-text'>{playlist.name}</div>
											{active_playlist.hash_id === playlist.hash_id &&
												<span className='material-icons'>check</span>
											}
										</div>
									</Haptic>
								</Ripples>
							))}
						</div>
					</React.Fragment>
				}
				{(playlist_overlay_option === 'loading' || loading_playing_sync) &&
					<div className='overlay-playlist-feedback'>
						<CircularProgress />
					</div>
				}
				{playlist_overlay_option === 'show_all' &&
					<React.Fragment>
						<div className='overlay-track-info' onClick={(e) => e.stopPropagation()}>
							<img
								alt='Playlist Cover'
								src={playlist_info.image_link}
								onError={(ev) => ev.target.src = assets.default_playlist_image}
								className='overlay-track-cover'
							/>
							<div className='overlay-playlist-info-text'>
								<div className='overlay-playlist-title'>{playlist_info.name}</div>
								<div className='overlay-playlist-subtitle'>Playlist ⸱ {playlist_info.songs.length} songs</div>
								{playlist_info.is_author ?
									<div className='overlay-playlist-subtitle' style={{ color: playlist_info.is_active ? '#ec6b43' : '#707070'}}>{playlist_info.is_active ? 'Active' : 'Not Active'}</div>
									: null
								}
							</div>
						</div>
						<div className='overlay-track-options'>
							<Ripples>
								<Haptic intesity='light'>
									{playlist_info.is_author ?
										<div className='overlay-track-item' onClick={() => {store_set('edit_playlist', true); store_set('active_overlay', false);}}>
											<div className='material-icons'>edit</div>
											<div className='overlay-track-item-text'>Edit Playlist</div>
										</div>
										:
										<div className='overlay-track-item' onClick={() => {playlist_manager.duplicate(); store_set('active_overlay', false);}}>
											<div className='material-icons'>control_point_duplicate</div>
											<div className='overlay-track-item-text'>Duplicate Playlist</div>
										</div>
									}
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-track-item' onClick={() => playlist_manager.export_internal_playlist()}>
										<div className='material-icons'>open_in_new</div>
										<div className='overlay-track-item-text'>Export to streaming apps</div>
									</div>
								</Haptic>
							</Ripples>
							{playlist_info.is_author && playlist_info.dsps.length > 0 &&
								playlist_info.dsps.map((dsp, index) => (
									<Ripples key={index}>
										<Haptic intesity='light'>
											<div className='overlay-track-item' onClick={() => {store_set('playlist_overlay_option', 'confirm_unsync_playlist'); store_set('chosen_dsp', dsp);}}>
												<div className='material-icons'>sync_disabled</div>
												<div className='overlay-track-item-text'>Unsync from {dsp.charAt(0).toUpperCase() + dsp.slice(1)}</div>
											</div>
										</Haptic>
									</Ripples>
								))
							}
							{playlist_info.is_author && (!playlist_info.dsps.includes('spotify') || !playlist_info.dsps.includes('deezer')) &&
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => store_set('playlist_overlay_option', 'sync_with_dsps')}>
											<div className='material-icons'>sync</div>
											<div className='overlay-track-item-text'>Sync Playlist</div>
										</div>
									</Haptic>
								</Ripples>
							}
							{playlist_info.songs.length > 0 &&
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => load_recommendations(playlist_info, 'playlist')}>
											<div className='overlay-track-options-icon'>
												<img src={assets.find_similar_icon} alt='find-similar-icon'/>
											</div>
											<div className='overlay-track-item-text'>Find Similar</div>
										</div>
									</Haptic>
								</Ripples>
							}
							{playlist_info.is_author &&
								<Ripples>
									<Haptic intesity='light'>
										<div className='overlay-track-item' onClick={() => store_set('playlist_overlay_option', 'confirm_delete_playlist')}>
											<div className='material-icons'>delete</div>
											<div className='overlay-track-item-text'>Remove</div>
										</div>
									</Haptic>
								</Ripples>
							}
						</div>
					</React.Fragment>
				}
			</PosedPlaylistOverlay>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		active_overlay: state.GlobalReducer.active_overlay,
		active_playlist: state.GlobalReducer.active_playlist,
		chosen_dsp: state.GlobalReducer.chosen_dsp,
		linked_accounts: state.GlobalReducer.linked_accounts,
		loading_playing_sync: state.GlobalReducer.loading_playing_sync,
		playlist_info: state.GlobalReducer.playlist_info,
		playlist_overlay_option: state.GlobalReducer.playlist_overlay_option,
		playlists: state.GlobalReducer.playlists,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(PlaylistOverlay));
