import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';
import {Img} from 'react-image';
import LinesEllipsis from 'react-lines-ellipsis';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { Capacitor } from '@capacitor/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

// CSS Imports
import './styles/Search.css';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Card from '@components/Card.js';
import RotateSentences from '@components/RotateSentences.js';
import UserMessage from '@components/UserMessage.js';
import Quiz from './Quiz.js';
import { ObsessionShare } from '@components/ObsessionPopup.js';

// Constants import
import * as constants from '@constants';

// Import Request Buffer
import { request_buffer } from '../RequestBuffer.js';

// Actions import
import { store_get, store_set, clear_all_overlays, execute_post_login_action } from '@/actions/GlobalActions.js';
import { redirect } from '@routes/Routes.js';

// Import the controllers
import ads from '@Ads';
import queue from '@Queue';
import list from '@List';
import user from '@User';
import app_analytics from '@Analytics';
import subscription from '@Subscription';
import export_track from '@Export';
import social from '@Social';
import storage from '@Storage';

// Import the event bus
import event_bus from '@Eventbus';

function display_loading_overlay(text, top_text = false) {
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

class Search extends Component {
	constructor(props){
		super(props);
		this.last_ad = false;
		this.state = {
			ordered_data: [],
			loading_result: false,
			loading_more: true,
			display_feedback: false,
			feedback_reason: '',
			nothing_all: false,
			songs: [],
			albums: [],
			artists: [],
			profiles: [],
			show_all_songs: false,
			show_all_artists: false,
			show_all_moods: false,
			show_all_genres: false,
			display_results_type: 'all',
			entities: this.props.url ? this.props.url.params.entities.split(',') : ['artist', 'album', 'song', 'profile', 'playlist'],
			// The Search component may be used as default on search page or
			// internally as a step of another feature (friends' recommendations, polls, answers etc)
			default_search_page: this.props.location === 'search' ? true : false,
		};

		clear_all_overlays();

		this.handle_card_click = this.handle_card_click.bind(this);
		this.input_changed = this.input_changed.bind(this);
		this.search = this.search.bind(this);
		this.second_search = this.second_search.bind(this);
		this.search_callback = this.search_callback.bind(this);
		this.order_by_levenshtein = this.order_by_levenshtein.bind(this);
		this.search_debounced = _.debounce(this.profile_search, 1000);
		this.reset_value = this.reset_value.bind(this);
		this.load_recommendations = this.load_recommendations.bind(this);
		this.follow_profile = this.follow_profile.bind(this);
		this.profile_search = this.profile_search.bind(this);
		this.loading_progress = this.loading_progress.bind(this);
		this.complete_loading = this.complete_loading.bind(this);
		this.select_search_item = this.select_search_item.bind(this);
		this.display_ads = this.display_ads.bind(this);

		store_set('search_query', '');

		const url_string = window.location.href;
		const url = new URL(url_string);

		store_set('expand_search', false);
		store_set('search_progress', false);
		store_set('total_search_progress', 0);
		store_set('display_results', false);
		store_set('reset_value', this.reset_value);
		store_set('input_changed', this.input_changed);

		const search_query = url.searchParams.get('q');
		if (search_query) {
			store_set('search_query', search_query);
			store_set('expand_search', true);

			this.state.loading_result = true;
			this.search_debounced(false);
		}

		event_bus.on('back-button', () => {
			redirect(this.props.previous_page);
		});

		const user_token = url.searchParams.get('token');
		if (this.props.logging_in && user_token) {
			this.log_user_in(user_token, url);
		}

		// If we are only use the search component, we display only song results
		if (!this.state.default_search_page) {
			this.state.display_results_type = this.state.entities.length > 1 ? 'all' : this.state.entities[0];
		}
	}

	componentDidMount() {
		const url_string = window.location.href;
		const url = new URL(url_string);
		const user_token = url.searchParams.get('token');
		if (this.props.logging_in && user_token) {
			this.log_user_in(user_token, url);
		}

		const date = store_get('trending_date');
		const reload = !date || moment(date).isBefore(moment().subtract(1, 'days'));

		const { trending_songs, trending_artists } = this.props;
		if (reload || trending_songs.length === 0 || trending_artists.length === 0) {
			social.get_trending();

			store_set('trending_date', new Date());
		}
	}

	loading_progress(){
		const progress = this.props.total_search_progress;
		// Here we will update the progress value.
		// Since we don't know when the stats data will be ready, we cannot let the progress reach 100.
		// As the value reaches higher values we will also increase the interval.
		const time = progress === 40 ? 1500 : progress === 70 ? 2000 : false;

		// If reaches this value, we don't want to keep increasing if the results aren't ready.
		if (this.props.total_search_progress > 80){
			clearInterval(this.loading_interval); return;
		}

		if (time){
			clearInterval(this.loading_interval);
			this.loading_interval = setInterval(() => this.loading_progress(), time);
		}
		store_set('total_search_progress', progress + 5);
	}

	complete_loading() {
		clearInterval(this.loading_interval);
		this.loading_interval = false;
		store_set('total_search_progress', 100);
	}

	display_ads(){
		if (subscription.is_valid()) return false;
		if (storage.get('new_member')) return false;
		if (!this.last_ad || moment(this.last_ad).isBefore(moment().subtract(5, 'minutes'))){
			this.last_ad = new Date();
			ads.show_interstitial();
			return true;
		}
		return false;
	}

	log_user_in(user_token, url){
		store_set('user_token', user_token);
		store_set('logging_in', false);

		const new_member = url.searchParams.get('new');
		if (new_member) {
			// Let's trigger events for specific platforms. This is the flow for users that logged
			// in by web (which means he did NOT accessed app installed by android or ios)
			app_analytics.log_event('signup_all');

			if (Capacitor.getPlatform() === 'web') {
				app_analytics.log_event('signup_web');
			}
		}

		// Call the post login action in case there's
		// something to be executed after login, but
		// only after setting premium pricing to make
		// sure the user is in the right group test.
		display_loading_overlay('Logging in...');
		user.set_pricing().then(() => execute_post_login_action(new_member));

		list.load(); user.load();
	}

	handle_card_click(seed) {
		// Trigger an event
		app_analytics.log_event('result_click');

		this.load_recommendations(seed, seed.type);
		store_set('seed', seed);

		// Add the new seed to the list
		if (this.props.search_query) {
			var recent_seeds = [...this.props.recent_seeds];
			recent_seeds = recent_seeds.filter(item => (item.hash_id ? item.hash_id : item.spotify_id) !== seed.spotify_id);
			recent_seeds.unshift(seed);
			store_set('recent_seeds', recent_seeds);
		}
	}

	input_changed(event) {
		// Check if event possess target atribute
		let search_query = '';
		if (event.target) {
			search_query = event.target.value;
		} else {
			search_query = event;
			store_set('expand_search', true);
		}
		this.setState({ display_feedback: false });

		store_set('search_query', search_query);

		if (search_query !== '') {
			if (this.props.display_results) {
				store_set('display_results', false);
			}
			this.setState({nothing_all: false});
			this.setState({loading_result: true});

			this.search_debounced();

			const pages_with_progress = ['poll-recommendation-search', 'recommendation-search','obsession-search', 'edit-profile-search'];
			if (pages_with_progress.includes(this.props.location)) {
				if (!this.loading_interval) {
					store_set('total_search_progress', 0);
					this.loading_interval = setInterval(this.loading_progress, 1000);
				}

				store_set('search_progress', true);
				store_set('is_footer_progress', true);
			}
		}
	}

	search(componentMounted = true) {
		const current_query = this.props.search_query;

		// Now, before we request from API, just check if the search is already saved in localstorage
		const profiles = this.state.profiles;

		const saved_results = storage.get('search_' + current_query);
		if (saved_results){
			const ordered_data = saved_results.ordered_data.filter(item => item.type !== 'profile');

			if (componentMounted) {
				this.setState({
					ordered_data: [...ordered_data].concat([...profiles]),
					loading_result: false,
					loading_more: false
				});
			} else {
				/* eslint-disable */
				this.state.ordered_data = [...ordered_data].concat([...profiles]);
				this.state.loading_result = false;
				this.state.loading_more = false;
				/* eslint-enable */
			}
			store_set('display_results', true);
			this.complete_loading();
			return;
		}

		let event_details = {
			event_name: 'search',
			event_source_url: window.location.pathname,
			custom_params: {},
			device_info: this.props.device_info,
			member_hash: this.props.user.hash_id
		};

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_internal_search,
			data: {
				search_query: this.props.search_query,
				event_details: JSON.stringify(event_details)
			},
			success: function (data) {
				const out_of_date = this.props.search_query !== current_query;
				if (data.status !== 'success' || out_of_date) return;
				this.search_callback([], data.data, profiles);
				
				if (!subscription.is_valid()){
					app_analytics.user_event('non_premium_search_song');
				}
			}.bind(this),
			complete: function(){
				if (this.props.search_query === current_query){
					this.second_search(current_query);
				}
			}.bind(this)
		});
	}

	second_search(query) {
		this.setState({ loading_more: true });
		const current_query = query;
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_search,
			data: { search_query: current_query },
			success: function (data) {
				const out_of_date = this.props.search_query !== current_query;
				if (data.status !== 'success' || out_of_date) return;
				if (
					data.data.songs.length === 0 &&
					data.data.albums.length === 0 &&
					data.data.artists.length === 0 &&
					this.state.songs.length === 0 &&
					this.state.albums.length === 0 &&
					this.state.artists.length === 0
				){
					this.setState({feedback_reason: 'nothing found'});
					this.setState({display_feedback: true});
					return;
				}
				this.search_callback(this.state.ordered_data, data.data);
			}.bind(this),
			error: function () {
				store_set('display_results', true);
				this.setState({ loading_result: false, feedback_reason: 'error', display_feedback: true });
			}.bind(this),
			complete: function(){
				store_set('display_results', true);
				this.setState({loading_result: false, loading_more: false});
			}.bind(this)
		});
	}

	profile_search() {
		let profiles = [];
		const current_query = this.props.search_query;

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_profile_search,
			data: { search_query: this.props.search_query },
			success: function (data) {
				const out_of_date = this.props.search_query !== current_query;
				if (data.status !== 'success' || out_of_date) return;
				profiles = this.order_by_levenshtein([], data.profiles, 'profiles');
			}.bind(this),
			complete: function () {
				this.setState({ profiles: profiles });
				this.search(current_query);
			}.bind(this),
		});
	}

	search_callback(current_results, new_data, extra_data=[]){
		let new_results = [...new_data.artists, ...new_data.albums, ...new_data.songs];
		if (new_data.playlists){
			new_results = new_results.concat(new_data.playlists);
		}
		let ordered_results = this.order_by_levenshtein(current_results, new_results);

		// Display one result of each type in the recommendations section
		const first_artist = ordered_results.splice(ordered_results.findIndex(item => item.type === 'artist'), 1);
		const first_album = ordered_results.splice(ordered_results.findIndex(item => item.type === 'album'), 1);
		const first_song = ordered_results.splice(ordered_results.findIndex(item => item.type === 'song'), 1);
		if (first_song.length) {
			ordered_results.unshift(first_song[0]);
		}
		if (first_album.length) {
			ordered_results.unshift(first_album[0]);
		}
		if (first_artist.length) {
			ordered_results.unshift(first_artist[0]);
		}

		const final_results = [...ordered_results, ...extra_data];

		this.setState({
			ordered_data: final_results,
			loading_result: final_results.length === 0
		});

		// We want to go ease with API, so we don't need to call it everytime
		// So let's save the search_query in local storage
		const save_results = {
			'ordered_data': final_results,
			'ordered_data_time': new Date()
		};
		const four_months = 1000 * 60 * 60 * 24 * 30 * 4;
		storage.set('search_' + this.props.search_query, save_results, four_months);
		store_set('display_results', final_results.length > 0);
		this.complete_loading();
	}

	order_by_levenshtein(previous_data, new_data, type_data = 'dafault') {
		// This function orders the results by the levenshtein distance
		// This is used to display the most relevant results first
		let temp_data = [...previous_data];

		if (type_data === 'profiles') {
			for (let new_item of new_data) { // eslint-disable-line
				const already_added = temp_data.find(item => item.member_id === new_item.member_id);
				if (!already_added) {
					temp_data.unshift(new_item);
				}
			}
			temp_data = temp_data.sort((a, b) => b.levenshtein_distance - a.levenshtein_distance || b.followers - a.followers);
		}
		else {
			for (let new_item of new_data){ // eslint-disable-line
				const already_added = temp_data.find(item => item.spotify_id && item.spotify_id === new_item.spotify_id);
				if (!already_added){
					temp_data.unshift(new_item);
				}
			}
			temp_data = temp_data.sort((a, b) => b.levenshtein_distance - a.levenshtein_distance || b.popularity - a.popularity);
		}

		return temp_data;
	}

	reset_value() {
		store_set('expand_search', false);
		this.setState({
			display_feedback: false,
			nothing_all: false,
			loading_result: false,
			loading_more: false
		});
		this.complete_loading();
		store_set('is_footer_progress', false);
		store_set('total_search_progress', 0);
		store_set('selected_search_items', []);
		store_set('footer_progress_allow_next', false);
	}

	load_recommendations(seed, entity){
		const text = ['artist', 'genre', 'mood', 'playlist'].includes(entity) ? seed.name : seed.title;
		display_loading_overlay(text, 'Now searching similar to:');
 
		// Display ad while the results are being loaded
		const displaying_ads = this.display_ads();
 
		queue.get_recommendations(seed.spotify_id || seed.hash_id, entity, this.props.search_query, true, displaying_ads);
		store_set('active_overlay', false);
	}

	follow_profile(user_id) {
		if (!user_id) { return; }
		let ordered_data = [...this.state.ordered_data];
		let profile = ordered_data.find(u => u.type === 'profile' && u.member_id === user_id);
		let user = this.props.user;

		if (profile) {
			profile['followed'] = !profile.followed;
			if (!profile.followed) {
				profile['followers']--;
				user.following--;
			}
			else {
				profile['followers']++;
				user.following++;
			}
			this.setState({ ordered_data: ordered_data });
			store_set('user', user);

			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_follow_user_management,
				data: { user_id: user_id },
			}, true);
		}
	}

	// In case of search component as part of a step by step (friend's recommendation, polls, etc)
	// we may select one items (in the future, we may select more) 
	select_search_item(item) {
		const new_array = [...this.props.selected_search_items];
		const current = [...this.props.already_selected_search_items];
		const already_added_index = new_array.findIndex(i => i.spotify_id === item.spotify_id);

		if (['poll-recommendation-search', 'recommendation-search', 'obsession-search'].includes(this.props.location) && new_array.length > 0) {
			new_array.pop();
			new_array.push(item);
		} else if (this.props.location === 'edit-profile-search' && already_added_index >= 0){
			new_array.splice(already_added_index, 1);
		} else if (this.props.location === 'edit-profile-search' && (current.length + new_array.length) >= 3){
			return false;
		} else {
			new_array.push(item);
		}

		store_set('selected_search_items', new_array);
		store_set('footer_progress_allow_next', true);
		if (this.props.footer_progress_index === 0) {
			store_set('is_footer_progress', true);
		}
	}

	render() {
		// Here we make sure results_container always shows the first results starting at the top
		const results_container = document.getElementById('search-results-container');
		if (results_container) {
			results_container.scrollTop = 0;
		}

		// Separates artists and removes duplicates from suggestions list
		const artists_only = this.props.suggestions && Array.from(this.props.suggestions, item => item.artist);
		const artists_list = artists_only.filter((artist, index) => index === artists_only.findIndex(other => artist.spotify_id === other.spotify_id));

		// Displays partial/full list of artists/songs depending on 'show more/less' button
		const artists = this.state.show_all_artists ? artists_list : artists_list.slice(0, 8);
		const songs = this.state.show_all_songs ? this.props.suggestions : this.props.suggestions.slice(0, 7);

		const { suggestions_loading, trending_albums, trending_artists, trending_songs, user_synced } = this.props;
		const { display_feedback, display_results_type, entities, feedback_reason, loading_more, loading_result, ordered_data, default_search_page } = this.state;

		let results_slice = 0; // Show the recommended section if default search page
		if (default_search_page) results_slice = 3;

		const selected_icon = this.props.location === 'edit-profile-search' ? 'checkbox' : 'normal';
		if (this.props.footer_progress_index === this.props.footer_progress_length - 1 && ['recommendation-search', 'poll-recommendation-search'].includes(this.props.location)) {
			return(
				<UserMessage />
			);
		} else if (this.props.footer_progress_index === this.props.footer_progress_length - 1 && this.props.location === 'obsession-search'){
			return(
				<ObsessionShare user_info={store_get('user')} onClose={() => redirect('/profile/')} />
			);
		} else if (this.props.location === 'quiz-search'){
			return(
				<Quiz props={this.props} state={this.state} change_result_type={(type) => this.setState({display_results_type: type})}/>
			);
		} else {
			return (
				<div className='search-main'>
					{loading_result &&
						<div className='search-loading-container'>
							<CircularProgress size='50px'/>
							<RotateSentences
								interval={2000}
								options={[
									'Stimulating artificially intelligent ears…',
									'Polarizing sound waves…',
									'Loading first batch notes…',
									'Harmonizing with your taste…',
									'Calibrating personality matrix…',
									'Initializing groovy algorithm…',
									'Mapping influence attributes…',
									'Cherry-picking gems…',
									'Eliminating songs everyone knows…'
								]}
							/>
						</div>
					}
					{!display_feedback && this.props.display_results && !loading_result &&
						<React.Fragment>
							{loading_more && default_search_page &&
								<div className='search-results-load-more'>
									<LinearProgress />
								</div>
							}
							<div className='search-container'>
								{default_search_page && ordered_data.slice(0, results_slice).length > 0 &&
									<div className='search-results-recommended'>
										<div className='search-results-section-title'>Recommended for you</div>
										<div className='search-results-recommended-content'>
											{ordered_data.slice(0, results_slice).map((item, index) => {
												if (item.type === 'song'){
													return (
														<Card data={item} type='big_card_song' displayLabel={true} onClick={() => this.handle_card_click(item)} onMoreClick={() => {store_set('song_info', item); store_set('active_overlay', 'track_options');}} key={item.spotify_id + index}/>
													);
												} else if (item.type === 'album'){
													return (
														<Card data={item} type='big_card_album' displayLabel={true} onClick={() => {this.handle_card_click(item);}} key={item.spotify_id + index} />
													);
												}
												return (
													<Card data={item} type='big_card_artist' onClick={() => this.handle_card_click(item)} key={item.spotify_id + index} />
												);
											})}
										</div>
									</div>
								}
								{(default_search_page || entities.length > 1) &&
									<div style={{position: 'relative'}}>
										<div className='search-results-section-options' style={{marginTop: default_search_page ? 20 : 5}}>
											<div className={'search-results-section-option-item' + (display_results_type === 'all' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'all'})}>All results</div>
											{entities.includes('profile') &&
												<div className={'search-results-section-option-item' + (display_results_type === 'profile' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'profile'})}>Profiles</div>
											}
											{entities.includes('playlist') &&
												<div className={'search-results-section-option-item' + (display_results_type === 'playlist' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'playlist'})}>Playlists</div>
											}
											{entities.includes('song') &&
												<div className={'search-results-section-option-item' + (display_results_type === 'song' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'song'})}>Songs</div>
											}
											{entities.includes('album') &&
												<div className={'search-results-section-option-item' + (display_results_type === 'album' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'album'})}>Albums</div>
											}
											{entities.includes('artist') &&
												<div className={'search-results-section-option-item' + (display_results_type === 'artist' ? '-selected' : '')} onClick={() => this.setState({display_results_type: 'artist'})}>Artists</div>
											}
										</div>
										<div className='search-results-gradient'></div>
									</div>
								}
								<div className='search-results-container' id='search-results-container'>
									{ordered_data.slice(results_slice, ordered_data.length).map((item, index) => {
										const display_all = display_results_type === 'all' && entities.includes(item.type);

										if (!item || !('type' in item) || (display_results_type !== item.type && !display_all)) return null;
										if (this.props.already_selected_search_items.filter(s => s.spotify_id === item.spotify_id).length > 0) return null;

										if (index > 0 && !['profile', 'playlist'].includes(item.type)) { // Prevent duplicate results even if different IDs (display the most popular)
											const previous = ordered_data[index - 1];
											const same_type = item.type === previous.type;
											const same_text = item.type === 'artist' ? item.name === previous.name : item.title === previous.title;
											const same_album = same_type && item.type === 'song' ? item.album.name === previous.album.name : true;
											const less_popular = previous.popularity >= item.popularity;
											if (same_type && same_text && same_album && less_popular) return null;
										}

										const selected = this.props.selected_search_items.filter(s => s.spotify_id === item.spotify_id).length > 0;
										if (item.type === 'song'){
											return(
												<Card key={item.spotify_id + index}
													data={item} type='small_card_song' displayLabel={true}
													onClick={!default_search_page ? () => this.select_search_item(item) : () => this.handle_card_click(item)}
													onMoreClick={() => {store_set('song_info', item); store_set('active_overlay', 'track_options');}}
													selected={selected} selectable={default_search_page ? false : true}
													selectedIcon={selected_icon}
												/>
											);
										} else if (item.type === 'album'){
											return (
												<Card key={item.spotify_id + index}
													data={item} type='small_card_album' displayLabel={true}
													onClick={!default_search_page ? () => this.select_search_item(item) : () => {this.handle_card_click(item);}}
													selected={selected} selectable={default_search_page ? false : true}
													selectedIcon={selected_icon}
													onMoreClick={() => {store_set('album_info', item); store_set('active_overlay_option', 'show_all_album'); store_set('active_overlay', 'album_options');}}
												/>
											);
										} else if (item.type === 'artist'){
											return(
												<Card key={item.spotify_id + index}
													data={item} type='small_card_artist'
													onClick={!default_search_page ? () => this.select_search_item(item) : () => this.handle_card_click(item)}
													selected={selected} selectable={default_search_page ? false : true}
													selectedIcon={selected_icon}
													button_type='more'
													onMoreClick={() => {store_set('artist_info', item); store_set('active_overlay_option', 'show_all_artist'); store_set('active_overlay', 'artist_options');}}
												/>
											);
										} else if (item.type === 'profile' && display_results_type === 'profile'){
											if (!item.hash_id) return null;
											return (
												<Card
													key={item.hash_id + index}
													data={item} type='profile_card'
													bodyLink={'/profile/' + (item.slug ? item.slug : item.hash_id.substring(0,10))}
													onClick={() => this.follow_profile(item.member_id)}
													show_follow={true}
												/>
											);
										} else if (item.type === 'playlist'){
											return (
												<Card
													key={item.hash_id + index}
													data={item} type='small_playlist_card'
													onFindSimilar={() => this.handle_card_click(item)}
													onMoreClick={() => {store_set('playlist_info', item); store_set('active_overlay', 'playlists_options'); store_set('playlist_overlay_option', 'show_all');}}
												/>
											);
										}
										else return null;
									})}
								</div>
							</div>
						</React.Fragment>
					}
					{display_feedback &&
						<React.Fragment>
							{feedback_reason === 'nothing found' &&
								<div className='search-feedback-container'>
									<div className='search-feedback-icon'>
										<span className='material-icons'>
											warning
										</span>
									</div>
									<div className='search-feedback-title'>Nothing found...</div>
									<div className='search-feedback-description'>Try searching again using a different spelling of keyword</div>
								</div>
							}
							{feedback_reason === 'error' &&
								<div className='search-feedback-container'>
									<div className='search-feedback-icon'>
										<span className='material-icons'>
											error
										</span>
									</div>
									<div className='search-feedback-title'>Ooooops...</div>
									<div className='search-feedback-description'>Seems like we had an error, please try again later.</div>
								</div>
							}
						</React.Fragment>
					}
					{!this.props.display_results && !loading_result && 
						<div className='search-sessions'>
							{this.props.recent_seeds.length > 0 && default_search_page ?
								<div className='search-session'>
									<div className='search-session-header'>
										<div className='search-session-title'>Recent Seeds</div>
									</div>
									<div className='search-session-circle-options'>
										{this.props.recent_seeds.map((seed, index) => {
											let image_link;
											let text_name;
											if(seed.type === 'song'){
												image_link = seed.album.images.small;
												text_name = seed.title;
											} else if(seed.type === 'album'){
												image_link = seed.images.small;
												text_name = seed.title;
											} else if(seed.type === 'artist'){
												image_link = seed.images.small;
												text_name = seed.name;
											} else if(seed.type === 'playlist'){
												image_link = seed.image_link;
												text_name = seed.name;
											}
											let seed_image = () => (
												<Img
													src={[image_link, assets.default_album_image]}
													loader={
														<ContentLoader 
															width={70}
															height={70}
															backgroundColor={'#D8D8D8'}
															foregroundColor={'#b1afaf'}
														>
															<circle cx='35' cy='35' r='35' />
														</ContentLoader>
													}
													className='search-session-circle-image'
												/>
											);
											return(
												<div className='search-session-circle-item' key={index} onClick={this.props.keyboard_open ? () => {} : () => this.handle_card_click(seed)}>
													{seed_image()}
													<div className='search-session-circle-title'>
														<LinesEllipsis
															text={text_name}
															maxLine='2'
															ellipsis='...'
															trimRight
															basedOn='words'
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								: null
							}
							{default_search_page &&
								<div className='search-session'>
									<Link
										to='/fine-tunning/'
									>
										<div className='search-fine-tune'>
											<div className='search-fine-tune-icon'>
												<span className='material-icons'>tune</span>
											</div>
											<div className='search-fine-tune-text'>
												<div className='search-fine-tune-text-details'>Didn&apos;t find what you were looking for?</div>
												<div className='search-fine-tune-text-details'>Try to <span>Fine Tune</span> your search!</div>
											</div>
										</div>
									</Link>
								</div>
							}
							{(!default_search_page || user_synced === 'none') &&
								<React.Fragment>
									{trending_artists && trending_artists.length > 0 && (default_search_page || entities.includes('artist')) &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-header-info'>
													<span className='material-icons'>headset</span>
													<div className='search-session-title'>Trending Artists</div>
												</div>
											</div>
											<div className='search-session-box-options'>
												{trending_artists.map((tag, index) => {
													const already_selected = !default_search_page && this.props.already_selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													if (already_selected) return null;

													const selected = !default_search_page && this.props.selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													return (
														<div
															key={index}
															className='search-session-tag-container'
															onClick={this.props.keyboard_open ? () => {} : (!default_search_page ? () => this.select_search_item(tag) : () => this.handle_card_click(tag))}
														>
															{selected &&
																<div className='search-session-tag-selected'></div>
															}
															<div className='search-session-tag-text'>{tag.name}</div>
															{selected &&
																<span className='material-icons'>check</span>
															}
														</div>
													);
												})}
											</div>
										</div>
									}
									{trending_albums && trending_albums.length > 0 && (default_search_page || entities.includes('album')) &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-header-info'>
													<span className='material-icons'>album</span>
													<div className='search-session-title'>Trending Albums</div>
												</div>
											</div>
											<div className='search-session-box-options'>
												{trending_albums.map((tag, index) => {
													const already_selected = !default_search_page && this.props.already_selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													if (already_selected) return null;

													const selected = !default_search_page && this.props.selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													return (
														<div key={index}
															className='search-session-tag-container'
															onClick={this.props.keyboard_open ? () => {} : (!default_search_page ? () => this.select_search_item(tag) : () => this.handle_card_click(tag))}
														>
															{selected &&
																<div className='search-session-tag-selected'></div>
															}
															<div className='search-session-tag-text'>{tag.title}</div>
															{selected &&
																<span className='material-icons'>check</span>
															}
														</div>
													);
												})}
											</div>
										</div>
									}
									{trending_songs && trending_songs.length > 0 && (default_search_page || entities.includes('song')) &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-header-info'>
													<span className='material-icons'>album</span>
													<div className='search-session-title'>Trending Songs</div>
												</div>
											</div>
											<div className='search-session-box-options'>
												{trending_songs.map((tag, index) => {
													const already_selected = !default_search_page && this.props.already_selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													if (already_selected) return null;

													const selected = !default_search_page && this.props.selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													return (
														<div key={index}
															className='search-session-tag-container'
															onClick={this.props.keyboard_open ? () => {} : (!default_search_page ? () => this.select_search_item(tag) : () => this.handle_card_click(tag))}
														>
															{selected &&
																<div className='search-session-tag-selected'></div>
															}
															<div className='search-session-tag-text'>{tag.title}</div>
															{selected &&
																<span className='material-icons'>check</span>
															}
														</div>
													);
												})}
											</div>
										</div>
									}
									{!suggestions_loading && default_search_page &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-title'>Get more suggestions:</div>
											</div>
											<div className='search-session-box-options'>
												<div className='search-session-sync-card' onClick={() => export_track.open_login_window('spotify', '/search/')}>
													<div className='search-session-sync-card-info'>
														<div className='search-session-sync-card-icon' style={{backgroundColor: '#000'}}>
															<img src={assets.spotify_round_icon} alt='Spotify'/>
														</div>
														<div className='search-session-sync-card-text'>Sync your Spotify account</div>
													</div>
													<span className='material-icons'>navigate_next</span>
												</div>
												<div className='search-session-sync-card' onClick={() => export_track.open_login_window('deezer', '/search')}>
													<div className='search-session-sync-card-info'>
														<div className='search-session-sync-card-icon' style={{backgroundColor: '#fff'}}>
															<img src={assets.deezer_icon} alt='Deezer'/>
														</div>
														<div className='search-session-sync-card-text'>Sync your Deezer account</div>
													</div>
													<span className='material-icons'>navigate_next</span>
												</div>
											</div>
										</div>
									}
								</React.Fragment>
							}
							{(!default_search_page || user_synced !== 'none') &&
								<React.Fragment>
									{songs.length > 0 && (default_search_page || entities.includes('song')) &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-header-info'>
													<span className='material-icons'>album</span>
													<div className='search-session-title'>Suggested Songs</div>
												</div>
												<div className='search-session-button' onClick={this.props.keyboard_open ? () => {} : () => this.setState({show_all_songs: !this.state.show_all_songs})}>View {this.state.show_all_songs ? 'less' : 'all'}</div>
											</div>
											<div className='search-session-box-options'>
												{songs.map((tag, index) => {
													const already_selected = !default_search_page && this.props.already_selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													if (already_selected) return null;
													if (!tag.title) return null;

													const selected = !default_search_page && this.props.selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													return (
														<div key={index}
															className='search-session-tag-container'
															onClick={this.props.keyboard_open ? () => {} : (!default_search_page ? () => this.select_search_item(tag) : () => this.handle_card_click(tag))}
														>
															{selected &&
																<div className='search-session-tag-selected'></div>
															}
															<div className='search-session-tag-text'>{tag.title}</div>
															{selected &&
																<span className='material-icons'>check</span>
															}
														</div>
													);
												})}
											</div>
										</div>
									}
									{artists.length > 0 && (default_search_page || entities.includes('artist')) &&
										<div className='search-session'>
											<div className='search-session-header'>
												<div className='search-session-header-info'>
													<span className='material-icons'>headset</span>
													<div className='search-session-title'>Suggested Artists</div>
												</div>
												<div className='search-session-button' onClick={this.props.keyboard_open ? () => {} : () => this.setState({show_all_artists: !this.state.show_all_artists})}>View {this.state.show_all_artists ? 'less' : 'all'}</div>
											</div>
											<div className='search-session-box-options'>
												{artists.map((tag, index) => {
													const already_selected = !default_search_page && this.props.already_selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													if (already_selected) return null;
													if (!tag.name) return null;

													const selected = !default_search_page && this.props.selected_search_items.filter(s => s.spotify_id === tag.spotify_id).length > 0;
													return (
														<div
															key={index}
															className='search-session-tag-container'
															onClick={this.props.keyboard_open ? () => {} : (!default_search_page ? () => this.select_search_item(tag) : () => this.handle_card_click(tag))}
														>
															{selected &&
																<div className='search-session-tag-selected'></div>
															}
															<div className='search-session-tag-text'>{tag.name}</div>
															{selected &&
																<span className='material-icons'>check</span>
															}
														</div>
													);
												})}
											</div>
										</div>
									}
								</React.Fragment>
							}
						</div>
					}
				</div>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		recent_seeds: state.GlobalReducer.recent_seeds,
		previous_page: state.GlobalReducer.previous_page,
		safe_area_top: state.GlobalReducer.safe_area_top,
		display_results: state.GlobalReducer.display_results,
		search_query: state.GlobalReducer.search_query,
		total_search_progress: state.GlobalReducer.total_search_progress,
		keyboard_open: state.GlobalReducer.keyboard_open,
		logging_in: state.GlobalReducer.logging_in,
		user_synced: state.GlobalReducer.user_synced,
		suggestions: state.GlobalReducer.suggestions,
		suggestions_loading: state.GlobalReducer.suggestions_loading,
		linked_accounts: state.GlobalReducer.linked_accounts,
		user: state.GlobalReducer.user,
		location: state.GlobalReducer.location,
		is_footer_progress: state.GlobalReducer.is_footer_progress,
		footer_progress_length: state.GlobalReducer.footer_progress_length,
		footer_progress_index: state.GlobalReducer.footer_progress_index,
		selected_search_items: state.GlobalReducer.selected_search_items,
		already_selected_search_items: state.GlobalReducer.already_selected_search_items,
		trending_artists: state.GlobalReducer.trending_artists,
		trending_albums: state.GlobalReducer.trending_albums,
		trending_songs: state.GlobalReducer.trending_songs,
		device_info: state.GlobalReducer.device_info,
		quiz: state.GlobalReducer.quiz,
		edit_quiz: state.GlobalReducer.edit_quiz,
		user_quiz: state.GlobalReducer.user_quiz
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Search));