import React, { useState, useEffect } from 'react';

import { store_set } from '@/actions/GlobalActions.js';

import './styles/ObsessionPopup.css';
import '@/assets/fonts/Montserrat.css';

import ShareImage, { get_shareable_my_obsession, share_data_url } from '@components/ShareImage.js';
import Card from '@components/Card.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export const ObsessionShare = ({
	user_info,
	onCloseObsession,
}) => {
	const [obsession_card_data, setObsessionCard] = useState();
	const [user, setUser] = useState(user_info);
	useEffect(() => {
		if (!user.slug || !user.obsession.song || !user.first_name){
			store_set('loading_overlay_display', true);
			store_set('loading_overlay_component', 'circular-progress');
			store_set('loading_overlay_text', 'Loading...');
			setUser(user_info);
		}
		// eslint-disable-next-line
	}, [user_info]);
	useEffect(() => {
		if (user && user.obsession && user.obsession.song)
			(async() => {
				await get_shareable_my_obsession(setObsessionCard);
				store_set('loading_overlay_display', false);
				store_set('active_overlay', false);
			})();
	}, [user]);
	if (!user.slug || !user.obsession.song || !user.first_name) {
		return null;
	}
	return(
		<div className='obsession-container-with-buttons' style={{ height: '95%' }}>
			<div className='obsession-content-card' style={{ height: '95%' }} id="alert-dialog-description">
				<Card key={user.obsession.song.spotify_id} user_info={user} type='share_obsession' data={user.obsession.song} />
			</div>
			<div className='obsession-action-button-container'>
				<ShareImage only_share element_data={obsession_card_data} onClose={onCloseObsession} onShareImage={() => share_data_url(obsession_card_data, 'my-obsession')} />
			</div>
		</div>
	);
};

const ObsessionPopup = ({
	obsession_card_data,
	openObsession, 
	user_info,
	onShareImage,
	onCloseObsession,
}) => {
	if (!openObsession || !user_info || !user_info.obsession)
		return null;

	return (
		<Dialog
			open={openObsession}
			onClose={onCloseObsession}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className='obsession-container'
		>
			<DialogContent className='obsession-content-card' id="alert-dialog-description">
				<Card key={user_info.obsession.song.spotify_id} type='share_obsession' data={user_info.obsession.song} />
			</DialogContent>
			<DialogActions>
				<ShareImage element_data={obsession_card_data} onClose={onCloseObsession} onShareImage={onShareImage} />
			</DialogActions>
		</Dialog>
	);
};

export default ObsessionPopup;
