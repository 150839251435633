import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/OutOfIdeas.css';

// Constant Imports
import * as constants from '@constants';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Import the controllers
import queue from '@Queue';
import app_analytics from '@Analytics';

class OutOfIdeas extends Component {
	constructor(props){
		super(props);
		this.state = {
			overlay: true,
			show_all_moods: false,
			show_all_genres: false
		};

		this.on_change = this.on_change.bind(this);
		this.load_recommendations = this.load_recommendations.bind(this);
	}

	on_change(key, top = false){
		if (this.state[key]){
			this.setState({[key]: false}); return;
		}

		// Scroll to the bottom of the corresponding section when user clicks on "show more"
		this.setState({[key]: true}, () => {
			var element = document.getElementsByClassName('main-content-inner');
			if (element.length > 0){
				top = top || element[0].scrollHeight;
				element[0].scroll({ top: top, left: 0, behavior: 'smooth' });
			}
		});
	}

	load_recommendations(data){
		// Trigger an event
		app_analytics.log_event('card_click');

		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		store_set('seed', data);
		queue.get_recommendations(data.spotify_id, data.type, false, true);
		store_set('active_overlay', false);
	}

	render(){
		var moods = this.state.show_all_moods ? constants.all_moods : constants.all_moods.slice(0,4);
		var genres = this.state.show_all_genres ? constants.all_genres : constants.all_genres.slice(0,8);
		return(
			<div className="out-of-ideas">
				<div className="out-of-ideas-section">
					<div className="out-of-ideas-section-header">
						<div className="out-of-ideas-section-title">
							Moods
						</div>
						<div className="out-of-ideas-section-button" onClick={() => this.on_change('show_all_track_moods', 257)}>
							show {this.state.show_all_moods ? 'less' : 'more'}
						</div>
					</div>
					<div className="out-of-ideas-section-items">
						{moods.map((mood, index) => (
							<div
								key={index}
								className="out-of-ideas-mood-item"
								style={{backgroundImage: 'url(' + mood.image + ')'}}
								onClick={() => this.load_recommendations(mood)}
							>
								<div className="out-of-ideas-item-gradient" />
								<img src={mood.icon} alt={mood.name}/>
								<div className="out-of-ideas-item-title">{mood.name}</div>
							</div>
						))}
					</div>
				</div>
				<div className="out-of-ideas-section">
					<div className="out-of-ideas-section-header">
						<div className="out-of-ideas-section-title">
							Genres
						</div>
						<div className="out-of-ideas-section-button" onClick={() => this.on_change('show_all_track_genres')}>
							show {this.state.show_all_genres ? 'less' : 'more'}
						</div>
					</div>
					<div className="out-of-ideas-section-items">
						{genres.map((genre, index) => (
							<div
								key={index}
								className="out-of-ideas-genre-item"
								style={{
									backgroundImage: 'url(' + genre.image + ')',
									borderImageSource: genre.color
								}}
								onClick={() => this.load_recommendations(genre)}
							>
								<div className="out-of-ideas-item-gradient" />
								<div className="out-of-ideas-item-title">{genre.name}</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps() {
	return {};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(OutOfIdeas));