// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Constants import
import {
	audio_time_update_interval
} from '@constants';

// Import Howler - the library responsible for managing audio playback
// import { Howl, Howler } from 'howler';
import { Howl } from 'howler';

// Import the controllers
import queue from '@Queue';
import file from '@FileManager';
import subscription from '@Subscription';
import { request_buffer } from '@/RequestBuffer.js';

// Import the event bus
import event_bus from '@Eventbus';

// Constants import
import * as constants from '@constants';

import { redirect } from '@routes/Routes.js';
import moment from 'moment';

class PlayerController{
	constructor() {
		this.current = false;
		this.time = false;
		this.free_interaction = false;
		this.count = 0;

		this.load = this.load.bind(this);
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.toggle = this.toggle.bind(this);
		this.reset = this.reset.bind(this);
		this.get_position = this.get_position.bind(this);
		this.get_duration = this.get_duration.bind(this);
		this.upate_track_time = this.upate_track_time.bind(this);
		this.save_play_history = this.save_play_history.bind(this);

		// Update current audio time
		this.upate_track_time_interval = setInterval(this.upate_track_time, audio_time_update_interval);

		event_bus.on('play', () => {
			const mute_switch_on = store_get('mute_switch_on');
			const dismiss = store_get('dismiss_mute_switch_message');
			const display_message = !dismiss || moment(dismiss).isBefore(moment().subtract(30, 'minutes'));
			if (mute_switch_on && display_message){
				this.pause();
				store_set('active_overlay', 'mute_switch_on');
			} else {
				store_set('is_playing', true);
			}
		});

		event_bus.on('pause', () => {store_set('is_playing', false);});
		event_bus.on('load', () => {
			store_set('duration', this.get_duration());

			// Free like / dislike after n + 2 seconds even if track didn't reach minimum play time where n is the default minimum play time
			this.free_interaction = setTimeout(() => {store_set('free_like_dislike', true);}, (constants.minimum_waiting_time + 2) * 1000);
		});
		event_bus.on('end', () => {store_set('is_playing', false);});
	}

	/* It will be automatically called once the persistor is done loading the saved store state */
	initialize(song = store_get('playing_song')){
		// If the song is false, it means the player wasn't working before the store loaded
		if (!song) return;

		// Otherwise, we will set the player to the config it was before the page refresh
		var seek = store_get('current_time') || 0;

		// The AudioContext isn't allowed to start before a user gesture on the page.
		// So even if it was playing, set to false and let the user trigger the change.
		// This will also prevent the user triggering two audios at the same time.
		store_set('is_playing', false);

		file.get(song, function(value){
			// Recreate the Howl object with our song
			var src = value ? value : song.preview_url;
			var sound = new Howl({
				src: [src],
				format: ['mp3'],
				autoplay: false,
				onplay: () => event_bus.emit('play'),
				onpause: () => event_bus.emit('pause'),
				onload: () => event_bus.emit('load'),
				onend: () => event_bus.emit('end'),
				onplayerror: () => this.load(),
				onloaderror: () => this.load(),
				volume: 0 // This will prevent the audio from bursting when starting
			});

			// Fade in the music so that we don't jump scare the user
			sound.fade(0, 1, 5000);

			// Set the position where the sound stoped before loading the page
			sound.seek(seek);

			// Save the sound in the player controller instance to be used later
			this.current = sound;
		}.bind(this));

		store_set('playing_song', song);
	}

	load(delay = 0, song = false, keep_paused = false){
		// If there's a song already playing, pause it
		if (this.current){
			this.current.pause();
			// Also unload and destroy the Howl object
			this.current.unload();
		}
		clearTimeout(this.free_interaction);
		store_set('free_like_dislike', false);
		store_set('current_time', 0);
		store_set('is_playing', false);

		setTimeout(function() {
			if (!song) {
				// Get the next song in queue and push it to the store
				song = queue.pop();
			}
			if (song !== undefined) {
				// For now, a random track every N should visually look like a sponsored recommendation.
				this.count = this.count + 1;
				const interval = store_get('premium_sponsored_recommendation_interval');
				if (this.count % interval === 0 && !subscription.is_valid()){
					song.sponsored = true;
				}
				store_set('playing_song', song);
				this.save_play_history(song);

				const redirect_to_home = store_get('redirect_to_home');
				if (redirect_to_home){
					redirect('/');
					store_set('redirect_to_home', false);
				}

				// And finally, create a new Howl object with our song
				file.get(song, function(value){
					// Do not start playing the song in case a secondary overlay appears
					const secondary_overlay = store_get('secondary_overlay');
					const autoplay = secondary_overlay ? false : true;

					const src = value ? value : song.preview_url;
					var sound = new Howl({
						src: [src],
						format: ['mp3'],
						autoplay: !keep_paused && autoplay,
						onplay: () => event_bus.emit('play'),
						onpause: () => event_bus.emit('pause'),
						onload: () => event_bus.emit('load'),
						onend: () => event_bus.emit('end'),
						onplayerror: () => this.load(0, song),
						volume: 0 // This will prevent the audio from bursting when starting
					});

					// Fade in the music so that we don't jump scare the user
					sound.fade(0, 1, 5000);

					// Save the sound in the player controller instance to be used later
					this.current = sound;
				}.bind(this));
			} else {
				store_set('playing_song', false);
			}
		}.bind(this), delay);
	}

	play(){
		if (this.current && !this.current.playing()) {
			this.current.play();
		}
	}

	pause(){
		if (this.current && this.current.playing()) {
			this.current.pause();
		}
	}

	toggle(){
		// In case the play/pause button is pressed and the state is empty,
		// it means that for some reason the Howl object is not loaded, so
		// load manually.
		if (!this.current){
			var song = store_get('playing_song');
			this.load(0, song);
		} else {
			if (this.current.playing()) {
				this.pause();
			} else {
				this.play();
			}
		}
	}

	get_position(){
		if (this.current) {
			return this.current.seek();
		}
		return false;
	}

	get_duration(){
		if (this.current) {
			return this.current.duration();
		}
		return false;
	}

	upate_track_time(){
		if (this.current && this.current.playing()) {
			store_set('current_time', this.current.seek());
		}
	}

	reset(){
		this.pause();
		this.current = false;
		this.time = false;
	}

	save_play_history(song){
		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_save_play_history,
			data: {
				song_hash_id: song.hash_id,
				spotify_id: song.spotify_id,
			}
		});
	}
}

const player = new PlayerController();

export default player;