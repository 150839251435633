// Objects import
import { store } from '@/store.js';
import file from '@FileManager';
import player from '@Player';
import queue from '@Queue';
import ads from '@Ads';
import * as constants from '@constants';
import { request_buffer } from '@/RequestBuffer.js';
import version from '@Version';

// Exposes objects on RemoteJs
export default function remote_exporter(value){
	if (!value) { return; }

	// This method returns an object to be accessed at the "window" object while using RemoteJs for remote debugging
	// On RemoteJs, there is no need to assign a variable nor use console.log, just call the function.
	// (eg. window.getObject('constants').all_moods will return the all_moods variable in constants object)
	if (['player'].includes(value)) {
		return player;

	} else if (['file manager', 'file_manager', 'file'].includes(value)) {
		return file;

	} else if (['queue', 'songs'].includes(value)) {
		return queue;

	} else if (['store', 'redux', 'redux_store'].includes(value)) {
		return store.getState().GlobalReducer;

	} else if (['constants'].includes(value)){
		return constants;

	} else if (['request_buffer', 'buffer', 'request buffer'].includes(value)) {
		return request_buffer;
	
	} else if (['version'].includes(value)){
		return version;

	} else if (['ads'].includes(value)) {
		return ads;

	} else {
		// Message in case of trying to get an object not imported or wrong value
		return console.log('Please try another object.');
	}
}