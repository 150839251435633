import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// CSS Imports
import './styles/NotificationsIcon.css';

class NotificationsIcon extends Component {
	render(){
		const notifications = this.props.notifications || [];
		var visible = notifications.filter(notif => notif.new === true);
		return(
			<Link
				to='/notifications/'
				className='notifications-icon-container'
			>
				{visible.length > 0 &&
					<div className='notifications-icon-bubble'>
						<div className='notifications-icon-bubble-text'>{visible.length > 10 ? '10+' : visible.length}</div>
					</div>
				}
				<span className='material-icons'>notifications</span>
			</Link>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		notifications: state.GlobalReducer.notifications,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(NotificationsIcon);