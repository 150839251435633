import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import firebase from 'firebase';
import { redirect } from './routes/Routes.js';

// Constants import
import * as constants from './constants.js';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from './RequestBuffer.js';

// Import controllers
import user from '@User';
import app_analytics from '@Analytics';
import event_bus from '@Eventbus';

class NotificationsController{
	constructor() {
		this.push_token = false;

		this.initialize = this.initialize.bind(this);
		this.fetch = this.fetch.bind(this);
		this.touch = this.touch.bind(this);

		event_bus.on('store-loaded', this.fetch);
	}

	initialize(){
		if (Capacitor.getPlatform() === 'web'){ // Web Notifications =============================================
			// First check if the browser supports Firebase
			if (!firebase.messaging.isSupported()){
				return;
			}
			if (firebase.apps.length) {
				firebase.app(); // if already initialized, use that one
			} 
			else {
				const firebaseConfig = {
					apiKey: 'AIzaSyCApjX5vUuL-7JN6nLcgYlrQF7tEo0vSQc',
					authDomain: 'magroove-app.firebaseapp.com',
					projectId: 'magroove-app',
					storageBucket: 'magroove-app.appspot.com',
					messagingSenderId: '1038185873962',
					appId: '1:1038185873962:web:d10b36e321f786ec4cb953',
					measurementId: 'G-81H9E22HYL'
				};
				// Initialize Firebase
				firebase.initializeApp(firebaseConfig);
			}
			const messaging = firebase.messaging();

			// Request a token to perform push notifications - a popup will be presented to the user if it hasn't accepted yet
			messaging.getToken().then((currentToken) => {
				// Store token in the store and touch the API
				this.push_token = currentToken;
				store_set('push_token', currentToken);
				this.touch();
				// If in debug mode, print the token for testing purposes
				if (constants.debug) {
					console.log('Push notification token accepted:');
					console.log(currentToken);
				}
			}).catch(() => {/* Do nothing if the permission was denied for now */});

			// If in the future we want to deal with messages that are triggered while our app's tab is opened,
			// use this snippet:
			messaging.onMessage(function(payload){
				if (constants.debug) {
					console.log('Received push notification:');
					console.log(payload);
				}
			});

		} else { // Native notifications =============================================
			if (Capacitor.getPlatform() === 'android') {
				// If platform is android, simlpy register for a token
				PushNotifications.register();
			} else {
				// If platform is ios, first ask the user for permission and just then register for a token
				PushNotifications.checkPermissions().then((response) => {
					if (response.receive !== 'granted') {
						PushNotifications.requestPermissions().then(() => {
							PushNotifications.register();
						});
					}
				});
			}

			// On succcess, we should be able to receive notifications
			PushNotifications.addListener('registration', () => {
				// Auto initialize the FCM plugin - necessary to work with FCM on Android and iOS
				FCM.isAutoInitEnabled().then((response) => {
					if(response.enabled) {
						FCM.setAutoInit({ enabled: true });
					}
				});

				// Get FCM token instead the APN one returned by Capacitor iOS
				// Capacitor Android will return an FCM token by default.
				FCM.getToken().then((response) => {
					// Store token in the store and touch the API
					this.push_token = response.token;
					store_set('push_token', response.token);
					this.touch();
					// If in debug mode, print the token for testing purposes
					if (constants.debug) {
						console.log('-------------------');
						console.log('registration triggered');
						console.log('FCM Token: ', response.token);
					}
				});
			});

			// If we want to do something in case of registration error in the future,
			// use this snippet
			// PushNotifications.addListener('registrationError', (error) => {
			//  console.log('-------------------')
			//  console.log('registrationError triggered')
			//  console.log(JSON.stringify(error));
			// });

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived', (notification) => {
				if (constants.debug) {
					console.log('Received push notification:');
					console.log(notification);
				}

				if (Capacitor.getPlatform() === 'ios') {
					try {
						app_analytics.log_event('notification_receive');
					}
					catch (error) { /* empty */ }
				}
			});

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
				// Update the notification history table with the current time
				var hash_id = notification.notification.data.hash_id;
				this.save_click(hash_id);

				// Redirect to the right page
				var url = new URL(notification.notification.data.url);
				var href = url.href;
				var page = href.replace(url.origin, '');
				redirect(page);
			});
		}
	}


	touch(){
		var token = store_get('push_token');
		var platform = Capacitor.getPlatform();

		if (!token) return; // Only touch API if a token was assigned

		var request = {
			type: 'post',
			url: constants.api_endpoint_notification_touch,
			data: {
				token: token,
				platform: platform,
				source: 'magrooveApp'
			},
		};

		// We send this token to the API and store it in the database
		// (even if anonymous user).
		var user_logged = user.check_for_token();
		if (user_logged){
			request_buffer.auth_execute(request);
		} else{
			request_buffer.execute(request);
		}
	}

	fetch(){
		let notification_interval = setInterval(() => {
			store_set('loading_notifications', true);
			request_buffer.auth_execute({
				type:'GET',
				url: constants.api_endpoint_fetch_notifications,
				success: function(data){
					if (data.status === 'success'){
						store_set('notifications', data.data);
					}
				},
				complete: function(){
					store_set('loading_notifications', false);
				}
			});
		}, 30000); // Runs the ajax every 5 minutes to keep notifications up to date
		store_set('notification_interval', notification_interval);
	}

	clear(hash_id = '', clear_all = 0){
		let temp = [...store_get('notifications')];
		const notifications = clear_all ? temp.filter(item => item.is_closeable === 0) : temp.filter(item => item.hash_id !== hash_id);
		store_set('notifications', notifications);

		const data = {};
		data.clear_all = clear_all;
		data.hash_id = hash_id;
		request_buffer.auth_execute({
			type:'POST',
			data: data,
			url: constants.api_endpoint_notification_clear,
			complete: function(){
				store_set('clearing_notifications', false);
			}
		});
	}

	save_click(hash_id){
		let data = {};
		data['hash_id'] = hash_id;
		request_buffer.auth_execute({
			type:'POST',
			data: data,
			context: this,
			url: constants.api_endpoint_notification_click,
		});
	}

	save_view(hash_ids){
		let data = {};
		data['hash_ids'] = JSON.stringify(hash_ids);
		request_buffer.auth_execute({
			type:'POST',
			data: data,
			context: this,
			url: constants.api_endpoint_notification_view,
		});
	}
}

const notification_controller = new NotificationsController();

export default notification_controller;
