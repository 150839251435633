import {
	AdMob,
	InterstitialAdPluginEvents
} from '@magroove/capacitor-admob';
import { Capacitor } from '@capacitor/core';

// Import controllers
import player from '@Player';
import app_analytics from '@Analytics';

// Import constants
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

class AdsController{
	constructor(){
		this.ready = false;

		this.adsense_loaded_timestamp = false;

		this.interstitial_loaded_timestamp = false;
		this.interstitial_clicked = false;

		this.interstitial_count = 0;
		this.interstitial_timestamp = false;
		this.interstitial_limit = 4;

		this.initialize = this.initialize.bind(this);
		this.can_show_interstitial = this.can_show_interstitial.bind(this);
		this.onload_adsense = this.onload_adsense.bind(this);
		this.show_interstitial = this.show_interstitial.bind(this);
		this.save_impression = this.save_impression.bind(this);
	}

	async initialize(){
		await AdMob.initialize({
			requestTrackingAuthorization: true,
			// testingDevices: ['aa715db119154864389228920d03e901', '7D74138F1137C2718B5EEE36F71A0136'],
			initializeForTesting: constants.admob_debug
		});

		AdMob.addListener(InterstitialAdPluginEvents.Showed, () => {
			this.interstitial_clicked = false;
			this.interstitial_loaded_timestamp = new Date().getTime();
		});

		AdMob.addListener(InterstitialAdPluginEvents.Clicked, () => {
			if (!this.interstitial_clicked){
				this.interstitial_clicked = true;
				this.save_impression('interstitial', 'clicked');
			}
		});

		AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
			if (!this.interstitial_clicked){
				this.save_impression('interstitial', 'viewed');
			}
		});

		this.ready = true;
	}

	onload_adsense(){
		this.adsense_loaded_timestamp = new Date().getTime();
	}

	can_show_interstitial(){
		// Make sure to limit the amount of ads per hour the user sees so it
		// won’t be too many ads in a short amount of time. We will try to
		// stay bellow 4 per hour.
		if (this.interstitial_count === this.interstitial_limit){
			if (new Date().getTime() < this.interstitial_timestamp + (60 * 60 * 1000)){
				player.play();
				return false;
			}
			this.interstitial_count = 0;
		}
		else if (this.interstitial_count === 0){
			this.interstitial_timestamp = new Date().getTime();
		}
		this.interstitial_count += 1;
		return true;
	}

	async show_interstitial(){
		if (!this.ready){
			setTimeout(this.show_interstitial, 1000); return;
		}
		if (!this.can_show_interstitial()) return;

		const adId = Capacitor.getPlatform() === 'ios' ? constants.admob_ios_interstitial_id : constants.admob_android_interstitial_id;
		const options = { adId: adId };

		try {
			await AdMob.prepareInterstitial(options);
			player.pause();
			await AdMob.showInterstitial();	

			// Save fb event
			if (Capacitor.getPlatform() === 'ios'){
				app_analytics.log_event('display_banner_ad', {amount: 0.25, currency: 'USD'});
			} else {
				app_analytics.log_event('display_banner_ad', {amount: 0.12, currency: 'USD'});
			}
		} catch(err) {
			player.play();
		}
	}

	save_impression(type, action, ad_location = window.location.pathname, action_timestamp = new Date().getTime()){
		if (!this[type + '_loaded_timestamp']) return;
		const data = {
			ad_type: type,
			action: action,
			action_timestamp: action_timestamp,
			loaded_timestamp: this[type + '_loaded_timestamp'],
			ad_location: ad_location
		};

		request_buffer.execute({
			type: 'POST',
			data: data,
			url: constants.api_endpoint_ad_tracking,
			error: function(){
				setTimeout(() => this.save_impression(action, type, action_timestamp), 5000);
			}.bind(this)
		});
	}
}

const ads = new AdsController();

export default ads;