import React, { Component, Fragment } from 'react';
import { Capacitor } from '@capacitor/core';
import _ from 'lodash';

// Components imports
import { Button } from '@magroove/magroove-ui';
import Card from '@components/Card.js';
import Milestone from '@components/Milestone.js';
import { Link } from 'react-router-dom';
import { Img } from 'react-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Swipeable } from 'react-swipeable';
import ContentLoader from '@components/ContentLoader.js';
import { convert_html_to_data_url, share_data_url } from '@components/ShareImage.js';

// Assets import
import * as assets from '@Assets';

// Constants import
import * as constants from '@constants';

// Nivo imports
import { useTheme } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveRadar } from '@nivo/radar';

// Import functions
import { redirect } from '@routes/Routes';
import { store_set } from '@actions/GlobalActions.js';

// ==============================================================================================================
function set_new_profile_info(data){
	store_set('profile_info', {
		'followers': data.followers,
		'hash_id': data.hash_id,
		'picture': data.picture,
		'slug': data.slug,
		'member_id': data.member_id,
	});
}

// Functions
function convert_time(seconds) {
	if (!seconds) return [0, 'seconds'];

	let value, unit;
	// More than 10 days
	if (seconds >= 864000) {
		value = Math.round(seconds / 86400);
		unit = 'days';
	}
	// More than 10 hours
	else if (seconds >= 36000) {
		value = Math.round(seconds / 3600);
		unit = 'hours';
	}
	// More than 10 minutes
	else if (seconds >= 600) {
		value = Math.round(seconds / 60);
		unit = 'minutes';
	}
	// Less than 10 minutes
	else {
		value = seconds;
		unit = (value === 1) ? 'second' : 'seconds';
	}
	return [value, unit];
}

// Discovery Timeline Nivo
const colors = {
	'likes': '#ec6b43',
	'dislikes': 'rgba(236, 107, 67, 0.5)',
	'Me': '#ec6b43',
	'Rest of the world': 'rgb(112, 112, 112)'
};

const StyledTick = ({
	value: _value,
	x,
	y,
	opacity,
	rotate,
	format,
	lineX,
	lineY,
	onClick,
	textX,
	textY,
	textBaseline,
	textAnchor
}) => {
	const theme = useTheme();
	let value = _value;
	if (format !== undefined) {
		value = format(value);
	}
	
	let gStyle = { opacity };
	if (onClick) {
		gStyle['cursor'] = 'pointer';
	}
	
	return (
		<g
			transform={`translate(${x},${y})`}
			{...(onClick ? { onClick: e => onClick(e, value) } : {})}
			style={gStyle}
		>
			<line x1={0} x2={lineX} y1={0} y2={lineY} style={theme.axis.ticks.line} />
			<text
				dominantBaseline={textBaseline}
				textAnchor={textAnchor}
				transform={`translate(${textX},${textY}) rotate(${rotate})`}
				// (1) merge theme styles with our fontWeight
				style={{ ...theme.axis.ticks.text, fontWeight: 400, fill: '#707070' }}
			>
				{value}
			</text>
		</g>
	);
};

// Music Genres and Aspects Nivo
const StatsRadar = (props) => (
	<ResponsiveRadar
		data={props.data}
		keys={props.levels}
		indexBy={props.vertex}
		margin={{ top: 50, right: 80, bottom: 40, left: 80 }}
		borderColor={{ from: 'color' }}
		gridShape='linear'
		gridLabelOffset={20}
		dotColor={{ from: 'color', modifiers: [] }}
		dotBorderWidth={3}
		colors={radar => {
			if (!props.colors)
				return radar.key in colors ? colors[radar.key] : '#ec6b43';
			return radar.key in props.colors ? props.colors[radar.key] : '#ec6b43';
		}}
		fillOpacity={0.2}
		motionConfig='wobbly'
		legends={[
			{
				anchor: 'bottom',
				direction: 'row',
				translateX: -65,
				translateY: -50,
				itemWidth: 80,
				itemHeight: 20,
				symbolSize: 12,
				symbolShape: 'circle',
			}
		]}
	/>
);

const AspectsBar = (props) => {
	return (
		<ResponsiveBar
			data={props.data}
			keys={props.levels}
			indexBy={props.vertex}
			margin={{ top: 40, right: 30, bottom: 90, left: 65 }}
			padding={props.editable ? 0.3 : 0.5}
			innerPadding={8}
			groupMode='grouped'
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={bar => {
				if (!props.colors)
					return bar.id in colors ? colors[bar.id] : '#ec6b43';
				return bar.id in props.colors ? props.colors[bar.id] : '#ec6b43';
			}}
			axisTop={null}
			maxValue={100}
			axisRight={null}
			borderRadius={10}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: -15,
					translateY: 80,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolShape: 'circle',
					symbolSize: 12,
				}
			]}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0
			}}
			axisLeft={{
				renderTick: StyledTick,
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				tickValues: [0, 25, 50, 75, 100],
				legend: '',
				format: e => e + '%',
			}}
			theme={{
				'fontSize': 10,
				'axis': {
					'ticks': {
						'text': {
							'fill': '#ffffff'
						}
					}
				},
				'legends': {
					'text': {
						'fill': '#707070',
						'fontWeight': '400'
					}
				},
				'grid': {
					'line': {
						'stroke': '#707070',
						'strokeDasharray': '3, 6',
						'strokeWidth':0.5 
					}
				}			
			}}
			gridYValues={[0, 25, 50, 75, 100]}
			enableGridY={true}
			isInteractive={false}
			enableLabel={false}
		/>
	);
};

const StatsBar = (props) => {
	return (
		<ResponsiveBar
			data={props.data}
			keys={props.levels}
			indexBy={props.vertex}
			margin={{ top: 40, right: 30, bottom: 90, left: 30 }}
			padding={0.25}
			innerPadding={3}
			groupMode='grouped'
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={bar => bar.id in colors ? colors[bar.id] : '#ec6b43'}
			axisTop={null}
			maxValue={props.maxValue}
			axisRight={null}
			borderRadius={10}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: -15,
					translateY: 80,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolShape: 'circle',
					symbolSize: 12,
				}
			]}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0
			}}
			axisLeft={{
				renderTick: StyledTick,
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				tickValues: props.gridYValues,
				legend: ''
			}}
			theme={{
				'fontSize': 10,
				'axis': {
					'ticks': {
						'text': {
							'fill': '#ffffff'
						}
					}
				},
				'legends': {
					'text': {
						'fill': '#707070',
						'fontWeight': '400'
					}
				},
				'grid': {
					'line': {
						'stroke': '#707070',
						'strokeDasharray': '3, 6',
						'strokeWidth':0.5 
					}
				}			
			}}
			gridYValues={props.gridYvalues}
			enableGridY={true}
			isInteractive={false}
			enableLabel={false}
		/>
	);
};

// ==============================================================================================================
class SpotifyStats extends Component {
	constructor(props){
		super(props);
		this.state = {
			shown_card: 0,
			first_stats_card_data: null,
			second_stats_card_data: null,
		};

		this.profile_picture_loaded = false;

		// Element references
		this.album_grid = React.createRef();
		this.album_grid_clone_slot = React.createRef();
		this.cards_container = React.createRef();
		this.first_stats_card = React.createRef();
		this.second_stats_card = React.createRef();
		this.my_stats_clone_slot = React.createRef();

		this.get_shareable_my_stats_data = this.get_shareable_my_stats_data.bind(this);
		this.handle_share_my_stats = this.handle_share_my_stats.bind(this);
	}

	componentDidMount() {
		// To share the My Stats component, the user must have at least 5 artists and 5 songs
		// And their profile picture must have loaded
		if (
			this.props.spotify_stats.long_term.artists.length >= 5 &&
			this.props.spotify_stats.long_term.songs.length >= 5
		) {
			setTimeout(this.get_shareable_my_stats_data, 1000);
		}
	}

	async get_shareable_my_stats_data() {
		// Check for profile picture until it's loaded
		if (!this.profile_picture_loaded) {
			setTimeout(this.get_shareable_my_stats_data, 100);
			return;
		}

		// Get shareable stats cards elements
		const first_stats_card_el = this.first_stats_card.current;
		const second_stats_card_el = this.second_stats_card.current;

		// If there isn't any element, return
		if (!first_stats_card_el || !second_stats_card_el) {
			return;
		}

		// Clone them and set ids for styling purposes
		const first_stats_clone = first_stats_card_el.cloneNode(true);
		const second_stats_clone = second_stats_card_el.cloneNode(true);
		first_stats_clone.id = 'first-stats-clone';
		second_stats_clone.id = 'second-stats-clone';

		// Remove my-stats-shade children from the clones
		first_stats_clone.querySelector('.my-stats-shade').remove();
		second_stats_clone.querySelector('.my-stats-shade').remove();

		// Append them to the clone slot to properly convert it to data url
		const clone_slot = this.my_stats_clone_slot.current;
		clone_slot.appendChild(first_stats_clone);
		clone_slot.appendChild(second_stats_clone);

		// Convert the elements to data urls asynchronously
		convert_html_to_data_url(first_stats_clone, (data_url) => this.setState({ first_stats_card_data: data_url }));
		convert_html_to_data_url(second_stats_clone, (data_url) => this.setState({ second_stats_card_data: data_url }));
	}

	handle_share_my_stats() {
		// Checks which cards is displayed on the My Stats component
		// And shares the corresponding data url
		const {
			shown_card,
			first_stats_card_data,
			second_stats_card_data,
		} = this.state;

		const data_url = shown_card === 0 ? first_stats_card_data : second_stats_card_data;
		share_data_url(data_url, 'my-stats');
	}

	render(){
		const { spotify_stats, user } = this.props;
		const {
			first_stats_card_data,
			second_stats_card_data,
			shown_card
		} = this.state;

		// NxN Albums Grid and My Stats section
		const [time_listened, time_unit] = convert_time(user.listened) || [0, ''];

		return (
			<div className='profile-section my-stats'>
				<div className='my-stats-description'>
					<span>Here&apos;s your Groove Card.</span>
					<strong>Share it with the world!</strong>
				</div>
				<div className='my-stats-content'
					style={{ height: `calc(${window.innerWidth}px + 8px)` }}
				>
					<Swipeable className='my-stats-swipeable'
						onSwipedLeft={() => this.setState({ shown_card: 1 })}
						onSwipedRight={() => this.setState({ shown_card: 0 })}
					>
						<div className='my-stats-cards' 
							style={{ transform: shown_card === 0 ? 'translateX(0%)' : 'translateX(-405px)' }}
						>
							{/* Orange stats card */}
							<div className='my-stats-card' ref={this.first_stats_card}
								style={{ transform: `scale(${(window.innerWidth - 30) / 375})` }}
							>
								<div className='my-stats-card-background'
									style={{ backgroundImage: 'url(' + assets.background_share_stats + ')' }}
								></div>
								<div className='my-stats-card-content'>
									<div className='my-stats-card-header'>
										<div className='my-stats-card-title'>
											{user.first_name + '\'s Magroove Card'}
										</div>
										<div className='my-stats-card-line'></div>
									</div>

									<div className='my-stats-card-body'>
										<div className='my-stats-card-left'>
											<div className='my-stats-card-picture'>
												<Img
													src={[user.picture, assets.default_profile_image]}
													alt={this.props.user.first_name + ' ' + this.props.user.last_name}
													loader={
														<ContentLoader
															width={106}
															height={106}
															backgroundColor={'#D8D8D8'}
															foregroundColor={'#b1afaf'}
														>
															<circle cx='50%' cy='50%' r='50%' width={106} height={106} />
														</ContentLoader>
													}
													onLoad={() => this.profile_picture_loaded = true}
												/>
											</div>
											<div className='my-stats-card-time-title'>Magrooving Time</div>
											<div className='my-stats-card-time-value'>
												{time_listened} <span>{time_unit}</span>
											</div>
											<div className='my-stats-card-discovery-title'>Discovery</div>
											<div className='my-stats-card-discovery-value'>
												{user.tracks} <span>songs</span>
											</div>
										</div>

										<div className='my-stats-card-right'>
											<div className='my-stats-card-right-title'>Top Artists</div>
											{spotify_stats.long_term.artists && spotify_stats.long_term.artists.slice(0, 5).map((artist, index) =>
												<div className='my-stats-card-right-text' key={index}>{artist.name}</div>
											)}

											<div className='my-stats-card-right-title second-title'>Top Songs</div>
											{spotify_stats.long_term.songs && spotify_stats.long_term.songs.slice(0, 5).map((song, index) => (
												<div className='my-stats-card-right-text' key={index}>{song.title}</div>
											))}
										</div>
									</div>

									<div className='my-stats-card-footer'>
										<span className='my-stats-card-footer-text'>Find out your music status with</span>
										<span className='my-stats-card-footer-logo'
											style={{ backgroundImage: 'url(' + assets.magroove_logo + ')' }}
										>
										</span>
									</div>
								</div>
								<div className='my-stats-shade'></div>
							</div>

							{/* Black stats card */}
							<div className='my-stats-card' ref={this.second_stats_card}
								style={{ transform: `scale(${(window.innerWidth - 30) / 375})` }}
							>
								<div className='my-stats-card-background'
									style={{ backgroundImage: 'url(' + assets.background_share_stats + ')' }}
								></div>
								<div className='my-stats-card-content'>
									<div className='my-stats-card-header'>
										<div className='my-stats-card-title'>
											{user.first_name + '\'s All time'}
										</div>
										<div className='my-stats-card-line'></div>
									</div>

									<div className='my-stats-card-body'>
										<div className='my-stats-card-left'>
											<div className='my-stats-card-picture'>
												<Img
													src={[user.picture, assets.default_profile_image]}
													alt={this.props.user.first_name + ' ' + this.props.user.last_name}
													loader={
														<ContentLoader style={{ width: 106, height: 106, borderRadius: '100%'}} />
													}
													onLoad={() => this.profile_picture_loaded = true}
												/>
											</div>

											<div className='my-stats-card-time-title'>Time Listening</div>
											<div className='my-stats-card-time-value'>
												{time_listened} <span>{time_unit}</span>
											</div>

											<div className='my-stats-card-discovery-title'>Discovery</div>
											<div className='my-stats-card-discovery-value'>
												{user.tracks} <span>songs</span>
											</div>
										</div>

										<div className='my-stats-card-right'>
											<div className='my-stats-card-right-title'>Top Artists</div>
											{spotify_stats.long_term.artists && spotify_stats.long_term.artists.slice(0, 5).map((artist, index) =>
												<div className='my-stats-card-right-text' key={index}>{artist.name}</div>
											)}

											<div className='my-stats-card-right-title second-title'>Top Tracks</div>
											{spotify_stats.long_term.songs && spotify_stats.long_term.songs.slice(0, 5).map((song, index) => (
												<div className='my-stats-card-right-text' key={index}>{song.title}</div>
											))}
										</div>
									</div>

									<div className='my-stats-card-footer'>
										<span className='my-stats-card-footer-text'>Find out you music status with</span>
										<span className='my-stats-card-footer-logo' style={{ backgroundImage: 'url(' + assets.magroove_logo + ')' }}
										></span>
									</div>
								</div>
								<div className='my-stats-shade'></div>
							</div>
						</div>
					</Swipeable>
					<Button className='share-stats-button'
						onClick={this.handle_share_my_stats}
						size='small'
						main_color='rgba(255, 255, 255, 0.17)'
						secondary_color='#fff5f2'
						value={
							(!first_stats_card_data || !second_stats_card_data) ? (
								<CircularProgress size={16} />
							) : Capacitor.getPlatform() === 'web' ? (
								<React.Fragment>
									<span className='material-icons'>file_download</span> Save Image
								</React.Fragment>
							) : (
								<React.Fragment>
									<span className='material-icons'>share</span> Share
								</React.Fragment>
							)
						}
					/>
					<div className='my-stats-scroll-container' style={{ marginTop: Capacitor.getPlatform() === 'web' ? '16px' : '25px' }}>
						<div className='my-stats-scroll-dot' style={{ opacity: shown_card === 0 ? '1' : '0.1' }}></div>
						<div className='my-stats-scroll-dot' style={{ opacity: shown_card === 1 ? '1' : '0.1' }}></div>
					</div>
				</div>
				<div id='my-stats-clone-slot' ref={this.my_stats_clone_slot}></div>
			</div>
		);
	}
}

class AlbumsGrid extends Component {
	constructor(props){
		super(props);
		this.state = {
			album_grid_data: false
		};

		// Element references
		this.album_grid = React.createRef();
		this.album_grid_clone_slot = React.createRef();

		this.get_shareable_album_grid_data = this.get_shareable_album_grid_data.bind(this);
	}

	componentDidMount() {
		// If there are 16 or more albums, get the album grid data
		if (this.props.spotify_stats &&
			this.props.spotify_stats.long_term.albums.length >= 16
		){
			setTimeout(this.get_shareable_album_grid_data, 1000);
		}
	}

	async get_shareable_album_grid_data() {
		// If there isn't any element, just return
		if (!this.album_grid.current) {
			return;
		}

		// This function get the album grid element and convert it to data url asynchronously
		const album_grid_el = this.album_grid.current;
		
		// Clone it and set the id for styling purposes
		const album_grid_clone = album_grid_el.cloneNode(true);
		album_grid_clone.id = 'album-grid-clone';

		// Append it to the clone slot to properly convert it to data url
		const clone_slot = this.album_grid_clone_slot.current;
		clone_slot.appendChild(album_grid_clone);
		
		// Convert the element to data url asynchronously
		convert_html_to_data_url(album_grid_clone, (data_url) => this.setState({ album_grid_data: data_url }));
	}

	render(){
		if (!this.props.spotify_stats || this.props.spotify_stats.long_term.albums.length < 16) return null;
		return (
			<div className='profile-section'>
				<div className='profile-subsection-share-stats'>
					<div className='share-stats-description'>
						<span>Your 4x4 with the albums you heard the most.</span>
						<strong>Share it with the world!</strong>
					</div>
					<div className='share-stats-album-grid' ref={this.album_grid}>
						{this.props.spotify_stats.long_term.albums.map((album, index) => (
							<div className='share-stats-album-grid-item' key={'stats-share-album-grid-item-' + index}>
								<Card data={album} type='grid_card_album' bodyLink={'/album/' + album.spotify_id + '/'} key={index} />
							</div>
						))}
					</div>
					<div className='share-stats-shade'></div>
					<Button
						className='share-stats-button'
						onClick={() => share_data_url(this.state.album_grid_data, 'most-heard-albums')}
						size='small'
						main_color='rgba(255, 255, 255, 0.17)'
						secondary_color='#fff5f2'
						value={
							!this.state.album_grid_data ? (
								<CircularProgress size={16} />
							) : Capacitor.getPlatform() === 'web' ? (
								<Fragment><span className='material-icons'>file_download</span>Save Image</Fragment>
							) : (
								<Fragment><span className='material-icons'>share</span>Share</Fragment>
							)
						}
					/>
					<div id='album-grid-clone-slot' ref={this.album_grid_clone_slot}></div>
				</div>
			</div>
		);
	}
}

class ShareableComponent extends Component {
	render(){
		if (this.props.type === 'spotify_stats'){
			return <SpotifyStats {...this} user={this.props.user} spotify_stats={this.props.spotify_stats} />;
		}
		else if (this.props.type === 'album_grid'){
			return <AlbumsGrid {...this} spotify_stats={this.props.spotify_stats} />;
		}
		return null;
	}
}

function DiscoveryLeaderboardComponent(props) {
	if (props.editable && props.data.length < 5){
		return (
			<div
				className='profile-section-suggestion'
				onClick={() => { redirect('/search/'); }}
				style={{backgroundImage: `url('${assets.milestone_find_friends_big}')`}}>
				<div className='profile-section-suggestion-text'>
					<p><b style={{color: '#ec6b43'}}>Follow your friends</b> to discover what your leaderboard looks like!</p>
					<div>FIND FRIENDS</div>
				</div>
			</div>
		);
	}
	return (
		<div className='profile-section-content-row'>
			{props.data.map((item, i) => (
				<div className='profile-section-rank-item' key={i} onClick={() => set_new_profile_info(item)}>
					<Link to={'/profile/' + (item.slug ? item.slug : item.hash_id.substring(0,10))}>
						<div className='profile-section-rank-item-position'>
							<div className='profile-section-rank-item-position-number'>{i + 1}</div>
						</div>
						<div className='profile-section-rank-item-info'>
							<Img src={[item.picture,assets.default_profile_image]} alt={item.name} />
							<div className='profile-section-rank-item-text'>
								<div className='profile-section-rank-item-title' style={item.hash_id === props.user.hash_id ? {color: '#ec6b43'} : {}}>{item.slug ? '@' + item.slug : item.name}</div>
								<div className='profile-section-rank-item-subtitle'>{item.likes} songs liked</div>
							</div>
						</div>
					</Link>
				</div>
			))}
		</div>
	);
}

function filter_discovery_timeline_data(props){
	const timeline_data = props.data;
	const timeline_available = timeline_data ? timeline_data.user_interactions && timeline_data.global_interactions : false;

	//  If there is no data, we return null
	if (!timeline_available) return;

	// Building the data for the chart
	let return_undefined = true;
	const user_interactions = [...timeline_data.user_interactions];
	const global_interactions = [...timeline_data.global_interactions];
	let discovery_timeline = []; let maxValue = 0;

	for (let i = 0; i < 7; i++) {
		let u = user_interactions[i];
		let g = global_interactions[i];

		discovery_timeline.push({
			name: g.weekday,
			[props.display_user]: u.interactions,
			'Rest of the world': g.interactions
		});

		maxValue = Math.max(u.interactions, g.interactions, maxValue);

		if (u.interactions){
			return_undefined = false;
		}
	}

	if (return_undefined) return undefined;

	// If there is no enough numbers, we return null
	if (maxValue === 0) return;

	// Calculate the grid height
	let gridYvalues = [];
	for (let i = 0; i <= Math.ceil(maxValue/5); i++) gridYvalues.push(i*5);
	return {discovery_timeline: discovery_timeline, gridYvalues: gridYvalues};
}

function DiscoveryTimelineComponent(props){
	const data = filter_discovery_timeline_data(props);
	if (!data && props.editable) {
		return (<Milestone label='First Steps' image={assets.milestone_discover_new} text='Discover new music' onClick={() => redirect('/search/')} />);
	}
	else if (!data) return null;
	else {
		return (
			<div className='profile-section-chart-aspect-content'>
				<StatsBar
					data={data.discovery_timeline}
					maxValue={data.gridYvalues.at(-1)} 
					gridYValues={data.gridYvalues}
					vertex='name' 
					levels={[props.display_user, 'Rest of the world']}
				/>
			</div>
		);
	}
}

function FollowingArtists(props){
	const index = props.data_index || 3;
	return (
		<div className='profile-section-content-row'>
			{props.data.slice(0, index).map((artist) => {
				const action_available = artist.is_following ? 'unfollow' : 'follow';
				const onMoreClick = props.editable ? false : () => props.follow_artist_management(action_available, artist);
				const blockClick = props.editable ? true : false;

				return (
					<Card 
						data={artist} 
						type='small_card_artist'
						button_type={props.editable ? false : 'follow'}
						subtitle_text={artist.followers + ' followers'}
						blockClick={blockClick}
						key={artist.spotify_id} 
						onMoreClick={onMoreClick}
					/>
				);
			})}
		</div>
	);
}

function FollowingUsers(props) {
	const index = props.data_index || 3;
	return (
		<div className='profile-section-content-row'>
			{props.data.slice(0, index).map((user) => {
				if (!user.hash_id && !user.slug) return null;

				const action_available = user.followed ? 'unfollow' : 'follow';
				const bodyLink = props.editable ? false : '/profile/' + (user.slug ? user.slug : user.hash_id.substring(0,10));
				const onClick = props.editable ? false : () => props.follow_user_management(action_available, user);

				return (
					<Card
						key={user.member_id}
						data={user}
						bodyLink={bodyLink}
						type='profile_card' 
						onClick={onClick}
						show_follow={!props.editable}
					/>
				);
			})}
		</div>
	);
}

function GenreChartComponent(props) {
	const { data, my_data, display_user, own_profile } = props;
	if (props.data.genre_data === undefined) return null;

	const all_display_plays = [];
	for (let i = 0; i < data.genre_data.length; i++) {
		const item = data.genre_data[i];
		const my_item = my_data.genre_data && my_data.genre_data.find(g => g.display_name === item.display_name);
		if (own_profile || !my_item){
			all_display_plays.push({ 
				display_name: item.display_name, 
				[display_user]: item.display_plays, 
			});
		} else if (my_item){ 
			all_display_plays.push({ 
				display_name: item.display_name, 
				'Me': my_item.display_plays,
				[display_user]: item.display_plays, 
			});
		}
	}

	if (all_display_plays.length === 0) return;
	const show_both_users = !own_profile && !!all_display_plays.find(a => a['Me'] !== undefined);
	return (
		<Fragment>
			<div className={show_both_users ? 'profile-section-chart-content-comparing-users' : 'profile-section-chart-content'}>
				<StatsRadar
					colors={show_both_users ? {'Me': 'rgba(236, 236, 236, 0.9)'} : false }
					data={all_display_plays} 
					vertex='display_name' 
					levels={show_both_users ? ['Me', display_user] : [display_user]}
				/>
			</div>
			{[...data.genre_data].sort((a,b) => b.total_plays - a.total_plays).map((item, i) => (
				<div className='profile-section-chart-item' key={i}>
					<div className='profile-section-chart-item-info'>
						<div className='profile-section-chart-item-number'>{i + 1}</div>
						<div className='profile-section-chart-item-title'>{item.musical_genre}</div>
					</div>
					<div className='profile-section-chart-item-infos-container'>
						{show_both_users && <div className='profile-section-chart-item-chip grey-chip'>{[...my_data.genre_data].find(i => i.display_name === item.display_name).total_plays} plays</div>}
						<div className='profile-section-chart-item-chip'>{item.total_plays} plays</div>
					</div>
				</div>
			))}
		</Fragment>
	);
}

function Likeminded(props){
	const default_data = [
		{hash_id: '1', slug: 'user123', first_name: 'John Doe', liked_artists: ['Magroove']}
	];

	const data = props.editable && props.data.length === 0 ? default_data : props.data;
	return (
		<div className='profile-section-content-row'>
			{data.map((user, i) => (
				<Card key={i}
					type='user_card' data={user}
					onClick={props.editable ? false : () => set_new_profile_info(user)}
					onFollow={props.editable ? false : () => props.handle_follow_action(user.user_id)}
				/>
			))}
		</div>
	);
}

function MusicAspectsComponent(props){
	const { data, my_data, own_profile, display_user } = props;

	const aspects_data = data.aspects_data.sort((a, b) => b.aspect_level - a.aspect_level);
	const my_aspects_data = my_data.aspects_data;

	const graph_data = [];
	for (let i = 0; i < aspects_data.length; i++) {
		const item = aspects_data[i];
		const my_item = my_aspects_data && my_aspects_data.find(g => g.display_name === item.display_name);
		if (!my_item || own_profile){
			graph_data.push({ 
				display_name: item.display_name, 
				[display_user]: item.aspect_level, 
			});
		} else if (my_item){ 
			graph_data.push({ 
				display_name: item.display_name, 
				'Me': my_item.aspect_level,
				[display_user]: item.aspect_level, 
			});
		}
	}

	const show_both_users = !own_profile && !!graph_data.find(a => a['Me'] !== undefined);
	return (
		<Fragment>
			<div className={show_both_users ? 'profile-section-chart-content-comparing-users' : 'profile-section-chart-content'}>
				<StatsRadar 
					colors={show_both_users ? {'Me': 'rgba(236, 236, 236, 0.9)'} : false }
					data={graph_data} 
					vertex='display_name' 
					levels={show_both_users ? ['Me', display_user] : [display_user]}
				/>
			</div>
			{graph_data.map((item, i) => (
				<div className='profile-section-chart-item' key={i}>
					<div className='profile-section-chart-item-info'>
						<div className='profile-section-chart-item-title'>{item.display_name}</div>
					</div>
					<div className='profile-section-chart-item-infos-container'>
						{show_both_users && <div className='profile-section-chart-item-chip grey-chip'>{~~item['Me']}</div>}
						<div className='profile-section-chart-item-chip'>{~~(item[display_user])}</div>
					</div>
				</div>
			))}
		</Fragment>
	);
}


function MusicTasteComponent(props){
	const { data, my_data, display_user, own_profile } = props; 

	const aspects_data = data.aspects_data;
	const my_aspects_data = my_data.aspects_data;
	const global_aspects_data = data.global_aspects_data;

	const graph_data = [];
	for (let i = 0; i < aspects_data.length; i++) {
		const item = aspects_data[i];
		if (['Danceable', 'Happiness'].includes(item.display_name)) continue;

		const my_item = my_aspects_data && my_aspects_data.find(g => g.display_name === item.display_name);
		const global_item = global_aspects_data && global_aspects_data.find(g => g.display_name === item.display_name);

		if (my_item && !own_profile){
			graph_data.push({ 
				name: item.display_name, 
				'Me': Math.floor(my_item.aspect_level),
				[display_user]: Math.floor(item.aspect_level), 
			});
		} else if (global_item){ 
			graph_data.push({ 
				name: item.display_name, 
				'Rest of the world': Math.floor(global_item.aspect_level),
				[display_user]: Math.floor(item.aspect_level), 
			});
		}
	}

	if (graph_data === undefined || !graph_data) return null;
	const show_both_users = !own_profile && !!graph_data.find(a => a['Me'] !== undefined);
	return (
		<div className={show_both_users ? 'profile-section-chart-aspect-content-comparing-users' : 'profile-section-chart-aspect-content'}>
			<AspectsBar
				key='global_graph_bar'
				colors={show_both_users ? {'Me': 'rgb(112, 112, 112)'} : false }
				data={graph_data} 
				vertex='name' 
				editable={props.editable} 
				levels={show_both_users ? ['Me', display_user] : [display_user, 'Rest of the world'] }
			/>
		</div>
	);
}

function Obsession(props){
	const { logged_user, user } = props;
	const own_profile = user.hash_id === logged_user.hash_id;
	const obsession_not_defined = user && (!user.obsession || !user.obsession.song);

	if (own_profile && obsession_not_defined){
		return (
			<div className='profile-section-content-row-milestone'>
				<Milestone label='What&apos;s new?' image={assets.milestone_set_obsession} text='Set your latest obsession' onClick={() => redirect('/obsession-search/song/')} />
			</div>
		);
	}
	else if (obsession_not_defined) return null;
	return (
		<div className='profile-section-content-row'>
			<Card
				key={user.obsession.song.isrc}
				data={user.obsession.song}
				type='small_card_song'
				onMoreClick={props.editable ? false : () => {store_set('song_info', user.obsession.song); store_set('active_overlay', 'track_options');}}
				blockLink={props.editable ? true : false}
			/>
		</div>
	);
}

function Overview(props){
	if (!props.user) return null;
	return (
		<div className='profile-section-content'>
			<div className='profile-overview-card'>
				<img className='profile-overview-card-icon' src={assets.icon_recomendations} alt='Seed'/>
				<div className='profile-overview-card-info'>
					<div className='profile-overview-card-title'>Recommendations</div>
					<div className='profile-overview-card-number'>{props.user.tracks}</div>
				</div>
			</div>
			<div className='profile-overview-card'>
				<span className='profile-overview-card-icon material-icons'>favorite</span>
				<div className='profile-overview-card-info'>
					<div className='profile-overview-card-title'>Likes</div>
					<div className='profile-overview-card-number'>{props.user.likes}</div>
				</div>
			</div>
			<div className='profile-overview-card'>
				<span className='profile-overview-card-icon material-icons'>close</span>
				<div className='profile-overview-card-info'>
					<div className='profile-overview-card-title'>Dislikes</div>
					<div className='profile-overview-card-number'>{props.user.dislikes}</div>
				</div>
			</div>
		</div>
	);
}

function Polls(props){
	if (props.data === 'loading') return null;

	const polls = props.data.filter(item => item.author);
	if (polls.length === 0){
		return (
			<div className='profile-section-content-row-milestone'>
				<Milestone label='First Steps' image={assets.milestone_first_poll} text='Create your first poll' onClick={() => redirect('/new-poll/')} />
			</div>
		);
	}

	const card_width = window.innerWidth*0.75;
	const image_width = 345; // Width of the rectangle in pixels
	const image_height = 245; // Height of the rectangle in pixels
	const height = (card_width * image_height) / image_width;

	return (
		<div className='profile-section-content'>
			{polls.map((item, index) => (
				<Card key={index} type='poll_card' data={item} user={props.user} onDelete={props.editable ? () => props.onDelete(item.hash_id) : false} />
			))}
			{props.own_profile &&
				<div className='profile-section-start-poll' style={{minWidth: card_width, height: height + 61, backgroundImage: `url(${assets.start_poll})`}}>
					<div className='profile-section-start-poll-text'>You can always count on a <span>GOOD FRIEND</span><br></br>for <span>GOOD MUSIC</span></div>
					<div className='profile-section-start-poll-button' onClick={() => redirect('/new-poll/')}>START POLL</div>
				</div>
			}
		</div>
	);
}

function TopAlbums(props){
	return (
		<div className='profile-section-content'>
			{props.data.slice(0, 3).map((album) =>
				<Card
					key={album.spotify_id}
					data={album}
					type='big_card_album'
					bodyLink={props.editable ? false : '/album/' + album.spotify_id + '/'}
					onDelete={props.editable ? () => props.onDelete(album) : false}
				/>
			)}
			{props.editable && props.data.length < 3 &&
				<div
					onClick={() => {store_set('selected_search_items', []); store_set('already_selected_search_items', props.data); redirect('/edit-profile-search/album/');}}
					className='profile-section-add-new-square'
					style={{ marginLeft: props.data.length > 0 ? 15 : 0}}
				>
					<div className='material-icons'>add</div>
					<div className='profile-section-add-new-text'>Add new</div>
				</div>
			}
		</div>
	);
}

function TopArtists(props){
	return (
		<div className='profile-section-content'>
			{props.data.slice(0,3).map((artist) =>
				<Card
					key={artist.spotify_id}
					data={artist}
					type='big_card_artist'
					bodyLink={props.editable ? false : '/artist/' + artist.spotify_id + '/'}
					onDelete={props.editable ? () => props.onDelete(artist) : false}
				/>
			)}
			{props.editable && props.data.length < 3 &&
				<div
					onClick={() => {store_set('selected_search_items', []); store_set('already_selected_search_items', props.data); redirect('/edit-profile-search/artist/');}}
					className='profile-section-add-new-square'
					style={{ marginLeft: props.data.length > 0 ? 15 : 0}}
				>
					<div className='material-icons'>add</div>
					<div className='profile-section-add-new-text'>Add new</div>
				</div>
			}
		</div>
	);
}

function TopTracks(props){
	return (
		<div className='profile-section-content-row'>
			{props.data.slice(0,3).map((song) =>
				<Card 
					key={song.spotify_id}
					data={song}
					type='small_card_song'
					blockLink={props.editable ? true : false}
					onDelete={props.editable ? () => props.onDelete(song) : false}
					onMoreClick={props.editable ? false : () => {store_set('song_info', song); store_set('active_overlay', 'track_options');}}
				/>
			)}
			{props.editable && props.data.length < 3 &&
				<div
					onClick={() => {store_set('selected_search_items', []); store_set('already_selected_search_items', props.data); redirect('/edit-profile-search/song/');}}
					className='profile-section-add-new-retangle'
				>
					<div className='material-icons'>add</div>
					<div className='profile-section-add-new-text'>Add new</div>
				</div>
			}
		</div>
	);
}

function PeopleYouMayKnow(props){
	const data = props.data;
	if (!data.length) return null;
	return (
		<div className='profile-section-content-scroll'>
			{data.slice(0,10).map((user) => (
				<Card key={user.hash_id}
					type='user_alternative_card' data={user}
					onClick={() => set_new_profile_info(user)}
					onFollow={() => props.onFollow(user.member_id, 'people_you_may_know')}
				/>
			))}
		</div>
	);
}

function EditQuizes(props) {
	const quiz_list = [...constants.quiz_list];
	const user_quiz = props.data;
	if (!user_quiz) return null;

	const take_quiz = () => {
		const first = constants.quiz_list[0];
		store_set('quiz', {quiz: first, index: 0});
		redirect(`/quiz-search/${first.search}/`);
	};

	return(
		<div className='profile-section-content'>
			<div className='profile-section-quizes'>
				{quiz_list.map((quiz_info, quiz_index) => {
					const quiz = user_quiz.find(item => item.quiz === quiz_info.quiz);
					const onEdit = () => { 
						store_set('quiz', {quiz: quiz_info, index: quiz_index}); 
						store_set('edit_quiz', true); 
						const showcase = document.querySelector('.main-content-inner > div');
						store_set('show_case_scroll', showcase.scrollTop);
						redirect(`/quiz-search/${quiz_info.quiz}/`); 
					};
					const onDelete = quiz ? () => props.onDelete(quiz.quiz) : () => {};
					return(
						<div key={quiz_index} className='profile-section-quiz' style={{borderBottom: quiz_index + 1 === quiz_list.length ? 'none' : 'solid 0.5px #a7a7a7'}}>
							<div className='profile-section-quiz-title-container'>
								<div className='profile-section-quiz-title'>
									<figure className='profile-section-quiz-icon'>
										<img src={quiz_info.icons[0]} alt=''/>
									</figure>
									<p>{quiz_info.title}</p>
								</div>
								<div className='profile-section-quiz-title-edit'>
									<span className='material-icons' onClick={onEdit}>edit</span>
									<span className='material-icons' onClick={onDelete} style={{opacity: quiz ? 1 : 0.3}}>delete</span>
								</div>
							</div>
							{quiz && quiz.item_type === 'song' &&
								<Card data={quiz.item} type='small_card_song' />
							}
							{quiz && quiz.item_type === 'artist' &&
								<Card data={quiz.item} type='small_card_artist' />
							}
						</div>
					);
				})}
				<div onClick={() => take_quiz()} className='profile-section-take-quiz-button'>TAKE QUIZ</div>
			</div>
		</div>
	);
}

// Stop complex elements from re-rendering when unnecessary
function freezeRender(prevProps, nextProps) {
	const is_equal = _.isEqual(prevProps, nextProps);
	return !is_equal;
}

const Shareable = (React.memo(ShareableComponent, freezeRender));
const DiscoveryTimeline = (React.memo(DiscoveryTimelineComponent, freezeRender));
const MusicAspects = (React.memo(MusicAspectsComponent, freezeRender));
const MusicTaste = (React.memo(MusicTasteComponent, freezeRender));
const GenreChart = (React.memo(GenreChartComponent, freezeRender));
const DiscoveryLeaderboard = (React.memo(DiscoveryLeaderboardComponent, freezeRender));


export {
	AlbumsGrid,
	DiscoveryLeaderboard,
	DiscoveryTimeline,
	FollowingArtists,
	FollowingUsers,
	GenreChart,
	Likeminded,
	MusicAspects,
	MusicTaste,
	Obsession,
	Overview,
	Polls,
	Shareable,
	TopAlbums,
	TopArtists,
	TopTracks,
	PeopleYouMayKnow,
	EditQuizes,
};
