import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import YouTube from 'react-youtube';
import CircularProgress from '@material-ui/core/CircularProgress';

// CSS Imports
import './styles/Track.css';
import 'react-bubble-ui/dist/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Assets Imports
import { default_album_image } from '@Assets';

// Components and Views Imports
import Haptic from '@components/haptic.js';
import Ripples from 'react-ripples';
// import Card from '@components/Card.js'

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '../RequestBuffer.js';

// Constant Imports
import * as constants from '../constants.js';

// Import the event bus
import event_bus from '@Eventbus';

// Import the controllers
import queue from '@Queue';
import player from '@Player';
import file from '@FileManager';

class Bubble {
	constructor(min, max) {
		// Setting X axis
		this.width = get_random_number(min, max);
		this.left = 0;
		this.aux_left = 0;
		this.top = 0;
		this.aux_top = 0;
	}

	set_position(x, y) {
		this.left = x;
		this.top = y;
	}

	set_aux_position(x, y) {
		this.aux_left = x;
		this.aux_top = y;
	}

	deg_to_radius(deg) {
		return (deg * Math.PI) / 180;
	}

	rotate(deg) {
		// rotate axis
		let new_left = (this.left * Math.cos(this.deg_to_radius(deg))) - (this.top * Math.sin(this.deg_to_radius(deg)));
		let new_top = (this.left * Math.sin(this.deg_to_radius(deg))) + (this.top * Math.cos(this.deg_to_radius(deg)));
		this.set_position(new_left, new_top);
		this.set_aux_position(new_left, new_top);
	}
}

function BubblesContainer(props) {
	const history = useHistory();
	const [entered_page, set_entered_page] = useState(false);
	const [current_list, set_current_list] = useState([]);
	const [temporary_list, set_temporary_list] = useState([]);
	const [temporary_matrix, set_temporary_matrix] = useState([]);

	// Get screen size
	const screen_height = window.innerHeight;
	let main_bubble_size = 150;
	let min_bubble_range = 100;
	let max_bubble_range = 120;
	let min_bubble_second_range = 70;
	let max_bubble_second_range = 90;
	if (screen_height < 741) {
		main_bubble_size = 120;
		min_bubble_range = 60;
		max_bubble_range = 90;
		min_bubble_second_range = 50;
		max_bubble_second_range = 70;
	}

	// Variables to control the bubbles
	main_bubble_size = props.main_bubble_size ? props.main_bubble_size : main_bubble_size;
	const main_bubble_border = props.main_bubble_border ? props.main_bubble_border : 20;
	const deg_between_bubbles = props.deg_between_bubbles ? props.deg_between_bubbles : 5;
	const initial_deg_bubbles = get_random_number(0, 360);
	const distance_between_bubbles = props.distance_between_bubbles ? props.distance_between_bubbles : 10;
	var max_bubbles = props.similar_songs.length < 25 ? props.similar_songs.length - 1 : 25;

	// Min and max range to sort bubbles size
	min_bubble_range = props.min_bubble_range ? props.min_bubble_range : min_bubble_range;
	max_bubble_range = props.min_bubble_range ? props.min_bubble_range : max_bubble_range;
	min_bubble_second_range = props.min_bubble_second_range ? props.main_bubble_size : min_bubble_second_range;
	max_bubble_second_range = props.min_bubble_second_range ? props.main_bubble_size : max_bubble_second_range;

	// Variables to control the container
	const container_padding = props.container_padding ? props.container_padding : 20;

	// Other variables
	let deg_big_bubbles = 3;
	deg_big_bubbles = (360 / deg_between_bubbles) / deg_big_bubbles;
	const layer_interval = 1;
	const layer_length = 300;
	let layer_distance;

	// Call handle click only when user first enter track page
	/* eslint-disable */
	useEffect(() => {
		if (props.similar_songs.length > 0 && !entered_page) {
			set_entered_page(true);
			handleClick(props.track_info);
		}
	}, [props.similar_songs]);
	/* eslint-enable */

	function distribute_bubbles(screen_middle_width, screen_middle_height) {
		function has_bubble_collided(bubble, index) {
			if (index === 0) return 0;

			let bubbles = [];
			bubbles.concat(temporary_matrix.at(index));
			/* eslint-disable */
			for (let i = 1; i <= Math.ceil((360 / deg_between_bubbles) / 8); i++) {
				bubbles = bubbles.concat(temporary_matrix.at(index - i));
				bubbles = bubbles.concat(temporary_matrix.at((index + i) % temporary_matrix.length));
			}
			/* eslint-enable */

			// Checking if  the bubble is collided with next bubbles
			for (let i = 0; i < bubbles.length; i++) {
				let b = bubbles[i];
				let x = Math.pow(bubble.aux_left - b.aux_left, 2);
				let y = Math.pow(bubble.aux_top - b.aux_top, 2);
				let min_distance = (bubble.width / 2) + (b.width / 2) + distance_between_bubbles;
				let distance = Math.sqrt(x + y);

				if (distance < min_distance) {
					return min_distance - distance;
				}
			}
			return 0;

		}

		// Checking if  the bubble is out of the screen
		function distance_out_screen(bubble) {
			let maxX = (screen_middle_width * 2) - container_padding;
			let maxY = (screen_middle_height * 2);
			let minX = 0;
			let minY = 0;

			if ((bubble.left + bubble.width) > maxX) return (bubble.left + bubble.width) - maxX;
			if (bubble.left < minX) return minX - bubble.left;
			if ((bubble.top + bubble.width) > maxY) return (bubble.top + bubble.width) - maxX;
			if (bubble.top < minY) return minY - bubble.top;
			return 0;
		}

		function get_new_bubble(dis, deg, min, max, index) {
			if (temporary_list.length === max_bubbles) return;
			let new_bubble = new Bubble(min, max);

			// Setting new position
			let posX = ((new_bubble.width + dis) / 2) + distance_between_bubbles;
			let posY = 0;
			new_bubble.set_position(posX, posY);

			// Rotating
			new_bubble.rotate(deg + initial_deg_bubbles);
			posX = screen_middle_width + new_bubble.left - (new_bubble.width / 2);
			posY = screen_middle_height + new_bubble.top - (new_bubble.width / 2);
			new_bubble.set_position(posX, posY);

			// Checking if  the bubble is out of the screen
			let distance = distance_out_screen(new_bubble, index);
			if (distance === 0) {
				distance = has_bubble_collided(new_bubble, index);
				if (distance === 0) {
					// Saving in matrix
					let new_list = [...temporary_matrix];
					new_list[index].push(new_bubble);
					set_temporary_matrix(new_list);

					// Saving in list
					temporary_list.push(new_bubble);
					set_temporary_list(temporary_list);
					return;
				}
				else {
					// Trying to get smaller
					let new_width = new_bubble.width - distance;
					if (new_width >= min_bubble_second_range) return get_new_bubble(dis, deg, min_bubble_second_range, new_width, index);

					if (dis + layer_interval <= layer_distance) {
						if (index % deg_big_bubbles === 0) return get_new_bubble(dis + layer_interval, deg, min_bubble_range, max_bubble_range, index);
						else return get_new_bubble(dis + layer_interval, deg, min_bubble_second_range, max_bubble_second_range, index);
					}
				}
			}
			else {
				// Trying to get smaller
				let new_width = new_bubble.width - distance;
				if (new_width >= min_bubble_second_range) return get_new_bubble(dis, deg, min_bubble_second_range, new_width, index);
			}
		}

		for (let i = 0; i < (360 / deg_between_bubbles); i++) {
			temporary_matrix.push([]);
			set_temporary_matrix(temporary_matrix);
		}

		const start = Date.now();
		let last_temporary_list_size = -1;
		layer_distance = main_bubble_size + layer_length;
		while (last_temporary_list_size !== temporary_list.length) {

			for (let i = 0; i < (360 / (deg_between_bubbles)); i += deg_big_bubbles) {
				let deg = i * deg_between_bubbles;
				let distance = main_bubble_size + main_bubble_border;

				// Recursive method
				let layer = temporary_matrix[i];
				if (layer.length > 0) {
					let last_bubble = layer.at(-1);
					distance += 2 * (last_bubble.width + distance_between_bubbles);
				}
				get_new_bubble(distance, deg, min_bubble_range, max_bubble_range, i);
			}

			// Distributing bubbles
			for (let i = 0; i < (360 / deg_between_bubbles); i++) {
				if (i % deg_big_bubbles === 0) continue;
				let deg = i * deg_between_bubbles;
				let distance = main_bubble_size + main_bubble_border;

				// Recursive method
				let layer = temporary_matrix[i];
				if (layer.length > 0) {
					let last_bubble = layer.at(-1);
					distance += 2 * (last_bubble.width + distance_between_bubbles);
				}
				get_new_bubble(distance, deg, min_bubble_second_range, max_bubble_second_range, i);
			}
			last_temporary_list_size = temporary_list.length;
			layer_distance += layer_length;
		}
		return Date.now() - start;
	}

	function handleClick(track_info) {
		let current_main_bubble = document.getElementsByClassName('track-similar-songs-main-bubble')[0];
		if (current_main_bubble) {
			current_main_bubble.style.opacity = 0;
		}

		let main_bubble = document.getElementById(track_info.spotify_id);
		let default_bubble = document.getElementById('default-bubble');
		if (!main_bubble) {
			main_bubble = default_bubble;
		}

		let bubbles_container = document.getElementById('bubbles-container');
		let bubbles_container_rect = bubbles_container.getBoundingClientRect();
		let screen_middle_width = (window.innerWidth / 2) - container_padding;
		let screen_middle_height = (bubbles_container_rect.height / 2);

		// The code below is a "work around" to set the position of the main bubble to the center of the screen 
		// (so we get the screen size and divide it by 2, then we subtract the width of the main bubble (130/2) and the margins that exists from both sides of the bubbles container)
		main_bubble.classList.add('main-bubble');
		main_bubble.style.top = `${screen_middle_height - (main_bubble_size / 2)}px`;
		main_bubble.style.left = `${screen_middle_width - (main_bubble_size / 2)}px`;
		main_bubble.style.width = `${main_bubble_size}px`;
		main_bubble.style.height = `${main_bubble_size}px`;

		// Filter the current_similar_songs to remove the main bubble
		var current_similar_songs = [...props.similar_songs];
		current_similar_songs = current_similar_songs.filter((item) => item.spotify_id !== track_info.spotify_id);

		// eslint-disable-next-line
		let distribute_time = distribute_bubbles(screen_middle_width, screen_middle_height);
		setTimeout(
			() => {
				// Get list current_similar_songs and random sort it
				let random_current_similar_songs = JSON.parse(JSON.stringify(current_similar_songs));
				random_current_similar_songs = random_current_similar_songs.sort(() => Math.random() - 0.5);

				// For each bubble created in temporary_list, combine it to an item in current_similar_songs
				let new_list = [];
				temporary_list.forEach((item, index) => {
					let new_item = {
						...item,
						...random_current_similar_songs[index]
					};
					new_list.push(new_item);
				});

				// Add the main bubble to the list
				let main_bubble_item = {
					...track_info,
					left: screen_middle_width - (main_bubble_size / 2),
					top: screen_middle_height - (main_bubble_size / 2),
					width: main_bubble_size,
					height: main_bubble_size
				};

				// Update bubbles current_list
				new_list.push(main_bubble_item);
				set_current_list(new_list);

				// Back to default values
				default_bubble.style.display = 'none';
				set_temporary_list([]);
				set_temporary_matrix([]);
				main_bubble.classList.remove('main-bubble');
			}
			, 1200);


		// Change page to the song user clicked and load all the necessary info by calling set_initial_config
		history.push('/track/' + track_info.spotify_id);
		props.set_initial_config(track_info);
	}

	if (props.similar_songs.length === 0) {
		return (
			<div className='bubbles-container' id='bubbles-container'>
				<CircularProgress size={50} />
			</div>
		);
	}

	return (
		<div className='bubbles-container' id='bubbles-container'>
			<div id="default-bubble"></div>
			{current_list.map((bubble, index) => {
				if (bubble.spotify_id === props.track_info.spotify_id) {
					return (
						<div
							className='bubble track-similar-songs-main-bubble'
							key={bubble.spotify_id + '_' + index.toString()}
							style={{ left: bubble.left, top: bubble.top, width: bubble.width, height: bubble.width }}
							id={bubble.spotify_id}
						>
							<div
								className='track-similar-songs-child-bubble track-similar-songs-parent-bubble'
								onClick={props.track_is_playing ? () => player.toggle() : () => player.load(0, props.track_info)}
								style={{ backgroundImage: 'url(\'' + bubble.album.images.medium + '\')' }}
							>
								<div
									className='track-similar-songs-child-bubble-play-button'
								>
									{(!props.track_is_playing || (props.track_is_playing && !props.is_playing)) &&
										<span className='material-icons'>play_arrow</span>
									}
									{props.track_is_playing && props.is_playing &&
										<span className='material-icons'>pause</span>
									}
								</div>
							</div>
							<div className='material-icons' onClick={() => { store_set('active_overlay', 'musical_reference'); }}>more_vert</div>
						</div>
					);
				} else {
					let bubble_image = bubble.album.images.medium ? bubble.album.images.medium : default_album_image;
					return (
						<div
							className="bubble"
							key={bubble.spotify_id + '_' + index.toString()}
							style={{ position: 'absolute', left: bubble.left, top: bubble.top, width: bubble.width, height: bubble.width, backgroundImage: 'url(\'' + bubble_image + '\')' }}
							id={bubble.spotify_id}
							onClick={() => handleClick(bubble)}
						>
						</div>
					);
				}
			})}
		</div>
	);
}

// This function helps us get random minimum and max numbers for the bubble sizes
function get_random_number(min, max) {
	return Math.floor(
		Math.random() * (max - min) + min
	);
}

const size_bubble = 98;

class Track extends Component {
	constructor(props) {
		super(props);

		let path = window.location.href;
		let track_hash_id = null;

		if (path.includes('track')) {
			track_hash_id = this.props.url.params.track_hash_id;
		}

		// Call the function that gets similar songs
		this.get_similar_songs = this.get_similar_songs.bind(this);
		this.get_similar_songs(track_hash_id);

		/* Youtube Video: commented for now ====================
		// Call function to get other works from same artist
		this.toggle_video = this.toggle_video.bind(this);
		this.get_youtube_video = this.get_youtube_video.bind(this);
		this.get_artist_works = this.get_artist_works.bind(this);
		this.get_artist_works(track_hash_id);
		===================================================== */

		// If we open track page with the redirected parameter, we want to show the "Similar Songs" section
		let current_slide = 0;
		let track_info = this.props.track_info;
		if (path.includes('redirected')) {
			current_slide = 1;

			// Also, to avoid loading the same song twice, 
			// we just get the song info from redirected_bubble props saved at the moment user clicked on a bubble
			if (this.props.redirected_bubble) {
				track_info = this.props.redirected_bubble;
				store_set('track_info', this.props.redirected_bubble);
			}
		}

		let current_similar_songs = this.props.similar_songs.length === 0 ? this.props.queue : this.props.similar_songs;
		// For each item inside current_similar_songs, add a random size to it
		current_similar_songs = current_similar_songs.map((data) => {
			data.random_size_bubble = get_random_number(size_bubble - 40, size_bubble);
			return data;
		});
		this.video = null;
		this.state = {
			available_to_play: false,
			current_slide: current_slide,
			track_hash_id: track_hash_id,
			similar_songs: current_similar_songs,
			minimized: false,
			show_thumbnail: false,
			opacity: 1,
			top_tracks: [],
			artist_albums: [],
			previous_page: this.props.url.url,
		};

		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_text', 'Loading...');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);

		this.load_file = this.load_file.bind(this);
		this.handle_play_track = this.handle_play_track.bind(this);
		this.get_track_info = this.get_track_info.bind(this);
		this.find_similar_button = this.find_similar_button.bind(this);
		this.set_initial_config = this.set_initial_config.bind(this);

		if (track_hash_id !== null && track_hash_id !== undefined && track_hash_id !== '' && track_hash_id.length !== 0) {
			if (track_info.spotify_id !== track_hash_id) {
				this.get_track_info(track_hash_id);
			} else {
				this.load_file();
				store_set('loading_overlay_display', false);
			}
		}

		/* Youtube Video: commented for now ====================
		if (!this.props.track_info.youtube_id) {
		this.get_youtube_video();
		}
		====================================================== */

		this.random_size_bubble = get_random_number(size_bubble - 40, size_bubble);
	}

	set_initial_config(track_info) {

		this.video = null;

		const img = document.getElementById('track-main-image');

		img.onload = () => {
			img.classList.add('fade-in');
		};

		let track_hash_id = track_info.spotify_id;

		store_set('track_info', track_info);
		store_set('song_info', track_info);

		this.get_similar_songs(track_hash_id);

		this.setState({ available_to_play: false });
		this.setState({ track_hash_id: track_hash_id });

		if (track_hash_id !== null && track_hash_id !== undefined && track_hash_id !== '' && track_hash_id.length !== 0) {
			if (track_info.spotify_id !== track_hash_id) {
				this.get_track_info(track_hash_id);
			} else {
				this.load_file();
				store_set('loading_overlay_display', false);
			}
		}

		this.random_size_bubble = get_random_number(size_bubble - 40, size_bubble);

		img.classList.remove('fade-in');


	}

	load_file() {
		file.load(this.props.track_info, function () {
			if (this.props.track_info.preview_url) {
				this.setState({ available_to_play: true });
			}
		}.bind(this));
	}

	get_track_info(hash_id) {
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/song/',
			data: { hash_id: hash_id },

			success: function (data) {
				if (data.status === 'success') {
					store_set('track_info', data);
					store_set('song_info', data);
					this.load_file();
				}
			}.bind(this),
			complete: function () {
				store_set('loading_overlay_display', false);
			},
		});
	}

	handle_play_track() {
		const track = this.props.track_info;
		// Load recommendations if user interacts with track if it isn't seed
		if (this.props.seed.spotify_id !== this.props.track_info.spotify_id) {
			store_set('seed', track);
			queue.get_recommendations(track.spotify_id, track.type, '', false);
		}
		// Then, load player
		player.load(0, track);
	}

	/* Youtube Video: commented for now ====================
	get_youtube_video(){
	var track_info = this.props.track_info;
	//Make copy track_info
	let copy_track_info = JSON.parse(JSON.stringify(track_info));
	request_buffer.execute({
	type: 'get',
	url: constants.api_endpoint + '/video/',
	data: {
	track: track_info.title,
	artist: track_info.artist.name,
	},
	success: function(data){
	if (data.status === 'success') {
	if (copy_track_info['spotify_id'] === this.state.track_hash_id) {
	copy_track_info['youtube_id'] = data.youtube_id;
	store_set('track_info', copy_track_info);
	}
	}
	}.bind(this),
	complete: function(){},
	})
	}
	
	toggle_video() {
	if (this.video) {
	var state = this.video.getPlayerState();
	if (state === 1) {
	this.setState({show_thumbnail: true, opacity: 0});
	this.video.pauseVideo();
	} else if (state === 2 || state === 5) {
	this.video.playVideo();
	this.setState({show_thumbnail: false, opacity: 1});
	}
	}
	}
	======================================================== */

	find_similar_button(seed) {
		store_set('seed', seed);
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_top_text', 'Now searching similar to:');
		store_set('loading_overlay_text', seed.title);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
		queue.get_recommendations(seed.spotify_id, seed.type, '', true);
		store_set('active_overlay', false);
	}

	get_similar_songs(hash_id) {
		// Make sure to get fine tune parameters 
		// (For example level of popularity, or happyness, etc)
		// We use for premium users that want to customize their recommendations
		const fine_tunning = this.props.fine_tunning || {};

		const data = {};
		data.hash_id = hash_id;
		data.seed_type = 'song';
		data.fine_tunning = JSON.stringify(fine_tunning);

		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_recommendations,
			data: data,
			success: function (data) {
				if (data.status === 'success') {
					// For each item inside new_list, add a random size to it
					let current_similar_songs = [...data.data].map((data) => {
						data.random_size_bubble = get_random_number(size_bubble - 40, size_bubble);
						return data;
					});

					// From current_similar_songs remove all tracks that have repeated spotify_id
					let repeated_ids = [];
					let new_current_similar_songs = [];
					// eslint-disable-next-line no-unused-vars
					current_similar_songs.forEach((item, index) => {
						if (repeated_ids.includes(item.spotify_id)) return;
						new_current_similar_songs.push(item);
						repeated_ids.push(item.spotify_id);
					});
					current_similar_songs = new_current_similar_songs;

					// From current_similar_songs, remove all tracks that have repeated artist spotify_id (we don't want to suggest the same artist twice)
					let repeated_artists_ids = [];
					new_current_similar_songs = [];
					// eslint-disable-next-line no-unused-vars
					current_similar_songs.forEach((item, index) => {
						if (repeated_artists_ids.includes(item.artist.spotify_id)) return;
						new_current_similar_songs.push(item);
						repeated_artists_ids.push(item.artist.spotify_id);
					});
					current_similar_songs = new_current_similar_songs;

					this.setState({ similar_songs: current_similar_songs });
					store_set('similar_songs', current_similar_songs);

					/* Youtube Video: commented for now ====================
					if (!this.props.track_info.youtube_id) {
					this.setState({
					'artist_albums': [],
					'top_tracks': []
					});
					this.get_youtube_video();
					this.get_artist_works(hash_id);
					}
					================================================= */
				}
			}.bind(this)
		}, true);
	}

	/* Youtube Video: commented for now ====================
	get_artist_works(track_hash_id){
	request_buffer.execute({
	type: 'get',
	url: constants.api_endpoint_get_artist_works,
	data: { track_hash_id: track_hash_id },
	success: function(data){
	if (data.status === 'success') {
	this.setState({
	'artist_albums': data.artist_albums,
	'top_tracks': data.top_tracks
	});
	}
	}.bind(this),
	})
	}
	
	componentDidUpdate(prevProps) {
	if (!this.video) { return; }
	
	var state = this.video.getPlayerState();
	if(prevProps.is_playing !== this.props.is_playing){ // player change
	if (this.props.is_playing && state === 1) { // play song
	this.video.pauseVideo();
	}
	}
	}
	======================================================== */

	render() {
		const settings = {
			initialSlide: this.state.current_slide,
			dots: false,
			infinite: false,
			arrows: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipe: this.state.minimized ? false : true,
			afterChange: (index) => {
				this.setState({
					current_slide: index,
					opacity: index === 1 ? 0 : 1,
				});
			},
		};

		/* Youtube Video: commented for now ====================
		// Youtube Player config
		const opts = {
		height: window.innerHeight - 63,
		width: window.innerWidth,
		playerVars: {
		cc_load_policy: 0,
		controls: 0,
		showinfo: 0,
		rel: 0,
		modestbranding: 1,
		autohide: 0,
		origin: window.location.href,
		}
		}
		
		// Necessary to make transition smoother
		const video_styles = {
		width: this.state.minimized ? '100vw' : '300%',
		height: !this.state.minimized ? '100vh' : (window.innerWidth / (16 / 9)),
		left: this.state.minimized ? 0 : '-100%',
		opacity: this.state.minimized ? 1 : (this.state.opacity)
		}
		
		const thumbnail_styles = {
		width: '100vw',
		height: !this.state.minimized ? 'calc(100vh - 60px)' : (window.innerWidth / (16 / 9)),
		opacity: this.state.show_thumbnail ? 1 : 0,
		}
		====================================================================== */

		if (this.props.track_info.spotify_id !== this.state.track_hash_id) {
			return (
				<div></div>
			);
		} else {
			var track_is_playing = false;
			if (this.props.playing_song.spotify_id === this.props.track_info.spotify_id) {
				track_is_playing = true;
			}
			/* Youtube Video: commented for now ====================
			var youtube_video = false;
			if (this.props.track_info.youtube_id && this.props.track_info.youtube_id !== '') {
			youtube_video = this.props.track_info.youtube_id;
			}
			var can_minimize = false;
			if (youtube_video && this.state.artist_albums.length + this.state.top_tracks.length > 0) {
			can_minimize = true;
			}
			========================================================= */
			return (
				<React.Fragment>
					<div className='track-slider'>
						<img
							className={'track-main'}
							id='track-main-image'
							src={this.props.track_info.album.images.large}
							style={this.state.current_slide === 1 ? { filter: 'blur(8px)', transition: 'filter 1s' } : { transition: 'filter 1s' }}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = default_album_image;
							}}
							alt=''
						/>
						<div className='track-background-color' />
						<Slider {...settings}>
							<div className='track-container'>
								{this.state.available_to_play && // !youtube_video &&
									<div className='track-play-button-container'>
										<div
											className='track-play-button'
											onClick={track_is_playing ? () => player.toggle() : () => this.handle_play_track()}
										>
											{(!track_is_playing || (track_is_playing && !this.props.is_playing)) &&
												<span className='material-icons'>play_arrow</span>
											}
											{track_is_playing && this.props.is_playing &&
												<span className='material-icons'>pause</span>
											}
										</div>
									</div>
								}
								<div className='track-first-slide'>
									{/* Youtube Video: commented for now ====================
<div className='track-video-wrapper'>
{youtube_video &&
<React.Fragment>
<div className={'track-video-thumb' + (this.state.minimized ? '-small' : '')} onClick={youtube_video ? () => this.toggle_video() : () => {}}>
<img src={this.props.track_info.album.images.large} style={thumbnail_styles} alt={this.props.track_info.title} />
</div>
<YouTube
videoId={youtube_video}
id='track-player'
className={'track-youtube-player' + (this.state.minimized ? '-small' : '')}
style={video_styles}
title={this.props.track_info.title} 
opts={opts}
onReady={this.state.current_slide === 1 ? (e) => {this.video = e.target;} : (e) => {player.pause(); e.target.setVolume(50); e.target.playVideo(); this.video = e.target;}}
onPause={() =>  this.setState({show_thumbnail: true, opacity: 0})}
onPlay={() => {player.pause(); this.setState({show_thumbnail: false, opacity: 1})}}
onEnd={(e) => {e.target.stopVideo()}}
/>
</React.Fragment>
}
<Ripples>
<Haptic intesity='light'>
<div
className='track-options-fullscreen-button'
onClick={() => this.setState({'minimized': false})}
style={{left:'calc(100vw' + (this.state.minimized ? ' - 60px' : ' + 100vw + 100vw') + ')'}}

>
<span className='material-icons'>fullscreen</span>
</div>
</Haptic>
</Ripples>
{can_minimize &&
<div className='track-recs'>
<div className='track-recs-header'>
<div className='track-title'>{this.props.track_info.title}</div>
<div className='track-artist'>{this.props.track_info.artist.name}</div>
</div>
<div className='track-recs-body'>
{this.state.artist_albums.length > 0 &&
<React.Fragment>
<div className='track-recs-subtitle'>More by {this.props.track_info.artist.name}</div>
<div className='track-recs-albums'>
{this.state.artist_albums.map((album, index) => (
<Card data={album} type='big_card_album' displayLabel={true} key={index} bodyLink={'/album/' + album.spotify_id} />

))}
</div>
</React.Fragment>
}
{this.state.top_tracks.length > 0 &&
<React.Fragment>
<div className='track-recs-subtitle'>You may also like:</div>
<div className='track-recs-songs'>
{this.state.top_tracks.map((song, index) => (
<Link to={'/track/' + song.spotify_id} className='track-song-link'>
<Card data={song} type='small_card_song' displayLabel={true} key={index} onMoreClick={() => {store_set('song_info', song); store_set('active_overlay', 'track_options')}}/>
</Link>

))}
</div>
</React.Fragment>
}
</div>
</div>
}
</div>
*/}
									{//!this.state.minimized &&
										<div className='track-content' /*onClick={youtube_video ? () => this.toggle_video() : () => {}}*/>
											<div className='track-info'>
												<div className='track-title'>{this.props.track_info.title}</div>
												<Link to={'/artist/' + this.props.track_info.artist.spotify_id} className='home-track-title-link'>
													<div className='track-artist'>{this.props.track_info.artist.name}</div>
												</Link>
											</div>
											<div className='track-options'>
												<div className='track-options-double-button'>
													{//!youtube_video &&
														<React.Fragment>
															<Ripples>
																<Haptic intesity='light'>
																	<div className='track-options-item-left' onClick={() => { event_bus.emit('play_full_song'); }}>Play Full Song</div>
																</Haptic>
															</Ripples>
															<span className='track-options-middle-line' />
														</React.Fragment>
													}
													<Ripples>
														<Haptic intesity='light'>
															<div className='track-options-item-right' onClick={() => this.find_similar_button(this.props.track_info)}>Find Similar</div>
														</Haptic>
													</Ripples>
												</div>
												<div className='track-options-buttons'>
													<Ripples>
														<Haptic intesity='light'>
															<div className='track-options-share-button' onClick={() => { event_bus.emit('share_song'); }}>
																<span className='material-icons'>share</span>
															</div>
														</Haptic>
													</Ripples>
													{/* Youtube Video: commented for now ====================
<Ripples>
<Haptic intesity='light'>
<div className='track-options-share-button' onClick={() => {store_set('song_info', this.props.track_info); store_set('active_overlay', 'track_options')}}>
<span className='material-icons'>more_vert</span>
</div>
</Haptic>
</Ripples>
{//can_minimize &&
<Ripples>
<Haptic intesity='light'>
<div className='track-options-share-button'  onClick={() => this.setState({'minimized': true})}>
<span className='material-icons'>fullscreen_exit</span>
</div>
</Haptic>
</Ripples>
========================================================= */}
												</div>
											</div>
										</div>
									}
								</div>
							</div>
							<div className='track-similar-songs'>
								<div className='track-similar-songs-bubbles' id='track-similar-songs-bubbles'>

									<BubblesContainer {...this.props} similar_songs={this.state.similar_songs} set_initial_config={this.set_initial_config} track_is_playing={track_is_playing} />

								</div>
								<div className='track-similar-songs-info'>
									<div className='track-similar-songs-title'>Similar Songs</div>
									<div className='track-similar-songs-subtitle'><span>{this.props.track_info.title}</span> and related songs</div>
									<div className='track-similar-songs-description-title'>Choose a song and go on a discovering spree!</div>
									<div className='track-similar-songs-description-text'>
										Once you find a track you like, click the <span className='material-icons'>more_horiz</span> button to listen to the full song or find similar ones.
									</div>
								</div>
							</div>
						</Slider>
						{!this.state.minimized &&
							<div className='track-slider-dots'>
								<div className={this.state.current_slide === 0 ? 'track-slider-dot-details track-slider-active-dot' : 'track-slider-dot-details'}></div>
								<div className={this.state.current_slide === 1 ? 'track-slider-dot-details track-slider-active-dot' : 'track-slider-dot-details'}></div>
							</div>
						}
					</div>
				</React.Fragment>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		song_info: state.GlobalReducer.song_info,
		track_info: state.GlobalReducer.track_info,
		is_playing: state.GlobalReducer.is_playing,
		playing_song: state.GlobalReducer.playing_song,
		queue: state.GlobalReducer.queue,
		active_overlay: state.GlobalReducer.active_overlay,
		duration: state.GlobalReducer.duration,
		current_time: state.GlobalReducer.current_time,
		redirected_bubble: state.GlobalReducer.redirected_bubble,
		fine_tunning: state.GlobalReducer.fine_tunning,
		similar_songs: state.GlobalReducer.similar_songs,
		current_page: state.GlobalReducer.current_page,
		previous_page: state.GlobalReducer.previous_page,
		seed: state.GlobalReducer.seed,
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(Track));