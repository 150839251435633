import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS Imports
import './styles/RotateSentences.css';

class RotateSentences extends Component {
	constructor(props){
		super(props);
		this.text_index = 0;
		this.text_in_timeout = false;
		this.text_out_timeout = false;
		this.component_ref = React.createRef();
	}

	componentDidMount(){
		this.animate_text();
	}

	animate_text(){
		const text_interval = this.props.interval;
		const component = this.component_ref.current;
		if (!component){
			clearTimeout(this.text_in_timeout);
			clearTimeout(this.text_out_timeout);
			return;
		}

		const texts = component.children;
		for (var item of texts){
			item.classList.remove('text-in', 'text-out');
		}
		texts[this.text_index].classList.add('text-in');

		this.text_in_timeout = setTimeout(function(){
			if (this.text_index === texts.length - 1){
				this.text_index = 0;
			} else {
				this.text_index += 1;
			}
			this.animate_text();
		}.bind(this), text_interval);

		this.text_out_timeout = setTimeout(function(){
			texts[this.text_index].classList.add('text-out');
		}.bind(this), text_interval - text_interval/6);
	}

	render(){
		const className = this.props.className || '';
		const style = this.props.style || {};

		return(
			<div ref={this.component_ref} className={'rotate-sentences-component ' + className} style={style}>
				{this.props.options.map((item, index) => (
					<span key={index}>{item}</span>
				))}
			</div>
		);
	}
}

RotateSentences.propTypes = {
	style: PropTypes.any,
	className: PropTypes.string,
	options: PropTypes.array,
	interval: PropTypes.number
};

export default RotateSentences;