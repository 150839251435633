import React from 'react';

// Capacitor Imports
import { Capacitor } from '@capacitor/core';

import { FileSharer } from '@byteowls/capacitor-filesharer';
import * as html_to_image from 'html-to-image';

import { Button } from '@magroove/magroove-ui';
import CircularProgress from '@material-ui/core/CircularProgress';

// CSS Imports
import './styles/ShareImage.css';


export function get_shareable_my_obsession(setObsessionCard) {
	// Check for profile picture until it's loaded
	const obsession_card = document.getElementById('share-obsession-card');
	if (!obsession_card) {
		setTimeout(() => get_shareable_my_obsession((data_url) => setObsessionCard(data_url)), 100);
		return;
	}
	// Clone them and set ids for styling purposes
	const obsession_clone = obsession_card.cloneNode(true);
	obsession_clone.id = 'obsession_clone';
	obsession_clone.style['padding'] = '20px 0px';
	obsession_clone.style['width'] = '375px';
	obsession_clone.style['height'] = '667px';

	const clone_slot = document.getElementById('share-obssesion-card-hidden');
	clone_slot.appendChild(obsession_clone);
	
	return convert_html_to_data_url(obsession_clone, (data_url) => setObsessionCard(data_url));
}

export function convert_html_to_data_url (element, callback) {
	// This function converts a rendered html element into a data url asynchronously
	// And returns the data url via callback function

	// If there isn't any element, just return
	if (!element) return;

	// Convert the element to data urls asynchronously
	return (async () => {
		const data_url = await html_to_image.toPng(element, { cacheBust: true });
		callback(data_url);
		return data_url;
	})();
}

export function handle_share_my_obsession ({ data, fileName }) {
	return share_data_url(data, fileName);
}


export function share_data_url (data_url, filename = 'magroove') {
	// This function shares the data url to other apps if mobile, or downloads it if web
	if (!data_url) return;

	// Encode data to base64
	const base64Data = data_url.replace(/^data:image\/png;base64,/, '');

	// Share the image
	FileSharer.share({
		filename: filename + '.png',
		contentType: 'image/png',
		base64Data: base64Data,
	}).catch(error => console.error('File sharing failed', error.message));
}

const ShareImage = ({ element_data, onClose, onShareImage, only_share = false }) => {
	const is_web_platform = Capacitor.getPlatform() === 'web';
	return (
		<>
			{!only_share && <Button onClick={onClose}>Cancel</Button>}
			<Button
				onClick={onShareImage}
				main_color={only_share ? '#ec6b43' : '#2d2d2d'}
				secondary_color={only_share ? '#000000' : '#fff5f2'}
				style={only_share ? {margin: '10px 20px 0'} : {}} 
				className='share-or-save-button'
				value={
					!element_data ? (
						<CircularProgress style={only_share && {color: '#000'}} className={only_share && 'black-loading'} size={16} />
					) : is_web_platform ? (
						<><span style={only_share ? {color: '#000'} : {}} className='material-icons'>file_download</span> Save</>
					) : (
						<><span style={only_share ? {color: '#000'} : {}} className='material-icons'>share</span> Share</>
					)
				}
			/>
		</>
	);
};

export default ShareImage;
