import randomstring from 'randomstring';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';
import 'format-unicorn';
import moment from 'moment';

// Actions import
import { store_set, store_get, clear_all_overlays, execute_post_link_account_action } from '@actions/GlobalActions.js';

// Constant Imports
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Import the event bus
import event_bus from '@Eventbus';

import { redirect } from '@routes/Routes.js';

import app_analytics from '@Analytics';
import user from '@User';
import subscription from '@Subscription';


const openCapacitorSite = async (url) => {
	await Browser.open({ url: url });
};

const remove_local_suggestions = async () => {
	await Preferences.remove({ key: 'local_suggestions' });
};

function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

function add_to_platform_callback(size){
	// Check if the user still is in the export flow.
	// This is needed cause after 35 seconds, he can
	// cancels it.
	var overlay = store_get('active_overlay_option');
	if (overlay === 'show_playlists'){
		clear_all_overlays();
		display_snackbar('Sending song' + (size > 1 ? 's' : '') + ' to your playlist...');

		// Also check if the flow was in the Select Mode
		var select_mode = store_get('select_mode');
		if (select_mode){
			store_set('tracks_selected', []);
		}
	}

	// Ask user to review app on sucessful export
	event_bus.emit('review-app', null, 'sucessful_export');
}

function add_to_platform_error(dsp){
	const linked_accounts = store_get('linked_accounts');
	for (var item of linked_accounts){
		if (item.platform === dsp){
			item.state = 'disabled';
		}
	}
	store_set('linked_accounts', linked_accounts);
	store_set(dsp + '_playlists', []);
	store_set(dsp + '_playlists_date', false);
}

class ExportController{
	constructor() {
		this.state = {};

		this.display_loading_overlay = this.display_loading_overlay.bind(this);
		this.open_login_window = this.open_login_window.bind(this);
		this.close_login_window = this.close_login_window.bind(this);
		this.get_timeout = this.get_timeout.bind(this);
		this.clear_interval = this.clear_interval.bind(this);
	
		this.link_account = this.link_account.bind(this);
		this.unlink_account = this.unlink_account.bind(this);
		this.get_playlists = this.get_playlists.bind(this);
		this.add_to_spotify = this.add_to_spotify.bind(this);
		this.add_sync_to_spotify = this.add_sync_to_spotify.bind(this);
		this.add_to_deezer = this.add_to_deezer.bind(this);
		this.add_sync_to_deezer = this.add_sync_to_deezer.bind(this);
	}

	display_loading_overlay(message = 'Loading...'){
		store_set('loading_overlay_component', 'circular-progress');
		store_set('loading_overlay_top_text', false);
		store_set('loading_overlay_text', message);
		store_set('loading_overlay_action', []);
		store_set('loading_overlay_display', true);
	}

	close_login_window(){
		if (this.state.login_window) {
			try{
				Browser.close();
			}
			catch(e){
				// Error closing window
			}
			// eslint-disable-next-line
			this.state.login_window = false;
		}
	}

	clear_interval(){
		if (this.state.get_interval) {
			clearInterval(this.state.get_interval);
			// eslint-disable-next-line
			this.state.get_interval = false;
		}
	}
	
	get_timeout(){
		if (this.state.get_interval) {
			this.clear_interval();
			this.close_login_window();
			store_set('loading_overlay_display', false);
		}
	}

	open_login_window(type, redirect = '/linked-accounts/'){
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();

		var url;
		var token = user.check_for_token();
		switch(type) {
		case 'spotify':
			url = constants.api_endpoint_login_link_spotify.formatUnicorn({session_hash_id: this.state.session_hash, token: token});
			break;
			
		case 'deezer':
			url = constants.api_endpoint_login_link_deezer.formatUnicorn({session_hash_id: this.state.session_hash, token: token});
			break;

		default:
			return;
		}

		this.display_loading_overlay();
		if (Capacitor.getPlatform() === 'web'){
			store_set('link_account_loading', true);
			url = new URL(url);
			url.searchParams.set('redirect', redirect);
			window.location.href = url;
		} else {
			// eslint-disable-next-line
			this.state.login_window = openCapacitorSite(url);

			Browser.addListener('browserFinished', () => {
				this.clear_interval();
				clear_all_overlays();
				Browser.removeAllListeners();
			});

			// eslint-disable-next-line
			this.state.get_interval = setInterval(this.link_account, constants.login_token_check_interval)
			setTimeout(this.get_timeout, constants.login_token_timeout);
		}
	}

	link_account(){
		request_buffer.auth_execute({
			url: constants.api_endpoint_linked_account.formatUnicorn({session_hash_id: this.state.session_hash}),
			type: 'get',
			success: function(data) {
				if (data.status !== 'unknown') {
					this.clear_interval();
					this.close_login_window();
					store_set('loading_overlay_display', false);
					if (data.status === 'success'){
						store_set('linked_accounts', data.linked_accounts);
					}

					execute_post_link_account_action(data.status);
				}
			}.bind(this),
			error: function() {
				store_set('loading_overlay_display', false);
			}
		}, true);
	}

	unlink_account(type){
		this.display_loading_overlay();
		store_set('active_overlay', false);

		request_buffer.auth_execute({
			url: constants.api_endpoint_unlink_account,
			type: 'post',
			data: {platform: type},
			success: function(data) {
				if (data.status === 'success') {
					var linked_accounts = [...store_get('linked_accounts')];
					for(var i = 0; i < linked_accounts.length; i++) {
						if (linked_accounts[i].platform === type){
							linked_accounts[i].state = 'disabled';
						}
					}
					store_set('linked_accounts', linked_accounts);

					// If user has unsynced from both accounts, we show the option to sync
					// again in Search page along with recent seeds and clear suggestions saved locally
					if (!linked_accounts.some(account => account.state === 'active')) {
						store_set('user_synced', 'none');
						store_set('suggestions', []);
						remove_local_suggestions();
					} else {
						// If there's still one account synced, we update the suggestions
						user.get_suggestions();
					}
				}
			},
			complete: function() {
				store_set('loading_overlay_display', false);
			}
		}, true);
	}

	get_playlists(type){
		store_set('streaming_overlay', type);

		// First, check if the user already has the account linked. 
		const linked_accounts = store_get('linked_accounts');
		const linked = linked_accounts.find(item => item.platform === type && item.state === 'active');

		// If it doesn't, trigger the login flow.
		if (!linked){
			const current_page = store_get('current_page');
			const post_link_account_action = {
				streaming_overlay: type,
				redirect_overlay: 'loading_playlists'
			};
			store_set('post_link_account_action', post_link_account_action);
			this.open_login_window(type, current_page);
			return;
		}

		// If the store already have playlists saved, check its TTL of 6 hours to avoid redunant ajax calls
		let load_playlists = true;

		const playlists = store_get(type + '_playlists');
		const playlists_loaded_at = store_get(type + '_playlists_date');
		if (playlists.length > 0 && playlists_loaded_at){
			if (moment(playlists_loaded_at).isAfter(moment().subtract(6, 'hours'))){
				load_playlists = false;
			}
		}

		if (load_playlists){
			store_set('active_overlay_option', 'loading_playlists');

			// Ajax to get the playlists
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_get_playlists,
				data: {platform: type},
				success: function(data){
					if (data.status === 'success'){
						store_set(type + '_playlists', data.playlists);
						store_set('active_overlay_option', 'show_playlists');
						store_set(type + '_playlists_date', new Date());
					} else if (!this.state.login_window){
						const current_page = store_get('current_page');
						const post_link_account_action = {
							streaming_overlay: type,
							redirect_overlay: 'loading_playlists'
						};
						store_set('post_link_account_action', post_link_account_action);
						this.open_login_window(type, current_page);
					}
				}.bind(this),
				error: function(){
					const select_mode = store_get('select_mode');
					const overlay = select_mode ? 'export_select_mode' : 'export';
					store_set('active_overlay_option', overlay);
					display_snackbar('Sorry, we got an error...');
				},
				timeout: 30000
			});
		} else{
			store_set('active_overlay_option', 'show_playlists');
		}
	}

	add_to_spotify(songs, playlist){
		// Exporting should not block the experience - instead it should run in the background
		// Display a "success" message and in case occurs an error and the user should sync
		// again, the next time they try to export, the sync flow will be restarted.
		add_to_platform_callback(songs.length);

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'spotify_export',
			event_source_url: window.location.pathname,
			custom_params: {songs: songs.length},
			device_info: device_info,
			member_hash: user.hash_id
		}

		const songs_id = songs.map(item => item.spotify_id);
		request_buffer.auth_execute({
			type: 'POST',
			data: {
				songs_id: JSON.stringify(songs_id),
				playlist: JSON.stringify(playlist),
				event_details: JSON.stringify(event_details)
			},
			url: songs_id.length < 50 ? constants.api_endpoint_export_to_spotify : constants.api_endpoint_export_sync_to_spotify,
			success: function(data){
				if (data.status === 'success'){
					if (!subscription.is_valid()){
						app_analytics.user_event('export_to_spotify');
					}
				} else if (data.reason === 'export_failure'){
					return;
				} else if (!this.state.login_window){
					add_to_platform_error('spotify');
				}
			}.bind(this)
		});
	}

	add_sync_to_spotify(playlist){
		// Exporting should not block the experience - instead it should run in the background
		// Display a "success" message and in case occurs an error and the user should sync
		// again, the next time they try to export, the sync flow will be restarted.
		add_to_platform_callback(2);

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'spotify_export',
			event_source_url: window.location.pathname,
			custom_params: {songs:  store_get('likes').length},
			device_info: device_info,
			member_hash: user.hash_id
		}

		request_buffer.auth_execute({
			type: 'POST',
			data: {
				playlist: JSON.stringify(playlist),
				event_details: JSON.stringify(event_details)
			},
			url: constants.api_endpoint_export_sync_to_spotify,
			success: function(data){
				if (data.status === 'success'){
					if (!subscription.is_valid()){
						app_analytics.user_event('export_to_spotify');
					}
				} else if (data.reason === 'export_failure'){
					return;
				} else if (!this.state.login_window){
					add_to_platform_error('spotify');
				}
			}.bind(this)
		});
	}

	add_to_deezer(songs, playlist){
		// Exporting should not block the experience - instead it should run in the background
		// Display a "success" message and in case occurs an error and the user should sync
		// again, the next time they try to export, the sync flow will be restarted.
		add_to_platform_callback(songs.length);

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'deezer_export',
			event_source_url: window.location.pathname,
			custom_params: {songs: songs.length},
			device_info: device_info,
			member_hash: user.hash_id
		}

		const songs_isrc = songs.map(item => item.isrc);
		request_buffer.auth_execute({
			type: 'POST',
			data: {
				songs_isrc: JSON.stringify(songs_isrc),
				playlist: JSON.stringify(playlist),
				event_details: JSON.stringify(event_details)
			},
			url: constants.api_endpoint_export_to_deezer,
			success: function(data){
				if (data.status === 'success'){
					if (!subscription.is_valid()){
						app_analytics.user_event('export_to_deezer');
					}
				} else if (data.reason === 'export_failure'){
					return;
				} else if (!this.state.login_window){
					add_to_platform_error('deezer');
				} 
			}.bind(this)
		});
	}

	add_sync_to_deezer(playlist){
		// Exporting should not block the experience - instead it should run in the background
		// Display a "success" message and in case occurs an error and the user should sync
		// again, the next time they try to export, the sync flow will be restarted.
		add_to_platform_callback(2);

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'deezer_export',
			event_source_url: window.location.pathname,
			custom_params: {songs: store_get('likes').length},
			device_info: device_info,
			member_hash: user.hash_id
		}

		request_buffer.auth_execute({
			type: 'POST',
			data: {
				playlist: JSON.stringify(playlist),
				event_details: JSON.stringify(event_details)
			},
			url: constants.api_endpoint_export_sync_to_deezer,
			success: function(data){
				if (data.status === 'success'){
					if (!subscription.is_valid()){
						app_analytics.user_event('export_to_deezer');
					}
				} else if (data.reason === 'export_failure'){
					return;
				} else if (!this.state.login_window){
					add_to_platform_error('deezer');
				} 
			}.bind(this)
		});
	}

	new_playlist(type, name){
		this.display_loading_overlay('Now creating...');

		// Ajax to create a new playlist
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_create_playlist,
			data: {
				platform: type,
				name: name,
			},
			success: function(data){
				if (data.status === 'success'){
					var playlists = [data.data, ...store_get(type + '_playlists')];
					store_set(type + '_playlists', playlists);
					display_snackbar('Successfully created!');
					store_set('create_playlist', false);
				} else if (data.reason === 'creation_failure'){
					display_snackbar('Sorry, something went wrong...');
				} else if (!this.state.login_window){
					const current_page = store_get('current_page');
					const post_link_account_action = {
						streaming_overlay: type,
						redirect_overlay: 'show_playlists'
					};
					store_set('create_playlist', false);
					store_set('post_link_account_action', post_link_account_action);
					this.open_login_window(type, current_page);
				} 
			},
			error: function(){
				display_snackbar('Sorry, we got an error...');
			},
			complete: function(){
				store_set('loading_overlay_display', false);
			}
		});
	}

	auto_recommendation(veredict){
		store_set('secondary_overlay', false);

		//  In case the user doesn't have a linked account, redirect them to the link account flow
		// and only save the tag after the link succeeded.
		const accepted = veredict === 'Accepted';
		const linked_accounts = store_get('linked_accounts');
		if (accepted && linked_accounts.length === 0){
			const post_link_account_action = {
				action: 'execute',
				type: 'accept_auto_recommendation'
			};
			store_set('post_link_account_action', post_link_account_action);
			redirect('/linked-accounts/'); return;
		}

		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_auto_recommendation,
			data: { veredict },
			success: function(data){
				if (data.status === 'success'){
					let user_info = store_get('user');
					user_info.auto_recommendation = veredict;
					store_set('user', user_info);
				}
			}
		});
	}
}

const export_track = new ExportController();

export default export_track;