import React from 'react';
import { connect } from 'react-redux';
import App from './App';
import DesktopApp from './DesktopApp';
import { isMobile } from 'react-device-detect';

// Component imports
import Meta from '@views/Meta.js';
import VersionOverlay from '@views/VersionOverlay.js';
import SplashScreen from '@views/SplashScreen.js';

class PlatformController extends React.Component {
	render() {
		if (!isMobile){
			return <DesktopApp>{this.props.children}</DesktopApp>;
		}
		if (!this.props.ready){
			return <SplashScreen />;
		}

		// In case the update is required, prevent the other components
		// from being rendered and the ajaxes from being called.
		if (['force', 'force_base'].includes(this.props.version_overlay)){
			return(
				<div id='app-mobile'>
					<Meta />
					<VersionOverlay />
				</div>
			);
		}
		return (<App>{this.props.children}</App>);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		version_overlay: state.GlobalReducer.version_overlay
	};
}

export default connect(mapStateToProps)(PlatformController);