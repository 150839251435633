import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/MyPlaylists.css';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Constant Imports
import * as constants from '@constants';

// Import the request buffer
import { request_buffer } from '@/RequestBuffer.js';

// Components and Views Imports
import { redirect } from '@routes/Routes.js';

// Import controllers
import subscription from '@Subscription';

function capitalize(string) {
	if (string){
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return '';
}

function loading_overlay(display, top_text, main_text) {
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_text', main_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', display);
}

function snackbar_message(message) {
	var select_mode = store_get('select_mode');
	var overlay = select_mode ? 'export_select_mode' : 'export';
	store_set('active_overlay_option', overlay);
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

class MyPlaylists extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.get_synced_playlists = this.get_synced_playlists.bind(this);
		this.add_synced_playlist = this.add_synced_playlist.bind(this);
		this.remove_synced_playlist = this.remove_synced_playlist.bind(this);

		// Sync playlists is a premium feature.
		// Redirect to the previous page and display invitation for premium if the user is not premium yet.
		if (subscription.is_valid()){
			this.get_synced_playlists();
		}
		else {
			redirect('/sync-playlists?invprem=1');
		}
	}

	get_synced_playlists() {
		// We only call the ajax if the list in store is empty or undefined
		if (this.props.synced_playlists.length === 0 || !this.props.synced_playlists) {
			loading_overlay(true, 'Now fetching your', 'Synced playlists');
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_get_synced_playlists,
				data: {platform: this.props.chosen_dsp},
				success: function(data){
					store_set('synced_playlists', data.playlists);
				},
				error: function(){
					snackbar_message('Sorry, we got an error...');
				},
				complete: function() {
					loading_overlay(false);
				},
				timeout: 30000
			});
		}
	}

	add_synced_playlist(playlist_id) {
		loading_overlay(true, 'Syncing playlist','Loading');
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_add_synced_playlist,
			data: {platform: this.props.chosen_dsp, playlist_id: playlist_id},
			success: function(data){
				if (data.status === 'success'){
					// Deezer playlists are numbers, so we turn the id into string
					store_set('synced_playlists', [...this.props.synced_playlists, playlist_id.toString()]);
				}
			}.bind(this),
			error: function(){
				snackbar_message('Sorry, we got an error...');
			},
			complete: function() {
				loading_overlay(false);
			},
			timeout: 30000
		});
	}

	remove_synced_playlist(playlist_id) {
		loading_overlay(true, 'Unsyncing playlist','Loading');
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_remove_synced_playlist,
			data: {platform: this.props.chosen_dsp, playlist_id: playlist_id},
			success: function(data){
				if (data.status === 'success'){
					// Deezer playlists are numbers, so we turn the id into string
					const new_synced_list = this.props.synced_playlists.filter(playlist => playlist !== playlist_id.toString());
					store_set('synced_playlists', new_synced_list);
				} else {
					// If for some reason there's been an error, a snackbar message appears
					loading_overlay(false);
					snackbar_message(data.reason);
				}
			}.bind(this),
			error: function(){
				snackbar_message('Sorry, we got an error...');
			},
			complete: function() {
				loading_overlay(false);
			},
			timeout: 30000
		});
	}

	render() {
		const chosen_dsp_playlist = this.props.chosen_dsp === 'spotify' ? this.props.spotify_playlists : this.props.deezer_playlists;
		// Separating the playlists from chosen dsp into synced (first section) and unsynced (second section)
		// according to list of ids from data, ignoring "Liked Songs" playlist
		const synced_playlists = chosen_dsp_playlist.filter(playlist => this.props.synced_playlists.includes(playlist.id.toString()));
		const dsp_playlists = chosen_dsp_playlist.filter(playlist => !this.props.synced_playlists.includes(playlist.id.toString()) && playlist.id !== '0');
		return (
			<div className="my-playlists-main-container">
				<div className="my-playlists-content">
					<div className="my-playlists-content-title">Playlists syncing</div>
					{!synced_playlists.length > 0 ?
						<div className="my-playlists-content-description">You don&apos;t have any synced playlists yet.<br/>
						Click the <span className="material-icons">done</span> button to sync a playlist.</div>
						:
						synced_playlists.map((item, ix) => (
							<div className="my-playlists-card-container" key={ix}>
								<div className="my-playlists-card-content">
									<img className="my-playlists-card-image" src={item.image} alt="" />
									<div className="my-playlists-card-info">
										<div className="my-playlists-card-text">{item.title}</div>
										<div className="my-playlists-card-text">{item.total_tracks} songs</div>
									</div>
								</div>
								<span className="my-playlists-synced material-icons" onClick={()=> this.remove_synced_playlist(item.id)}>close</span>
							</div>
						))
					}
				</div>
				<div className="my-playlists-content">
					<div className="my-playlists-content-title">My {capitalize(this.props.chosen_dsp)} playlists</div>
					{dsp_playlists.length > 0 &&
						dsp_playlists.map((item, ix) => (
							<div className="my-playlists-card-container" key={ix}>
								<div className="my-playlists-card-content">
									<img className="my-playlists-card-image" src={item.image} alt="" />
									<div className="my-playlists-card-info">
										<div className="my-playlists-card-text">{item.title}</div>
										<div className="my-playlists-card-text">{item.total_tracks} songs</div>
									</div>
								</div>
								<span className="material-icons" onClick={()=> this.add_synced_playlist(item.id)}>{synced_playlists.length > 0 ? 'arrow_upward' : 'done'}</span>
							</div>
						))
					}
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		chosen_dsp: state.GlobalReducer.chosen_dsp,
		spotify_playlists: state.GlobalReducer.spotify_playlists,
		deezer_playlists: state.GlobalReducer.deezer_playlists,
		synced_playlists: state.GlobalReducer.synced_playlists
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(MyPlaylists));