import React, { Component } from 'react';

// CSS Imports
import './styles/Bubbles.css';

// Assets Imports
import * as assets from '../Assets.js';
const artists = [
	assets.artist_n8f8c3g9wmpg0wseqmncwwkphobh6kae,
	assets.artist_nk7644k93ogyusp2bgz1wtyhx9vcqocm,
	assets.artist_hdjzfvhp772l18xryphwhpvk2uske81x,
	assets.artist_ufidk7oj3gkgr45zku3q58lyc6kt7nko,
	assets.artist_79ldnnfdvo0ju10slhkaxlni132zvavf,
	assets.artist_jcqh8vcq27fsnituqhwqbsn2ixd6nokj,
	assets.artist_yrsqdzfi6okg1i0b8imo4fn8b14hze2e,
	assets.artist_fh97lkk7uiwywg99g6pxzj359v8z8foh,
	assets.artist_oh3067ooegmlwzrol2v9ndqtqt20o3q2,
	assets.artist_se5345322tmjgjiz5bonvusj9g36f2mo,
	assets.artist_l15pf4xz6w06ana1fjjehwhc1ac889v4,
	assets.artist_0r8fsg6u1pwf2z3bag662joft5c83akp,
	assets.artist_2u0dr24ns167r598awp3z0tyxbr23w69,
	assets.artist_w305bixgmk1yfwr5p7h4u22a5ohq89gp,
	assets.artist_3ezbmbc1441a89e4u7bqfcekxbapt86d,
	assets.artist_cdn282ufygl8sgbtj5gn0sc6bkn2fej5,
	assets.artist_tkc27h05q31tjxv94rngbb409bfbl2e1,
	assets.artist_ys5fo12bf26dg1fb5vfr7lghydk285pw,
	assets.artist_s6m9e8cinwbhrsaiv700w2nn30brer1v,
	assets.artist_pta46r3typ0vwx6aqp423mdd5v08d4ss,
	assets.artist_pclxuzn6cmnl43vdaf22dpk1ng7rvkfk,
	assets.artist_odtbotfr3a29xte8dw5zvxp37hq12v0b,
	assets.artist_d29y3p5h84onrjjokd31a8a12yiucjrs,
	assets.artist_ubxqfavztk1qfpil78jjbxo5892uhyht,
	assets.artist_187m9ndh4vhapdsyoq7z1c73qtnb9gev,
	assets.artist_qsuu2shh4bh707yidcz2qsrvtnikc0wx,
	assets.artist_98vyoi8atvxh8uxi0bjr70zle9f6gx2g,
	assets.artist_ktm9n7x85fogx9m7oihh0ntafbvml2qc,
	assets.artist_ik1spm6au1n4whwojoyefi5akkoedt1q,
	assets.artist_sugw6osbw4xtawm6n5nelnytc1zyrym7,
	assets.artist_xu7ca0l0moka59o17ftzbnkbibg3wkfl,
	assets.artist_6ca262x4w5v1gs6v63lo19s533qljh7m,
	assets.artist_9xa86dfvdmf4zncnzxtmzl8ho5foc4bh,
	assets.artist_cd5x13vu6fbn14ycn31i9s6mv6k1vc0j,
	assets.artist_01sgfe8uwuht2vgolfauzrbu51o2jjpd,
	assets.artist_xwtswj04j0gm7nfillca9mtnncg8uu01,
	assets.artist_tmd9toz55zzqvrv3nonkwyuya3nys5wn,
	assets.artist_510ttqeloejs0zk5vqahgp042oktiz6s,
	assets.artist_xz3xiq7bu3w1fuq1dhlcftgbgx9hjsyt,
	assets.artist_mn4v4pajvr1a7pusljgph195ojfxzkkc,
	assets.artist_rzk2ie3pt4ev84p9maen11p7w3v3eb55,
	assets.artist_872ycqxzclzcdsbizv4r00luqii0g9u9,
	assets.artist_1a79quv5esk8xwx2gms9j8b3erca342p,
	assets.artist_du1gzafynfkhlgwyz235ho6qsv8u3knn,
	assets.artist_73j2wqdp5sdz3zdjfvp105uuvfhtweob,
	assets.artist_6nueaxdjqqikdprslyzkov14qkknzx67,
	assets.artist_oulfht9bksaa0qer0aexmdrm5qxm747i,
	assets.artist_9jjmhvnf21vpwuobh94nkgolig71hue7,
	assets.artist_ylod31fplkq6qyoi5tqw5452yefdiqtt,
];

function shuffleArray(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}

export function setBubblesList(lines, animation){
	let artist_list = shuffleArray(artists);
	let interval = Math.floor(artist_list.length / lines);
	let lines_list = [];

	for (let i = 0; i < lines; i++){
		let list = artist_list.slice(i*interval, (i+1)*interval + (animation ? 0 : i%2));
		if (animation) list = list.concat(list, list, list, list);
		lines_list.push(list);
	}
	return lines_list;
}

class Bubbles extends Component {
	constructor(props){
		super(props);
		this.state = {
			lines: []
		};
		if (props.bubbles.length === 0) this.state.lines = setBubblesList(this.props.lines, this.props.animation);
		else this.state.lines = this.props.bubbles;
	}
	render(){
		return(
			<div className='bubbles'>
				{this.state.lines.map((sample, index) => {
					return (
						<div key={index} className='bubbles-content'>
							<div className={`bubbles-line ${this.props.animation ? (index % 2  ? ' bubbles-go-left' : ' bubbles-go-right'): ''}`}>
								{sample.map((item, index) => (
									<img
										key={index}
										src={item}
										alt='Artist'
										style={{
											width: this.props.size,
											height: this.props.size
										}}
									/>
								))}
							</div>
						</div>
					);
				})}

			</div>
		);
	}
}

// Specifies the default values for props:
Bubbles.defaultProps = {
	className: '',
	animation: false,
	size: 81,
	lines: 2, 
	bubbles: [],
};
export default Bubbles;