import React, { Component } from 'react';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// Components and Views Imports
import { CircularProgress } from '@material-ui/core';
import RotateSentences from '@components/RotateSentences.js';
import { redirect } from '@routes/Routes';

// Constants import
import * as constants from '@constants';

// Import Request Buffer
import { request_buffer } from '@RequestBuffer';

// CSS Imports
import './styles/TrackRecommendation.css';

class TrackRecommendation extends Component {
	constructor(props){
		super(props);
		this.get_user_recommendations();
	}

	get_user_recommendations(){
		request_buffer.auth_execute({
			type: 'post',
			url: constants.api_endpoint_user_recommendations,
			success: function(res){
				if (res.status === 'success'){
					if (res.data.length > 0){
						redirect('/track/' + res.data[0].spotify_id);
					}
				}
			}
		});
	}

	render() {
		return (
			<div className='track-recommendation'>
				<div className='track-recommendation-loading'>
					<CircularProgress size='50px'/>
					<RotateSentences
						interval={2000}
						options={[
							'Stimulating artificially intelligent ears…',
							'Polarizing sound waves…',
							'Loading first batch notes…',
							'Harmonizing with your taste…',
							'Calibrating personality matrix…',
							'Initializing groovy algorithm…',
							'Mapping influence attributes…',
							'Cherry-picking gems…',
							'Eliminating songs everyone knows…'
						]}
					/>
				</div>
			</div>
		);
	}
}

// Connect component to the store
export default error_boundary_hoc(TrackRecommendation);