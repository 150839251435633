import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'format-unicorn';
import randomstring from 'randomstring';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import Cookies from 'js-cookie';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';

// CSS Imports
import './styles/DesktopLogin.css';

// Assets Imports
import * as assets from '@Assets';

// Constants import
import * as constants from '@constants';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

class DesktopLogin extends Component {
	constructor(props){
		super(props);
		this.state = {
			session_hash: '',
			loading: false,
			snackbar_message: '',
			snackbar_open: false,
		};

		var url_string = window.location.href;
		var url = new URL(url_string);

		const aid = url.searchParams.get('aid');
		if (aid && aid.length >= 10) {
			Cookies.set('aid', aid);
		}

		var login_failed = url.searchParams.get('login-failed');
		var logging_in = store_get('logging_in');
		if (logging_in && login_failed){
			store_set('logging_in', false);
			this.state.snackbar_open = true;
			this.state.snackbar_message = 'Sorry, we got an error...';
		}

		this.open_login_window = this.open_login_window.bind(this);
	}

	open_login_window(type){
		this.setState({loading: true});
		// eslint-disable-next-line
		var url;
		// eslint-disable-next-line
		this.state.session_hash = randomstring.generate(32).toLowerCase();
		switch(type) {
		case 'facebook':
			url = constants.api_endpoint_login_facebook.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'google':
			url = constants.api_endpoint_login_google.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'spotify':
			url = constants.api_endpoint_login_spotify.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		case 'debug':
			url = constants.api_endpoint_login_debug.formatUnicorn({session_hash_id: this.state.session_hash});
			break;

		default:
			return;
		}

		this.setState({loading: true});
		const aid = Cookies.get('aid');
		store_set('logging_in', true);
		if (aid) {
			window.location.href = url + '?redirect=true&aid=' + aid;
		} else {
			window.location.href = url + '?redirect=true';
		}
	}

	render() {
		return (
			<div className='desktop-login-main'>
				<div className='desktop-login-container'>
					<div className='desktop-login-title'>
						Login<span>/Sign Up</span>
					</div>
					<div className='desktop-desktop-login-buttons'>
						<div
							className='desktop-login-button'
							style={{backgroundColor: '#3b5998'}}
							onClick={() => this.open_login_window('facebook')}
						>
							<img src={assets.login_button_facebook} alt='Facebook'/>
							<span className='desktop-login-button-text'>SIGN IN WITH FACEBOOK</span>
						</div>
						<div
							className='desktop-login-button'
							style={{backgroundColor: '#ffffff'}}
							onClick={() => this.open_login_window('google')}
						>
							<img src={assets.login_button_google} alt='Google'/>
							<span className='desktop-login-button-text' style={{color: '#757575'}}>SIGN IN WITH GOOGLE</span>
						</div>
						<div
							className='desktop-login-button'
							style={{backgroundColor: '#1db954'}}
							onClick={() => this.open_login_window('spotify')}
						>
							<img src={assets.login_button_spotify} alt='Spotify'/>
							<span className='desktop-login-button-text'>SIGN IN WITH SPOTIFY</span>
						</div>
						{constants.debug &&
							<div
								className='desktop-login-button'
								style={{backgroundColor: 'grey'}}
								onClick={() => this.open_login_window('debug')}
							>
								<img src={assets.login_button_debug} alt='Debug'/>
								<span className='desktop-login-button-text'>SIGN IN DEBUG TOOL</span>
							</div>
						}
					</div>
				</div>
				<div className='desktop-login-stock-image'></div>
				<Backdrop open={this.state.loading} style={{zIndex: 1}}>
					<CircularProgress />
				</Backdrop>
				<Snackbar
					open={this.state.snackbar_open}
					message={this.state.snackbar_message}
					autoHideDuration={5000}
					onClose={() => this.setState({snackbar_open: false})}
				/>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		logging_in: state.GlobalReducer.logging_in,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(DesktopLogin));