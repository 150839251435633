import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as constants from '@constants';
import { request_buffer } from '@/RequestBuffer.js';

// Import store actions
import { store_set, store_get } from '@/actions/GlobalActions.js';

// CSS Imports
import './styles/UserMessage.css';

// Import components
import Card from '@components/Card.js';
import { TextInput } from '@magroove/magroove-ui';

// Import functions
import { redirect } from '@routes/Routes';

function display_loading_overlay(text = 'Loading...', top_text = false){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

function add_new_reply_to_poll(data){
	const polls = [...store_get('polls')];
	const user = Object.assign({}, store_get('user'));

	// eslint-disable-next-line
	for (const item of polls){
		if (item.poll_id && data.poll_id && item.poll_id.toString() === data.poll_id.toString()){
			const new_reply = Object.assign({}, data);
			new_reply.user = {
				'hash_id': user.hash_id,
				'name': user.first_name + ' ' + user.last_name,
				'slug': user.slug,
				'picture': user.picture
			};
			item.replies = [new_reply, ...item.replies];
			store_set('polls', polls);
			break;
		}
	}
}

class UserMessage extends Component {
	constructor(props){
		super(props);
		this.state = {
			message: '',
			ajax_called: false,
		};

		this.handle_message_text = this.handle_message_text.bind(this);
		this.send_user_message = this.send_user_message.bind(this);

		store_set('send_user_message', this.send_user_message);
	}

	handle_message_text(message) {
		if (this.state.message.length > 130 && message.length > 130) { return; }
		this.setState({message});
	}

	send_user_message() {
		if (this.state.ajax_called) { return; }
		if (this.props.selected_search_items.length === 0) { return; }
		if (this.state.message.length >= 130) { return; }
		
		// The function is being called from a different component (footer)
		// so we make sure we're only calling the ajax once
		this.state.ajax_called = true; // eslint-disable-line
		this.setState({ajax_called: true});

		store_set('footer_progress_index', 0);
		store_set('footer_progress_length', 2);
		store_set('footer_progress_allow_next', false);
		store_set('is_footer_progress', false);

		display_loading_overlay('Sending...');

		const data = {};
		data.recommendation = JSON.stringify(this.props.selected_search_items);
		data.message = this.state.message;
		if (this.props.location === 'recommendation-search'){
			data.entity_type = 'member';
			data.profile_id = this.props.profile_info.member_id;
		} else {
			data.entity_type = 'poll';
			data.poll_id = this.props.poll_info.poll_id;
		}

		request_buffer.auth_execute({
			type: 'post',
			data: data,
			url: constants.api_endpoint_save_friend_recommendation,
			success: function(res){
				if (res.status !== 'success') return;
				display_snackbar('Success!');

				if (data.entity_type === 'poll'){
					add_new_reply_to_poll(res.saved_recommendation);
				}
				else {
					// We will save the recommendations the logged user made to others
					const my_recommendations = [...store_get('my_recommendations'), res.saved_recommendation];
					store_set('my_recommendations', my_recommendations);
				}
			},
			error: function(){
				display_snackbar('Something went wrong. Please, try again...');
			},
			complete: function(){
				store_set('selected_search_items', []);
				const page = store_get('recommendation_return_page');
				redirect(page);
				store_set('loading_overlay_display', false);
			}
		});
	}

	render(){
		const profile = this.props.profile_info;
		const data = this.props.selected_search_items.length > 0 ? this.props.selected_search_items[0] : false;
		const user_name = profile.slug ? ('@' + profile.slug) : profile.first_name;
		const pronouns = profile.pronouns === 'he/him' ? 'he' : (profile.pronouns === 'she/her' ? 'she' : 'they');

		const is_a_poll_recommendation = this.props.location === 'poll-recommendation-search';
		const label = is_a_poll_recommendation ? 'Send a message with your recommendation' : `Send a message to ${user_name} telling why ${pronouns} need${pronouns === 'they' ? '' : 's'} to hear '${(data.name || data.title)}'`;

		if (!data) return <div></div>;
		return(
			<div className='user-message-component'>
				<Card data={data} type={'small_card_' + data.type} displayLabel={true} onMoreClick={() => {store_set('song_info', data); store_set('active_overlay', 'track_options');}} />
				<TextInput
					theme='dark'
					label={label}
					value={this.state.message}
					placeholder='Type here your message...'
					onChange={(message) => this.handle_message_text(message)}
				/>
			</div>
		);
	}
}

UserMessage.propTypes = {
	profile: PropTypes.any,
	data: PropTypes.any,
};

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		user: state.GlobalReducer.user,
		poll_info: state.GlobalReducer.poll_info,
		profile_info: state.GlobalReducer.profile_info,
		selected_search_items: state.GlobalReducer.selected_search_items,
	};
}

export default connect(mapStateToProps)(UserMessage);