import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { App } from '@capacitor/app';
import 'typeface-montserrat';
import safeAreaInsets from 'safe-area-insets';
import { Capacitor } from '@capacitor/core';
import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';

// import the store created in the store.js file
import { store_persistor } from './store';

// Constants import
import notification_controller from './Notifications.js';

// Constants import
import * as constants from '@constants';

// CSS Imports
import './App.css';
import './assets/fonts/Montserrat.css';

// URLs Imports
import Routes, { redirect } from '@routes/Routes.js';

// Actions import
import { store_get, store_set, clear_all_overlays, update_undefined_props } from '@actions/GlobalActions.js';

// Import the controllers
import interaction from './Interaction.js';
import app_analytics from './Analytics.js';
import player from '@Player';
import storage from '@Storage';
import subscription from '@Subscription';
import ads from './Ads.js';
import event_bus from './Eventbus.js';

// Components and Views Imports
import Header from '@views/Header.js';
import MiniPlayer from '@views/MiniPlayer.js';
import Footer from '@views/Footer.js';
import Overlay from '@views/Overlay.js';
import VersionOverlay from '@views/VersionOverlay.js';
import SecondaryOverlay from '@views/SecondaryOverlay.js';
import PremiumOverlay from '@views/PremiumOverlay.js';
import LoadingOverlay from '@views/LoadingOverlay.js';
import Meta from '@views/Meta.js';
import Snackbar from '@components/Snackbar.js';
import BetaMessage from '@components/BetaMessage.js';
import BackButton from './components/BackButton';

import { request_buffer } from '@RequestBuffer';
import { CapPlayInstallReferrer } from 'cap-play-install-referrer';

// App Tracking Transparency imports
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { MuteSwitch } from 'capacitor-plugin-mute-switch';

// Import Preferences API
import { Preferences } from '@capacitor/preferences';

// Login Plugins
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';

const get_installation_hash = async () => {
	const token = await Preferences.get({ key: 'installation_hash_id' });
	return token.value;
};

const save_installation_hash = async (hash_id) => {
	// Save the hash on capacitor native store
	await Preferences.set({ key: 'installation_hash_id', value: hash_id });
};

const material_ui_theme = {
	typography: {
		fontFamily: [
			'Montserrat',
		].join(','),
	},
	palette: {
		primary: {
			main: '#ec6b43',
			light: '#ec6b43',
			dark: '#ec6b43',
			contrastText: '#FFFFFF',
		},
		primary1Color: '#ec6b43',
		primary2Color: '#ec6b43',
		primary3Color: '#ec6b43',
		accent1Color: '#ec6b43',
		accent2Color: '#ec6b43',
		accent3Color: '#ec6b43',
		textColor: '#101010',
	},
	appBar: {
		height: 50,
	},
	datePicker: {
		color: '#ec6b43',
		textColor: '#101010',
		calendarTextColor: '#101010',
		selectColor: '#ec6b43',
		selectTextColor: '#101010',
		calendarYearBackgroundColor: '#ffffff',
		headerColor: '#ec6b43',
	},
	raisedButton: {
		textColor: '#000000',
		primaryTextColor: '#000000'
	}
};
const theme = createMuiTheme(material_ui_theme);

class MagrooveApp extends Component {
	constructor(props){
		super(props);
		this.was_playing = false;

		// Check if store should be purged - for development purposes it can be useful
		if (constants.purge_store_on_reload) {
			store_persistor.pause();
			store_persistor.flush().then(() => { return store_persistor.purge(); });
		}

		// Get device info
		let device_info = {};
		try {
			let parser = new UAParser();
			let device_properties = parser.getResult();
			
			device_info = {
				platform: Capacitor.getPlatform() !== 'web' ? Capacitor.getPlatform() === 'android' ? 'a2' : 'i2' : '',
				package_name: Capacitor.getPlatform() !== 'web' ? Capacitor.getPlatform() === 'android' ? 'com.magroove.app' : 'com.magroove.magrooveapp' : '',
				os_version: device_properties.os.name + ' ' + device_properties.os.version,
				device_model: device_properties.device.model,
				locale: 'En_US',
				timezone: '',
				carrier: '',
				width: window.innerWidth,
				height: window.innerHeight,
				density: window.devicePixelRatio,
				cpu_cores: '',
				external_storage_size: '',
				external_storage_size_free_space: '',
				device_timezone: '',
			};
			store_set('device_info', device_info);
		} catch (e) {} // eslint-disable-line

		// Set some default states
		clear_all_overlays();
		update_undefined_props();
		
		// Add listeners to the hardware back button
		if (Capacitor.getPlatform() !== 'web'){
			// Listen for the hardware back button event.
			App.addListener('backButton', () => { 
				event_bus.emit('back-button-pressed');
			});
		}

		// Initialize the notification controller
		notification_controller.initialize();

		// Initialize analytics
		app_analytics.initialize();

		App.addListener('appUrlOpen', (urlOpen) => {
			const url = new URL(urlOpen.url);
			const page = url.href.replace(url.origin, '');
			redirect(page);
		});

		this.resize_observer = null;

		// Bind functions
		this.add_safe_area_listener = this.add_safe_area_listener.bind(this);
		this.add_state_change_listener = this.add_state_change_listener.bind(this);

		// Listener will check if user has returned to the app
		document.addEventListener('visibilitychange', this.check_app_visibility);
	}

	componentDidMount(){
		ads.initialize();
		subscription.initialize();
		this.initialize_native_login();
		this.trigger_app_events();
		this.add_safe_area_listener();
		this.add_state_change_listener();
		this.add_silent_mode_listener();
		this.display_tracking_transparency();
		this.invite_to_download_app();
		this.save_referrer();
		storage.clear_expired();

		// If first time mounting the app (fresh install),
		// we set an attribute to tells us listener exists
		const app = document.getElementById('app-mobile');
		app.setAttribute('check-store-listener', true);
	}

	check_app_visibility() {
		// In case user has reinstalled or returned to app after an update, we
		// must make sure the user's saved redux store is up to date with our default redux store
		// so we call the store action that checks and updates undefined props
		if (document.visibilityState === 'visible') {
			update_undefined_props();
		}
	}

	componentDidUpdate() {
		// Check if listener already exists (app update) and add it if not
		const app = document.getElementById('app-mobile');
		if (!app.getAttribute('check-store-listener')) {
			app.setAttribute('check-store-listener', true);
			document.addEventListener('visibilitychange', this.check_app_visibility);
		}
	}

	initialize_native_login(){
		GoogleAuth.initialize({
			clientId: '1038185873962-c1quuqtp7nv5ritvcgt2c05evbluju8s.apps.googleusercontent.com',
			scopes: ['profile', 'email'],
			grantOfflineAccess: true,
		});

		FacebookLogin.initialize({ appId: '1005273696715452' });
	}

	trigger_app_events(){
		// App Events ----------------------------------------------------------------------------------
		if (Capacitor.getPlatform() !== 'web'){
			app_analytics.log_event('open_app');
		}
		else {
			// Check if navigation started by clicking a link or entering the URL in the browser's address bar
			const perf_entries = performance.getEntriesByType('navigation');
			const navigate = perf_entries.find(item => item.type === 'navigate');
			if (navigate){
				app_analytics.log_event('open_web');
			}
		}
	}

	add_silent_mode_listener(){
		if (Capacitor.getPlatform() === 'ios'){
			MuteSwitch.isMute().then(response => store_set('mute_switch_on', response.value));
			MuteSwitch.addListener('onChange', response => {
				const location = store_get('location');
				store_set('mute_switch_on', response.isMute);
				if (response.isMute){
					player.pause();
				}
				else if (location === 'home'){
					player.play();
				}
			});
		}
	}

	add_safe_area_listener(){
		// Some pages use position fixed and there are some moments where we need to use different distances.
		// For example, in case the app is running on an iOS, some devices have a status bar in app and we need
		// to add an orange border. Also, if we need to show the Beta version message, we need to increase the
		// space. Here, we will set the safe area so the pages can be adapted.
		const beta_message = this.props.display_beta_message && window.innerWidth > 300 ? 50 : 0;
		const ios_sat = !Capacitor.getPlatform() === 'ios' || Number.isNaN(safeAreaInsets.top) || !safeAreaInsets.top ? 0 : safeAreaInsets.top;
		store_set('safe_area_top', ios_sat + beta_message);

		safeAreaInsets.onChange(function(style){
			const beta_message = this.props.display_beta_message && window.innerWidth > 300 ? 50 : 0;
			const ios_sat = !Capacitor.getPlatform() === 'ios' || Number.isNaN(style.top) || !style.top ? 0 : style.top;
			store_set('safe_area_top', ios_sat + beta_message);
		}.bind(this));
	}

	display_tracking_transparency(){
		// Call it after the component did mount because for some iOS versions it may not not pop up if you ask
		// it to pop up immediately at app start.
		if (Capacitor.getPlatform() === 'ios'){
			AppTrackingTransparency.getStatus().then((response) => {
				if (response.status === 'notDetermined'){
					AppTrackingTransparency.requestPermission().then(() => {
						// Do something after the response
					});
				}
			});
		}
	}

	invite_to_download_app(){
		if (Capacitor.getPlatform() === 'web'){
			const already_invited = Cookies.get('invite_magroove_app');
			if (!already_invited){
				store_set('secondary_overlay', 'download_app');

				// Create a cookie that expires 1 day from now
				Cookies.set('invite_magroove_app', 'true', { expires: 1 });
			}
		}
	}

	add_state_change_listener(){
		// Autopause player if users move app to the background and display invitation overlay when he comes back
		App.addListener('appStateChange', ({ isActive }) => {
			const member_is_premium = subscription.is_valid();
			if (!member_is_premium && isActive && this.was_playing){
				// App went to foreground
				const action = 'app_foreground_while_playing';
				subscription.invite('Upgrade to premium to listen non stop with our app in the background', 'Discover much more and get extra control over your songs!', action);
				this.was_playing = null;
			}
			else if (!member_is_premium && !isActive){
				// App went to background
				this.was_playing = store_get('is_playing');
				player.pause();
			}
		});
	}

	save_referrer(){
		if (Capacitor.getPlatform() !== 'web'){
			get_installation_hash().then((hash_id) => {
				if (hash_id) return;
				CapPlayInstallReferrer.GetReferrer().then((resp) => {
					try {
						const res = JSON.stringify(resp);
						if (res === 'Not referred') return;
						request_buffer.execute({
							type: 'post',
							data: { referrer: res },
							url: constants.api_endpoint_save_referrer,
							success: function(data) {
								save_installation_hash(data.hash_id);
							}
						}, true);
					}
					catch (err) {
						// Deal with error
					}
				});
			});
		}
	}

	render() {
		return (
			<Router>
				<MuiThemeProvider theme={theme}>
					<div id='app-mobile' className='main-content' onClick={() => interaction.stop_countdown()}>
						<Meta />
						<BetaMessage />
						<Header />
						<Routes />
						<MiniPlayer />
						<Footer />
						<Overlay />
						<SecondaryOverlay />
						<PremiumOverlay />
						<LoadingOverlay />
						<VersionOverlay />
						<Snackbar />
						<BackButton />
					</div>
				</MuiThemeProvider>
			</Router>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user_token: state.GlobalReducer.user_token,
		display_beta_message: state.GlobalReducer.display_beta_message,
		version: state.GlobalReducer.version
	};
}

// Connect component to the store
export default connect(mapStateToProps)(MagrooveApp);