import React, { Component } from 'react';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Assets Imports
import './styles/PremiumOverlayHide.css';

// Import the event bus
import event_bus from '@Eventbus';

// Badge Animation
import Lottie from 'react-lottie-player';

// Constant Imports
import badge_sparkle_animation from '../assets/badge_sparkle_animation.json';

export default class PremiumOverlayHide extends Component{
	render(){
		return(
			<div className='premium-overlay-hide'>
				<div className='premium-overlay-hide-container'>
					<div className='premium-overlay-hide-badge'>
						<Lottie
							loop={true}
							play={true}
							animationData={badge_sparkle_animation}
							direction={1}
						/>
					</div>    
					<div className='premium-overlay-hide-content'>
						<p className='premium-overlay-hide-title'>This feature requires a <br></br>Premium Membership</p>
						<p  className='premium-overlay-hide-subtitle'>Magroove Premium is here to help you discover much more!</p>
						{this.props.icon && this.props.title &&
							<div className='premium-overlay-hide-benefit'>
								<span className='material-icons'>{this.props.icon}</span>
								<p className='premium-overlay-hide-benefit-title'>Subscribing now  grants you <b>{this.props.title}</b></p>
							</div>
						}
						<div className='premium-overlay-button' onClick={() => {store_set('secondary_overlay', false); event_bus.emit('display-plans');}}>
							SUBSCRIBE NOW FOR $1/mo
						</div>
					</div>
				</div>
			</div>
		);
	}
}