import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Button } from '@magroove/magroove-ui';
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// CSS Imports
import './styles/UploadImage.css';

// Assets Imports
import { edit_black_icon, range_dashes } from '@Assets';

import s3 from '../S3.js';

class UploadImage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			image: '',
			position: { x: 0.5, y: 0.5 },
			scale: 1,
			rotate: 0,
			borderRadius: 0,
			width: 200,
			height: 200,
			display_buttons: false,
			photo_dropzone: false,
			snackbar_status: false,
			snackbar_message: '',
			tabIndex: 0,
			range_zoom_dashes: [ ...Array(31).keys() ].map( i => i*0.1),
			range_rotate_dashes: [ ...Array(75).keys() ].map( i => i*5),
		};

		if (this.props.type === 'header'){
			this.state.width = window.innerWidth;
			this.state.height = Math.ceil(197 * window.innerWidth/500);
		}
		else if (this.props.type === 'poll'){
			this.state.width = window.innerWidth - 30;
			this.state.height = Math.ceil(245 * (window.innerWidth - 30)/345);
		}

		this.close = this.close.bind(this);
		this.change_tab_index = this.change_tab_index.bind(this);
		this.handle_confirm = this.handle_confirm.bind(this);
	}

	close(){
		this.setState({photo_dropzone: false});
		if (this.props.onClose){
			this.props.onClose();
		}
	}

	handle_drop = (dropped) => {
		if (dropped[0].type === 'image/jpeg' || dropped[0].type === 'image/jpg' || dropped[0].type === 'image/png' || dropped[0].type === 'image/bmp') {
			this.setState({ image: dropped[0] });
			this.setState({photo_dropzone: true});
			if (this.props.onEdit){
				this.props.onEdit();
			}
		} else {
			this.setState({snackbar_message: 'Invalid Format'});
			this.setState({snackbar_status: true});
		}
	};

	// This function allows user to zoom images
	handle_scale = () => {
		// Get the dashes section element
		let dashes_area = document.getElementById('upload-image-dialog-options-edit-zoom');
		// Get the app parent div (this element will be useful to know the correct app width)
		let total_mobile_width = document.getElementsByClassName('main-content');
		// Calculate the total scroll width area
		let total_scroll_width = dashes_area.scrollWidth - total_mobile_width[0].offsetWidth;

		// Do calculation to get each dash area so we know when we should zoom in or zoom out
		// Observation: We create 31 different scales, but we only allow user to zoom out at minimum on scale 1 forward
		let one_dash_area = total_scroll_width / 31;

		// Store inside a list the width from each dash area available
		let count = 0;
		let dashes_list = this.state.range_zoom_dashes.map(() => {
			count++;
			return one_dash_area*count;
		});

		// This is the minimum scale we allow user when trying to zoom out
		let scale = 1;

		// Let's keep control of scroll current position. We do calculation because we want the current position to start at scale 1
		let current_scroll_position = (dashes_area.scrollLeft + dashes_list[10]);

		// Here we guarantee scale minimum: 1
		if(current_scroll_position < dashes_list[10]){
			scale = 1;
		} else {
			// Here we find which dash area user is above currently
			let current_dash_area = dashes_list.filter((element) => parseFloat(current_scroll_position) > element && parseFloat(current_scroll_position) <= dashes_list[dashes_list.indexOf(element) + 1]);
			/* eslint-disable-next-line */
			for(let dash_item of dashes_list){
				let get_index_position;
				// If user is above the first dash area, just set zoom to be from the first scale available
				if (current_dash_area[0] < one_dash_area){
					get_index_position = 0;
					scale = this.state.range_zoom_dashes[get_index_position + 1];
				} else if (current_dash_area[0] >= dash_item && current_dash_area[0] < dashes_list[dashes_list.indexOf(dash_item) + 1]){
					get_index_position = dashes_list.indexOf(dash_item);
					scale = this.state.range_zoom_dashes[get_index_position + 1];
				}
				// If user is above the last dash area, just set zoom to be from the last scale available
				else if (current_scroll_position > dashes_list[dashes_list.length - 1]){
					scale = this.state.range_zoom_dashes[this.state.range_zoom_dashes.length - 1];
				}
			}
		}
		this.setState({ scale });
	};

	// This fucntion allow users to rotate images
	rotateScale = () => {
		// Get the dashes section element
		let dashes_area = document.getElementById('upload-image-dialog-options-edit-rotate');
		// Get the app parent div (this element will be useful to know the correct app width)
		let total_mobile_width = document.getElementsByClassName('main-content');
		// Calculate the total scroll width area
		let total_scroll_width = dashes_area.scrollWidth - total_mobile_width[0].offsetWidth;

		// We'll allow user to rotate maximum of 360º degrees, 
		// so we need to get the unit area based on total_scroll_width to calculate exactly the correct scale for each degree
		let unit_area = 360 / total_scroll_width;
		let scale = unit_area;
		// Deafult is to start at 0º degree, so we just increase with the minimum unit area value
		if(this.state.rotate !== 0){
			scale = dashes_area.scrollLeft * unit_area;
		}
		// For the main degrees values (90º, 180º, 270º and 360º) we just set a snap to facilitate user when he's close to those degrees
		if(scale > 89 && scale < 91){
			dashes_area.scrollLeft = 1297;
			this.setState({rotate: 90});
			return;
		} else if(scale > 179 && scale < 181){
			dashes_area.scrollLeft = 2593;
			this.setState({rotate: 180});
			return;
		} else if(scale > 269 && scale < 271){
			dashes_area.scrollLeft = 3889;
			this.setState({rotate: 270});
			return;
		} else if(scale > 359){
			dashes_area.scrollLeft = 5185;
			this.setState({rotate: 360});
			return;
		} else{
			this.setState({rotate: scale});
		} 
	};

	logCallback(e) {
		// eslint-disable-next-line
		if(e === 'onLoadSuccess'){
			this.setState({display_buttons: true});
		}
	}

	setEditorRef = editor => {
		if (editor) {
			this.editor = editor;
		}
	};

	handle_position_change = position => {
		this.setState({ position });
	};

	handle_confirm = () => {
		const canvas = this.editor.getImage().toDataURL();
		fetch(canvas)
			.then(res => res.blob())
			.then(blob => {
				blob['name'] = this.state.image.name;
				s3.upload_image(blob, this.props.type, this.props.prefix).then(res => {
					if (res.status === 'success'){
						this.props.onSuccess(res.data);
					}
				});
				this.close();
			});
	};

	change_tab_index(_, tabIndex) {
		this.setState({ tabIndex });
	}

	render() {
		const { tabIndex } = this.state;
		const { type, width, height, show_icon, show_button, show_dropzone, show_text, overlay } = this.props;
		/* UploadImage props:
			type: 'profile', 'header' (pencil icon) or 'playlist' (dropzone with add_photo icon),
			width: for playlist type,
			height: for playlist type,
			show_icon: shows pencil icon if playlist type
		*/
		return (
			<React.Fragment>
				{!this.state.photo_dropzone ?
					<div className='upload-image-dropzone-container'>
						<Dropzone
							onDrop={this.handle_drop}
							noKeyboard
							style={{ width: '250px', height: '250px' }}
						>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									{show_icon ?
										<div className='upload-image-change'>
											<img src={edit_black_icon} alt='edit-black-icon'/>
											<input {...getInputProps()} />
										</div>
										: show_dropzone ?
											<div className='upload-image-upload-dropzone' style={width && height ? {'width': width, 'height': height} : {}}>
												<span className='material-icons-sharp'>add_photo_alternate</span>
												{this.props.text &&
													<div className='upload-image-upload-dropzone-text'>{this.props.text}</div>
												}
												<input {...getInputProps()} />
											</div>
											: show_button ?
												<div className='upload-image-button'>
													<span>{this.props.text}</span>
													<input {...getInputProps()} />
												</div>
												: show_text ?
													<div id='upload-image-ref' className='upload-image-text'>
														<span>{this.props.text}</span>
														<input {...getInputProps()} />
													</div>
													:
													<div id='upload-image-ref' style={{display: 'none'}}>
														<input {...getInputProps()} />
													</div>
									}
								</div>
							)}
						</Dropzone>
						<Snackbar
							className='edit-desktop-container-snackbar'
							open={this.state.snackbar_status}
							message={this.state.snackbar_message}
							autoHideDuration={2000}
							onClose={() => this.setState({snackbar_status: false})}
						/>
					</div>
					:
					<div className={'upload-image-dialog-main-container' + (overlay ? ' upload-image-dialog-main-container-overlay' : '')}>
						<div
							open={this.state.photo_dropzone}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
							className='upload-image-dialog-container-component'
						>
							<div className='upload-image-dialog-background'>
								<div className='upload-image-dialog-container'>
									{overlay &&
										<header className='upload-image-header'>
											<div className='upload-image-header-box'>
												<div className='upload-image-header-left'>
													<span className='material-icons' onClick={this.close}>keyboard_backspace</span>
												</div>
												{(type === 'profile' || type === 'header') &&
													<div className='upload-image-header-center'>Edit Profile</div>
												}
											</div>
										</header>
									}
									<div className='upload-image-dialog-avatar'>
										{this.state.image &&
											<React.Fragment>
												<AvatarEditor
													ref={this.setEditorRef}
													scale={parseFloat(this.state.scale)}
													width={this.state.width}
													height={this.state.height}
													position={this.state.position}
													onPositionChange={this.handle_position_change}
													rotate={parseFloat(this.state.rotate)}
													borderRadius={type === 'profile' ? 100 : 0}
													onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
													onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
													onImageReady={this.logCallback.bind(this, 'onImageReady')}
													image={this.state.image}
													style={{'width': '100%', 'height': '100%'}}
												/>
											</React.Fragment>
										}
									</div>
									{this.state.display_buttons &&
										<div className='upload-image-dialog-options'>
											<div className='upload-image-dialog-options-tab'>
												<Tabs
													value={tabIndex}
													onChange={this.change_tab_index}
													scrollButtons='auto'
													TabIndicatorProps={{style: {background:'#ec6b43'}}}
												>
													<Tab label='Zoom' />
													{type !== 'header' &&
														<Tab label='Rotate' />
													}
												</Tabs>
											</div>
											<div className='upload-image-dialog-options-edit'>
												{this.state.tabIndex === 0 &&
													<div className='upload-image-dialog-options-edit-zoom-container'>
														<div className="upload-image-dialog-options-edit-zoom" onScroll={this.handle_scale} id="upload-image-dialog-options-edit-zoom">
															{this.state.range_zoom_dashes.slice(21,-1).map((_, ix) => <img src={range_dashes} alt='' key={ix}/> )}
														</div>
														<div className='upload-image-dialog-options-edit-zoom-indicator'></div>
													</div>
												}
												{this.state.tabIndex === 1 &&
													<div className='upload-image-dialog-options-edit-rotate-container'>
														<div className='upload-image-dialog-options-edit-rotate' onScroll={this.rotateScale} id='upload-image-dialog-options-edit-rotate'>
															{this.state.range_rotate_dashes.map((degrees, index) => {
																return(
																	<div className='upload-image-dialog-options-edit-rotate-degrees' key={index}>
																		<div className='upload-image-dialog-options-edit-rotate-degrees-text'>
																			{degrees === 0 && '0º'}
																			{degrees === 15 && '15º'}
																			{degrees === 30 && '30º'}
																			{degrees === 45 && '45º'}
																			{degrees === 60 && '60º'}
																			{degrees === 75 && '75º'}
																			{degrees === 90 && '90º'}
																			{degrees === 105 && '105º'}
																			{degrees === 120 && '120º'}
																			{degrees === 135 && '135º'}
																			{degrees === 150 && '150º'}
																			{degrees === 165 && '165º'}
																			{degrees === 180 && '180º'}
																			{degrees === 195 && '195º'}
																			{degrees === 210 && '210º'}
																			{degrees === 225 && '225º'}
																			{degrees === 240 && '240º'}
																			{degrees === 255 && '255º'}
																			{degrees === 270 && '270º'}
																			{degrees === 285 && '285º'}
																			{degrees === 300 && '300º'}
																			{degrees === 315 && '315º'}
																			{degrees === 330 && '330º'}
																			{degrees === 345 && '345º'}
																			{degrees === 360 && '360º'}
																		</div>
																		{/* eslint-disable-next-line */}
																		<img src={range_dashes} alt=''/>
																	</div>
																);
															})}
														</div>
														<div className='upload-image-dialog-options-edit-rotate-indicator'></div>
													</div>
												}
											</div>
										</div>
									}
								</div>
								<div className='upload-image-dialog-button'>
									<Button 
										fullWidth={true} 
										value='SAVE' 
										onClick={this.handle_confirm} 
										className='profile-desktop-photo-dialog-button-details' 
										variant='contained' 
										size='small'
									/>
									<Button 
										fullWidth={true} 
										value='CANCEL' 
										onClick={this.close} 
										className='profile-desktop-photo-dialog-button-details' 
										size='small'
										main_color='#101010' 
										secondary_color='#ec6b43' 
									/>
								</div>
							</div>
						</div>
					</div>
				}
				<Snackbar
					className='edit-desktop-container-snackbar'
					open={this.state.snackbar_status}
					message={this.state.snackbar_message}
					autoHideDuration={2000}
					onClose={() => this.setState({snackbar_status: false})}
				/>
			</React.Fragment>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		get_image: state.GlobalReducer.get_image
	};
}

// Connect component to the store
export default connect(mapStateToProps)(UploadImage);
