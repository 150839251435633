import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import Slider from '@components/Slider.js';
import ClickNHold from 'react-click-n-hold';
import { redirect } from '@routes/Routes.js';

// CSS Imports
import './styles/MyList.css';
import '@/assets/fonts/Montserrat.css';

// Import the controllers
import list from '@List';
import subscription from '@Subscription';

// Components and Views Imports
import Ripples from 'react-ripples';
import Bubbles from '@components/Bubbles.js';
import Card from '@components/Card.js';
import AdUnit from '@components/AdUnit.js';
import CircularProgress from '@material-ui/core/CircularProgress';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

// Assets Imports
import * as assets from '@Assets';

class CardWrapped extends Component {
	render(){
		const { song_id, card_height, card_id, card_type, click_n_hold, is_new_like, track, slider_disabled, handle_song_click } = this.props;
		return (
			<Slider
				key={song_id}
				song_id={song_id}
				backgroundColor='#ec6b43'
				icon='delete'
				text='Remove'
				height={card_height}
				marginBottom={15}
				action={() => list.remove(track, 1000)}
				disabled={slider_disabled}
				id={card_id}
			>
				<ClickNHold 
					time={0.9} // Time to keep pressing
					onClickNHold={(e) => click_n_hold(e, track)}
					className='my-list-card-click-n-hold'
				>
					<Card
						data={track}
						type={card_type}
						is_new_like={is_new_like}
						onClick={(e) => handle_song_click(e, track)}
						onMoreClick={() => {
							store_set('active_overlay', 'track_options');
							store_set('song_info', track);
						}}
					/>
				</ClickNHold>
			</Slider>
		);
	}
}

class MyList extends Component {
	constructor(props){
		super(props);

		// Everytime user enter my-list page, we only want to display the first 30 tracks
		let default_list_index = 30;

		this.state = {
			likes_list_index: default_list_index,
			likes_list: this.props.likes.slice(0,default_list_index),
		};
		this.belong_to_period = this.belong_to_period.bind(this);
		this.handle_song_click = this.handle_song_click.bind(this);
		this.click_n_hold = this.click_n_hold.bind(this);
		this.expand_likes_list = this.expand_likes_list.bind(this);
		this.create_new_playlist = this.create_new_playlist.bind(this);

		store_set('error_loading_older_likes', false);
		store_set('new_likes', false);

		event_bus.on('expand-likes-list', this.expand_likes_list);
		event_bus.on('update-likes-list', () => {
			const new_index = this.state.likes_list_index - 1;
			this.setState({likes_list: this.props.likes.slice(0, new_index), likes_list_index: new_index});
		});
	}

	belong_to_period(all_likes_list){
		let tracks_last_24_hours = [];
		let tracks_one_day_ago = [];
		let tracks_last_seven_days = [];
		let tracks_last_thirty_days = [];
		let tracks_more_than_thirty_days = [];

		// eslint-disable-next-line
		for (let track of all_likes_list){
			const track_date = new Date(track.created_at);
			track_date.setHours(0,0,0,0);

			const current_date = new Date();
			current_date.setHours(0,0,0,0);

			const one_day_ago = new Date();
			one_day_ago.setDate(current_date.getDate() - 1);
			one_day_ago.setHours(0,0,0,0);

			const last_seven_days = new Date();
			last_seven_days.setDate(current_date.getDate() - 7);
			last_seven_days.setHours(0,0,0,0);

			const last_thirty_days = new Date();
			last_thirty_days.setDate(current_date.getDate() - 30);
			last_thirty_days.setHours(0,0,0,0);

			if (track_date.toDateString() === current_date.toDateString()) {
				track.period = 'last_24_hours';
				tracks_last_24_hours.push(track);
			} else if (track_date.toDateString() === one_day_ago.toDateString()) {
				track.period = 'one_day_ago';
				tracks_one_day_ago.push(track);
			} else if ((track_date < one_day_ago) && (track_date > last_seven_days)) {
				track.period = 'last_seven_days';
				tracks_last_seven_days.push(track);
			} else if ((track_date <= last_seven_days) && (track_date >= last_thirty_days)) {
				track.period = 'last_thirty_days';
				tracks_last_thirty_days.push(track);
			} else {
				track.period = 'more_than_thirty_days';
				tracks_more_than_thirty_days.push(track);
			}
		}
		return [tracks_last_24_hours, tracks_one_day_ago, tracks_last_seven_days, tracks_last_thirty_days, tracks_more_than_thirty_days];
	}

	handle_song_click(e, song){
		if (this.props.select_mode) {
			var tracks = [...this.props.tracks_selected];
			var selected = tracks.filter(track => track.spotify_id === song.spotify_id).length > 0;
			if (selected) {
				tracks = tracks.filter(track => track.spotify_id !== song.spotify_id);
			} else {
				tracks = [...tracks, song];
			}
			store_set('tracks_selected', tracks);
		} else {
			store_set('loading_overlay_component', 'circular-progress');
			store_set('loading_overlay_top_text', false);
			store_set('loading_overlay_text', 'Loading...');
			store_set('loading_overlay_action', []);
			store_set('loading_overlay_display', true);
		}
	}

	click_n_hold(e, song){
		// Here we check if the card isn't on the Slide Mode to be removed. In this case, we don't want to
		// trigger the Select Mode when pressing the card.
		var element = document.getElementById(song.spotify_id);
		var left = element.getBoundingClientRect().left;

		if (this.props.click_and_hold_event && (this.props.my_list_display === 'grid_view' || left === 15)){ // 15 is the initial position of the card
			// Click and hold of any music card should enter the select mode with that card already selected
			store_set('select_mode', true);
			this.handle_song_click(e, song);
		}
	}

	componentDidMount(){
		var element = document.querySelector('.main-content-inner > div');

		// If mylist-main-container is not inside element, we do a settimeout to try again.
		// This is important to allow user to load more tracks when he scrolls down
		if (!element.querySelector('.mylist-main-container')){
			setTimeout(function() {
				this.componentDidMount();
			}.bind(this), 1000);
		}

		element.onscroll = function(){
			if (this.props.location !== 'my-list') return;

			// onSroll, create a 2s timeout. Click and hold event on a card should only be available
			// when expired.
			store_set('click_and_hold_event', false);
			if (this.countdown){
				clearTimeout(this.countdown);
			}
			this.countdown = setTimeout(function() {
				store_set('click_and_hold_event', true);
				this.countdown = false;
			}.bind(this), 2000);

			// When scrolling all the way to the bottom of the my-list page, display a loading component
			// and perform an ajax request to fetch older results to display on the my list page.
			const distance = element.scrollHeight - (element.clientHeight + element.scrollTop);
			if (distance <= 600){
				if (this.props.location === 'my-list' &&
					!this.props.error_loading_older_likes &&
					!this.props.no_more_likes 
				){
					if (!this.props.loading_older_likes) {
						// In case we still didn't fetch all tracks in store, we need first to display all tracks that are in store already
						if (this.state.likes_list.length !== this.props.likes.length) {
							this.expand_likes_list();
						} else {
							store_set('loading_older_likes', true);
							list.load();
						}
					}
				// If we already fecth all tracks in store, we just update the list everytime user scroll page down, little by little
				} else if ((this.state.likes_list.length !== this.props.likes.length) && this.props.no_more_likes) {
					this.expand_likes_list();
				}
			}
		}.bind(this);

		// On Select Mode, clicking away from any card should close the select mode
		element.onclick = function(e){
			if (this.props.location === 'my-list' &&
				this.props.select_mode
			){
				var elements = document.getElementsByClassName('card-box-item');
				for (var i=0; i < elements.length; i++){
					if (elements[i].contains(e.target)) return;
				}
				store_set('select_mode', false);
				store_set('tracks_selected', []);
			}
		}.bind(this);
	}

	expand_likes_list(){
		let new_index = this.state.likes_list_index + 30;
		this.setState({likes_list: this.props.likes.slice(0,new_index)});
		this.setState({likes_list_index: new_index});
	}

	create_new_playlist() {
		if (subscription.is_valid()) {
			store_set('create_playlist', true);
			store_set('playlist_overlay_option', 'internal');
		} else {
			subscription.require('queue_music', 'Subscribing now allows you to organize your liked songs into playlists!', 'internal_playlists');
		}
	}

	// Due to the amount of tracks saved, the page can be slow to load.
	// This function will only prevent the re-render when user change to another page
	shouldComponentUpdate(nextProps){
		if (nextProps.current_page !== this.props.current_page) {
			return false;
		}
		return true;
	}

	render() {
		const likes_list = this.state.likes_list === undefined ? [] : this.state.likes_list;
		const playlists_list = this.props.playlists === undefined ? [] : this.props.playlists;
		const my_list_display = this.props.my_list_display;
		const card_height = window.innerWidth <= 300 ? 37 : 54;

		const [tracks_last_24_hours, tracks_one_day_ago, tracks_last_seven_days, tracks_last_thirty_days, tracks_more_than_thirty_days] = this.belong_to_period(likes_list);
		const empty_state = playlists_list.length + likes_list.length === 0;
		const container_height = empty_state ? {height: '100%', justifyContent: 'flex-end'} : {height: 'initial'};

		if (empty_state){
			const bubble_lines = window.innerHeight > 800 ? 5 : 4;
			return (
				<div className='mylist-main-container' style={container_height}>
					<Bubbles lines={bubble_lines} />
					<div className='mylist-empty-state-content'>
						<div className='mylist-empty-state-text'>
							<div className='mylist-empty-state-title'>Time to discover!</div>
							<div className='mylist-empty-state-description'>Looks like you haven&apos;t liked any song yet. You can search for a track, album or artist and we will suggest you songs based on your search!</div>
						</div>
						<Ripples>
							<Link to='/search/' className='mylist-empty-state-button'>
								<div className='mylist-empty-state-button-text'>Search</div>
								<span className='material-icons'>arrow_forward</span>
							</Link>
						</Ripples>
					</div>
					<div className='mylist-empty-state-shadow' />
				</div>
			);
		}
		return (
			<React.Fragment>
				<div className='mylist-main-container' style={container_height}>
					{playlists_list.length === 0 && !empty_state &&
						<div className='mylist-empty-playlists'>
							<div className='mylist-playlists-container-title'>Playlists</div>
							<div className='mylist-playlists-container-subtitle'>You don&apos;t have any playlists yet. Time to fix it!</div>
							<div className='mylist-empty-playlists-image'>
								<img src={assets.first_playlist} alt='Guitar man singing' />
								<div className='mylist-empty-playlists-content'>
									<img src={assets.first_playlist_sparkles} alt='Sparkles' />
									<div className='mylist-empty-playlists-text'>Create your first playlist</div>
									<div className='mylist-empty-playlists-button' onClick={this.create_new_playlist}>START NOW</div>
								</div>
							</div>
						</div>
					}
					{playlists_list.length > 0 &&
						<React.Fragment>
							<div className='mylist-playlists-container-header'>
								<div className='mylist-playlists-container-title'>Playlists</div>
								<Ripples>
									<div
										className='mylist-playlists-header-button'
										onClick={this.create_new_playlist}
									> + Add New
									</div>
								</Ripples>
							</div>
							<div className='mylist-playlists-container' style={{height: '248px !important'}}>
								{playlists_list.map((playlist, index) => (
									<Card
										key={index}
										data={playlist}
										type='big_playlist_card'
										onClick={() => {store_set('playlist_info', playlist); redirect('/playlist/' + playlist.hash_id);}}
										onMoreClick={() => {store_set('active_overlay', 'playlists_options'); store_set('playlist_overlay_option', 'show_all'); store_set('playlist_info', playlist);}}
									/>
								))}
							</div>
						</React.Fragment>
					}
					{likes_list.length === 0 &&
						<div className='mylist-empty-container'>
							<div className='mylist-empty-icon'>
								<span className='material-icons-sharp'>music_off</span>
							</div>
							<div className='mylist-empty-title'>Your list is empty</div>
							<div className='mylist-empty-text'>Start by picking up a seed and listening to some recommendations.</div>
							<div className='mylist-empty-button'>
								<Ripples>
									<Link to='/search/'className='mylist-empty-button-details'>
										SEARCH
									</Link>
								</Ripples>
							</div>
						</div>
					}
					{likes_list.length > 0 &&
						<React.Fragment>
							<div className='mylist-albuns-container-title-container'>
								<div className='mylist-albuns-container-title'>Recent likes</div>
								{this.props.new_likes_list && this.props.new_likes_list.length > 0 &&
									<span className='mylist-albuns-container-title-bubble'></span>
								}
							</div>
							<div className='mylist-albuns-container'>
								<div className='mylist-albuns-boxes-container'>
									{/* Last 24 hours */}
									{tracks_last_24_hours.length > 0 &&
										<div className='mylist-likes-history-title'>Today</div>
									}
									{tracks_last_24_hours.map((track, index) => {
										const song_id = track.spotify_id || track.hash_id;
										const card_type = my_list_display === 'grid_view' ? 'big_card_song_original' : 'small_card_song';
										const is_new_like = this.props.new_likes_list.includes(track.spotify_id);
										const display_ad = index === 1;
										/* eslint-disable */
										return(
											<React.Fragment>
												{display_ad ? <AdUnit height={60} /> : null}
												<CardWrapped
													card_height={card_height}
													card_type={card_type}
													card_id={index === 0 ? 'slider-container-focus' : ''}
													click_n_hold={this.click_n_hold}
													is_new_like={is_new_like}
													slider_disabled={my_list_display === 'grid_view'}
													handle_song_click={this.handle_song_click}
													song_id={song_id}
													track={track}
												/>
											</React.Fragment>
										)
										/* eslint-enable*/
									})}
									{/* one_day_ago */}
									{tracks_one_day_ago.length > 0 &&
										<div className='mylist-likes-history-title'>Yesterday</div>
									}
									{tracks_one_day_ago.map((track, index) => {
										const song_id = track.spotify_id || track.hash_id;
										const card_type = my_list_display === 'grid_view' ? 'big_card_song_original' : 'small_card_song';
										const first_section = tracks_last_24_hours.length === 0;
										const display_ad = first_section && index === 1;
										/* eslint-disable */
										return(
											<React.Fragment>
												{display_ad ? <AdUnit height={60} /> : null}
												<CardWrapped
													card_height={card_height}
													card_type={card_type}
													card_id={index === 0 && first_section ? 'slider-container-focus' : ''}
													click_n_hold={this.click_n_hold}
													slider_disabled={my_list_display === 'grid_view'}
													handle_song_click={this.handle_song_click}
													song_id={song_id}
													track={track}
												/>
											</React.Fragment>
										)
										/* eslint-enable */
									})}
									{/* last_seven_days */}
									{tracks_last_seven_days.length > 0 &&
										<div className='mylist-likes-history-title'>This week</div>
									}
									{tracks_last_seven_days.map((track, index) => {
										const song_id = track.spotify_id || track.hash_id;
										const card_type = my_list_display === 'grid_view' ? 'big_card_song_original' : 'small_card_song';
										const first_section = tracks_last_24_hours.length === 0 && tracks_one_day_ago.length === 0;
										const display_ad = first_section && index === 1;
										/* eslint-disable */
										return(
											<React.Fragment>
												{display_ad ? <AdUnit height={60} /> : null}
												<CardWrapped
													card_height={card_height}
													card_type={card_type}
													card_id={index === 0 && first_section ? 'slider-container-focus' : ''}
													click_n_hold={this.click_n_hold}
													slider_disabled={my_list_display === 'grid_view'}
													handle_song_click={this.handle_song_click}
													song_id={song_id}
													track={track}
												/>
											</React.Fragment>
										)
										/* eslint-enable */
									})}
									{/* last_thirty_days */}
									{tracks_last_thirty_days.length > 0 &&
										<div className='mylist-likes-history-title'>This month</div>
									}
									{tracks_last_thirty_days.map((track, index) => {
										const song_id = track.spotify_id || track.hash_id;
										const card_type = my_list_display === 'grid_view' ? 'big_card_song_original' : 'small_card_song';
										const first_section = tracks_last_24_hours.length === 0 && tracks_one_day_ago.length === 0 && tracks_last_seven_days.length === 0;
										const display_ad = first_section && index === 1;
										/* eslint-disable */
										return(
											<React.Fragment>
												{display_ad ? <AdUnit height={60} /> : null}
												<CardWrapped
													card_height={card_height}
													card_type={card_type}
													card_id={index === 0 && first_section ? 'slider-container-focus' : ''}
													click_n_hold={this.click_n_hold}
													slider_disabled={my_list_display === 'grid_view'}
													handle_song_click={this.handle_song_click}
													song_id={song_id}
													track={track}
												/>
											</React.Fragment>
										)
										/* eslint-enable */
									})}
									{/* more_than_thirty_days */}
									{tracks_more_than_thirty_days.length > 0 &&
										<div className='mylist-likes-history-title'>More than a month ago</div>
									}
									{tracks_more_than_thirty_days.map((track, index) => {
										const song_id = track.spotify_id || track.hash_id;
										const card_type = my_list_display === 'grid_view' ? 'big_card_song_original' : 'small_card_song';
										const first_section = tracks_last_24_hours.length === 0 && tracks_one_day_ago.length === 0 && tracks_last_seven_days.length === 0 && tracks_last_thirty_days.length === 0;
										const display_ad = first_section && index === 1;
										/* eslint-disable */
										return(
											<React.Fragment>
												{display_ad ? <AdUnit height={60} /> : null}
												<CardWrapped
													card_height={card_height}
													card_type={card_type}
													card_id={index === 0 && first_section ? 'slider-container-focus' : ''}
													click_n_hold={this.click_n_hold}
													slider_disabled={my_list_display === 'grid_view'}
													handle_song_click={this.handle_song_click}
													song_id={song_id}
													track={track}
												/>
											</React.Fragment>
										)
										/* eslint-enable */
									})}
								</div>
							</div>
							{this.props.loading_older_likes &&
								<div className='mylist-load-more'>
									<CircularProgress />
								</div>
							}
							{this.props.error_loading_older_likes &&
								<div className='mylist-load-more'>
									<span className='material-icons'>sync_problem</span>
									<div className='mylist-load-more-error-text'>Unable to load more</div>
								</div>
							}
						</React.Fragment>
					}
				</div>
			</React.Fragment>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		location: state.GlobalReducer.location,
		current_page: state.GlobalReducer.current_page,
		likes: state.GlobalReducer.likes,
		my_list_display: state.GlobalReducer.my_list_display,
		error_loading_older_likes: state.GlobalReducer.error_loading_older_likes,
		loading_older_likes: state.GlobalReducer.loading_older_likes,
		no_more_likes: state.GlobalReducer.no_more_likes,
		click_and_hold_event: state.GlobalReducer.click_and_hold_event,
		tracks_selected: state.GlobalReducer.tracks_selected,
		select_mode: state.GlobalReducer.select_mode,
		new_likes_list: state.GlobalReducer.new_likes_list,
		create_playlist: state.GlobalReducer.create_playlist,
		playlists: state.GlobalReducer.playlists,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(MyList));