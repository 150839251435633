import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Img } from 'react-image';
import ContentLoader from 'react-content-loader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Ripples from 'react-ripples';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '../RequestBuffer.js';

// Constant Imports
import * as constants from '../constants.js';

// Import Components
import Card from '@components/Card.js';

// Assets Imports
import { default_album_image } from '@Assets';

// CSS Imports
import './styles/DesktopTrackPage.css';

class DesktopTrackPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			track_hash_id: null,
			open_share_overlay: false,
			open_play_overlay: false,
			backdrop: false,
			artist_albums: this.props.artist_albums || [],
			recommended_albums: this.props.recommended_albums || [],
			loading_artist_albums: true,
			loading_recommendations: true,
		};

		var path = window.location.href;
		var track_hash_id = null;

		if (path.includes('track')){
			track_hash_id = this.props.url.params.track_hash_id;
			this.state.track_hash_id = track_hash_id;
		}

		this.get_song_info = this.get_song_info.bind(this);
		this.get_artist_works = this.get_artist_works.bind(this);
		this.get_album_recommendations = this.get_album_recommendations.bind(this);

		this.get_artist_works(this.state.track_hash_id);
		this.get_album_recommendations(this.props.song_info.album.spotify_id);

		if (this.props.song_info.spotify_id !== this.state.track_hash_id) {
			this.get_song_info(this.state.track_hash_id);
			this.state.artist_albums = [];
			this.state.recommended_albums = [];
		} else {
			store_set('loading_overlay_display', false);
		}
	}

	get_song_info(hash_id) {
		/* eslint-disable-next-line */
		this.state.backdrop = true
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/song/',
			data: { hash_id: hash_id },
			success: function (data) {
				store_set('song_info', data);
				this.get_artist_works(hash_id);
				this.get_album_recommendations(data.album.spotify_id);
			}.bind(this),
			complete: function () {
				store_set('loading_overlay_display', false);
				this.setState({ backdrop: false })
			}.bind(this),
		});
	}

	get_artist_works(hash_id) {
		this.setState({ loading_artist_albums: true });
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_artist_works,
			data: { track_hash_id: hash_id },
			success: function (data) {
				if (data.status === 'success') {
					this.setState({ 'artist_albums': data.artist_albums });
					store_set('artist_albums', data.artist_albums);
				}
			}.bind(this),
			complete: function () {
				this.setState({ loading_artist_albums: false });
			}.bind(this)
		});
	}

	get_album_recommendations(hash_id) {
		this.setState({ loading_recommendations: true });
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_album_recommendations,
			data: { album_hash: hash_id },
			success: function (data) {
				if (data.status === 'success') {
					const recommended_albums = data.data.recommended_albums.sort(() => 0.5 - Math.random());
					this.setState({ 'recommended_albums': recommended_albums });
					store_set('recommended_albums', recommended_albums);
				}
			}.bind(this),
			complete: function () {
				this.setState({ loading_recommendations: false });
			}.bind(this),
		});
	}

	render() {
		if (this.state.backdrop) {
			return (
				<React.Fragment>
					<Backdrop open={this.state.backdrop}>
						<CircularProgress color='inherit' />
					</Backdrop>
				</React.Fragment>
			);
		} else {
			var song_info = this.props.song_info;
			return (
				<div className='app-desktop-track-page'>
					<div className='app-desktop-track-header-background' style={{ backgroundImage: 'url(' + song_info.album.images.large + ')' }}>
						<div className='app-desktop-track-header-gradient'></div>
					</div>
					<div className='app-desktop-track-container'>
						<div className='app-desktop-track'>
							<div className='app-desktop-track-header'>
								<div className='app-desktop-track-header-image'>
									<Img
										src={[song_info.album.images.large, default_album_image]}
										loader={
											<ContentLoader
												width={'212px'}
												height={'212px'}
												backgroundColor={'#D8D8D8'}
												foregroundColor={'#b1afaf'}
											>
												<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={100 + '%'} />
											</ContentLoader>
										}
									/>
								</div>
								<div className='app-desktop-track-header-description'>
									<div className='app-desktop-track-header-description-title'>
										<div className='app-desktop-track-header-description-text-title'>{song_info.title}</div>
										<Ripples>
											<div className='app-desktop-track-header-button' onClick={() => window.open('http://open.spotify.com/track/' + song_info.spotify_id)}>
												<span className='material-icons'>play_arrow</span>
												<div className='app-desktop-track-header-button-text'>play</div>
											</div>
										</Ripples>
									</div>
									<div className='app-desktop-track-header-description-text-subtitle'><a href={'/album/' + song_info.album.spotify_id}>{(song_info.album.name || song_info.album.title)}</a></div>
									<div className='app-desktop-track-header-description-text-subtitle'>By <a href={'/artist/' + song_info.artist.spotify_id}>{song_info.artist.name}</a></div>
								</div>
							</div>
							<div className='app-desktop-track-body'>
								{this.state.loading_artist_albums ?
									<div className='app-desktop-album-dialog-loading' style={{ marginTop: 50, padding: 0 }}>
										<CircularProgress color='inherit' />
									</div>
									:
									<div className='app-desktop-track-body-section'>
										<div className='app-desktop-track-body-section-title'>More from {song_info.artist.name}</div>
										<div className='app-desktop-track-body-section-content'>
											{this.state.artist_albums.slice(0, 5).map((album, i) => (
												<Card data={album} type='desktop_card_album' bodyLink={'/album/' + album.spotify_id} key={i} />
											))}
										</div>
									</div>
								}
								{this.state.loading_recommendations ?
									<div className='app-desktop-album-dialog-loading' style={{ marginTop: 50, padding: 0 }}>
										<CircularProgress color='inherit' />
									</div>
									:
									this.state.recommended_albums.length > 0 ? (
										<div className='app-desktop-track-body-section'>
											<div className='app-desktop-track-body-section-title'>You may also like</div>
											<div className='app-desktop-track-body-section-content'>
												{this.state.recommended_albums.slice(0, 5).map((album, i) => (
													<Card data={album} type='desktop_card_album' bodyLink={'/album/' + album.spotify_id} key={i} />
												))}
											</div>
										</div>
									) : null
								}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		song_info: state.GlobalReducer.song_info,
		artist_albums: state.GlobalReducer.artist_albums,
		recommended_albums: state.GlobalReducer.recommended_albums,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopTrackPage);