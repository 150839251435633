import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { Img } from 'react-image';

// Actions import
import { store_set } from '@actions/GlobalActions.js';

// CSS Imports
import './styles/Recommendation.css';

// Assets Imports
import * as assets from '@Assets';

// Import functions
import { redirect } from '@routes/Routes';

// Constant Imports
import * as constants from '@constants';

// Import Request Buffer
import { request_buffer } from '../RequestBuffer.js';

// Import the controllers
import interaction from '@Interaction';
import player from '@Player'; 

class Recommendation extends Component {
	constructor(props){
		super(props);
		this.state = {
			interaction: false,
		};

		const hash_id = this.props.url ? this.props.url.params.hash_id : this.props.recommendation_info.hash_id;
		const current_recommendation = this.props.friends_recommendations.find(rec => rec.hash_id === hash_id);
		if (current_recommendation) {
			this.state.interaction = current_recommendation.interaction;
			if (this.props.url) store_set('recommendation_info', current_recommendation);
		}
		else {
			redirect(this.props.previous_page);
		}

		this.on_more_click = this.on_more_click.bind(this);
		this.friend_recommendation_interaction = this.friend_recommendation_interaction.bind(this);
	}

	on_more_click(){
		const data = this.props.recommendation_info;
		if (data.recommendation.type === 'song'){
			store_set('active_overlay', 'track_options'); store_set('song_info', data.recommendation);
		}
		else if (data.recommendation.type === 'artist'){
			store_set('active_overlay', 'artist_options'); store_set('artist_info', data.recommendation);
		}
		else {
			store_set('active_overlay', 'album_options'); store_set('album_info', data.recommendation);
		}
	}

	friend_recommendation_interaction(interaction) {
		let new_friends_recommendations = [...this.props.friends_recommendations];
		let recommendation = new_friends_recommendations.find(rec => rec.hash_id === this.props.data.hash_id);

		if (recommendation) {
			recommendation.interaction = interaction;
			store_set('friends_recommendations', new_friends_recommendations);
		}

		this.setState({interaction});
		request_buffer.auth_execute({
			type: 'post',
			data: {
				tag_hash: this.props.data.hash_id,
				interaction: interaction,
			},
			url: constants.api_endpoint_friend_recommendation_interaction,
		});
	}

	render() {
		const data = this.props.recommendation_info;
		const recommendation = data.recommendation;
		const display_image = recommendation.images ? recommendation.images.medium : recommendation.album.images.medium;
		const user_link = data.user.slug ? data.user.slug : data.user.hash_id.substring(0,10);

		if (!this.props.recommendation_info) return null;
		return(
			<div className='recommendation-page'>
				<div className='recommendation-page-header'>
					<Link to={`/profile/${user_link}`}>
						<div className='recommendation-page-user'>
							<Img src={[data.user.picture, assets.default_profile_image]} alt='' />
							<div className='recommendation-page-header-info'>
								<div className='recommendation-page-header-title'>{data.user.name}</div>
								{data.user.slug &&
									<div className='recommendation-page-header-subtitle'>@{data.user.slug}</div>
								}
							</div>
						</div>
					</Link>
					<span className='material-icons' onClick={this.on_more_click}>more_vert</span>
				</div>
				<div className='recommendation-page-content'>
					<div className='recommendation-page-description'>{data.message}</div>
					<Link to={`/${(recommendation.type === 'song' ? 'track' : recommendation.type)}/${recommendation.spotify_id}`}>
						<div className='recommendation-page-recommended'>
							<Img src={[display_image, assets.default_album_image]} alt='' />
							<div className='recommendation-page-recommended-shadow'></div>
							<div className='recommendation-page-recommended-inner'>
								<div className='recommendation-page-recommended-info'>
									<div className='recommendation-page-recommended-title'>{(recommendation.name || recommendation.title)}</div>
									<div className='recommendation-page-recommended-subtitle'>{recommendation.type} {recommendation.type !== 'artist' ? ' • ' + (recommendation.artist.name) : ''} </div>
								</div>
								{recommendation.type === 'song' &&
									<div className='recommendation-page-recommended-play'
										onClick={
											// If track has no preview to play, we simply redirect to track page
											!recommendation.preview_url ? () => { redirect('/track/' + recommendation.spotify_id);} :
												(this.props.playing_song.spotify_id === recommendation.spotify_id ?
													(e) => {e.preventDefault(); player.toggle();}
													:
													(e) => {e.preventDefault(); player.load(0, recommendation);}
												)
										}
									>
										<span className='material-icons'>{this.props.is_playing && this.props.playing_song.spotify_id === recommendation.spotify_id ? 'pause' : 'play_arrow'}</span>
									</div>
								}
							</div>
						</div>
					</Link>
				</div>
				{recommendation.type === 'song' && data.user.hash_id !== this.props.user.hash_id &&
					<div className='recommendation-page-options'>
						<div
							className='recommendation-page-option'
							style={this.state.interaction === 'dislike' ? {opacity: 0.5} : {}}
							onClick={() => {interaction.like(0, recommendation); this.friend_recommendation_interaction('like');}}
						>
							<span className='material-icons'>favorite</span>
							<div className='recommendation-page-option-text'>Like</div>
						</div>
						<div
							className='recommendation-page-option'
							style={this.state.interaction === 'like' ? {opacity: 0.5} : {}}
							onClick={() => {interaction.dislike(0, recommendation); this.friend_recommendation_interaction('dislike');}}
						>
							<span className='material-icons'>close</span>
							<div className='recommendation-page-option-text'>Dislike</div>
						</div>
					</div>
				}
				<div className='recommendation-page-option-date'>{data.created_at}</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		recommendation_info: state.GlobalReducer.recommendation_info,
		previous_page: state.GlobalReducer.previous_page,
		playing_song: state.GlobalReducer.playing_song,
		is_playing: state.GlobalReducer.is_playing,
		friends_recommendations: state.GlobalReducer.friends_recommendations,
		user: state.GlobalReducer.user
	};
}

export default error_boundary_hoc(connect(mapStateToProps)(Recommendation));