import React from 'react';
import DefaultError from '@views/DefaultError.js';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { has_error: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { has_error: true };
	}

	componentDidCatch(error, errorInfo) { // eslint-disable-line
		// Now log this error somewhere
		// TO BE DONE
	}

	render() {
		if (this.state.has_error) {
			// Now render a custom fallback UI
			return (<DefaultError/>);
		}

		return this.props.children; 
	}
}


export function error_boundary_hoc(OriginalComponent){
	return class ErrorClass extends React.Component{
		render(){
			return (
				<ErrorBoundary>
					<OriginalComponent {...this.props} />
				</ErrorBoundary>
			);
		}
	};
}