import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from '@views/DesktopHome.js';
import Track from '@views/DesktopTrackPage.js';
import Album from '@views/DesktopAlbumPage.js';
import Artist from '@views/DesktopArtistPage.js';
import Profile from '@views/DesktopProfilePage.js';
import TermsAndConditions from '@views/DesktopTerms.js';
import PrivacyPolicy from '@views/DesktopPolicy.js';
import ContactUs from '@views/DesktopContact.js';
import RedirectStores from '@views/RedirectStores.js';
import DesktopLogin from '@views/DesktopLogin.js';

class Routes extends Component {
	render() {
		return (
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/artist/:artist_hash_id/" exact component={({ match }) => <Artist url={match}/>} />
				<Route path="/album/:album_hash_id/" exact component={({ match }) => <Album url={match}/>} />
				<Route path="/track/:track_hash_id/" exact component={({ match }) => <Track url={match}/>} />
				<Route path="/profile/:slug/" exact component={({ match }) => <Profile url={match}/>} />
				<Route path="/terms-and-conditions/" exact component={TermsAndConditions} />
				<Route path="/privacy-policy/" exact component={PrivacyPolicy} />
				<Route path="/contact-us/" exact component={ContactUs} />
				<Route path="/lp/" exact component={RedirectStores} />
				<Route path="/login/" exact component={DesktopLogin} />
				<Route component={Home} />
			</Switch>
		);
	}
}

function mapStateToProps() {
	return {};
}

export default connect(mapStateToProps)(Routes);
