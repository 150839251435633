import React, { Component } from 'react';
import { connect } from 'react-redux';
import player from '@Player';
import {Img} from 'react-image';
import ContentLoader from 'react-content-loader';
import Dialog from '@material-ui/core/Dialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Ripples from 'react-ripples';
import Haptic from '@components/haptic.js';
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	TelegramShareButton,
	VKShareButton,
} from 'react-share';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PublicIcon from '@material-ui/icons/Public';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TuneIcon from '@material-ui/icons/Tune';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { abbreviateNumber } from 'js-abbreviation-number';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '../RequestBuffer.js';

// Constant Imports
import * as constants from '../constants.js';

// Assets Imports
import * as assets from '@Assets';

// CSS Imports
import './styles/DesktopProfilePage.css';

// Nivo imports
import { useTheme } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveRadar } from '@nivo/radar';

// Discovery Timeline Nivo
const colors = {
	'likes': '#ec6b43',
	'dislikes': 'rgba(236, 107, 67, 0.5)',
	'Me': '#ec6b43',
	'Rest of the world': 'rgb(112, 112, 112)'
};

function format_song_duration(millis) {
	let minutes = Math.floor(millis / 60000);
	let seconds = ((millis % 60000) / 1000).toFixed(0);
	let duration_formatted = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
	return duration_formatted;
}

const StyledTick = ({
	value: _value,
	x,
	y,
	opacity,
	rotate,
	format,
	lineX,
	lineY,
	onClick,
	textX,
	textY,
	textBaseline,
	textAnchor
}) => {
	const theme = useTheme();
	let value = _value;
	if (format !== undefined) {
		value = format(value);
	}
	
	let gStyle = { opacity };
	if (onClick) {
		gStyle['cursor'] = 'pointer';
	}
	
	return (
		<g
			transform={`translate(${x},${y})`}
			{...(onClick ? { onClick: e => onClick(e, value) } : {})}
			style={gStyle}
		>
			<line x1={0} x2={lineX} y1={0} y2={lineY} style={theme.axis.ticks.line} />
			<text
				dominantBaseline={textBaseline}
				textAnchor={textAnchor}
				transform={`translate(${textX},${textY}) rotate(${rotate})`}
				// (1) merge theme styles with our fontWeight
				style={{ ...theme.axis.ticks.text, fontWeight: 400, fill: '#707070' }}
			>
				{value}
			</text>
		</g>
	);
};

const AspectsBar = (props) => {
	return (
		<ResponsiveBar
			data={props.data}
			keys={props.levels}
			indexBy={props.vertex}
			margin={{ top: 40, right: 30, bottom: 90, left: 65 }}
			padding={0.5}
			innerPadding={8}
			groupMode='grouped'
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={bar => bar.id in colors ? colors[bar.id] : '#ec6b43'}
			axisTop={null}
			maxValue={100}
			axisRight={null}
			borderRadius={10}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: -15,
					translateY: 80,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolShape: 'circle',
					symbolSize: 20,
				}
			]}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0
			}}
			axisLeft={{
				renderTick: StyledTick,
				tickSize: 0,
				tickPadding: 10,
				tickRotation: 0,
				tickValues: [0, 25, 50, 75, 100],
				legend: '',
				format: e => e + '%',
			}}
			theme={{
				'fontSize': 13,
				'axis': {
					'ticks': {
						'text': {
							'fill': '#ffffff'
						}
					}
				},
				'legends': {
					'text': {
						'fill': '#707070',
						'fontWeight': '400'
					}
				},
				'grid': {
					'line': {
						'stroke': '#707070',
						'strokeDasharray': '3, 6',
						'strokeWidth':0.5 
					}
				}			
			}}
			gridYValues={[0, 25, 50, 75, 100]}
			enableGridY={true}
			isInteractive={false}
			enableLabel={false}
		/>
	);
};

const StatsBar = (props) => (
	<ResponsiveBar
		data={props.data}
		keys={props.levels}
		indexBy={props.vertex}
		margin={{ top: 40, right: 15, bottom: 40, left: 50 }}
		padding={0.3}
		groupMode='grouped'
		valueScale={{ type: 'linear' }}
		indexScale={{ type: 'band', round: true }}
		colors={bar => colors[bar.id]}
		axisTop={null}
		axisRight={null}
		axisBottom={{
			tickSize: 0,
			tickPadding: 10,
			tickRotation: 0
		}}
		axisLeft={{
			tickSize: 0,
			tickPadding: 3,
			tickRotation: 0,
			legend: '',
			format: e => Math.floor(e) === e && e,
		}}
		enableGridY={false}
		isInteractive={false}
		enableLabel={false}
	/>
);

// Music Genres and Aspects Nivo
const StatsRadar = (props) => (
	<ResponsiveRadar
		data={props.data}
		keys={props.levels}
		indexBy={props.vertex}
		margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
		borderColor={{ from: 'color' }}
		gridShape='linear'
		gridLabelOffset={20}
		dotColor={{ from: 'color', modifiers: [] }}
		dotBorderWidth={3}
		colors={['#ec6b43']}
		fillOpacity={0.2}
		motionConfig='wobbly'
		isInteractive={false}
	/>
);

class TopTracksCard extends Component {
	constructor(props){
		super(props);
		this.state = {
			open_overlay: false,
		};
	}

	render(){
		return(
			<div className='profile-desktop-top-tracks-card'>
				<div className='profile-desktop-top-tracks-card-content'>
					<div className='profile-desktop-top-tracks-card-info'>
						<Img
							src={[this.props.song.album.images.medium, assets.default_album_image]} alt=''
							loader={
								<ContentLoader 
									width={72}
									height={72}
									backgroundColor={'#D8D8D8'}
									foregroundColor={'#b1afaf'}
								>
									<rect x='0' y='0' rx='0' ry='0' width={72} height={72} /> 
								</ContentLoader>
							}
							className='profile-desktop-top-tracks-card-info-image'
						/>
						<div className='profile-desktop-top-tracks-card-info-details'>
							<div className='profile-desktop-top-tracks-card-info-details-title'
								style={(this.props.song.album.title.length > 40 && this.props.song.title.length > 40) || this.props.song.title.length > 50 ? {width: '60%', whiteSpace: 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis'}: {}}
							>{this.props.song.title}</div>
							<div className='profile-desktop-top-tracks-card-info-details-subtitle'>{this.props.song.artist.name}</div>
						</div>
					</div>
					<div className='profile-desktop-top-tracks-card-text'>{this.props.song.album.title}</div>
					<div className='profile-desktop-top-tracks-card-buttons'>
						<div
							className='profile-desktop-top-tracks-card-button-player'
							onClick={() => window.open('https://open.spotify.com/track/' + this.props.song.spotify_id)}
						>
							<span className='material-icons profile-desktop-top-tracks-card-button-play'>play_arrow</span>
						</div>
						<div className='profile-desktop-top-tracks-card-time'>{format_song_duration(this.props.song.duration_ms)}</div>
						<span className='material-icons profile-desktop-top-tracks-card-button-more' onClick={() => this.setState({open_overlay: !this.state.open_overlay})}>more_vert</span>
					</div>
				</div>
				{this.state.open_overlay &&
					<ClickAwayListener onClickAway={() => this.setState({open_overlay: false})}>
						<div className='profile-desktop-top-tracks-card-overlay'>
							<div onClick={() => window.open('https://open.spotify.com/track/' + this.props.song.spotify_id)} className='profile-desktop-top-tracks-card-overlay-option'>Play full song</div>
							<Link to={'/artist/' + this.props.song.artist.spotify_id} className='profile-desktop-top-tracks-card-overlay-option-link'>
								<div className='profile-desktop-top-tracks-card-overlay-option'>Go to artist page</div>
							</Link>
							<div className='profile-desktop-top-tracks-card-overlay-share'>
								<div className='profile-desktop-top-tracks-card-overlay-share-button'>
									<div className='profile-desktop-top-tracks-card-overlay-share-button-text'>Share</div>
									<span className='material-icons'>chevron_right</span>
								</div>
								<div className='profile-desktop-top-tracks-card-overlay-share-options-list'>
									<Ripples>
										<FacebookShareButton url={constants.api_endpoint_song_meta.replace('{hash_id}', this.props.song.spotify_id)}>
											<Haptic intesity='light'>
												<div className='profile-desktop-top-tracks-card-overlay-share-option'>
													<img src={assets.facebook_icon_circle} className='profile-desktop-top-tracks-card-overlay-share-option-image profile-desktop-top-tracks-card-overlay-share-option-facebook' alt='facebook_icon_circle'/>
													<div className='profile-desktop-top-tracks-card-overlay-share-option-title'>Facebook</div>
												</div>
											</Haptic>
										</FacebookShareButton>
									</Ripples>
									<Ripples>
										<TwitterShareButton url={constants.api_endpoint_song_meta.replace('{hash_id}', this.props.song.spotify_id)}>
											<Haptic intesity='light'>
												<div className='profile-desktop-top-tracks-card-overlay-share-option'>
													<img src={assets.twitter_bird_icon} className='profile-desktop-top-tracks-card-overlay-share-option-image profile-desktop-top-tracks-card-overlay-share-option-twitter' alt='twitter_bird_icon'/>
													<div className='profile-desktop-top-tracks-card-overlay-share-option-title'>Twitter</div>
												</div>
											</Haptic>
										</TwitterShareButton>
									</Ripples>
									<CopyToClipboard 
										text={constants.api_endpoint_song_meta.replace('{hash_id}', this.props.song.spotify_id)}
										onCopy={() => this.props.open_snackbar('Link Copied')}
									>
										<div className='profile-desktop-top-tracks-card-overlay-share-option'>
											<div className='profile-desktop-top-tracks-card-overlay-share-option-icon material-icons'>link</div>
											<div className='profile-desktop-top-tracks-card-overlay-share-option-title'>Copy Link</div>
										</div>
									</CopyToClipboard>
								</div>
							</div>
						</div>
					</ClickAwayListener>
				}
			</div>
		);
	}
}

class DesktopProfilePage extends Component {
	constructor(props){
		super(props);
		this.state = {
			track_hash_id: null,
			profile_slug: '',
			open_share_overlay: false,
			open_play_overlay: false,
			backdrop: false,
			tabIndex: 0,
			snackbar_status: false,
			snackbar_message: '',

			spotify_stats: this.props.spotify_stats || false,
			magroove_stats: this.props.magroove_stats || false,
			user_leaderboard: this.props.user_leaderboard || [],
			likeminded_users: this.props.likeminded_users || [],
		};

		var path = window.location.href;
		var profile_slug = null;

		if (path.includes('profile')){
			profile_slug = this.props.url.params.slug;
			this.state.profile_slug = profile_slug;
		}

		this.change_tab = this.change_tab.bind(this);
		
		this.sort_leaderboard = this.sort_leaderboard.bind(this);
		this.handle_change = this.handle_change.bind(this);
		this.open_snackbar = this.open_snackbar.bind(this);
		
		this.get_magroove_stats = this.get_magroove_stats.bind(this);
		this.get_spotify_stats = this.get_spotify_stats.bind(this);
		this.get_user_leaderboard = this.get_user_leaderboard.bind(this);
		this.get_likeminded_users = this.get_likeminded_users.bind(this);
		this.get_profile_info = this.get_profile_info.bind(this);

		if (this.props.profile_info === undefined) {
			store_set('profile_info', {});
		}

		if (this.state.profile_slug.length > 0){
			this.state.spotify_stats =  false;
			this.state.magroove_stats = false;
			this.state.user_leaderboard = [];
			this.state.likeminded_users = [];
			this.get_profile_info(this.state.profile_slug);
		} else {
			this.setState({backdrop: false});
			this.get_spotify_stats();
			this.get_magroove_stats();
			this.get_likeminded_users();
			this.get_user_leaderboard();
		}

	}

	open_snackbar(message){
		this.setState({snackbar_message: message, snackbar_status: true});
	}

	change_tab(event, tabIndex){
		this.setState({ tabIndex });
	}

	handle_change(key, value){
		this.setState({[key]: value});
	}

	get_profile_info(profile_slug){
		player.reset();

		this.setState({
			profile_slug: profile_slug,
			magroove_stats: false,
			spotify_stats: false,
			user_leaderboard: [],
			likeminded_users: []
		});

		// eslint-disable-next-line
		this.state.backdrop = true

		// We won't allow slug with less than 5 characters
		let slug = profile_slug;
		if (slug.length < 5){
			window.location.href = '/';
			return;
		}
		let key_type = 'slug';

		// Check if we already have the profile info stored in redux
		let is_profile_info = false;
		if(this.props.profile_info && Object.keys(this.props.profile_info).length > 0){
			if('hash_id' in this.props.profile_info && (this.props.profile_info.hash_id.substring(0,10) === profile_slug || this.props.profile_info.slug === profile_slug)){
				is_profile_info = true;
			}
		}
		if(is_profile_info){
			if ('slug' in this.props.profile_info && this.props.profile_info.slug.length > 0){
				slug = this.props.profile_info.slug;
			} else if ('hash_id' in this.props.profile_info){
				slug = this.props.profile_info.hash_id;
				key_type = 'hash_id';
			}
		}

		store_set('profile_info', {});

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_profile,
			data: {
				slug:slug,
				key_type:key_type,
			},
			success: function(data){
				if (data.status === 'success'){
					store_set('profile_info', data.data.user);
					if (data.data.user.fine_tunning && Object.keys(data.data.user.fine_tunning).length > 0){
						store_set('fine_tunning', data.data.user.fine_tunning);
					}
					this.get_spotify_stats();
					this.get_magroove_stats();
					this.get_likeminded_users();
					this.get_user_leaderboard();
				} else if(data.status === 'error' && data.reason === 'user_not_found'){
					window.location.href = '/';
				}
			}.bind(this),
			// eslint-disable-next-line
			complete: function(data){
				store_set('loading_overlay_display', false);
				this.setState({backdrop: false});
			}.bind(this),
		});
	}

	get_magroove_stats(){
		// Check for user until it's loaded
		if (!this.props.profile_info.hash_id) {
			setTimeout(this.get_magroove_stats, 1000);
			return;
		}

		let hash_id = this.state.profile_slug.length > 0 ? this.props.profile_info.hash_id : this.props.user.hash_id;

		// Once loaded, fetch the magroove stats
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_magroove_stats,
			data: { member_hash_id: hash_id },
			success: function(data){
				if (data.status === 'success'){
					store_set('magroove_stats', data.magroove_stats);
					this.setState({magroove_stats: data.magroove_stats});
				}
			}.bind(this),
			// eslint-disable-next-line
			complete: function(data){}.bind(this),
		}, true);
	}

	get_spotify_stats(){
		// Check for user until it's loaded
		if (!this.props.profile_info.hash_id) {
			setTimeout(this.get_spotify_stats, 1000);
			return;
		}

		// Once loaded, fetch the spotify stats
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_spotify_stats,
			data: { member_hash_id: this.props.profile_info.hash_id },
			success: function(data){
				if (data.status === 'success'){
					store_set('spotify_stats', data.spotify_stats);
					this.setState({spotify_stats: data.spotify_stats});
				} else {
					store_set('spotify_stats', false);
					this.setState({spotify_stats: false});
				}
			}.bind(this)
		}, true);
	}

	sort_leaderboard(data){
		if (data.length === 0) { return; }

		// Add the current user to their friends list
		let user = this.state.profile_slug.length > 0 ? this.props.profile_info : this.props.user;
		let new_data = data;
		data.push({
			name: user.first_name + ' ' + user.last_name,
			slug: user.slug,
			picture: user.picture,
			likes: user.likes,
			dislikes: user.dislikes,
			hash_id: user.hash_id,
		});

		// Sort list by highest number of likes
		let sorted_list = new_data.sort((a, b) => b.likes - a.likes);
		let sliced_list = [];

		// In case the user is the one with most likes, we display them on top of leaderboard

		if (sorted_list[0].hash_id === this.props.user.hash_id) {
			sliced_list = sorted_list.slice(0,5);
		} else {
			// Otherwise, current user must be on center
			// Find indexes
			let user_ix = sorted_list.findIndex(user => user.hash_id === this.props.user.hash_id);
			let start_ix = user_ix - 2;
			let len = sorted_list.length;

			// Slice list, the leaderboard must only show 5 members
			sliced_list = sorted_list.slice(start_ix, len).slice(0,5);
		}

		store_set('user_leaderboard', sliced_list);
		this.setState({user_leaderboard: sliced_list});
	}

	get_user_leaderboard(){
		// Check for user until it's loaded
		if (!this.props.profile_info.hash_id) {
			setTimeout(this.get_user_leaderboard, 1000);
			return;
		}

		// Once loaded, fetch the user leaderboard
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_user_leaderboard,
			data: { member_hash_id: this.props.profile_info.hash_id },
			success: function(data){
				if (data.status === 'success'){
					this.sort_leaderboard(data.user_leaderboard);
				}
			}.bind(this)
		}, true);
	}

	get_likeminded_users(){
		// Check for user until it's loaded
		if (!this.props.profile_info.hash_id) {
			setTimeout(this.get_likeminded_users, 1000);
			return;
		}

		// Once loaded, fetch the list of likeminded users
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_likeminded_users,
			data: {
				user_logged_hash_id: false,
				user_profile_hash_id: this.props.profile_info.hash_id
			},
			success: function(data){
				if (data.status === 'success'){
					store_set('likeminded_users', data.likeminded_users);
					this.setState({likeminded_users: data.likeminded_users});
				}
			}.bind(this)
		}, true);
	}

	render() {
		const { tabIndex } = this.state;
		if(this.state.backdrop){
			return(
				<React.Fragment>
					<Backdrop open={this.state.backdrop} className='profile-desktop-backdrop'>
						<CircularProgress color='inherit' />
					</Backdrop>
				</React.Fragment>
			);
		} else {

			const { spotify_stats, magroove_stats, likeminded_users, user_leaderboard } = this.state;
			let display_user = this.state.profile_slug.length > 0 ? this.props.profile_info.first_name : 'Me';

			let profile_info = this.props.profile_info;
			if (profile_info === undefined) {
				profile_info = {};
			}

			var album_image = () => (
				<Img
					src={[profile_info.picture, assets.default_profile_image]}
					loader={
						<ContentLoader 
							width={'288px'}
							height={'288px'}
							backgroundColor={'#D8D8D8'}
							foregroundColor={'#b1afaf'}
						>
							<circle cx='50%' cy='50%' r='50%' />
						</ContentLoader>
					}
				/>
			);

			return (
				<div className='profile-desktop-background'>
					<div className='profile-desktop-background-header-container'>
						<Img
							src={[profile_info.header_picture, assets.profile_desktop_headerdefault]} alt=''
							loader={
								<ContentLoader 
									width={'100%'}
									height={250}
									backgroundColor={'#D8D8D8'}
									foregroundColor={'#b1afaf'}
								>
									<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={250} /> 
								</ContentLoader>
							}
							className='profile-desktop-background-header'
						/>
					</div>
					<div className='profile-desktop-background-header-gradient' style={{backgroundImage: 'url("' + assets.profile_desktop_headergradient + '")'}}></div>
					<div className='profile-desktop-main-container-background'>
						<div className='profile-desktop-main-container'>
							<div className='profile-desktop-header'>
								<div className='profile-desktop-header-main-info'>
									<div className='profile-desktop-header-main-info-container'>
										<div className='profile-desktop-header-main-info-image'>
											{album_image()}
										</div>
										<div className='profile-desktop-header-main-info-description'>
											<div className='profile-desktop-header-main-info-description-tags'>
												<div className='profile-desktop-header-main-info-description-tag-card'>
													<div className='profile-desktop-header-main-info-description-tag-card-number'>{abbreviateNumber(profile_info.followers)}</div>
													<div className='profile-desktop-header-main-info-description-tag-card-text'>followers</div>
												</div>
												<div className='profile-desktop-header-main-info-description-tag-card'>
													<div className='profile-desktop-header-main-info-description-tag-card-number'>{abbreviateNumber(profile_info.following)}</div>
													<div className='profile-desktop-header-main-info-description-tag-card-text'>following</div>
												</div>
											</div>
											<div className='profile-desktop-header-main-info-description-title'>{profile_info.first_name} {profile_info.last_name}</div>
											{profile_info.slug.length > 0 &&
												<div className='profile-desktop-header-main-info-description-subtitle'>@{profile_info.slug}</div>
											}
											<div className='profile-desktop-header-main-info-description-badges'></div>
										</div>
									</div>
									<div className='profile-desktop-header-main-info-button' onClick={() => this.setState({open_share_overlay: true})}>
										<span className='material-icons profile-desktop-header-main-info-button-details'>share</span>
									</div>
								</div>
								{(profile_info.seeds > 0 || profile_info.tracks > 0 || profile_info.likes > 0 || profile_info.dislikes > 0) &&
									<div className='profile-desktop-header-highlights'>
										<div className='profile-desktop-header-highlights-card'>
											<div className='profile-desktop-header-highlights-card-icon'>
												<img src={assets.seed_icon} alt='seed_icon'/>
											</div>
											<div className='profile-desktop-header-highlights-card-info'>
												<div className='profile-desktop-header-highlights-card-info-title'>Seeds setted</div>
												<div className='profile-desktop-header-highlights-card-info-number'>{abbreviateNumber(profile_info.seeds)}</div>
											</div>
										</div>
										<div className='profile-desktop-header-highlights-card'>
											<div className='profile-desktop-header-highlights-card-icon'>
												<span className='material-icons'>album</span>
											</div>
											<div className='profile-desktop-header-highlights-card-info'>
												<div className='profile-desktop-header-highlights-card-info-title'>Recommendations</div>
												<div className='profile-desktop-header-highlights-card-info-number'>{abbreviateNumber(profile_info.tracks)}</div>
											</div>
										</div>
										<div className='profile-desktop-header-highlights-card'>
											<div className='profile-desktop-header-highlights-card-icon' style={{backgroundColor: 'rgba(6, 167, 82, 0.05)'}}>
												<span className='material-icons' style={{color: '#06a752'}}>favorite</span>
											</div>
											<div className='profile-desktop-header-highlights-card-info'>
												<div className='profile-desktop-header-highlights-card-info-title' style={{color: '#06a752'}}>Likes</div>
												<div className='profile-desktop-header-highlights-card-info-number'>{abbreviateNumber(profile_info.likes)}</div>
											</div>
										</div>
										<div className='profile-desktop-header-highlights-card'>
											<div className='profile-desktop-header-highlights-card-icon' style={{backgroundColor: 'rgba(236, 107, 67, 0.05)'}}>
												<span className='material-icons' style={{color: '#ec6b43'}}>close</span>
											</div>
											<div className='profile-desktop-header-highlights-card-info'>
												<div className='profile-desktop-header-highlights-card-info-title' style={{color: '#ec6b43'}}>Dislikes</div>
												<div className='profile-desktop-header-highlights-card-info-number'>{abbreviateNumber(profile_info.dislikes)}</div>
											</div>
										</div>
									</div>
								}
							</div>
							<div className='profile-desktop-container'>
								{spotify_stats &&
									<React.Fragment>
										{spotify_stats.long_term.artists.length > 0 &&
											<div className='profile-desktop-top-artists-section'>
												<div className='profile-desktop-section-title'>Top Artists</div>
												<div className='profile-desktop-top-artists-section-list'>
													{spotify_stats.long_term.artists.slice(0,4).map((artist, index) => {
														return(
															<Link to={'/artist/' + artist.spotify_id + '/'}  key={index}>
																<div className='profile-desktop-top-artists-card'>
																	<Img
																		src={[artist.album ? artist.album.images.medium : artist.images.medium, assets.default_artist_image]} alt=''
																		loader={
																			<ContentLoader 
																				width={'100%'}
																				height={229}
																				backgroundColor={'#D8D8D8'}
																				foregroundColor={'#b1afaf'}
																			>
																				<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={229} /> 
																			</ContentLoader>
																		}
																		className='profile-desktop-top-artists-card-image'
																	/>
																	<div className='profile-desktop-top-artists-info'>
																		<div className='profile-desktop-top-artists-info-title'>{artist.name}</div>
																		{/* <div className='profile-desktop-top-artists-info-subtitle'>{artist.plays === 1 ? artist.plays + 'play' : artist.plays + 'plays'}</div> */}
																	</div>
																</div>
															</Link>
														);
													})}
												</div>
											</div>
										}
										{spotify_stats.long_term.albums.length > 0 &&
											<div className='profile-desktop-top-albums-section'>
												<div className='profile-desktop-section-title'>Top Albums</div>
												<div className='profile-desktop-top-albums-section-container'>
													{spotify_stats.long_term.albums.slice(0,1).map((album, index) =>
														<Link to={'/album/' + album.spotify_id + '/'}  key={index} className='profile-desktop-top-albums-section-main-link'>
															<Img
																src={[album.images.medium, assets.default_artist_image]} alt=''
																loader={
																	<ContentLoader 
																		width={'100%'}
																		height={425}
																		backgroundColor={'#D8D8D8'}
																		foregroundColor={'#b1afaf'}
																	>
																		<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={425} /> 
																	</ContentLoader>
																}
																className='profile-desktop-top-albums-section-main'
															/>
															<div className='profile-desktop-top-albums-section-card-opacity'></div>
															<div className='profile-desktop-top-albums-section-card-title'>{album.title}</div>
															<div className='profile-desktop-top-albums-section-card-subtitle'>{album.artist.name}</div>
															{/* <div className='profile-desktop-top-albums-section-card-legend'>{album.plays === 1 ? album.plays + 'play' : album.plays + 'plays'}</div> */}
														</Link>
													)}
													<div className='profile-desktop-top-albums-section-card-container'>
														<div className='profile-desktop-top-albums-section-card-container-one'>
															{spotify_stats.long_term.albums.slice(1,4).map((album, index) =>
																<Link to={'/album/' + album.spotify_id + '/'}  key={index} >
																	<Img
																		src={[album.images.medium, assets.default_artist_image]} alt=''
																		loader={
																			<ContentLoader 
																				width={'100%'}
																				height={205}
																				backgroundColor={'#D8D8D8'}
																				foregroundColor={'#b1afaf'}
																			>
																				<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={205} /> 
																			</ContentLoader>
																		}
																		className='profile-desktop-top-albums-section-card'
																	/>
																	<div className='profile-desktop-top-albums-section-card-opacity'></div>
																	<div className='profile-desktop-top-albums-section-card-title'>{album.title}</div>
																	<div className='profile-desktop-top-albums-section-card-subtitle'>{album.artist.name}</div>
																	{/* <div className='profile-desktop-top-albums-section-card-legend'>{album.plays === 1 ? album.plays + 'play' : album.plays + 'plays'}</div> */}
																</Link>
															)}
														</div>
														<div className='profile-desktop-top-albums-section-card-container-two'>
															{spotify_stats.long_term.albums.slice(4,7).map((album, index) =>
																<Link to={'/album/' + album.spotify_id + '/'}  key={index} >
																	<Img
																		src={[album.images.medium, assets.default_artist_image]} alt=''
																		loader={
																			<ContentLoader 
																				width={'100%'}
																				height={205}
																				backgroundColor={'#D8D8D8'}
																				foregroundColor={'#b1afaf'}
																			>
																				<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={205} /> 
																			</ContentLoader>
																		}
																		className='profile-desktop-top-albums-section-card'
																	/>
																	<div className='profile-desktop-top-albums-section-card-opacity'></div>
																	<div className='profile-desktop-top-albums-section-card-title'>{album.title}</div>
																	<div className='profile-desktop-top-albums-section-card-subtitle'>{album.artist.name}</div>
																	{/* <div className='profile-desktop-top-albums-section-card-legend'>{album.plays === 1 ? album.plays + 'play' : album.plays + 'plays'}</div> */}
																</Link>
															)}
														</div>
													</div>
												</div>
											</div>
										}
										<div className='profile-desktop-top-tracks-section'>
											<div className='profile-desktop-section-title'>Top Tracks</div>
											<div className='profile-desktop-top-tracks-list-container'>
												{spotify_stats.long_term.songs.slice(0,5).map((song, index) => {
													return (
														<TopTracksCard
															key={index}
															song={song}
															set_share_config={this.set_share_config}
															open_snackbar={this.open_snackbar}
															{...this.props}
														/>
													);
												})}
											</div>
										</div>
									</React.Fragment>
								} 
								{magroove_stats && profile_info.likes > 0 &&
									<div className='profile-desktop-charts-section'>
										<div className='profile-desktop-charts-nav'>
											<Tabs
												value={tabIndex}
												onChange={this.change_tab}
												scrollButtons='auto'
											>
												<Tab icon={<LibraryMusicIcon />} className={'profile-desktop-charts-option-details'} label='Genre Chart' disableRipple />
												<Tab icon={<TuneIcon />} className={'profile-desktop-charts-option-details'} label='Music Aspects' disableRipple />
												<Tab icon={<PublicIcon />} className={'profile-desktop-charts-option-details'} label='Music Taste' disableRipple />
												<Tab icon={<EventNoteIcon />} className={'profile-desktop-charts-option-details'} label='Discovery Timeline' disableRipple />
											</Tabs>
										</div>
										<div className='profile-desktop-charts-container'>
											{tabIndex === 0 &&
												<div className='profile-desktop-chart'>
													{magroove_stats.genre_data &&
														<React.Fragment>
															<div className='profile-desktop-chart-background'>
																<StatsRadar data={magroove_stats.genre_data} vertex='display_name' levels={['display_plays']}/>
															</div>
															<div className='profile-desktop-chart-labels'>
																{magroove_stats.genre_data.sort((a, b) => b.total_plays - a.total_plays).map((item, i) => (
																	<div className='profile-desktop-section-chart-item' key={i}>
																		<div className='profile-desktop-section-chart-item-info'>
																			<div className='profile-desktop-section-chart-item-number'>{i + 1}</div>
																			<div className='profile-desktop-section-chart-item-title'>{item.musical_genre}</div>
																		</div>
																		<div className='profile-desktop-section-chart-item-chip'>{item.total_plays} plays</div>
																	</div>
																))}
															</div>
														</React.Fragment>
													}
													{!magroove_stats.genre_data &&
														<div className='profile-desktop-chart-no-data'>No data available...</div>
													}
												</div>
											}
											{tabIndex === 1 &&
												<div className='profile-desktop-chart'>
													{magroove_stats.aspects_data &&
														<React.Fragment>
															<div className='profile-desktop-chart-background'>
																<StatsRadar data={magroove_stats.aspects_data} vertex='display_name' levels={['aspect_level']} />
															</div>
															<div className='profile-desktop-chart-labels'>
																{magroove_stats.aspects_data.sort((a, b) => b.aspect_level - a.aspect_level).map((item, i) => (
																	<div className='profile-desktop-section-chart-item' key={i}>
																		<div className='profile-desktop-section-chart-item-info'>
																			<div className='profile-desktop-section-chart-item-title'>{item.aspect}</div>
																		</div>
																		<div className='profile-desktop-section-chart-item-chip'>{~~(item.aspect_level)}</div>
																	</div>
																))}
															</div>
														</React.Fragment>
													}
													{!magroove_stats.aspects_data &&
														<div className='profile-desktop-chart-no-data'>No data available...</div>
													}
												</div>
											}
											{tabIndex === 2 &&
												<div className='profile-desktop-chart'>
													{magroove_stats.aspects_data &&
														<React.Fragment>
															<div className='profile-desktop-chart-background'>
																<AspectsBar
																	key="global_graph_bar"
																	data={magroove_stats.aspects_data.filter(
																		a => 
																			a.display_name !== 'Danceable' && a.display_name !== 'Happiness'
																	).map(
																		(a) => ({
																			name: a.display_name, 
																			[display_user]: Math.floor(a.aspect_level), 
																			'Rest of the world': magroove_stats.global_aspects_data.find(
																				(g => g.display_name === a.display_name)
																			).aspect_level
																		})
																	)} 
																	vertex='name' 
																	levels={[display_user, 'Rest of the world']}
																/>
															</div>
														</React.Fragment>
													}
													{!magroove_stats.aspects_data &&
														<div className='profile-desktop-chart-no-data'>No data available...</div>
													}
												</div>
											}
											{tabIndex === 3 &&
												<div className='profile-desktop-chart'>
													{magroove_stats.timeline_data && (magroove_stats.weekly_interactions.likes + magroove_stats.weekly_interactions.dislikes > 0) &&
														<React.Fragment>
															<div className='profile-desktop-chart-background'>
																<StatsBar data={magroove_stats.timeline_data} vertex='weekday' levels={['likes', 'dislikes']}/>
															</div>
															<div className='profile-desktop-chart-labels'>
																<div className='profile-desktop-section-chart-item'>
																	<div className='profile-desktop-section-chart-item-info'>
																		<div className='profile-desktop-section-chart-item-title'>Likes</div>
																	</div>
																	<div className='profile-desktop-section-chart-item-chip'>{magroove_stats.weekly_interactions.likes}</div>
																</div>
																<div className='profile-desktop-section-chart-item'>
																	<div className='profile-desktop-section-chart-item-info'>
																		<div className='profile-desktop-section-chart-item-title'>Dislikes</div>
																	</div>
																	<div className='profile-desktop-section-chart-item-chip'>{magroove_stats.weekly_interactions.dislikes}</div>
																</div>
															</div>
														</React.Fragment>
													}
													{!(magroove_stats.timeline_data && (magroove_stats.weekly_interactions.likes + magroove_stats.weekly_interactions.dislikes > 0)) &&
														<div className='profile-desktop-chart-no-data'>No data available...</div>
													}
												</div>
											}
										</div>
									</div>
								}
								{user_leaderboard.length === 5 &&
									<div className='profile-desktop-leaderboard-section'>
										<div className='profile-desktop-section-title'>Discovery Leaderboard</div>
										<div className='profile-desktop-leaderboard-container'>
											<React.Fragment>
												{user_leaderboard.map((user, i) => {
													return (
														<div className='profile-desktop-leaderboard-card' key={i}>
															<div className='profile-desktop-leaderboard-card-status'>
																{/* <span className='material-icons profile-desktop-leaderboard-card-status-arrow-up'>arrow_drop_up</span> */}
																<div className='profile-desktop-leaderboard-card-status-number'>{i + 1}</div>
																{/* <span className='material-icons profile-desktop-leaderboard-card-status-arrow-down'>arrow_drop_down</span> */}
															</div>
															<div className='profile-desktop-leaderboard-card-background'>
																<div className='profile-desktop-leaderboard-card-info'>
																	<div className='profile-desktop-leaderboard-card-info-image'>
																		<Img src={[user.picture,assets.default_profile_image]} alt={user.name} />
																	</div>
																	<div className='profile-desktop-leaderboard-card-info-text'>
																		<div className='profile-desktop-leaderboard-card-info-text-title'  style={user.hash_id === this.props.profile_info.hash_id ? {color: '#ec6b43'} : {}}>{user.slug ? '@' + user.slug : user.name}</div>
																		<div className='profile-desktop-leaderboard-card-info-text-subtitle'>{user.likes} songs liked</div>
																	</div>
																</div>
																<div className='profile-desktop-leaderboard-card-buttons'>
																	<div className='profile-desktop-leaderboard-card-buttons-details'>
																		<div className='profile-desktop-leaderboard-card-buttons-details-circle profile-desktop-leaderboard-card-buttons-like'>
																			<span className='material-icons'>favorite</span>
																		</div>
																		<div className='profile-desktop-leaderboard-card-buttons-details-number profile-desktop-leaderboard-card-buttons-details-number-like'>{abbreviateNumber(user.likes)}</div>
																	</div>
																	<div className='profile-desktop-leaderboard-card-buttons-details'>
																		<div className='profile-desktop-leaderboard-card-buttons-details-circle profile-desktop-leaderboard-card-buttons-dislike'>
																			<span className='material-icons'>clear</span>
																		</div>
																		<div className='profile-desktop-leaderboard-card-buttons-details-number profile-desktop-leaderboard-card-buttons-details-number-dislike'>{abbreviateNumber(user.dislikes)}</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
											</React.Fragment>
										</div>
									</div>
								}
								{likeminded_users.length > 0 &&
									<div className='profile-desktop-like-minded-section'>
										<div className='profile-desktop-section-title'>Like-minded</div>
										<div className='profile-desktop-like-minded-container'>
											{likeminded_users.map((user, i) => {
												let user_slug = user.slug.length > 0 ? user.slug : user.hash_id.substring(0,10);
												return(
													<Link to={'/profile/' + user_slug}  key={i} className='profile-desktop-like-minded-card-container' onClick={() => this.get_profile_info(user_slug)}>
														<div className='profile-desktop-like-minded-card' key={i}>
															<div className='profile-desktop-like-minded-card-header'>
																<Img
																	src={[user.header_picture, assets.profile_desktop_headerdefault]} alt=''
																	loader={
																		<ContentLoader 
																			width={'100%'}
																			height={100}
																			backgroundColor={'#D8D8D8'}
																			foregroundColor={'#b1afaf'}
																		>
																			<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={100} /> 
																		</ContentLoader>
																	}
																	className='profile-desktop-like-minded-card-header-image'
																/>
																<div className='profile-desktop-like-minded-card-header-opacity'></div>
															</div>
															<div className='profile-desktop-like-minded-card-info'>
																<Img
																	src={[user.picture, assets.default_profile_image]} alt=''
																	loader={
																		<ContentLoader 
																			width={'99px'}
																			height={'99px'}
																			backgroundColor={'#D8D8D8'}
																			foregroundColor={'#b1afaf'}
																		>
																			<circle cx='50%' cy='50%' r='50%' />
																		</ContentLoader>
																	}
																	className='profile-desktop-like-minded-card-info-image'
																/>
																<div className='profile-desktop-like-minded-card-info-title'>{user.first_name}</div>
																{user.slug &&
																	<div className='profile-desktop-like-minded-card-info-subtitle'>@{user.slug}</div>
																}
																<div className='profile-desktop-like-minded-card-info-description'>
																	Both like <span>{user.liked_artists[0]}</span>
																	{user.liked_artists.length - 1 > 0 &&
																		' + ' + (user.liked_artists.length - 1) + ' more'
																	}
																</div>
															</div>
														</div>
													</Link>
												);
											})}
										</div>
									</div>
								}
							</div>
						</div>
					</div>
					<Dialog
						open={this.state.open_share_overlay}
						onClose={() => this.setState({open_share_overlay: false})}
						className='app-desktop-dialog'
					>
						<div className='app-desktop-dialog-container'>
							<div className='app-desktop-dialog-image'>
								{album_image()}
							</div>
							<div className='app-desktop-dialog-info'>
								<div className='app-desktop-dialog-info-title'>{profile_info.first_name} {profile_info.last_name}</div>
								{profile_info.slug.length > 0 &&
									<div className='app-desktop-dialog-info-subtitle'>@{profile_info.slug}</div>
								}
							</div>
							<div className='app-desktop-dialog-buttons-container'>
								<Ripples>
									<FacebookShareButton url={window.location.href}>
										<Haptic intesity='light'>
											<div style={{backgroundColor: '#3b5998'}} className='app-desktop-dialog-button'>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-frontpage/assets/facebook_icon.png' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>SHARE ON FACEBOOK</div>
											</div>
										</Haptic>
									</FacebookShareButton>
								</Ripples>
								<Ripples>
									<TwitterShareButton url={window.location.href}>
										<Haptic intesity='light'>
											<div style={{backgroundColor: '#50aeea'}} className='app-desktop-dialog-button'>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/twitter_share_icon.png' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>SHARE ON TWITTER</div>
											</div>
										</Haptic>
									</TwitterShareButton>
								</Ripples>
								<Ripples>
									<WhatsappShareButton url={window.location.href}>
										<Haptic intesity='light'>
											<div style={{backgroundColor: '#3fb64d'}} className='app-desktop-dialog-button'>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/whatsapp_share_icon.png' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>SHARE ON WHATSAPP</div>
											</div>
										</Haptic>
									</WhatsappShareButton>
								</Ripples>
								<Ripples>
									<TelegramShareButton url={window.location.href}>
										<Haptic intesity='light'>
											<div style={{backgroundColor: '#20a0e1'}} className='app-desktop-dialog-button'>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/telegram_share_icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>SHARE ON TELEGRAM</div>
											</div>
										</Haptic>
									</TelegramShareButton>
								</Ripples>
								<Ripples>
									<VKShareButton url={window.location.href}>
										<Haptic intesity='light'>
											<div style={{backgroundColor: '#54769b'}} className='app-desktop-dialog-button'>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/vk_share_icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>SHARE ON VK</div>
											</div>
										</Haptic>
									</VKShareButton>
								</Ripples>
							</div>
						</div>
					</Dialog>
					<Snackbar
						open={this.state.snackbar_status}
						message={this.state.snackbar_message}
						autoHideDuration={3000}
						onClose={() => this.setState({'snackbar': false})}
					/>
				</div>
			);
		}
	}
}

DesktopProfilePage.propTypes = {
	spotify_stats: PropTypes.any,
	user_token: PropTypes.any,
	user: PropTypes.object,
	onClick: PropTypes.func
};

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_playing: state.GlobalReducer.is_playing,
		playing_song: state.GlobalReducer.playing_song,
		duration: state.GlobalReducer.duration,
		current_time: state.GlobalReducer.current_time,
		profile_info: state.GlobalReducer.profile_info,
		user: state.GlobalReducer.user,
		spotify_stats: state.GlobalReducer.spotify_stats,
		magroove_stats: state.GlobalReducer.magroove_stats,
		user_leaderboard: state.GlobalReducer.user_leaderboard,
		likeminded_users: state.GlobalReducer.likeminded_users,
		user_token: state.GlobalReducer.user_token,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopProfilePage);