import { store } from '@/store.js';
import { redirect } from '@routes/Routes.js';
import { request_buffer } from '@RequestBuffer';

import * as constants from '@constants';

import { Preferences } from '@capacitor/preferences';
import moment from 'moment';
import version from '@Version';

// Import controllers
import export_track from '@Export';
import interaction from '@Interaction';
import user from '@User';

// Import the event bus
import event_bus from '@Eventbus';

export function reset_store(){
	var action_to_return = {
		type: 'RESET_STORE',
	};
	store.dispatch(action_to_return);
}

export function update_undefined_props(){
	var action_to_return = {
		type: 'UPDATE_PROPS',
	};
	store.dispatch(action_to_return);
}

export function store_update(dict){
	var action_to_return = {
		type: 'UPDATE_STORE',
		dict: dict
	};
	store.dispatch(action_to_return);
}

export function store_set(key, value){
	var action_to_return = {
		type: 'STORE_SET',
		key: key,
		value: value
	};
	store.dispatch(action_to_return);
}

export function store_get(key){
	const state = store.getState();
	return state.GlobalReducer[key];
}

export function clear_all_overlays(){
	store_update({
		active_overlay: false,
		active_overlay_option: 'show_all_track',
		create_playlist: false,
		loading_overlay_display: false,
		loading_overlay_component: false,
		loading_overlay_text: false,
		loading_overlay_top_text: false,
		loading_overlay_action: false
	});
}

export function execute_post_login_action(is_new_member){
	const state = store.getState();
	const post_login_action = state.GlobalReducer.post_login_action;

	const page = is_new_member ? '/onboarding/' : '/';
	if (!post_login_action){
		// If there's no post login action to be called, just redirect to onboarding page
		store_set('loading_overlay_display', false);
		redirect(page);
		return;
	}

	// If the instruction has an action of type 'execute', then execute the function described
	if (post_login_action.action === 'execute') {
		switch (post_login_action.type) {
		case 'interaction':
			store_set('loading_overlay_display', false);
			interaction.update_remote_status(post_login_action.params.song, post_login_action.params.status, post_login_action.params.timestamp);
			redirect(post_login_action.params.page);
			return;
		default:
			store_set('loading_overlay_display', false);
			redirect(page);
			return;
		}
	}

	store_set('post_login_action', false);
}

export function execute_post_link_account_action(status){
	const state = store.getState();
	const post_link_account_action = state.GlobalReducer.post_link_account_action;

	if (!post_link_account_action) return;

	if (status === 'success'){
		// Update the state of the platform in the linked accounts
		const streaming_overlay = post_link_account_action.streaming_overlay;
		if (streaming_overlay){
			var linked_accounts = state.GlobalReducer.linked_accounts;
			linked_accounts.filter(element => element.platform !== streaming_overlay);
			linked_accounts.push({ platform: streaming_overlay, state: 'active' });
			store_set('linked_accounts', linked_accounts);
		}

		// This function checks for existing suggestions saved locally
		// and updates them if necessary
		user.get_suggestions();

		// Also check if an overlay should be displayed
		const redirect_overlay = post_link_account_action.redirect_overlay;
		if (redirect_overlay){
			store_set('active_overlay', 'track_options');
			store_set('active_overlay_option', redirect_overlay);
			store_set('redirect_overlay', false);

			// Get playlists if necessary;
			if (redirect_overlay === 'loading_playlists'){
				export_track.get_playlists(streaming_overlay);
			}
		}

		// If the instruction has an action of type 'execute', then execute the function described
		if (post_link_account_action.action === 'execute') {
			switch (post_link_account_action.type) {
			case 'accept_auto_recommendation':
				export_track.auto_recommendation('Accepted');
				break;
			default:
				break;
			}
		}
		// Ask user to review app on sync
		event_bus.emit('review-app', null, 'sync_activated');
	}
	else if (status === 'error'){
		store_set('snackbar_message', 'Sorry, we got an error...');
		store_set('snackbar_status', true);
	}

	store_set('link_account_loading', false);
	store_set('post_link_account_action', false);
}

export async function load_version_info(data){
	// Save in the local storage the API version the app should consume from
	// now on.
	await Preferences.set({ key: 'api_version', value: data.api_version});

	// Update version config in the store.
	store_set('version', data);

	const is_update_necessary = data.is_update_necessary;
	if (!is_update_necessary){
		store_set('version_overlay', false);
		return { status: 'success' };
	}

	/**
	 * Using the local storage, we will keep track of the successful
	 * and unsuccessful live updates we make. This will help us avoid
	 * requesting the user to update more than once if an error occurs
	 * and also prevent downloading versions that we can auto fallback
	 * to.
	 * @params: last_overlay (Date), pending (list), success (list), error (list)
	*/
	const info = JSON.parse((await Preferences.get({ key: 'version_info' })).value);
	const available_versions = data.app_version.map(object => object.version);
	if (!info){
		await Preferences.set({ key: 'version_info', value: JSON.stringify({
			last_invite_overlay: new Date(),
			pending: available_versions,
			success: [],
			error: [],
		})});
		store_set('version_overlay', is_update_necessary);
		return { status: 'success' };
	}

	const already_suggested = get_versions_already_suggested(info);
	const current_pending = [...available_versions].filter(item => !info.success.includes(item) && !info.error.includes(item));
	info.pending = current_pending;

	await Preferences.set({ key: 'version_info', value: JSON.stringify(info)});

	// If it's a suggestion update, we will wait for a couple of interactions
	// before displaying the overlay. So in case the is_update_necessary didn't
	// change, keep the version_overlay as it is and do nothing more.
	const suggestion_options = ['suggest', 'suggest_base'];
	if (
		suggestion_options.includes(store_get('version_overlay')) &&
		suggestion_options.includes(is_update_necessary)
	){
		return { status: 'success' };
	}

	// Make the final decision and set the value.
	const display_overlay = await should_display_version_overlay(available_versions, already_suggested, current_pending, is_update_necessary);
	if (display_overlay){
		store_set('version_overlay', is_update_necessary);
	} else {
		store_set('version_overlay', false);
	}

	return { status: 'success' };
}

export async function get_api_version(){
	const response = (await Preferences.get({ key: 'api_version' })).value || version.api_version;
	return response;
}

export async function get_base_version(){
	const response = (await Preferences.get({ key: 'base_version' })).value || version.base_version;
	return response;
}

export function finish_search_selection(entity_type, items){
	const user = Object.assign({}, store_get('user'));
	const section = 'top_' + entity_type + 's';
	user[section] = [...items];

	const showcase = user.showcase;
	for (var item_section of showcase){
		if (item_section.section === section){
			item_section.spotify_stats = false;
		}
	}

	store_set('user', user);

	if (store_get('location') !== 'edit-profile'){
		redirect('/edit-profile?tab=Showcase&section='+section);
	}

	const data_items = [];
	for (var item of items){
		const data = {};
		if (item.hash_id) {
			data.entity_hash_id = item.hash_id;
		}
		if (item.spotify_id){
			data.entity_source_id = item.spotify_id;
		}
		data_items.push(data);
	}

	request_buffer.auth_execute({
		type: 'post',
		url: constants.api_endpoint_top_entities_edit,
		data: {entity_type: entity_type, items: JSON.stringify(data_items)}
	}, true);
}

// ========================================================================================================================
// Create your auxiliary functions here.
// ========================================================================================================================
async function should_display_version_overlay(available_versions, already_suggested, current_pending, is_update_necessary){
	if (is_update_necessary === 'force_base'){
		return true;
	}
	// If its a suggestion to update the base version, we need to check if the user
	// already saw the invite because, in this case, we don't want to display again
	// in a short period of time.
	if (is_update_necessary === 'suggest_base'){
		const base_suggested_date = await Preferences.get({ key: 'base_suggested_date' });
		if (base_suggested_date.value && more_than_a_day_ago(base_suggested_date.value)){
			return false;
		}
		await Preferences.set({ key: 'base_suggested_date', value: new Date() });
		return true;
	}
	// ATTENTION: the condition order now is important, we don't want to display a live
	// update request in case there is no pending version in queue.
	if (current_pending.length === 0){
		return false;
	}
	// In case the current version was already in the pending array, it means that we already
	// suggested the update to the user and they declined. If the update continues to be a
	// suggestion, we can ignore it and not display the overlay.
	if (is_update_necessary === 'suggest'){
		return !already_suggested.includes(available_versions[0]);
	}
	return true;
}

function get_versions_already_suggested(info){
	// In case a version was already in the pending array, it means we already invited the
	// user to update in a recent period of time.
	// Return it blank if more than a day have passed since the last overlay.
	const versions = [...info.pending];
	if (more_than_a_day_ago(info.last_invite_overlay)){ 
		versions.length = 0;
	}
	return versions;
}

function more_than_a_day_ago(date){
	return moment(date).isBefore(moment(new Date()).subtract(1, 'days'));
}