import { request_buffer } from '@RequestBuffer';
import * as constants from '@constants';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';

// Import controllers
import storage from '@Storage';

// Import the event bus
import event_bus from '@Eventbus';

class SocialController {
	constructor(){
		// Social Activity
		this.activity_count = 0;
		this.activity_retries = 0;
		this.activity_hashes = [];

		this.loading_social_activity = false;
		this.loading_new_releases = false;
		this.loading_user_favorites = false;
		this.loading_people_you_may_know = false;

		this.get_activity = this.get_activity.bind(this);
		this.get_new_releases = this.get_new_releases.bind(this);
		this.get_user_favorites = this.get_user_favorites.bind(this);
		this.remove_activity = this.remove_activity.bind(this);

		// (Un)follow
		this.follow_user = this.follow_user.bind(this);
		this.follow_artist = this.follow_artist.bind(this);
		this.follow_playlist = this.follow_playlist.bind(this);
		this.get_followers = this.get_followers.bind(this);

		this.initialize = this.initialize.bind(this);
		event_bus.on('store-loaded', this.initialize);
	}

	initialize(){
		const current = store_get('social_activity');
		this.activity_hashes = current ? current.map(item => item.hash_id) : [];
		this.activity_count = current ? current.length : 0;

		this.get_user_polls(() => {}, true, true);
		this.get_followers(() => {}, true, true);
	}

	get_user_polls(callback = () => {}, own_profile = true, reload = false){
		const data = store_get('polls');
		if (own_profile && data.length > 0){
			callback(data);
			if (!reload) return;
		}

		if (own_profile){
			const value = storage.get('user_polls');
			if (value && value.length > 0){
				callback(value);
				store_set('polls', value);
				if (!reload) return;
			}
		}

		// Check for user until it's loaded
		const hash_id = own_profile ? store_get('user').hash_id : store_get('profile_info').hash_id;
		if (!hash_id){
			setTimeout(this.get_user_polls, 1000);
			return;
		}

		// Once loaded, fetch the user quiz
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_polls,
			data: { member_hash_id: hash_id },
			success: function(data){
				if (data.status === 'success'){
					const polls = [...data.data];

					if (own_profile){
						store_set('polls', polls);
						storage.set('user_polls', polls, 86400);
					}
					callback(polls);
				}
			}
		});
	}

	get_activity(reload = false, hashes = this.activity_hashes){
		if (this.loading_social_activity) return;
		if (reload){
			hashes = [];
			store_set('social_activity', []);
		}

		this.loading_social_activity = false;
		store_set('loading_social_activity', true);

		request_buffer.auth_execute({
			type: 'GET',
			data: { follower_hashes: JSON.stringify(hashes) },
			url: constants.api_endpoint_get_social_activity,
			success: function(res){
				const data = res.data;
				if (res.status === 'success'){
					const new_item = data.item;
					const social_activity = store_get('social_activity') || [];
					const duplicated = social_activity.find(item => item.member_info.hash_id === new_item.member_info.hash_id);
					if (!duplicated){
						this.activity_count += 1;
						store_set('social_activity', [...social_activity, new_item]);
					}

					this.activity_hashes = data.follower_hashes;

					if (this.activity_count < 3){
						this.get_activity(reload, data.follower_hashes);
					} else {
						store_set('loading_social_activity', false);
						this.loading_social_activity = false;
					}
				}
				else {
					store_set('loading_social_activity', false);
					this.loading_social_activity = false;
				}
			}.bind(this),
			error: function(){
				if (this.activity_retries < 3){
					this.activity_retries += 1;
					setTimeout(this.get_activity, 4000);
				} else {
					store_set('loading_social_activity', false);
					this.loading_social_activity = false;
				}
			}.bind(this)
		});
	}

	get_trending(){
		const value = storage.get('search_trending_v1');
		if (value && value.albums){
			store_set('trending_artists', value.artists);
			store_set('trending_albums', value.albums);
			store_set('trending_songs', value.songs);
			return;
		}

		request_buffer.auth_execute({
			type: 'GET',
			url: constants.api_endpoint_get_trending,
			success: function(res){
				if (res.status === 'success'){
					const data = res.data;
					store_set('trending_artists', data.artists);
					store_set('trending_albums', data.albums);
					store_set('trending_songs', data.songs);
					storage.set('search_trending_v1', data, 86400);
				}
			}
		});
	}

	get_new_releases(){
		const value = storage.get('hub_new_releases');
		if (value){
			store_set('new_releases', value);
			store_set('loading_new_releases', false);
			return;
		}

		if (this.loading_new_releases) return;
		store_set('loading_new_releases', true);

		request_buffer.auth_execute({
			type: 'GET',
			url: constants.api_endpoint_get_new_releases,
			success: function(res){
				if (res.status === 'success'){
					const data = res.data;
					store_set('new_releases', data);
					storage.set('hub_new_releases', data.slice(0,10), 86400);
				}
			},
			complete: function(){
				store_set('loading_new_releases', false);
				this.loading_new_releases = false;
			}.bind(this)
		});
	}

	get_user_favorites(){
		const value = storage.get('hub_user_favorites');
		if (value){
			store_set('user_favorites', value);
			store_set('loading_user_favorites', false);
			return;
		}

		if (this.loading_user_favorites) return;
		store_set('loading_user_favorites', true);

		request_buffer.auth_execute({
			type: 'GET',
			url: constants.api_endpoint_get_user_favorites,
			success: function(res){
				if (res.status === 'success'){
					const data = res.data;
					store_set('user_favorites', data);
					storage.set('hub_user_favorites', data.slice(0,10), 86400);
				}
			},
			complete: function(){
				store_set('loading_user_favorites', false);
				this.loading_user_favorites = false;
			}.bind(this)
		});
	}

	remove_activity(member_hash){
		const social_activity = [...store_get('social_activity')];
		const updated_activity = social_activity.filter(item => item.member_info.hash_id !== member_hash);
		store_set('social_activity', updated_activity);
		this.activity_count = updated_activity.length;
	}

	get_following(entity_type, callback, own_profile = true){
		const state_key = 'following_' + entity_type + 's';
		const data = store_get(state_key);
		if (own_profile && data && data.length > 0){
			const filtered = data.filter(item => (entity_type === 'artist' && item.is_following) || (entity_type === 'user' && item.followed));
			callback(filtered);
			return;
		}

		// Check for user until it's loaded
		const hash_id = own_profile ? store_get('user').hash_id : store_get('profile_info').hash_id;
		if (!hash_id) {
			setTimeout(() => this.get_following(entity_type, callback, own_profile), 1000);
			return;
		}

		request_buffer.auth_execute({
			type: 'get',
			url: constants.api_endpoint_get_following_entities,
			data: { entity_type: entity_type, user_profile_hash_id: hash_id },
			success: function(data){
				if (data.status === 'success'){
					if (own_profile) {
						store_set(state_key, data.following_entities);
					}
					callback(data.following_entities);
				}
			}
		});
	}

	get_followers(callback, own_profile = true, reload = false){
		const data = store_get('followers');
		if (own_profile && data && data.length > 0){
			callback(data);
			if (!reload) return;
		}

		// Check for user until it's loaded
		const hash_id = own_profile ? store_get('user').hash_id : store_get('profile_info').hash_id;
		if (!hash_id) {
			setTimeout(() => this.get_followers(callback, own_profile), 1000);
			return;
		}

		request_buffer.auth_execute({
			type: 'get',
			url: constants.api_endpoint_get_followers,
			data: { hash_id: hash_id },
			success: function(res){
				if (res.status === 'success'){
					if (own_profile) {
						store_set('followers', res.data);
					}
					callback(res.data);
				}
			}
		});
	}

	follow_user(member_id){
		return new Promise((resolve, reject) => {
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_follow_user_management,
				data: { user_id: member_id },
				success: function(data){
					resolve(data);
				},
				error: function(jqXHR, textStatus, errorThrown){
					reject(errorThrown);
				}
			});
		});
	}

	follow_artist(hash_id, action){
		return new Promise((resolve, reject) => {
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_follow_artist_management,
				data: { artist_hash_id: hash_id, action: action },
				success: function(data){
					resolve(data);
				},
				error: function(jqXHR, textStatus, errorThrown){
					reject(errorThrown);
				}
			});
		});
	}

	follow_playlist(hash_id, action){
		return new Promise((resolve, reject) => {
			request_buffer.auth_execute({
				type: 'post',
				url: constants.api_endpoint_follow_playlist_management,
				data: { playlist_hash_id: hash_id, action: action },
				success: function(data){
					resolve(data);
				},
				error: function(jqXHR, textStatus, errorThrown){
					reject(errorThrown);
				}
			});
		});
	}

	get_people_you_may_know(){
		if (this.loading_people_you_may_know) return;

		const data = store_get('people_you_may_know');
		if (data.length > 0){
			const new_data = data.filter(item => !item.followed);
			store_set('people_you_may_know', new_data);
			return;
		}

		this.loading_people_you_may_know = true;
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_people_you_may_know,
			success: function(data){
				if (data.status === 'success') {
					store_set('people_you_may_know', data.people_you_may_know);
				}
			},
			complete: function(){
				this.loading_people_you_may_know = false;
			}.bind(this)
		});
	}
}

const social = new SocialController();

export default social;

