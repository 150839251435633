import React, { Component } from 'react';
import { connect } from 'react-redux';
import UAParser from 'ua-parser-js';

// CSS Imports
import './styles/RedirectStores.css';

// Assets Imports
import { loading_gif } from '@Assets';

class RedirectStores extends Component {
	constructor(props){
		super(props);
		this.state = {};

		this.redirect_to_store = this.redirect_to_store.bind(this);
		this.redirect_to_store();
	}

	redirect_to_store() {
		var parser = new UAParser();
		var device_type = parser.getDevice().type;
		var platform = parser.getOS().name;
		
		if (device_type === 'mobile') {
			if (platform === 'iOS') {
				window.location.replace(this.props.version.ios);
			} else if (platform === 'Android') {
				window.location.replace(this.props.version.android);
			} else {
				window.location.replace('/');
			}
		} else {
			window.location.replace('/');
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="redirect-stores-container">
					<div className="redirect-stores-gif">
						<img src={loading_gif} alt="loading"/>
					</div>
					<div className="redirect-stores-text">Redirecting...</div>
				</div>
			</React.Fragment>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		version: state.GlobalReducer.version
	};
}

// Connect component to the store
export default connect(mapStateToProps)(RedirectStores);