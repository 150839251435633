import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import { redirect } from '@routes/Routes';

// In-app purchase
import { InAppPurchase2 as store } from '@ionic-native/in-app-purchase-2';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

// Constant Imports
import * as constants from '@constants';

// Import controllers
import app_analytics from '@Analytics';
import user from '@User';

function display_snackbar(text){
	store_set('snackbar_message', text);
	store_set('snackbar_status', true);
}

function display_loading(text = 'Loading...'){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_top_text', false);
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

class SubscriptionController{
	constructor(){
		this.action = '';
		this.feature = '';
		this.current_plan = '';

		this.premium_action_overlay = false;
		this.order_in_progress = false;
		this.renewal = false;

		this.already_registered = [];

		this.initialize = this.initialize.bind(this);
		this.require = this.require.bind(this);
		this.invite = this.invite.bind(this);
		this.order = this.order.bind(this);
		this.register = this.register.bind(this);
		this.response = this.response.bind(this);
		this.restore = this.restore.bind(this);
		this.fetch = this.fetch.bind(this);
		this.trigger_analytics_events = this.trigger_analytics_events.bind(this);
		this.save_action = this.save_action.bind(this);

		event_bus.on('store-loaded', this.fetch);
	}

	get(id = this.retrieve_premium_subs_id()){
		// Once the product is registered and the store refreshed we can use store.get() to retrieve
		// an IAPProduct object. The platforms' server were be contacted to load informations about
		// the registered products: human readable title and description, price, etc.
		return store.get(id);
	}

	retrieve_premium_subs_id(){
		if (Capacitor.getPlatform() === 'android'){
			return store_get('premium_subscription_ID');
		}
		return store_get('premium_subscription_ID_ios');
	}

	initialize(){
		const sub_ID = this.retrieve_premium_subs_id();
		if (!user.is_logged()) return;

		if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
			if (constants.debug){
				store.verbosity = store.DEBUG;
			}

			// Let's set the member hash to the optional string that is uniquely associated with
			// the user's account in our app. This value can be used to link a purchase with a
			// user on a backend server.
			var user_info = store_get('user');
			store.applicationUsername = user_info.hash_id || ''; // App Store appAccountToken

			// To get the most up-to-date information about purchases (in case a purchase have been
			// canceled, or a subscription renewed), we implement server side receipt validation.
			// This also protects us against fake "purchases", made by some users using "free in-app
			// purchase" apps on their devices. Besides that, for subscription, we MUST implement
			// remote receipt validation.
			store.validator = function(product, callback) {
				if (product.id === 'com.magroove.magrooveapp'){
					callback(true, product.transaction); return;
				}
				if (this.order_in_progress && !this.renewal){
					display_loading('Unlocking awesome features...');
				}
				request_buffer.execute({
					type: 'POST',
					url: constants.api_endpoint_subscription_verify,
					data: { data: JSON.stringify(product) },
					success: function(data){
						if (data.status === 'success'){
							callback(true, product.transaction);
						}
						else if (data.status === 'error'){
							callback(false, 'Impossible to proceed with validation');
						}
					}
				});
			}.bind(this);

			// Register subscription - the store needs to know the type and identifiers of our products
			// before we can use them in our code.
			const subscription_codes = store_get('subscription_codes')[Capacitor.getPlatform()];
			
			// If for some reason this list if empty, we must still register a product
			if (!subscription_codes.length) {
				this.register(sub_ID);
			}

			// We subscribe all codes in case user logs out and in to new account with different product type/id
			for (let product_ID of subscription_codes) { // eslint-disable-line
				this.register(product_ID);
			}

			// Register event handlers for the specific product
			store.when('subscription')
				.approved(p => p.verify())
				.verified(p => { p.finish(); })
				.cancelled(() => { this.order_in_progress = false; })
				.owned(() => {
					/* Success! User now own Premium Subscription */
					this.response('success');
				});

			// User closed the native purchase dialog
			store.when('subscription').cancelled(() => { store_set('loading_overlay_display', false); });

			// Track all store errors
			store.error(() => { this.response('error', 'Please, try again...'); });

			// Get the real product information
			store.ready(() => { /* Store ready! (store.products) */ });

			store.refresh();
		}
	}

	register(sub_ID = this.retrieve_premium_subs_id()){
		if (this.already_registered.includes(sub_ID)) return;

		store.register({
			id: sub_ID,
			type: store.PAID_SUBSCRIPTION
		});

		store.register({
			id: sub_ID + '_yearly',
			type: store.PAID_SUBSCRIPTION
		});

		this.already_registered.push(sub_ID);
	}

	order(plan = 'monthly', data = false){
		this.current_plan = plan;
		this.order_in_progress = true;

		// For strategic reasons, we want to know which overlay action was triggered to make user subscribe for premium
		this.save_action(this.premium_action_overlay);

		display_loading();
		if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
			if (!store.getApplicationUsername()){
				var user_info = store_get('user');
				store.applicationUsername = user_info.hash_id;
			}

			// Purchases are initiated using the store.order("some_product_id") method.
			// The store will manage the internal purchase flow. It'll end:
			// with an approved event. The product enters the APPROVED state.
			// with a cancelled event. The product gets back to the VALID state.
			// with an error event. The product gets back to the VALID state.
			let sub_ID = this.retrieve_premium_subs_id();
			sub_ID = plan === 'monthly' ? sub_ID : sub_ID + '_yearly';

			const product = this.get(sub_ID);
			if (!product.canPurchase){ this.response('error', 'We were unable to proceed with your purchase. Please, try again...'); }

			store.order(sub_ID)
				.then(
					() => { /* Processing Purchase */ }, 
					() => { this.response('error', 'Please, try again...'); }
				);
		}
		else {
			return new Promise((resolve) => {
				request_buffer.auth_execute({
					type: 'POST',
					url: constants.api_endpoint_subscription_card_payment,
					data: { data_dict: JSON.stringify(data) },
					success: function(response){
						if (response.status === 'requires_action'){
							resolve(response);
						}
						else {
							this.response(response.status, 'There\'s been a problem with your card. Please, check your card information or try another card.');
						}
					}.bind(this),
					error: function(){
						this.response('error');
					}.bind(this)
				});
			});
		}
	}

	handle_card_action(pi_id, data){
		request_buffer.auth_execute({
			type: 'POST',
			url: constants.api_endpoint_subscription_confirm_payment,
			data: {
				payment_intent_id: pi_id,
				data_dict: JSON.stringify(data)
			},
			success: function(data){
				if (data.status === 'success'){
					this.response('success');
				}
				else{
					this.response('error');
				}
			}.bind(this),
			error: function(){
				this.response('error', 'Sorry, an error occurred while trying to confirm your payment. Please, try again...');
			}.bind(this)
		});
	}

	require(icon, description, feature, title = 'This action requires Premium Membership'){
		// An overlay will be displayed with info about the feature the user is trying to use
		// without the Premium Membership. Pass a nice icon and text to tell them what they
		// will be able to do. OPTIONAL: You may also pass a title to substitute our default
		// message
		this.renewal = false;
		this.action = '';
		this.feature = feature;

		// Get the action that make premium overlay be displayed to user
		// For example could be after he clicked to sync songs, or after he clicked in header or settings button
		this.premium_action_overlay = {
			action: feature,
			title: title,
			subtitle: description
		};

		event_bus.emit('require-premium', null, icon, description, title, feature);
	}

	invite(title = '', subtitle = '', action = 'user_click'){
		// Final check in case user just bought premium and app hasn't fully updated yet
		if (this.is_valid()) {
			store_set('is_member_premium', true);
			return;
		}
		// An overlay will be displayed with info about all the features the user is missing
		// without the Premium Membership. OPTIONAL: You may pass a title and subtitle to
		// substitute our default text.
		this.renewal = false;
		this.action = action;
		this.feature = '';

		// Get the action that make premium overlay be displayed to user
		// For example could be after he clicked to sync songs, or after he clicked in header or settings button
		this.premium_action_overlay = {
			action: action,
			title: title,
			subtitle: subtitle
		};

		event_bus.emit('invite-premium', null, title, subtitle, action);
	}

	is_valid(expires_at = store_get('premium_subscription_expires_at')){
		// Checks if the user has an active premium subscription:
		// - false if the user never had an active subscription;
		// - true if it has a subscription that never ends (especial cases like us for example);
		//  - a date indicating the time where the latest subscription ends. (Note that this date
		// could be in the past and in this case a user is no longer under an active subscription)
		if (typeof expires_at === 'string'){
			const now = moment(new Date());
			expires_at = moment(expires_at);
			return expires_at > now;
		}
		return expires_at;
	}

	response(status, text = false){
		// An overlay will be displayed with Feedback to user
		// For example when subscription purchase is successful or unsuccessful
		// Parameters:
		// text = optional, could explain why purchase was unsuccessful
		// status = the confirmation if the action was successful or not (Example: success or error)
		if (this.order_in_progress){
			this.order_in_progress = false;

			// Track a conversion to premium - store as well what exactly started the conversion (which feature or action)
			if (status === 'success'){
				const amount = store_get('premium_' + this.current_plan + '_subscription_price');
				app_analytics.log_event('Purchase', {amount: amount, currency: 'USD'});

				// Let's trigger events for specific platforms and plans
				this.trigger_analytics_events();
			}

			store_set('loading_overlay_display', false);
			if (!this.renewal){
				event_bus.emit('response-premium', null, status, text);
			}
			else if (status === 'success'){
				let subscription = Object.assign({}, store_get('member_subscription'));
				subscription.internal_state = 'active';
				subscription.payment_method = 'in_app_' + Capacitor.getPlatform();
				store_set('member_subscription', subscription);
				store_set('is_member_premium', true);
			}
			else {
				display_snackbar('Sorry, something went wrong...');
			}
		}
	}

	cancel(){
		display_loading('Canceling subscription...');

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'app_store_subscription_cancel',
			event_source_url: window.location.pathname,
			custom_params: {},
			device_info: device_info,
			member_hash: user.hash_id
		};

		request_buffer.auth_execute({
			type: 'POST',
			data: {
				event_details: JSON.stringify(event_details)
			},
			url: constants.api_endpoint_subscription_cancel,
			success: function(data){
				if (data.status === 'success'){
					let subscription = Object.assign({}, store_get('member_subscription'));
					subscription.internal_state = 'canceled';
					subscription.end_of_billing = data.data;
					store_set('member_subscription', subscription);
					store_set('is_member_premium', false);

					store.update();
				}
				else {
					display_snackbar('Sorry, something went wrong...');
				}
			},
			error: function(){
				display_snackbar('Sorry, we got an error. Please, try again...');
			},
			complete: function(){
				store_set('loading_overlay_text', 'Loading...');
				store_set('loading_overlay_display', false);
			}
		});
	}

	renew(frequency, payment_method){
		this.renewal = true;
		this.current_plan = frequency;

		this.premium_action_overlay = {
			action: 'renew',
			title: '',
			subtitle: ''
		};

		if (Capacitor.getPlatform() === 'web' && payment_method !== 'credit_card'){
			// Display credit card form
			event_bus.emit('order-premium', null, frequency);
			return;
		}
		if (Capacitor.getPlatform() !== 'web' && payment_method !== 'credit_card'){
			this.order(frequency);
			return;
		}

		/* Only for web payments */
		display_loading('Restarting subscription...');

		let device_info = store_get('device_info');
		let user = store_get('user');
		let event_details = {
			event_name: 'app_store_subscription_renew',
			event_source_url: window.location.pathname,
			custom_params: {},
			device_info: device_info,
			member_hash: user.hash_id
		};

		request_buffer.auth_execute({
			type: 'POST',
			data: {
				event_details: JSON.stringify(event_details)
			},
			url: constants.api_endpoint_subscription_renew,
			success: function(data){
				if (data.status === 'success'){
					let subscription = Object.assign({}, store_get('member_subscription'));
					subscription.internal_state = 'active';
					store_set('member_subscription', subscription);
				}
				else {
					display_snackbar('Sorry, something went wrong...');
				}
			},
			error: function(){
				display_snackbar('Sorry, we got an error. Please, try again...');
			},
			complete: function(){
				store_set('loading_overlay_text', 'Loading...');
				store_set('loading_overlay_display', false);
			}
		});
	}

	fetch(){
		if (user.is_logged()) {
			request_buffer.auth_execute({
				type: 'GET',
				url: constants.api_endpoint_subscription_get_info,
				success: function(data){
					if (data.status === 'success' && user.is_logged()){
						store_set('member_subscription', data.data);
						const is_member_premium = this.is_valid();
						store_set('is_member_premium', is_member_premium);
					}
				}.bind(this)
			});

			store.update();
		}
	}

	can_restore(){
		for (var product of store.products){
			if (product.owned){
				return true;
			}
		}
		return false;
	}

	restore(){
		store.refresh();

		const products = store.products.sort((a,b) => b.owned - a.owned);
		const subscription = products.find(product => product.transaction);
		if (!subscription){
			display_snackbar('No subscriptions found...');
			store_set('loading_overlay_display', false);
			return;
		}

		display_loading('Restoring purchases...');
		request_buffer.auth_execute({
			type: 'POST',
			data: { transaction_id: subscription.transaction.id, receipt_data: subscription.transaction.appStoreReceipt },
			url: constants.api_endpoint_subscription_restore,
			success: function(data){
				if (data.status === 'success'){
					event_bus.emit('back-button-premium');
					display_snackbar('Restored with success!');
					redirect('/subscriptions/');
				}
				else if (data.message){
					display_snackbar(data.message);
				}
				else {
					display_snackbar('Sorry, something went wrong... We were unable to restore your purchase. Please, contact us.');
				}
			},
			complete: function(){
				store_set('loading_overlay_display', false);
				store_set('loading_overlay_text', 'Loading...');
			}
		});
	}

	trigger_analytics_events(){
		const platform = Capacitor.getPlatform();
		const plan = this.current_plan === 'monthly' ? 'monthly' : 'annual';

		app_analytics.log_event('in_app_purchase_all_' + plan);
		app_analytics.log_event('in_app_purchase_' + platform);
		app_analytics.log_event('in_app_purchase_' + platform + '_' + plan);
		if (platform !== 'web'){
			app_analytics.log_event('in_app_purchase_android_and_ios');
			app_analytics.log_event('in_app_purchase_android_and_ios_' + plan);
		}
	}

	// This function exists just to store information about the action that triggered the premium overlay and make user subscribe to premium
	save_action(data_dict){
		// For the specific 'playlists_sync' action, just make sure to get only text
		// The data sent is an object format that was originated from html
		if (data_dict['action'] === 'playlists_sync'){
			data_dict['subtitle'] = 'Subscribing now grants you automatically sync to your selected playlists';
		}

		const data = {
			action: data_dict['action'],
			title: data_dict['title'],
			subtitle: data_dict['subtitle']
		};

		request_buffer.auth_execute({
			type: 'POST',
			url: constants.api_endpoint_save_premium_action,
			data: { data: JSON.stringify(data) }
		});
	}
}

const subscription = new SubscriptionController();

export default subscription;