import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import Lottie from 'react-lottie-player';

// CSS Imports
import './styles/Overlay.css';

// Assets Imports
import * as assets from '@Assets';
import circles_animations_background from '../assets/circles_animations_background.js';

// Constant Imports
import * as constants from '@constants';

// Components and Views Imports
import posed from 'react-pose';
import Haptic from '@components/haptic.js';
import ExportTip from '@components/ExportTip.js';
import Ripples from 'react-ripples';
import { Textfield, Button, TextInput } from '@magroove/magroove-ui';
import { Swipeable } from 'react-swipeable';
import UploadImage from '@components/UploadImage.js';
import ContentLoader from '@components/ContentLoader.js';

// Overlays
import PlaylistOverlay from '@components/PlaylistOverlay.js';
import ItemOverlay from '@components/ItemOverlay.js';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Import the controllers
import user from '@User';
import queue from '@Queue';
import player from '@Player';
import list from '@List';
import export_track from'@Export';
import subscription from '@Subscription';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';
import { CircularProgress } from '@material-ui/core';
import { Review } from '@components/Review.js';

// Import the event bus
import event_bus from '@Eventbus';

// ========================================================================================================================================
// Create your functions here ==============================================================================================================
// ========================================================================================================================================
export function display_loading_overlay(text = 'Loading...', top_text = false){
	store_set('loading_overlay_component', 'circular-progress');
	store_set('loading_overlay_text', text);
	store_set('loading_overlay_top_text', top_text);
	store_set('loading_overlay_action', []);
	store_set('loading_overlay_display', true);
}

export function load_recommendations(entity_info, seed_type){
	const title = (seed_type === 'artist' || seed_type === 'playlist') ? entity_info.name : entity_info.title;
	display_loading_overlay(title, 'Now searching similar to:');
	queue.get_recommendations(seed_type === 'playlist' ? entity_info.hash_id : entity_info.spotify_id, seed_type, false, true);
	store_set('seed', entity_info);
}

function display_snackbar(message){
	store_set('snackbar_message', message);
	store_set('snackbar_status', true);
}

function convert_seconds_to_minutes(seconds) {
	let minutes = Math.floor(seconds / 60);
	let seconds_left = seconds - minutes * 60;
	return minutes + '.' + seconds_left.toFixed(0);
}

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const shareCapacitor = async (url, text, dialogTitle = 'Share with your friends') => {
	await Share.share({
		title: 'Let\'s find new songs!',
		text: text,
		url: url,
		dialogTitle: dialogTitle
	});
};


// ========================================================================================================================================
// Create your components here ============================================================================================================
// ========================================================================================================================================
class ImageLoader extends Component {
	render(){
		const { image_url, style, className, onError } = this.props;

		return(
			<div className='card-image-loader'>
				<ContentLoader style={style} />
				<img src={image_url} className={className} style={style} alt='' onError={(ev) => ev.target.src = onError} />
			</div>
		);
	}
}

function CircularDeterminate(props) {
	const track_is_playing =  props.playing_song.spotify_id === props.song_info.spotify_id;
	return (
		<div className='overlay-player-progress'>
			<CircularProgress variant='determinate' value={track_is_playing ? ((props.duration - props.current_time)*100 / props.duration) : 100} />
			<div className='overlay-track-play-button-container'>
				<div
					className='overlay-track-play-button'
					onClick={ track_is_playing ? ()=> player.toggle() : () => player.load(0, props.song_info)}
				>
					{(!track_is_playing || (track_is_playing && !props.is_playing)) &&
						<span className='material-icons'>play_arrow</span>
					}
					{track_is_playing && props.is_playing &&
						<span className='material-icons'>pause</span>
					}
				</div>
			</div>
		</div>
	);
}

const PosedConfirmOverlay = posed.div({
	full: {
		height: () => { return window.innerWidth > 300 ? 145 : 102; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

class ConfirmationOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {};

		this.remove_songs = this.remove_songs.bind(this);
	}

	remove_songs(){
		if (this.props.select_mode){
			var tracks = this.props.tracks_selected;
			for (var i=0; i < tracks.length; i++){
				list.remove(tracks[i]);
			}
			event_bus.emit('update-likes-list');
		}
	}

	render(){
		const { active_overlay, tracks_selected } = this.props;

		return (
			<React.Fragment>
				<PosedConfirmOverlay
					pose={active_overlay === 'unlink_spotify_account' || active_overlay === 'unlink_deezer_account' ? 'full' : 'hidden'}
					className='overlay-main'
				>
					<div className='overlay-confirm-content-unsync'>
						<div className='overlay-confirm-text'>Are you sure you want to unsync your {active_overlay === 'unlink_spotify_account' ? 'Spotify' : 'Deezer'} account?</div>
						<div className='overlay-confirm-buttons'>
							<Ripples>
								<Haptic intesity='light'>
									<div
										className='overlay-confirm-yes-button'
										onClick={() => active_overlay === 'unlink_spotify_account' ? export_track.unlink_account('spotify') : export_track.unlink_account('deezer')}
									>
										YES
									</div>
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div
										className='overlay-confirm-no-button'
										onClick={() => store_set('active_overlay', false)}
									>
										NO
									</div>
								</Haptic>
							</Ripples>
						</div>
					</div>
				</PosedConfirmOverlay>
				<PosedConfirmOverlay
					pose={active_overlay === 'logout' ? 'full' : 'hidden'}
					className='overlay-main'
				>
					<div className='overlay-confirm-content-logout'>
						<div className='overlay-confirm-text'>Are you sure you want to log out?</div>
						<div className='overlay-confirm-buttons'>
							<Ripples>
								<Haptic intesity='light'>
									<Link to='/login/'>
										<div className='overlay-confirm-yes-button' onClick={() => user.logout()}>YES</div>
									</Link>
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-confirm-no-button' onClick={() => store_set('active_overlay', false)}>NO</div>
								</Haptic>
							</Ripples>
						</div>
					</div>
				</PosedConfirmOverlay>
				<PosedConfirmOverlay
					pose={active_overlay === 'remove_songs' ? 'full' : 'hidden'}
					className='overlay-main'
				>
					<div className='overlay-confirm-content-logout'>
						<div className='overlay-confirm-text'>
							Remove {tracks_selected.length + (tracks_selected.length > 1 ? ' songs' : ' song')} from your list?
						</div>
						<div className='overlay-confirm-buttons'>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-confirm-yes-button' onClick={this.remove_songs}>YES</div>
								</Haptic>
							</Ripples>
							<Ripples>
								<Haptic intesity='light'>
									<div className='overlay-confirm-no-button' onClick={() => store_set('active_overlay', false)}>NO</div>
								</Haptic>
							</Ripples>
						</div>
					</div>
				</PosedConfirmOverlay>
			</React.Fragment>
		);
	}
}

const PosedMusicalReferenceOverlay = posed.div({
	full: {
		height: () => { return 165; },
		borderTop: 'solid 4px #707070'
	},
	hidden: {
		height: 0,
		borderTop: 'solid 0px #707070'
	}
});

class MusicalReferenceOverlay extends Component {
	render(){
		const { active_overlay, get_song_links, song_info } = this.props;
		const artists = song_info.artist.name ? song_info.artist.name : Array.from(song_info.artist, artist => artist.name).join(', ');
		const image_link = song_info.album ? (song_info.album.images ? song_info.album.images.large : '') : '';
		return(
			<PosedMusicalReferenceOverlay
				pose={active_overlay === 'musical_reference' ? 'full' : 'hidden'}
				className='overlay-main'
			>
				<div className='overlay-music-connections'>
					<div className='overlay-music-connections-info' onClick={(e) => e.stopPropagation()}>
						<div className='overlay-music-connections-info-details'>
							<img
								alt='Release Album'
								src={image_link}
								onError={(ev) => ev.target.src = assets.default_album_image}
								className='overlay-music-connections-cover'
							/>
							<div className='overlay-music-connections-info-text'>
								<div className='overlay-music-connections-title'>{song_info.title}</div>
								<div className='overlay-music-connections-subtitle'>{('title' || song_info.album.name || song_info.album.title)} - {artists}</div>
							</div>
						</div>
						<div className='overlay-music-connections-info-player'>
							<CircularDeterminate {...this.props} />
						</div>
					</div>
					{/* The layout below is commented because the functionalities will be developed later */}
					{/* <div className='overlay-music-connections-info-items-container'>
						<div className='overlay-music-connections-info-item' onClick={(e) => e.stopPropagation()}>
							<div className='overlay-music-connections-info-item-left'>
								<img
									alt='Release Album'
									src={song_info.album.images.small}
									onError={(ev) => ev.target.src = assets.default_album_image}
									className='overlay-music-connections-cover-item'
								/>
								<div className='overlay-music-connections-info-text-item'>
									<div className='overlay-music-connections-title-item'>{song_info.title}</div>
									<div className='overlay-music-connections-subtitle-item'>Album</div>
								</div>
							</div>
							<div className='material-icons'>more_vert</div>
						</div>
						<div className='overlay-music-connections-info-item' onClick={(e) => e.stopPropagation()}>
							<div className='overlay-music-connections-info-item-left'>
								<img
									alt='Release Album'
									src={song_info.album.images.small}
									onError={(ev) => ev.target.src = assets.default_album_image}
									className='overlay-music-connections-cover-item'
								/>
								<div className='overlay-music-connections-info-text-item'>
									<div className='overlay-music-connections-title-item'>{song_info.title}</div>
									<div className='overlay-music-connections-subtitle-item'>Artist</div>
								</div>
							</div>
							<div className='material-icons'>more_vert</div>
						</div>
					</div> */}
					<div className='overlay-music-connections-buttons-container' onClick={(e) => e.stopPropagation()}>
						<div className='overlay-music-connections-button' onClick={() => load_recommendations(song_info, 'song')}>
							<div className='overlay-music-connections-button-text'>Find Similar</div>
							<div className='overlay-music-connections-button-icon'>
								<img src={assets.find_similar_orange_icon} alt='find-similar-icon'/>
							</div>
						</div>
						<div className='overlay-music-connections-button' onClick={() => get_song_links()}>
							<div className='overlay-music-connections-button-text'>Full Song</div>
							<div className='material-icons'>open_in_new</div>
						</div>
					</div>
				</div>
			</PosedMusicalReferenceOverlay>
		);
	}
}

class NoMoreRecommendationsOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			search_seed: ''
		};

		this.go_search_seed = React.createRef();
		this.check_empty_search = this.check_empty_search.bind(this);
	}

	check_empty_search(){
		if (this.state.search_seed.length === 0){
			display_snackbar('Please, provide a search term');
		} else {
			this.go_search_seed.current.click();
		}
	}

	render(){
		const { search_seed } = this.state;
		const { current_streak, current_streak_total_songs, current_streak_total_time, safe_area_top, seed } = this.props;

		return (
			<div className='overlay-search-seed' style={{top: safe_area_top}}>
				<div className='overlay-header-end-recommendations-left-side'>
					<div className='overlay-header-end-recommendations-left-side-info'>
						<img src={assets.seed_icon} alt='Seed icon' />
						<div className='overlay-header-end-recommendations-seed'>
							{seed && !(seed.type === 'song' || seed.type === 'album') &&
								<React.Fragment>
									<div className='overlay-header-end-recommendations-title'>{seed.name}</div>
									<div className='overlay-header-end-recommendations-info'>{seed.type.length > 0 ? capitalize(seed.type) : ''}</div>
								</React.Fragment>
							}
							{seed && (seed.type === 'song' || seed.type === 'album') &&
								<React.Fragment>
									<div className='overlay-header-end-recommendations-title'>{seed.title}</div>
									<div className='overlay-header-end-recommendations-info'>{capitalize(seed.type)} - {seed.artist.name}</div>
								</React.Fragment>
							}
						</div>
					</div>
				</div>
				<div className='overlay-search-seed-main'>
					<div className='overlay-search-seed-main-background'>
						<Lottie
							loop={true}
							play={true}
							animationData={circles_animations_background}
							direction={1}
						/>
					</div>
					<div className='overlay-search-seed-title'>Wow!</div>
					<div className='overlay-search-seed-description'>You&apos;ve listened to all recommendations for this seed!</div>
					<div className='overlay-search-seed-cards-container'>
						<div className='overlay-search-seed-card'>
							<div className='overlay-search-seed-card-number'>{current_streak}</div>
							<div className='overlay-search-seed-card-text'>Current Streak</div>
						</div>
						<div className='overlay-search-seed-card'>
							<div className='overlay-search-seed-card-number'>{current_streak_total_songs}</div>
							<div className='overlay-search-seed-card-text'>Songs Discovered</div>
						</div>
						<div className='overlay-search-seed-card'>
							<div className='overlay-search-seed-card-number'>{convert_seconds_to_minutes(current_streak_total_time)}</div>
							<div className='overlay-search-seed-card-text'>Minutes Listened</div>
						</div>
					</div>
					<div className='overlay-search-seed-caption'>Let&apos;s find more awesome music!</div>
					<div className='overlay-search-seed-input-container'>
						<Haptic>
							<div className='overlay-search-seed-textfield'>
								<input
									placeholder='Give us a song, album or artist..'
									value={search_seed} 
									type='text' 
									onChange={(e) => this.setState({search_seed: e.target.value})} 
									onKeyUp={(event) => event.keyCode === 13 ? this.check_empty_search() : null}
								/>
								{search_seed.length > 0 &&
									<Link 
										ref={this.go_search_seed} 
										to={'/search?q=' + this.state.search_seed} 
										className='material-icons'
										onClick={() => this.setState({search_seed: ''})}
									>
										keyboard_backspace
									</Link>
								}
								{search_seed.length === 0 &&
									<Link onClick={this.check_empty_search} className='material-icons'>keyboard_backspace</Link>
								}
							</div>
						</Haptic>
					</div>
				</div>
			</div>
		);
	}
}

class CreatePlaylistOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			step: 1,
			name: '',
			description: '',
			show_editor: false,
			image_hash: '',
			image_link: ''
		};

		this.handle_next_step = this.handle_next_step.bind(this);
		this.handle_new_text = this.handle_new_text.bind(this);

		this.on_new_image_success = this.on_new_image_success.bind(this);

		this.create_external_playlist = this.create_external_playlist.bind(this);
		this.create_internal_playlist = this.create_internal_playlist.bind(this);
	}

	// Object changes
	handle_next_step(type='next') {
		if (!this.state.name) {
			display_snackbar('Please, provide a playlist name');
			return;
		}

		if (type === 'skip') {
			if (this.state.step === 2) {
				this.setState({image_link: '', image_hash: ''});
			}
			if (this.state.step === 3) {
				this.setState({description: ''});
			}
		}

		let step = this.state.step + 1;
		if (step > 3) {
			this.create_internal_playlist();
		} else {
			this.setState({ step: step });
		}
	}

	handle_new_text(value, type) {
		const { name, description } = this.state;
		if (name.length > 35 && value.length > 35) {
			return;
		}
		if (description.length > 50 && value.length > 50) {
			return;
		}
		this.setState({[type]: value});
	}

	on_new_image_success(data){
		const { file_hash_id, image_link } = data;
		setTimeout(() => {
			store_set('loading_overlay_display', false);
			this.setState({
				image_hash: file_hash_id,
				image_link: image_link,
				show_editor: true
			});
		}, 1000);
	}

	// Playlist creation
	create_external_playlist(name){
		var platform = this.props.streaming_overlay;
		if (name.length === 0){
			display_snackbar('Please, provide a playlist name');
			return;
		}
		export_track.new_playlist(platform, name);
	}

	create_internal_playlist() {
		display_loading_overlay();
		const playlist_data = {
			'title': this.state.name,
			'description': this.state.description,
			'image_hash': this.state.image_hash
		};
		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_create_internal_playlist,
			data: { playlist_data: JSON.stringify(playlist_data) },
			success: function(data){
				if (data.status === 'success'){
					let playlists = [...this.props.playlists];
					playlists.push(data.data);
					store_set('playlists', playlists);
				}
			}.bind(this),
			complete: function(){
				store_set('create_playlist', false);
				store_set('loading_overlay_display', false);
			}
		});
	}

	render(){
		const { step, image_link } = this.state;
		const playlist_overlay_option = this.props.playlist_overlay_option;
		return(
			<div className='overlay-create-new-playlist' onClick={(e) => e.stopPropagation()} style={{ paddingTop: this.props.safe_area_top }}>
				<div className='header-create-new-playlist'>
					<span className='material-icons' onClick={() => store_set('create_playlist', false)}>keyboard_backspace</span>
					<div className='header-create-new-playlist-text'>Create New Playlist</div>
				</div>
				{step === 1 &&
					<TextInput
						theme='dark'
						label='What is the new playlist name?'
						value={this.state.name}
						onChange={(value) => this.handle_new_text(value, 'name')}
					/>
				}
				{step === 2 &&
					<div className='overlay-create-new-playlist-section'>
						<div className='overlay-create-new-playlist-title'>Choose a picture for your new Playlist.</div>
						{image_link.length > 0 ?
							<div className='overlay-playlist-prewiew-container'>
								<img className='overlay-playlist-prewiew-image' src={image_link} alt='' />
								{this.state.show_editor &&
									<UploadImage
										type='playlist'
										show_icon={true}
										overlay={true}
										prefix='playlist_pics/'
										onSuccess={this.on_new_image_success}
									/>
								}
							</div>
							:
							<UploadImage
								type='playlist'
								width={144}
								height={144}
								show_dropzone={true}
								text='Upload a Picture'
								overlay={true}
								prefix='playlist_pics/'
								onSuccess={this.on_new_image_success}
							/>
						}
					</div>
				}
				{step === 3 &&
					<TextInput
						theme='dark'
						label='Write a description for your playlist.'
						value={this.state.description}
						onChange={(value) => this.handle_new_text(value, 'description')}
					/>
				}
				{playlist_overlay_option === 'external' ?
					<div className='footer-create-new-playlist'>
						<div className='footer-create-new-playlist-simple-item' onClick={() => store_set('create_playlist', false)}>Back</div>
						<div className='footer-create-new-playlist-simple-item' onClick={() => this.create_external_playlist(this.state.name)}>Create</div>
					</div>
					:
					<div className='footer-create-new-playlist' style={{padding: 0}}>
						<div className='footer-create-new-playlist-item' style={{ visibility: step === 1 ? 'hidden' : 'visible', paddingRight: 46 }}>
							<div className='footer-create-new-playlist-item-skip' onClick={() => this.handle_next_step('skip')}>Skip</div>
						</div>
						<div className='footer-create-new-playlist-nav'>
							{Array.from({length: 3}).map((_, ix) =>(
								<div className='footer-create-new-playlist-nav-item' key={ix} style={step === ix + 1 ? {width: 24, opacity: 1} : {}}></div>
							))}
						</div>
						<div className='footer-create-new-playlist-item' onClick={() => this.handle_next_step('next')}>
							<div className='footer-create-new-playlist-item-text'>Next</div>
							<span className='material-icons'>arrow_forward</span>
						</div>
					</div>
				}
			</div>
		);
	}
}

class EditPlaylistOverlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			title: this.props.playlist_info.name,
			description: this.props.playlist_info.description,
			image_link: this.props.playlist_info.image_link,
			show_editor: this.props.playlist_info.image_link !== '' ? true : false,
			image_hash: ''
		};

		this.save_changes = this.save_changes.bind(this);
		this.handle_new_text = this.handle_new_text.bind(this);
		this.on_new_image_success = this.on_new_image_success.bind(this);
	}

	handle_new_text(value, type) {
		const { title, description } = this.state;
		if (title.length > 35 && value.length > 35) {
			return;
		}
		if (description.length > 50 && value.length > 50) {
			return;
		}
		this.setState({[type]: value});
	}

	on_new_image_success(data){
		const { file_hash_id, image_link } = data;
		setTimeout(() => {
			store_set('loading_overlay_display', false);
			this.setState({
				image_hash: file_hash_id,
				image_link: image_link,
				show_editor: true
			});
		}, 1000);
	}

	save_changes() {
		display_loading_overlay();
		const playlist_hash_id = this.props.playlist_info.hash_id;
		const playlist_data = {
			'hash_id': playlist_hash_id,
			'title': this.state.title,
			'description': this.state.description,
			'image_link': this.state.image_link,
			'image_hash': this.state.image_hash,
		};
		request_buffer.execute({
			type: 'post',
			url: constants.api_endpoint_edit_internal_playlist,
			data: {playlist_data: JSON.stringify(playlist_data)},
			success: function(data){
				if (data.status === 'success'){
					let playlists = [...this.props.playlists];
					let changed_playlist = playlists.find(p => p.hash_id === playlist_hash_id);
					changed_playlist.name = data.playlist_data.title;
					changed_playlist.description = data.playlist_data.description;
					changed_playlist.image_link = data.playlist_data.image_link;
					store_set('playlists', playlists);
					this.setState({
						image_hash: '',
						image_link: data.playlist_data.image_link
					});
				}
			}.bind(this),
			complete: function(){
				store_set('loading_overlay_display', false);
				store_set('active_overlay', false);
				store_set('edit_playlist', false);
			}
		});
	}

	render(){
		return(
			<div className='playlist-edit-mode' onClick={(e) => e.stopPropagation()}>
				<div className='playlist-edit-mode-header'>
					<span className='material-icons' onClick={() => {store_set('active_overlay', false); store_set('edit_playlist', false);}}>keyboard_backspace</span>
					<div className='playlist-edit-mode-header-text'>Edit Playlist</div>
				</div>
				<div className='playlist-edit-mode-body'>
					<div className='playlist-edit-mode-section'>
						{this.state.image_link ?
							<React.Fragment>
								<div className='playlist-edit-mode-dropzone'>
									<ImageLoader style={{height: 145, width: 145}} image_url={this.state.image_link} onError={assets.default_playlist_image} className='playlist-edit-mode-dropzone-image' />
									{this.state.show_editor &&
										<UploadImage
											type='playlist'
											show_icon={true}
											overlay={true}
											prefix='playlist_pics/'
											onSuccess={this.on_new_image_success}
										/>
									}
								</div>
								<div className='playlist-edit-mode-section-text'>Playlist Picture</div>
							</React.Fragment>
							:
							<React.Fragment>
								<UploadImage
									type='playlist'
									width={144}
									height={144}
									show_dropzone={true}
									text='Upload a Picture'
									overlay={true}
									prefix='playlist_pics/'
									onSuccess={this.on_new_image_success}
								/>
								<div className='playlist-edit-mode-section-text'>Upload a Picture <br/><span>(optional)</span></div>
							</React.Fragment>
							
						}
						<div className='playlist-edit-mode-textfield'>
							<div className='playlist-edit-mode-textfield-label'>Playlist Name</div>
							<Textfield theme='dark' size='small'
								placeholder='Magroove Playlist'
								value={this.state.title}
								onChange={(value) => this.handle_new_text(value, 'title')}
							/>
						</div>
						<div className='playlist-edit-mode-textfield'>
							<div className='playlist-edit-mode-textfield-label'>Playlist Description <span>(optional)</span></div>
							<Textfield theme='dark' multiline rows={5} size='small'
								placeholder='Write here a very cool description for your new playlist. Be creative!'
								value={this.state.description}
								onChange={(value) => this.handle_new_text(value, 'description')}
							/>
						</div>
					</div>
					<Ripples>
						<Haptic intesity='light'>
							<Button
								value='SAVE PLAYLIST' size='small' className='playlist-edit-mode-button'
								onClick={this.save_changes} />
						</Haptic>
					</Ripples>
				</div>
			</div>
		);
	}
}

class Overlay extends Component {
	constructor(props){
		super(props);
		this.state = {
			share_url: '',
			loading_links: false,
			search_seed: ''
		};

		this.hide_overlay = this.hide_overlay.bind(this);

		this.share = this.share.bind(this);
		this.get_song_links = this.get_song_links.bind(this);
		this.get_album_links = this.get_album_links.bind(this);

		event_bus.on('play_full_song', this.get_song_links);
		event_bus.on('play_full_album', this.get_album_links);
		event_bus.on('share_song', this.share);
	}

	share(entity_type = 'song'){
		let url;
		let text;
		if (entity_type === 'song'){
			url = constants.api_endpoint_song_meta.replace('{hash_id}', this.props.song_info.spotify_id);
			text = this.props.song_info.title + ' - ' + (this.props.song_info.artist.name || Array.from(this.props.song_info.artist, artist => artist.name).join(', '));
		}
		else if (entity_type === 'artist'){
			url = constants.api_endpoint_artist_meta.replace('{hash_id}', this.props.artist_info.spotify_id);
			text = this.props.artist_info.name;
		}
		else if (entity_type === 'album'){
			url = constants.api_endpoint_album_meta.replace('{hash_id}', this.props.album_info.spotify_id);
			text = this.props.album_info.title;
		}

		if (Capacitor.getPlatform() === 'web'){
			const overlay = entity_type === 'song' ? 'track_options' : entity_type === 'artist' ? 'artist_options' : 'album_options';
			store_set('active_overlay', overlay);
			store_set('active_overlay_option', 'share');
			this.setState({share_url: url});
		}
		else{
			shareCapacitor(url, text);
		}
	}

	hide_overlay(e){
		if (!e) e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		
		store_set('active_overlay', false);
		store_set('active_overlay_option', 'show_all_track');
		store_set('playlist_overlay_option', 'show_all');
		store_set('tracks_selected', []);
	}

	get_song_links(song = this.props.song_info){
		store_set('active_overlay', 'track_options');
		store_set('active_overlay_option', 'loading');

		if (!song.itunes_link){
			song['itunes_link'] = '';
		}
		if (!song.deezer_link){
			song['deezer_link'] = '';
		}
		if (!song.spotify_link){
			song['spotify_link'] = '';
		}
		if (!song.youtube_link){
			song['youtube_link'] = '';
		}
		store_set('song_info', song);

		if (song.itunes_link && song.deezer_link && song.spotify_link && song.youtube_link){
			store_set('active_overlay_option', 'play_full_song');
			return;
		}

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_song_links,
			data: {
				song_spotify_id: song.spotify_id,
				song_title: song.title,
				song_artist_name: song.artist.name
			},
			success: function(data){
				if (data.status === 'success'){
					song['spotify_link'] = data.final_dict.spotify.song;
					song['itunes_link'] = data.final_dict.itunes.release;
					song['deezer_link'] = data.final_dict.deezer.song;
					song['youtube_link'] = data.final_dict.youtube.song;
					store_set('song_info', song);
				}
			},
			complete: function(){
				store_set('active_overlay_option', 'play_full_song');
			}
		});
	}

	get_album_links(){
		store_set('active_overlay', 'album_options');
		store_set('active_overlay_option', 'loading');

		var album = this.props.album_info;
		if (!album.itunes_link){
			album['itunes_link'] = '';
		}
		if (!album.deezer_link){
			album['deezer_link'] = '';
		}
		if (!album.spotify_link){
			album['spotify_link'] = '';
		}
		store_set('album_info', album);

		if (album.itunes_link && album.deezer_link && album.spotify_link){
			store_set('active_overlay_option', 'play_full_album');
			return;
		}

		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_album_links,
			data: {spotify_id: album.spotify_id},
			success: function(data){
				if (data.status === 'success'){
					album['spotify_link'] = data.final_dict.spotify.release;
					album['itunes_link'] = data.final_dict.itunes.release;
					album['deezer_link'] = data.final_dict.deezer.release;
					store_set('album_info', album);
				}
			},
			complete: function(){
				store_set('active_overlay_option', 'play_full_album');
			}
		});
	}

	render() {
		const { active_overlay, edit_playlist, create_playlist } = this.props;
		const showExportTip = this.props.likes.length === 2 && this.props.user.tracks <= 10;

		if (active_overlay === 'mute_switch_on') {
			return(
				<div className='overlay-mute-switch-on'>
					<span className='material-icons-outlined'>volume_off</span>
					<div className='overlay-mute-switch-on-title'>Your silent mode is On!</div>
					<div className='overlay-mute-switch-on-subtitle'>Disable silent mode so you can enjoy</div>
					<div className='overlay-mute-switch-on-subtitle'>all your music to the fullest! </div>
					<div className='overlay-mute-switch-on-buttons' onClick={() => store_set('active_overlay', false)}>
						<Button value='DISMISS' size='small' onClick={()=> store_set('dismiss_mute_switch_message', new Date())} />
						<Button value='OK' size='small' />
					</div>
				</div>
			);
		}
		if (active_overlay === 'no_more_recommendations') {
			return <NoMoreRecommendationsOverlay {...this.props} />;
		}
		if (active_overlay === 'follow_overlay') {
			return <div></div>; /* Rendered inside Profile page */
		}
		return (
			<div
				className={(active_overlay || create_playlist || edit_playlist) ? 'magroove-overlay-open' : ''}
				onClick={()=> {
					store_set('active_overlay', false);
					store_set('active_overlay_option', 'show_all_track');
					store_set('playlist_overlay_option', 'show_all');
				}}
			>
				{active_overlay === 'max_timeouts' &&
					<div className='overlay-max-timeouts'>
						<span className='material-icons'>alarm_on</span>
						<div className='overlay-max-timeouts-text'>Are you still there?</div>
						<div className='overlay-max-timeouts-description'>It seems like you haven&apos;t interacted with your recommendations for a while.</div>
						<Ripples>
							<Haptic intesity='light'>
								<Button
									value='I&#39;M STILL HERE' size='small'
									className='overlay-max-timeouts-button'
									onClick={() => {store_set('active_overlay', false);}}
								/>
							</Haptic>
						</Ripples>
					</div>
				}
				<Swipeable onSwipedDown={['show_seed_likes', 'show_playlists'].includes(this.props.active_overlay_option) ? false : (e) => {
					this.hide_overlay(e);
				}} preventDefaultTouchmoveEvent={true}>
					<ItemOverlay
						share={this.share}
						share_url={this.state.share_url}
						get_song_links={() => this.get_song_links()}
						load_recommendations={load_recommendations}
					/>
					<PlaylistOverlay load_recommendations={load_recommendations} />
					<ConfirmationOverlay {...this.props} />
					<MusicalReferenceOverlay {...this.props} get_song_links={() => this.get_song_links()} />
				</Swipeable>
				{create_playlist &&
					<CreatePlaylistOverlay {...this.props} />
				}
				{edit_playlist &&
					<EditPlaylistOverlay {...this.props} />
				}
				{showExportTip &&
					<ExportTip {...this.props} is_premium={subscription.is_valid()} />
				}
				<Review/>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		active_overlay: state.GlobalReducer.active_overlay,
		active_overlay_option: state.GlobalReducer.active_overlay_option,
		playlist_overlay_option: state.GlobalReducer.playlist_overlay_option,
		song_info: state.GlobalReducer.song_info,
		album_info: state.GlobalReducer.album_info,
		playlist_info: state.GlobalReducer.playlist_info,
		artist_info: state.GlobalReducer.artist_info,
		likes: state.GlobalReducer.likes,
		streaming_overlay: state.GlobalReducer.streaming_overlay,
		playlists: state.GlobalReducer.playlists,
		spotify_playlists: state.GlobalReducer.spotify_playlists,
		deezer_playlists: state.GlobalReducer.deezer_playlists,
		select_mode: state.GlobalReducer.select_mode,
		tracks_selected: state.GlobalReducer.tracks_selected,
		create_playlist: state.GlobalReducer.create_playlist,
		edit_playlist: state.GlobalReducer.edit_playlist,
		safe_area_top: state.GlobalReducer.safe_area_top,
		user: state.GlobalReducer.user,
		user_quiz: state.GlobalReducer.user_quiz,
		review_type: state.GlobalReducer.review_type,
		current_streak: state.GlobalReducer.current_streak,
		current_streak_total_songs: state.GlobalReducer.current_streak_total_songs,
		current_streak_total_time: state.GlobalReducer.current_streak_total_time,
		seed: state.GlobalReducer.seed,
		duration: state.GlobalReducer.duration,
		current_time: state.GlobalReducer.current_time,
		playing_song: state.GlobalReducer.playing_song,
		is_playing: state.GlobalReducer.is_playing,
		secondary_overlay: state.GlobalReducer.secondary_overlay,
		overlay_seed: state.GlobalReducer.overlay_seed
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Overlay));
