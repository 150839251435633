import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { Img } from 'react-image';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import { Link } from 'react-router-dom';
import MarqueeText from 'react-marquee-text-component';

// CSS Imports
import './styles/MiniPlayer.css';

// import the player controller
import player from '@Player';

// Assets Imports
import { default_album_image, mini_player_handler } from '@Assets';

// Components and Views Imports
import { LinearProgress } from '@material-ui/core';
import { Swipeable } from 'react-swipeable';
import posed from 'react-pose';

// Actions import
import { store_set, store_get } from '@/actions/GlobalActions.js';

// Import the interaction controller
import interaction from '@Interaction';

// Constants import
import * as constants from '@constants';

const PosedPlayer = posed.div({
	full: {
		height: () => (window.innerWidth < 300 ? 37 : 56)
	},
	hidden: {
		height: 0
	}
});

class MiniPlayer extends Component {
	constructor(props){
		super(props);
		this.handle_player = this.handle_player.bind(this);
	}

	handle_player(){
		var display_player = store_get('display_player');
		store_set('display_player', !display_player);
	}

	render() {
		let album_image = () => (
			<Img
				src={[this.props.playing_song.album.images.small, default_album_image]}
				loader={
					<ContentLoader 
						width={window.innerWidth < 300 ? 37 : 52}
						height={window.innerWidth < 300 ? 37 : 52}
						backgroundColor={'#D8D8D8'}
						foregroundColor={'#b1afaf'}
					>
						{/* eslint-disable-next-line */}
						<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={100 + '%'} /> 
					</ContentLoader>
				}
				className='mini-player-cover'
			/>
		);
		const full_player = this.props.playing_song && this.props.display_player && this.props.location !== 'home';
		const display_handler = this.props.playing_song && this.props.location !== 'home';
		const interaction_available = this.props.free_like_dislike || this.props.current_time > constants.minimum_waiting_time;

		if (this.props.location === 'onboarding' ||
			this.props.location === 'login' ||
			this.props.location === 'out-of-ideas' ||
			this.props.location === 'obsession-search' ||
			this.props.location === 'recommendation-search' ||
			this.props.location === 'quiz-search' ||
			(this.props.location === 'edit-profile' && this.props.active_overlay === 'quiz')
		){
			return <div></div>;
		}

		// Mini player should not be visible when navigation is not visible and we should never play audio in
		// the same scenario, except in the track page
		if (this.props.location !== 'track' && (this.props.keyboard_open || this.props.hide_footer)){
			this.props.is_playing && player.pause();
			return <div></div>;
		}

		return(
			<Swipeable
				delta={1}
				className='mini-player'
				onSwipedDown={() => store_set('display_player', false)}
				onSwipedUp={() => store_set('display_player', true)}
				style={{borderTop: display_handler ? '3px solid #ec6b43' : ''}}
			>
				<Fragment>
					<div
						className='mini-player-handler'
						onClick={this.handle_player}
						style={{display: display_handler ? '' : 'none'}}
					>
						<span className='material-icons'>{this.props.display_player ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>
						<img src={mini_player_handler} alt='player handler'/>
					</div>
					<PosedPlayer pose={full_player ? 'full' : 'hidden'} className='mini-player-main'>
						{this.props.playing_song &&
							<Fragment>
								<div className='mini-player-content'>
									<Link to='/'>
										<div className='mini-player-track-cover-container' style={{height: full_player ? '' : 0}}>
											{album_image()}
										</div>
										<div className='mini-player-track-info'>
											{this.props.playing_song.title.length > 26 ?
												<MarqueeText
													className='mini-player-track-title'
													text={this.props.playing_song.title}
													duration={10}
													repeat={10}
												/>
												:
												<div className='mini-player-track-title'>{this.props.playing_song.title}</div>
											}
											<div className='mini-player-track-subtitle'>{(this.props.playing_song.album.name || this.props.playing_song.album.title)} - {this.props.playing_song.artist.name}</div>
										</div>
									</Link>
									<div className='mini-player-play-buttons-container'>
										{/* Only show the like and dislike buttons to logged users */}
										{this.props.user_token &&
											<React.Fragment>
												<div className='mini-player-play-button' style={interaction_available ? {} : {pointerEvents: 'none', opacity: 0.2}}>
													<span className='material-icons' onClick={interaction_available ? interaction.dislike : () => {}}>close</span>
												</div>
												<div className='mini-player-play-button' style={interaction_available ? {} : {pointerEvents: 'none', opacity: 0.2}}>
													<span className='material-icons' onClick={interaction_available ? interaction.like : () => {}}>favorite</span>
												</div>
											</React.Fragment>
										}
										<div className='mini-player-play-button'>
											<span className='material-icons' onClick={player.toggle}>{this.props.is_playing ? 'pause' : 'play_arrow'}</span>
										</div>
									</div>
								</div>
								<div className='mini-player-progress'>
									<LinearProgress variant='determinate' value={this.props.current_time*100 / this.props.duration}/>
								</div>
							</Fragment>
						}
					</PosedPlayer>
				</Fragment>
			</Swipeable>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		is_playing: state.GlobalReducer.is_playing,
		duration: state.GlobalReducer.duration,
		current_time: state.GlobalReducer.current_time,
		location: state.GlobalReducer.location,
		playing_song: state.GlobalReducer.playing_song,
		display_player: state.GlobalReducer.display_player,
		free_like_dislike: state.GlobalReducer.free_like_dislike,
		user_token: state.GlobalReducer.user_token,
		hide_footer: state.GlobalReducer.hide_footer,
		keyboard_open: state.GlobalReducer.keyboard_open,
		active_overlay: state.GlobalReducer.active_overlay
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(MiniPlayer));
