import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import posed from 'react-pose';

// CSS Imports
import './styles/Header.css';

// Assets Imports
import * as assets from '@Assets';

// Constant Imports
import * as constants from '@constants';

// Components and Views Imports
import { ClickAwayListener, LinearProgress } from '@material-ui/core';
import NotificationsIcon from '@components/NotificationsIcon.js';
// import UploadImage from '@components/UploadImage.js'

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

// Import the controllers
import keyboard from '@/Keyboard';
import notifications from '@Notifications';

const shareCapacitor = async (url, text) => {
	await Share.share({
		title: 'Let\'s find new songs!',
		text: text,
		url: url,
		dialogTitle: 'Share with your friends'
	});
};

const PosedSearch = posed.div({
	full: {
		margin: '0px',
		padding: '10px',
	},
	hidden: {
		margin: (window.screen.width > 360) ? '20px 15px 10px' : '10px 18px 10px',
		padding: '0px',
	},
	progress: {
		margin: '20px 0px 20px',
		padding: '0px 10px'
	}
});

function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function share(song){
	// If web, display overlay. Otherwise, just open native
	if (Capacitor.getPlatform() === 'web'){
		store_set('song_info', song);
		event_bus.emit('share_song');
	} else {
		var text = song.title + ' - ' + song.artist.name;
		var url = constants.api_endpoint_song_meta.replace('{hash_id}', song.spotify_id);
		shareCapacitor(url, text);
	}
}

class Header extends Component {
	constructor(props){
		super(props);
		this.state = {};

		this.back_button = React.createRef();

		event_bus.on('back-button', () => {
			if (this.back_button.current){
				this.back_button.current.click();
			}
		});

	}

	render() {
		// Used for the Recommendation Search header
		const profile = this.props.profile_info;
		const handleClickBackButtonEvent = () => {event_bus.emit('back-button-pressed')}
		const notifications_list = this.props.notifications || [];

		if (this.props.location === 'login'){
			return(
				<React.Fragment>
					<header className='default-header'>
						<Link ref={this.back_button} to='/onboarding/'/>
						<img src={assets.magroove_logo} alt='magroove-logo'/>
					</header>
				</React.Fragment>
			);
		} else if (this.props.location === 'onboarding'){
			return(
				<React.Fragment>
					<header className='onboarding-header' style={{top: this.props.safe_area_top}}>
						<img src={assets.magroove_logo} alt='magroove-logo'/>
					</header>
				</React.Fragment>
			);
		} else if (this.props.location === 'out-of-ideas'){
			return(
				<header className='out-of-ideas-header'>
					<div className='out-of-ideas-header-left'>
						{!this.props.user_token &&
							<Link ref={this.back_button} to='/onboarding/'>
								<span className='material-icons'>keyboard_backspace</span>
							</Link>
						}
						{this.props.user_token &&
							<Link ref={this.back_button} to='/'>
								<span className='material-icons'>keyboard_backspace</span>
							</Link>
						}
					</div>
					<img src={assets.magroove_logo} alt='magroove-logo'/>
					<div className='out-of-ideas-header-right' />
				</header>
			);
		} else if (this.props.location === 'track'){
			return(
				<header className='track-header-container' style={{top: this.props.safe_area_top}}>
					<div className='track-header'>
						{/* Only display the back button to logged users */}
						{this.props.user_token &&
							<div className='track-header-left-side' onClick={handleClickBackButtonEvent}>
								<Link ref={this.back_button} to={this.props.previous_page.includes('profile') ? this.props.previous_page : '/'}>
									<span className='material-icons'>keyboard_backspace</span>
								</Link>
							</div>
						}
					</div>
					<div className='artist-header-right-side'>
						<span className='material-icons' onClick={() => {store_set('song_info', this.props.track_info); store_set('active_overlay', 'track_options');}}>more_vert</span>
					</div>
				</header>
			);
		} else if (this.props.location === 'artist'){
			return(
				<div className='artist-header-container' id='artist-header-container' style={{top: this.props.safe_area_top}}>
					<div className='artist-header'>
						{/* Only display the back button to logged users */}
						{this.props.user_token &&
							<div className='artist-header-left-side' onClick={handleClickBackButtonEvent}>
								<Link ref={this.back_button} to={this.props.previous_page}>
									<span className='material-icons'>keyboard_backspace</span>
								</Link>
							</div>
						}
					</div>
					<div className='artist-header-right-side'>
						<span className='material-icons' onClick={() => {store_set('active_overlay', 'artist_options'); store_set('active_overlay_option', 'show_all_artist');}}>more_vert</span>
					</div>
				</div>
			);
		} else if (this.props.location === 'album'){
			return(
				<header className='album-header-container' style={{top: this.props.safe_area_top}}>
					<div className='album-header'>
						{/* Only display the back button to logged users */}
						{this.props.user_token &&
							<div className='album-header-left-side' onClick={handleClickBackButtonEvent}>
								<Link ref={this.back_button} to={this.props.previous_page}>
									<span className='material-icons'>keyboard_backspace</span>
								</Link>
							</div>
						}
					</div>
				</header>
			);
		} else if (this.props.location === 'my-list'){
			return(
				<header className='my-list-header-container'>
					<div className='my-list-header'>
						<div className='my-list-header-left' onClick={handleClickBackButtonEvent}>
							{!this.props.select_mode &&
								<Link ref={this.back_button} to={this.props.previous_page}>
									<span className='material-icons'>keyboard_backspace</span>
								</Link>
							}
							{this.props.select_mode &&
								<React.Fragment>
									{this.props.previous_page === '/settings/' &&
										<Link ref={this.back_button} to='/settings/'>
											<span className='material-icons'>keyboard_backspace</span>
										</Link>
									}
									{this.props.previous_page !== '/settings/' &&
										<span
											ref={this.back_button}
											className='material-icons'
											onClick={()=> {store_set('tracks_selected', []); store_set('select_mode', false);}}
										>
											keyboard_backspace
										</span>
									}
								</React.Fragment>
							}
						</div>
						<div className='my-list-header-center'>
							<img src={assets.magroove_logo} alt='magroove-logo'/>
						</div>
						<div className='my-list-header-right'>
							
							{!this.props.select_mode &&
								<React.Fragment>
									<span className='material-icons-outlined' onClick={() => store_set('select_mode', true)}>file_upload</span>
									<span className='material-icons' onClick={() => {store_set('active_overlay', 'track_options');store_set('active_overlay_option', 'my_list_header_overlay');}}>more_vert</span>
								</React.Fragment>
							}	
						</div>
					</div>
				</header>
			);
		} else if (this.props.location === 'profile'){
			return(
				<header className='more-header' id='more-header' style={{top: this.props.safe_area_top}}>
					<div className='more-header-box'>
						<div className='more-header-left'>
							<Link ref={this.back_button} to='/' className='more-header-left'>
								<span className='material-icons' onClick={handleClickBackButtonEvent}>keyboard_backspace</span>
							</Link>
						</div>
					</div>
					<div className='more-header-row'>
						<Link to='/settings/'>
							<span className='material-icons'>settings</span>
						</Link>
						<NotificationsIcon />
					</div>
				</header>
			);
		} else if (this.props.location === 'settings'){
			return(
				<header className='settings-header'>
					<div className='settings-header-left'>
						<Link ref={this.back_button} to={this.props.previous_page}>
							<span className='material-icons' onClick={handleClickBackButtonEvent}>keyboard_backspace</span>
						</Link>
					</div>
					<div className='settings-header-center'>Settings</div>
				</header>
			);
		} else if (this.props.location === 'edit-profile'){
			return(
				<header className='edit-profile-header' style={{top: this.props.safe_area_top}}>
					<div className='edit-profile-header-box'>
						<div className='edit-profile-header-left'>
							<Link ref={this.back_button} to='/profile/'>
								<span className='material-icons' onClick={() => store_set('hide_footer', false)}>keyboard_backspace</span>
							</Link>
						</div>
						<div className='edit-profile-header-center'>Settings</div>
					</div> 
				</header>
			);
		} else if (this.props.location === 'subscriptions'){
			return(
				<header className='more-header' style={{top: this.props.safe_area_top}}>
					<div className='more-header-box'>
						<div className='more-header-left'>
							<Link ref={this.back_button} to='/settings/'>
								<span className='material-icons'>keyboard_backspace</span>
							</Link>
						</div>
						<div className='more-header-center'>Your Subscription</div>
					</div>
				</header>
			);
		} else if (this.props.location === 'blocklist'){
			return(
				<header className='blocklist-header'>
					<div className='blocklist-header-box'>
						<div className='blocklist-header-left'>
							<Link ref={this.back_button} to='/'>
								<span className='material-icons'>keyboard_backspace</span>
							</Link>
						</div>
						<div className='blocklist-header-center'>Blocklist</div>
					</div>
				</header>
			);
		} else if (this.props.location === 'recommendation'){
			return(
				<header className='blocklist-header'>
					<div className='blocklist-header-box'>
						<div className='blocklist-header-left'>
							<Link ref={this.back_button} to={this.props.previous_page}>
								<span className='material-icons'>keyboard_backspace</span>
							</Link>
						</div>
						<div className='blocklist-header-center'>Recommendation</div>
					</div>
				</header>
			);
		} else if (this.props.location === 'default-error'){
			return(
				<header className='default-error-header'>
					<Link ref={this.back_button} to={this.props.previous_page}/>
					<div className='default-error-header-center'>
						<img src={assets.magroove_logo} alt='magroove-logo'/>
					</div>
				</header>
			);
		} else if (this.props.location === 'fine-tunning'){
			return(
				<header className='fine-tunning-header'>
					<div className='fine-tunning-header-left'>
						<Link ref={this.back_button} to={this.props.previous_page}>
							<span className='material-icons' onClick={handleClickBackButtonEvent}>keyboard_backspace</span>
						</Link>
					</div>
					<img src={assets.magroove_logo} alt='magroove-logo'/>
					<div className='fine-tunning-header-right' />
				</header>
			);
		} else if (this.props.location === 'playlist') {
			return(
				<header className='playlist-header-container' style={{top: this.props.safe_area_top}}>
					<div className='playlist-header'>
						<Link ref={this.back_button} to={this.props.previous_page}>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
						<span className='material-icons' onClick={() => {store_set('active_overlay', 'playlists_options'); store_set('playlist_overlay_option', 'show_all');}}>more_vert</span>
					</div>
				</header>
			);
		} else if (this.props.location === 'notifications') {
			return(
				<header className='notifications-header-container' style={{top: this.props.safe_area_top}}>
					<Link ref={this.back_button} to={this.props.previous_page}>
						<span className='material-icons'>keyboard_backspace</span>
					</Link>
					<div className='notifications-header-title'>
						<div className='notifications-header-title-text'>Notifications</div>
						{notifications_list.find(item => item.new) ? 
							<div className='notifications-header-title-circle'></div>
							: null
						}
					</div>
					{notifications_list.length > 0 &&
						<div className='notifications-header-button' onClick={() => {store_set('clearing_notifications', true); notifications.clear('', 1);}}>Clear all</div>
					}
				</header>
			);
		} else if (this.props.navigation_location === 'settings'){
			return(
				<header className='settings-header'>
					<div className='settings-header-left'>
						<Link ref={this.back_button} to={(this.props.location === 'contact-us' || this.props.location === 'my-playlists') ? this.props.previous_page : '/settings/'}>
							<span className='material-icons' onClick={handleClickBackButtonEvent}>keyboard_backspace</span>
						</Link>
					</div>
					{this.props.location === 'linked-accounts' &&
						<div className='settings-header-center'>Linked accounts</div>
					}
					{this.props.location === 'settings' &&
						<div className='settings-header-center'>Settings</div>
					}
					{this.props.location === 'terms-of-service' &&
						<div className='settings-header-center'>Terms of Service</div>
					}
					{this.props.location === 'privacy-policy' &&
						<div className='settings-header-center'>Privacy Policy</div>
					}
					{this.props.location === 'delete-account' &&
						<div className='settings-header-center'>Delete Account</div>
					}
					{this.props.location === 'contact-us' &&
						<div className='settings-header-center'>Contact us</div>
					}
					{this.props.location === 'version' &&
						<div className='settings-header-center'>Version</div>
					}
					{(this.props.location === 'sync-playlists' || this.props.location === 'my-playlists') &&
						<div className='settings-header-center'>Sync with playlist</div>
					}
				</header>
			);
		} else if (this.props.navigation_location === 'search'){ 
			return(
				<React.Fragment>
					{(this.props.see_all_artists || this.props.see_all_songs || this.props.see_all_albuns) ?
						<div className='search-header-container' style={{top: this.props.safe_area_top}}>
							<div className='search-header-container-specific-results'>
								<span className='material-icons' onClick={this.props.reset_value}>keyboard_backspace</span>
								<div className='search-header-container-specific-results-description'>
									{this.props.see_all_artists &&
										<React.Fragment>{'Artists for \'' + this.props.search_query + '\''}</React.Fragment>
									}
									{this.props.see_all_songs &&
										<React.Fragment>{'Songs for \'' + this.props.search_query + '\''}</React.Fragment>
									}
									{this.props.see_all_albuns &&
										<React.Fragment>{'Albums for \'' + this.props.search_query + '\''}</React.Fragment>
									}
								</div>
							</div>
						</div>
						:
						<div className='search-header-section' style={this.props.expand_search ? {padding: 0} : {paddingTop: window.screen.width > 360 ? 40 : 30}}>
							<div className='search-header-section-title' style={this.props.expand_search ? {display: 'none'} : {display: 'inline'}}>Find recommendations for:</div>
							<div className='search-header-container' style={{top: this.props.safe_area_top}}>
								<ClickAwayListener onClickAway={this.props.search_query.length > 0 ? () => {} : () => store_set('expand_search', false)}>
									<PosedSearch
										className={'search-field-container' + (this.props.expand_search ? ' search-field-expand' : '')}
										pose={this.props.expand_search ? 'full' : 'hidden'}
									>
										<input
											type='text'
											value={this.props.search_query}
											onChange={this.props.input_changed}
											placeholder='Search for a song or artist...'
											onClick={() => {store_set('expand_search', true); keyboard.opened();}}
											onBlur={() => keyboard.hidden()}
										/>
										{this.props.search_query.length > 0 &&
											<span className='material-icons' onClick={() => {store_set('search_query', ''); store_set('display_results', false); this.props.reset_value();}}>close</span>
										}
										{this.props.search_query.length === 0 &&
											<span className='material-icons'>search</span>
										}
									</PosedSearch>
								</ClickAwayListener>
							</div>
						</div>
					}
				</React.Fragment>
			);
		} else if (this.props.location === 'edit-profile-search'){
			const entity_type = window.location.pathname.replace('/edit-profile-search/', '').replace('/', '');
			return (
				<div className='search-progress-header-section'>
					<div className='search-progress-header-top'>
						<Link ref={this.back_button} to='/edit-profile?tab=Showcase'>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
						<div className='search-progress-header-section-title'>Add to Top {capitalize(entity_type)}s</div>
					</div>
					<div className='search-progress-header-container'>
						<ClickAwayListener onClickAway={this.props.search_query.length > 0 ? () => {} : () => store_set('search_progress', false)}>
							<PosedSearch
								className={'search-progress-field-container' + (this.props.search_progress ? ' search-progress-field-expand' : '')}
								pose={this.props.search_progress ? 'progress' : 'hidden'}
							>
								<input
									type='text'
									value={this.props.search_query}
									onChange={this.props.input_changed}
									placeholder='Search for a song or artist...'
									onClick={() => {store_set('search_progress', true); keyboard.opened();}}
									onBlur={() => keyboard.hidden()}
								/>
								{this.props.search_query.length > 0 &&
									<span className='material-icons' onClick={() => {store_set('search_query', ''); store_set('display_results', false); this.props.reset_value();}}>close</span>
								}
								{this.props.search_query.length === 0 &&
									<span className='material-icons'>search</span>
								}
							</PosedSearch>
						</ClickAwayListener>
						<LinearProgress variant='determinate' value={this.props.total_search_progress} style={{bottom: this.props.search_progress ? 16 : 5, visibility: this.props.search_progress ? 'visible' : 'hidden'}} />
					</div>
				</div>
			);
		} else if (['recommendation-search', 'poll-recommendation-search'].includes(this.props.location)){
			return (
				<div className='search-progress-header-section'>
					<div className='search-progress-header-top'>
						<Link ref={this.back_button} to={this.props.recommendation_return_page} onClick={this.props.footer_progress_index === 0 ? () => {store_set('search_progress', false); store_set('selected_search_items', []); store_set('footer_progress_allow_next', false); store_set('footer_progress_index', 0); store_set('footer_progress_length', 2);} : (e) => {e.preventDefault(); store_set('footer_progress_index', this.props.footer_progress_index - 1);}}>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
						{this.props.location === 'recommendation-search' ?
							<div className='search-progress-header-section-title'>Recommend to {profile.slug ? ('@' + profile.slug) : profile.first_name}</div>
							:
							<div className='search-progress-header-section-title'>Answer {this.props.poll_info.user && this.props.poll_info.user.name} poll</div>
						}
					</div>
					{!(this.props.footer_progress_index === this.props.footer_progress_length - 1) &&
						<div className='search-progress-header-container'>
							<ClickAwayListener onClickAway={this.props.search_query.length > 0 ? () => {} : () => store_set('search_progress', false)}>
								<PosedSearch
									className={'search-progress-field-container' + (this.props.search_progress ? ' search-progress-field-expand' : '')}
									pose={this.props.search_progress ? 'progress' : 'hidden'}
								>
									<input
										type='text'
										value={this.props.search_query}
										onChange={this.props.input_changed}
										placeholder='Search for a song or artist...'
										onClick={() => {store_set('search_progress', true); keyboard.opened();}}
										onBlur={() => keyboard.hidden()}
									/>
									{this.props.search_query.length > 0 &&
										<span className='material-icons' onClick={() => {store_set('search_query', ''); store_set('display_results', false); this.props.reset_value();}}>close</span>
									}
									{this.props.search_query.length === 0 &&
										<span className='material-icons'>search</span>
									}
								</PosedSearch>
							</ClickAwayListener>
							<LinearProgress variant='determinate' value={this.props.total_search_progress} style={{bottom: this.props.search_progress ? 16 : 5, visibility: this.props.search_progress ? 'visible' : 'hidden'}} />
						</div>
					}
				</div>
			);
		} else if (this.props.location === 'obsession-search'){
			return (
				<div className='search-progress-header-section'>
					<div className='search-progress-header-top'>
						<Link ref={this.back_button} to={this.props.previous_page} onClick={this.props.footer_progress_index === 0 ? () => {store_set('search_progress', false); store_set('selected_search_items', []); store_set('footer_progress_allow_next', false); store_set('footer_progress_index', 0); store_set('footer_progress_length', 2);} : (e) => {e.preventDefault(); store_set('footer_progress_index', this.props.footer_progress_index - 1);}}>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
						<div className='search-progress-header-section-title'>Set Obsession</div>
					</div>
					{!(this.props.footer_progress_index === this.props.footer_progress_length - 1) &&
						<div className='search-progress-header-container'>
							<ClickAwayListener onClickAway={this.props.search_query.length > 0 ? () => {} : () => store_set('search_progress', false)}>
								<PosedSearch
									className={'search-progress-field-container' + (this.props.search_progress ? ' search-progress-field-expand' : '')}
									pose={this.props.search_progress ? 'progress' : 'hidden'}
								>
									<input
										type='text'
										value={this.props.search_query}
										onChange={this.props.input_changed}
										placeholder='Search for a song or artist...'
										onClick={() => {store_set('search_progress', true); keyboard.opened();}}
										onBlur={() => keyboard.hidden()}
									/>
									{this.props.search_query.length > 0 &&
										<span className='material-icons' onClick={() => {store_set('search_query', ''); store_set('display_results', false); this.props.reset_value();}}>close</span>
									}
									{this.props.search_query.length === 0 &&
										<span className='material-icons'>search</span>
									}
								</PosedSearch>
							</ClickAwayListener>
							<LinearProgress variant='determinate' value={this.props.total_search_progress} style={{bottom: this.props.search_progress ? 16 : 5, visibility: this.props.search_progress ? 'visible' : 'hidden'}} />
						</div>
					}
				</div>
			);
		} else if (this.props.location === 'home'){
			return null;
		} else if (this.props.location === 'queue-list'){
			return (
				<header className='queue-header'>
					<div className='queue-header-left'>
						<Link ref={this.back_button} to={this.props.previous_page}>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
					</div>
					<div className='queue-header-center'>Recommendation Queue</div>
				</header>
			);
		} else if (this.props.location.includes('poll')){
			return (
				<header className='queue-header'>
					<div className='queue-header-left'>
						<Link ref={this.back_button} to={this.props.previous_page.includes('edit-profile') ? '/edit-profile?tab=Showcase&section=polls' : this.props.previous_page}>
							<span className='material-icons'>keyboard_backspace</span>
						</Link>
					</div>
					<div className='queue-header-center'>{this.props.location === 'poll' ? 'Poll' : 'Start a Poll'}</div>
				</header>
			);
		} else if (this.props.location === 'quiz-search'){
			return null;
		} 
		else {
			return (
				<header className='main-header'>
					<Link ref={this.back_button} to='/'/>
					<img src={assets.magroove_logo} alt='magroove-logo'/>
				</header>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user_token: state.GlobalReducer.user_token,
		previous_page: state.GlobalReducer.previous_page,
		location: state.GlobalReducer.location,
		navigation_location: state.GlobalReducer.navigation_location,
		seed: state.GlobalReducer.seed,
		seed_type: state.GlobalReducer.seed_type,
		playing_song: state.GlobalReducer.playing_song,
		song_info: state.GlobalReducer.song_info,
		track_info: state.GlobalReducer.track_info,
		my_list_display: state.GlobalReducer.my_list_display,
		select_mode: state.GlobalReducer.select_mode,
		safe_area_top: state.GlobalReducer.safe_area_top,
		see_all_artists: state.GlobalReducer.see_all_artists,
		see_all_songs: state.GlobalReducer.see_all_songs,
		see_all_albuns: state.GlobalReducer.see_all_albuns,
		search_query: state.GlobalReducer.search_query,
		expand_search: state.GlobalReducer.expand_search,
		search_progress: state.GlobalReducer.search_progress,
		total_search_progress: state.GlobalReducer.total_search_progress,
		display_results: state.GlobalReducer.display_results,
		reset_value: state.GlobalReducer.reset_value,
		input_changed: state.GlobalReducer.input_changed,
		suggestions: state.GlobalReducer.suggestions,
		recent_seeds: state.GlobalReducer.recent_seeds,
		home_type: state.GlobalReducer.home_type,
		profile_info: state.GlobalReducer.profile_info,
		footer_progress_length: state.GlobalReducer.footer_progress_length,
		footer_progress_index: state.GlobalReducer.footer_progress_index,
		recommendation_return_page: state.GlobalReducer.recommendation_return_page,
		notifications: state.GlobalReducer.notifications,
		notifications_return_page: state.GlobalReducer.notifications_return_page,
		poll_info: state.GlobalReducer.poll_info
	};
}

// Connect component to the store
export default connect(mapStateToProps)(Header);
