import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Img } from 'react-image';

// CSS Imports
import './styles/Profile.css';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import Card from '@components/Card.js';
import Milestone from '@components/Milestone.js';
import ContentLoader from '@components/ContentLoader.js';
import FollowOverlay from '@components/FollowOverlay.js';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';
import {
	DiscoveryLeaderboard,
	DiscoveryTimeline,
	GenreChart,
	FollowingArtists,
	FollowingUsers,
	Likeminded,
	MusicAspects,
	Obsession,
	MusicTaste,
	Overview,
	Polls,
	TopAlbums,
	TopArtists,
	TopTracks,
	PeopleYouMayKnow
} from '@components/ProfileSections.js';

import { Button } from '@magroove/magroove-ui';
import premium_badge from '../assets/badge-orange-no-sparkles.webp';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdUnit from '@components/AdUnit.js';

// Constant Imports
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Actions import
import { store_get, store_set } from '@actions/GlobalActions.js';

// Import the controllers
import social from '@Social';
import storage from '@Storage';
import subscription from '@Subscription';

// Import functions
import { redirect } from '@routes/Routes';

function ObsessionSection(props) {
	const name_title = props.own_profile? 'Your' : `${props.user.first_name}'s`;
	const obsession_not_defined = props.user && (!props.user.obsession || !props.user.obsession.song);
	if (!props.own_profile && obsession_not_defined) return null;
	return (
		<div className='obsession-section'>
			<div className='profile-section-title-container'>
				<div className='profile-section-title'>{name_title} Obsession</div>
			</div>
			<Obsession {...props} />
		</div>
	);
}

function PollSection(props){
	if (props.data === 'loading' || (!props.own_profile && !props.data.find(item => item.author))) return null;

	const name_title = props.own_profile? 'Your' : `${props.user.first_name}'s`;
	return (
		<div className='profile-section'>
			<div className='profile-section-title-container'>
				<div className='profile-section-title'>{name_title} Polls</div>
			</div>
			<Polls {...props} />
		</div>
	);
}

function QuizSection(props){
	const { data, own_profile } = props;
	if (data.length === 0 && own_profile){
		const take_quiz = () => {
			const first = constants.quiz_list[0];
			store_set('quiz', {quiz: first, index: 0});
			redirect(`/quiz-search/${first.search}/`);
		};

		return (
			<div className='profile-section'>
				<div className='profile-section-title-container'>
					<div className='profile-section-title'>Quizes</div>
				</div>
				<div className='profile-section-quiz-card'>
					<div
						className='profile-section-suggestion'
						onClick={() => take_quiz()}
						style={{backgroundImage: `url('${assets.milestone_quiz}')`}}>
						<div className='profile-section-suggestion-text'>
							<p>Unleash your musical persona - <b style={{color: '#ec6b43'}}>take the quiz</b> and show it to the world!</p>
							<div>TAKE QUIZ</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className='quiz-section'>
			{data.map((item, index) => {
				const question = constants.quiz_list.find(q => q.quiz === item.quiz);
				if (!question) return null;
				return (
					<Card 
						key={index}
						type='quiz_card' 
						label={question.title}
						asset={question.icons[0]}
						dataItem={item}
						displayButtons={true}
						shouldExpand={true}
						onMoreClick={item.item_type === 'artist' ?
							() => {store_set('artist_info', item.item); store_set('active_overlay', 'artist_options'); store_set('active_overlay_option', 'show_all_artist');} :
							() => {store_set('song_info', item.item); store_set('active_overlay', 'track_options');}
						}
					/>
				);
			})}
		</div>
	);
}

function DiscoverMoreSection(props){
	const { user } = props;

	return (
		<React.Fragment>
			{(Object.keys(user) && ((!user.picture || user.picture.includes('default')) || !subscription.is_valid() || user.likes.length === 0 || !props.linked_accounts.some(item => item.state === 'active') || props.playlists.length === 0)) &&
				<div className='profile-section'>
					<div className='profile-section-title-container'>
						<div className='profile-section-title'>Get in the groove</div>
					</div>
					<div className='profile-scroll-section-content'>
						{Object.keys(user) && (!user.picture || user.picture.includes('default')) &&
							<Milestone label='First Steps' image={assets.milestone_profile_pic} text='Set your profile pic' onClick={() => redirect('/edit-profile/')} />
						}
						{/* <Milestone label='First Steps' image={milestone_find_friends} text='Find your friends' /> */}
						{!subscription.is_valid() &&
							<Milestone label='First Steps' image={assets.milestone_premium_subs} text='Get a premium subscription' onClick={() => subscription.invite()} />
						}
						{user.likes === 0 &&
							<Milestone label='First Steps' image={assets.milestone_discover_new} text='Discover new music' onClick={() => redirect('/search/')} />
						}
						{!props.linked_accounts.some(item => item.state === 'active') &&
							<Milestone label='First Steps' image={assets.milestone_sync_streaming} text='Sync with your streaming' onClick={() => redirect('/sync-playlists/')} />
						}
						{props.playlists.length === 0 &&
							<Milestone label='First Steps' image={assets.milestone_create_playlist} text='Create your first playlist' onClick={subscription.is_valid() ? () => {store_set('create_playlist', true); store_set('playlist_overlay_option', 'internal');} : () => subscription.invite('Discover much more and get extra control over your songs!', 'Unlimited rollbacks, swipes and autoplay, all with a top-notch recommendation algorithm!', 'playlists_my_list')} />
						}
					</div>
				</div>
			}
			{user.likes === 0 &&
				<div className='profile-section'>
					<div className='profile-section-title-container'>
						<div className='profile-section-title'>Time to discover!</div>
					</div>
					{/* eslint-disable-next-line react/no-unescaped-entities*/}
					<div className='profile-section-description'> The more songs you discover on the app, the more insights you'll get about your taste in music.</div>
					<Button label='DISCOVER NEW MUSIC' className='profile-section-button' size='small' onClick={() => redirect('/search/')} />
				</div>
			}
		</React.Fragment>
	);
}

function FollowingSection(props){
	if (props.data.length === 0) return null;
	return (
		<div className='profile-section'>
			<div className='profile-section-title-container'>
				<div className='profile-section-title'>Following {props.type}</div>
				{props.data.length > 3 &&
					<div className='profile-section-title-button' onClick={() => props.display_follow_list(props.type === 'Artists' ? 'following_artists' : 'following_users')}>
						View All
					</div>
				}
			</div>
			{props.type === 'Artists' ? <FollowingArtists {...props} data_index={3} /> : <FollowingUsers {...props} data_index={3} />}
		</div>
	);
}

function LikemindedSection(props){
	if (props.data.length === 0) return null;
	return (
		<div className='profile-section'>
			<div className='profile-section-title-column'>
				<div className='profile-section-title'>Like-minded</div>
				<div className='profile-section-subtitle'>Find users with similar interests as you</div>
			</div>
			<Likeminded {...props} handle_follow_action={props.handle_follow_action} />
		</div>
	);
}

function GenericSection(props){
	if (props.disabled) return null;
	return (
		<div className={'profile-section ' + (props.chart ? 'profile-section-chart' : '')}>
			{props.chart ?
				<div className='profile-section-chart-title'>{props.title}</div>
				:
				<div className='profile-section-title-container'>
					<div className='profile-section-title'>{props.title}</div>
				</div>
			}
			{props.component}
		</div>
	);
}

function OverviewSection(props){
	const { user } = props;

	return (
		<div className='profile-section'>
			<div className='profile-section-title-container'>
				<div className='profile-section-title'>Overview</div>
			</div>
			{Object.keys(user).length > 0 ?
				<Overview {...props} />
				:
				<div className='profile-section-content'>
					<ContentLoader style={{ minWidth: '50vw', height: 67, borderRadius: 4 }} />
					<ContentLoader style={{ minWidth: '50vw', height: 67, borderRadius: 4 }} />
				</div>
			}
		</div>
	);
}

class ProfileTopContainer extends Component {
	constructor(props){
		super(props);

		this.handle_follow = this.handle_follow.bind(this);
	}

	handle_follow(action){
		const { slug, followers, first_name, last_name, picture, followed, member_id, hash_id } = this.props.user;
		const data = {
			type: 'profile',
			member_id: member_id,
			hash_id: hash_id,
			slug: slug,
			followed: followed,
			followers: followers,
			name: first_name + ' ' + last_name,
			common_followers: [],
			picture: picture
		};

		this.props.follow_user_management(action, data);
	}

	render() {
		const slug = this.props.profile_slug;
		const own_profile = slug.length === 0 || slug === this.props.logged_user.slug;

		return (
			<div className='profile-top-container'>
				{own_profile ?
					<React.Fragment>
						{Object.keys(this.props.user).length > 3 ?
							<React.Fragment>
								<div className='profile-banner'>
									<Img
										src={[this.props.user.header_picture, assets.default_profile_header]} alt=''
										loader={
											<ContentLoader
												width='100vw'
												height={97}
												backgroundColor='#D8D8D8'
												foregroundColor='#b1afaf'
											>
												<rect x='0' y='0' rx='0' ry='0' width='100vw' height={97} />
											</ContentLoader>
										}
									/>
									<div className='profile-banner-shadow'></div>
								</div>
								<div className='profile-info'>
									<div className='profile-info-image'>
										<Img
											src={[this.props.user.picture, assets.default_profile_image]} alt={this.props.user.first_name + ' ' + this.props.user.last_name}
											loader={
												<ContentLoader
													width={106}
													height={106}
													backgroundColor={'#D8D8D8'}
													foregroundColor={'#b1afaf'}
												>
													<circle cx='50%' cy='50%' r='50%' width={106} height={106} />
												</ContentLoader>
											}
											onLoad={() => this.props.handle_change('editable', true)}
										/>
										{this.props.editable &&
											<Link to='/edit-profile/'>
												<div className='profile-info-edit'>
													<span className='material-icons'>edit</span>
												</div>
											</Link>
										}
									</div>
									<div className='profile-info-text'>
										<div className='profile-info-title'>
											<div className='profile-info-name'>{this.props.user.first_name} {this.props.user.last_name}</div>
											{subscription.is_valid() &&
												<img className='profile-info-badge' src={premium_badge} alt='premium-badge' />
											}
										</div>
										{!this.props.user.slug ?
											<Link to='/edit-profile/'>
												<div className='profile-info-missing'>Choose a username</div>
											</Link>
											:
											<div className='profile-info-user'>@{this.props.user.slug}</div>
										}
										{this.props.user.pronouns && this.props.user.pronouns !== 'not say' &&
											<div className='profile-info-pronoun'>{this.props.user.pronouns}</div>
										}
										<div className='profile-info-numbers'>
											<span onClick={() => {this.props.display_follow_list('followers');}}>
												<strong>{this.props.user.followers}</strong> Followers
											</span>
											<span onClick={() => {this.props.display_follow_list('following_users');}}>
												<strong>{this.props.user.following}</strong> Following
											</span>
										</div>
									</div>
								</div>
							</React.Fragment>
							:
							<React.Fragment>
								<div className='profile-banner'>
									<Img src={[this.props.user.header_picture, assets.default_profile_header]} alt='' />
									<div className='profile-banner-shadow'></div>
								</div>
								<div className='profile-info'>
									<div className='profile-info-image'>
										<ContentLoader style={{ width: 106, height: 106, borderRadius: '100%' }} />
									</div>
									<div className='profile-info-text'>
										<div className='profile-info-title'>
											<ContentLoader style={{ width: 100, height: 20 }} />
										</div>
										<div className='profile-info-user'>
											<ContentLoader style={{ width: 180, height: 20 }} />
										</div>
										<div className='profile-info-pronoun'>
											<ContentLoader style={{ width: 40, height: 12 }} />
										</div>
										<div className='profile-info-numbers'>
											<ContentLoader style={{ width: 156, height: 12 }} />
										</div>
									</div>
								</div>
							</React.Fragment>
						}
					</React.Fragment>
					:
					<React.Fragment>
						{Object.keys(this.props.user).length > 0 ?
							<React.Fragment>
								<div className='profile-banner'>
									<Img
										src={[this.props.user.header_picture, assets.default_profile_header]} alt=''
										loader={
											<ContentLoader
												width={'100vw'}
												height={97}
												backgroundColor={'#D8D8D8'}
												foregroundColor={'#b1afaf'}
											>
												<rect x='0' y='0' rx='0' ry='0' width={'100vw'} height={97} />
											</ContentLoader>
										}
									/>
									<div className='profile-banner-shadow'></div>
								</div>
								<div className='profile-visiting-info'>
									<div className='profile-visiting-info-image'>
										<Img
											src={[this.props.user.picture, assets.default_profile_image]} alt={this.props.user.first_name + ' ' + this.props.user.last_name}
											loader={
												<ContentLoader
													width={106}
													height={106}
													backgroundColor={'#D8D8D8'}
													foregroundColor={'#b1afaf'}
												>
													<circle cx='50%' cy='50%' r='50%' width={106} height={106} />
												</ContentLoader>
											}
										/>
									</div>
									<div className='profile-visiting-info-text'>
										<div className='profile-visiting-info-title'>
											<div className='profile-visiting-info-name'>{this.props.user.first_name} {this.props.user.last_name}</div>
										</div>
										<div className='profile-visiting-info-user-container'>
											{this.props.user.slug &&
												<div className='profile-visiting-info-user'>@{this.props.user.slug}</div>
											}
											{this.props.user.pronouns && this.props.user.pronouns !== 'not say' &&
												<React.Fragment>
													<div className='profile-visiting-info-circle'>●</div>
													<div className='profile-visiting-info-pronoun'>{this.props.user.pronouns}</div>
												</React.Fragment>
											}
										</div>
										<div className='profile-visiting-info-numbers'>
											<span onClick={() => {this.props.display_follow_list('followers');}}>
												<strong>{this.props.user.followers}</strong> Followers
											</span>
											<span onClick={() => {this.props.display_follow_list('following_users');}}>
												<strong>{this.props.user.following}</strong> Following
											</span>
										</div>
									</div>
									{this.props.user.follow_status &&
										<div className='profile-visiting-button' onClick={() => this.handle_follow('unfollow')}>
											<span className='material-icons profile-visiting-button-icon'>done</span>
											<div className='profile-visiting-button-text'>Following</div>
										</div>
									}
									{!this.props.user.follow_status &&
										<div className='profile-visiting-button' onClick={() => this.handle_follow('follow')}>
											<span className='material-icons profile-visiting-button-icon'>person_add_alt_1</span>
											<div className='profile-visiting-button-text'>Follow</div>
										</div>
									}
								</div>
							</React.Fragment>
							:
							<React.Fragment>
								<div className='profile-banner'>
									<Img src={[this.props.user.header_picture, assets.default_profile_header]} alt='' />
									<div className='profile-banner-shadow'></div>
								</div>
								<div className='profile-visiting-info'>
									<div className='profile-visiting-info-image'>
										<ContentLoader style={{ width: 106, height: 106, borderRadius: '100%' }} />
									</div>
									<div className='profile-visiting-info-text'>
										<div className='profile-visiting-info-title'>
											<ContentLoader style={{ width: 100, height: 20 }} />
										</div>
										<div className='profile-visiting-info-user'>
											<ContentLoader style={{ width: 180, height: 20 }} />
										</div>
										<div className='profile-visiting-info-numbers'>
											<ContentLoader style={{ width: 156, height: 12 }} />
										</div>
									</div>
								</div>
							</React.Fragment>
						}
					</React.Fragment>
				}
			</div>
		);
	}
}

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			did_mount: false,
			editable: false,
			spotify_stats: false,
			magroove_stats: false,
			user_polls: 'loading',
			user_leaderboard: [],
			likeminded_users: [],
			following_artists: [],
			display_follow_list: false,
			user_quiz: [],
			album_grid_data: null,
			profile_slug: '',
			own_profile: true,
			magroove_stats_loading: false,
			people_you_may_know: [],
			people_you_may_know_loading: true,
			following_artists_index: 3,
			following_users: [],
			followers: [],
			show_my_recommendation: false,
			latest_recommendation: null
		};

		this.profile_picture_loaded = false;

		// Element references
		this.album_grid = React.createRef();
		this.album_grid_clone_slot = React.createRef();
		this.cards_container = React.createRef();
		this.first_stats_card = React.createRef();
		this.second_stats_card = React.createRef();
		this.my_stats_clone_slot = React.createRef();

		this.state.profile_slug = this.props.url.params.slug;

		// Bind functions
		this.handle_follow_action = this.handle_follow_action.bind(this);
		this.sort_leaderboard = this.sort_leaderboard.bind(this);
		this.handle_change = this.handle_change.bind(this);

		this.get_magroove_stats = this.get_magroove_stats.bind(this);
		this.get_spotify_stats = this.get_spotify_stats.bind(this);
		this.get_user_leaderboard = this.get_user_leaderboard.bind(this);
		this.get_user_quiz = this.get_user_quiz.bind(this);
		this.get_likeminded_users = this.get_likeminded_users.bind(this);
		this.get_profile_info = this.get_profile_info.bind(this);
		this.set_new_profile_info = this.set_new_profile_info.bind(this);
		this.follow_user_management = this.follow_user_management.bind(this);
		this.follow_artist_management = this.follow_artist_management.bind(this);
		this.update_entity_follow_status = this.update_entity_follow_status.bind(this);
		this.recommendation_time_limit = this.recommendation_time_limit.bind(this);

		if (this.props.profile_info === undefined) {
			store_set('profile_info', {});
		}

		this.state.own_profile = this.state.profile_slug.length === 0 || this.state.profile_slug === this.props.user.slug;
	}

	componentDidMount(){
		if (this.state.own_profile){
			this.get_spotify_stats();
			this.get_magroove_stats();
			this.get_user_leaderboard();
			social.get_following('user', (data) => this.setState({ following_users: data }));
			social.get_followers((data) => this.setState({ followers: data }));
			social.get_following('artist', (data) => this.setState({ following_artists: data }));
			social.get_user_polls((value) => this.setState({user_polls: value}));
			this.get_user_quiz();
			this.get_likeminded_users();
		} else {
			this.get_profile_info();
		}

		const scrollable_element = document.querySelector('.main-content-inner');
		scrollable_element.onscroll = this.handle_scroll; // For Chrome
		scrollable_element.onwheel = this.handle_scroll; // For Firefox
		scrollable_element.ontouchmove = this.handle_scroll; // For mobile
		scrollable_element.onmousewheel = this.handle_scroll; // For IE
		this.check_scrollTop();

		this.setState({ did_mount: true });

		if (store_get('active_overlay') === 'follow_user_list'){
			store_set('active_overlay', false);
		}
	}

	handle_change(key, value) {
		this.setState({ [key]: value });
	}

	get_profile_info() {
		const user_logged_hash = this.props.user ? this.props.user.hash_id : false;

		// We won't allow slug with less than 5 characters
		let slug = this.state.profile_slug;
		if (slug.length < 5) {
			redirect('/'); return;
		}
		let key_type = 'slug';

		// Check if we already have the profile info stored in redux
		let is_profile_info = false;
		if (this.props.profile_info && Object.keys(this.props.profile_info).length > 0) {
			if ('hash_id' in this.props.profile_info && (this.props.profile_info.hash_id.substring(0, 10) === this.state.profile_slug || this.props.profile_info.slug === this.state.profile_slug)) {
				is_profile_info = true;
			}
		}
		if (is_profile_info) {
			if ('slug' in this.props.profile_info && this.props.profile_info.slug.length > 0) {
				slug = this.props.profile_info.slug;
			} else if ('hash_id' in this.props.profile_info) {
				slug = this.props.profile_info.hash_id;
				key_type = 'hash_id';
			}
		}

		store_set('profile_info', {});
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_profile,
			data: { slug: slug, key_type: key_type, user_logged_hash: user_logged_hash },
			success: function (data) {
				if (data.status === 'success') {
					store_set('profile_info', data.data.user);
					if (this.state.own_profile && data.data.user.fine_tunning && Object.keys(data.data.user.fine_tunning).length > 0) {
						store_set('fine_tunning', data.data.user.fine_tunning);
					}

					this.get_spotify_stats();
					this.get_magroove_stats();
					this.get_user_quiz();
					social.get_user_polls((value) => this.setState({user_polls: value}), false);
					social.get_following('user', (data) => this.setState({ following_users: data }), false);
					social.get_followers((data) => this.setState({ followers: data }), false);
					social.get_following('artist', (data) => this.setState({ following_artists: data }), false);
					this.get_user_leaderboard();
					this.recommendation_time_limit();

				} else if(data.status === 'error' && data.reason === 'user_not_found'){
					redirect('/');
				}
			}.bind(this)
		});
	}

	set_new_profile_info(data) {
		store_set('profile_info', {
			'followers': data.followers,
			'hash_id': data.hash_id,
			'picture': data.picture,
			'slug': data.slug,
			'member_id': data.member_id,
		});
	}

	componentWillUnmount() {
		clearInterval(this.scroll_interval);
	}

	handle_scroll(){
		// Make sure we set a background color in header (so he can easily see the content inside)
		// but just when user scrolls the page down
		if (!window.location.pathname.includes('/profile')) {
			return;
		}

		const scrollable_element = document.querySelector('.main-content-inner > div');
		const profile_header = document.getElementById('more-header');
		if (!profile_header) return;

		if (scrollable_element.scrollTop !== 0){
			profile_header.classList.add('more-header-fixed');
		} else {
			profile_header.classList.remove('more-header-fixed');
		}
	}

	check_scrollTop() {
		const scrollable_element = document.querySelector('.main-content-inner > div');
		if (scrollable_element) {
			if (scrollable_element.scrollTop < 60) {
				var profile_header = document.getElementById('more-header');
				if (profile_header) profile_header.classList.remove('more-header-fixed');
			}
		}
		this.scroll_interval = setInterval(this.check_scrollTop, 1000);
	}

	get_magroove_stats(){
		// If magroove stats data does not correspond to the v1
		// we should fetch the data again
		const data_version = store_get('magroove_stats_version');
		const hash_id = this.state.own_profile ? this.props.user.hash_id : this.props.profile_info.hash_id;
		if (data_version === 'v1'){
			if (this.state.own_profile && Object.keys(this.props.magroove_stats).length > 0){
				this.setState({ magroove_stats: this.props.magroove_stats });
				return;
			}
			if (this.state.own_profile){
				const value = storage.get('magroove_stats');
				if (value && Object.keys(value).length > 0){
					store_set('magroove_stats', value);
					this.setState({magroove_stats: value});
					return;
				}
			}
		} else if (this.state.own_profile){
			store_set('magroove_stats', false);
			this.setState({magroove_stats: false});
		}

		// Check for user until it's loaded
		if (!hash_id) {
			setTimeout(this.get_magroove_stats, 1000);
			return;
		}

		this.setState({magroove_stats_loading: true});

		// Once loaded, fetch the magroove stats
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_magroove_stats,
			data: { member_hash_id: hash_id, my_member_hash_id: this.props.user.hash_id },
			success: function(data){
				if (data.status === 'success'){
					if (this.state.own_profile){
						store_set('magroove_stats', data.magroove_stats);
						store_set('magroove_stats_version', data.magroove_stats_version);
						storage.set('magroove_stats', data.magroove_stats, 86400);
					}
					this.setState({magroove_stats: data.magroove_stats});
				}
			}.bind(this),
			complete: function(){
				this.setState({magroove_stats_loading: false});
			}.bind(this),
		}, true);
	}

	get_spotify_stats(){
		if (this.state.own_profile && this.props.spotify_stats){
			this.setState({ spotify_stats: this.props.spotify_stats });
			social.get_people_you_may_know();
			return;
		}
		if (this.state.own_profile){
			const value = storage.get('spotify_stats');
			if (value && value.long_term && value.long_term.length > 0){
				social.get_people_you_may_know();
				this.setState({spotify_stats: value});
				store_set('spotify_stats', value);
				return;
			}
		}

		// Check for user until it's loaded
		const hash_id = this.state.own_profile ? this.props.user.hash_id : this.props.profile_info.hash_id;
		if (!hash_id) {
			setTimeout(this.get_spotify_stats, 1000);
			return;
		}
		// Once loaded, fetch the spotify stats
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_spotify_stats,
			data: { member_hash_id: hash_id },
			success: function (data) {
				var att_spotify_stats = false;
				if (data.status === 'success') {

					// Check if there is any data in the spotify_stats object
					[...Object.keys(data.spotify_stats.long_term)].forEach(key => {if (data.spotify_stats.long_term[key].length > 0) att_spotify_stats = true;});

					const spotify_stats = att_spotify_stats ? data.spotify_stats : false;
					if (this.state.own_profile && spotify_stats){
						store_set('spotify_stats', spotify_stats);
						storage.set('spotify_stats', spotify_stats, 86400);
					}
					this.setState({spotify_stats: spotify_stats});
				} else {
					if (this.state.own_profile) store_set('spotify_stats', att_spotify_stats);
					this.setState({spotify_stats: att_spotify_stats});
				}

				if (this.state.own_profile || !att_spotify_stats){
					social.get_people_you_may_know();
				}
			}.bind(this)
		}, true);
	}

	sort_leaderboard(data) {
		if (data.length === 0) { return; }

		// Add the current user to their friends list
		let user = this.state.own_profile ? this.props.user : this.props.profile_info;
		let new_data = data;
		data.push({
			name: user.first_name + ' ' + user.last_name,
			slug: user.slug,
			picture: user.picture,
			likes: user.likes,
			hash_id: user.hash_id,
		});

		// Sort list by highest number of likes
		let sorted_list = new_data.sort((a, b) => b.likes - a.likes);
		let sliced_list = [];

		// In case the user is the one with most likes, we display them on top of leaderboard
		if (sorted_list[0].hash_id === this.props.user.hash_id) {
			sliced_list = sorted_list.slice(0,5);
		} else {
			// Otherwise, current user must be on center
			// Find indexes
			let user_ix = sorted_list.findIndex(user => user.hash_id === this.props.user.hash_id);
			let start_ix = user_ix - 2;
			let len = sorted_list.length;

			// Slice list, the leaderboard must only show 5 members
			sliced_list = sorted_list.slice(start_ix, len).slice(0,5);
		}

		// We must only save on the store the logged user's data
		if (this.state.own_profile){
			store_set('user_leaderboard', sliced_list);
			storage.set('user_leaderboard', sliced_list, 86400);
		}
		this.setState({ user_leaderboard: sliced_list });
	}

	get_user_leaderboard(){
		if (this.state.own_profile && this.props.user_leaderboard.length >= 5){
			this.setState({ user_leaderboard: this.props.user_leaderboard });
			return;
		}
		if (this.state.own_profile){
			const value = storage.get('user_leaderboard');
			if (value && value.length >= 5){
				this.setState({user_leaderboard: value});
				store_set('user_leaderboard', value);
				return;
			}
		}

		// Check for user until it's loaded
		const hash_id = this.state.own_profile ? this.props.user.hash_id : this.props.profile_info.hash_id;
		if (!hash_id){
			setTimeout(this.get_user_leaderboard, 1000);
			return;
		}

		// Once loaded, fetch the user leaderboard
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_user_leaderboard,
			data: { member_hash_id: hash_id },
			success: function (data) {
				if (data.status === 'success') {
					this.sort_leaderboard(data.user_leaderboard);
				}
			}.bind(this)
		}, true);
	}

	get_user_quiz(){
		if (this.state.own_profile && this.props.user_quiz.length > 0){
			this.setState({ user_quiz: this.props.user_quiz });
			return;
		}

		if (this.state.own_profile){
			const value = storage.get('user_quiz');
			if (value && value.length > 0){
				this.setState({user_quiz: value});
				store_set('user_quiz', value);
				return;
			}
		}

		// Check for user until it's loaded
		const hash_id = this.state.own_profile ? this.props.user.hash_id : this.props.profile_info.hash_id;
		if (!hash_id){
			setTimeout(this.get_user_quiz, 1000);
			return;
		}

		// Once loaded, fetch the user quiz
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_user_quiz,
			data: { member_hash_id: hash_id },
			success: function(data){
				if (data.status === 'success'){
					let new_user_quiz = [...data.data];

					this.setState({user_quiz: new_user_quiz});
					if (this.state.own_profile){
						store_set('user_quiz', new_user_quiz);
						storage.set('user_quiz', new_user_quiz, 86400);
					}
				}
			}.bind(this)
		}, true);
	}

	get_likeminded_users(){
		if (this.state.own_profile && this.props.likeminded_users && this.props.likeminded_users.length > 0){
			const filtered = this.props.likeminded_users.filter(item => !item.followed);
			this.setState({ likeminded_users: filtered });
			return;
		}

		// Check for user until it's loaded
		const hash_id = this.state.own_profile ? this.props.user.hash_id : this.props.profile_info.hash_id;
		if (!hash_id) {
			setTimeout(this.get_likeminded_users, 1000);
			return;
		}

		// Once loaded, fetch the list of likeminded users
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_likeminded_users,
			data: { user_profile_hash_id: hash_id },
			success: function(data){
				if (data.status === 'success'){
					// We must only save on the store the logged user's data
					if (this.state.own_profile){
						store_set('likeminded_users', data.likeminded_users);
					}
					this.setState({ likeminded_users: data.likeminded_users });
				}
			}.bind(this)
		}, true);
	}

	handle_follow_action(user_id, type='likeminded_users') {
		if (!user_id) { return; }

		var user;
		if (type === 'likeminded_users'){
			const likeminded_users = [...this.props.likeminded_users];
			user = likeminded_users.find(u => u.user_id === user_id);
			if (!user) return;

			user.followed = !user.followed;
			this.setState({likeminded_users: likeminded_users});
			if (this.state.own_profile){
				store_set('likeminded_users', likeminded_users);
			}
		}
		else if (type === 'people_you_may_know'){
			const people_you_may_know = [...this.props.people_you_may_know];
			user = people_you_may_know.find(u => u.member_id === user_id);
			user.followed = !user.followed;
			this.setState({people_you_may_know: people_you_may_know});
		}

		const action = user.followed ? 'follow' : 'unfollow';
		this.update_entity_follow_status('user', action, user);
		social.follow_user(user_id);
	}

	update_entity_follow_status(type, action, entity){
		if (type === 'artist'){
			const new_list = this.update_following_artist(this.state.following_artists, entity, action);
			if (this.state.own_profile){
				store_set('following_artists', new_list);
			} else {
				const new_prop_list = this.update_following_artist(this.props.following_artists, entity, action);
				store_set('following_artists', new_prop_list);
			}
			this.setState({ following_artists: new_list });
			return;
		}

		// Manage member from now on
		if (action === 'unfollow'){
			social.remove_activity(entity.hash_id);
		}

		const new_following = this.update_following_user(this.state.following_users, entity, action);
		const new_followers = this.update_following_user(this.state.followers, entity, action, false);
		if (this.state.own_profile){
			store_set('following_users', new_following);
			store_set('followers', new_followers);
		} else {
			const new_prop_following = this.update_following_user(this.props.following_users, entity, action);
			const new_prop_followers = this.update_following_user(this.props.followers, entity, action, false);
			store_set('following_users', new_prop_following);
			store_set('followers', new_prop_followers);
		}

		if (this.props.profile_info.hash_id === entity.hash_id) {
			const update_profile_info = Object.assign({}, this.props.profile_info);
			update_profile_info.follow_status = !update_profile_info.follow_status;

			const user_followers = update_profile_info.followers;
			update_profile_info.followers = update_profile_info.follow_status ? user_followers + 1 : user_followers - 1;

			store_set('profile_info', update_profile_info);
		} else {
			this.setState({ following_users: new_following, followers: new_followers });
		}
	}

	update_following_user(current_array, member, action, add_option = this.state.own_profile){
		const new_list = [...current_array];
		const item = new_list.find(m => m.member_id === member.member_id);
		if (!item && action === 'follow' && add_option){
			member.followed = true;
			member.followers = member.followers + 1;
			return [member, ...current_array];
		}
		if (!item){
			return new_list;
		}

		if (action === 'follow') {
			item.followed = true;
			item.followers = item.followers + 1;

		} else {
			item.followed = false;
			item.followers = item.followers - 1;
		}

		return new_list;
	}

	update_following_artist(current_array, artist, action){
		const new_list = [...current_array];
		const item = new_list.find(a => a.spotify_id === artist.spotify_id);
		if (!item && action === 'follow'){
			artist.is_following = true;
			artist.followers = artist.followers + 1;
			return [artist, ...current_array];
		} 
		if (!item){
			return new_list;
		}

		if (action === 'follow') {
			item.is_following = true;
			item.followers = item.followers + 1;

		} else {
			item.is_following = false;
			item.followers = item.followers - 1;
		}

		return new_list;
	}

	follow_artist_management(action, artist) {
		// Update the following status immediately
		this.update_entity_follow_status('artist', action, artist);
		social.follow_artist(artist.spotify_id, action);
	}

	follow_user_management(action, user) {
		// Update the following status immediately
		this.update_entity_follow_status('user', action, user);
		social.follow_user(user.member_id);
	}

	recommendation_time_limit() {
		let my_recommendations = [...this.props.my_recommendations];
		let latest_recommendation = my_recommendations.find(r => Number(r.profile_id) === this.props.profile_info.member_id);
		if (latest_recommendation) {

			const user = this.props.user;
			latest_recommendation['user'] = {
				name: user.first_name + ' ' + user.last_name,
				slug: user.slug ? user.slug : user.hash_id.substring(0,10),
				hash_id: user.hash_id,
				picture: user.picture
			};

			let date_limit = new Date(latest_recommendation.limit);
			let limit = new Date(date_limit.getTime() + (24 * 60 * 60 * 1000));
			let today = new Date();
			this.setState({
				'show_my_recommendation': today >= limit ? false : true,
				'latest_recommendation': latest_recommendation
			});
			store_set('recommendation_info', latest_recommendation);
		}
	}

	render(){
		const {
			spotify_stats,
			magroove_stats,
			likeminded_users,
			user_leaderboard,
			following_artists,
			following_users,
			followers,
			show_my_recommendation,
			latest_recommendation,
			user_quiz,
			own_profile,
		} = this.state;

		let user = own_profile ? this.props.user : this.props.profile_info;
		if (user === undefined) {
			user = {};
		}

		const empty_profile = user.seeds === 0 && user.tracks === 0 && user.likes === 0  && user.dislikes === 0 && following_artists.length === 0 && this.state.following_users.length === 0 && !spotify_stats && likeminded_users.length === 0;

		let logged_following_artists = this.props.following_artists;
		let artist_matches = false;
		if (following_artists.length > 0) {
			artist_matches = following_artists.filter(a1 => following_artists.some(a2 => a1.hash_id === a2.hash_id));
		}

		const user_pronouns = user.pronouns === 'he/him' ? ['him','his'] : (user.pronouns === 'she/her' ? ['her','her'] : ['them','their']);
		const display_user = this.state.profile_slug.length > 0 ? this.props.profile_info.first_name : 'Me';

		const options = user.showcase || [];
		const top_artists_section = options.find(item => item.section === 'top_artists');
		const top_albums_section = options.find(item => item.section === 'top_albums');
		const top_songs_section = options.find(item => item.section === 'top_songs');

		const people_you_may_know = this.props.people_you_may_know;

		const top_artists = user.top_artists && (user.top_artists.length > 0 || top_artists_section.spotify_stats === false) ? user.top_artists : spotify_stats ? spotify_stats.long_term.artists : [];
		const top_albums = user.top_albums && (user.top_albums.length > 0 || top_albums_section.spotify_stats === false) ? user.top_albums : spotify_stats ? spotify_stats.long_term.albums : [];
		const top_songs = user.top_songs && (user.top_songs.length > 0|| top_songs_section.spotify_stats === false) ? user.top_songs : spotify_stats ? spotify_stats.long_term.songs : [];
		artist_matches = logged_following_artists.filter(a1 => following_artists.some(a2 => a1.hash_id === a2.hash_id));

		const empty_aspects_data = !magroove_stats.aspects_data || !magroove_stats.aspects_data.find(i => i.aspect_level);
		const empty_discovery_timeline = !magroove_stats.timeline_data || (magroove_stats.timeline_data.user_interactions && !magroove_stats.timeline_data.user_interactions.find(i => i.interactions));

		const sections = {
			discovery_leaderboard: (
				<GenericSection title='Discovery Leaderboard' component={<DiscoveryLeaderboard data={user_leaderboard} user={user} />} disabled={user_leaderboard.length !== 5} />
			),
			discovery_timeline: (
				<GenericSection title='Discovery Timeline' chart={true} component={<DiscoveryTimeline data={magroove_stats.timeline_data} display_user={display_user} />} disabled={empty_discovery_timeline}/>
			),
			following_artists: (
				<FollowingSection type='Artists' data={following_artists} follow_artist_management={this.follow_artist_management} display_follow_list={(value) => {store_set('active_overlay', 'follow_overlay');this.setState({ display_follow_list: value });}} />
			),
			following_users: (
				<FollowingSection type='Users' data={following_users} follow_user_management={this.follow_user_management} display_follow_list={(value) => {store_set('active_overlay', 'follow_overlay');this.setState({ display_follow_list: value });}} />
			),
			genre_chart: (
				<GenericSection title='Genre Chart' chart={true} component={<GenreChart data={magroove_stats} my_data={this.props.magroove_stats} own_profile={own_profile} display_user={display_user} />} disabled={empty_aspects_data} />
			),
			music_aspects: (
				<GenericSection title='Music Aspects' chart={true} component={<MusicAspects data={magroove_stats} my_data={this.props.magroove_stats} own_profile={own_profile} display_user={display_user} />} disabled={empty_aspects_data} />
			),
			music_taste: (
				<GenericSection title='Music Taste' chart={true} component={<MusicTaste data={magroove_stats} my_data={this.props.magroove_stats} own_profile={own_profile} display_user={display_user} />} disabled={empty_aspects_data}/>
			),
			overview: (
				<OverviewSection user={user} />
			),
			top_artists: (
				<GenericSection title='Top Artists' component={<TopArtists data={top_artists} />} disabled={top_artists.length === 0} />
			),
			top_albums: (
				<GenericSection title='Top Albums' component={<TopAlbums data={top_albums} />} disabled={top_albums.length === 0} />
			),
			top_songs: (
				<GenericSection title='Top Tracks' component={<TopTracks data={top_songs} />} disabled={top_songs.length === 0} />
			),
			obsession: (
				<ObsessionSection own_profile={own_profile} user={user} logged_user={this.props.user} />
			),
			quizes: (
				<QuizSection data={user_quiz} own_profile={own_profile} />
			),
			polls: (
				<PollSection data={this.state.user_polls} own_profile={own_profile} user={user} logged_user={this.props.user} />
			)
		};

		const default_options = [{section: 'overview', visibility: 'on'}];
		const sorted_options = options.filter(item => item.visibility === 'on').sort((a, b) => a.ordinal - b.ordinal);
		const render_options = sorted_options.length > 0 ? sorted_options : default_options;

		const active_overlay = this.props.active_overlay;
		const follow_type = this.state.display_follow_list;
		if (active_overlay === 'follow_overlay' && follow_type) {
			const follow_data = follow_type === 'followers' ? followers : follow_type === 'following_artists' ? following_artists : following_users;

			return (
				<FollowOverlay
					data={follow_data}
					follow_type={follow_type}
					user={this.props.user}
					profile_info={user}
					safe_area_top={this.props.safe_area_top}
					follow_profile={this.follow_user_management}
					follow_artist={this.follow_artist_management}
					onClose={() => {store_set('active_overlay', false); this.setState({ display_follow_list: false });}}
				/>
			);
		}
		return (
			<div className='profile'>
				<ProfileTopContainer
					{...this.state}
					user={user}
					logged_user={this.props.user}
					follow_user_management={this.follow_user_management}
					handle_change={this.handle_change}
					display_follow_list={(value) => {store_set('active_overlay', 'follow_overlay'); this.setState({ display_follow_list: value });}}
				/>
				{this.state.profile_slug.length > 0 && Object.keys(user).length > 0 &&
					<React.Fragment>
						{show_my_recommendation && latest_recommendation ?
							<div className='profile-center-section'>
								<Card type='recommendation' data={latest_recommendation} onClick={() => {store_set('active_overlay', 'track_options'); store_set('song_info', latest_recommendation.recommendation);}} hide_interaction={true} />
							</div>
							:
							(!empty_profile&&
								<div className='profile-section'>
									<Link to='/recommendation-search/' className='profile-share-song-container' onClick={() => store_set('recommendation_return_page', window.location.pathname)}>
										<div className='profile-share-song-info'>
											<div className='profile-share-song-title'>
												<div className='profile-share-song-title-text'>{artist_matches.length > 0 ? 'IT\'S A MATCH' : 'SHARE A SONG'}</div>
												<div className='profile-share-song-title-shadow'>{artist_matches.length > 0 ? 'IT\'S A MATCH' : 'SHARE A SONG'}</div>
											</div>
											<div className='profile-share-song-content'>
												<div className='profile-share-song-icons'>
													<Img src={[this.props.user.picture, assets.default_profile_image]} alt='' />
													<span className='profile-share-song-icons-symbol'>+</span>
													<Img src={[user.picture, assets.default_profile_image]} alt='' />
												</div>
												{artist_matches.length > 0 ?
													<div className='profile-share-song-subtitle'>Both of you listen 
												to <span>{artist_matches[0].name}{artist_matches.length -1 > 0 ? ' and ' + (artist_matches.length -1) + ' more' : ''}</span>. Try suggesting something to {user_pronouns[0]}!</div>
													:
													<div className='profile-share-song-subtitle'>You can now post a recommendation on {user_pronouns[1]} profile page!</div>
												}	
											</div>
										</div>
										<div className='profile-share-song-handle'>
											<span className='material-icons'>chevron_right</span>
										</div>
									</Link>
								</div>
							)
						}
					</React.Fragment>
				}
				{/* Empty profile page */}
				{this.state.profile_slug.length > 0 && empty_profile && !this.state.magroove_stats_loading &&
					<div className='profile-section-empty'>
						<div className='profile-section-empty-song-recommendation'>
							<figure className='profile-section-empty-song-recommendation-icon'>
								<img src={assets.profile_empty_song_recommendation_icon} alt='Empty profile chart'></img>
							</figure>
							<div className='profile-section-empty-song-recommendation-content'>
								<figure className='profile-section-empty-song-recommendation-title'>
									<img src={assets.profile_empty_song_recommendation_title} alt='Share a song!'></img>
								</figure>
								<p className='profile-section-empty-song-recommendation-text'>This user haven&apos;t been much active, but you can share  a song to help!</p>
								<Link to='/recommendation-search/' className='profile-section-empty-song-recommendation-button'>RECOMMEND</Link>
							</div>
						</div>
					</div>
				}
				{render_options.map((item, index) => {
					const section = sections[item.section];
					return (
						<React.Fragment key={index}>
							{index === 0 && own_profile && people_you_may_know.length ? <GenericSection title='People You May Know' component={<PeopleYouMayKnow data={people_you_may_know} onFollow={this.handle_follow_action}/>} /> : null}
							{section ? section : null}
							{index === 0 ? <AdUnit height={60} /> : null}
							{index === 0 && own_profile ? <DiscoverMoreSection user={user} linked_accounts={this.props.linked_accounts} playlists={this.props.playlists} /> : null}
							{/* ************************************************ */}
							{/* The components below should be commented for now */}
							{/* {item.section === 'top_albums' && own_profile && did_mount && (spotify_stats && spotify_stats.long_term.albums.length >= 16) ? <Shareable spotify_stats={spotify_stats} type='album_grid' /> : null} */}
							{/* {item.section === 'top_artists' && own_profile && did_mount && (spotify_stats && spotify_stats.long_term.songs.length >= 5 && spotify_stats.long_term.artists.length >= 5) ? <Shareable spotify_stats={spotify_stats} type='spotify_stats' user={this.props.user} /> : null} */}
							{index === render_options.length - 1 ? 
								<React.Fragment>
									<LikemindedSection data={likeminded_users} handle_follow_action={this.handle_follow_action} />
								</React.Fragment>
								: null
							}
						</React.Fragment>
					);
				})}
				{this.state.magroove_stats_loading &&
					<div className='profile-stats-loading'>
						<CircularProgress size={65} />
					</div>
				}
				<div className='profile-margin'></div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		user_quiz: state.GlobalReducer.user_quiz,
		profile_info: state.GlobalReducer.profile_info,
		spotify_stats: state.GlobalReducer.spotify_stats,
		magroove_stats: state.GlobalReducer.magroove_stats,
		user_leaderboard: state.GlobalReducer.user_leaderboard,
		likeminded_users: state.GlobalReducer.likeminded_users,
		user_token: state.GlobalReducer.user_token,
		linked_accounts: state.GlobalReducer.linked_accounts,
		following_artists: state.GlobalReducer.following_artists,
		following_users: state.GlobalReducer.following_users,
		followers: state.GlobalReducer.followers,
		playlists: state.GlobalReducer.playlists,
		selected_search_items: state.GlobalReducer.selected_search_items,
		my_recommendations: state.GlobalReducer.my_recommendations,
		active_overlay: state.GlobalReducer.active_overlay,
		safe_area_top: state.GlobalReducer.safe_area_top,
		people_you_may_know: state.GlobalReducer.people_you_may_know,
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(Profile));
