import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/SyncPlaylists.css';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// Assets Imports
import * as assets from '@Assets';

// Components and Views Imports
import { redirect } from '@routes/Routes.js';

// Import controllers
import export_track from '@Export';
import subscription from '@Subscription';

class SyncPlaylists extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.redirect_to_playlists = this.redirect_to_playlists.bind(this);
	}

	redirect_to_playlists(dsp) {
		// Display invitation for premium if the user is not premium yet.
		if (!subscription.is_valid()) {
			subscription.require('sync', <div>Subscribing now grants you <strong>automatically sync</strong> to your selected playlists</div>, 'playlists_sync', 'Sync is only enabled for premium subscribers!');
			return;
		}

		if (this.props.linked_accounts.some(account => account.platform === dsp && account.state === 'active')) {
			// Sets the chosen dsp so the correct list of playlists can be retrieved,
			// calls the get_playlists function to update them should be necessary
			store_set('chosen_dsp', dsp);
			export_track.get_playlists(dsp);
			redirect('/my-playlists/');
		} else {
			// If account is not synced for that dsp, redirects to login flow
			export_track.open_login_window(dsp, '/sync-playlists/');
		}
	}

	render() {
		var spotify_is_linked = this.props.linked_accounts.filter(account => account.platform === 'spotify' && account.state === 'active').length > 0;
		var deezer_is_linked = this.props.linked_accounts.filter(account => account.platform === 'deezer' && account.state === 'active').length > 0;
		return (
			<div className='sync-playlists-main-container'>
				<div className='sync-playlists-header'>Sync your streaming app account to fully enjoy all your new songs!</div>
				<div className='sync-playlists-content'>
					<div className='sync-playlists-content-title'>Streaming services</div>
					<div className='sync-playlists-box' onClick={() => this.redirect_to_playlists('spotify')}>
						<div className='sync-playlists-box-info'>
							<div className='sync-playlists-box-info-image'>
								<img src={assets.spotify_round_icon} alt='account-pic' />
							</div>
							<div className='sync-playlists-box-info-description'>Sync your Spotify account</div>
						</div>
						<div className='sync-playlists-box-button'>
							{spotify_is_linked ?
								<span className='material-icons' style={{ color: '#ec6b43' }}>check</span>
								:
								<span className='material-icons'>keyboard_arrow_right</span>
							}
						</div>
					</div>
					<div className='sync-playlists-box' onClick={() => this.redirect_to_playlists('deezer')}>
						<div className='sync-playlists-box-info'>
							<div className='sync-playlists-box-info-image'>
								<img src={assets.deezer_icon} alt='account-pic' />
							</div>
							<div className='sync-playlists-box-info-description'>Sync your Deezer account</div>
						</div>
						<div className='sync-playlists-box-button'>
							{deezer_is_linked ?
								<span className='material-icons' style={{ color: '#ec6b43' }}>check</span>
								:
								<span className='material-icons'>keyboard_arrow_right</span>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		linked_accounts: state.GlobalReducer.linked_accounts
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(SyncPlaylists));