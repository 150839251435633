const animationData = {
	'v': '5.9.0',
	'fr': 25,
	'ip': 0,
	'op': 60,
	'w': 375,
	'h': 667,
	'nm': 'Composição 1',
	'ddd': 0,
	'assets': [],
	'layers': [
		{
			'ddd': 0,
			'ind': 1,
			'ty': 4,
			'nm': 'Camada de forma 16',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						336.694,
						460.039,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								45,
								45.202,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								90,
								90.404,
								100
							]
						},
						{
							't': 60,
							's': [
								45,
								45.202,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 2,
			'ty': 4,
			'nm': 'Camada de forma 13',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						333.194,
						214.726,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								45,
								45.202,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								90,
								90.404,
								100
							]
						},
						{
							't': 60,
							's': [
								45,
								45.202,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 3,
			'ty': 4,
			'nm': 'Camada de forma 12',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						304.444,
						131.476,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								45,
								45.202,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								90,
								90.404,
								100
							]
						},
						{
							't': 60,
							's': [
								45,
								45.202,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 4,
			'ty': 4,
			'nm': 'Camada de forma 3',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						46.944,
						171.726,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								45,
								45.202,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								90,
								90.404,
								100
							]
						},
						{
							't': 60,
							's': [
								45,
								45.202,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 5,
			'ty': 4,
			'nm': 'Camada de forma 15',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						332.433,
						171.864,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								35,
								35.157,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								75,
								75.337,
								100
							]
						},
						{
							't': 60,
							's': [
								35,
								35.157,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 6,
			'ty': 4,
			'nm': 'Camada de forma 14',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						323.712,
						123.772,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								80,
								80.359,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								40,
								40.179,
								100
							]
						},
						{
							't': 60,
							's': [
								80,
								80.359,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 7,
			'ty': 4,
			'nm': 'Camada de forma 2',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						50.787,
						145.233,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								80,
								80.359,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								45,
								45.202,
								100
							]
						},
						{
							't': 60,
							's': [
								80,
								80.359,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									0.925490196078,
									0.419607843137,
									0.262745098039,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 2,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 8,
			'ty': 4,
			'nm': 'Camada de forma 8',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						269.887,
						118.488,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								60,
								60,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								115,
								115,
								100
							]
						},
						{
							't': 60,
							's': [
								60,
								60,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 9,
			'ty': 4,
			'nm': 'Camada de forma 7',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						334.137,
						242.488,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								60,
								60,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								110,
								110,
								100
							]
						},
						{
							't': 60,
							's': [
								60,
								60,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 10,
			'ty': 4,
			'nm': 'Camada de forma 6',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						47.638,
						222.987,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-221.583,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								60,
								60,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								110,
								110,
								100
							]
						},
						{
							't': 60,
							's': [
								60,
								60,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 11,
			'ty': 4,
			'nm': 'Camada de forma 11',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						47.95,
						559.175,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-221.205,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								60,
								60,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								110,
								110,
								100
							]
						},
						{
							't': 60,
							's': [
								60,
								60,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 12,
			'ty': 4,
			'nm': 'Camada de forma 10',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						26.915,
						550.16,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								92,
								92,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								45,
								45,
								100
							]
						},
						{
							't': 60,
							's': [
								92,
								92,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 13,
			'ty': 4,
			'nm': 'Camada de forma 9',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						351.227,
						437.347,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								92,
								92,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								50,
								50,
								100
							]
						},
						{
							't': 60,
							's': [
								92,
								92,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 14,
			'ty': 4,
			'nm': 'Camada de forma 5',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						68.227,
						206.972,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								92,
								92,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								40,
								40,
								100
							]
						},
						{
							't': 60,
							's': [
								92,
								92,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 15,
			'ty': 4,
			'nm': 'Camada de forma 4',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						309.25,
						206,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								100,
								100,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								50,
								50,
								100
							]
						},
						{
							't': 60,
							's': [
								100,
								100,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		},
		{
			'ddd': 0,
			'ind': 16,
			'ty': 4,
			'nm': 'Camada de forma 1',
			'sr': 1,
			'ks': {
				'o': {
					'a': 0,
					'k': 40,
					'ix': 11
				},
				'r': {
					'a': 0,
					'k': 0,
					'ix': 10
				},
				'p': {
					'a': 0,
					'k': [
						87,
						112.75,
						0
					],
					'ix': 2,
					'l': 2
				},
				'a': {
					'a': 0,
					'k': [
						-100.5,
						-220.75,
						0
					],
					'ix': 1,
					'l': 2
				},
				's': {
					'a': 1,
					'k': [
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 0,
							's': [
								100,
								100,
								100
							]
						},
						{
							'i': {
								'x': [
									0.833,
									0.833,
									0.833
								],
								'y': [
									0.833,
									0.833,
									0.833
								]
							},
							'o': {
								'x': [
									0.167,
									0.167,
									0.167
								],
								'y': [
									0.167,
									0.167,
									0.167
								]
							},
							't': 30,
							's': [
								60,
								60,
								100
							]
						},
						{
							't': 60,
							's': [
								100,
								100,
								100
							]
						}
					],
					'ix': 6,
					'l': 2
				}
			},
			'ao': 0,
			'shapes': [
				{
					'ty': 'gr',
					'it': [
						{
							'd': 1,
							'ty': 'el',
							's': {
								'a': 0,
								'k': [
									16.5,
									16.5
								],
								'ix': 2
							},
							'p': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 3
							},
							'nm': 'Caminho da elipse 1',
							'mn': 'ADBE Vector Shape - Ellipse',
							'hd': false
						},
						{
							'ty': 'st',
							'c': {
								'a': 0,
								'k': [
									1,
									1,
									1,
									1
								],
								'ix': 3
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 4
							},
							'w': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'lc': 1,
							'lj': 1,
							'ml': 4,
							'bm': 0,
							'nm': 'Traçado 1',
							'mn': 'ADBE Vector Graphic - Stroke',
							'hd': false
						},
						{
							'ty': 'fl',
							'c': {
								'a': 0,
								'k': [
									0.925490255917,
									0.419607873056,
									0.262745098039,
									1
								],
								'ix': 4
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 5
							},
							'r': 1,
							'bm': 0,
							'nm': 'Preenchimento 1',
							'mn': 'ADBE Vector Graphic - Fill',
							'hd': false
						},
						{
							'ty': 'tr',
							'p': {
								'a': 0,
								'k': [
									-100.5,
									-220.75
								],
								'ix': 2
							},
							'a': {
								'a': 0,
								'k': [
									0,
									0
								],
								'ix': 1
							},
							's': {
								'a': 0,
								'k': [
									100,
									100
								],
								'ix': 3
							},
							'r': {
								'a': 0,
								'k': 0,
								'ix': 6
							},
							'o': {
								'a': 0,
								'k': 100,
								'ix': 7
							},
							'sk': {
								'a': 0,
								'k': 0,
								'ix': 4
							},
							'sa': {
								'a': 0,
								'k': 0,
								'ix': 5
							},
							'nm': 'Transformar'
						}
					],
					'nm': 'Elipse 1',
					'np': 3,
					'cix': 2,
					'bm': 0,
					'ix': 1,
					'mn': 'ADBE Vector Group',
					'hd': false
				}
			],
			'ip': 0,
			'op': 750,
			'st': 0,
			'bm': 0
		}
	],
	'markers': []
}

export default animationData;