import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ripples from 'react-ripples';
import { Button } from '@magroove/magroove-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { error_boundary_hoc } from '@components/ErrorBoundary.js';

// CSS Imports
import './styles/LoadingOverlay.css';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

class LoadingOverlay extends Component {
	render() {
		var loading_overlay_display = this.props.loading_overlay_display;
		var loading_overlay_component = this.props.loading_overlay_component;
		var loading_overlay_top_text = this.props.loading_overlay_top_text;
		var loading_overlay_text = this.props.loading_overlay_text;
		var loading_overlay_action = this.props.loading_overlay_action;
		return(
			<div className='loading-overlay-open' style={loading_overlay_display ? {} : {display: 'none'}}>
				{loading_overlay_component &&
					<div className='loading-overlay-component'>
						{loading_overlay_component === 'wifi' &&
							<span className='material-icons'>
								{loading_overlay_component}
							</span>
						}
						{loading_overlay_component === 'circular-progress' &&
							<CircularProgress />
						}
					</div>
				}
				{loading_overlay_top_text &&
					<div className='loading-overlay-top-text'>
						{loading_overlay_top_text}
					</div>
				}
				{loading_overlay_text &&
					<div className='loading-overlay-text'>
						{loading_overlay_text}
					</div>
				}
				{loading_overlay_action &&
					<div className='loading-overlay-action'>
						{loading_overlay_action.length > 0 &&
							loading_overlay_action.map((element, index) => (
								<Ripples key={index}>
									<Button value={element.label} size='small' fullWidth={true} onClick={() => {element.function(); store_set('loading_overlay_display', false);}}/>
								</Ripples>
							))
						}
					</div>
				}
			</div>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		loading_overlay_display: state.GlobalReducer.loading_overlay_display,
		loading_overlay_component: state.GlobalReducer.loading_overlay_component,
		loading_overlay_top_text: state.GlobalReducer.loading_overlay_top_text,
		loading_overlay_text: state.GlobalReducer.loading_overlay_text,
		loading_overlay_action: state.GlobalReducer.loading_overlay_action
	};
}

// Connect component to the store
export default error_boundary_hoc(connect(mapStateToProps)(LoadingOverlay));