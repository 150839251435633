import localForage from 'localforage';
import moment from 'moment';

// Actions import
import { store_get } from '@/actions/GlobalActions.js';

// Import the event bus
import event_bus from '@Eventbus';

class FileManager{
	constructor() {
		this.get = this.get.bind(this);
		this.load = this.load.bind(this);
		this.preload_queue = this.preload_queue.bind(this);
		this.collect_garbage = this.collect_garbage.bind(this);

		event_bus.on('store-loaded', this.preload_queue);

		this.collect_garbage();
		// Call the gargabe collection every 5 minutes
		setInterval(this.collect_garbage, 300000);
	}

	clear_all(){
		localForage.clear().then(function() {
			// Run this code once the database has been entirely deleted.
		}).catch(function() {
			// This code runs if there were any errors
		});
	}

	collect_garbage(){
		localForage.iterate(function(value, key) {
			// Resulting key/value pair -- this callback
			// will be executed for every item in the
			// database.
			var created_at = value.created_at;
			// Check if the data is older than one week. In this case, we remove it.
			if (moment(created_at).isBefore(moment().subtract(7, 'days'))){
				localForage.removeItem(key).then(function() {
					// Run this code once the key has been removed.
				}).catch(function() {
					// This code runs if there were any errors
				});
			}
		}).then(function() {
			// Iteration has completed
		}).catch(function() {
			// This code runs if there were any errors
		});
	}

	load(song, callback = () => {}){
		// Chek if the song is already saved in localForage. In this case, there's
		// no need to fetch again.
		this.get(song, function(value){
			if (value){
				callback(value);
				return;
			}

			fetch(song.preview_url)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					// Here's where we get access to the blob
					// Now let's convert it to base64
					var reader = new FileReader();
					reader.readAsDataURL(blob); 
					reader.onloadend = function() {
						var base64data = reader.result;

						// Once converted, we can save in localForage
						var hash_id = song.hash_id ? song.hash_id : song.spotify_id;
						var data = {
							base64: base64data,
							created_at: new Date()
						};

						localForage.setItem(hash_id, data).then(function (value) {
							// Do other things once the value has been saved.
							callback(value);
						}).catch(function() {
							// This code runs if there were any errors
							callback(false);
						});
					};
				});
		});
	}

	preload_queue(){
		var queue = store_get('queue');
		queue.forEach(song => this.load(song));
	}

	get(song, callback){
		var hash_id = song.hash_id ? song.hash_id : song.spotify_id;

		localForage.getItem(hash_id).then(function(value) {
			// This code runs once the value has been loaded
			// from the offline store.
			callback(value.base64);
		}).catch(function() {
			// This code runs if there were any errors
			callback(false);
		});
	}
}

const file = new FileManager();

export default file;