// Actions import
import { store_set, store_get } from '@actions/GlobalActions.js';

// Import the list controller
import interaction from './Interaction.js';
import user from '@User';

// Constant Imports
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from './RequestBuffer.js';

// Import the event bus
import event_bus from './Eventbus.js';

class ListController{
	constructor() {
		this.add = this.add.bind(this);
		this.remove = this.remove.bind(this);
		this.load = this.load.bind(this);

		event_bus.on('store-loaded', this.load);
	}

	add(song){
		// Retrieve the current list
		let likes_list = store_get('likes');

		// Add the song to the likes list
		likes_list = [...likes_list];
		likes_list.unshift(song);
		store_set('likes', likes_list);
	}

	remove(song, delay = 0){
		// Removed a track from the liked list + send that info to the API
		// IMPORTANT - Keep track of the successfully synced songs,
		// for the non successful, schelude a new ajax for t+X seconds
		// where X is double the previous interval time
		setTimeout(function() {
			var spotify_id = song.spotify_id;
			interaction.update_remote_status(song, 'removed');

			// Update likes list
			let likes_list = store_get('likes');
			likes_list = [...likes_list];
			likes_list = likes_list.filter(track => track.spotify_id !== spotify_id);
			store_set('likes', likes_list);
			store_set('active_overlay', false);
			event_bus.emit('update-likes-list');
		}, delay);
	}

	load(){
		var no_more_likes = store_get('no_more_likes');
		var likes_list = [...store_get('likes')];

		// For each ajax request, we get older results. In case there's none  yet, use
		// the current date so in case the user likes a track while the ajax is running,
		// we don't add the same track twice.
		var older_track = likes_list.length > 0 ? likes_list[likes_list.length - 1] : false;
		var last_datetime = older_track ? older_track.created_at : new Date().toISOString();

		if (user.is_logged() && !no_more_likes) {
			// Loads the tracks already liked from the API
			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_likes_list,
				data: {last_datetime: last_datetime},
				success: function(data){
					// In case of success, simply display the more complete list of likes and 
					// allow the user to trigger a load more again when scrolling all the way
					// to the bottom.
					if (data.status === 'success'){
						if (data.data.length === 0){
							store_set('no_more_likes', true);
						}
						likes_list = likes_list.concat(data.data);
						store_set('likes', likes_list);
					}
					// In case of failure, display the warning message and block new fetching
					// events.
					else{
						store_set('error_loading_older_likes', true);
					}
				},
				error: function(){
					store_set('error_loading_older_likes', true);
				},
				complete: function(){
					// The loading component should disappear in case of success or failure.
					store_set('loading_older_likes', false);
					event_bus.emit('expand-likes-list', null);
				}
			});
		}
	}
}

const list = new ListController();

export default list;