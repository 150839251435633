import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Img } from 'react-image';
import ContentLoader from 'react-content-loader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import SnackbarComponent from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import Ripples from 'react-ripples';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
	FacebookShareButton,
	TwitterShareButton,
} from 'react-share';

// Actions import
import { store_set } from '@/actions/GlobalActions.js';

// import the request_buffer
import { request_buffer } from '../RequestBuffer.js';

// Imports
import * as constants from '../constants.js';
import * as assets from '@Assets';

// Import Components
import Card from '@components/Card.js';

// CSS Imports
import './styles/DesktopAlbumPage.css';

function get_duration(ms) {
	var minutes = Math.floor(ms / 60000);
	var seconds = ((ms % 60000) / 1000).toFixed(0);
	return (seconds === 60 ? (minutes+1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
}

class DesktopAlbumPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			album_hash_id: null,
			object_info: false,
			backdrop: true,
			overlay: false,
			popover: false,
			second_popover: false,
			anchorEl: false,
			snackbar: false,
			snackbar_message: '',
			recommended_artist: [],
			recommended_albums: [],
			loading_recommendations: true,
		};

		var path = window.location.href;
		var album_hash_id = null;

		if (path.includes('album')) {
			album_hash_id = this.props.url.params.album_hash_id;
			this.state.album_hash_id = album_hash_id;
		}

		this.get_album_info = this.get_album_info.bind(this);
		this.get_object_links = this.get_object_links.bind(this);
		this.get_album_recommendations = this.get_album_recommendations.bind(this);

		this.get_album_info(album_hash_id);
	}

	get_album_info(hash_id){
		/* eslint-disable-next-line */
		this.state.backdrop = true
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint + '/album/',
			data: {hash_id: hash_id},
			success: function(data){
				if (data.status === 'success') {
					store_set('album_info', data);
					this.get_album_recommendations(hash_id);
				}
			}.bind(this),
			complete: function(){
				this.setState({backdrop: false});
			}.bind(this),
		});
	}

	get_album_recommendations(hash_id){
		this.setState({loading_recommendations: true});
		request_buffer.execute({
			type: 'get',
			url: constants.api_endpoint_get_album_recommendations,
			data: {album_hash: hash_id},
			success: function(data){
				if (data.status === 'success') {
					this.setState({
						recommended_artist: data.data.recommended_artist,
						recommended_albums: data.data.recommended_albums
					});
				}
			}.bind(this),
			complete: function(){
				this.setState({loading_recommendations: false});
			}.bind(this),
		});
	}

	get_object_links(obj){
		this.setState({'overlay': true, 'loading': true});
		var object = obj || this.state.object_info;

		if (!object.itunes_link){
			object['itunes_link'] = '';
		}
		if (!object.deezer_link){
			object['deezer_link'] = '';
		}
		if (!object.spotify_link){
			object['spotify_link'] = '';
		}
		if (!object.youtube_link){
			object['youtube_link'] = '';
		}
		this.setState({'object_info': object});

		if (object.itunes_link && object.deezer_link && object.spotify_link && object.youtube_link){
			this.setState({'overlay': true});
			return;
		}

		let url = object.type === 'song' ? 'api_endpoint_get_song_links' : 'api_endpoint_get_album_links';
		let data = {};
		if (object.type === 'song') {
			data = {
				song_spotify_id: object.spotify_id,
				song_title: object.title,
				song_artist_name: object.artist.name
			};
		} else {
			data = {
				spotify_id: object.spotify_id
			};
		}

		request_buffer.execute({
			type: 'get',
			url: constants[url],
			data: data,
			success: function(data){
				if (data.status === 'success'){
					object['spotify_link'] = (data.final_dict.spotify.song || data.final_dict.spotify.release);
					object['itunes_link'] = data.final_dict.itunes.release;
					object['deezer_link'] = (data.final_dict.deezer.song || data.final_dict.deezer.release);
					if (object.type === 'song') {
						object['youtube_link'] = (data.final_dict.youtube.song || false);
					}
					store_set('object_info', object);
				}
			},
			complete: function(){
				this.setState({'overlay': true, 'loading': false});
			}.bind(this)
		});
	}

	render() {
		if (this.state.backdrop) {
			return(
				<React.Fragment>
					<Backdrop open={this.state.backdrop}>
						<CircularProgress color='inherit' />
					</Backdrop>
				</React.Fragment>
			);
		} else {
			var album_info = this.props.album_info;
			var { object_info, recommended_artist, recommended_albums } = this.state;
			return (
				<div className='app-desktop-album-page'>
					<div className='app-desktop-album-header-background' style={{backgroundImage: 'url(' + album_info.images.large + ')'}}>
						<div className='app-desktop-album-header-gradient'></div>
					</div>
					<div className='app-desktop-album-container'>
						<div className='app-desktop-album'>
							<div className='app-desktop-album-header'>
								<div className='app-desktop-album-header-image'>
									<Img
										src={[album_info.images.large, assets.default_album_image]}
										loader={
											<ContentLoader 
												width={'212px'}
												height={'212px'}
												backgroundColor={'#D8D8D8'}
												foregroundColor={'#b1afaf'}
											>
												<rect x='0' y='0' rx='0' ry='0' width={100 + '%'} height={100 + '%'} /> 
											</ContentLoader>
										}
									/>
								</div>
								<div className='app-desktop-album-header-description'>
									<div className='app-desktop-album-header-description-title'>
										<div className='app-desktop-album-header-description-text-title'>{album_info.title}</div>
										<Ripples>
											<div className='app-desktop-album-header-button' onClick={() => {this.setState({'object_info': album_info}); this.get_object_links(album_info);}}>
												<span className='material-icons'>play_arrow</span>
												<div className='app-desktop-album-header-button-text'>play full album</div>
											</div>
										</Ripples>
									</div>
									<div className='app-desktop-album-header-description-text-subtitle'>Album by <a href={'/artist/' + album_info.artist.spotify_id}>{album_info.artist.name}</a></div>
								</div>
							</div>
							<div className='app-desktop-album-body'>
								<div className='app-desktop-album-body-section'>
									{album_info.songs.map((song, i) => (
										<Ripples key={i}>
											<div className='app-desktop-album-song-card' id={'desktop-song-card-' + i} onClick={() => window.location.replace('/track/' + song.spotify_id)}>
												<div className='app-desktop-album-song-card-inner'>
													<div className='app-desktop-album-song-card-subtitle'>{i+1}</div>
													<div className='app-desktop-album-song-card-info'>
														<div className='app-desktop-album-song-card-title'
															style={(album_info.title.length > 40 && song.title.length > 40) || song.title.length > 50 ? {width: '60%', whiteSpace: 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis'}: {}}
														>{song.title}</div>
														<div className='app-desktop-album-song-card-subtitle'>{album_info.artist.name}</div>
													</div>
												</div>
												<div className='app-desktop-album-song-card-center'>
													<div className='app-desktop-album-song-card-subtitle'
														style={album_info.title.length > 40 && song.title.length > 40 ? {width: '90%', whiteSpace: 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis'}: {}}
													>{album_info.title}</div>
												</div>
												<div className='app-desktop-album-song-card-inner'>
													<div className='app-desktop-album-song-card-subtitle'>{get_duration(song.duration_ms)}</div>
													<span className='material-icons' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({anchorEl: 'desktop-song-card-' + i, popover: true, object_info: song});}}>more_vert</span>
												</div>
											</div>
										</Ripples>
									))}
								</div>
								{this.state.loading_recommendations ? 
									<div className='app-desktop-album-dialog-loading' style={{marginTop: 50, padding: 0}}>
										<CircularProgress color='inherit' />
									</div>
									:
									<React.Fragment>
										{recommended_artist.length > 0 &&
											<div className='app-desktop-album-body-section'>
												<div className='app-desktop-album-body-section-title'>More from {album_info.artist.name}</div>
												<div className='app-desktop-album-body-section-content'>
													{recommended_artist.filter(item => item.type === 'album').slice(0,5).map((album, i) => (
														<Card data={album} type='desktop_card_album' bodyLink={'/album/' + album.spotify_id} key={i} />
													))}
												</div>
											</div>
										}
										{recommended_albums.length > 0 &&
											<div className='app-desktop-album-body-section'>
												<div className='app-desktop-album-body-section-title'>You may also like</div>
												<div className='app-desktop-album-body-section-content'>
													{recommended_albums.slice(0,5).map((album, i) =>(
														<Card data={album} type='desktop_card_album' bodyLink={'/album/' + album.spotify_id} key={i} />
													))}
												</div>
											</div>
										}
									</React.Fragment>
								}
							</div>
						</div>
					</div>
					<Popover
						open={this.state.popover}
						anchorEl={document.getElementById(this.state.anchorEl)}
						onClose={()=> this.setState({popover: false})}
						anchorOrigin={{vertical: 'center', horizontal: 'right'}}
						transformOrigin={{vertical: 'center', horizontal: 'left'}}
					>
						<div style={{position: 'relative'}}>
							<div className='app-desktop-album-popover'>
								<div className='app-desktop-album-popover-item' onClick={() => {this.get_object_links();}}>Play full song</div>
								<div className='app-desktop-album-popover-item' onClick={() => window.location.replace('/artist/' + album_info.artist.spotify_id)}>Go to artist page</div>
								<div className='app-desktop-album-popover-item' id='second-popover' onClick={() => this.setState({second_popover: true})}>
									Share
									<span className='material-icons'>navigate_next</span>
								</div>
							</div>
							<div className='app-desktop-album-popover-arrow'></div>
						</div>
					</Popover>
					<Popover
						open={this.state.second_popover}
						anchorEl={document.getElementById('second-popover')}
						onClose={()=> this.setState({second_popover: false})}
						anchorOrigin={{vertical: 'center', horizontal: 'right'}}
						transformOrigin={{vertical: 'center', horizontal: 'left'}}
					>
						<div className='app-desktop-album-second-popover'>
							<FacebookShareButton url={constants.api_endpoint_song_meta.replace('{hash_id}', object_info.spotify_id)}>
								<div className='app-desktop-album-popover-item'>
									<img src={assets.facebook_icon_circle} alt=''/>
									Facebook
								</div>
							</FacebookShareButton>
							<TwitterShareButton url={constants.api_endpoint_song_meta.replace('{hash_id}', object_info.spotify_id)}>
								<div className='app-desktop-album-popover-item'>
									<img src={assets.twitter_bird_icon} alt=''/>
									Twitter
								</div>
							</TwitterShareButton>
							<CopyToClipboard text={constants.api_endpoint_song_meta.replace('{hash_id}', object_info.spotify_id)} onCopy={() => this.setState({'snackbar': true, 'snackbar_message': 'Copied to your clipboard!', 'second_popover': false, 'popover': false})}>
								<div className='app-desktop-album-popover-item' style={{justifyContent: 'flex-start'}}>
									<span className='material-icons' style={{marginRight: 5}}>link</span>
									Copy Link
								</div>
							</CopyToClipboard>
						</div>
					</Popover>
					<Dialog
						open={this.state.overlay && object_info}
						onClose={() => this.setState({overlay: false})}
						className='app-desktop-dialog'
					>
						<div className='app-desktop-album-dialog-container'>
							<div className='app-desktop-album-dialog-header'>
								<div className='app-desktop-album-dialog-image'>
									<img src={album_info.images.large} alt='album'/>
								</div>
								<div className='app-desktop-album-dialog-info'>
									<div className='app-desktop-album-dialog-info-title'>{object_info.title}</div>
									<div className='app-desktop-album-dialog-info-subtitle'>{album_info.artist.name}</div>
								</div>
							</div>
							{this.state.loading ? 
								<div className='app-desktop-album-dialog-loading'>
									<CircularProgress color='inherit' />
								</div>
								:
								<div className='app-desktop-album-dialog-buttons-container'>
									{object_info.spotify_link &&
										<Ripples>
											<div style={{backgroundColor: '#20b856'}} className='app-desktop-dialog-button' onClick={(e) => {window.open(object_info.spotify_link); e.stopPropagation();}}>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/spotify-round-white-bg-icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>PLAY ON SPOTIFTY</div>
											</div>
										</Ripples>
									}
									{object_info.itunes_link &&
										<Ripples>
											<div style={{backgroundColor: '#93a0b7'}} className='app-desktop-dialog-button' onClick={(e) => {window.open(object_info.itunes_link); e.stopPropagation();}}>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/apple-white-bg-icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>PLAY ON APPLE MUSIC</div>
											</div>
										</Ripples>
									}
									{object_info.deezer_link &&
										<Ripples>
											<div style={{backgroundColor: '#7fb7e3'}} className='app-desktop-dialog-button' onClick={(e) => {window.open(object_info.deezer_link); e.stopPropagation();}}>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/deezer-icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>PLAY ON DEEZER</div>
											</div>
										</Ripples>
									}
									{object_info.youtube_link &&
										<Ripples>
											<div style={{backgroundColor: '#ff0000'}} className='app-desktop-dialog-button' onClick={(e) => {window.open(object_info.youtube_link); e.stopPropagation();}}>
												<div className='app-desktop-dialog-button-icon'>
													<img src='https://magroove-dev-test.s3.amazonaws.com/magroove-backend/youtube-white-bg-icon.svg' alt='share-icon'/>
												</div>
												<div className='app-desktop-dialog-button-text'>PLAY ON YOUTUBE</div>
											</div>
										</Ripples>
									}
								</div>
							}
						</div>
					</Dialog>
					<div className='snackbar-container'>
						<SnackbarComponent
							open={this.state.snackbar}
							message={this.state.snackbar_message}
							autoHideDuration={3000}
							onClose={() => this.setState({'snackbar': false})}
						/>
					</div>
				</div>
			);
		}
	}
}

// Map Redux state to component props
function mapStateToProps(state) {
	return {
		album_info: state.GlobalReducer.album_info,
	};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopAlbumPage);