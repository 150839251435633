// Actions import
import { store_set, store_get, reset_store, execute_post_login_action } from '@actions/GlobalActions.js';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

// Constant Imports
import * as constants from '@constants';

// import the request_buffer
import { request_buffer } from '@RequestBuffer';

// Import controllers and event bus
import player from '@Player';
import event_bus from '@Eventbus';
import interaction from '@Interaction';
import app_analytics from '@Analytics';
import subscribe_ab_test from './SubscribeAB.js';
import notification_controller from '@Notifications';
import subscription from '@Subscription';
import file from '@FileManager';
import storage from '@Storage';
import list from '@List';
import queue from '@Queue';

// Import Preferences API
import { Preferences } from '@capacitor/preferences';

// Import libraries
import moment from 'moment';
import Cookies from 'js-cookie';
import 'format-unicorn';

import { redirect } from '@routes/Routes';
import { Capacitor } from '@capacitor/core';

// ========================================================================================================================
// Create your auxiliary functions here.
// ========================================================================================================================
const save_local_suggestions = async (json) => {
	await Preferences.set({ key: 'local_suggestions', value: json });
};

const get_local_suggestions = async () => {
	const local_suggestions = await Preferences.get({ key: 'local_suggestions' });
	return local_suggestions.value;
};

const remove_local_suggestions = async () => {
	await Preferences.remove({ key: 'local_suggestions' });
};

const save_backup_token = async (token) => {
	// Save the token on capacitor native store
	await Preferences.set({ key: 'user_token', value: token });
};

const save_hash_id = async (hashId) => {
	// Save the token on capacitor native store
	await storage.set('hash_id', hashId);
};

const clear_preferences = async () => {
	await Preferences.clear();
};

const get_installation_hash = async () => {
	const token = await Preferences.get({ key: 'installation_hash_id' });
	return token.value;
};

const SignOutGoogle = async () => {
	const response = await GoogleAuth.signOut();
	return response;
};

// ========================================================================================================================

class UserController{
	constructor() {
		this.user_data_loading = false;

		this.load = this.load.bind(this);
		this.get_data = this.get_data.bind(this);
		this.get_recent_seeds = this.get_recent_seeds.bind(this);
		this.get_suggestions = this.get_suggestions.bind(this);
		this.get_suggestions_ajax = this.get_suggestions_ajax.bind(this);
		this.get_internal_playlists = this.get_internal_playlists.bind(this);
		this.get_friends_recommendations = this.get_friends_recommendations.bind(this);
		this.get_my_recommendations = this.get_my_recommendations.bind(this);
		this.probe_token = this.probe_token.bind(this);
		this.check_for_token = this.check_for_token.bind(this);
		this.get_backup_token = this.get_backup_token.bind(this);
		this.get_blocklist = this.get_blocklist.bind(this);
		this.update_blocklist = this.update_blocklist.bind(this);

		// AB tests
		this.set_default_params = this.set_default_params.bind(this);
		this.check_for_premium_max_swipes = this.check_for_premium_max_swipes.bind(this);
		this.set_home_type = this.set_home_type.bind(this);
		this.set_pricing = this.set_pricing.bind(this);

		event_bus.on('store-loaded', this.load);
	}

	load(){
		// Gather the rest of the data
		this.get_data();
		this.get_recent_seeds();
		this.get_internal_playlists();
		this.probe_token();
		this.get_blocklist();
		this.get_suggestions();
		this.get_friends_recommendations();
		this.get_my_recommendations();
		this.set_premium_overlay();
	}

	login(data){
		const user_object = store_get('user');

		this.trigger_events(data.is_new_member);

		// Set the token in the store
		save_backup_token(data.token);
		store_set('user_token', data.token);

		if (data.linked_accounts){
			store_set('linked_accounts', data.linked_accounts);
		}

		// Here we set the email type 'profile' get from secondary table to be displayed at profile page
		if (data.secondary_emails){
			store_set('secondary_emails', data.secondary_emails);
			const profile_email = data.secondary_emails.find(email => email.email_type === 'profile');
			if (profile_email){
				user_object.email = profile_email.email;
			}
		}

		if (data.hash_id){
			user_object.hash_id = data.hash_id;
			save_hash_id(data.hash_id);
		}

		const fine_tunning = user_object.fine_tunning || {};

		store_set('user', user_object);
		store_set('fine_tunning', fine_tunning);

		this.set_premium_overlay(user_object);
		this.set_default_params(user_object, fine_tunning);
		this.set_home_type(user_object);

		// Call the post login action in case there's
		// something to be executed after login, but
		// only after setting premium pricing to make
		// sure the user is in the right group test.
		this.set_pricing().then(() => {
			execute_post_login_action(data.is_new_member);

			// Load user data - only after setting the price
			// to avoid calling multiple ajaxes and delaying
			// the pricing ajax.
			list.load(); this.load();
			subscription.fetch();

			// Touch API with the token
			notification_controller.touch();

			this.update_referrer();
		});
	}

	logout(url_params = ''){
		// The order here is important, check if the ad
		// is being displayed before reseting the store
		try { SignOutGoogle(); } catch(err){ /** */}

		clear_preferences();
		player.reset();
		file.clear_all();
		storage.clear();

		// Clears notification ajax calls
		let notification_interval = store_get('notification_interval');
		clearInterval(notification_interval);

		// Reload app so the subscription can be initialized again
		window.location.reload();

		// Reset store to clean user token and user data
		reset_store(); redirect('/login/' + url_params);
	}

	check_for_token() {
		const user_token = store_get('user_token');
		if (!user_token) {
			this.get_backup_token().then(response => {
				if (response) {
					store_set('user_token', response);
					return response;
				}
			});
		}
		return user_token;
	}

	get_data(){
		if (!this.is_logged() || this.user_data_loading) return;

		this.user_data_loading = true;
		request_buffer.auth_execute({
			type: 'get',
			url: constants.api_endpoint_user,
			success: function(res){
				if (res.status === 'success'){
					const data = res.data;
					store_set('user', data.user);
					save_hash_id(data.user.hash_id);
					if (data.fine_tunning && Object.keys(data.fine_tunning).length > 0){
						store_set('fine_tunning', data.fine_tunning);
					}
					store_set('linked_accounts', data.linked_accounts);
					store_set('last_day_recommendations_total', data.user.last_day);

					this.check_for_premium_max_swipes();
				}
			}.bind(this),
			complete: function(){
				this.user_data_loading = false;
			}.bind(this)
		}, true);
	}

	async get_backup_token() {
		const token = await Preferences.get({ key: 'user_token' });
		return token.value;
	}

	set_default_params(user = store_get('user'), fine_tunning = store_get('fine_tunning')){
		if (!this.is_logged()) return;
		const user_hash = user.hash_id;
		if (!user_hash || (fine_tunning && Object.keys(fine_tunning).length > 0)) return;

		// We'll find where in these groups is the last character of user's hash_id and apply
		// the default target value according to the group
		const groups = ['abcdefghijkl', 'mnopqrstuvwx', 'yz0123456789'];
		let target = 20;
		let value = groups.findIndex(group => group.includes(user_hash.slice(-1)));
		switch (value) {
		case 2:
			target = 80;
			break;
		case 1:
			target = 50;
			break;
		case 0:
			target = 20;
			break;
		default:
			break;
		}

		const config = {};
		config.target_popularity = target;
		store_set('fine_tunning', config);

		// Then we subscribe user to the A/B test
		subscribe_ab_test('e57nu8lhz9', value);
	}

	set_home_type(user = store_get('user')) {
		if (store_get('home_type_ajax') || !this.is_logged()) return;

		var user_hash = user.hash_id;
		if (!user_hash) return;
		store_set('home_type_ajax', true);

		var i = -1;
		var render_version = 0;

		// This will turn the last character of the user_hash into its ASCII counterpart, then
		// try to get its remainder (can be between 0-2) and render the homepage according to this result
		function calculate() {
			let hash_code = user_hash.slice(i).charCodeAt(0);
			render_version = hash_code % 3;
			switch (render_version) {
			case 2:
				return 'B';
			case 1:
				return 'A';
			case 0:
				return 'default';
			default:
				return false;
			}
		}

		let home_type = calculate();
		if (!home_type) {
			// If the result is false, we'll get the next character
			// in hash_id and try again
			do {
				i -= 1;
				home_type = calculate();
			} while (!home_type && i > -33);
		}

		// As a safeguard, if there's no result we set it to default
		if (!home_type) { home_type = 'default'; render_version = 0; }
		store_set('home_type', home_type);

		// Then we subscribe user to the A/B test
		subscribe_ab_test('qg2grwy2zh', render_version, store_set('home_type_ajax', false));
	}

	set_premium_overlay(user = store_get('user')){
		if (!this.is_logged()) return;

		const user_hash = user.hash_id;
		if (!user_hash) { return; }

		// We'll find where in these groups is the last character of user's hash_id and apply
		// the premium overlay according to the group.
		const groups = ['d9yvptm56e8jw4rkfi', '', '', 'bul07sxz1gnqcaoh32', '', '', ''];
		const group_number = groups.findIndex(group => group.includes(user_hash.slice(-1)));

		const values = ['default', 'A', 'B', 'C', 'D', 'E', 'F'];
		const premium_overlay = values[group_number];

		store_set('premium_overlay_type', premium_overlay);
		store_set('ab_premium_overlay_test', {'hash_id': 'egky15v9l6', 'group': group_number});
	}

	set_pricing(){
		return new Promise((resolve) => {
			if (!this.is_logged()){
				resolve({status: 'error', code: 'not_logged'});
			}

			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_subscription_pricing,
				success: function(res){
					if (res.status === 'success'){
						const data = res.data;
						store_set('ab_pricing_test', data.ab_test);
						for (var [key, value] of Object.entries(data.values)) {
							store_set(key, value);
						}

						// Save the other subscription codes to register them as well
						// In case user logs out and logs in to account with different codes
						if (data.subscription_codes) {
							store_set('subscription_codes', data.subscription_codes);
						}

						subscription.initialize();
						resolve({status: 'success'});
					} else {
						store_set('ab_pricing_test', false);
						resolve({status: 'error', code: 'response_error'});
					}
				},
				error: function(){
					store_set('ab_pricing_test', false);
					resolve({status: 'error', code: 'request_error'});
				}
			});
		});
	}

	check_for_premium_max_swipes() {
		if (this.is_logged()) {
			const user_hash = store_get('user').hash_id;
			if (!user_hash) { return; }
			// Checks if user is subscribed to premium max daily swipes A/B test
			const ab_premium_swipes = Cookies.get('premium_max_swipes_per_day_' + user_hash);
			if (ab_premium_swipes) {
				store_set('premium_max_swipes_per_day', parseInt(ab_premium_swipes));
			} else {
				interaction.set_max_swipes();
			}
		}
	}

	get_recent_seeds(){
		if (this.is_logged()) {
			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_seed_list,
				success: function(data){
					if (data.status === 'success'){
						// Let's make sure the seed list is updated
						let recent_seeds = store_get('recent_seeds');
						recent_seeds = [...recent_seeds, ...data.data];

						// Here we just filter to get unique objects
						let updated_recent_seeds = [...new Map(recent_seeds.map(item => [item.hash_id ? item.hash_id : item.spotify_id, item])).values()];

						store_set('recent_seeds', updated_recent_seeds);
						store_set('user_info_date', new Date());
					}
				}
			}, true);
		}
	}

	get_suggestions(){
		const now = moment(new Date());
		get_local_suggestions().then((data) => {
			// First, we try getting the suggestions from local storage
			if (data) {
				const suggestions = JSON.parse(data);
				const ttl = moment(suggestions.ttl);
				// Check TTL, if 1 month has passed we remove the local key
				// and fetch the suggestions again
				if (now.isAfter(ttl)) {
					remove_local_suggestions();
				} else if (suggestions.suggestions && suggestions.suggestions.length > 0) {
					// Set the data in redux store
					store_set('user_synced', suggestions.user_synced);
					store_set('suggestions', suggestions.suggestions);
					store_set('suggestions_loading', false);
					return;
				}
			}

			this.get_suggestions_ajax();
		});
	}

	get_suggestions_ajax() {
		if (!this.is_logged()) return;

		store_set('suggestions_loading', true);
		request_buffer.auth_execute({
			type: 'get',
			url: constants.api_endpoint_get_suggestions,
			success: function(data){
				if (data.status === 'success'){
					store_set('user_synced', data.user_synced);
					store_set('suggestions', data.suggestions);
				}
				// If user is synced to at least one account, save results locally
				if (data.user_synced !== 'none') {
					let local_suggestions = {
						'user_synced': data.user_synced,
						'suggestions': data.suggestions,
						'ttl': moment().add(1, 'months')
					};
					save_local_suggestions(JSON.stringify(local_suggestions));
				}
			},
			error: function(){},
			complete: function(){
				store_set('suggestions_loading', false);
			}
		}, true);
	}

	get_internal_playlists(){
		if (this.is_logged()) {
			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_internal_playlists,
				success: function(data){
					if (data.status === 'success'){
						store_set('playlists', data.playlists);
						let active_playlist = data.playlists.find(playlist => playlist.is_active);
						if (active_playlist) {
							store_set('active_playlist', active_playlist);
						}
					}
				}
			});
		}
	}

	trigger_events(is_new_member){
		// Trigger an event if new member
		if (is_new_member){
			storage.set('new_member', new Date(), 86400);

			app_analytics.log_event('CompleteRegistration');

			// Let's trigger events for specific platforms
			// This is the flow for users that log in using app installed by android or ios (which means he did NOT accessed via web)
			app_analytics.log_event('signup_all');

			const platform = Capacitor.getPlatform();
			if (platform !== 'web'){
				app_analytics.log_event('signup_android_and_ios');
				app_analytics.log_event('signup_' + platform);
			}
		}
	}

	update_referrer(){
		get_installation_hash().then((hash_id) => {
			if (!hash_id) return;
			request_buffer.auth_execute({
				type: 'post',
				data: { hash_id: hash_id },
				url: constants.api_endpoint_save_referrer
			}, true);
		});
	}

	probe_token(){
		const user_token = this.check_for_token();
		if (user_token) {
			request_buffer.execute({
				type: 'get',
				url: constants.api_endpoint_probe_token,
				beforeSend: function (xhr) {
					xhr.setRequestHeader('Authorization', 'JWT ' + user_token);
				},
				success: function(data){
					if (data.status === 'error'){
						this.logout();
					}
				}.bind(this)
			});
		}
	}

	get_blocklist(){
		const user_token = this.is_logged();
		if (user_token) {
			store_set('loading_blocklist', true);

			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_blocklist,
				success: function(res){
					if (res.status === 'success'){
						store_set('blocklist', res.data);
					}
				},
				complete: function(){
					store_set('loading_blocklist', false);
				}
			});
		}
	}

	add_to_blocklist(entity, entity_type){
		if (entity_type === 'artist'){
			const playing_song = store_get('playing_song');
			entity.images = playing_song.album.images;
		}

		const blocklist = store_get('blocklist');
		const new_blocklist = [entity, ...blocklist];
		store_set('blocklist', new_blocklist);

		// Clean the current queue
		queue.clean(entity_type, entity);

		// Display feedback message
		store_set('snackbar_message', 'Blocked!');
		store_set('snackbar_status', true);

		// Skip to the next song
		player.load();
	}

	remove_from_blocklist(entity){
		const blocklist = store_get('blocklist');
		const new_blocklist = [...blocklist].filter(b => (b.spotify_id && b.spotify_id !== entity.spotify_id) && (b.hash_id && b.hash_id !== entity.hash_id));
		store_set('blocklist', new_blocklist);
	}

	update_blocklist(action, entity_type, entity){
		if (action === 'add'){
			this.add_to_blocklist(entity, entity_type);
		} else if (action === 'remove'){
			this.remove_from_blocklist(entity);
		}

		// Execute the ajax in background
		const data = {};
		data.entity_type = entity_type;
		if (entity.hash_id) {
			data.entity_hash_id = entity.hash_id;
		}
		if (entity.spotify_id){
			data.entity_source_id = entity.spotify_id;
		}

		request_buffer.execute({
			type: 'POST',
			url: constants.api_endpoint_update_blocklist.formatUnicorn({action: action}),
			data: data
		});
	}

	get_friends_recommendations() {
		const user_token = this.is_logged();
		if (user_token) {
			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_friends_recommendations,
				success: function(data){
					if (data.status === 'success'){
						store_set('friends_recommendations', data.friends_recommendations);
					}
				},
			});
		}
	}

	get_my_recommendations() {
		const user_token = this.is_logged();
		if (user_token) {
			request_buffer.auth_execute({
				type: 'get',
				url: constants.api_endpoint_get_my_recommendations,
				success: function(data){
					if (data.status === 'success'){
						store_set('my_recommendations', data.my_recommendations);
					}
				},
			});
		}
	}

	is_logged(){
		return store_get('user_token');
	}
}

const user = new UserController();

export default user;
