import React, { Component } from 'react';
import { connect } from 'react-redux';

// CSS Imports
import './styles/DesktopTerms.css';

class DesktopTerms extends Component {
	render() {
		return (
			<div className='terms-desktop'>
				<div className='terms-content-dekstop'>
					<div className='terms-title-desktop'>
						<div className='terms-title-text-desktop'>Terms and Conditions</div>
						<div className='terms-title-line-desktop' />
					</div>
					<div className='terms-text-desktop'>
						<div className='terms-text-content-description'>
							Welcome to Magroove.
							<br/><br/>
							Magroove is a song recommendation app developed to help people find new songs. It relies on a musically-based Artificial Intelligence to recommend you songs that sound alike, remind or have influences of other known artists you like, as well as a Machine Learning system which shapes your searches according to what you like or dislike, as well as your behavior in the app. These Terms of Use govern your relationship with MAGROOVE (“Magroove”, “we”, “our”, “us”) and your use of Magroove services, including the website magroove.com and any sub-domains (our “Website”, “Webapp”), our mobile and desktop apps (our “Apps”) and all related players, widgets, tools, applications, data, software, APIs and other services provided by Magroove (the “Services”).
							<br/><br/>
							These Terms of Use, together with our Privacy Policy and our Magroove for Artists Terms of Use, when applicable, constitute a legally binding agreement (the “Agreement”) between you and Magroove, in relation to your use of the Website, Apps and Services (together, the “Platform”).
						</div>
						<div className='terms-text-content-title'>1. Acceptance of these Terms of Use</div>
						<div className='terms-text-content-description'>
							1.1. Please read these Terms of Use very carefully. If you do not agree to any of the provisions set out in this document, you should not register an account or use the Website, Apps or any of the Services. By registering an account, accessing or using the Platform, or by viewing, accessing, streaming, uploading or downloading any information or content from or to the Platform, you represent and warrant that you have read and understood these Terms of Use, will abide by them.
							<br/><br/>
							1.2. You may not register an account, use the Website, Apps or any of the Services if you are under 18 years old or under the Minimum Age according to the laws of your jurisdiction. By registering an account and agreeing to these Terms of Use, you represent and warrant that you are above the age of majority and fully capable, or otherwise duly assisted or represented, according to the laws of your jurisdiction.
						</div>
						<div className='terms-text-content-title'>2. Changes to the Terms of Use</div>
						<div className='terms-text-content-description'>
							2.1. We reserve the right to change, alter, replace or otherwise modify these Terms of Use at any time, in our own discretion. 
							<br/><br/>
							2.2. When we make any material changes to these Terms of Use, we will notify you in advance by sending a message to your Magroove account and/or an email to the email address that you have provided us, and we will give you an opportunity to review the new Terms of Use before they go into effect, according to the case. You will have no obligation to continue using the Platform following any such notification, but if you do not terminate your account as described in the Termination section below, your continued use of the Platform will constitute your acceptance of the revised Terms of Use.
						</div>
						<div className='terms-text-content-title'>3. Your Magroove account</div>
						<div className='terms-text-content-description'>
							3.1. You are obliged to register to use the Platform. Registration can be made through Facebook’s or Google+’ login authorization system (your “Credentials”). There is no difference function-wise if you login using one or another.
							<br/><br/>
							3.2. When you register to use the Platform, you will provide us with your Facebook or Google+ public information, including your name and email. You must ensure that the email address that you provide is, and remains, valid. Your email address and any other information you chose to provide about yourself will be treated in accordance with our Privacy Policy.
							<br/><br/>
							3.3. You are solely responsible for maintaining the confidentiality and security of your credentials used for login (Google or Facebook’s username and password), and you will remain responsible for all activity emanating from your account, whether or not such activity was authorized or operated by you.
							<br/><br/>
							3.4. If your lost access to your Credentials, or if you believe that your account has been accessed by unauthorized third parties, you are advised to notify us through support@magroove.com and should change your password at the earliest possible opportunity. We will not recover any data and/or transfer it to another account in case of Credential loss.
							<br/><br/>
							3.5. We reserve the right to disallow, cancel, remove or reassign certain usernames and permalinks in appropriate circumstances, as determined by us in our sole discretion, and may, with or without prior notice, suspend or terminate your account if activities occur on that account which, in our sole discretion, would or might constitute a violation of these Terms of Use, or an infringement or violation of the rights of any third party, or of any applicable laws or regulations.
							<br/><br/>
							3.6. You may terminate your account at any time as described in the Termination section below.
						</div>
						<div className='terms-text-content-title'>4. Your use of the Platform</div>
						<div className='terms-text-content-description'>
							4.1. Subject to your strict compliance with these Terms of Use, at any and all times during your use of the Platform, Magroove grants you a limited, personal, non-exclusive, revocable, non-assignable and non-transferable right to use the Platform in order to view Content uploaded and posted to the Website and Apps, to listen to audio Content streamed from the Platform and to share audio Content using the features of the Platform, whenever the appropriate functionality has been enabled and subject to the territorial availability of that feature and audio Content.
							<br/><br/>
							4.2. The above rights are conditional upon your strict compliance with these Terms of Use at any and all times during your use of the Platform, including, without limitation, the following:
							<br/><br/>
							(i) You must not copy, rip or capture, or attempt to copy, rip or capture, any audio Content from the Platform or any part of the Platform, other than by means of download, when permitted by the Platform and when legally available.
							<br/><br/>
							(ii) You must not adapt, copy, republish, make available or otherwise communicate to the public, display, perform, transfer, share, distribute or otherwise use or exploit any Content on or from the Platform at any and all times, except (i) where such Content is Your Content at any and all times during your use of the applicable Content, or (ii) as permitted under these Terms of Use, and within the parameters set by the Uploader (for example, under the terms of Creative Commons licenses selected by the Uploader, when applicable).
							<br/><br/>
							(iii) You must not use any Content (other than Your Content) in any way that is designed to create a separate content service or that replicates any part of the Platform offering.
							<br/><br/>
							(iv) You must not employ scraping or any other similar technique to aggregate, repurpose, republish or otherwise make use of any Content.
							<br/><br/>
							(v) You must not employ any technique or make use of any services, automated or otherwise, designed to misrepresent the popularity of Your Content on the Platform, or to misrepresent your activity on the Platform, including, without limitation, by the use of bots, botnets, scripts, apps, plugins, extensions or other automated means to register accounts, log in, play Content or otherwise to act on your behalf, particularly where such activity occurs in a multiple or repetitive fashion. You must not offer or promote the availability of any such techniques or services to any other users of the Platform.
							<br/><br/>
							(vi) You must not alter or remove, or attempt to alter or remove, any trademark, copyright or other proprietary or legal notices contained in, or appearing on, the Platform or any Content appearing on the Platform (other than Your Content).
							<br/><br/>
							(vii) You must not, and must not permit any third party to, copy or adapt the object code of the Website or any of the Apps or Services, reverse engineer, reverse assemble, decompile, modify or attempt to discover any source or object code of any part of the Platform, circumvent or attempt to circumvent any copy protection mechanism or territorial restrictions, and access any rights management information pertaining to Content other than Your Content.
							<br/><br/>
							(viii) You must not use the Platform to upload, post, store, transmit, display, copy, distribute, promote, make available, continue to make available or otherwise communicate to the public:
							<br/><br/>
							(a) any Content that is abusive, libelous, defamatory, pornographic or obscene, that promotes or incites violence, terrorism, illegal acts, or hatred on the grounds of race, ethnicity, cultural identity, religious belief, disability, gender, identity or sexual orientation, or any other Content that is objectionable in Magroove’s reasonable discretion;
							<br/><br/>
							(b) any information, Content or other material that violates, plagiarizes, misappropriates or infringes the rights of third parties including, without limitation, copyright, trademark rights, rights of privacy or publicity, confidential information or any other right;
							<br/><br/>
							(c) any Content that violates, breaches or is contrary to any law, rule, regulation, court order or that is otherwise illegal or unlawful in Magroove’s reasonable discretion;
							<br/><br/>
							(d) any material, of any kind, that contains any virus, Trojan horse, spyware, adware, malware, bot, time bomb, worm, or other harmful or malicious component, which or might overburden, impair or disrupt the Platform or servers or networks forming part of, or connected to, the Platform, or which does or might restrict or inhibit any other user’s use and enjoyment of the Platform;
							<br/><br/>
							(e) any unsolicited or unauthorized advertising, promotional messages, spam or any other form of solicitation.
							<br/><br/>
							(ix) You must not commit, engage in, encourage, induce, solicit or promote, any conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law or regulation.
							<br/><br/>
							(x) You must not rent, sell or lease access to the Platform, or any Content on the Platform. However, this shall not prevent you from including links (in the appropriate fields) from Your Content to online download stores from where any item of Your Content may be purchased.
							<br/><br/>
							(xi) You must not sell or transfer, or offer to sell or transfer, any Magroove account to any third party without the prior written approval of Magroove.
							<br/><br/>
							(xii) You must not deliberately impersonate or otherwise misrepresent your affiliation to any person or entity (for example, by registering an account in the name of another person or company).
							<br/><br/>
							(xiii) You must not stalk, exploit, threaten, abuse or otherwise harass another user, Uploader or any Magroove partner or employee.
							<br/><br/>
							(xiv) You must not use or attempt to use another person’s account, password, or other information, unless you have express permission from that other person.
							<br/><br/>
							(xv) You must not collect or attempt to collect personal data, or any other kind of information about other users or Uploaders, including, without limitation, through spidering or any form of scraping.
							<br/><br/>
							(xvi) You must not violate, circumvent or attempt to violate or circumvent any data security measures employed by Magroove or any Uploader; access or attempt to access data or materials which are not intended for your use; log into, or attempt to log into, a server or account which you are not authorized to access; attempt to scan or test the vulnerability of Magroove’s servers, system or network or attempt to breach Magroove’s data security or authentication procedures; attempt to interfere with the Website, Apps or the Services, by any means, including, without limitation, hacking Magroove’s servers or systems, submitting a virus, overloading, mail-bombing or crashing.
							<br/><br/>
							4.3. You agree to comply with the above conditions at any and all times during your use of the Platform, and acknowledge and agree that Magroove has the right, in its sole discretion, to terminate your account or take such other action as we see fit, if you breach any of the above conditions or any of the other terms of these Terms of Use. Without limitation to any other Magroove’s rights or remedies under these Terms of Use, Magroove reserves the right to investigate any situation that appears to involve violation to any of the items above, and may report such matters to, and co-operate with, appropriate law enforcement authorities in prosecuting any users who have participated in any such violations.
						</div>
						<div className='terms-text-content-title'>5. Content property</div>
						<div className='terms-text-content-description'>
							5.1. Any Content other than Your Content is the property of the relevant Uploader, and is or may be subject to copyright, trademark rights or other intellectual property or proprietary rights. Such Content may not be downloaded, reproduced, distributed, transmitted, re-uploaded, republished, displayed, sold, licensed, made available or otherwise communicated to the public or exploited for any purposes, except via the features of the Platform. Should you repost another user’s Content, include another user’s Content in a playlist or station or should you listen to another user’s Content offline, you acquire no ownership rights whatsoever in relation to that Content. All proprietary rights related to the Content are reserved to the relevant Uploader.
							<br/><br/>
							5.2. With the sole exception of the abovementioned Content, Magroove is the owner of all intellectual property rights associated with the Platform, including, without limitation, any copyrights, patents or trademarks.
						</div>
						<div className='terms-text-content-title'>6. Data protection, privacy and cookies</div>
						<div className='terms-text-content-description'>
							6.1. All personal data that you provide us in connection with your use of the Platform is collected, stored, used and disclosed by Magroove in accordance with our Privacy Policy. In addition, in common with most online services, we use cookies to help us understand how people are using the Platform, so that we can continue to improve the service we offer. In order to register an account and use the Services, you must also read and accept our Privacy Policy.
						</div>
						<div className='terms-text-content-title'>7. Reporting infringements</div>
						<div className='terms-text-content-description'>
							7.1. If you discover any Content on the Platform that you believe infringes your copyright or the copyright of a third party on whose behalf you are entitled to act, please inform us immediately by any of the methods indicated below.
							<br/><br/>
							7.2. If you would prefer to send us your own written notification, please make sure that you include the following information:
							<br/><br/>
							(i) a statement that you have identified Content on Magroove that infringes your copyright or the copyright of a third party on whose behalf you are entitled to act;
							<br/><br/>
							(ii) a description of the copyright work(s) that you claim have been infringed;
							<br/><br/>
							(iii) a description of the Content that you claim is infringing your copyright and the Magroove URL(s) where such Content can be located;
							<br/><br/>
							(iv) your full name, address and telephone number, a valid email address by which you can be contacted;
							<br/><br/>
							(v) a statement that you have a good faith belief that the disputed use of the material is not authorized by the copyright owner, its agent, or by law; and
							<br/><br/>
							(vi) a statement that the information in your notice is accurate and that you are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.
							<br/><br/>
							7.3. Your notice shall be sent by email to legal@magroove.com.
							<br/><br/>
							7.4. The foregoing process applies to copyright only. If you discover any Content that you believe to be in violation of your trademark rights, please report this to us by email at legal@magroove.com. 
							<br/><br/>
							7.5. In all other cases, if you discover Content that infringes or violates any of your other rights, which you believe is defamatory, pornographic, obscene, racist or otherwise liable to cause widespread offense, or which constitutes impersonation, abuse, spam or otherwise violates these Terms of Use or applicable law, please report this to us at legal@magroove.com.
						</div>
						<div className='terms-text-content-title'>8. External services</div>
						<div className='terms-text-content-description'>
							8.1. The Platform may provide you with access to third party websites, databases, networks, servers, information, software, programs, systems, directories, applications, products or services, including without limitation, linked services (hereinafter “External Services”).
							<br/><br/>
							8.2. Magroove does not have or maintain any control over External Services, and is not and cannot be responsible for their content, operation or use. By linking or otherwise providing access to any External Services, Magroove does not give any representation, warranty or endorsement, express or implied, with respect to the legality, accuracy, quality or authenticity of content, information or services provided by such External Services.
							<br/><br/>
							8.3. External Services may have their own terms of use and/or privacy policy, and may have different practices and requirements to those operated by Magroove with respect to the Platform. You are solely responsible for reviewing any terms of use, privacy policy or other terms governing your use of these External Services, which you use at your own risk. You are advised to make reasonable enquiries and investigations before entering into any transaction, financial or otherwise, and whether online or offline, with any third party related to any External Services.
							<br/><br/>
							8.4. You are solely responsible for taking the necessary precautions in order to protect yourself from fraud when using External Services, and to protect your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content and material that may be included on or may emanate from any External Services.
							<br/><br/>
							Magroove disclaims any and all responsibility or liability for any harm resulting from your use of External Services, and you hereby irrevocably waive any claim against Magroove with respect to the content or operation of any External Services.
						</div>
						<div className='terms-text-content-title'>9. Limitation of Liability</div>
						<div className='terms-text-content-description'>
							9.1. MAGROOVE AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, SHALL HAVE NO LIABILITY FOR ANY LOSS OR DAMAGE ARISING OUT OF:
							<br/><br/>
							(a) YOUR INABILITY TO ACCESS OR USE THE PLATFORM OR ANY PART OR PARTS THEREOF, OR TO ACCESS ANY CONTENT OR ANY EXTERNAL SERVICES VIA THE PLATFORM;
							<br/><br/>
							(b) ANY CHANGES THAT MAGROOVE MAY MAKE TO THE PLATFORM OR ANY PART THEREOF, OR ANY TEMPORARY OR PERMANENT SUSPENSION OR CESSATION OF ACCESS TO THE PLATFORM OR ANY CONTENT IN OR FROM ANY OR ALL TERRITORIES;
							<br/><br/>
							(d) ANY ERRORS OR OMISSIONS IN THE PLATFORM’S TECHNICAL OPERATION, OR FROM ANY INACCURACY OR DEFECT IN ANY CONTENT OR ANY INFORMATION RELATING TO CONTENT;
							<br/><br/>
							(e) YOUR FAILURE TO PROVIDE MAGROOVE WITH ACCURATE OR COMPLETE INFORMATION, OR YOUR FAILURE TO KEEP YOUR USERNAME OR PASSWORD SUITABLY CONFIDENTIAL;
							<br/><br/>
							(f) ANY MISCONDUCT BY OTHER USERS OR THIRD PARTIES USING THE PLATFORM, ESPECIALLY IN BREACH OF THE AGREEMENT;
							<br/><br/>
							(g) ANY LOSS OR DAMAGE TO ANY COMPUTER HARDWARE OR SOFTWARE, ANY LOSS OF DATA (INCLUDING YOUR CONTENT), OR ANY LOSS OR DAMAGE FROM ANY SECURITY BREACH; AND
							<br/><br/>
							(h) ANY LOSS OF PROFITS, LOSS OF OPPORTUNITY OR ANY OTHER LOSS WHICH IS NOT A FORESEEABLE CONSEQUENCE OF THE BREACH OF THESE TERMS OF USE BY MAGROOVE. LOSSES ARE CONSIDERED FORESEEABLE WHENEVER THEY CAN BE CONTEMPLATED BY YOU AND MAGROOVE AT THE TIME YOU AGREE TO THESE TERMS OF USE.
							<br/><br/>
							9.2. ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE OF THE PLATFORM MUST BE NOTIFIED TO MAGROOVE AS SOON AS POSSIBLE.
						</div>
						<div className='terms-text-content-title'>10. Indemnification</div>
						<div className='terms-text-content-description'>
							10.1. You hereby agree to indemnify, defend and hold harmless Magroove, its successors, assigns, affiliates, agents, directors, officers, employees, partners or shareholders from and against any and all claims, obligations, damages, losses, expenses, and costs, including reasonable attorneys’ fees, resulting from:
							<br/><br/>
							(i) any violation by you of these Terms of Use;
							<br/><br/>
							(ii) any activity related to your account, be it by you or by any other person accessing your account with or without your consent, unless such activity was caused by Magroove.
						</div>
						<div className='terms-text-content-title'>11. Changes to the Platform</div>
						<div className='terms-text-content-description'>
							11.1. Magroove reserves the right to suspend, discontinue, terminate or cease providing access to the Platform or any part thereof, at any time and for any reason, temporarily or permanently, and whether in its entirety or with respect to individual territories only. In the case of any temporary or permanent suspension, discontinuation, termination or cessation of access, Magroove shall use its reasonable endeavors to notify registered users of such decision in advance.
							<br/><br/>
							11.2. You hereby agree that Magroove and its subsidiaries, affiliates, successors, assigns, employees, agents, directors, officers and shareholders shall not be liable for any changes or modifications to the Website, Apps and/or any Services that Magroove may wish to make from time to time, or for any decision to suspend, discontinue or terminate the Website, Apps and/or the Services or any part or parts thereof.
						</div>
						<div className='terms-text-content-title'>12. Termination</div>
						<div className='terms-text-content-description'>
							12.1. You may terminate this Agreement, at any time, by removing all of Your Content from your account, when applicable, deleting your account, and thereafter ceasing to use the Platform. If you wish to delete your account, you will need to do so from the Account tab in your Settings on the Website. 
							<br/><br/>
							12.2. Magroove may suspend your access to the Platform and/or terminate this Agreement, at any time and in its reasonable discretion, if: 
							<br/><br/>
							(i) you have repeatedly breached these Terms of Use, Privacy Policy or any other terms applicable to your use of the Platform;
							<br/><br/>
							(ii) Magroove elects to cease providing access to the Platform in the jurisdiction where you reside or from where you are attempting to access the Platform, or
							<br/><br/>
							(iii) in other reasonable circumstances as determined by Magroove at its discretion.
							<br/><br/>
							12.3. Magroove shall not be responsible for any loss or damage arising from the abovementioned suspension or termination of Services.
							<br/><br/>
							12.4. Once your account has been terminated, any and all Content residing in your account, or pertaining to activity from your account, will be irretrievably deleted by Magroove, except to the extent that we are obliged or permitted to retain such content, data or information for a certain period of time in accordance with applicable laws and regulations and/or to protect our legitimate business interests. 
							<br/><br/>
							12.5. If you access the Platform via any of our Apps or via any third party app connected to your account, deleting that app will not delete your account. 
							<br/><br/>
							12.6. The provisions of these Terms of Use shall survive the termination or cancellation of this Agreement. 
						</div>
						<div className='terms-text-content-title'>13. Assignment to third parties</div>
						<div className='terms-text-content-description'>
							13.1. Magroove may assign its rights and (when permitted by law) its obligations under this Agreement, in whole or in part, to any third party and at any time. In such cases, we will notify you in advance by sending a message to your Magroove account and/or an email to the email address that you have provided us, and you will have no obligation to continue using the Platform following such notification. However, if you do not terminate your account as described in the Termination section, your continued use of the Platform will constitute your acceptance to such assignment.
							<br/><br/>
							13.2. You may not assign this Agreement or the rights and duties hereunder, in whole or in part, to any third party without the prior written consent of Magroove.
						</div>
						<div className='terms-text-content-title'>14. Severability</div>
						<div className='terms-text-content-description'>
							14.1. Should one or more provisions of these Terms of Use be found to be unlawful, void or unenforceable, such provision(s) shall be deemed severable and will not affect the validity and/or enforceability of the remaining provisions of the Terms of Use, which will remain in full force and effect.
						</div>
						<div className='terms-text-content-title'>15. Entire agreement</div>
						<div className='terms-text-content-description'>
							15.1. These Terms of Use, together with the Privacy Policy and Magroove for Artists Terms of Use, when applicable, constitute the entire agreement between you and Magroove with respect to your use of the Platform, and supersede any prior agreement between you and Magroove.
						</div>
						<div className='terms-text-content-title'>16. Applicable law and jurisdiction</div>
						<div className='terms-text-content-description'>
							16.1. This Agreement is subject to the laws of the Federative Republic of Brazil, and the Parties hereby agree to submit to the exclusive jurisdiction of the courts in the city of São Paulo, State of São Paulo, Brazil, for resolution of any dispute, action or proceeding arising in connection with this Agreement.
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Map Redux state to component props
// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
	return {};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopTerms);