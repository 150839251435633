import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Snackbar from '@components/Snackbar.js';
import Meta from '@views/Meta.js';

// URLs Imports
import DesktopRoutes from './routes/DesktopRoutes.js';

// Components and Views Imports
import DesktopHeader from '@views/DesktopHeader.js';

class DesktopApp extends Component {
	render() {
		return (
			<Router>
				<Meta />
				<DesktopHeader />
				<DesktopRoutes />
				<Snackbar />
			</Router>
		);
	}
}

// Map Redux state to component props
function mapStateToProps(state) { // eslint-disable-line
	return {};
}

// Connect component to the store
export default connect(mapStateToProps)(DesktopApp);